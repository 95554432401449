/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";

const SectionGroups = styled.section`
  position: relative;
  padding: 30px 30px;

  ${media.mobile`
		padding: 10px;
	`}
`;

export const SectionHead = styled.header`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.mobile`
		display: block;
	`}
`;

export const SectionHeadActions = styled.div`
   {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 10px;
    }
  }
`;

export const SectionTitle = styled.h1`
  font-weight: 700;
  margin-bottom: 0;
  font-size: 24px;
`;

export const SectionBody = styled.div.attrs({
  id: (props) => props.id,
})``;

export const SortByToggle = styled.div`
  display: flex;
  align-items: center;

  .selectToggle {
    width: 80px;
  }
`;

export const SelectLabel = styled.label`
  color: #a3aaad;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.29;
  padding-right: 5px;
  flex-shrink: 0;
`;

export const BtnSettings = styled.button`
  border: none;
  background: none;
  padding: 5px;
  font-size: 0;
  margin-left: 30px;
`;

export const BtnMore = styled.button`
  border: none;
  background: none;
  padding: 5px;
  font-size: 0;
`;

export const BtnAdd = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  background: #8013e0;
  border-radius: 2px;
  transition: background 0.4s;
  margin-left: 30px;

  &:hover {
    background: #a750f4;
  }
`;

export default SectionGroups;
