import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import NavTrigger, { NavTriggerItem } from "styles/components/NavTrigger";
import LogoHolder, { Logo, LogoText } from "styles/components/Logo";
import Header, {
  HeaderAside,
  HeaderActions,
  BtnNotification,
  BtnInfo,
} from "styles/components/Header";
import { MaintenanceBannerContainer } from "styles/components/Banner";
import SearchComponent from "components/elements/SearchComponent";
import NavigationComponent from "components/nav/NavigationComponent";
import { setPageBlur } from "store/state/questions/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ProfileWidgetComponent from "../elements/ProfileWidgetComponent";
import ButtonLink from "components/common/ButtonLink";
import stemifyRoutes from "../../constants/routes";
import { Link } from "react-router-dom";
import HelpWidgetComponent from "components/elements/HelpWidgetComponent";
import { getMaintenanceBannerMessage } from "utilities/notification";
import moment from "moment";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.bannerRef = React.createRef();
  }

  state = {
    showNav: false,
    bannerOffset: 0,
  };

  toggleMenu = () => {
    this.setState({ showNav: !this.state.showNav });
  };

  closeMenu = () => {
    this.setState({ showNav: false });
  };

  handleClick = () => {
    this.props.triggerLock();
    this.toggleMenu();
  };

  debounce = (callback, delay) => {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  calculateBannerOffset = this.debounce((e) => {
    if (this.bannerRef?.current) {
      this.setState({
        bannerOffset: this.bannerRef.current.clientHeight,
      });
    }
  }, 500);

  componentDidMount() {
    window.addEventListener("resize", this.calculateBannerOffset);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateBannerOffset);
  }

  componentDidUpdate(lastProps) {
    if (this.props.banner.isActive !== lastProps.banner.isActive) {
      this.calculateBannerOffset();
    }
  }

  getDrawerName = () => {
    switch (this.props.userRole) {
      case "STUDENT":
        return "Student";
      case "INSTRUCTOR":
        return "Instructor";
      case "MASTER_ADMIN":
      case "ACCOUNT_ADMIN":
        return "Admin";
      default:
        return "";
    }
  };

  render() {
    const { banner } = this.props;
    const active =
      banner &&
      banner?.isActive &&
      moment(banner?.displayStartTime).isSameOrBefore(moment()) &&
      moment(banner?.displayEndTime).isSameOrAfter(moment());

    return (
      <>
        <MaintenanceBannerContainer active={active} innerRef={this.bannerRef}>
          <>
            <IconSvgComponent
              iconPath="svg/error-icon-black.svg"
              additionalClass="maintenanceBannerIcon"
            />
            <span style={{ paddingLeft: "20px" }}>
              <span
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Scheduled Maintenance: &nbsp;
              </span>
              <span>
                {getMaintenanceBannerMessage({
                  mxStartTime: this.props.banner.mxStartTime,
                  mxEndTime: this.props.banner.mxEndTime,
                })}
              </span>
            </span>
          </>
        </MaintenanceBannerContainer>
        <Header>
          <HeaderAside isFixed={this.state.showNav}>
            <ButtonLink
              id={"navigationMenuBtn"}
              isNavTrigger
              onClick={this.handleClick}
              isHighlighted={this.state.showNav}
            >
              <NavTriggerItem />
              <NavTriggerItem />
              <NavTriggerItem />
            </ButtonLink>

            <LogoHolder
              className={this.state.showNav ? "is-highlighted" : ""}
              to={stemifyRoutes.home}
              aria-label="Stemify Logo. Click to go Home"
            >
              <IconSvgComponent iconPath="svg/stemify-logo-head.svg" />
            </LogoHolder>
          </HeaderAside>

          <MediaQuery minWidth={768}>
            <SearchComponent />
          </MediaQuery>

          <HeaderActions>
            <BtnNotification>
              <IconSvgComponent iconPath="svg/ico-notification.svg" />
            </BtnNotification>
            <HelpWidgetComponent />
            <ProfileWidgetComponent />
          </HeaderActions>

          <NavigationComponent
            isOpen={this.state.showNav}
            triggerCloseNavigation={this.closeMenu}
            userRole={this.props.userRole}
            bannerOffset={this.state.bannerOffset}
          />
        </Header>
      </>
    );
  }
}

export default connect(
  (state) => ({
    isModalOpen: state.questions.isModalOpen,
    userRole: state.user.role,
    banner: state.administration.banner,
  }),
  {
    setPageBlur,
  }
)(HeaderComponent);
