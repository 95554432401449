/**
 * @ The external dependecies
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { prop } from "ramda";
import classnames from "classnames";

/**
 * @ The internal dependecies.
 */
import { closeModal } from "store/state/ui/actions";
import { clearDeployment } from "store/state/deployment/actions";
import ModalCreateAccount from "./ModalCreateAccount";
import ModalCreateUser from "./ModalCreateUser";
import ModalCreateCohort from "./ModalCreateCohort";
import ModalCreateStudent from "./ModalCreateStudent";
import ModalRemoveStudent from "./ModalRemoveStudent";
import ModalActivation from "./ModalActivation";
import ModalWizard from "./ModalWizard";
import ModalUserLimitReached from "./ModalUserLimitReached";
import ModalDeploymentCancellation from "./ModalDeploymentCancellation";
import ModalArchive from "./ModalArchive";
import ModalAddUsers from "./ModalAddUsers";
import ModalAccountListing from "./ModalAccountListing";
import ModalAssessmentPasscode from "./ModalAssessmentPasscode";
import ModalAssessmentSubmit from "./ModalAssessmentSubmit";
import ModalAssessmentTimeEnd from "./ModalAssessmentTimeEnd";
import ModalShowErrorMessage from "./ModalShowErrorMessage";
import ModalCooldownPeriod from "./ModalCooldownPeriod";
import ModalAssessmentSubmitGuide from "./ModalAssessmentSubmitGuide";
import ModalAssessmentResume from "./ModalAssessmentResume";
// import ModalAssessmentResume from "./ModalAssessmentResume";
import ModalCreateSection from "./ModalCreateSection";
import ModalConfirmation from "./ModalConfirmation";
import ModalCreateCourseUnit from "./ModalCreateCourseUnit";
import ModalRemoveSelected from "./ModalRemoveSelected";
import ModalCreateBuilderAssessment from "./ModalCreateBuilderAssessment";
import ModalDeleteBuilderQuestion from "./ModalDeleteBuilderQuestion";
import ModalShowNoNetworkErrorMessage from "./ModalShowNoNetworkErrorMessage";
import ModalImageManager from "./ModalImageManager";
import ModalDashboardResumeAttempt from "./ModalDashboardResumeAttempt";

/**
 * @ Mount the react modal portal.
 */
Modal.setAppElement("#root");

/**
 * Class for Modal Manager.
 *
 * @class ModalManager (name)
 */
class ModalManager extends Component {
  lookupModalComponent() {
    const { type } = this.props.modal;
    const { modals } = ModalManager;
    return modals[type];
  }

  isModalWizard() {
    return this.props.modal.type === "ModalWizard";
  }

  handleWizardClose = (e) => {
    e.preventDefault();
    const { closeModal, clearDeployment, modal } = this.props;
    const hasProp = (key) => prop(key, modal.data);

    if (hasProp("editDeployment")) {
      closeModal();
      clearDeployment();
    } else if (!hasProp("shouldNotCloseOnEsc")) {
      closeModal();
    }
  };

  render() {
    const { modal } = this.props;

    const ModalComponent = this.lookupModalComponent();
    return (
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        onRequestClose={this.handleWizardClose}
        className={classnames([
          "Modal",
          modal.data && modal.data.modalSize && `ReactModal__Size-${modal.data.modalSize}`,
        ])}
        overlayClassName={classnames({
          Overlay: true,
          ReactModal__Wizard: this.isModalWizard(),
        })}
        isOpen={modal.isOpen}
      >
        <div className="ReactModal__Inner">
          {ModalComponent ? <ModalComponent /> : <p>MODAL-NOT-FOUND</p>}
        </div>
      </Modal>
    );
  }
}

/**
 * Define the supported modals.
 *
 * @type {Object}
 */
ModalManager.modals = {
  ModalUserLimitReached,
  ModalCreateAccount,
  ModalActivation,
  ModalCreateUser,
  ModalCreateCohort,
  ModalCreateStudent,
  ModalRemoveStudent,
  ModalWizard,
  ModalDeploymentCancellation,
  ModalArchive,
  ModalAddUsers,
  ModalAccountListing,
  ModalAssessmentPasscode,
  ModalAssessmentSubmit,
  ModalAssessmentTimeEnd,
  ModalShowErrorMessage,
  ModalCooldownPeriod,
  ModalAssessmentSubmitGuide,
  ModalAssessmentResume,
  ModalCreateSection,
  ModalConfirmation,
  ModalCreateCourseUnit,
  ModalRemoveSelected,
  ModalCreateBuilderAssessment,
  ModalDeleteBuilderQuestion,
  ModalImageManager,
  ModalShowNoNetworkErrorMessage,
  ModalDashboardResumeAttempt,
};

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    closeModal,
    clearDeployment,
  }
)(ModalManager);
