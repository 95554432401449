import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentTitle from "react-document-title";
import moment from "moment";

import { PaginatedTable } from "components/common/table";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import SectionTable, { SectionHead, SectionBody } from "styles/components/SectionTable";
import Form, {
  FormActions,
  FormBody,
  FormCol,
  FormControls,
  FormRowGrid,
} from "styles/components/Form";
import Field from "components/common/Field";
import { TableEntry, TableTitle } from "styles/components/TableDefault";
import { openModal } from "store/state/ui/actions";
import { getAttempts } from "store/state/assessment/actions";
import { deleteAttemptById } from "services/assessment";
import { MODAL_CONFIRMATION } from "lib/constants";

const AdminPage = () => {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.user.accountId);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [username, setUsername] = useState("");
  const [data, setData] = useState([]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setUsername(value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    setIsTableLoading(true);
    const trimmedUsername = username.trim();

    try {
      const attempts = await dispatch(
        getAttempts({ username: trimmedUsername, includeModel: true })
      );
      const formattedAttempts = attempts.map((attempt) => {
        return {
          id: attempt.id,
          username: attempt.username,
          type: attempt.assessment.type,
          assessmentSeries: attempt.assessment_series.name,
          assessment: attempt.assessment.name,
          cohort: attempt.cohort?.name || "--",
          startTime: moment(attempt.start_time).format("YYYY-MM-DD"),
          endTime: attempt.end_time ? moment(attempt.end_time).format("YYYY-MM-DD") : "--",
        };
      });
      setData(formattedAttempts);
    } catch (error) {
      console.log({ error });
    }

    setHasSearched(true);
    setIsTableLoading(false);
  };

  const deleteAttempt = async ({ type, attemptId }) => {
    setIsTableLoading(true);

    try {
      await deleteAttemptById({ accountId, type, attemptId });
    } catch (error) {
      console.log({ error });
    }

    const newData = data.filter((a) => a.id !== attemptId);
    setData(newData);
    setIsTableLoading(false);
  };

  const openDeleteAttemptConfirmationModal = ({ type, attemptId }) => {
    dispatch(
      openModal({
        type: MODAL_CONFIRMATION,
        data: {
          title: "this attempt",
          icon: "ico-assessment-lg.svg",
          type: "delete",
          action: () => deleteAttempt({ type, attemptId }),
        },
      })
    );
  };

  const columns = [
    {
      id: "username",
      Header: "Username",
      accessor: "username",
    },
    {
      id: "type",
      Header: "Type",
      accessor: "type",
    },
    {
      id: "assessmentSeries",
      Header: "Assessment Series",
      accessor: "assessmentSeries",
    },
    {
      id: "assessment",
      Header: "Assessment",
      accessor: "assessment",
    },
    {
      id: "cohort",
      Header: "Cohort",
      accessor: "cohort",
    },
    {
      id: "startTime",
      Header: "Start Time",
      accessor: "startTime",
    },
    {
      id: "endTime",
      Header: "End Time",
      accessor: "endTime",
    },
    {
      id: "dropdown",
      Header: "",
      width: 125,
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      Cell: (props) => {
        const attempt = props.row.original;

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              openDeleteAttemptConfirmationModal({ type: attempt.type, attemptId: attempt.id })
            }
          >
            <IconSvgComponent iconPath="svg/ico-delete.svg" />
          </div>
        );
      },
      filterable: false,
    },
  ];

  return (
    <DocumentTitle title="Stemify | Administration">
      <SectionTable>
        <SectionHead>
          <div
            style={{
              width: "500px",
              margin: "0 auto",
            }}
          >
            <Form onSubmit={handleSearchSubmit}>
              <FormBody>
                <FormRowGrid>
                  <FormCol>
                    <FormControls>
                      <Field
                        id="username"
                        name="username"
                        type="text"
                        onChange={handleInputChange}
                        value={username}
                        placeholder="Username"
                      />
                    </FormControls>
                  </FormCol>
                </FormRowGrid>
              </FormBody>
              <FormActions>
                <ButtonLink type="submit" violet hasIcon disabled={isTableLoading || !username}>
                  <span>Search</span>
                  <IconSvgComponent iconPath="svg/ico-magnifier1.svg" />
                </ButtonLink>
              </FormActions>
            </Form>
          </div>
        </SectionHead>

        <SectionBody>
          <PaginatedTable
            data={data}
            columns={columns}
            page={0}
            pages={1}
            pageSize={data.length}
            count={data.length}
            isLoading={isTableLoading}
            NoDataComponent={() => (
              <div
                style={{
                  height: "487px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {hasSearched ? (
                  <Fragment>
                    <TableTitle>There are no attempts for this user.</TableTitle>
                    <TableEntry style={{ marginBottom: "50px" }}>
                      Looks like you need to try another username.
                    </TableEntry>
                  </Fragment>
                ) : (
                  <Fragment>
                    <TableTitle>Enter a username and click search.</TableTitle>
                    <TableEntry style={{ marginBottom: "50px" }}>
                      Looks like you haven't searched for anything yet.
                    </TableEntry>
                  </Fragment>
                )}
              </div>
            )}
          />
        </SectionBody>
      </SectionTable>
    </DocumentTitle>
  );
};

export default AdminPage;
