import React, { Component } from "react";
import { connect } from "react-redux";
import { includes } from "ramda";
import AnimateHeight from "react-animate-height";

import { sidebarToggle, setCurrentQuestion } from "store/state/assessment/actions";
import { setCourseCurrentQuestion } from "store/state/courseAssessment/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  Sidebar,
  SidebarOpen,
  SidebarOpenInner,
  SidebarClose,
  SidebarCloseInner,
  SidebarContent,
  Pinned,
  PinnedHead,
  PinnedBody,
  PinnedIcon,
  PinnedText,
  PinnedTotal,
  QuestionsButtons,
  QuestionButton,
  QuestionIcon,
  QuestionWrapper,
} from "styles/components/AssessmentSidebar";

class AssessmentSidebar extends Component {
  state = {
    isPinnedBodyOpen: false,
    height: 0,
    isFocusSiderbarClose: false,
    isFocusSiderbarOpen: false,
  };

  componentDidMount = async () => {
    document.addEventListener("keydown", this.keyboardEventHandler, false);
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyboardEventHandler, false);
  }

  keyboardEventHandler = (e) => {
    if (e.which === 9) {
      if (this.props.isSidebarOpen === false) {
        this.setState({ isFocusSiderbarOpen: false, isFocusSiderbarClose: false }, () => {
          this.props.sidebarToggle();
        });
      }
    }
  };

  togglePinnedBody = () => {
    const { height } = this.state;

    this.setState({
      height: height === 0 ? "auto" : 0,
    });
  };

  handleEnterPress = (e, q, i) => {
    if (e.which == 13 || e.which == 32) {
      return this.handleQuestionButtonClick(q, i);
    }
  };

  onKeyPressSideBar = (e, action) => {
    if (e.which == 13 || e.which == 32) {
      if (action == "open") {
        this.setState({ isFocusSiderbarOpen: false, isFocusSiderbarClose: true });
      } else {
        if (action == "close") {
          this.setState({ isFocusSiderbarOpen: true, isFocusSiderbarClose: false });
        }
      }
    }
  };

  onKeyPressPinnedQuestions = (e) => {
    if (e.which == 13 || e.which == 32) {
      this.togglePinnedBody();
    }
  };

  handleQuestionButtonClick = (questionId, questionIndex) => {
    const { setCurrentQuestion, courseAssessment, setCourseCurrentQuestion } = this.props;

    //!courseAssessment
      //? setCurrentQuestion({ questionIndex, questionId })
      //: setCourseCurrentQuestion({ questionIndex, questionId });
      setCurrentQuestion({ questionIndex, questionId })
  };

  renderQuestionButtons = () => {
    const { questions, currentQuestionId, completedQuestions, pinnedQuestions } = this.props;

    return (
      <QuestionsButtons>
        {questions.map((question, index) => {
          return (
            <QuestionButton
              tabIndex={question.id === currentQuestionId ? null : 0}
              onKeyPress={(e) => this.handleEnterPress(e, question.id, index)}
              key={question.id}
              id={question.id}
              current={question.id === currentQuestionId}
              pinned={includes(question.id, pinnedQuestions)}
              completed={completedQuestions[question.id] !== undefined}
              onClick={() => this.handleQuestionButtonClick(question.id, index)}
            >
              <QuestionIcon>
                <IconSvgComponent
                  additionalClass={"check"}
                  iconPath="svg/ico-check-white-medium.svg"
                />

                <IconSvgComponent additionalClass={"pin"} iconPath="svg/ico-pin-blue.svg" />
              </QuestionIcon>

              {index + 1}
            </QuestionButton>
          );
        })}
      </QuestionsButtons>
    );
  };

  renderPinnedQuestion = () => {
    const { questions, currentQuestionId, completedQuestions, pinnedQuestions } = this.props;

    return (
      <QuestionsButtons>
        {questions.map((question, index) => {
          if (includes(question.id, pinnedQuestions)) {
            return (
              <QuestionButton
                key={question.id}
                id={question.id}
                current={question.id === currentQuestionId}
                completed={completedQuestions[question.id] !== undefined}
                onClick={() => this.handleQuestionButtonClick(question.id, index)}
                tabIndex={question.id === currentQuestionId ? null : 0}
                onKeyPress={(e) => this.handleEnterPress(e, question.id, index)}
              >
                <QuestionIcon>
                  <IconSvgComponent
                    additionalClass={"check"}
                    iconPath="svg/ico-check-white-medium.svg"
                  />

                  <IconSvgComponent additionalClass={"pin"} iconPath="svg/ico-pin-blue.svg" />
                </QuestionIcon>

                {index + 1}
              </QuestionButton>
            );
          } else {
            return null;
          }
        })}
      </QuestionsButtons>
    );
  };

  render() {
    const { height } = this.state;
    const { isSidebarOpen, sidebarToggle, pinnedQuestions } = this.props;

    return (
      <Sidebar className={isSidebarOpen ? "sidebar-open" : ""}>
        <SidebarOpen
          type="button"
          onClick={() => {
            this.setState({ isFocusSiderbarOpen: false });
            sidebarToggle();
          }}
          tabIndex={0}
          focused={this.state.isFocusSiderbarOpen}
          isOpen={isSidebarOpen}
          onKeyPress={(e) => this.onKeyPressSideBar(e, "open")}
        >
          <SidebarOpenInner>
            <IconSvgComponent iconPath="svg/ico-sidebar-open.svg" />
          </SidebarOpenInner>
        </SidebarOpen>

        <SidebarContent className="sidebar-content">
          <SidebarClose
            type="button"
            onClick={() => {
              this.setState({ isFocusSiderbarClose: false });
              sidebarToggle();
            }}
            focused={this.state.isFocusSiderbarClose}
            tabIndex={0}
            isOpen={!isSidebarOpen}
            onKeyPress={(e) => this.onKeyPressSideBar(e, "close")}
          >
            <SidebarCloseInner>
              <IconSvgComponent iconPath="svg/ico-sidebar-close.svg" />
              Question Navigator
            </SidebarCloseInner>
          </SidebarClose>
          <QuestionWrapper>
            <Pinned>
              <PinnedHead
                onKeyPress={(e) => this.onKeyPressPinnedQuestions(e)}
                onClick={this.togglePinnedBody}
                tabIndex={0}
                isOpen={this.state.isPinnedBodyOpen && pinnedQuestions.length}
              >
                <PinnedIcon>
                  <IconSvgComponent iconPath="svg/ico-pin-blue.svg" />
                </PinnedIcon>

                <PinnedText>Pinned Questions</PinnedText>

                <PinnedTotal>{pinnedQuestions.length}</PinnedTotal>
              </PinnedHead>

              <AnimateHeight duration={500} height={height}>
                <PinnedBody active={this.state.isPinnedBodyOpen && pinnedQuestions.length}>
                  {this.renderPinnedQuestion()}
                </PinnedBody>
              </AnimateHeight>
            </Pinned>

            {this.renderQuestionButtons()}
          </QuestionWrapper>
        </SidebarContent>
      </Sidebar>
    );
  }
}

export default connect(
  (state, ownProps) => {
    //const storeKey = ownProps.courseAssessment ? "courseAssessment" : "assessment";
    return {
      questions: state.assessment.assessmentQuestions,
      isSidebarOpen: state.assessment.isSidebarOpen,
      activeAssessment: state.assessment.activeAssessment,
      currentQuestionId: state.assessment.currentQuestionId,
      completedQuestions: state.assessment.completedQuestions,
      pinnedQuestions: state.assessment.pinnedQuestions,
      currentQuestionIndex: state.assessment.currentQuestionIndex,
    };
  },
  {
    setCurrentQuestion,
    sidebarToggle,
    setCourseCurrentQuestion,
  }
)(AssessmentSidebar);
