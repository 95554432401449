/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

export const BuilderQuestion = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  width: 100%;
  align-self: flex-start;
  border-radius: 8px;

  .btn-add-answer {
    text-transform: uppercase;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: 16px 25px;
    border-top: 1px solid rgba(151, 151, 151, 0.1) !important;
    border-radius: 0;

    span {
      margin-left: 15px;
    }

    svg {
      width: 15px;
      height: 15px;

      g {
        stroke: ${theme.color.red};
      }
    }

    &:hover {
      color: ${theme.color.red};
    }
  }

  .selectToggle {
    background: transparent;
    color: ${theme.color.darker_gray};
    height: auto !important;
    max-width: 165px;

    .react-select__control {
      height: auto !important;
      outline: 0 !important;
      padding: 0 20px !important;
      box-shadow: none !important;
      cursor: pointer;
    }

    .react-select__dropdown-indicator {
      display: none;
    }

    .react-select__control::after {
      top: 17px;
      background-image: url(/svg/ico-select-caret-dark.svg);
    }

    .react-select__control--is-focused,
    .react-select-container > .react-select__control,
    .is_focused {
      border-color: transparent !important;
    }

    .react-select__control .option span {
      color: currentColor;
      font-weight: 400;
    }

    .react-select__control .option .svg-icon svg g {
      fill: currentColor;
    }

    .react-select__control:hover {
      border: none;
      border-color: transparent;
    }

    .react-select__option {
      padding: 0 10px !important;
    }

    .react-select__control .react-select__value-container {
      padding-right: 5px !important;
    }

    .react-select__control .react-select__single-value {
      padding-right: 5px !important;
    }

    ${media.desktop`
      max-width: 150px;
    `}
  }
`;

export const BuilderQuestionBar = styled.div`
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 30px;
  min-height: 76px;

  ${media.desktop`
    padding: 8px 15px;
  `}

  ${media.tablet_portrait`
    min-height: 0;
    flex-wrap: wrap;
  `}
`;

export const BuilderQuestionBarActions = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  .question-btn {
    font-size: 15px;
    color: #4a4a4a;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 1px;
    span {
      margin-right: 10px;
    }

    svg g {
      fill: ${theme.color.darker_gray};
    }
  }

  .question-btn--delete {
    &:hover {
      color: ${theme.color.red};
      svg g {
        fill: ${theme.color.red};
      }
    }

    ${media.mobile`
      position: absolute;
      top: 15px;
      right: 15px;

      span { display: none; }
    `}
  }

  ${media.tablet_portrait`
    width: 100%;
    justify-content: flex-end;
  `}

  ${media.mobile`
    justify-content: center;
  `}
`;

export const BuilderQuestionName = styled.div`
  display: block;
  max-width: 50%;

  h3 {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${(props) =>
    props.noTitleEdit &&
    `
    pointer-events: none;

    h3 {
      white-space: normal;
      overflow: visible;
      line-height: 1;
      height: auto;
    }
  `}

  ${media.tablet_portrait`
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  `}

  ${media.mobile`
    text-align: center;

    button {
      margin: 0 auto;
    }
  `}
`;

export const BuilderQuestioContent = styled.div`
  display: block;
  padding: 0 15px;
  min-height: 185px;
`;

export const BuilderQuestioContentActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    padding: 15px;
  }
`;

export const BuilderQuestioContentEntry = styled.div`
  display: block;

  label {
    top: 25px !important;
    width: 100%;
    font-size: 26px !important;
    text-align: center;
    color: ${theme.color.darker_gray} !important;
    transition: opacity 0.3s, visibility 0.3s;
  }

  .is-focused,
  .is-filled {
    label {
      opacity: 0;
      visibility: hidden;
      transform: none !important;
    }
  }

  input {
    resize: none;
    text-align: center;
    font-size: 26px;
    border: none;
    padding: 30px 0;
  }
`;

export const BuilderQuestioImagePreview = styled.div`
  display: block;
  padding: 0 15px;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;
`;

export const BuilderQuestioFoot = styled.div``;

export default BuilderQuestion;
