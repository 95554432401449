import { createAction } from "redux-actions";
import { COMMON_MESSAGES } from "../../../constants/messages";
import { isEmpty, pick } from "ramda";
import {
  getStudentPerformanceData,
  getStudentSelectedAssessmentDetails,
} from "../../../services/student";

import { OPERATIONS } from "../../../constants/common";
import assessment from "../assessment/reducer";

/**
 * @ Action creators
 */
export const studentDashboardDetails = createAction("STUDENT_DASHBOARD_DETAILS");
export const AssessmentPerformanceDetails = createAction(
  "STUDENT_DASHBOARD_SELECTED_ASSESSMENT_DETAILS"
);

export const request = createAction("REQUEST_STUDENT_DASHBOARD");
export const reject = createAction("REJECT_STUDENT_DASHBOARD");

export const getDashboardDetails = ({ selector }) => async (dispatch) => {
  dispatch(request({ selector, operation: OPERATIONS.GET }));
  try {
    const response = await getStudentPerformanceData();
    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        const assessments = { ...pick(["assessments"], response.data) };
        const topicInfo = { ...pick(["topicInfo"], response.data) };
        const data = {
          assessments: !isEmpty(assessments) ? [...assessments.assessments] : [],
          topicInfo: !isEmpty(topicInfo) ? [...topicInfo.topicInfo] : [],
        };
        dispatch(studentDashboardDetails({ data, selector, operation: OPERATIONS.GET }));
      } else {
        // student is yet to give assessment
        const data = {
          assessments: [],
          topicInfo: [],
        };
        dispatch(studentDashboardDetails({ data, selector, operation: OPERATIONS.GET }));
      }
    } else {
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.GET,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {
    console.log(e);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
  }
};

export const getAssessmentDetails = ({ selector, deploymentId, assessmentSeriesId }) => async (
  dispatch
) => {
  dispatch(request({ selector, operation: OPERATIONS.GET }));

  try {
    const response = await getStudentSelectedAssessmentDetails(assessmentSeriesId);
    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        // Find matching deploymentment assessment series
        const deployedAssessmentSeries = response.data.find(
          (deployedSeries) => deployedSeries.deployment_id === deploymentId
        );

        const assessmentCourse = { ...pick(["assessment_course"], deployedAssessmentSeries) };
        const attemptHistory = { ...pick(["attempt_history"], deployedAssessmentSeries) };
        const topicInfo = { ...pick(["topicInfo"], deployedAssessmentSeries) };
        const data = {
          assessmentId: deployedAssessmentSeries.id,
          deploymentId: deployedAssessmentSeries.deployment_config_id,
          deploymentName: deployedAssessmentSeries.deployment_name,
          nextAttemptDate: deployedAssessmentSeries.next_attempt_date,
          topAttemptDate: deployedAssessmentSeries.attempt_history.reduce(
            (topAttempt, attempt) => (attempt.score > topAttempt.score ? attempt : topAttempt),
            { score: 0 }
          ).attempt_date,
          remainingAttempt: deployedAssessmentSeries.remaining_attempt,
          lastAttemptDate: deployedAssessmentSeries.last_attempt_date,
          assessmentName: deployedAssessmentSeries.assessment_name,
          status: deployedAssessmentSeries.status,
          score: {
            ...pick(
              ["top_score", "total_score", "top_score_perc", "total_score_perc"],
              deployedAssessmentSeries
            ),
          },
          assessment_course: !isEmpty(assessmentCourse)
            ? [...assessmentCourse.assessment_course]
            : [],
          attempt_history: !isEmpty(attemptHistory) ? [...attemptHistory.attempt_history] : [],
          topicInfo: !isEmpty(topicInfo) ? [...topicInfo.topicInfo] : [],
        };

        dispatch(AssessmentPerformanceDetails({ data, selector, operation: OPERATIONS.GET }));
      }

      return response.data;
    } else {
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.GET,
          errorItem: response.data,
        })
      );
      return false;
    }
  } catch (e) {
    console.log(e);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
  }
};
