import React from "react";

import FullscreenModalItem from "components/courses/FullscreenModal";
import FormCreateAssignment from "components/form/FormCreateAssignment";
import BaseAssignmentForm from "components/form/BaseAssignmentForm";
import { FullscreenModalContent } from "styles/components/FullscreenModal";
import Field from "components/common/Field";
import { FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES } from "constants/messages";
import { ASSIGNMENT_TYPES } from "constants/common";
import Form, {
  FormControls,
  FormLabel,
  StyledRadioCotainer,
  FormFieldStatic,
} from "styles/components/Form";
import AttemptRangeSlider from "components/chart/AttemptRangeSlider";
import "styles/radioButtons.css";

const ModalWrapper = ({ isOpen, onClose, children }) => {
  return (
    <FullscreenModalItem active={isOpen} onClose={onClose}>
      <FullscreenModalContent max_width="764px">{children}</FullscreenModalContent>
    </FullscreenModalItem>
  );
};

const ModalCourseAssignmentCreate = ({
  onClose,
  isOpen,
  assignmentType,
  onSubmit,
  onAdd,
  isEditMode,
  activeAssignmentId,
  activeUnitId,
}) => {
  switch (assignmentType) {
    case ASSIGNMENT_TYPES.VIDEO:
      return (
        <ModalWrapper onClose={onClose} isOpen={isOpen}>
          <BaseAssignmentForm
            type={ASSIGNMENT_TYPES.VIDEO}
            onCancel={onClose}
            onSubmit={onSubmit}
            onAddAssets={onAdd}
            isEditMode={isEditMode}
            activeAssignmentId={activeAssignmentId}
            activeUnitId={activeUnitId}
            customProperties={{
              showAttachButton: true,
            }}
          />
        </ModalWrapper>
      );

    case ASSIGNMENT_TYPES.PRACTICE:
      return (
        <ModalWrapper onClose={onClose} isOpen={isOpen}>
          <BaseAssignmentForm
            type={ASSIGNMENT_TYPES.PRACTICE}
            onCancel={onClose}
            onSubmit={onSubmit}
            onAddAssets={onAdd}
            isEditMode={isEditMode}
            activeAssignmentId={activeAssignmentId}
            activeUnitId={activeUnitId}
            customProperties={{
              showAttachButton: true,
              formState: {
                pointValue: 100,
              },
              formErrors: {
                pointValue: {
                  valid: true,
                  message: "",
                },
              },
              rules: {
                pointValue: {
                  requiredMessage:
                    FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_POINTS_REQUIRED,
                },
              },
              validation: (validateTextField, validateFormDateField) => {
                return [validateTextField(document.getElementById("pointValue"), true)];
              },

              RightColumnFields: (formState, handleInputChange) => {
                return (
                  <FormControls topOffset="25">
                    <Field
                      id="pointValue"
                      name="pointValue"
                      type="number"
                      onChange={handleInputChange}
                      value={formState.pointValue}
                      placeholder="Points"
                      required
                    />
                  </FormControls>
                );
              },
            }}
          />
        </ModalWrapper>
      );
    case ASSIGNMENT_TYPES.EXAM:
      return (
        <ModalWrapper onClose={onClose} isOpen={isOpen}>
          <BaseAssignmentForm
            type={ASSIGNMENT_TYPES.EXAM}
            onCancel={onClose}
            onSubmit={onSubmit}
            onAddAssets={onAdd}
            isEditMode={isEditMode}
            activeAssignmentId={activeAssignmentId}
            activeUnitId={activeUnitId}
            customProperties={{
              showAttachButton: true,
              formState: {
                pointValue: 100,
                attemptLimit: 1,
                // sliderVal: 1,
                timeLimit: 60,
                // multiAttempt: "No",
                // uniqueFormPerAttempt: "No",
              },
              formErrors: {
                pointValue: {
                  valid: true,
                  message: "",
                },
                attemptLimit: {
                  valid: true,
                  message: "",
                },
                timeLimit: {
                  valid: true,
                  message: "",
                },
              },
              rules: {
                pointValue: {
                  requiredMessage:
                    FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_POINTS_REQUIRED,
                },
                attemptLimit: {
                  requiredMessage:
                    FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_ATTEMPTS_REQUIRED,
                },
                timeLimit: {
                  requiredMessage:
                    FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_ATTEMPTS_REQUIRED,
                },
              },
              validation: (validateTextField, validateFormDateField) => {
                return [
                  validateTextField(document.getElementById("pointValue"), true),
                  // validateTextField(document.getElementById("attemptLimit"), true),
                ];
              },
              RightColumnFields: (
                formState,
                handleInputChange,
                handleSliderChange,
                onChangeRadioSelection
              ) => {
                return (
                  <React.Fragment>
                    <FormControls topOffset="25">
                      <Field
                        id="pointValue"
                        name="pointValue"
                        type="number"
                        onChange={handleInputChange}
                        value={formState.pointValue}
                        placeholder="Points"
                        required
                        max="255"
                      />
                    </FormControls>
                    {/* <FormControls>
                      <FormFieldStatic noPadding>Multi Attempt</FormFieldStatic>
                      <StyledRadioCotainer>
                        <label class="container">
                          Yes
                          <input
                            type="radio"
                            name="multiAttempt"
                            onChange={onChangeRadioSelection}
                            checked={formState.multiAttempt === "Yes"}
                            value={"Yes"}
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="container">
                          No
                          <input
                            type="radio"
                            name="multiAttempt"
                            checked={formState.multiAttempt === "No"}
                            onChange={onChangeRadioSelection}
                            value={"No"}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </StyledRadioCotainer>
                    </FormControls>
                    <FormControls>
                      <br></br>
                      <FormFieldStatic noPadding>Unique Form Per Attempt</FormFieldStatic>
                      <StyledRadioCotainer>
                        <label class="container">
                          Yes
                          <input
                            type="radio"
                            name="uniqueFormPerAttempt"
                            onChange={onChangeRadioSelection}
                            checked={formState.uniqueFormPerAttempt === "Yes"}
                            value={"Yes"}
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="container">
                          No
                          <input
                            type="radio"
                            name="uniqueFormPerAttempt"
                            checked={formState.uniqueFormPerAttempt === "No"}
                            onChange={onChangeRadioSelection}
                            value={"No"}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </StyledRadioCotainer>
                    </FormControls>
                    {formState.uniqueFormPerAttempt === "No" && (
                      <FormControls>
                        <br></br>
                        <Field
                          id="attemptLimit"
                          name="attemptLimit"
                          type="number"
                          onChange={handleInputChange}
                          value={formState.attemptLimit}
                          placeholder="Attempt Limit"
                          required
                          max="255"
                        />
                      </FormControls>
                    )}*/}
                    {/* {formState.uniqueFormPerAttempt === "Yes" && (
                      <FormControls>
                        <br></br>
                        <AttemptRangeSlider
                          value={formState.attemptLimit}
                          min={1}
                          max={3}
                          stepSize={1}
                          onChange={handleSliderChange}
                          sliderStyle={{
                            track: {
                              backgroundColor: "#16B5F2",
                              borderRadius: 0,
                              height: "5px",
                              width: "100%",
                            },
                            active: {
                              backgroundColor: "#16B5F2",
                            },
                            thumb: {
                              width: 25,
                              height: 25,
                              backgroundColor: "#16B5F2",
                            },
                            disabled: {
                              opacity: 0.5,
                            },
                          }}
                        ></AttemptRangeSlider>
                      </FormControls>
                    )} */}
                    <br></br>
                    <FormControls>
                      <Field
                        id="timeLimit"
                        name="timeLimit"
                        type="number"
                        onChange={handleInputChange}
                        value={formState.timeLimit}
                        placeholder="Time Limit (Minutes)"
                        required
                        isNumericTextField
                        max="255"
                      />
                    </FormControls>
                  </React.Fragment>
                );
              },
            }}
          />
        </ModalWrapper>
      );
    case ASSIGNMENT_TYPES.OPENSTAX:
      return (
        <ModalWrapper onClose={onClose} isOpen={isOpen}>
          <BaseAssignmentForm
            type={ASSIGNMENT_TYPES.OPENSTAX}
            onCancel={onClose}
            onSubmit={onSubmit}
            onAddAssets={onAdd}
            isEditMode={isEditMode}
            activeAssignmentId={activeAssignmentId}
            activeUnitId={activeUnitId}
            customProperties={{
              showAttachButton: true,
            }}
          />
        </ModalWrapper>
      );

    default:
      return null;
  }
};

export default ModalCourseAssignmentCreate;
