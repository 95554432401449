import moment from "moment";
import { createAction } from "redux-actions";
import { isEmpty } from "ramda";
import {
  getSectionsAPI,
  getSectionByIdAPI,
  createSectionAPI,
  copySectionAPI,
  updateSectionAPI,
  getAssignmentsAPI,
  getAssignmentByIdAPI,
  getSchedulesAPI,
  getAllDisciplinesAPI,
  getUnitsBySectionIdAPI,
  getAssignmentsBySectionIdAPI,
  getVideoDetailsByIdAPI,
  getAssessmentDetailsByIdAPI,
  getAllAssessments,
  getAllVideosAPI,
  createUnit as createUnitAPI,
  updateUnit as updateUnitAPI,
  deleteUnitAPI,
  deleteAssignmentAPI,
  createAssignmentAPI,
  getAssignmentAPI,
  updateAssignmentAPI,
  createScheduleAPI,
  getAssignedCourseUsersAPI,
  addUsersToCourseAPI,
  removeUserFromCourseAPI,
  getEnrolledCourseUsersAPI,
  removeEnrolledCourseStudentsAPI,
  enrollCourseStudentsAPI,
  deleteSectionAPI,
  getScheduleForSelectedAssignmentAPI,
  updateScheduleForSelectedAssignmentAPI,
  getSectionGradesByAssignmentAPI,
  getAssignmentGradesAPI,
  getAssignmentAttemptsAPI,
  getNumberOfAssignmentAttemptsAPI,
  getAssignmentDashboardStudentCountAPI,
  getAssignmentDashboardAverageTimeAPI,
  getAssignmentDashboardAverageScoreAPI,
  getAssignmentStandardDeviationAPI,
  getCourseAssessmentCompletionAPI,
  getCourseAssessmentScoreDistributionAPI,
  getCourseAssessmentTopicBreakDownAPI,
  getCourseAssessmentStudentBreakDownAPI,
  getVideoQuizAverageCorrectAnswersAPI,
  getVideoConceptsBreakdownAPI,
  getVideoQuizStudentBreakdownAPI,
  getVideoStudentBreakdownAPI,
  fetchVideoCompletionDataAPI,
  fetchVideoQuizCompletionDataAPI,
} from "services/courses";

import { getUserCurrentAccount, getRandomColor } from "utilities/commonFunctions";
import { convertKeysToCamelCase, convertKeysToUnderscore } from "utilities/API";
import { OPERATIONS } from "constants/common";
import { COMMON_MESSAGES } from "constants/messages";
import { getUsersForAccount } from "services/users";
import { removeAll } from "../administration/actions";
import { ASSIGNMENT_TYPES } from "constants/common";
import { IMAGE_S3_SERVER, THUMBNAILS } from "constants/api";
import { addNotification, clearNotifications } from "store/state/ui/actions";
import { createNotification, LEVELS } from "utilities/notification";
import { padTime } from "utilities/commonFunctions";

/**
 * @ Action creators
 */
export const requestActionCourse = createAction("REQUEST_ACTION_COURSE");
export const rejectActionCourse = createAction("REJECT_ACTION_COURSE");
export const listActionCourse = createAction("LIST_ACTION_COURSE");
export const updateCourseItem = createAction("UPDATE_COURSE_ITEM");
export const deleteActionCourse = createAction("DELETE_ACTION_COURSE");
export const removeActionCourse = createAction("REMOVE_ACTION_COURSE");
export const setActiveCourse = createAction("SET_ACTIVE_COURSE");
export const insertCourseUnits = createAction("INSERT_COURSE_UNITS");
export const deleteCourseUnitAssignment = createAction("DELETE_COURSE_UNIT_ASSIGNMENT");
export const insertCourseUnitAssignments = createAction("INSERT_COURSE_UNIT_ASSIGNMENTS");
export const setAssignmentAssets = createAction("SET_ASSIGNMENT_ASSETS");
export const setAssessmentAsset = createAction("SET_ASSESSMENT_ASSETS");
export const setAPICallInProgress = createAction("SET_API_CALL_INPROGRESS");
export const resetAsset = createAction("REMOVE_ASSESSMENT_ASSET");
export const resetPrevAssignmentCreationError = createAction(
  "RESET_PREV_ASSIGNEMENT_CREATION_ERROR"
);
export const listGradesByAssignment = createAction("LIST_GRADES_BY_ASSIGNMENT");

// takes payload: { selector, operation, resetData }
export const requestActionAssignmentDashboard = createAction("REQUEST_ACTION_ASSIGNMENT_DASHBOARD");
// takes payload: { selector, operation, errorItem }
export const rejectActionAssignmentDashboard = createAction("REJECT_ACTION_ASSIGNMENT_DASHBOARD");
// takes payload: { selector, items, appendRecords }
export const listActionAssignmentDashboard = createAction("LIST_ACTION_ASSIGNMENT_DASHBOARD");
export const listCourseAssessmentCompletionData = createAction("LIST_COURSE_ASSESSMENT_COMPLETION");
export const rejectCourseAssessmentCompletionData = createAction(
  "REJECT_COURSE_ASSESSMENT_COMPLETION"
);
export const listCourseAssessmentData = createAction("LIST_COURSE_ASSESSMENT_DATA");
export const rejectCourseAssessmentData = createAction("REJECT_COURSE_ASSESSMENT_DATA");
export const courseAssessmentDashboardStudentNTopicBreakDown = createAction(
  "COURSE_ASSESSMENT_DASHBOARD_STUDNET_N_TOPIC_BREAKDOWN"
);

export const noop = createAction("NOOP");

const mapUserResponseToUserList = (data, userList) => {
  return data
    .filter(
      (responseUser) =>
        userList.findIndex((stateUser) => stateUser.user_name === responseUser.username) > -1
    )
    .map((item) => {
      const user = userList.find((userDetails) => userDetails.user_name == item.username);
      return user
        ? {
            id: item.username,
            name: user.name + " " + user.family_name,
            role: item.role,
            email: user.email,
            sectionId: item.section_id,
            isActive: item.is_active,
          }
        : {};
    });
};

const mapUserResponseToStateUserList = (data, userList) => {
  return data.map((item) => {
    const user = userList.find((userDetails) => userDetails.id === item.username);

    return {
      id: item.username,
      name: user.firstName + " " + user.lastName,
      role: item.role,
      email: user.email,
      sectionId: item.section_id,
      isActive: item.is_active,
    };
  });
};

/**
 ********** ACTIONS ***********
 */

/*
 ********** SECTIONS ***********
 */
export const getSections = ({ page = 1, pageSize = 10 } = {}) => {
  const selector = "sections";
  const operation = OPERATIONS.LIST;
  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());
    dispatch(
      requestActionCourse({
        selector,
        operation,
        resetData: true,
      })
    );

    try {
      const response = await getSectionsAPI({ accountId });

      if (response.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: response.data.map(decorateSectionResponse),
          })
        );
      } else {
        rejectActionCourse({
          selector,
          operation,
          errorItem: { message: response.data },
        });
      }

      return response;
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: e,
        })
      );
    }
  };
};

export const getSectionById = ({ courseId }) => {
  const selector = "sections";
  const operation = OPERATIONS.GET;

  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation }));
    const accountId = getUserCurrentAccount(getState());

    try {
      const sectionResponse = await getSectionByIdAPI({
        accountId,
        sectionId: courseId,
      });

      if (sectionResponse.status === "success") {
        const course = decorateSectionResponse(sectionResponse.data);

        dispatch(
          listActionCourse({
            selector,
            items: course,
          })
        );
        dispatch(setActiveCourse(course));
      } else {
        rejectActionCourse({
          selector,
          operation,
          errorItem: sectionResponse.data,
        });
      }

      return sectionResponse;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: e,
        })
      );
      return false;
    }
  };
};

export const createSection = (formValue) => {
  const selector = "sections";
  const operation = OPERATIONS.CREATE;

  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.CREATE }));

    const state = getState();
    const accountId = getUserCurrentAccount(state);

    try {
      const request = decorateSectionRequest(formValue);
      const createSectionResponse = await createSectionAPI(request, accountId);

      if (createSectionResponse.status === "success") {
        dispatch(
          listActionCourse(
            decorateSectionResponse({
              selector,
              items: decorateSectionResponse(createSectionResponse.data),
            })
          )
        );
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation,
            errorItem: createSectionResponse.data,
          })
        );
      }
      return createSectionResponse;
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: e,
        })
      );
      return false;
    }
  };
};

export const copySection = ({ id: sectionId, name: sectionName }) => {
  const selector = "sections";
  const operation = OPERATIONS.COPY;

  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation }));
    dispatch(
      addNotification({
        notification: createNotification(
          LEVELS.INFO,
          "Copying...",
          `Creating a copy of "${sectionName}"`,
          0 // manual dismiss required
        ),
      })
    );

    const state = getState();
    const accountId = getUserCurrentAccount(state);

    try {
      const copySectionResponse = await copySectionAPI({ accountId, sectionId });

      if (copySectionResponse.status === "success") {
        const { units, ...section } = copySectionResponse.data;

        // Update store with created Section from Section copy
        dispatch(
          listActionCourse({
            selector: "sections",
            items: decorateSectionResponse(section),
          })
        );

        // Update store with created Units from Section copy
        if (units.length) {
          units.forEach(({ assignments, ...unit }) => {
            dispatch(
              listActionCourse({
                selector: "units",
                items: decorateUnitResponse(unit),
              })
            );

            // Update store with created Assignments from Section copy
            if (assignments.length) {
              assignments.forEach((assignment) => {
                dispatch(
                  listActionCourse({
                    selector: "assignments",
                    items: decorateAssignmentResponse(assignment),
                  })
                );
              });
            }
          });
        }
        // Success Notification for Copy
        await dispatch(clearNotifications());
        await dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              "Success",
              `You have successfully copied "${sectionName}"`
            ),
          })
        );
      } else {
        // Error Notification for Copy
        await dispatch(clearNotifications());
        await dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.ERROR,
              "Error",
              `There was a problem copying "${sectionName}"`
            ),
          })
        );
        dispatch(
          rejectActionCourse({
            selector,
            operation,
          })
        );
      }

      return copySectionResponse;
    } catch (e) {
      // Error Notification for Copy
      dispatch(clearNotifications());
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            "Error",
            `There was a problem copying "${sectionName}"`
          ),
        })
      );
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: e,
        })
      );
      return false;
    }
  };
};

export const updateSection = (formValue) => {
  const selector = "sections";
  const operation = OPERATIONS.UPDATE;

  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.UPDATE }));

    const state = getState();
    const accountId = getUserCurrentAccount(state);
    const errorObj = {
      selector,
      operation,
      errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
    };

    try {
      const request = decorateSectionRequest(formValue);
      const updateSectionResponse = await updateSectionAPI(request, formValue.id, accountId);

      if (updateSectionResponse.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: decorateSectionResponse(updateSectionResponse.data),
          })
        );
      } else {
        dispatch(
          rejectActionCourse({
            ...errorObj,
            errorItem: updateSectionResponse.data,
          })
        );
      }

      return updateSectionResponse;
    } catch (e) {
      dispatch(
        rejectActionCourse({
          ...errorObj,
          errorItem: e,
        })
      );
      return false;
    }
  };
};

/*
 ********** ASSIGNMENTS ***********
 */
export const getAssignments = ({ page = 1, pageSize = 10, sectionIds } = {}) => {
  const selector = "assignments";
  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());
    dispatch(
      requestActionCourse({
        selector,
        operation: OPERATIONS.GET,
        resetData: false,
      })
    );

    try {
      const assignmentsResponse = await getAssignmentsAPI({ accountId, sectionIds });

      if (assignmentsResponse.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: assignmentsResponse.data.map(decorateAssignmentResponse),
          })
        );
        return assignmentsResponse;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
    }
  };
};

export const getAssignmentAttempts = ({
  page = 1,
  pageSize = 10,
  assignmentIds = [],
  requestAction = requestActionCourse,
  listAction = listActionCourse,
  rejectAction = rejectActionCourse,
} = {}) => {
  const selector = "attempts";

  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());

    dispatch(
      requestAction({
        selector,
        operation: OPERATIONS.GET,
        resetData: false,
      })
    );

    try {
      const assignmentAttemptsResponse = await Promise.all(
        assignmentIds.map((assignmentId) => getAssignmentAttemptsAPI({ accountId, assignmentId }))
      );

      if (assignmentAttemptsResponse.every((response) => response.status === "success")) {
        const attempts = assignmentAttemptsResponse.reduce(
          (attempts, currentAssignmentResponse) => {
            const flattenedAssignmntAttempts = currentAssignmentResponse.data;
            return attempts.concat(flattenedAssignmntAttempts);
          },
          []
        );

        const items = attempts.map((attempt) => {
          return {
            id: attempt.id,
            assessmentId: attempt.assessment_id,
            assessmentSeriesId: attempt.assessment_series_id,
            assignmentId: attempt.assignment_id,
            attemptNumber: attempt.attempt_number,
            endTime: attempt.end_time,
            questionsCorrect: attempt.questions_correct,
            result: attempt.result,
            score: attempt.score,
            startTime: attempt.start_time,
            username: attempt.username,
          };
        });

        dispatch(
          listAction({
            selector,
            items,
          })
        );
        return assignmentAttemptsResponse;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectAction({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
    }
  };
};

export const getAssignmentAttemptsAnalytics = ({ page = 1, pageSize = 0, assignmentId } = {}) => {
  return async (dispatch, getState) => {
    const selector = "attemptsAnalytics";
    const accountId = getUserCurrentAccount(getState());

    dispatch(
      requestActionAssignmentDashboard({
        selector,
        operation: OPERATIONS.GET,
        resetData: false,
      })
    );

    try {
      const numberOfAttemptsResponse = await getNumberOfAssignmentAttemptsAPI({
        assignmentId,
        accountId,
      });

      if (numberOfAttemptsResponse.status === "success") {
        const bucketedAttempts = numberOfAttemptsResponse.data.reduce(
          (buckets, { attempts, student_count }) => {
            const attemptCount = attempts < 4 ? attempts : 4;

            return {
              ...buckets,
              [attemptCount]: {
                attempts: buckets[attemptCount].attempts,
                studentCount: buckets[attemptCount].studentCount + student_count,
              },
            };
          },
          {
            0: { attempts: "0", studentCount: 0 },
            1: { attempts: "1", studentCount: 0 },
            2: { attempts: "2", studentCount: 0 },
            3: { attempts: "3", studentCount: 0 },
            4: { attempts: "4+", studentCount: 0 },
          }
        );

        const items = decorateAssignmentDashboardResponse(bucketedAttempts, false);

        dispatch(
          listActionAssignmentDashboard({
            selector,
            items,
          })
        );

        return items;
      }
      dispatch(rejectActionAssignmentDashboard({ selector }));
      return false;
    } catch (error) {
      console.log({ error });
      dispatch(rejectActionAssignmentDashboard({ selector }));
    }
  };
};

export const getAssignmentGrades = ({ sectionId }) => {
  return async (dispatch, getState) => {
    const selector = "gradesByStudent";
    const accountId = getUserCurrentAccount(getState());

    dispatch(
      requestActionCourse({
        selector,
        operation: OPERATIONS.GET,
        resetData: false,
      })
    );

    try {
      const assignmentGradesResponse = await getAssignmentGradesAPI({ accountId, sectionId });
      if (assignmentGradesResponse.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: Object.values(assignmentGradesResponse.data).map((studentGradebook) => ({
              ...studentGradebook,
              id: studentGradebook.student.user_name,
            })),
          })
        );
        return assignmentGradesResponse;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
    }
  };
};

export const getAssignmentById = ({ sectionId, assignmentId } = {}) => {
  const selector = "assignments";
  const operation = OPERATIONS.GET;
  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation }));

    const accountId = getUserCurrentAccount(getState());

    try {
      const schedulePromise = dispatch(
        getScheduleForSelectedAssignmentById({ activeAssignmentId: assignmentId })
      );

      const assignmentPromise = getAssignmentByIdAPI({
        accountId,
        sectionId,
        assignmentId,
      });

      const [assignmentResponse, schedule] = await Promise.all([
        assignmentPromise,
        schedulePromise,
      ]);

      if (assignmentResponse.status === "success") {
        const assignment = decorateAssignmentResponse(assignmentResponse.data);

        dispatch(
          listActionCourse({
            selector,
            items: assignment,
          })
        );

        return { ...assignment, ...schedule };
      } else {
        rejectActionCourse({
          selector,
          operation,
          errorItem: assignmentResponse.data,
        });
      }

      return assignmentResponse;
    } catch (error) {
      console.log(error.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: error,
        })
      );
      return false;
    }
  };
};

export const getAssignmentsBySectionId = ({ courseId }) => {
  return async (dispatch, getState) => {
    const selector = "assignments";
    const accountId = getUserCurrentAccount(getState());
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.GET }));

    const state = getState();

    try {
      const assignmentResponse = await getAssignmentsBySectionIdAPI({
        accountId,
        sectionId: courseId,
      });

      if (assignmentResponse.status === "success") {
        const scheduleResponse = state.storedCourses.schedules.length
          ? true
          : //commenting as this API will not be ready for demo
            //   : await getScheduleBySectionIdAPI({
            //       accountId,
            //       sectionId: courseId,
            //     });
            await getSchedulesAPI({ accountId, sectionIds: [courseId] });

        scheduleResponse.status &&
          scheduleResponse.status === "success" &&
          dispatch(
            listActionCourse({
              items: assignmentResponse.data.map(decorateAssignmentResponse),
              selector,
            })
          );

        return assignmentResponse;
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.GET,
            errorItem: assignmentResponse.data,
          })
        );
        return assignmentResponse;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const createAssignment = ({ activeUnitId, formData }) => {
  return async (dispatch, getState) => {
    const selector = "assignments";

    // Initiate request for load state
    dispatch(
      requestActionCourse({
        selector,
        operation: OPERATIONS.CREATE,
        resetData: false,
      })
    );

    const state = getState();
    const accountId = getUserCurrentAccount(state);
    const { activeCourseId } = state.storedCourses;

    const errorObj = {
      selector,
      operation: OPERATIONS.CREATE,
      errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
    };

    try {
      const requestMap = { accountId, activeCourseId, activeUnitId, ...formData };
      const request = decorateAssignmentRequest(requestMap);
      const createAssignmentResponse = await createAssignmentAPI(request, accountId);

      if (createAssignmentResponse.status === "success") {
        await dispatch(
          createSchedule({
            startDate: formData.startDate,
            endDate: formData.endDate,
            assignmentId: createAssignmentResponse.data.id,
            sectionId: activeCourseId,
          })
        );
        dispatch(
          listActionCourse({
            selector,
            items: decorateAssignmentResponse(createAssignmentResponse.data),
          })
        );

        return true;
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.CREATE,
            errorItem: createAssignmentResponse.data,
          })
        );

        return false;
      }
    } catch (error) {
      console.log(error);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.CREATE,
          errorItem: errorObj,
        })
      );
      return false;
    }
  };
};

export const deleteAssignment = (assignmentId) => {
  return async (dispatch, getState) => {
    const selector = "assignments";
    const accountId = getUserCurrentAccount(getState());

    dispatch(requestActionCourse({ selector, operation: OPERATIONS.DELETE, resetData: false }));

    try {
      const response = await deleteAssignmentAPI({ accountId, assignmentId });

      if (response.status === "success") {
        dispatch(deleteActionCourse({ selector, itemId: assignmentId }));
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.DELETE,
            errorItem: response.data,
          })
        );
      }
      return response;
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.DELETE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

/*
 ********** SCHEDULES ***********
 */
export const getSchedules = ({ page = 1, pageSize = 10, sectionIds = [] }) => {
  const selector = "schedules";

  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());
    dispatch(
      requestActionCourse({
        selector,
        operation: OPERATIONS.GET,
        resetData: false,
      })
    );

    try {
      const response = await getSchedulesAPI({ accountId, sectionIds });

      if (response.status === "success") {
        const items = response.data.map((item) => convertKeysToCamelCase(item));
        dispatch(
          listActionCourse({
            selector,
            items,
          })
        );
        return items;
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.LIST,
            errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
          })
        );
      }
      return response;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
    }
  };
};

const createSchedule = (scheduleData) => {
  return async (dispatch, getState) => {
    // Initiate request for load state
    const selector = "schedules";
    const state = getState();
    const userId = state.user.username;
    const accountId = getUserCurrentAccount(state);
    const errorObj = {
      selector,
      operation: OPERATIONS.CREATE,
      errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
    };

    const requestBody = {
      ...scheduleData,
      startDate: moment(scheduleData.startDate)
        .startOf("day")
        .utc()
        .format(),
      endDate: moment(scheduleData.endDate)
        .endOf("day")
        .utc()
        .format(),
      startTs: moment(scheduleData.startDate)
        .startOf("day")
        .utc()
        .format(),
      endTs: moment(scheduleData.endDate)
        .endOf("day")
        .utc()
        .format(),
      isRecurring: false,
      isFullDay: false,
    };

    dispatch(requestActionCourse({ selector, operation: OPERATIONS.CREATE, resetData: false }));

    try {
      const createScheduleResponse = await createScheduleAPI({
        accountId,
        request: convertKeysToUnderscore(requestBody),
      });

      if (createScheduleResponse.status === "success") {
        dispatch(
          listActionCourse({ selector, items: convertKeysToCamelCase(createScheduleResponse.data) })
        );
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.CREATE,
            errorItem: errorObj,
          })
        );
      }
      return createScheduleResponse;
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.CREATE,
          errorItem: errorObj,
        })
      );
      return false;
    }
  };
};

/*
 ********** UNITS ***********
 */
export const getUnitsBySectionId = ({ accountId, courseId, selector }) => {
  return async (dispatch) => {
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.GET }));
    try {
      const unitResponse = await getUnitsBySectionIdAPI({
        accountId,
        sectionId: courseId,
      });

      if (unitResponse.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: unitResponse.data.map(decorateUnitResponse),
          })
        );
        return unitResponse;
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.GET,
            errorItem: unitResponse.data,
          })
        );
        return unitResponse;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const createUnit = ({ unit, section }) => {
  const selector = "units";

  return async (dispatch, getState) => {
    try {
      dispatch(requestActionCourse({ selector, operation: OPERATIONS.CREATE, resetData: false }));

      const accountId = getUserCurrentAccount(getState());
      const response = await createUnitAPI({
        unit: convertKeysToUnderscore(unit),
        accountId,
      });

      if (response.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: decorateUnitResponse(response.data),
          })
        );
        return true;
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.CREATE,
            errorItem: response.data,
          })
        );
        return false;
      }
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.CREATE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const updateUnit = (unit) => {
  const selector = "units";

  return async (dispatch, getState) => {
    try {
      const accountId = getUserCurrentAccount(getState());
      dispatch(requestActionCourse({ selector, operation: OPERATIONS.UPDATE, resetData: false }));

      const formattedUnit = decorateUnitRequest(unit);
      const { id, ...restOfUnit } = formattedUnit;
      const response = await updateUnitAPI(restOfUnit, id, accountId);

      if (response.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: decorateUnitResponse(response.data),
          })
        );
        return true;
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.UPDATE,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.UPDATE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const deleteSection = (sectionId) => {
  const selector = "sections";
  return async (dispatch, getState) => {
    try {
      const accountId = getUserCurrentAccount(getState());
      dispatch(requestActionCourse({ selector, operation: OPERATIONS.DELETE, resetData: false }));
      let response = await deleteSectionAPI({ sectionId, accountId });
      if (response.status === "success") {
        dispatch(removeActionCourse({ selector, id: sectionId }));
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.DELETE,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.DELETE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
    }
  };
};

export const deleteUnit = (unitId) => {
  return async (dispatch, getState) => {
    const selector = "units";
    const accountId = getUserCurrentAccount(getState());

    dispatch(requestActionCourse({ selector, operation: OPERATIONS.DELETE, resetData: false }));

    try {
      const response = await deleteUnitAPI({ accountId, unitId });

      if (response.status === "success") {
        dispatch(deleteActionCourse({ selector, itemId: unitId }));
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.DELETE,
            errorItem: response.data,
          })
        );
      }
      return response;
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.DELETE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

/*
 ********** DISCIPLINES ***********
 */
export const getAllDisciplines = () => {
  return async (dispatch, getState) => {
    const selector = "disciplines";

    dispatch(requestActionCourse({ selector, operation: OPERATIONS.GET }));
    try {
      const state = getState();
      const accountId = getUserCurrentAccount(state);

      const response = await getAllDisciplinesAPI({ accountId });

      if (response.status === "success") {
        dispatch(
          listActionCourse({ selector, items: decorateGetAllDisciplinesResponse(response.data) })
        );
        return response;
      } else {
        dispatch(
          rejectActionCourse({ selector, operation: OPERATIONS.GET, errorItem: response.data })
        );
        return response;
      }
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

/*
 ********** USERS ***********
 */
export const getAssignedCourseUsers = ({ courseId }) => {
  return async (dispatch, getState) => {
    const selector = "assignedInstructors";
    dispatch(
      requestActionCourse({
        selector,
        operation: OPERATIONS.GET,
      })
    );
    try {
      const state = getState();
      const accountId = getUserCurrentAccount(state);

      const response = await getAssignedCourseUsersAPI({ accountId, courseId });

      if (response.status === "success") {
        const userList = state.administration.users.length
          ? state.administration.users
          : await getUsersForAccount(accountId, 1, -1);

        const userListWithAddedRelationship = !userList.data
          ? userList
          : userList.data.rows
          ? userList.data.rows
          : [];

        if (userListWithAddedRelationship) {
          dispatch(
            listActionCourse({
              selector,
              items: mapUserResponseToUserList(response.data, userListWithAddedRelationship),
            })
          );
        }
        return response;
      } else {
        dispatch(
          rejectActionCourse({ selector, operation: OPERATIONS.GET, errorItem: response.data })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const addUsersToCourse = ({
  selectedItems,
  accountId,
  courseId,
  userRole,
  selector,
}) => async (dispatch, getState) => {
  dispatch(requestActionCourse({ selector, operation: OPERATIONS.GET }));
  try {
    const state = getState();
    const decorateAddUsersRequest = selectedItems.map((item) => {
      return {
        username: item.id,
        role: userRole,
      };
    });

    const response = await addUsersToCourseAPI({
      accountId,
      courseId,
      decorateAddUsersRequest,
      selectedItems,
    });

    if (response.status === "success") {
      const userList = state.administration.users.length
        ? state.administration.users
        : await getUsersForAccount(accountId, 1, -1);

      const userListWithAddedRelationship = !userList.data
        ? mapUserResponseToStateUserList(response.data.data, userList)
        : userList.data.rows
        ? mapUserResponseToUserList(response.data.data, userList.data.rows)
        : [];

      if (userListWithAddedRelationship) {
        dispatch(
          listActionCourse({
            selector: selector,
            items: userListWithAddedRelationship,
          })
        );
        dispatch(removeAll({ selector: "selectedUsers" }));
        return true;
      }
    } else {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.REMOVE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
        })
      );
      return false;
    }

    return true;
  } catch (error) {
    console.log({ error });
    dispatch(
      rejectActionCourse({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
      })
    );
    return false;
  }
};

export const removeUserFromCourse = ({ accountId, courseId, userName, userRole }) => async (
  dispatch
) => {
  const selector = "assignedInstructors";
  const operation = OPERATIONS.REMOVE;
  dispatch(requestActionCourse({ selector, operation }));

  try {
    const response = await removeUserFromCourseAPI({ accountId, courseId, userName, userRole });

    if (response.status === "success") {
      dispatch(removeActionCourse({ selector, id: userName }));
    } else {
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {
    dispatch(
      rejectActionCourse({
        selector,
        operation,
        errorItem: e,
      })
    );
  }
};

export const getEnrolledCourseStudents = ({ courseId, pageSize = 0 }) => {
  return async (dispatch, getState) => {
    const selector = "assignedStudents";
    dispatch(
      requestActionCourse({
        selector,
        operation: OPERATIONS.GET,
        resetData: true,
      })
    );

    try {
      const state = getState();
      const accountId = getUserCurrentAccount(state);

      let response = await getEnrolledCourseUsersAPI({ accountId, courseId, pageSize });

      if (response.status === "success") {
        //TODO: Move user mapping in common function
        const userList = state.administration.users.length
          ? state.administration.users
          : await getUsersForAccount(accountId, 1, -1);

        const userListWithAddedRelationship = !userList.data
          ? mapUserResponseToStateUserList(response.data, userList)
          : userList.data.rows
          ? mapUserResponseToUserList(response.data, userList.data.rows)
          : [];

        if (userListWithAddedRelationship) {
          dispatch(
            listActionCourse({
              selector,
              items: userListWithAddedRelationship,
            })
          );
        }
        return response;
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.GET,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const enrollCourseStudents = ({ selectedItems, accountId, courseId }) => async (
  dispatch,
  getState
) => {
  const selector = "assignedStudents";
  dispatch(requestActionCourse({ selector, operation: OPERATIONS.ATTACH_TO_ENTITY }));

  try {
    const state = getState();
    const selectedStudents = decorateEnrollUsersRequest(selectedItems);

    let response = await enrollCourseStudentsAPI({
      accountId,
      courseId,
      users: selectedStudents,
    });

    if (response.status && response.status.length > 0) {
      const enrolledStudents = response.data.filter((student) =>
        response.status.filter(
          (s) => s.username === student.username && s.status === "user enrolled successful"
        )
      );

      const userList = state.administration.users.length
        ? state.administration.users
        : await getUsersForAccount(accountId, 1, -1);

      const userListWithAddedRelationship = !userList.data
        ? mapUserResponseToStateUserList(enrolledStudents, userList)
        : userList.data.rows
        ? mapUserResponseToUserList(enrolledStudents, userList.data.rows)
        : [];

      if (userListWithAddedRelationship) {
        dispatch(
          listActionCourse({
            selector: selector,
            items: userListWithAddedRelationship,
          })
        );
      }

      dispatch(removeAll({ selector: "selectedUsers" }));
      return true;
    } else {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.ATTACH_TO_ENTITY,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
        })
      );
      return false;
    }
  } catch (e) {
    dispatch(
      rejectActionCourse({
        selector,
        operation: OPERATIONS.ATTACH_TO_ENTITY,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
      })
    );
  }
};

export const removeEnrolledCourseStudents = ({ accountId, courseId, username }) => async (
  dispatch
) => {
  const selector = "assignedStudents";
  const operation = OPERATIONS.DEACTIVATE;
  dispatch(requestActionCourse({ selector, operation }));

  try {
    const response = await removeEnrolledCourseStudentsAPI({
      accountId,
      courseId,
      username,
    });

    if (response.status === "success") {
      dispatch(
        updateCourseItem({ selector, operation, id: username, update: { isActive: false } })
      );
    } else {
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {
    dispatch(
      rejectActionCourse({
        selector,
        operation,
        errorItem: e,
      })
    );
  }
};

/*
 ********** ASSESSMENT, VIDEO DETAILS ***********
 */
export const getVideoDetailsById = ({ videoId }) => {
  return async (dispatch) => {
    const selector = "videoDetails";
    const accountId = "1";
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.GET }));

    try {
      const videoDetailsResponse = await getVideoDetailsByIdAPI({ accountId, id: videoId });
      dispatch(setAPICallInProgress(false));
      return videoDetailsResponse.status === "success"
        ? decorateGetVideoDetailsByIdResponse(videoDetailsResponse.data)
        : [];
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return [];
    }
  };
};

export const getAssessmentDetailsById = ({ id, selector }) => {
  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.GET }));
    const accountId = getUserCurrentAccount(getState());

    try {
      const assessmentDetailsResponse = await getAssessmentDetailsByIdAPI({ accountId, id });

      if (assessmentDetailsResponse.status === "success") {
        const items = decorateGetAssessmentDetailsByIdResponse(
          assessmentDetailsResponse.data.assessments[0]
        );

        dispatch(
          listActionCourse({
            selector,
            items,
          })
        );
        return items;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getVideos = () => {
  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector: "videos", operation: OPERATIONS.LIST }));
    dispatch(requestActionCourse({ selector: "concepts", operation: OPERATIONS.LIST }));

    try {
      const accountId = getUserCurrentAccount(getState());
      const response = await getAllVideosAPI({ accountId });

      if (response.status === "success") {
        dispatch(listActionCourse({ selector: "videos", items: response.data.videos }));
        dispatch(
          listActionCourse({
            selector: "concepts",
            // TODO: the api is returning arrray of concepts but calling it "concept" singula
            items: response.data.concept.map((c) => ({ ...c, id: c.tag_id })),
          })
        );
        return response;
      } else {
        dispatch(
          rejectActionCourse({
            selector: "videos",
            operation: OPERATIONS.LIST,
            errorItem: response.data,
          })
        );
        dispatch(
          rejectActionCourse({
            selector: "concepts",
            operation: OPERATIONS.LIST,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector: "videos",
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      dispatch(
        rejectActionCourse({
          selector: "concepts",
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getAssessments = () => {
  return async (dispatch, getState) => {
    const selector = "assessments";
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.GET }));

    try {
      const accountId = getUserCurrentAccount(getState());
      const response = await getAllAssessments({ accountId });

      if (response.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: response.data.assessments.map((a) => ({ ...a, id: a.assessment_id })),
          })
        );
        return response;
      } else {
        dispatch(
          rejectActionCourse({ selector, operation: OPERATIONS.GET, errorItem: response.data })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getVideoQuizAverageCorrectAnswers = (assignmentId) => async (dispatch, getState) => {
  const selector = "videoQuizAverageCorrectAnswers";
  dispatch(requestActionAssignmentDashboard({ selector, operation: OPERATIONS.GET }));
  const accountId = getUserCurrentAccount(getState());
  try {
    const response = await getVideoQuizAverageCorrectAnswersAPI({
      accountId,
      assignmentId,
    });
    if (response.status === "success") {
      return convertKeysToCamelCase(decorateVideoQuizAverageCorrectAnswers(response.data));
    }
    return false;
  } catch (e) {
    console.log(e.message);
    dispatch(
      rejectActionAssignmentDashboard({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const getAverageTime = (assignmentId) => async (dispatch, getState) => {
  const selector = "avgTime";
  dispatch(requestActionAssignmentDashboard({ selector, operation: OPERATIONS.LIST }));
  const accountId = getUserCurrentAccount(getState());
  try {
    let response = await getAssignmentDashboardAverageTimeAPI({
      accountId,
      assignmentId,
    });
    if (response.status === "success") {
      const duration = response.data.avg_duration;
      const roundedMinutes = Math.floor(duration);
      const roundedSeconds = Math.round((duration - roundedMinutes) * 60);
      const roundedAverageTime = duration
        ? `${padTime(roundedMinutes)}:${padTime(roundedSeconds)}`
        : null;

      dispatch(
        listActionAssignmentDashboard({
          selector,
          items: decorateAssignmentDashboardResponse(roundedAverageTime, false),
        })
      );
    } else {
      dispatch(
        rejectActionAssignmentDashboard({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {}
};

export const getVideoQuizStudentBreakdownData = ({
  assignmentId,
  page = 1,
  pageSize = 0,
  appendRecords = false,
}) => {
  const selector = "videoQuizStudentBreakdown";
  const operation = OPERATIONS.GET;

  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());

    try {
      const response = await getVideoQuizStudentBreakdownAPI({
        assignmentId,
        accountId,
      });

      if (response.status === "success") {
        return response.data.map((student) => convertKeysToCamelCase(student));
      }

      return false;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionAssignmentDashboard({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getVideoStudentBreakdownData = ({
  assignmentId,
  page = 1,
  pageSize = 0,
  appendRecords = false,
}) => {
  const selector = "videoStudentBreakdown";
  const operation = OPERATIONS.GET;

  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());

    try {
      const response = await getVideoStudentBreakdownAPI({
        assignmentId,
        accountId,
      });
      if (response.status === "success") {
        return response.data.map((student) => convertKeysToCamelCase(student));
      }
      return false;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionAssignmentDashboard({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const updateAssignment = ({ activeUnitId, formData, activeAssignmentId }) => {
  const selector = "assignments";
  const operation = OPERATIONS.UPDATE;

  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation }));
    const accountId = getUserCurrentAccount(getState());
    const { activeCourseId } = getState().storedCourses;

    try {
      const request = decorateAssignmentRequest({
        accountId,
        activeCourseId,
        activeUnitId,
        ...formData,
      });

      const updateAssignmentResponse = await updateAssignmentAPI(
        request,
        activeAssignmentId,
        accountId
      );

      if (updateAssignmentResponse.status === "success") {
        await dispatch(
          updateScheduleForSelectedAssignmentById({
            startDate: formData.startDate,
            endDate: formData.endDate,
            assignmentId: updateAssignmentResponse.data.id,
            sectionId: activeCourseId,
            instructorId: getState().user.instructorId,
          })
        );
        dispatch(
          listActionCourse({
            selector,
            items: decorateAssignmentResponse(updateAssignmentResponse.data),
          })
        );
      } else {
        rejectActionCourse({
          selector,
          operation,
          errorItem: updateAssignmentResponse.data,
        });
      }
      return updateAssignmentResponse;
    } catch (error) {
      console.log(error.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: error,
        })
      );
      return false;
    }
  };
};

export const getScheduleForSelectedAssignmentById = ({ activeAssignmentId }) => {
  const selector = "schedules";
  const operation = OPERATIONS.GET;
  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation }));

    const accountId = getUserCurrentAccount(getState());

    try {
      const getScheduleResponse = await getScheduleForSelectedAssignmentAPI({
        assignmentIdArray: activeAssignmentId,
        accountId,
      });

      if (getScheduleResponse.status === "success") {
        const schedule = getScheduleResponse.data.map((item) => convertKeysToCamelCase(item))[0];

        dispatch(
          listActionCourse({
            selector,
            items: schedule,
          })
        );

        return schedule;
      } else {
        rejectActionCourse({
          selector,
          operation,
          errorItem: getScheduleResponse.data,
        });
      }

      return getScheduleResponse;
    } catch (error) {
      console.log(error.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: error,
        })
      );
      return false;
    }
  };
};

const updateScheduleForSelectedAssignmentById = (scheduleData) => {
  return async (dispatch, getState) => {
    const selector = "schedules";
    const accountId = getUserCurrentAccount(getState());
    const errorObj = {
      selector,
      operation: OPERATIONS.UPDATE,
      errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
    };

    const requestBody = {
      ...scheduleData,
      startDate: moment(scheduleData.startDate)
        .startOf("day")
        .utc()
        .format(),
      endDate: moment(scheduleData.endDate)
        .endOf("day")
        .utc()
        .format(),
      startTs: moment(scheduleData.startDate)
        .startOf("day")
        .utc()
        .format(),
      endTs: moment(scheduleData.endDate)
        .endOf("day")
        .utc()
        .format(),
      isRecurring: false,
      isFullDay: false,
    };

    dispatch(requestActionCourse({ selector, operation: OPERATIONS.UPDATE, resetData: false }));

    try {
      const updateScheduleResponse = await updateScheduleForSelectedAssignmentAPI({
        request: convertKeysToUnderscore(requestBody),
        assignmentId: scheduleData.assignmentId,
        accountId,
      });

      if (updateScheduleResponse.status === "success") {
        dispatch(
          listActionCourse({ selector, items: convertKeysToCamelCase(updateScheduleResponse.data) })
        );
      } else {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.UPDATE,
            errorItem: errorObj,
          })
        );
      }
      return updateScheduleResponse;
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.UPDATE,
          errorItem: errorObj,
        })
      );
      return false;
    }
  };
};

/*
 ********** GRADES ***********
 */
export const getSectionGradesByAssignment = ({ page = 1, pageSize = 0, sectionId }) => {
  const selector = "gradesByAssignment";

  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());
    dispatch(
      requestActionCourse({
        selector,
        operation: OPERATIONS.GET,
        resetData: false,
      })
    );

    try {
      const response = await getSectionGradesByAssignmentAPI({
        accountId,
        sectionId,
        page,
        pageSize,
      });

      if (response.status === "success") {
        dispatch(
          listGradesByAssignment({
            selector,
            items: response.data,
          })
        );
      } else if (response.status === "error") {
        dispatch(
          rejectActionCourse({
            selector,
            operation: OPERATIONS.LIST,
            errorItem: {
              message: response.data.message,
              errorCode: response.data.errorCode,
            },
          })
        );
      }
      return response;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
    }
  };
};

export const getAssignmentStudentCount = (assignmentId) => async (dispatch, getState) => {
  const selector = "studentTotalCount";
  dispatch(requestActionAssignmentDashboard({ selector, operation: OPERATIONS.LIST }));
  const accountId = getUserCurrentAccount(getState());
  try {
    let response = await getAssignmentDashboardStudentCountAPI({
      accountId,
      assignmentId,
    });
    if (response.status === "success") {
      dispatch(
        listActionAssignmentDashboard({
          selector,
          items: decorateAssignmentDashboardResponse(response.data.student_count, false),
        })
      );
    } else {
      dispatch(
        rejectActionAssignmentDashboard({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {}
};

export const getStandardDeviation = ({ assignmentId }) => async (dispatch, getState) => {
  const selector = "stdDev";
  dispatch(requestActionAssignmentDashboard({ selector, operation: OPERATIONS.LIST }));
  const accountId = getUserCurrentAccount(getState());
  try {
    let response = await getAssignmentStandardDeviationAPI({
      accountId,
      assignmentId,
    });
    if (response.status === "success") {
      const standardDeviation = response.data.std_dev;
      const roundedStandardDeviation = standardDeviation
        ? Number(standardDeviation).toPrecision(3)
        : null;

      dispatch(
        listActionAssignmentDashboard({
          selector,
          items: decorateAssignmentDashboardResponse(roundedStandardDeviation, false),
        })
      );
    } else {
      dispatch(
        rejectActionAssignmentDashboard({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {}
};
export const getCourseAssessmentCompletionData = ({ assignmentId } = {}) => {
  const selector = "assessmentDashboardCompletionDonutChart";
  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());
    try {
      const response = await getCourseAssessmentCompletionAPI({
        assignmentId,
        accountId,
      });

      if (response.status === "success") {
        // These values need to be numbers in order to display the donut chart correctly
        // Sometimes the API returns the values as a String (seems like only complete key for practice assignments)
        const items = {
          complete: Number(response.data.complete),
          incomplete: Number(response.data.incomplete),
        };
        dispatch(listCourseAssessmentCompletionData({ selector, items }));

        return items;
      } else {
        dispatch(
          listCourseAssessmentCompletionData({
            selector,
            items: { complete: 0, incomplete: 0 },
          })
        );
        return {};
      }
    } catch (error) {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
    }
  };
};

export const getCourseAssessmentScoreDistributionData = ({ assignmentId, questionCount }) => async (
  dispatch,
  getState
) => {
  const selector = "scoreDistribution";
  const accountId = getUserCurrentAccount(getState());

  try {
    const response = await getCourseAssessmentScoreDistributionAPI({
      assignmentId,
      accountId,
    });

    if (response.status === "success") {
      const items = Array.from({ length: questionCount + 1 }, (column, index) => {
        const graphObj = response.data.histogram.find((obj) => obj.score === index);

        return {
          studentCount: graphObj?.student_count || 0,
          score: graphObj?.score || index,
        };
      });

      dispatch(listCourseAssessmentData({ selector, items }));
      return items;
    } else {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  } catch (error) {
    dispatch(
      rejectActionCourse({
        selector,
        operation: OPERATIONS.LIST,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const getStudentCount = (assignmentId) => async (dispatch, getState) => {
  const selector = "studentTotalCount";
  dispatch(requestActionAssignmentDashboard({ selector, operation: OPERATIONS.LIST }));
  const accountId = getUserCurrentAccount(getState());
  try {
    let response = await getAssignmentDashboardStudentCountAPI({
      accountId,
      assignmentId,
    });
    if (response.status === "success") {
      dispatch(
        listActionAssignmentDashboard({
          selector,
          items: decorateAssignmentDashboardResponse(response.data.student_count, false),
        })
      );
    } else {
      dispatch(
        rejectActionAssignmentDashboard({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {}
};

export const getAverageScore = ({ assignmentId }) => async (dispatch, getState) => {
  const selector = "avgScore";
  dispatch(requestActionAssignmentDashboard({ selector, operation: OPERATIONS.LIST }));
  const accountId = getUserCurrentAccount(getState());
  try {
    let response = await getAssignmentDashboardAverageScoreAPI({
      accountId,
      assignmentId,
    });

    if (response.status === "success") {
      const averageScore = response.data.avg_score;
      const roundedAverageScore = averageScore ? Math.round(averageScore) : null;

      dispatch(
        listActionAssignmentDashboard({
          selector,
          items: decorateAssignmentDashboardResponse(roundedAverageScore, false),
        })
      );
    } else {
      dispatch(
        rejectActionAssignmentDashboard({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: response.data,
        })
      );
    }
  } catch (e) {}
};

export const getCourseAssessmentTopicBreakdownData = ({
  assignmentId,
  page = 1,
  pageSize = 0,
  appendRecords = false,
}) => async (dispatch, getState) => {
  const selector = "assessmentDashboardTopicBreakDown";
  const accountId = getUserCurrentAccount(getState());
  try {
    const response = await getCourseAssessmentTopicBreakDownAPI({
      assignmentId,
      accountId,
      page,
      pageSize,
    });

    if (response.status === "success" && !isEmpty(response.data)) {
      const topicBreakdownData = response.data.map((topic) => {
        return {
          topicName: topic.topic_name,
          classPerformance: `${topic.class_performance}%`,
        };
      });
      dispatch(
        courseAssessmentDashboardStudentNTopicBreakDown({
          selector,
          data: topicBreakdownData,
          page,
          count: topicBreakdownData.length,
          operation: OPERATIONS.GET,
          appendRecords,
        })
      );
      return response.data;
    } else {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.GET,
          errorItem: {
            message: response.data.message,
            errorCode: response.data.errorCode,
          },
        })
      );
      return false;
    }
  } catch (error) {
    dispatch(
      rejectActionCourse({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
  }
};

export const getCourseAssessmentStudentBreakdownData = ({
  assignmentId,
  page = 1,
  pageSize = 0,
  appendRecords = false,
}) => async (dispatch, getState) => {
  const selector = "assessmentDashboardStudentBreakDown";
  const accountId = getUserCurrentAccount(getState());
  try {
    const response = await getCourseAssessmentStudentBreakDownAPI({
      assignmentId,
      accountId,
      page,
      pageSize,
    });

    if (response.status === "success" && !isEmpty(response.data)) {
      const studentBreakdownData = response.data.map((student) => {
        return {
          studentName: student.student_name,
          attempts: student.attempts,
          topScore: student.top_score,
          latestAttempt: student.latest_attempt,
          duration: student.duration,
        };
      });
      dispatch(
        courseAssessmentDashboardStudentNTopicBreakDown({
          selector,
          data: studentBreakdownData,
          page,
          count: studentBreakdownData.length,
          operation: OPERATIONS.GET,
          appendRecords,
        })
      );
      return studentBreakdownData;
    } else {
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.GET,
          errorItem: {
            message: response.data.message,
            errorCode: response.data.errorCode,
          },
        })
      );
      return false;
    }
  } catch (error) {
    dispatch(
      rejectActionCourse({
        selector,
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
  }
};

export const getVideoConceptsBreakdown = ({
  assignmentId,
  page = 1,
  pageSize = 0,
  appendRecords = false,
}) => async (dispatch, getState) => {
  const selector = "videoDashboardConceptsBreakdown";
  const operation = OPERATIONS.GET;
  const accountId = getUserCurrentAccount(getState());
  try {
    const response = await getVideoConceptsBreakdownAPI({
      assignmentId,
      accountId,
    });
    if (response.status === "success" && response.data.length > 0) {
      const videoConceptsBreakdownData = response.data.map((topic) => ({
        topicName: topic.topic_name,
        classPerformance: `${
          topic.class_performance !== null ? Math.round(topic.class_performance * 100) / 100 : 0
        }%`,
      }));
      dispatch(
        listActionAssignmentDashboard({
          selector,
          operation,
          items: videoConceptsBreakdownData,
        })
      );
      return videoConceptsBreakdownData;
    } else {
      dispatch(
        rejectActionAssignmentDashboard({
          selector,
          operation,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  } catch (error) {
    dispatch(
      rejectActionAssignmentDashboard({
        selector,
        operation,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const getVideoCompletionData = ({ assignmentId }) => {
  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());

    try {
      const response = await fetchVideoCompletionDataAPI({ accountId, assignmentId });

      if (response.status === "success") {
        return response.data;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
};

export const getVideoQuizCompletionData = ({ assignmentId }) => {
  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());

    try {
      const response = await fetchVideoQuizCompletionDataAPI({ accountId, assignmentId });

      if (response.status === "success") {
        return response.data;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
};

/**
 * DECORATORS
 */
const decorateGetAllDisciplinesResponse = (data) => {
  return data.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
};

export const decorateAssignmentResponse = (assignment) => {
  return {
    id: assignment.id,
    name: assignment.name,
    description: assignment.description,
    unitId: assignment.unit_id,
    assignmentType: assignment.assignment_type,
    assets: [],
    isDraft: false,
    isScheduled: false,
    sequence: assignment.sequence,
    cooldownDays: assignment.cooldown_days,
    attemptLimit: assignment.attempt_limit,
    timedIndicator: assignment.timed_indicator,
    timeLimit: assignment.time_limit,
    instantFeedbackIndicator: assignment.instant_feedback_indicator,
    gradedIndicator: assignment.graded_indicator,
    parentId: assignment.parent_id,
    assessmentId: assignment.assessment_id,
    videoId: assignment.video_id,
    link: assignment.link,
    sectionId: assignment.section_id,
    createdBy: assignment.created_by,
    modifiedBy: assignment.modified_by,
    createdOn: assignment.created_ts,
    modifiedOn: assignment.modified_ts,
    cutoffRange: assignment.cutoff_range,
    studentId: assignment.student_id,
    assessmentSeriesId: assignment.assessment_series_id,
    videoThumbnail: assignment.video_thumbnail,
    assignedUsers: assignment.assigned_users,
    pointValue: assignment.point_value,
    background: getRandomColor(),
    openstax_thumbnail: assignment.openstax_thumbnail,
    openstax_title: assignment.openstax_title,
    openstax_description: assignment.openstax_description,
    openstax_url: assignment.openstax_url,
  };
};

const decorateGetVideoDetailsByIdResponse = (videoResponse) => {
  return [
    {
      id: videoResponse.id,
      img: IMAGE_S3_SERVER + THUMBNAILS + videoResponse.video_thumbnail,
      title: videoResponse.name,
      text: videoResponse.author_name,
      icon: "svg/video_sm_ic.svg",
      duration: videoResponse.duration,
    },
  ];
};

const decorateGetAssessmentDetailsByIdResponse = (assessmentResponse) => {
  return [
    {
      id: assessmentResponse.id || assessmentResponse.assessment_id,
      title: assessmentResponse.assessment_name,
      icon: "svg/assessments_nav_ic.svg",
      text: assessmentResponse.created_by,
      img: "/images/assignment-placeholder.jpg",
      type: assessmentResponse.type,
      versions: assessmentResponse.versions,
    },
  ];
};

export const decorateSectionRequest = (formValue) => {
  return {
    course_name: formValue.name,
    course_number: formValue.courseNumber,
    number: formValue.sectionNumber,
    discipline_id: formValue.discipline.value,
    is_active: true,
    room: formValue.room,
    term: formValue.term,
    schedule: formValue.schedule,
    year: formValue.year,
    background: formValue.background,
  };
};

const disciplines = [
  {
    value: "1",
    label: "Mathematics",
  },
  {
    value: "2",
    label: "Physics",
  },
  {
    value: "3",
    label: "Chemistry",
  },
  {
    value: "4",
    label: "Science",
  },
  {
    value: "5",
    label: "Biology",
  },
];

export const decorateSectionResponse = (sectionResponse) => {
  return {
    id: sectionResponse.id,
    name: sectionResponse.course_name,
    courseNumber: sectionResponse.course_number,
    sectionNumber: sectionResponse.number,
    discipline: {
      label:
        disciplines && sectionResponse.discipline_id
          ? disciplines.find((i) => i.value === sectionResponse.discipline_id)?.label
          : [],
      value: sectionResponse.discipline_id,
    },
    room: sectionResponse.room || "",
    term: sectionResponse.term || "",
    schedule: sectionResponse.term || "",
    year: sectionResponse.year ? String(sectionResponse.year) : "",
    background: sectionResponse.background,
    due: sectionResponse.schedule,
    students: sectionResponse.student_count,
    createdBy: sectionResponse.created_by,
    modifiedBy: sectionResponse.modified_by,
    createdOn: sectionResponse.created_ts,
    modifiedOn: sectionResponse.modified_ts,
  };
};

const decorateUnitRequest = (unit) => {
  return {
    id: unit.id,
    name: unit.name,
    description: unit.description || "Test Description",
    section_id: unit.sectionId,
    start_time: unit.startDate,
    end_time: unit.endDate,
  };
};

const decorateUnitResponse = (unit) => {
  return {
    id: unit.id,
    sectionId: unit.section_id,
    name: unit.name,
    parentUnitId: unit.parent_unit_id,
    startDate: unit.start_time,
    endDate: unit.end_time,
    assignments: [],
    createdBy: unit.created_by,
    modifiedBy: unit.modified_by,
    createdOn: unit.created_ts,
    modifiedOn: unit.modified_ts,
    description: unit.description,
  };
};

const decorateAssignmentRequest = (map) => {
  return {
    assignment_type: map.type,
    name: map.name,
    description: map.description,
    unit_id: map.activeUnitId,
    section_id: map.activeCourseId,
    account_id: map.accountId,
    assessment_series_id:
      map.type === ASSIGNMENT_TYPES.EXAM || map.type === ASSIGNMENT_TYPES.PRACTICE
        ? !!map.assetId
          ? map.assetId
          : 0
        : 0,
    video_id: map.type === ASSIGNMENT_TYPES.VIDEO ? map.assetId : 0,
    cooldown_days: 0,
    attempt_limit: map.type === ASSIGNMENT_TYPES.EXAM ? map.attemptLimit : 0,
    timed_indicator: false,
    time_limit: map.type === ASSIGNMENT_TYPES.EXAM ? map.timeLimit : 0,
    instant_feedback_indicator: false,
    graded_indicator: map.type === ASSIGNMENT_TYPES.EXAM,
    point_value: map.pointValue,
    status: "Live",
    openstax_thumbnail: map.type === ASSIGNMENT_TYPES.OPENSTAX ? map.openstax_thumbnail : null,
    openstax_title: map.type === ASSIGNMENT_TYPES.OPENSTAX ? map.openstax_title : null,
    openstax_description: map.type === ASSIGNMENT_TYPES.OPENSTAX ? map.openstax_description : null,
    openstax_url: map.type === ASSIGNMENT_TYPES.OPENSTAX ? map.openstax_url : null,
  };
};

function decorateEnrollUsersRequest(selectedItems) {
  return selectedItems.map((item) => ({
    username: item.id,
  }));
}

const decorateAssignmentDashboardResponse = (value, apiCallInProgress) => {
  return {
    value: value,
    apiCallInProgress: apiCallInProgress,
  };
};

const decorateVideoQuizAverageCorrectAnswers = (responseData) => ({
  average_questions_correct: responseData.average_questions_correct
    ? responseData.average_questions_correct
    : 0,
  total_questions: responseData.total_questions ? responseData.total_questions : 0,
});
