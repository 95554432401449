/**
 * The external dependencies.
 */
import React, { Component } from "react";
import styled, { css } from "styled-components";
import { media } from "styles/media";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import AnimateHeight from "react-animate-height";

/**
 * The internal dependencies.
 */
import scrollTo from "lib/helpers/scroll-to";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import { FormattedText } from "../common/FormattedText";

/**
 * Class for drawer layout.
 *
 * @class DrawerLayout (name)
 */
class DrawerLayout extends Component {
  /**
   * The internal state of the component.
   *
   * @type {Object}
   */
  state = {
    drawerIsOpen:
      this.props.isOpen == !undefined || this.props.isOpen != null ? this.props.isOpen : true,
    scrollBarHeight: "calc(100vh - 133px)",
    offsetTop: 113,
    data: this.props.data,
  };

  /**
   * Toggles the drawer.
   *
   * @param  {Object} The event
   * @return {Void}
   */
  toggleDrawer = (event) => {
    event.preventDefault();

    this.setState(({ drawerIsOpen }) => ({
      drawerIsOpen: !drawerIsOpen,
    }));
  };

  /**
   * Sets the scroll bar height.
   *
   * @return {Void}
   */
  setScrollBarHeight = () => {
    const windowHeight = window.innerHeight;
    const header = document.querySelector(".header");
    const breadcrumbs = document.querySelector(".breadcrumbs");
    const offsetTop = header.clientHeight + breadcrumbs.clientHeight;

    this.setState({
      scrollBarHeight: windowHeight - offsetTop,
      offsetTop: offsetTop,
    });
  };

  /**
   * Handle drawer button click.
   *
   * @param  {String} The identifier
   * @return {Void}
   */
  onDrawerButtonClick = (id, idx) => (event) => {
    event.preventDefault();
    const element = document.getElementById(id);

    this.setState(({ data }) => ({
      data: data.map((item, index) => {
        if (index !== idx) {
          return {
            ...item,
            height: 0,
          };
        }

        return {
          ...item,
          height: item.height === 0 ? "auto" : 0,
        };
      }),
    }));

    scrollTo(
      this.scrollCotnainer.getScrollTop(),
      this.scrollCotnainer.scrollTop,
      element.offsetTop
    );
  };

  /**
   * Renders a list item with dropdown.
   *
   * @param  {Object} The properties
   * @param  {String} The identifier
   * @param  {String} The title
   * @param  {String} The url
   * @param  {Number} The height
   * @param  {Object} The children
   * @param  {Number} The index
   * @return {Object}
   */
  renderListItemWithDropdown = (item, idx, childName) => {
    const { id, name, height } = item;
    const children = item[childName];

    return (
      <DrawerListItem key={id}>
        <ButtonLink
          isDrawerListButton
          isActive={height === 0 ? false : true}
          onClick={this.onDrawerButtonClick(id, idx)}
        >
          <IconSvgComponent iconPath="svg/ico-angle-right.svg" />

          <span>
            <FormattedText text={name}></FormattedText>
          </span>
        </ButtonLink>

        <AnimateHeight height={height}>
          <DrawerList nested>
            {children.map(({ url, name }, innerIdx) => (
              <DrawerListItem key={`drawer-${url}`}>
                <ButtonLink isDrawerListLink to={url}>
                  <span className="counter">{innerIdx + 1}.</span>

                  <span>
                    <FormattedText text={name}></FormattedText>
                  </span>
                </ButtonLink>
              </DrawerListItem>
            ))}
          </DrawerList>
        </AnimateHeight>
      </DrawerListItem>
    );
  };

  /**
   * Renders a list item.
   *
   * @param  {Object} The properties
   * @param  {String} The identifier
   * @param  {String} The url
   * @param  {String} The title
   * @param  {Number} The index
   * @return {Object}
   */
  renderListItem = ({ id, url, name }, idx) => {
    return (
      <DrawerListItem key={`drawer-${url}`}>
        <ButtonLink isDrawerListLink to={url}>
          <span className="counter">{idx + 1}.</span>

          <span>
            <FormattedText text={name}></FormattedText>
          </span>
        </ButtonLink>
      </DrawerListItem>
    );
  };

  /**
   * The componentDidMount lifecycle hook.
   *
   * @return {Void}
   */
  componentDidMount() {
    this.setScrollBarHeight();
    window.addEventListener("resize", this.setScrollBarHeight);
  }

  /**
   * The componentWillUnmount lifecycle hook.
   *
   * @return {Void}
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.setScrollBarHeight);
  }

  /**
   * The render method of the component.
   *
   * @return {Object}
   */
  render() {
    const {
      toggleDrawer,
      props: {
        //renderAside,
        children,
        childArrayName,
        isOpen,
      },
      state: { drawerIsOpen, scrollBarHeight, offsetTop, data },
    } = this;
    const childName = childArrayName ? childArrayName : "children";
    return (
      <Section>
        <Scrollbar
          innerRef={(scrollCotnainer) => (this.scrollCotnainer = scrollCotnainer)}
          className="scrollbar"
          renderTrackHorizontal={(props) => (
            <div {...props} className="scrollbar__track-horizontal" />
          )}
          renderTrackVertical={(props) => <div {...props} className="scrollbar__track-vertical" />}
          style={{
            height: scrollBarHeight,
          }}
        >
          <SectionContent isOpen={drawerIsOpen}>{children}</SectionContent>
        </Scrollbar>

        <SectionAside mobileOffset={offsetTop} isOpen={drawerIsOpen}>
          <ButtonLink
            isSectionAsideButton
            isOpen={drawerIsOpen}
            type="button"
            onClick={toggleDrawer}
          >
            <IconSvgComponent iconPath="svg/arrow-stand.svg" />
          </ButtonLink>

          <ButtonLink isSectionAsideHead type="button" onClick={toggleDrawer}>
            <SectionAsideTitle>
              <IconSvgComponent iconPath="svg/arrow-stand.svg" />

              <span>{this.props.title || "Table of Contents"}</span>
            </SectionAsideTitle>
          </ButtonLink>

          <SectionAsideBody height={scrollBarHeight}>
            <DrawerList>
              {data.map((item, idx) =>
                item[childName]
                  ? this.renderListItemWithDropdown(item, idx, childName)
                  : this.renderListItem(item, idx)
              )}
            </DrawerList>
          </SectionAsideBody>
        </SectionAside>
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  display: flex;
  overflow: hidden;
`;
const SectionContent = styled.div`
  max-width: 100%;
  flex: 1 1 100%;
  padding: 26px 13px 0 30px;

  ${media.tablet`
    padding: 15px;
  `}
`;
const SectionAside = styled.aside`
  position: relative;
  max-width: 251px;
  flex: 0 0 251px;
  margin-left: 16px;
  background: #fff;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
  transition: right 0.3s, margin-right 0.3s, margin-left 0.3s;

  ${(props) =>
    !props.isOpen &&
    `
    margin-right: -267px;
    margin-left: 92px;
  `}

  ${media.tablet`
    position: fixed;
    right: 0;
    top: ${(props) => props.mobileOffset}px;
    width: 100%;
    height: 100%;
    margin-left: 0;

    ${(props) =>
      !props.isOpen &&
      `
      margin-right: 0;
      right: -251px;
    `}
  `}
`;
export const SectionAsideHead = styled.button`
  width: 100%;
  padding: 17px 20px 9px 16px;
  background: none;
  border: 0 none;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);

  h6 {
    padding: 2px;
    margin-left: -4px;
    margin-top: -4px;
    border: 2px solid transparent;
    border-radius: 4px;
  }

  &:focus {
    h6 {
      border: 2px solid #2049e6;
    }
  }
`;
const SectionAsideTitle = styled.h6`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  color: #4a4a4a;

  span {
    margin-left: 12px;
  }
`;
const SectionAsideBody = styled.div`
  overflow: auto;
  height: ${(props) => props.height - 50}px;
`;
export const SectionAsideButton = styled.button`
  position: absolute;
  right: calc(100% + 16px);
  top: 26px;
  padding: 18px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04);
  border: 0 none;
  opacity: 0;
  visibility: hidden;
  transition: visibility .3s, opacity .3s, transform .3s;
  transform: rotate(180deg) translateX(-100%);

  .svg-icon svg {
     width: 22px;
     height: 18px;
  }

  .svg-icon {
    border: 2px solid transparent;
    border-radius: 4px;
    padding: 2px;
  }

  &:focus {
    .svg-icon {
      border: 2px solid #2049E6;
    }
  }

  ${(props) =>
    !props.isOpen &&
    `
    opacity: 1;
    visibility: visible;
    transform: rotate(180deg) translateX(0%);
  `}

  ${media.tablet`
    right: 100%;
  `}

  ${media.mobile`
    top: 15px;
    border: 1px solid rgba(204, 204, 204, 0.5);
  `}
`;

const Scrollbar = styled(Scrollbars)`
  .scrollbar__track-vertical {
    top: 0;
    right: 0;
    height: 100%;
    width: 4px !important;
    padding-top: 48px;

    div {
      border-radius: 4px !important;
      background-color: #a3aaad !important;
    }
  }

  .scrollbar__track-horizontal {
    display: none !important;
  }

  ${media.tablet`
    height: auto !important;

    & > div {
      position: static !important;
    }

    .scrollbar__track-vertical {
      display: none;
    }
  `}
`;

export const DrawerListLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 2px;
  margin-top: -4px;
  margin-left: -4px;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    border: 2px solid #2049e6;
  }
`;
const DrawerListItem = styled.li`
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const DrawerListButton = styled.button.attrs({
  type: "button",
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: none;
  border: 0 none;
  text-align: left;
  padding: 2px;
  margin-left: -4px;
  margin-top: -4px;
  border: 2px solid transparent;
  border-radius: 4px;

  span {
    margin-left: 7px;
  }

  .svg-icon {
    transition: transform 0.2s;
  }

  &:focus {
    border: 2px solid #2049e6;
  }

  ${(props) =>
    props.isActive &&
    `
    .svg-icon {
      transform: rotate(90deg);
    }
  `}
`;

const DrawerList = styled.ul`
  list-style-type: none;
  padding: 17px;
  font-size: 12px;

  ${(props) =>
    props.nested &&
    css`
      padding: 10px 15px;

      ${DrawerListItem} {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    `}

  ${DrawerListItem} {
    .counter {
      margin-right: 3px;
    }
  }
`;

const DrawerOrderedList = styled.ol``;

export default DrawerLayout;
