import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Loader from "../common/loader";
import StudentDashboardIntroView from "../studentDashboard/Intro";
import NoAssessmentView from "../studentDashboard/NoAssessmentView";
import { getDashboardDetails } from "../../store/state/studentDashboard/action";
import { getSections } from "store/state/courses/actions";

class StudentHome extends Component {
  componentDidMount() {
    const { getDashboardDetails, getSections } = this.props;
    getDashboardDetails({ selector: "DashboardAssessmentList" });
    getSections();
  }

  render() {
    const {
      props: { assessmentCount: hasAssessments, sectionsCount: hasCourses, isLoading },
    } = this;

    return isLoading === true ? (
      <Loader />
    ) : (
      <React.Fragment>
        {!!hasAssessments || !!hasCourses ? <StudentDashboardIntroView /> : <NoAssessmentView />}
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    assessmentCount: state.studentDashboard.DashboardAssessmentList.assessments.length,
    sectionsCount: Object.values(state.storedCourses.sections).length,
    isLoading: state.studentDashboard.isLoading,
  }),
  {
    getDashboardDetails,
    getSections,
  }
)(StudentHome);
