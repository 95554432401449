import {
  accountPath,
  contentType,
  contentTypeJSON,
  accountStatusPath,
  accountsPath,
} from "../constants/api";
import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";

const callCreateAccountAPI = async (accountData) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: accountPath,
    method: "post",
    body: JSON.stringify(accountData),
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};

const callUpdateAccountAPI = async (accountCode, accountData) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: accountPath,
    method: "PUT",
    body: JSON.stringify(accountData),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountCode,
    },
  });
};

const callUpdateAccountSettingsAPI = async ({ account_id, settings }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/account/${account_id}/settings`,
    method: "PUT",
    body: JSON.stringify(settings),
    headers: {
      Authorization: getAccessToken(),
      account_id,
    },
  });
};

const callUpdateAccountStatusAPI = async (accountCode, accountStatusData) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: accountStatusPath,
    method: "PUT",
    body: JSON.stringify(accountStatusData),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountCode,
    },
  });
};

const callGetAccountsAPI = async ({ accountId, page, pageSize }) => {
  var accountsPageUrl = accountsPath;
  if (accountId) {
    accountsPageUrl = accountsPageUrl + "/" + accountId;
  }
  if (!pageSize) {
    pageSize = 10;
  }
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath:
      pageSize === -1 ? accountsPageUrl : `${accountsPageUrl}?pageSize=${pageSize}&page=${page}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};

const callGetAccountAPI = async (accountId) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: accountsPath + "/" + accountId,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentTypeJSON,
    },
  });
};

export {
  callCreateAccountAPI,
  callUpdateAccountAPI,
  callUpdateAccountStatusAPI,
  callGetAccountsAPI,
  callGetAccountAPI,
  callUpdateAccountSettingsAPI,
};
