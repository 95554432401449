import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import IconSvgComponent from "components/common/IconSvgComponent";

import {
  submitLogin,
  completeLogin,
  rejectLogin,
  getLoggedInStatus,
} from "store/state/user/actions";

import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Form, {
  FormBody,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
  FieldContainer,
  Wrapper,
  LoginWrapper,
  StyledFormWrapper,
  StyledHead,
  StyledIconWrapper,
  StyledTitle,
  StyledBody,
  StyledFoot,
  StyledEntry,
  StyledText,
  StyledMessage,
} from "styles/components/Form";

import { validateField } from "utilities/validation";
import { FORM_LOGIN_MESSAGES } from "constants/messages";
import FieldErrors from "../common/FieldErrors";

class FormLogin extends Component {
  state = {
    email: "",
    password: "",
    loginApiError: this.props.loginApiError,
    apiSuccess: (() => {
      var ret = null;
      if (this.props.location && this.props.location.state && this.props.location.state.referrer) {
        switch (this.props.location.state.referrer) {
          case "/login/password-reset": {
            // to clear referer as we are showing tooltip on form basis of referer which should get cleared after refresh.
            this.props.history.replace(this.props.location.pathname, null);
            ret = {
              messageType: "success",
              message: FORM_LOGIN_MESSAGES.PASSWORD_RESET_EMAIL_SENT,
            };
            break;
          }
          case "/login/first-time-login/": {
            // to clear referer as we are showing tooltip on form basis of referer which should get cleared after refresh.
            this.props.history.replace(this.props.location.pathname, null);
            if (this.props.firstTimeLoginResetError.code != -1) {
              ret = {
                messageType: "error",
                message:
                  this.props.firstTimeLoginResetError.message ||
                  FORM_LOGIN_MESSAGES.ERROR_CHANGE_PASSWORD_FIRST_LOGIN,
              };
            } else {
              ret = {
                messageType: "success",
                message: FORM_LOGIN_MESSAGES.SUCCESS_CHANGE_PASSWORD_FIRST_LOGIN,
              };
            }
            break;
          }
          default:
            ret = { message: null, messageType: null };
        }
      }
      return ret;
    })(),
    formErrors: {
      email: {
        valid: true,
        message: "",
      },
      password: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };

  rules = {
    email: {
      requiredMessage: FORM_LOGIN_MESSAGES.EMAIL_REQUIRED,
    },
    password: {
      requiredMessage: FORM_LOGIN_MESSAGES.PASSWORD_REQUIRED,
    },
  };

  componentWillMount() {
    this.props.getLoggedInStatus({ routeParams: this.props.routeParams });
  }

  handleFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value.trim() });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      apiSuccess: { message: null, messageType: null },
    });

    this.validateAllFields();

    if (this.state.formErrors.formValid) {
      const { email, password } = this.state;
      this.props.submitLogin({
        username: email,
        password: password,
      });
    }
  };

  validateTextField = (element, trimValues) => {
    this.setState({
      formErrors: validateField(this.state.formErrors, this.rules, element, "", trimValues),
    });
  };

  validateAllFields = () => {
    this.validateTextField(document.getElementById("email"), true);
    this.validateTextField(document.getElementById("password"), true);
  };

  render() {
    const { formErrors, apiSuccess } = this.state;
    const { loggingIn } = this.props;
    return (
      <Wrapper>
        {this.props.firstTimeLogin ? <Redirect to="/login/first-time-login/" /> : null}
        <LoginWrapper>
          <StyledFormWrapper>
            <StyledHead invalidForm={!formErrors.formValid} login="login">
              <StyledIconWrapper>
                <IconSvgComponent additionalClass="login" iconPath="svg/stemify-logo-login.svg" />
              </StyledIconWrapper>
              {apiSuccess && apiSuccess.message ? (
                <StyledMessage id="lblAPIStatusMsg" type={apiSuccess.messageType}>
                  {apiSuccess.message}
                </StyledMessage>
              ) : (
                <FieldErrors
                  id="lblAPIStatusMsg"
                  formErrors={formErrors}
                  apiErrorMessage={this.props.loginApiError.message}
                ></FieldErrors>
              )}
            </StyledHead>

            <StyledBody login="login">
              <Form onSubmit={this.handleSubmit}>
                <FieldContainer disabled={loggingIn}>
                  <FormBody>
                    <FormRowGrid>
                      <FormCol>
                        <FormControls>
                          <Field
                            id="email"
                            name="email"
                            defaultFocus={true}
                            onChange={this.handleFieldChange}
                            value={this.state.email}
                            placeholder="User Id / Email"
                            required
                            max="128"
                            error={!formErrors.email.valid}
                            hideRequiredSymbol
                          />
                        </FormControls>

                        <FormControls>
                          <Field
                            id="password"
                            name="password"
                            type="password"
                            defaultFocus={true}
                            onChange={this.handleFieldChange}
                            value={this.state.password}
                            placeholder="Password"
                            required
                            max="255"
                            hideRequiredSymbol
                            error={!formErrors.password.valid}
                          />
                        </FormControls>
                      </FormCol>
                    </FormRowGrid>
                  </FormBody>

                  <FormActions>
                    <ButtonLink type="submit" violet id="submit" widgetButton>
                      {loggingIn ? <div className="loader"></div> : <span>Log In</span>}
                    </ButtonLink>
                  </FormActions>
                </FieldContainer>
                <StyledFoot>
                  <StyledEntry>
                    <StyledText>
                      <ButtonLink isStyledLink to="/login/password-reset">
                        Forgot your password?
                      </ButtonLink>
                    </StyledText>
                  </StyledEntry>
                </StyledFoot>
              </Form>
            </StyledBody>
          </StyledFormWrapper>
        </LoginWrapper>
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => ({
      userPool: state.user.userPool,
      firstTimeLogin: state.user.firstTimeLogin,
      loginApiError: state.user.apiErrors.login,
      firstTimeLoginResetError: state.user.apiErrors.firstSignInResetPassword,
      loggingIn: state.user.loggingIn,
      routeParams: ownProps.match.params,
    }),
    (dispatch) => ({
      submitLogin: ({ username, password }) => dispatch(submitLogin({ username, password })),
      completeLogin: () => dispatch(completeLogin()),
      rejectLogin: () => dispatch(rejectLogin()),
      getLoggedInStatus: ({ routeParams }) => dispatch(getLoggedInStatus({ routeParams })),
    })
  )(FormLogin)
);
