import React from "react";
import { useSelector } from "react-redux";

const CourseAssessmentDashboardBreadcrumb = (props) => {
  const { assignmentId } = props.match.params;
  const assignment = useSelector((state) => state.storedCourses.assignments[assignmentId]);
  const title = assignment ? `${assignment.name} Dashboard` : "";

  return <span>{title}</span>;
};

export default CourseAssessmentDashboardBreadcrumb;
