const authKey = "@stemify:auth";
const localStore = window.localStorage;

export const isLoggedIn = () => {
  if (process.env.REACT_APP_DISABLE_LOGIN) {
    return true;
  }
  const authVal = JSON.parse(localStore.getItem(authKey));
  return authVal ? authVal.isLoggedIn : false;
};

export const login = () => {
  const authVal = { isLoggedIn: true };
  localStore.setItem(authKey, JSON.stringify(authVal));
};

export const logout = () => {
  const authVal = { isLoggedIn: false };
  localStore.setItem(authKey, JSON.stringify(authVal));
};
