const theme = {
  container: "1328px",
  font: "Circular Pro, sans-serif",
  font_size_base: "14px",
  line_height_base: "1.5",
  border_radius: "8px",
  color: {
    black: "#000",
    white: "#fff",
    white_smoke: "#F6F6F6",
    sky_blue_smoke: "#E6EAF1",
    gray: "#96a0b2",
    light_gray: "#f8f8f8",
    // dark_gray: '#808898',
    dark_gray: "#484848",
    darker_gray: "#4A4A4A",
    red: "#FF3E1D",
    red_faded: "#FF735B",
    dark_red: "#e70f0e",
    blue: "#2049e6",
    dark_blue: "#0b289b",
    light_blue: "#1fc9ff",
    dark_light_blue: "#16b5f2",
    royal_blue: "#1F48E6",
    light_royal_blue: "#479AE4",
    sky_blue: "#1FC9FF",
    orange: "#FF953A",
    perfect: "#ffdb7d57",
    success: "#1CC379",
    correct: "#1cc37959",
    incorrect: "#ff5f6559",
  },
  font_size: {
    h1: "32px",
    h2: "32px",
    h3: "24px",
    h4: "20px",
    h5: "16px",
    h6: "14px",
    small: "12px",
  },
};

export default theme;
