import React, { Component } from "react";
import { connect } from "react-redux";

import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import {
  SubmitContainer,
  SubmitIcon,
  SubmitTitle,
  SubmitText,
  SubmitActions,
} from "styles/components/AssessmentSubmit";
import moment from "moment";

class CooldownModalContent extends Component {
  render() {
    const { icon, title, nextAttemptDate, assessmentName, closeModal } = this.props;

    return (
      <SubmitContainer>
        <SubmitIcon>
          <IconSvgComponent iconPath={icon} />
        </SubmitIcon>
        <SubmitTitle id={"cooldownPeriodTitle"}>{title}</SubmitTitle>
        <SubmitText>
          You have to wait before you can take this {assessmentName} again. The assessment will open
          again at{" "}
          <span style={{ color: "#2049E6" }}>{moment(nextAttemptDate).format("MMM DD, YYYY")}</span>
          .
        </SubmitText>
        <SubmitActions>
          <ButtonLink onClick={closeModal} className="btn-submit">
            Close
          </ButtonLink>
        </SubmitActions>
      </SubmitContainer>
    );
  }
}

export default connect((state) => ({}), {
  closeModal,
})(CooldownModalContent);
