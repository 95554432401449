import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectAssessmentSeries } from "store/state/assessment/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import { closeModal, openModal } from "store/state/ui/actions";
import { MODAL_ASSESSMENT_RESUME } from "lib/constants";
import {
  AssessmentContainer,
  AssessmentWrapper,
  AssessmentIcon,
  AssessmentTitle,
  AssessmentStats,
  AssessmentStat,
  AssessmentButton,
} from "styles/components/SelectAssessment";
import stemifyRoutes from "constants/routes";
import moment from "moment";

class Assessment extends Component {
  handleAssessmentClick = async (assessmentId, deploymentId) => {
    const { attemptsRemaining, selectAssessmentSeries, history, openModal } = this.props;

    if (attemptsRemaining > 0) {
      await selectAssessmentSeries({ assessmentId, deploymentId });

      history.push({
        pathname: `${stemifyRoutes.assessments}/${deploymentId}/${assessmentId}`,
        state: { referrer: `${stemifyRoutes.assessments}` },
      });
      // }
    }
  };

  onKeyPress = async (e, assessmentId, deploymentId) => {
    const { attemptsRemaining, history } = this.props;

    if (e.which === 13 || e.which === 32) {
      if (attemptsRemaining > 0) {
        await selectAssessmentSeries({ assessmentId, deploymentId });

        history.push({
          pathname: `${stemifyRoutes.assessments}/${deploymentId}/${assessmentId}`,
          state: { referrer: `${stemifyRoutes.assessments}` },
        });
      }
    }
  };

  isCoolingPeriodExceeded = (coolingPeriod, lastAttemptDate) => {
    return moment(lastAttemptDate)
      .add(coolingPeriod, "days")
      .isBefore(moment(), "day");
  };

  render() {
    const {
      icon,
      id,
      deploymentId,
      title,
      attemptsRemaining,
      timeLimit,
      prevScore,
      coolingPeriod,
      lastAttemptDate,
    } = this.props;
    return (
      <AssessmentContainer>
        {attemptsRemaining && this.isCoolingPeriodExceeded(coolingPeriod, lastAttemptDate) ? (
          <AssessmentWrapper
            onClick={() => this.handleAssessmentClick(id, deploymentId)}
            tabIndex={0}
            onKeyPress={(e) => this.onKeyPress(e, id, deploymentId)}
          >
            <AssessmentIcon className="assessment__icon">
              <IconSvgComponent iconPath={icon} />
            </AssessmentIcon>

            <AssessmentTitle>{title}</AssessmentTitle>

            <AssessmentStats>
              <AssessmentStat>Attempts Remaining: {attemptsRemaining}</AssessmentStat>
              <AssessmentStat>Time Limit: {timeLimit}</AssessmentStat>
              <AssessmentStat>Previous Score: {prevScore}</AssessmentStat>
            </AssessmentStats>

            <AssessmentButton className="assessment__button">Take Assessment</AssessmentButton>
          </AssessmentWrapper>
        ) : (
          <AssessmentWrapper onClick={void 0} disabled>
            <AssessmentIcon className="assessment__icon">
              <IconSvgComponent iconPath={icon} />
            </AssessmentIcon>

            <AssessmentTitle>{title}</AssessmentTitle>

            <AssessmentStats>
              <AssessmentStat>Attempts Remaining: {attemptsRemaining}</AssessmentStat>
              <AssessmentStat>Time Limit: {timeLimit}</AssessmentStat>
              <AssessmentStat>Previous Score: {prevScore}</AssessmentStat>
            </AssessmentStats>

            <AssessmentButton className="assessment__button">Take Assessment</AssessmentButton>
          </AssessmentWrapper>
        )}
      </AssessmentContainer>
    );
  }
}

export default connect(
  (state) => ({
    activeAssessment: state.assessment.activeAssessment,
  }),
  {
    selectAssessmentSeries,
    openModal,
  }
)(withRouter(Assessment));
