/**
 * The external dependencies.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { pick } from "ramda";
import { validateSelectField } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { getCohorts, resetApiErrors } from "store/state/administration/actions";
import { closeModal } from "store/state/ui/actions";
/**
 * The internal dependencies.
 */

import { updateDeployment } from "store/state/deployment/actions";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";

import {
  FormDefault as Form,
  FormBody,
  FormSection,
  FormRowGrid,
  FormCol,
  FormControls,
  FormActions,
} from "styles/components/Form";
import Field from "components/common/Field";
import { FORM_DEPLOYMENT_MESSAGES } from "constants/messages";
/**
 * Class for form create deployment cohort.
 *
 * @class      FormCreateDeploymentCohort (name)
 */
class FormCreateDeploymentCohort extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    cohort: "",
    formErrors: {
      cohort: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };
  rules = {
    cohort: {
      requiredMessage: FORM_DEPLOYMENT_MESSAGES.COHORT_REQUIRED,
    },
  };

  /**
   * Handles dropdown field change.
   *
   * @param      {String} / The date
   * @return     {Void}
   */
  handleSelectChange = (key) => (value) => {
    this.props.resetApiErrors();
    this.setState({
      [key]: value,
    });
  };

  validateDropDownField = (properties) => {
    this.setState({
      formErrors: validateSelectField(this.state.formErrors, this.rules, properties),
    });
  };

  /**
   * Handles the form submit.
   *
   * @param {Object} / The form event
   * @return {Void}
   */
  handleFormSubmit = async (e) => {
    e.preventDefault();

    this.validateDropDownField({ name: "cohort", value: this.state.cohort, required: true });

    if (this.state.formErrors.formValid) {
      const { updateDeployment, nextStep, modal, closeModal } = this.props;
      const updatedDeployment = {
        cohort: this.state.cohort,
      };
      var reqParams = {
        ...updatedDeployment,
        is_draft: true,
      };
      const resp = await updateDeployment(reqParams);
      if (resp.status == "success") {
        nextStep();
      }
    }
  };

  handleDraftSave = async (e) => {
    e.preventDefault();
    const { updateDeployment, nextStep, modal } = this.props;
    const updatedDeployment = {
      cohort: this.state.cohort,
    };
    var reqParams = {
      ...updatedDeployment,
      is_draft: true,
    };

    await updateDeployment(reqParams);
  };

  /**
   * The componentDidMount lifecycle hook of the component.
   *
   * @return {Void}
   */
  componentDidMount() {
    this.props.resetApiErrors();
    this.setState({
      ...pick(["cohort"], this.props.deployment),
    });

    this.props
      .getCohorts({
        selector: "cohorts",
        accountId: this.props.accountId,
        pageSize: -1,
      })
      .then(() => {
        this.setState({
          cohorts: this.props.cohorts,
        });
      });
  }

  render() {
    const {
      props: { modal, prevStep, deploymentsApiErrors, apiCallInProgress },
      state: { cohorts, formErrors, cohort },
    } = this;
    const { create: createApiErrors, update: updateApiErrors } = deploymentsApiErrors;

    return (
      <Form fulloNmobile onSubmit={this.handleFormSubmit} colSpacing>
        <FieldErrors
          formErrors={formErrors}
          apiErrorMessage={createApiErrors.message || updateApiErrors.message}
        ></FieldErrors>
        <FormBody>
          <FormSection>
            <FormRowGrid justifyCenter>
              <FormCol size1>
                <FormControls>
                  <Field
                    id="cohort"
                    name="cohort"
                    isSelect
                    onChange={this.handleSelectChange("cohort")}
                    options={cohorts}
                    value={cohort}
                    placeholder="Select Cohort"
                    required
                    error={!formErrors.cohort.valid}
                    apiCallInProgress={apiCallInProgress}
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>
          </FormSection>
        </FormBody>

        <FormActions flex>
          <ButtonLink type="submit" violet hasIcon isPrevious onClick={prevStep}>
            <IconSvgComponent iconPath="svg/ico-arrow-left-large.svg" />
            <span>Previous</span>
          </ButtonLink>
          <ButtonLink
            disabled={apiCallInProgress}
            transparent
            onClick={this.handleDraftSave}
            type="button"
          >
            {modal.data.editDeployment ? "Save Changes" : "Save Draft"}
          </ButtonLink>
          <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
            <span>Next Step</span>
            <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    cohorts: state.administration.cohorts.map((item) => ({
      label: item.name,
      value: item.id,
      cohort_start_date: item.start_date,
      cohort_end_date: item.end_date,
    })),
    deployment: state.deployment,
    accountId: state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId,
    apiCallInProgress: state.administration.apiCallInProgress,
    modal: state.ui.modal,
    deploymentsApiErrors: state.administration.apiErrors.deployments,
  }),
  {
    updateDeployment,
    getCohorts,
    resetApiErrors,
    closeModal,
  }
)(FormCreateDeploymentCohort);
