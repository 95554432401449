import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "components/common/loader";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { getLoggedInStatus, completeSSOLogin } from "store/state/user/actions";

import Form, { Wrapper, StyledBody } from "styles/components/Form";

class FormUconnSSO extends Component {
  state = {};

  componentWillMount() {
    this.props.getLoggedInStatus();
  }

  async componentDidMount() {
    const { location, completeSSOLogin } = this.props;
    const searchParams = queryString.parse(location.search);
    var code = searchParams?.code;

    if (code) {
      let res = await completeSSOLogin({ code, url: window.location.href });
      console.log({ ssoLoginResponse: res });

      if (!res) {
        // Just to rid for referer condition and clean redirect to login page.
        window.location.href = "/";
      }
    }
  }

  render() {
    return (
      <Wrapper>
        <StyledBody login="login">
          <Loader />
        </StyledBody>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    userPool: state.user.userPool,
    firstTimeLogin: state.user.firstTimeLogin,
    loginApiError: state.user.apiErrors.login,
    firstTimeLoginResetError: state.user.apiErrors.firstSignInResetPassword,
    loggingIn: state.user.loggingIn,
  }),
  (dispatch) => ({
    getLoggedInStatus: () => dispatch(getLoggedInStatus()),
    completeSSOLogin: (obj) => dispatch(completeSSOLogin(obj)),
  })
)(withRouter(FormUconnSSO));
