/**
 * The external dependencies.
 */
import React, { Component } from "react";
import IconSvgComponent from "components/common/IconSvgComponent";
import { SectionBuilderNotification } from "styles/components/SectionBuilder";
import ButtonLink from "components/common/ButtonLink";

class NoQuestionsNotification extends Component {
  render() {
    return (
      <SectionBuilderNotification isMain={this.props.isMain}>
        <IconSvgComponent iconPath={`${this.props.iconPath}`} />

        <h5>{this.props.title}</h5>

        {this.props.onClickAction && (
          <ButtonLink onClick={this.props.onClickAction} hasIcon violet className="btn-add">
            <span>Add Question</span>

            <IconSvgComponent iconPath={`svg/ico-plus.svg`} />
          </ButtonLink>
        )}
      </SectionBuilderNotification>
    );
  }
}

export default NoQuestionsNotification;
