/**
 * The external dependencies.
 */
import React, { Component } from "react";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";
import { sort } from "ramda";
import theme from "styles/theme";
import { FrameCell } from "styles/components/Frame";
import MediaQuery from "react-responsive";

/**
 * Class for deployment chart.
 *
 * @class      DeploymentChart (name)
 */
class DeploymentChartStudent extends Component {
  state = {
    data: {
      labels: this.props.chartData.map((item) => item.label),
      datasets: [
        {
          data: this.props.chartData.map((item) => item.number),
          borderWidth: 0,
          hoverBorderWidth: 3,
          backgroundColor: this.props.chartColors,
          hoverBorderColor: this.props.chartColors,
          hoverBackgroundColor: this.props.chartColors,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      cutoutPercentage: 57,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
  };

  getTotal() {
    let total = 0;

    this.props.chartData.forEach((item) => {
      total += item.number;
    });

    return total;
  }

  getTotalInPercentage() {
    return (
      Math.floor((this.props.chartData[0].number / this.props.chartData[1].number) * 100) + "%"
    );
  }

  getLegendData() {
    const { chartData, chartColors } = this.props;
    // const sortedData = sort((a, b) => a.number - b.number, chartData);

    return chartData.map((item, i) => ({
      ...item,
      color: chartColors[i],
    }));
  }

  render() {
    const {
      props: { onMouseEnter, onMouseLeave, selected },
      state: { data, options },
    } = this;

    return (
      <FrameCell>
        <FrameCell percent="100">
          <ChartWrapper>
            <Doughnut
              ref={(reference) => (this.chartReference = reference)}
              data={data}
              width={73}
              height={240}
              options={options}
            />
          </ChartWrapper>
        </FrameCell>

        <FrameCell percent="100">
          <ChartLegend>
            {this.getLegendData().map((item, idx) => (
              <ChartLegendItem
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter(idx, item.number)}
                key={item.label + item.number}
              >
                <ChartLegendLabel>{item.label}</ChartLegendLabel>

                <MediaQuery maxWidth={1023}>
                  <ChartLegendValue small color={item.color}>
                    {item.number}
                  </ChartLegendValue>
                </MediaQuery>
                <MediaQuery minWidth={1024}>
                  <ChartLegendValue color={item.color}>{item.number}</ChartLegendValue>
                </MediaQuery>
              </ChartLegendItem>
            ))}
          </ChartLegend>
        </FrameCell>
      </FrameCell>
    );
  }
}

const ChartWrapper = styled.div`
  position: relative;
  pointer-events: none;
  user-select: none;

  .chartjs-render-monitor {
    height: 245px !important;
  }
`;
const ChartOverlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding-bottom: 16px;
  text-align: center;
`;
const ChartOverlayGroup = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const ChartTotalTitle = styled.h4`
  margin: 0 0 3px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: ${theme.color.dark_gray};
`;

const ChartLegendLabel = styled.span`
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.color.dark_gray};
`;

const ChartLegendValue = styled.div`
  font-size: 69px;
  font-weight: 500;
  line-height: 65px;
  color: ${(props) => props.color};

  ${(props) =>
    props.small &&
    `
    font-size: 59px;
  `};
`;

const ChartTotalEntry = styled.div`
  font-size: 69px;
  font-weight: 500;
  color: ${theme.color.darker_gray};

  ${(props) =>
    props.small &&
    `
    font-size: 54px;
  `};
`;

const ChartLegend = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  width: 100%;
  margin-right: 0px;
  margin-left: auto;

  li {
    flex-grow: 1;
  }
`;
const ChartLegendItem = styled.li`
  cursor: pointer;
  text-align: center;
`;

const ChartLegendDot = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

export default DeploymentChartStudent;
