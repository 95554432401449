/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import { Link } from "react-router-dom";

const LogoHolder = styled(Link)`
  position: relative;
  padding: 0;
  border: none;
  z-index: 10;
  margin-left: 20px;
  display: flex;
  text-decoration: none;
  color: #4a4a4a;

  strong,
  span {
    color: #4a4a4a;
  }

  &.is-highlighted {
    color: #4a4a4a;

    strong,
    span {
      color: #4a4a4a;
    }
  }

  ${media.mobile`
		margin-left: 10px;
	`}
`;

export const Logo = styled.strong`
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: white;
  transition: color 0.4s;
`;

export const LogoText = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: white;
  transition: color 0.4s;
  margin-left: 5px;
`;

export default LogoHolder;
