const colors = {
  fog: "#f9f9f9",
  haze: "#8a909c",
  eggplant: "#211f50",
  concrete: "#e5e5e5",
  cloud: "#f7f7f7",
  cherry: "#d82237",
};

export default colors;
