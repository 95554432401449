/**
 * @ External Dependecies
 */

import styled from "styled-components";
import { media } from "../media";

export const CategoryBreadcrumbs = styled.div`
  padding: 17px 17px;
  background: #fff;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style-type: none;

  ${media.mobile`
		padding: 10px;
	`}
`;

export const Category = styled.li`
  position: relative;
  margin: 0 9px 0 9px;
  padding: 0 27px 0 16px;
  width: calc(25% - 18px);

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #b2bbc6;
  }

  .svg-icon {
    font-size: 0;
    position: absolute;
    left: 0;
  }

  &:last-child:after {
    content: none;
  }

  ${media.mobile`
		width: 100%;
		margin: 10px 0;
	`}
`;

export const CategoryFolder = styled.h6`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 2px;
  line-height: 1.25;
`;

export const CatergoryItem = styled.p`
  font-size: 12px;
  font-weight: 700;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default CategoryBreadcrumbs;
