import React from "react";
import { Transition, animated } from "react-spring/renderprops";
import PracticeQuestion from "components/practiceAssignment/PracticeQuestion";

const PracticeQuestions = ({
  questions,
  currentQuestionIndex,
  isAnimationForward,
  handlePreviousQuestionButtonClick,
  handleNextQuestionButtonClick,
  openstax_resource,
}) => {
  if (!questions.length) {
    return null;
  }

  const animationIn = {
    from: { opacity: 0, transform: "translate3d(0px, 500px, 0px)" },
    enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
    leave: { opacity: 0, transform: "translate3d(500px, 0px, 0px)" },
  };

  const animationOut = {
    from: { opacity: 0, transform: "translate3d(500px, 0px, 0px)" },
    enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
    leave: { opacity: 0, transform: "translate3d(0px, 500px, 0px)" },
  };

  const animation = isAnimationForward ? animationIn : animationOut;

  return (
    <Transition unique reset native items={currentQuestionIndex} {...animation}>
      {(currentQuestionIndex) => (style) => {
        return (
          <animated.div style={{ ...style }}>
            <PracticeQuestion
              question={questions[currentQuestionIndex]}
              index={currentQuestionIndex}
              onPreviousQuestionButtonClick={handlePreviousQuestionButtonClick}
              onNextQuestionButtonClick={handleNextQuestionButtonClick}
              openstax_resource={openstax_resource}
            />
          </animated.div>
        );
      }}
    </Transition>
  );
};

export default PracticeQuestions;
