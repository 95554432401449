/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import ButtonLink from "components/common/ButtonLink";
import theme from "styles/theme";
import { media } from "styles/media";
import IconSvgComponent from "components/common/IconSvgComponent";

/**
 * The internal dependencies.
 */
import { openModal } from "store/state/ui/actions";
import { MODAL_ACTIVATION, MODAL_USER_LIMIT_REACHED } from "lib/constants";

/**
 * Class for table dropdown.
 *
 * @class      TableDropdown (name)
 */
class TableDropdown extends Component {
  state = {
    isActive: false,
  };

  toggle = (e) => {
    e.preventDefault();

    this.setState(({ isActive }) => ({
      isActive: !isActive,
    }));
  };

  close = () => {
    this.setState({
      isActive: false,
    });
  };

  edit = (e) => {
    this.props.edit(e);
    this.close();
  };

  documentClick = (e) => {
    const el = this.dropdownWrapper;
    const target = e.target;

    if (el !== target && !el.contains(target)) {
      this.close();
    }
  };

  handleActivation = () => {
    if (this.props.usersLimitReached && this.props.status === "not-active") {
      this.props.openModal({
        type: MODAL_USER_LIMIT_REACHED,
      });
    } else {
      this.props.openModal({
        type: MODAL_ACTIVATION,
        data: {
          id: this.props.id,
          accountId: this.props.accountId,
          status: this.props.status,
          title: this.props.title,
          type: this.props.type,
        },
      });
    }
    this.close();
  };

  handleUsersRoute = () => {
    this.props.history.push(`accounts/${this.props.id}`);
  };

  customClick = (callback) => (e) => {
    e.preventDefault();

    callback(e);

    this.close();
  };

  componentDidMount() {
    document.addEventListener("click", this.documentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.documentClick);
  }

  render() {
    const { status, options, hideManageUsers, isSettingsButton } = this.props;

    return (
      <Wrapper className="dropdown-wrapper" innerRef={(el) => (this.dropdownWrapper = el)}>
        {isSettingsButton && (
          <ButtonLink isTableDropdownButton onClick={this.toggle}>
            <IconSvgComponent iconPath="svg/ico-cogwheel.svg" />
          </ButtonLink>
        )}

        {!isSettingsButton && (
          <ButtonLink isTableDropdownButton onClick={this.toggle}>
            <span></span>

            <span></span>

            <span></span>
          </ButtonLink>
        )}

        <DropdownWrapper active={this.state.isActive}>
          {options ? (
            <Dropdown>
              {options.items.map((item, idx) => (
                <DropdownItem key={`dropdown-item-${idx}`}>
                  <DropdownButton
                    onClick={this.customClick(item.callback)}
                    first={0 === idx}
                    last={options.items.length - 1 === idx}
                    disabled={item.disabled}
                  >
                    {item.btnText}
                  </DropdownButton>
                </DropdownItem>
              ))}
            </Dropdown>
          ) : (
            <Dropdown>
              <DropdownItem>
                <DropdownButton onClick={this.edit} first>
                  Edit
                </DropdownButton>
              </DropdownItem>

              {!hideManageUsers && (
                <DropdownItem>
                  <DropdownButton onClick={this.handleUsersRoute}>Manage Users</DropdownButton>
                </DropdownItem>
              )}

              <DropdownItem>
                <DropdownButton onClick={this.handleActivation} last>
                  {status && status === "active" ? "Deactivate" : "Activate"}
                </DropdownButton>
              </DropdownItem>
            </Dropdown>
          )}
        </DropdownWrapper>
      </Wrapper>
    );
  }
}

export const Wrapper = styled.div`
  /* position: relative; */
  /* position: absolute;
  display: inline-block;
  font-size: 0;
  line-height: 0; */
  margin-right: 24px;

  ${media.mobile`
    margin-right: 0
  `}
`;

export const TableDropdownButton = styled.button`
  display: inline-flex;
  align-items: center;
  height: 25px;
  background: none;
  border: 2px solid transparent !important;
  border-radius: 4px;
  padding: 2px 6px 2px 6px;
  margin-top: -2px;
  margin-right: -8px;

  span {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #ccc;
  }

  span + span {
    margin-left: 3px;
  }

  &:focus {
    border: 2px solid #2049e6 !important;
  }
`;

export const DropdownWrapper = styled.div`
 position: absolute;
 top: 0;
 right: 0;
 z-index: 10;


  border-radius: ${theme.border_radius};
  /* right: -16px;
  top: calc(100% + 10px); */
  width: 221px;
  background: #fff;
  box-shadow: 0 0 7px -2px rgba(0,0,0,0.50), 0 41px 51px -6px rgba(0,0,0,0.27);
  visibility: hidden;
  opacity: 0;
  transition: visibility .3s, opacity .3s, z-index .3s;

  &:before {
    /* content: '';
    position: absolute;
    z-index: 5;
    bottom: calc(100% - 12px);
    right: 15px;
    width: 20px;
    height: 22px;
    background: #fff;
    transform: rotate(-45deg);
    box-shadow: 0 0 7px -2px rgba(0,0,0,0.50), 0 41px 51px -6px rgba(0,0,0,0.27); */
  }

  ${(props) =>
    props.active &&
    `
    visibility: visible;
    opacity: 1;
    z-index: 1000;
  `}

  ${(props) =>
    props.auto &&
    `
    left: 0;
    right: auto;
    top: 100%;
    width: 100%;

    &:before {
      display: none;
    }
  `}

  ${(props) =>
    props.short &&
    `
    max-height: 158px;
    overflow: auto;
  `}
`;

export const Dropdown = styled.ul`
  list-style-type: none;
  position: relative;
  /* z-index: 10; */
  transform: rotate(0deg);
  background: #fff;
  font-size: 14px;
  border-radius: ${theme.border_radius};
`;

export const DropdownItem = styled.li`
  border-bottom: 1px solid #d8d8d8;
  border-radius: ${theme.border_radius};
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  ${(props) =>
    props.blue &&
    `
    background: #1DB2E3;
    color: #F6F6F6;
    overflow-wrap: break-word;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}

  &:last-child {
    border: none;
  }
`;

export const DropdownButton = styled.button`
  display: block;
  width: 100%;
  padding: 17px 25px;
  background: none;
  border: none;
  text-align: left;
  transition: background 0.3s, color 0.3s;
  font-weight: bold;

  ${(props) =>
    !props.fixColor &&
    `
        &:hover {
            background: #E3EBFA;
            color: #4A4A4A;
            border-radius: 0px;
        }
   `}

   ${(props) =>
     props.first &&
     `
        &:hover {
            border-radius: ${theme.border_radius} !important;
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
   `}

    ${(props) =>
      props.last &&
      `
        &:hover {
            border-bottom-left-radius: ${theme.border_radius} !important;;
            border-bottom-right-radius: ${theme.border_radius} !important;;
        }
   `}

    ${(props) =>
      props.active &&
      `
    background: #E3EBFA;
        color: #4A4A4A;
        border-radius: ${theme.border_radius};
    `}

    ${(props) =>
      props.handleFocus &&
      `
        margin-left:0px !important;

        &:focus {
            background: #1DB2E3;
            color: #F6F6F6;
            overflow-wrap: break-word;
            border-radius: 5px;
        }
    `}
    ${(props) =>
      props.noWrap &&
      `
        white-space: nowrap;
        &:hover {
            border-radius: 5px;
        }
        `}

  ${(props) =>
    props.handleFocus &&
    `
        &:focus {
            background: #1DB2E3;
            color: #F6F6F6;
            border-radius: 5px;
            }
	`}
${(props) =>
  props.handleLogoutWidgetFocus &&
  `
        margin-left:0px !important;

        &:focus {
            background: #E3EBFA;
            color: #4A4A4A;
            overflow-wrap: break-word;
            border-radius: 5px;
        }
    `}

${(props) =>
  props.borderOnFocus &&
  `
        &:focus {
            border: 2px solid #0b289b !important
            border-radius: 5px;
            padding: 17px 23px;
        }
    `}

      ${(props) =>
        props.disabled &&
        `
    color: ${theme.color.gray}
    &:hover {
        color: ${theme.color.gray}
        background: none;
        border-radius: 0px;
      }
  `}

`;

export default withRouter(
  connect(null, {
    openModal,
  })(TableDropdown)
);
