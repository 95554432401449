import React, { Component } from "react";
import IconSvgComponent from "components/common/IconSvgComponent";
import stemifyRoutes from "constants/routes";
import { ProfileWidgetHolder } from "styles/components/ProfileWidget";
import DropdownMenu, {
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuBody,
} from "styles/components/DropdownMenu";
import ButtonLink from "components/common/ButtonLink";
import { Dropdown, DropdownItem, DropdownButton } from "./TableDropdown";
import { withRouter } from "react-router";
import { toggleGlobalOverlay } from "store/state/ui/actions";
import { connect } from "react-redux";
import GlobalOverlay from "styles/components/GlobalOverlay";
import Tooltip from "styles/components/Tooltip";

class HelpWidgetComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileDropdownActive: false,
    };
  }

  toggleProfileDropdown = () => {
    this.setState(({ profileDropdownActive }) => ({
      profileDropdownActive: !profileDropdownActive,
    }));

    this.props.toggleGlobalOverlay();
  };

  handleRedirect = (url) => {
    const { history } = this.props;
    if (url === stemifyRoutes.stemifyGuide) {
      window.open(stemifyRoutes.stemifyGuide, "_blank");
    } else {
      history.push(url);
    }
  };

  render() {
    const { profileDropdownActive } = this.state;
    return (
      <div>
        <Tooltip
          id="externalLinkNotification"
          place="left"
          // type="info"
          effect="solid"
        >
          Clicking this link takes you to the Stemify support website.
        </Tooltip>
        <ProfileWidgetHolder>
          <DropdownMenu active={profileDropdownActive}>
            <DropdownMenuTrigger
              data-tip
              data-for="externalLinkNotification"
              aria-haspopup="true"
              role="link"
              onClick={() => this.handleRedirect(stemifyRoutes.stemifyGuide)}
            >
              <ButtonLink isBtnUser className="LinkBtn-Help">
                <IconSvgComponent iconPath="svg/ico-help.svg" additionalClass="Icon-Centre-Grey" />
              </ButtonLink>
              {/* <DropdownMenuContent isHelpBtn>
                <DropdownMenuBody isHelpBtn>
                  <Dropdown>
                    <DropdownItem>
                      <DropdownButton
                        noWrap
                        handleFocus
                        onClick={() => this.handleRedirect(stemifyRoutes.faq)}
                      >
                        FAQ
                      </DropdownButton>
                    </DropdownItem>
                    <DropdownItem>
                      <DropdownButton
                        noWrap
                        handleFocus
                        onClick={() => this.handleRedirect(stemifyRoutes.stemifyGuide)}
                      >
                        Knowledge Base
                      </DropdownButton>
                    </DropdownItem>
                  </Dropdown>
                </DropdownMenuBody>
              </DropdownMenuContent> */}
            </DropdownMenuTrigger>
          </DropdownMenu>

          {/* <GlobalOverlay onClick={this.toggleProfileDropdown} visible={profileDropdownActive} /> */}
        </ProfileWidgetHolder>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    globalOverlayIsVisible: state.ui.globalOverlayIsVisible,
  }),
  (dispatch) => ({
    toggleGlobalOverlay: () => dispatch(toggleGlobalOverlay()),
  })
)(withRouter(HelpWidgetComponent));
