import { createAction } from "redux-actions";
import { OPERATIONS, ATTEMPT_STATUSES, ASSESSMENT_TYPES } from "constants/common";
import { MODAL_ASSESSMENT_TIME_END, MODAL_DASHBOARD_RESUME_ATTEMPT } from "lib/constants";
import { COMMON_MESSAGES } from "constants/messages";
import { getUserCurrentAccount, decorateQnAResponse } from "utilities/commonFunctions";
import { convertKeysToCamelCase } from "utilities/API";
import { openModal } from "store/state/ui/actions";
import {
  fetchCourseAssessment,
  fetchAssessmentSeries,
  fetchPlacementAssessment,
  submitPlacementAssessment,
  submitAttempt as submitAttemptAPI,
  getAttempts as getAttemptsAPI,
  beginAttempt as beginAttemptAPI,
  resumeAttempt as resumeAttemptAPI,
  updateQuestionResponse as updateQuestionResponseAPI,
  getAssignmentById as getAssignmentByIdAPI,
  getAttemptStats as getAttemptStatsAPI,
} from "services/assessment";
import moment from "moment";

export const startCountdown = createAction("START_COUNTDOWN");
export const sidebarToggle = createAction("SIDEBAR_TOGGLE");
export const setActiveAssessment = createAction("SET_ACTIVE_ASSESSMENT");

export const setCurrentQuestion = createAction("SET_CURRENT_QUESTION");
export const setCompletedQuestion = createAction("SET_COMPLETED_QUESTION");

export const setPinnedQuestion = createAction("SET_PINNED_QUESTION");

export const requestAssessmentSeries = createAction("REQUEST_ASSESSMENT_SERIES");
export const listAssessmentSeries = createAction("LIST_ASSESSMENT_SERIES");
export const rejectAssessmentSeries = createAction("REJECT_ASSESSMENT_SERIES");

export const requestPlacementAssessment = createAction("REQUEST_PLACEMENT_ASSESSMENT");
export const receivePlacementAssessment = createAction("RECEIVE_PLACEMENT_ASSESSMENT");
export const rejectPlacementAssessment = createAction("REJECT_PLACEMENT_ASSESSMENT");

export const requestCourseAssessment = createAction("REQUEST_COURSE_ASSESSMENT");
export const receiveCourseAssessment = createAction("RECEIVE_COURSE_ASSESSMENT");
export const rejectCourseAssessment = createAction("REJECT_COURSE_ASSESSMENT");

export const requestUpdateQuestionResponse = createAction("REQUEST_UPDATE_QUESTION_RESPONSE");
export const receiveUpdateQuestionResponse = createAction("RECEIVE_UPDATE_QUESTION_RESPONSE");
export const rejectUpdateQuestionResponse = createAction("REJECT_UPDATE_QUESTION_RESPONSE");

export const requestCompletePlacementAssessment = createAction(
  "REQUEST_COMPLETE_PLACEMENT_ASSESSMENT"
);
export const receiveCompletePlacementAssessment = createAction(
  "RECEIVE_COMPLETE_PLACEMENT_ASSESSMENT"
);
export const rejectCompletePlacementAssessment = createAction(
  "REJECT_COMPLETE_PLACEMENT_ASSESSMENT"
);

export const requestSubmitAttempt = createAction("REQUEST_SUBMIT_ATTEMPT");
export const receiveSubmitAttempt = createAction("RECEIVE_SUBMIT_ATTEMPT");
export const rejectSubmitAttempt = createAction("REJECT_SUBMIT_ATTEMPT");

export const selectAssessmentSeries = createAction("SELECT_ASSESSMENT_SERIES");

export const requestGetAttempts = createAction("REQUEST_GET_ATTEMPTS");
export const rejectGetAttempts = createAction("REJECT_GET_ATTEMPTS");
export const receiveGetAttempts = createAction("RECEIVE_GET_ATTEMPTS");
export const receiveOpenAttempt = createAction("RECEIVE_OPEN_ATTEMPT");

export const requestGetOpenAttempts = createAction("REQUEST_GET_OPEN_ATTEMPTS");
export const rejectGetOpenAttempts = createAction("REJECT_GET_OPEN_ATTEMPTS");

export const requestBeginAttempt = createAction("REQUEST_BEGIN_ATTEMPT");
export const receiveBeginAttempt = createAction("RECEIVE_BEGIN_ATTEMPT");
export const rejectBeginAttempt = createAction("REJECT_BEGIN_ATTEMPT");

export const requestResumeAttempt = createAction("REQUEST_RESUME_ATTEMPT");
export const rejectResumeAttempt = createAction("REJECT_RESUME_ATTEMPT");
export const receiveResumeAttempt = createAction("RECEIVE_RESUME_ATTEMPT");

export const receiveGetExpiredAttemptAsResult = createAction("RECEIVE_EXPIRED_ATTEMPT");
export const requestGetExpiredAttemptAsResult = createAction("REQUEST_EXPIRED_ATTEMPT");
export const rejectGetExpiredAttemptAsResult = createAction("REJECT_EXPIRED_ATTEMPT");

export const clearAttempt = createAction("CLEAR_ATTEMPT");

export const getAssessmentSeries = () => {
  return async (dispatch, getState) => {
    dispatch(requestAssessmentSeries({ operation: OPERATIONS.LIST }));
    try {
      const state = getState();
      const accountId = getUserCurrentAccount(state);

      let response = await fetchAssessmentSeries({
        accountId: accountId,
      });

      if (response && response.status === "success") {
        dispatch(listAssessmentSeries({ items: response.data.rows }));

        return response;
      } else {
        if (response && response.status === "error") {
          dispatch(
            rejectAssessmentSeries({
              operation: OPERATIONS.LIST,
              errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
            })
          );
          return response;
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectAssessmentSeries({
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getPlacementAssessment = ({ deploymentId, assessmentSeriesId, accessCode }) => {
  return async (dispatch, getState) => {
    dispatch(
      requestPlacementAssessment({
        operation: OPERATIONS.GET,
        assessmentSeriesId,
      })
    );
    try {
      const response = await fetchPlacementAssessment({
        deploymentId,
        assessmentSeriesId,
        accessCode,
      });
      if (response && response.status === "success") {
        dispatch(
          receivePlacementAssessment({
            data: decorateQnAResponse(response.data),
            assessmentId: assessmentSeriesId,
            deploymentId,
          })
        );

        return response;
      } else {
        if (response && response.status === "error") {
          dispatch(
            rejectPlacementAssessment({
              operation: OPERATIONS.GET,
              errorItem: { message: response.data.message },
            })
          );
          return response;
        }
      }
    } catch (e) {
      dispatch(
        rejectPlacementAssessment({
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getExpiredAttemptAsResult = ({
  assignmentId,
  deploymentConfigId,
  attemptId,
  assessmentSeriesId,
  page = 1,
  pageSize = 0,
}) => {
  return async (dispatch, getState) => {
    try {
      const response = assignmentId
        ? await dispatch(getAttempts({ attemptId, includeModel: true }))
        : await getAttemptStatsAPI({ assessmentId: assessmentSeriesId, attemptId });

      if (response.status === "success" || Array.isArray(response)) {
        const responseData = response.data
          ? response.data
          : {
              total_score: response[0].score,
              correct_questions_count: response[0].questions_correct,
              total_questions: response[0].assessment.question_count,
              topic_breakdown: [],
              cooling_period: "Not Available",
            };
        dispatch(
          receiveGetExpiredAttemptAsResult({
            attempt: { ...convertKeysToCamelCase(responseData, ["topic_breakdown"]) },
          })
        );
        return response.data;
      } else {
        dispatch(rejectGetExpiredAttemptAsResult());
        return response;
      }
    } catch (e) {
      console.log(e);
      dispatch(
        rejectGetExpiredAttemptAsResult({
          operation: OPERATIONS.UPDATE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const submitAttempt = ({
  attemptId,
  assessmentSeriesId,
  deploymentConfigId,
  assignmentId,
}) => async (dispatch, getState) => {
  dispatch(requestSubmitAttempt());
  const state = getState();
  const accountId = getUserCurrentAccount(state);

  try {
    const response = await submitAttemptAPI({ accountId, attemptId });
    if (response.status === "success") {
      dispatch(
        receiveSubmitAttempt({
          data: {
            ...response.data,
            deploymentConfigId,
            assessmentId: assessmentSeriesId,
            assignmentId,
            attemptId,
          },
        })
      );
    } else {
      if (response && response.status === "error") {
        dispatch(
          rejectSubmitAttempt({
            operation: OPERATIONS.UPDATE,
            errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
          })
        );
        return response;
      }
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      rejectSubmitAttempt({
        operation: OPERATIONS.UPDATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const completePlacementAssessment = ({
  deploymentId,
  assessmentId,
  assignmentId,
  attemptId,
}) => {
  return async (dispatch, getState) => {
    dispatch(requestCompletePlacementAssessment({ operation: OPERATIONS.UPDATE }));

    try {
      const state = getState();
      const accountId = getUserCurrentAccount(state);

      const response = await submitPlacementAssessment({ accountId, attemptId });
      if (response && response.status === "success") {
        dispatch(
          receiveCompletePlacementAssessment({
            data: { ...response.data, deploymentId, assessmentId, assignmentId, attemptId },
          })
        );
        return response;
      } else {
        if (response && response.status === "error") {
          dispatch(
            rejectCompletePlacementAssessment({
              operation: OPERATIONS.UPDATE,
              errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
            })
          );
          return response;
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectCompletePlacementAssessment({
          operation: OPERATIONS.UPDATE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getOpenAttempts = ({
  username,
  assessmentSeriesId,
  assignmentId,
  attemptId,
  deploymentId,
  deploymentConfigId,
  page = 1,
  pageSize = 0,
} = {}) => async (dispatch, getState) => {
  dispatch(requestGetOpenAttempts());
  try {
    const openAttempts = await dispatch(
      getAttempts({
        username,
        status: ATTEMPT_STATUSES.OPEN,
        assessmentSeriesId,
        deploymentConfigId,
        assignmentId,
        attemptId,
        includeModel: true,
      })
    );

    if (openAttempts.length) {
      const decoratedResponseData = openAttempts.map((attempt) => convertKeysToCamelCase(attempt));
      // sort attempts by startTime
      const attemptsSortedByStartTime = decoratedResponseData.sort(
        (a, b) => moment(b.startTime) - moment(a.startTime)
      );
      const topAttempt = attemptsSortedByStartTime[0];
      const {
        assessment: { data: assessmentState },
        storedCourses: { assignments: assignmentsState },
      } = getState();

      // try to resume the attempt - if it's successful,
      const resumeResponse = await dispatch(
        resumeAttempt({
          type: topAttempt.deploymentId
            ? ASSESSMENT_TYPES.PLACEMENT
            : topAttempt.assignmentId
            ? ASSESSMENT_TYPES.EXAM
            : undefined,
          deploymentId: topAttempt.deploymentId,
          assignmentId: topAttempt.assignmentId,
        })
      );

      resumeResponse.status === "success"
        ? // if the top attempt is open, show the modal to resume the attempt
          dispatch(
            openModal({
              type: MODAL_DASHBOARD_RESUME_ATTEMPT,
              data: {
                attemptId: topAttempt.id,
                assessmentSeriesId: topAttempt.assessmentSeriesId,
                deploymentConfigId: topAttempt.deploymentId,
                assignmentId: topAttempt.assignmentId,
                courseId: topAttempt.assignment?.sectionId,
              },
            })
          )
        : // if the top attempt is expired, submit it and show the modal to view results
          dispatch(
            openModal({
              type: MODAL_ASSESSMENT_TIME_END,
              data: {
                shouldNotCloseOnEsc: true,
                attemptId: topAttempt.id,
                assignmentId: topAttempt.assignmentId,
                deploymentConfigId: topAttempt.deploymentId,
                assessmentSeriesId: topAttempt.assessmentSeriesId,
              },
            })
          );

      dispatch(
        receiveOpenAttempt({
          attempt: topAttempt,
          deploymentId,
          assessmentSeriesId,
        })
      );
      return openAttempts;
    }
    return [];
  } catch (e) {
    console.log(e.message);
    dispatch(
      rejectGetOpenAttempts({
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const getAttempts = ({
  username,
  status,
  assessmentSeriesId,
  assignmentId,
  attemptId,
  deploymentId,
  deploymentConfigId,
  includeModel,
  page = null,
  pageSize = null,
} = {}) => async (dispatch, getState) => {
  dispatch(requestGetAttempts({ operation: OPERATIONS.UPDATE }));
  const state = getState();
  const accountId = getUserCurrentAccount(state);

  try {
    const response = await getAttemptsAPI({
      accountId,
      username,
      status,
      assessmentSeriesId,
      deploymentId: deploymentConfigId,
      assignmentId,
      attemptId,
      includeModel,
      page,
      pageSize,
    });

    if (response && response.status === "success") {
      dispatch(receiveGetAttempts(response.data.map((attempt) => convertKeysToCamelCase(attempt))));
      return response.data;
    } else {
      if (response && response.status === "error") {
        dispatch(rejectGetAttempts({ ...response }));
        return response;
      }
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      rejectGetAttempts({
        operation: OPERATIONS.UPDATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const resumeAttempt = ({ type, deploymentId, assignmentId }) => async (
  dispatch,
  getState
) => {
  dispatch(requestResumeAttempt({ operation: OPERATIONS.UPDATE }));
  const state = getState();
  const accountId = getUserCurrentAccount(state);

  try {
    const response = await resumeAttemptAPI({
      accountId,
      type,
      deploymentId,
      assignmentId,
    });

    if (response.status === "success") {
      const decoratedAssessmentData = decorateQnAResponse(response.data.assessment);
      const {
        assessment_series_id: assessmentSeriesId,
        deployment_id: deploymentId,
        assignment_id: assignmentId,
      } = response.data.attempt;
      dispatch(
        receivePlacementAssessment({
          data: decoratedAssessmentData,
          assessmentId: assessmentSeriesId,
          deploymentId,
        })
      );
      dispatch(
        receiveResumeAttempt({
          assessment: convertKeysToCamelCase(response.data.assessment),
          attempt: convertKeysToCamelCase(response.data.attempt),
        })
      );
      return response;
    } else {
      if (response && response.status === "error") {
        dispatch(
          rejectPlacementAssessment({
            operation: OPERATIONS.GET,
            errorItem: { message: response.data.message },
          })
        );
        dispatch(rejectResumeAttempt());
        return response;
      }
    }
  } catch (e) {
    dispatch(
      rejectPlacementAssessment({
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    dispatch(rejectResumeAttempt());
    return false;
  }
};

export const beginAttempt = ({
  type,
  deploymentId: programCohortEnrollmentId,
  deploymentConfigId,
  assessmentSeriesId,
  assignmentId,
  accessCode,
}) => async (dispatch, getState) => {
  dispatch(requestBeginAttempt());
  const state = getState();
  const accountId = getUserCurrentAccount(state);

  try {
    const response = await beginAttemptAPI({
      accountId,
      type,
      deploymentConfigId,
      assignmentId,
      accessCode,
    });

    if (response.status === "success") {
      const decoratedAssessmentData = decorateQnAResponse(response.data.assessment);

      dispatch(
        receivePlacementAssessment({
          data: decoratedAssessmentData,
          assessmentId: assessmentSeriesId,
          deploymentId: programCohortEnrollmentId,
        })
      );
      dispatch(
        receiveBeginAttempt({
          attempt: convertKeysToCamelCase(response.data.attempt),
          assessment: convertKeysToCamelCase(response.data.assessment),
        })
      );
    } else {
      dispatch(
        rejectPlacementAssessment({
          operation: OPERATIONS.GET,
          errorItem: { message: response.data.message },
        })
      );
      dispatch(rejectBeginAttempt());
    }
    return response;
  } catch (e) {
    console.log(e);
    dispatch(
      rejectPlacementAssessment({
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    dispatch(rejectBeginAttempt());
  }
};

export const updateQuestionResponse = ({
  type,
  assignmentId,
  deploymentId,
  assessmentId,
  questionId,
  answerId,
  attemptId,
  isPinned,
}) => async (dispatch, getState) => {
  dispatch(requestUpdateQuestionResponse({ operation: OPERATIONS.UPDATE }));
  const state = getState();
  const accountId = getUserCurrentAccount(state);

  try {
    const response = await updateQuestionResponseAPI({
      type,
      assignmentId,
      deploymentId,
      assessmentId,
      questionId,
      answerId,
      attemptId,
      accountId,
      isPinned,
    });

    if (response && response.status === "success") {
      dispatch(receiveUpdateQuestionResponse({ ...response.data }));
      return response;
    } else {
      if (response && response.status === "error") {
        dispatch(rejectUpdateQuestionResponse({ ...response.status }));
        return response;
      }
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      rejectUpdateQuestionResponse({
        operation: OPERATIONS.UPDATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};
