import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import IconSvgComponent from "components/common/IconSvgComponent";

import { SectionQuestionItems, QuestionItem } from "styles/components/SectionBuilder";

/**
 * @ function to help us with reordering the result
 */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SortableItem = SortableElement(({ item, itemId, isActive, onQuestionClick }) => (
  <QuestionItem active={isActive} onClick={() => onQuestionClick(itemId)}>
    <span> {item} </span>
  </QuestionItem>
));

const SortableList = SortableContainer(({ items, activeId, onQuestionClick }) => {
  return (
    <SectionQuestionItems>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id}`}
          index={index}
          isActive={item.id === activeId}
          item={index + 1}
          itemId={item.id}
          onQuestionClick={onQuestionClick}
        />
      ))}
    </SectionQuestionItems>
  );
});

class QuestionIndicators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      activeIndex: "",
    };
  }

  componentDidMount() {
    this.setState({
      items: this.props.questions,
      activeId: this.props.activeItemId,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeItemId !== prevProps.activeItemId) {
      this.setState({
        items: this.props.questions,
        activeId: this.props.activeItemId,
      });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const reorderedQuestions = reorder(this.state.items, oldIndex, newIndex);

    this.setState({
      items: reorderedQuestions,
    });

    this.props.onReorder(reorderedQuestions);
  };

  onQuestionClick = (data) => {
    this.props.onQuestionClick(data);
  };

  render() {
    return (
      <SortableList
        items={this.state.items}
        activeId={this.state.activeId}
        onSortEnd={this.onSortEnd}
        onQuestionClick={(data) => this.onQuestionClick(data)}
        distance={1}
        axis={"xy"}
        helperClass="is-draggable"
      />
    );
  }
}

export default QuestionIndicators;
