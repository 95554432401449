import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";
import SearchComponent from "components/elements/SearchComponent";
import IconSvgComponent from "components/common/IconSvgComponent";
import Nav, {
  NavigationList,
  NavigationListItem,
  NavLink,
  Dropdown,
  NavigationOvarlay,
} from "styles/components/Navigation";
import Can from "components/common/Can";
import stemifyRoutes from "../../constants/routes";

const navItems = [
  {
    title: "Home",
    icon_path: "svg/ico-home.svg",
    link: `${stemifyRoutes.home}`,
    permissions: "home-page:view",
  },
  {
    title: "My Courses",
    icon_path: "svg/courses_nav_ic.svg",
    link: `${stemifyRoutes.courses}`,
    permissions: "courses:view",
  },
  {
    title: "Learning Library",
    icon_path: "svg/ico-modules.svg",
    link: `${stemifyRoutes.learningLibrary}`,
    parent: true,
    permissions: "learning-library-page:view",
  },
  {
    title: "Placement & Diagnostic",
    icon_path: "svg/ico-users.svg",
    link: "",
    permissions: "non-student-menu:view",
    parent: true,
    dropdown: [
      {
        title: "Deployments",
        icon_path: "svg/ico-rocket.svg",
        link: `${stemifyRoutes.deployments}`,
        permissions: "deployments:view",
      },
      {
        title: "Cohorts",
        icon_path: "svg/ico-users.svg",
        link: `${stemifyRoutes.cohorts}`,
        permissions: "cohorts-page:view",
      },
      {
        title: "Assessments",
        icon_path: "svg/assessments_nav_ic.svg",
        link: `${stemifyRoutes.assessments}`,
        permissions: "placement-assessment:view",
      },
    ],
  },
  {
    title: "Placement Assessment",
    icon_path: "svg/assessments_nav_ic.svg",
    link: `${stemifyRoutes.assessments}`,
    permissions: "student-menu:view",
  },
  {
    title: "User Management",
    icon_path: "svg/ico-merch.svg",
    link: `${stemifyRoutes.accounts}`,
    permissions: "users-page:view",
  },
  {
    title: "FAQ",
    icon_path: "svg/ico-help-small.svg",
    link: `${stemifyRoutes.faq}`,
    permissions: "faq:view",
  },
  {
    title: "Admin",
    icon_path: "svg/ico-pencil.svg",
    link: "",
    permissions: "admin:view",
    parent: true,
    dropdown: [
      {
        title: "Attempts",
        icon_path: "svg/ico-users.svg",
        link: "/admin/attempts",
        permissions: "admin:view",
      },
      {
        title: "Banner",
        icon_path: "svg/ico-rocket.svg",
        link: "/admin/banner",
        permissions: "admin:view",
      },
      {
        title: "OpenStax",
        icon_path: "svg/courses_nav_ic.svg",
        link: "/admin/openstax",
        permissions: "admin:view",
      },
      {
        title: "Assessments",
        icon_path: "svg/assessments_nav_ic.svg",
        link: "/admin/assessments",
        permissions: "admin:view",
      },
    ],
  },
];

const NavigationListItemContainer = ({
  item,
  index,
  activeIndex,
  childClicked,
  handleNavItemClick,
}) => {
  const isDropdownActive = item.dropdown && activeIndex === index && !childClicked;
  let dropdown;
  return (
    <>
      <NavLink
        id={item.title}
        hasDropdown={!!item.dropdown}
        current={activeIndex === index}
        onClick={handleNavItemClick(item.link, index, !!item.dropdown)}
      >
        <IconSvgComponent iconPath={`${item.icon_path}`} />
        {item.title}
        {item.dropdown && (
          <IconSvgComponent
            iconPath="svg/ico-arrow-right-color.svg"
            additionalClass={isDropdownActive ? "ico-arrow rotate-left" : "ico-arrow rotate-right"}
          />
        )}
      </NavLink>

      {isDropdownActive && (
        <Dropdown
          childClicked={childClicked}
          className="dropdown"
          innerRef={(comp) => {
            dropdown = comp;
          }}
        >
          {item.dropdown.map((innerItem, idx) => (
            <NavigationListItem key={innerItem.title}>
              <NavLink
                to={innerItem.link}
                onClick={handleNavItemClick(innerItem.link, idx, !!innerItem.dropdown)}
              >
                <IconSvgComponent iconPath={`${innerItem.icon_path}`} />
                {innerItem.title}
              </NavLink>
            </NavigationListItem>
          ))}
        </Dropdown>
      )}
    </>
  );
};

class Navigation extends Component {
  state = {
    activeIndex: null,
    childClicked: false,
  };

  shouldComponentUpdate() {
    if (this.state.childClicked) {
      this.setState({ childClicked: false });
      return false;
    }
    return true;
  }

  handleNavItemClick = (link, index, parentMenu) => (event) => {
    event.preventDefault();
    this.setState(({ activeIndex }) => ({
      activeIndex: index !== activeIndex ? index : null,
    }));

    if (!parentMenu) {
      this.setState({ childClicked: true });
      this.props.triggerCloseNavigation();
      this.props.history.push(link);
    } else {
      this.setState({ childClicked: false });
    }
  };

  render() {
    const { activeIndex, childClicked } = this.state;
    const { isOpen, triggerCloseNavigation, userRole, bannerOffset } = this.props;

    return (
      <Nav className={this.props.isOpen ? "nav-open" : ""}>
        <MediaQuery query="(max-device-width: 767px)">
          <SearchComponent />
        </MediaQuery>
        <NavigationList id={"navMenuList"} style={{ paddingTop: bannerOffset }}>
          {navItems.map((item, index) => (
            <Can
              key={index}
              role={userRole}
              perform={item.permissions}
              yes={() => (
                <NavigationListItemContainer
                  item={item}
                  index={index}
                  activeIndex={activeIndex}
                  childClicked={childClicked}
                  current={activeIndex === index}
                  hasDropdown={!!item.dropdown}
                  handleNavItemClick={this.handleNavItemClick}
                />
              )}
              no={() => {
                if (process.env.REACT_APP_DISABLE_LOGIN) {
                  return (
                    <NavigationListItemContainer
                      item={item}
                      index={index}
                      activeIndex={activeIndex}
                      childClicked={childClicked}
                      current={activeIndex === index}
                      hasDropdown={!!item.dropdown}
                      handleNavItemClick={this.handleNavItemClick}
                    />
                  );
                }
                return null;
              }}
            />
          ))}
        </NavigationList>
        <NavigationOvarlay expand={isOpen} onClick={triggerCloseNavigation} />
      </Nav>
    );
  }
}

export default withRouter(Navigation);
