/**
 * The external dependecies
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import FormCreateSection from "components/form/FormCreateSection";

/**
 * @class      ModalCreateSection (name)
 * @return     {JSX}
 */
const ModalCreateSection = (props) => {
  return (
    <ModalFrame>
      <FormCreateSection />
    </ModalFrame>
  );
};

export default ModalCreateSection;
