import moment from "moment";
import states from "data/states.json";

const keyCodesAllowed = [8, 9, 17, 35, 36, 37, 38, 39, 40, 46]; //Backspace, Tab, Arrow keys, Delete keys
const keysCombinedWithCtrl = [65, 67, 86, 88]; //A, C, V, X keys
const keyCodesCutCopyPaste = [67, 86, 88]; //C, V, X keys
var ctrlKeyPressed = false;

export const limitText = (e, id, count, focusNextElement = false, nextId = null) => {
  count = Number(count);
  let element = document.getElementById(id);
  if (
    element.value.length >= count &&
    !keyCodesAllowed.includes(e.keyCode) &&
    !(keysCombinedWithCtrl.includes(e.keyCode) && (e.ctrlKey === true || e.metaKey === true))
  ) {
    if (element.selectionEnd - element.selectionStart === 0) {
      element.value = element.value.substr(0, count);
      e.preventDefault();
      if (focusNextElement) {
        var nextElement = document.getElementById(nextId);
        if (nextElement) {
          //Position cursor to the beginning of next element
          // if (nextElement.createTextRange) {
          //     var part = nextElement.createTextRange();
          //     part.move("character", 0);
          //     part.select();
          // } else
          if (nextElement.setSelectionRange) {
            nextElement.setSelectionRange(0, count);
          }
          //Focus next element
          nextElement.focus();
        }
      }
    }
  }
};

export const selectInputText = (e) => {
  let element = e.target;
  let count = element.value ? element.value.length : 0;
  if (element.setSelectionRange) {
    element.setSelectionRange(0, count);
  }
};

export const allowOnlyDigits = (e) => {
  if (isNaN(e.key) && !keyCodesAllowed.includes(e.keyCode)) {
    e.preventDefault();
  }
};

export const setMetaKey = (e) => {
  if (e.key === "Control" || e.key === "Meta") {
    ctrlKeyPressed = true;
  }
};

export const unsetMetaKey = (e) => {
  if (e.key === "Control") {
    ctrlKeyPressed = false;
  }
};

export const preventPaste = (e) => {
  if (ctrlKeyPressed && keyCodesCutCopyPaste.includes(e.keyCode)) {
    e.preventDefault();
    return false;
  }
};

export const disableContextMenu = (e) => {
  if (!e.target.getAttribute("oncontextmenu")) {
    e.target.setAttribute("oncontextmenu", "return false;");
  }
};

export const getStateName = (stateCode) => {
  let stateName = states.find((state) => state.code === stateCode);
  return stateName ? stateName.name : stateCode;
};

export const getLabel = (list, code) => {
  let option = list.find((item) => item.value === code);
  return option ? option.label : code;
};

export const getValueFromKey = (list, keyName, valueName, key) => {
  let option = list.find((item) => item[keyName] === key);
  return option ? option[valueName] : "";
};

export const trimStrings = (param) => {
  try {
    if (typeof param === "string") {
      return param.trim();
    }
    if (typeof param === "object") {
      for (var key in param) {
        param[key] = trimStrings(param[key]);
      }
    }
  } catch (e) {}
  return param;
};

export const getUserCurrentAccount = (state) => {
  const { user } = state;
  const { accountId, currentAccountId } = user;
  return currentAccountId ? currentAccountId : accountId;
};

export const decorateQnAResponse = (resp) => {
  resp = {
    ...resp,
    questions: resp.questions.map((q) => {
      var qDisplay = "radio";
      if (q.description.find((qi) => qi.type == "IMAGE") != undefined) {
        qDisplay = "image";
      }
      return {
        ...q,
        answers: q.answers.map((a) => {
          let display = "radio";
          if (a.description.find((i) => i.type === "IMAGE") != undefined) {
            display = "image";
          }
          return { ...a, displayType: display };
        }),
        displayType: qDisplay,
      };
    }),
  };
  return resp;
};

export const removeKeysByValues = (obj, excludedValuesArray) => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === "object" && !val.length && delete obj[key]) ||
      (excludedValuesArray.indexOf(val) !== -1 && delete obj[key])
  );
  return obj;
};

export const capitalizeFirstChar = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Get random color from specified color array
 */
export function getRandomColor() {
  const colorArray = ["#2049E6", "#1FC9FF", "#FF3E1D", "#16B5F2", "#E70F0E", "#0B289B"];
  return colorArray[Math.floor(Math.random() * colorArray.length)];
}

export const COMPARE_TYPES = {
  DATE: "date",
  ALPHABETICAL: "alphabetical",
};

const compareDates = (dateOne, dateTwo) => {
  // if dateOne is before dateTwo return -1
  if (moment(dateOne).isBefore(dateTwo)) return -1;
  // if dateOne is after dateTwo return 1
  if (moment(dateOne).isAfter(dateTwo)) return 1;
  // if dateOne is same as dateTwo return 0
  if (moment(dateOne).isSame(dateTwo)) return 0;
};

const lexicalCompareString = (stringOne, stringTwo) => {
  return stringOne.localeCompare(stringTwo);
};

const getCompareFunction = (type) => {
  switch (type) {
    case COMPARE_TYPES.DATE:
      return compareDates;
    case COMPARE_TYPES.ALPHABETICAL:
      return lexicalCompareString;
    default:
      break;
  }
};

export const multiSort = ({ items, operations }) => {
  const newItems = [...items];
  newItems.sort((a, b) => {
    const comparisons = operations.map(({ type, key, order }) => {
      const compareFunction = getCompareFunction(type);
      return compareFunction(a[key], b[key]);
    });
    let sortValue;
    for (let i = 0; i <= comparisons.length - 1; i++) {
      if (comparisons[i] !== 0 || i === comparisons.length - 1) {
        sortValue = comparisons[i];
        break;
      }
    }
    return sortValue;
  });
  return newItems;
};

export const ascendingAlphaSort = (key) => (a, b) => {
  return a[key] === b[key] ? 0 : a[key] < b[key] ? -1 : 1;
};

export const padTime = (number) => (number < 10 ? `0${number}` : number);

export const getDebounced = (action, delay) => {
  let timeout;
  const debounceAction = () => {
    action();
    clearTimeout(timeout);
    timeout = "";
  };
  return () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = "";
    }
    timeout = setTimeout(debounceAction, delay);
  };
};
