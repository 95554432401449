/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import IconSvgComponent from "components/common/IconSvgComponent";

export const NextArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <IconSvgComponent iconPath="svg/ico-next.svg" />
  </div>
);

export const PrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={{ ...style }} onClick={onClick}>
    <IconSvgComponent iconPath="svg/ico-next.svg" />
  </div>
);
