import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { resetForgottenPassword } from "store/state/user/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Form, {
  FormBody,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
  StyledError,
  Wrapper,
  LoginWrapper,
  StyledFormWrapper,
  StyledHead,
  StyledIconWrapper,
  StyledTitle,
  StyledFormDescription,
  StyledBody,
} from "styles/components/Form";
import { validateField } from "utilities/validation";
import { FORM_CHANGE_PASSWORD_MESSAGES } from "constants/messages";
import FieldErrors from "../common/FieldErrors";

class FormChangePassword extends Component {
  state = {
    email: "",
    resetCode: "",
    newPassword: "",
    repeatNewPassword: "",
    formErrors: {
      email: {
        valid: true,
        message: "",
      },
      resetCode: {
        valid: true,
        message: "",
      },
      newPassword: {
        valid: true,
        message: "",
      },
      repeatNewPassword: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
    formSubmitted: false,
  };

  rules = {
    email: {
      requiredMessage: FORM_CHANGE_PASSWORD_MESSAGES.EMAIL_REQUIRED,
    },
    confirmationCode: {
      requiredMessage: FORM_CHANGE_PASSWORD_MESSAGES.RESET_CODE_REQUIRED,
    },
    newPassword: {
      requiredMessage: FORM_CHANGE_PASSWORD_MESSAGES.NEW_PASSWORD_REQUIRED,
      // eslint-disable-next-line no-useless-escape
      expression: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{6,}$/,
    },
    repeatNewPassword: {
      requiredMessage: FORM_CHANGE_PASSWORD_MESSAGES.REPEAT_NEW_PASSWORD_REQUIRED,
    },
  };

  handleFieldChange = (e) => {
    const element = e.target;
    this.setState({ [element.name]: element.value.trim() }, () => {
      // const { formErrors } = this.state;
      // const el = document.getElementById(element.id);
      // this.setState({
      //   formErrors: validateField(formErrors, this.rules, el, '', true)
      // });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, newPassword, resetCode } = this.state;

    const handleSubmissionCallback = async (formErrors) => {
      if (formErrors.formValid) {
        await this.props.resetForgottenPassword({
          username: email,
          newPassword,
          confirmationCode: resetCode,
        });
        this.props.apiError.code === -1 && this.setState({ formSubmitted: true });
      } else {
        this.setState({
          formSubmitted: false,
        });
      }
    };

    this.validateAllFields(handleSubmissionCallback);
  };

  validateAllFields = (callback) => {
    const { formErrors } = this.state;

    let formErrorMessages = {};
    formErrorMessages.formValid = true;

    const emailElement = document.getElementById("email");
    const { email } = validateField(formErrors, this.rules, emailElement, "", true);
    formErrorMessages.email = email;

    const resetCodeElement = document.getElementById("resetCode");
    const { resetCode } = validateField(formErrors, this.rules, resetCodeElement, "", true);
    formErrorMessages.resetCode = resetCode;

    const newPasswordElement = document.getElementById("newPassword");
    const { newPassword } = validateField(formErrors, this.rules, newPasswordElement, "", true);
    formErrorMessages.newPassword = newPassword;

    if (!newPassword.valid) {
      formErrorMessages.passwordLength = {
        valid: false,
        message: "Password must contain at least 6 characters",
      };
      formErrorMessages.passwordLowerCase = {
        valid: false,
        message: "Password must contain at least 1 lower case letter",
      };
      formErrorMessages.passwordUpperCase = {
        valid: false,
        message: "Password must contain at least 1 upper case letter",
      };
      formErrorMessages.passwordNumber = {
        valid: false,
        message: "Password must contain at least 1 number",
      };
      formErrorMessages.newPassword = { valid: true, message: "" }; // tricking validator
      formErrorMessages.formValid = false;
    }

    const repeatNewPasswordElement = document.getElementById("repeatNewPassword");
    const { repeatNewPassword } = validateField(
      formErrors,
      this.rules,
      repeatNewPasswordElement,
      "",
      true
    );
    formErrorMessages.repeatNewPassword = repeatNewPassword;

    const passwordsMatch = this.state.repeatNewPassword === this.state.newPassword;
    if (!passwordsMatch) {
      formErrorMessages.repeatNewPassword = { valid: false, message: "Passwords must match" };
      formErrorMessages.formValid = false;
    }

    this.setState({ formErrors: formErrorMessages }, () => {
      callback(this.state.formErrors);
    });
  };

  render() {
    const {
      email,
      resetCode,
      newPassword,
      repeatNewPassword,
      formErrors,
      formSubmitted,
    } = this.state;

    return formSubmitted ? (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: this.props.location.pathname },
        }}
      />
    ) : (
      <Wrapper>
        <LoginWrapper>
          <StyledFormWrapper>
            <StyledHead>
              <StyledIconWrapper>
                <IconSvgComponent additionalClass="login" iconPath="svg/stemify-logo-login.svg" />
              </StyledIconWrapper>
              <StyledTitle id="lblChangePwdTitle" top={25}>
                Change My Password
              </StyledTitle>
              <StyledFormDescription>
                Your new password must contain at least 6 characters, 1 special character, 1 number,
                and 1 upper case.
              </StyledFormDescription>
              <FieldErrors formErrors={formErrors} apiErrorMessage={this.props.apiError.message} />
            </StyledHead>

            <StyledBody>
              <Form onSubmit={this.handleSubmit}>
                <FormBody>
                  <FormRowGrid>
                    <FormCol>
                      <FormControls>
                        <Field
                          id="email"
                          type="email"
                          name="email"
                          onChange={this.handleFieldChange}
                          value={email}
                          placeholder="Username"
                          required
                          error={!formErrors.email.valid}
                        />
                      </FormControls>

                      <FormControls>
                        <Field
                          id="resetCode"
                          type="password"
                          name="resetCode"
                          onChange={this.handleFieldChange}
                          value={resetCode}
                          placeholder="Reset Code"
                          required
                          error={!formErrors.resetCode.valid}
                        />
                      </FormControls>

                      <FormControls>
                        <Field
                          id="newPassword"
                          type="password"
                          name="newPassword"
                          onChange={this.handleFieldChange}
                          value={newPassword}
                          placeholder="New Password"
                          required
                          error={!formErrors.newPassword.valid}
                        />
                        {formErrors.passwordLength && formErrors.passwordLength.message && (
                          <StyledError>Password is weak</StyledError>
                        )}
                      </FormControls>

                      <FormControls>
                        <Field
                          id="repeatNewPassword"
                          type="password"
                          name="repeatNewPassword"
                          onChange={this.handleFieldChange}
                          value={repeatNewPassword}
                          placeholder="Repeat New Password"
                          required
                          error={!formErrors.repeatNewPassword.valid}
                        />
                        {formErrors.repeatNewPassword.message && (
                          <StyledError>{formErrors.repeatNewPassword.message}</StyledError>
                        )}
                      </FormControls>
                    </FormCol>
                  </FormRowGrid>
                </FormBody>
                <FormActions>
                  <ButtonLink type="submit" violet>
                    <span>Change Password</span>
                  </ButtonLink>
                </FormActions>
              </Form>
            </StyledBody>
          </StyledFormWrapper>
        </LoginWrapper>
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      apiError: state.user.apiErrors.changePassword,
    }),
    { resetForgottenPassword }
  )(FormChangePassword)
);
