export const userRoles = ["Normal User", "Account Admin", "System Admin"];

export const userRoleOptions = [
  {
    id: 0,
    title: "Normal User",
    label: "Normal User",
    value: userRoles[0],
    isChecked: false,
  },
  {
    id: 1,
    title: "Account Admin",
    label: "Account Admin",
    value: userRoles[1],
    isChecked: false,
  },
  {
    id: 2,
    title: "System Admin",
    label: "System Admin",
    value: userRoles[2],
    isChecked: false,
  },
];

export const userHierarchy = {
  Instructor: ["Instructor", "Account Admin"],
  Student: ["Student", "Instructor", "Account Admin", "Master Admin"],
};
