import React from "react";
import { connect } from "react-redux";
import ModalFrame from "./ModalFrame";
import CooldownModalContent from "components/studentDashboard/CooldownModalContent";

const ModalCooldownPeriod = ({ nextAttemptDate, assessmentName }) => {
  return (
    <ModalFrame>
      <CooldownModalContent
        icon="svg/cooldown-retry.svg"
        title="You are currently in the cooling period."
        nextAttemptDate={nextAttemptDate}
        assessmentName={assessmentName}
      />
    </ModalFrame>
  );
};

export default connect(
  (state) => ({
    nextAttemptDate: state.ui.modal.data.nextAttemptDate,
    assessmentName: state.ui.modal.data.assessmentName,
  }),
  {}
)(ModalCooldownPeriod);
