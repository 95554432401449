import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Icon from "components/common/IconSvgComponent";
import styled from "styled-components";

const CardContainer = styled.div`
  position: relative;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  background: #fff;
  height: 175px;
  padding: 20px;
  width: calc(1 / 4 * 100% - (1 - 1 / 4) * 10px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;

  &:first-child {
    margin-left: 0px;
  }

  .metricCard-icon {
    margin-bottom: 15px;

    .svg-icon svg {
      width: 50px;
      height: 50px;
    }
  }

  .metricCard-textContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #333;
  }

  .metricCard-value {
    font-size: 45px;
    font-weight: bold;
    font-family: Circular Pro, sans-serif;
    margin: -10px 0px;
  }

  .metricCard-unit {
    font-size: 18px;
    font-family: Circular Pro, sans-serif;
  }
`;

const MetricCard = ({ iconPath, unit, valueSelector, getDataHandler }) => {
  const value = useSelector((state) => valueSelector(state)).value;

  useEffect(() => {
    getDataHandler();
  }, [getDataHandler]);

  return (
    <CardContainer>
      <div className="metricCard-icon">
        <Icon iconPath={iconPath} />
      </div>
      <div className="metricCard-textContainer">
        <div className="metricCard-value">{value ? value : "-"}</div>
        <div className="metricCard-unit">{unit.toUpperCase()}</div>
      </div>
    </CardContainer>
  );
};

export default MetricCard;
