import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";

import IconSvgComponent from "components/common/IconSvgComponent";

import CourseSubnav from "components/courses/CourseSubnav";
import Tooltip from "styles/components/Tooltip";
import { ButtonEdit } from "styles/components/TitleEditable";
import {
  CourseDetailsGroupHead,
  CourseDetailsGroupBody,
  CourseDetailsTitle,
  CourseDetailsFlex,
  CourseDetailsTitleSmall,
  CourseDetailsBack,
} from "styles/components/CourseDetails";

import CourseTabs, { CourseTab, CourseTabsContent } from "styles/components/CourseTabs";

import { setActiveCourse } from "store/state/courses/actions";

import TableDefault, {
  TableContainer,
  TableWrapper,
  TableHead,
  TableBody,
  TableRow,
  TableTh,
  TableTd,
} from "styles/components/TableDefault";

import TableDropdown from "components/elements/TableDropdown";

class CourseDetailPageAssessment extends Component {
  state = {
    students: [
      {
        id: "instructor1",
        name: "Amit Savkar",
        grade: "100%",
        representations: "100%",
        operations: "100%",
      },
    ],
  };

  viewTableItem = (id) => {
    console.log("view", id);
  };

  deleteTableItem = (id) => {
    console.log("delete", id);
  };

  componentDidMount() {
    const { courseId } = this.props.match.params;
    const activeCourse = this.props.courses.filter((course) => course.id === courseId);
    activeCourse.length > 0 && this.props.setActiveCourse(activeCourse[0]);
  }

  render() {
    const { courses, match } = this.props;
    const { courseId } = match.params;
    const currentCourse = courses.find((item) => item.id == courseId);
    const { students } = this.state;
    if (!currentCourse) {
      return null;
    }

    return (
      <DocumentTitle title="Stemify | Course">
        <CourseTabs>
          <CourseSubnav />

          <CourseTabsContent>
            <CourseTab active={true}>
              <CourseDetailsGroupHead>
                <CourseDetailsFlex>
                  <CourseDetailsTitle>
                    Alegebra Practice - Functions
                    <CourseDetailsTitleSmall>Week 1</CourseDetailsTitleSmall>
                  </CourseDetailsTitle>
                  <CourseDetailsBack onClick={() => this.props.history.goBack()}>
                    <IconSvgComponent iconPath="svg/ico-arrow-left.svg" />
                    Back to All Assignments
                  </CourseDetailsBack>
                </CourseDetailsFlex>
              </CourseDetailsGroupHead>

              <CourseDetailsGroupBody>
                <TableContainer isCourseTable isResponsive>
                  <TableWrapper noTopBorder>
                    <TableDefault boxed>
                      <TableHead>
                        <TableRow>
                          <TableTh>Student Name</TableTh>
                          <TableTh>Overall Grade</TableTh>
                          <TableTh>Representations of whole numbers</TableTh>
                          <TableTh>Operations on a number line</TableTh>
                          <TableTh>
                            <ButtonEdit data-tip="Download .CSV" data-place={"bottom"}>
                              <IconSvgComponent iconPath="svg/ico-download.svg" />
                            </ButtonEdit>
                            <Tooltip className="transform-none" />
                          </TableTh>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {students &&
                          students.map((student) => (
                            <TableRow key={student.id}>
                              <TableTd>
                                <IconSvgComponent iconPath="svg/ico-user-blue.svg" />
                                {student.name}
                              </TableTd>

                              <TableTd>{student.grade}</TableTd>

                              <TableTd>{student.representations}</TableTd>

                              <TableTd>{student.operations}</TableTd>

                              <TableTd>
                                <TableDropdown
                                  options={{
                                    items: [
                                      {
                                        callback: () => this.viewTableItem(student.id),
                                        btnText: "View",
                                      },
                                      {
                                        callback: () => this.deleteTableItem(student.id),
                                        btnText: "Delete",
                                      },
                                    ],
                                  }}
                                />
                              </TableTd>
                            </TableRow>
                          ))}
                      </TableBody>
                    </TableDefault>
                  </TableWrapper>
                </TableContainer>
              </CourseDetailsGroupBody>
            </CourseTab>
          </CourseTabsContent>
        </CourseTabs>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    userRole: state.user.role,
    currentAccountId: state.user.currentAccountId,
    accountId: state.user.accountId,
    apiCallInProgress: state.administration.apiCallInProgress,
    courses: Object.values(state.storedCourses.sections),
  }),
  {
    setActiveCourse,
  }
)(CourseDetailPageAssessment);
