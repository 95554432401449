import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { MODAL_ASSESSMENT_TIME_END } from "lib/constants";
import { openModal } from "store/state/ui/actions";
import { startCountdown } from "store/state/assessment/actions";
import { CountdownContainer, CountdownTitle, CountdownTimer } from "styles/components/Countdown";
import moment from "moment";

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props.assessmentTimeLimit || 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.isCountdownRunning) {
      this.handleCounting();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.timer) return;
    const { assessmentTimeLimit, attemptStartTime, isCountdownRunning } = this.props;

    if (isCountdownRunning !== prevProps.isCountdownRunning) {
      this.handleCounting();
    }

    if (prevProps.assessmentTimeLimit === 0 && assessmentTimeLimit !== 0 && !attemptStartTime) {
      this.setState({ count: assessmentTimeLimit });
    }

    if (!prevProps.attemptStartTime && attemptStartTime && assessmentTimeLimit) {
      this.handleCounting();
    }

    this.handleEndTimeModal();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timer);
  }

  format(time) {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time / 60) % 60);
    let seconds = Math.floor(time % 60);

    hours = hours.toString().length === 1 ? `0${hours}` : hours;
    minutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
    seconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;

    if (time < 0) {
      return "00:00:00";
    }

    return `${hours}:${minutes}:${seconds}`;
  }

  handleCounting() {
    const { assessmentTimeLimit, attemptStartTime } = this.props;

    if (assessmentTimeLimit && attemptStartTime) {
      this.timer = setInterval(() => {
        const nextCount = assessmentTimeLimit - (moment() - moment(attemptStartTime)) / 1000;

        if (nextCount <= 0) {
          this.handleEndTimeModal();
        }
        this.setState({ count: nextCount });
      }, 1000);
    }
  }

  handleEndTimeModal = () => {
    const { openModal, match } = this.props;
    const { assessmentId, deploymentId, courseId, assignmentId, attemptId } = match.params;
    const { count } = this.state;

    if (count <= 0) {
      clearInterval(this.timer);

      openModal({
        type: MODAL_ASSESSMENT_TIME_END,
        data: {
          shouldNotCloseOnEsc: true,
          attemptId,
          assignmentId,
          courseId,
          deploymentConfigId: deploymentId,
          assessmentSeriesId: assessmentId,
        },
      });
    }
  };

  render() {
    const { title } = this.props;
    const { count } = this.state;

    return (
      <CountdownContainer className={`countdown ${count <= 900 ? "danger" : ""}`}>
        <CountdownTitle>{title}</CountdownTitle>

        <CountdownTimer>{this.format(this.state.count)}</CountdownTimer>
      </CountdownContainer>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const {
        match: {
          params: { assignmentId, courseId, assessmentId },
        },
      } = ownProps;
      const isCourseAssessment = assignmentId && courseId;
      const assessmentTimeLimit = isCourseAssessment
        ? state.storedCourses.assignments[assignmentId]?.timeLimit * 60
        : state.assessment?.attempt?.timeLimit * 60;

      return {
        attemptStartTime: state.assessment.attempt.startTime,
        assessmentTimeLimit,
      };
    },
    {
      startCountdown,
      openModal,
    }
  )(Countdown)
);
