/**
 * @ External Dependecies
 */

import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

export const TagsBar = styled.div`
  padding: 0 25px 22px;
  background: #fff;
  display: none;

  ${(props) =>
    props.isVisible &&
    `
		display: block;
	`}

  ${media.mobile`
		padding: 0 10px 10px;
	`}
`;

export const TagsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  margin: 0 -2px;
`;

export const TagsItem = styled.li`
  display: inline-flex;
  align-items: center;
  padding: 5px 13px;
  margin: 2px;
  background: #484848;
  border-radius: 15px;
`;

export const TagsItemText = styled.span`
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  margin-right: 10px;
  line-height: 1.29;
`;

export const TagsItemBtnRemove = styled.button`
  background: none;
  border: none;

  .svg-icon g {
    fill: #fff;
  }
`;

export const SearchBar = styled.div`
	display: none;
	background: rgba(150, 160, 178, 0.2);

	.highlight {
	  color: ${theme.color.blue};
	  font-weight: bold;
	}

	${(props) =>
    props.isVisible &&
    `
		display: block;
	`}

	// input
	.react-autosuggest__input {
		width: 100%;
		height: 60px
		padding: 0 25px;
		font-size: 24px;
		font-weight: 400;
		background: none;
		color: ${theme.color.darker_gray};
		border: none;
		border-top: 1px solid transparent;
		border-bottom: 1px solid rgba(184, 194, 199, 0.3);

		&::placeholder {
			font-size: 18px;
		}

		${media.mobile`
			font-size: 16px
			padding: 0 10px
		`}
	}

	// list
	.react-autosuggest__suggestions-container {
		height: 0;
		overflow-y: scroll;

		&--open {
			padding: 10px 0;
		}
	}

	.react-autosuggest__suggestions-list {
		list-style: none;
	}

	.react-autosuggest__section-title {
		font-size: 18px;
		padding-left: 25px;

		${media.mobile`
			padding-left: 10px;
			font-size: 15px;
		`}
	}

	${(props) =>
    props.isExpanded &&
    `
		.react-autosuggest__suggestions-container {
			height: 320px;

		}
	`}
	${media.mobile`
		${(props) =>
      props.isExpanded &&
      `
			.react-autosuggest__suggestions-container {
				height: 200px;
			}
		`}
	`}

	// list Item
	.react-autosuggest__suggestion {
		padding-left: 50px;
		font-size: 16px;
		line-height: 2;
		font-weight: 400;
		cursor: pointer;

		${media.mobile`
			padding-left: 10px;
			font-size: 13px;
		`}
	}

	// list-item-hover
	.react-autosuggest__suggestion--highlighted {
		background: rgba(150, 160, 178, 0.4);
	}

	.react-autosuggest__section-container + .react-autosuggest__section-container {
		${media.mobile`
			margin-top: 5px;
		`}
	}
`;

export const SearchCategories = styled.div`
  min-height: 70px;

  .category-breadcrumbs {
    padding: 10px;
  }

  ${media.mobile`
  	display: none;
  `}
`;

export default TagsBar;
