import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedIn } from "utilities/mockLogin";

const PrivateRoute = ({ component: Component, loggedIn, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) => {
        return isLoggedIn() || loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: props.location.pathname + props.location.search },
            }}
          />
        );
      }}
    />
  );
};

export default connect((state) => ({ loggedIn: state.user.loggedIn }), {})(PrivateRoute);
