/**
 * The external dependecies
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */

import IconSvgComponent from "components/common/IconSvgComponent";
import ModalFrame from "./ModalFrame";

import FormImageUpload from "components/form/FormImageUpload";
import FormImageLibrary from "components/form/FormImageLibrary";

import ImageManager, {
  ImageManagerTabs,
  ImageManagerTabsHead,
  ImageManagerTabsNav,
  ImageManagerTabsNavButton,
  ImageManagerTabsBody,
  ImageManagerTab,
  ImageManagerTabContent,
} from "styles/components/ImageManager";

/**
 * @class      ModalCreateBuilderAssessment (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */

class ModalImageManager extends Component {
  state = {
    activeTabIndex: 0,
  };

  render() {
    const { activeTabIndex } = this.state;

    return (
      <ModalFrame modifier={"modal-frame-image-manager"}>
        <ImageManager>
          <ImageManagerTabs>
            <ImageManagerTabsHead>
              <ImageManagerTabsNav>
                <ImageManagerTabsNavButton
                  active={activeTabIndex === 0}
                  onClick={() => this.setState({ activeTabIndex: 0 })}
                >
                  {" "}
                  Upload Image{" "}
                </ImageManagerTabsNavButton>

                <ImageManagerTabsNavButton
                  active={activeTabIndex === 1}
                  onClick={() => this.setState({ activeTabIndex: 1 })}
                >
                  {" "}
                  Image Library{" "}
                </ImageManagerTabsNavButton>
              </ImageManagerTabsNav>
            </ImageManagerTabsHead>

            <ImageManagerTabsBody>
              {activeTabIndex === 0 && (
                <ImageManagerTab>
                  <ImageManagerTabContent>
                    <FormImageUpload
                      onUploadComplete={() => this.setState({ activeTabIndex: 1 })}
                    />
                  </ImageManagerTabContent>
                </ImageManagerTab>
              )}

              {activeTabIndex === 1 && (
                <ImageManagerTab>
                  <ImageManagerTabContent alignStretch>
                    <FormImageLibrary />
                  </ImageManagerTabContent>
                </ImageManagerTab>
              )}
            </ImageManagerTabsBody>
          </ImageManagerTabs>
        </ImageManager>
      </ModalFrame>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    // create,
    // closeModal
  }
)(ModalImageManager);
