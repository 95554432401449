import { deploymentsPath } from "../constants/api";
import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";

export const callGetDeploymentsAPI = async ({ accountId, page, pageSize = 10 }) => {
  // If page is provided add query params
  const queryParams = page ? `?pageSize=${pageSize}&page=${page}` : "";

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: pageSize === -1 ? deploymentsPath : `${deploymentsPath}${queryParams}`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const callGetDeploymentByIdAPI = async ({ id, accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${deploymentsPath}/${id}`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const callCreateDeploymentAPI = async (deploymentData, accountId) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: deploymentsPath,
    method: "post",
    body: JSON.stringify(deploymentData),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const decorateDeploymentListResponse = ({ deployments }) => {
  return deployments.map((item) => {
    return {
      id: item.id,
      name: item.name,
      program: item.program_name,
      programId: item.program_id,
      cohort: item.cohort_name,
      cohortId: item.cohort_id,
      cohortNumber: item.total_user.toLocaleString(),
      startDate: item.start_date,
      endDate: item.end_date,
      dateCreated: "",
      status: item.status,
      retakeNumber: item.retake_number,
      coolingPeriod: item.cooling_period,
      deliverVersionsRandomly: item.deliver_versions_randomly,
      ownerName: item.owner_name,
      ownerEmail: item.owner_email,
      message: item.message,
      completed_before_retake: item.pretest_content_review,
      restricted: item.is_access_code_required,
      accessCode: item.access_code ? item.access_code.toString() : item.access_code,
      assessmentTime: item.assessment_time ? item.assessment_time / 60 : "",
    };
  });
};

const callUpdateDeploymentAPI = async (id, deploymentData, accountId) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${deploymentsPath}/${id}`,
    method: "put",
    body: JSON.stringify(deploymentData),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export { callCreateDeploymentAPI, callUpdateDeploymentAPI };
