import React from "react";
import { BlockMath, InlineMath } from "react-katex";

import { Formula } from "styles/components/BreadcrumbsContainer";
import "katex/dist/katex.min.css";

const dollarPlaceholder = "%DOLLAR%";

const KatexFormat = ({ text, delimiter, adjustFont }) => {
  const isBlockMath = delimiter === "$$" ? true : false;
  // Regex with negative lookback is used to ensure $ preceded by \ is ignored
  // Matches $ and $$, but not \$ or \$$.  \$$ is treated as $.
  const delimiterRegex = isBlockMath ? /\$\$/ : /\$/;
  if (text && text.trim() !== "") {
    const textDollarReplace = text.replace(/\\\$/g, dollarPlaceholder);
    if (textDollarReplace.search(delimiterRegex) >= 0) {
      const results = textDollarReplace.split(delimiterRegex);
      return results.map(function(segment, index) {
        const segmentDollarReplace = !!segment
          ? segment.replace(dollarPlaceholder, "\\$")
          : segment;
        return (
          segmentDollarReplace && (
            <span key={index}>
              {index % 2 === 1 ? (
                isBlockMath ? (
                  <Formula adjustFont={adjustFont}>
                    <BlockMath>{segmentDollarReplace}</BlockMath>
                  </Formula>
                ) : (
                  <Formula adjustFont={adjustFont}>
                    <InlineMath>{segmentDollarReplace}</InlineMath>
                  </Formula>
                )
              ) : isBlockMath ? (
                <KatexFormat text={segmentDollarReplace} delimiter="$" adjustFont={adjustFont} />
              ) : (
                segmentDollarReplace
              )}
            </span>
          )
        );
      });
    } else if (isBlockMath) {
      //BlockMath operation but no block math code present
      return <KatexFormat text={text} delimiter="$" adjustFont={adjustFont} />;
    } else {
      return text;
    }
  } else {
    return "";
  }
};

export const FormattedText = ({ text, adjustFont }) => {
  return <KatexFormat text={text} delimiter="$$" adjustFont={adjustFont} />;
};
