/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import { media } from "../media";
import { Link } from "react-router-dom";
import theme from "../theme";

export const FormHead = styled.header`
  margin-bottom: 35px;
  text-align: center;

  ${(props) =>
    props.bottomOffset &&
    `
    margin-bottom: ${props.bottomOffset}px;
  `}
`;

export const FieldContainer = styled.fieldset`
	border: 0px;

	${(props) =>
    props.disabled &&
    `
    opacity: .4;`}
	${(props) =>
    props.marginTopValue &&
    `
    	margin-top: ${props.marginTopValue}px;`}

  }

	input[type=text] {
		background-color: #FFF !important;
	}
`;

export const FormBody = styled.div`
	margin-bottom: 32px;

	${(props) =>
    props.noMargin &&
    `
		margin-bottom: 0px !important;`}

	${(props) =>
    props.alignleft &&
    `
		text-align: left;
		font-family: "Circular Pro";
		font-size: 19px;
		font-weight: 500;
		line-height: 24px;`}

	${(props) =>
    props.quizForm &&
    `
		margin-left:20%;
		margin-right:20%;
		width:80%;`}

     ${(props) =>
       props.bottomOffset &&
       `
    margin-bottom: ${props.bottomOffset}px;
  `}
`;

export const FormTitle = styled.h3`
  font-size: 24px;
  ${(props) =>
    props.small &&
    `
		font-size: 22px;
	`}
  ${(props) =>
    props.leftMargin &&
    `
		margin-left: ${props.leftMargin}px;
	`}
	word-wrap: break-word;
`;

export const FormTitleWithClick = styled.button`
  position: relative;
  display: inline-block;
  verical-align: middle;
  padding-right: 15px;
  background: none;
  border: 0 none;
  font-size: 24px;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: calc(50% + 1px);
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #9b9b9b;
    transition: transform 0.3s;
  }

  ${(props) =>
    props.active &&
    `
		&:after {
			transform: translateY(-50%) rotate(180deg);
		}
	`}
`;

export const FormLogo = styled.div`
  margin-bottom: 14px;
`;

export const FormActions = styled.div`
  text-align: center;
  ${(props) =>
    props.marginTop &&
    `
		margin-top: ${props.marginTop}px;
    `}
  ${(props) =>
    props.flex &&
    `
        display: flex;
        button {
            margin: auto;
        }
	`}
`;

export const FormRow = styled.div`
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.textarea &&
    `
		margin-bottom: 24px;
	`}

  ${(props) =>
    props.switch &&
    `
		margin-bottom: 18px;
	`}
`;

export const FormRowGrid = styled.div.attrs({
  className: "form-row",
})`
	display: flex;
	flex-wrap: wrap;
	margin: 0 0px 12px;
  gap: 10px 0px;

	&:last-child {
		margin: 0 0px 0;
	}

	${(props) =>
    props.justifyCenter &&
    `
		justify-content: center;
	`}

	${(props) =>
    props.switch &&
    `
		margin-bottom: 18px;
	`}

	${(props) =>
    props.tinyBottomSpace &&
    `
		margin-bottom: 1px;
  `}
  ${(props) =>
    props.displayProp &&
    `display: ${props.displayProp};
  `}
`;

export const FormCol = styled.div.attrs({
  className: "form-col",
})`
	max-width: 100%;
	flex: 1 1 100%;
	padding: 0 15px;


	${(props) =>
    props.half &&
    `
		max-width: 50%;
		flex: 0 0 50%;
	`}

	${(props) =>
    props.third &&
    `
		max-width: 33%;
		flex: 0 0 33%;
	`}

  ${(props) =>
    props.two_thirds &&
    `
    max-width: 66%;
    flex: 0 0 66%;
  `}

	${(props) =>
    props.size1 &&
    `
		max-width: 300px;
		flex: 0 0 300px;
	`}

	${(props) =>
    props.size2 &&
    `
		max-width: 361px;
		flex: 0 0 361px;
	`}

	${(props) =>
    props.size3 &&
    `
		max-width: 155px;
		flex: 0 0 155px;
  `}

`;

export const FormControls = styled.div`
  margin-bottom: 11px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
  ${(props) =>
    props.isCoolingPeriodField &&
    `
        display: flex;
        justify-content: space-between
        &:first-child  {
            flex-grow: 3;
        }
        &:last-child  {
            flex-grow: 1;
        }

    `}
  ${(props) =>
    props.dir &&
    `float: ${props.dir};
    `}

    ${(props) =>
      props.bottomOffset &&
      `
    margin-bottom: ${props.bottomOffset}px;
  `}
    ${(props) =>
      props.topOffset &&
      `
    margin-top: ${props.topOffset}px;
  `}
`;

export const CuttoffWrapper = styled.div``;

export const FormIcon = styled.div``;

export const FormField = styled.input`
	display: block;
	width: 100%;
  padding: 20px 5px 10px;
	border: none;
	border-bottom: 1px solid rgba(138, 144, 156, 0.25);
    font-size: 14px;

  ${(props) =>
    props.fieldWidth &&
    `
		padding-right: 85px !important;
	`}

	${(props) =>
    props.withSuffix &&
    `
		padding-right: 85px !important;
	`}

	${(props) =>
    props.error &&
    `
		border-bottom: 1px solid #B11E1F
	`}

	${(props) =>
    props.shiftUp &&
    `
		margin-top: -15px;
	`}

     ${(props) =>
       props.fieldWidth &&
       `
        width: ${props.fieldWidth};
      `}

      ${(props) =>
        props.isHidden &&
        `
        visibility:hidden
    `}

  ${(props) =>
    props.disabled &&
    `
		opacity: .5;
	`}
`;

export const FormFieldStatic = styled.span`
  display: block;
  width: 100%;
  padding: 17px 0 14px;
  border: none;
  font-size: 14px;
  ${(props) =>
    props.noPadding &&
    `
     padding: 0px;
  `}
`;

export const FormTextarea = styled.textarea`
  display: block;
  max-width: 100%;
  width: 100%;
  min-height: 82px;
  padding: 20px 10px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  font-size: 14px;
  color: #4a4a4a;

  &::placeholder {
    color: #9b9b9b;
  }
`;

export const FormLabel = styled.label`
  position: absolute;
  z-index: 10;
  left: 0;
  top: 17px;
  font-size: 14px;
  transform-origin: top left;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
  user-select: none;

  ${(props) =>
    props.smallLabel &&
    `
      height: 15px !important;
      font-family: "Circular Pro";
      font-size: 9px !important;;
      font-weight: 300;
      letter-spacing: 0.06px;
      line-height: 11px;
      top: 23px !important;
  `}

  span {
    color: #ff4323;
  }

  ${(props) =>
    props.largeLabel &&
    `
      font-size: 20px;
      top: 10px;
  `}
`;

export const LabelBoldBlue = styled.label`
  font-size: 14px;
  color: ${theme.color.blue};
  word-wrap: break-word;
`;

export const FormFieldWrapper = styled.div`
  position: relative;

  &.is-focused ${FormLabel}, &.is-filled ${FormLabel} {
    transform: translateY(-17px) scale(0.75);
  }

  &.is-textarea.is-focused ${FormLabel}, &.is-textarea.is-filled ${FormLabel} {
    transform: translateY(-5px) scale(0.75);
  }

  /* &.disabled {
    opacity: 0.5;
  } */

  &.has-icon {
    ${FormIcon} {
      position: absolute;
      left: 0;
      top: calc(50% + 4px);
      transform: translateY(-50%);
      z-index: 5;
      pointer-events: none;
      user-select: none;
    }

    ${FormFieldStatic},
    ${FormField} {
      padding: 17px 0 14px 30px;
    }

    ${FormLabel} {
      left: 30px;
    }
  }

  &.is-static {
    ${FormLabel} {
      font-size: 12px;
      font-weight: normal;
      color: #9b9b9b;
    }

    ${FormFieldStatic} {
      font-size: 14px;
    }
  }

  .field-suffix {
    position: absolute;
    bottom: 14px;
    left: 60px;
    line-height: 1.2;
    pointer-events: none;
    user-select: none;
  }

  &.is-textarea {
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      right: 17px;
      height: 20px;
      background: #fff;
      z-index: 10;
    }

    ${FormLabel} {
      top: 10px;
      left: 11px;
      font-size: 14px;
      color: #9b9b9b;
    }
  }

  ${(props) =>
    props.fieldWidth &&
    `
    width: ${props.fieldWidth}px !important;
  `}

  ${(props) =>
    props.inline &&
    `
    display: inline-block !important;
    margin-left: 15px;
		margin-right: 15px;
  `}
`;

export const FormSection = styled.div`
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormEntry = styled.p`
  color: #9b9b9b;
`;

export const CheckboxWrapper = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-right: 16px;
`;

export const CheckboxLabel = styled.label`
  position: relative;
  display: inline-block;
  min-height: 14px;
  min-width: 14px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #9b9b9b;
    border-radius: 2px;
    opacity: 0.6;

    ${(props) =>
      props.bannerActiveLabel &&
      `
      left: -20px;
      top: 2px;
    `}
  }
`;

export const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  display: none;

  &:checked + label:before {
    background-color: #3269d6;
    background-image: url(/svg/ico-check-small.svg);
    background-position: center center;
    background-size: contain;
    border-color: #3269d6;
    opacity: 1;

    ${(props) =>
      props.bannerActiveCheckbox &&
      `
      left: -20px;
      top: 2px;
    `}
  }
`;

const Form = styled.form`
  position: relative;

  .form-btn-image {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 3px;
  }

	${(props) =>
    props.colSpacing &&
    css`
      ${FormCol} {
        margin-bottom: 10px;
      }
    `}

	${(props) =>
    props.fulloNmobile &&
    css`
      ${media.mobile`
			${FormCol} {
				max-width: 100%;
				flex: 1 1 100%;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		`};
    `}

    ${(props) =>
      props.tabletSize1 &&
      css`
        ${media.tablet_portrait`
        ${FormBody} {
          max-width: 300px;
          margin: 0 auto 32px
        }
      `};
      `}

	${(props) =>
    props.edit &&
    `
		position: relative;
		padding-left: 83px;

		.form-actions-edit {
			position: absolute;
			right: 0;
			top: 0px;
			line-height: 0;

			a,
			button {
				line-height: 1.2;
			}

			a + button {
				margin-left: 15px;
			}
		}

		.form-col {
			max-width: 190px;
			flex: 0 0 190px;
			padding-bottom: 11px;
		}

		.form-body {
			margin-bottom: -11px;
		}

		@media(max-width: 1235px){
			& {
				padding: 30px 0 0 0;
			}

			.form-body {
				padding-right: 148px;
			}
		}

		@media(max-width: 1023px) {
			.form-body {
				padding: 0;
			}

			.form-actions-edit {
				position: relative;
				right: auto;
				top: auto;
				text-align: left;
				padding: 30px 0 0;
			}

			.form-col {
				max-width: 100%;
				flex: 0 0 100%;
			}
		}
  `}

  ${(props) =>
    props.hasLargeFields &&
    css`
      ${FormCol} {
        margin-bottom: 10px;
      }

      ${FormLabel} {
        font-size: 20px;
        top: 10px;
      }

      .is-focused ${FormLabel}, .is-filled ${FormLabel} {
        transform: translateY(-17px) scale(0.65);
      }

      .is-textarea.is-focused ${FormLabel}, .is-textarea.is-filled ${FormLabel} {
        transform: translateY(-5px) scale(0.65);
      }

      ${FormField} {
        font-size: 20px;
      }

      .react-select__control {
        min-height: 57px;
      }

      .react-select__value-container {
        font-size: 20px;
      }

      .react-select__menu {
        font-size: 16px;
      }

      .custom-datepicker {
        .form-control {
          font-size: 20px;
        }
      }
    `}
`;

export const FormTemplate = styled(Form)`
  padding-top: 37px;

  ${FormHead} {
    margin-bottom: 30px;
  }

  ${FormTitle} {
    font-size: 20px;
  }

  ${FormBody} {
    max-width: 783px;
    margin: 0 auto 37px;
  }

  ${media.mobile`
		${FormCol} {
			max-width: 100%;
			flex: 1 1 100%;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	`};
`;

export const FormDefault = styled(Form)`
  ${FormTitleWithClick},
  ${FormTitle} {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 1.29;
    font-weight: normal;
    span {
      color: #ff4323;
    }
  }

  ${FormTitleWithClick} {
    margin-bottom: 17px;
  }

  ${FormEntry} {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 1.23;
    color: #9b9b9b;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledFormWrapper = styled.div`
  max-width: 471px;
  margin: 87px auto;
  padding: 38px 45px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  border-radius: ${theme.border_radius};
`;
export const LoginWrapper = styled.div`
  flex: 1 1 100%;
  background: ${theme.sky_blue_smoke};
`;
export const StyledHead = styled.div`
  margin-bottom: 35px;
  text-align: center;

  ${(props) =>
    props.invalidForm &&
    `
		margin-bottom: 0px;
	`}

  ${(props) =>
    props.login &&
    `
		margin-top: 10px;
	`}
`;
export const StyledFormDescription = styled.div`
  color: #a2a2a2;
  height: 48px;
  margin-bottom: 25px;

  ${(props) =>
    props.short &&
    `
  height: 20px;
  `}
`;
export const StyledBody = styled.div`
  margin-bottom: 40px;

  ${(props) =>
    props.login &&
    `
    margin-bottom: 10px;
  `}
`;
export const StyledTitle = styled.h4.attrs({
  id: (props) => props.id,
})`
  color: #211f50;
  font-size: 1.8em;
  ${(props) =>
    props.top &&
    `
  margin-top: ${props.top}px;
`}
`;
export const StyledIconWrapper = styled.div`
  margin-bottom: 8px;

  .login {
    height: 48px;
  }
`;
export const StyledFoot = styled.div`
  margin-top: 15px;
  text-align: center;
`;

export const StyledEntry = styled.div`
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const StyledText = styled.p`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.023em;
  color: #211f50;
`;

//#8013e0
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #2f55e7;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 0.5px;
  margin-left: -2.5px;
  &:focus {
    border: 2px solid #0b289b;
  }

  ${(props) =>
    props.floatdir &&
    `
  	float: ${props.floatdir};
  `}

  ${(props) =>
    props.size &&
    `
    width: ${props.size}px;
    text-align: center;
  `}
`;

export const StyledFieldset = styled.fieldset`
  border: 0;
  padding: 0;

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;

    button {
      cursor: default;
      transition: none;
    }
  `}

  ${(props) =>
    props.hidden &&
    `
    display:none;
  `}
`;

export const StyledMessage = styled.div.attrs({
  id: (props) => props.id,
})`
	width: 374px;
	color: #61490B;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	text-align: center;
	background-color: rgba(243,187,38,0.27);}
	padding: 15px;
	margin-top:25px;
	margin-bottom: 25px;
	${(props) =>
    props.type &&
    props.type == "success" &&
    `
		background-color:#C2EFDB;
		color: #0D6F44
	`}
`;

export const StyledError = styled.label`
  font-size: 14px;
  color: #ff0000;
`;

export const StyledGenericError = styled.div.attrs({
  id: (props) => props.id,
})`
  background-color: rgba(255, 0, 10, 0.16);
  color: #d90000;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
  padding: 15px;
  margin-bottom: 25px;

  ${(props) =>
    props.slider &&
    `
		width: 80%;
	`}

  ${(props) =>
    props.fit &&
    `
		width: 45%;
		margin-left: auto;
		margin-right: auto;
    `}

    ${(props) =>
      props.width &&
      `
        width: ${props.width};
        margin-left: auto;
        margin-right: auto;
	`}
`;

export const StyledFieldErrorSection = styled.div.attrs({
  id: (props) => props.id,
})`
  background-color: rgba(255, 0, 10, 0.16);
  color: #d90000;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-align: center;

  ${(props) =>
    props.slider &&
    `
		width: 80%;
	`}

  ${(props) =>
    props.placeholder &&
    `
		background: none;
    `}

    ${(props) =>
      props.width &&
      `
        width: ${props.width};
        margin-left: auto;
        margin-right: auto;
	`}
`;

export const StyledFieldErrorTitle = styled.div`
  padding-bottom: 10px;
  display: inline-block;
`;

export const StyledFieldErrorList = styled.ul`
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  padding-left: 5%;
`;

export const StyledFieldError = styled.li``;

export const StyledErrorIconWrapper = styled.div`
  float: right;
  margin-top: -25px;

  ${(props) =>
    props.specialInput &&
    `
		margin-top: 0px;
		position: relative;
		top: -25px;
	`}
`;

export const QuizAnswerWrapper = styled.div`
  .svg-icon {
    float: left;
    margin-right: 10px;
    margin-left: 47%;
  }
  margin-top: 15px;
  ${(props) =>
    props.quizstyle &&
    `
			margin-right: 30%;
		`}
`;

export const AccountList = styled.ul`
  & > li {
    &:first-child {
      border-top: 1px solid #f5f5f5;
    }
  }
`;

export const AccountListItem = styled.li`
  min-height: 40px;
  padding-top: 8px;
  padding-left: 20px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;

  ${(props) =>
    props.active &&
    `
	background: #E3EBFA;
`}

  &:hover {
    background: #e3ebfa;
  }
  padding-right: 20px;
  overflow-wrap: break-word;
`;

export const AccountListHeading = styled.span`
  color: #b7b7b7;
  font-size: 8px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 20px;
  display: inline-block;
`;

export const SplitFieldSlot = styled.input`
  margin: 18px;
  padding-top: 5px;
  box-sizing: border-box;
  border: 1px solid #9b9b9b;
  border-radius: 9px;
  box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.26);
  text-align: center;
  caret-color: transparent;

  &:focus {
    border: 3px solid #1fc9ff;
  }

  &::selection {
    background: #ffffff;
  }

  ${(props) =>
    props.secure &&
    `
		-webkit-text-security: disc;
	`}

  ${(props) =>
    props.styleName === "single" &&
    `
		width: 45px;
		height: 45px;
		font-size: 55px;
	`}
`;

export const FormItemHeading = styled.span`
  width: 143px;
  color: #4a4a4a;
  font-family: "Circular Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 18px;
`;

export const CuttoffContainer = styled.div`
  width: 236px;
  height: 93px;
  border: 1px solid #dfdfdf;
  margin-top: 15px;
  padding-top: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
  ${(props) =>
    props.floatdir &&
    `
    float: ${props.floatdir};
  `}
`;

export const CuttOffTitle = styled.div`
  height: 18px;
  color: #4a4a4a;
  font-family: "Circular Pro";
  font-size: 14px;
  font-weight: 480;
  letter-spacing: 0.1px;
  line-height: 18px;
  display: inline-block;
`;

export const SwitchTitle = styled.div`
  height: 18px;
  color: #4a4a4a;
  font-family: "Circular Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 18px;
  display: inline-block;
`;

export const CuttoffLink = styled(StyledLink)`
  color: ${theme.color.blue} !important;
  &:focus {
    border: 2px solid transparent;
  }
  text-align: right;
  ${(props) =>
    props.remove &&
    `
    margin-top: 10px;
    color: #2049E6;
    font-family: "Circular Pro";
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.08px;
    line-height: 14px;
  `}
`;

export const ColorPickerHolder = styled.div`
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -8px;

  button {
    appearance: none;
    border: none;
    border-radius: 0;
    background: transparent;
    width: 20px;
    text-align: center;
  }
`;

export const ColorPicker = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  visibility: hidden;

  ${(props) =>
    props.active &&
    `
    opacity: 1;
    visibility: visible;
  `}
`;

export const ColorPickerIndicator = styled.div`
  display: block;
  height: 3px;
  width: 20px;

  ${(props) =>
    props.chosenColor &&
    `
    background: ${props.chosenColor}
  `}
`;

export const ColorPickerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
export const FormBodyGroup = styled.div``;

export default Form;

export const StyledRadioCotainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
