import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { CardInfo } from "styles/components/CardInfo";

import TableDropdown from "components/elements/TableDropdown";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import AssignmentStatusPill from "components/courses/AssignmentStatusPill";
import CourseAssignmentItem, {
  CourseAssignmentItemHead,
  CourseAssignmentItemTitle,
  CourseAssignmentItemBody,
  CourseAssignmentCard,
  CourseAssignmentCardImage,
  CourseAssignmentCardContent,
  CourseAssignmentCardAction,
  CourseAssignmentCardText,
  CourseAssignmentCardTitle,
  CourseAssignmentCardEntry,
  CourseAssignmentItemMeta,
  CourseAssignmentItemMetaContent,
  CourseAssignmentItemMetaStats,
  CourseAssignmentItemMetaStat,
  CourseAssignmentItemTag,
  CourseAssignmentCardLink,
  CourseAssignmentCardAnchor,
  CourseAssignmentLink,
  CourseAttemptLinkButton,
  CourseAssignmentItemDateAnalyticIcon,
} from "styles/components/CourseAssignments";

import { openModal } from "store/state/ui/actions";
import { MODAL_CONFIRMATION } from "lib/constants";
import {
  getVideoDetailsById,
  getAssessmentDetailsById,
  deleteAssignment,
} from "store/state/courses/actions";
import { ASSIGNMENT_TYPES } from "constants/common";
import { ROLES } from "constants/roles";
import stemifyRoutes from "constants/routes";
import { setIsNavigationFromTopicFilter } from "utilities/localStore";
import { Fragment } from "react";
import { Checkbox, CheckboxLabel, CheckboxWrapper } from "styles/components/Form";
import { saveUserActivity } from "services/activity";
import { saveOpenStaxClick } from "services/courses";

const getTopScore = (studentGrades = []) => {
  return studentGrades.reduce((topScore, student) => {
    const score = student.grade?.score || 0;
    return Math.max(topScore, score);
  }, 0);
};

const CourseItemUnitAssignment = ({ assignment, index, courseId, editAssignment }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const gradesByAssignment = useSelector((state) => state.storedCourses.gradesByAssignment);
  const [isExpanded, setIsExpanded] = useState(false);
  const [assets, setAssets] = useState([]);

  const canExpandCard = () => {
    return (
      user.role !== ROLES.STUDENT ||
      (moment(assignment.startTs).isSameOrBefore(moment(), "day") &&
        moment(assignment.endTs).isSameOrAfter(moment(), "day"))
    );
  };

  const AttachmentCard = ({ type, assets, userRole, assignmentGrade = null }) => {
    const status = assignmentGrade?.student_grades[0]?.activity?.status;
    const hasVisited = Boolean(["VISITED", "DONE"].includes(status));
    const [userHasVisitedLink, updateUserHasVisitedLink] = useState(hasVisited);
    const hasCompleted = Boolean(status === "DONE");
    const [markedDone, updateMarkedDone] = useState(hasCompleted);

    const handleUserVisitLink = () => {
      if (userHasVisitedLink) return;

      updateUserHasVisitedLink(true);
      saveUserActivity({
        type: "ASSIGNMENT",
        id: assignment.id,
        status: "VISITED",
      });
    };

    const handleUserFinishingAssignment = () => {
      if (markedDone) return;

      updateMarkedDone(true);
      saveUserActivity({
        type: "ASSIGNMENT",
        id: assignment.id,
        status: "DONE",
      });
    };

    switch (type) {
      case ASSIGNMENT_TYPES.EXAM:
        return (
          <Fragment>
            {assets.map((asset) => (
              <CourseAssignmentItemMetaStats key={asset.id}>
                <CourseAttemptLinkButton
                  to={{
                    pathname: getRoute(assignment.assignmentType, asset),
                  }}
                  key={asset.id}
                  className={`${
                    assignment.attemptLimit - assignment.attemptCount <= 0 ? "disabled" : ""
                  }`}
                >
                  Take Assessment
                </CourseAttemptLinkButton>
                <CourseAssignmentCardTitle isAttemptText>{`Attempts Remaining: ${
                  assignment.attemptLimit - assignment.attemptCount > 0
                    ? assignment.attemptLimit - assignment.attemptCount
                    : 0
                }`}</CourseAssignmentCardTitle>
              </CourseAssignmentItemMetaStats>
            ))}
          </Fragment>
        );

      case ASSIGNMENT_TYPES.OPENSTAX:
        return (
          <Fragment>
            {assets.map((asset) => (
              <CourseAssignmentCard key={asset.id} addHover>
                <CourseAssignmentCardAnchor
                  key={asset.id}
                  onClick={async () => {
                    window.open(asset.url, "_blank");
                    saveOpenStaxClick({ accountId: user.accountId, feature: "Course" });
                    handleUserVisitLink();
                  }}
                  partial
                >
                  {asset.img && <CourseAssignmentCardImage image={asset.img} />}

                  <CourseAssignmentCardContent>
                    <IconSvgComponent
                      additionalClass={"course-video-assignment-details-icon"}
                      iconPath={asset.icon}
                    />

                    <CourseAssignmentCardText>
                      <CourseAssignmentCardTitle>{asset.title}</CourseAssignmentCardTitle>

                      <CourseAssignmentCardEntry>{asset.description}</CourseAssignmentCardEntry>
                    </CourseAssignmentCardText>
                  </CourseAssignmentCardContent>
                </CourseAssignmentCardAnchor>
                {userRole === ROLES.STUDENT && userHasVisitedLink && (
                  <CourseAssignmentCardAction disabled={markedDone}>
                    <CheckboxWrapper className="checkbox" disabled={markedDone}>
                      <Checkbox
                        checked={markedDone}
                        onChange={() => handleUserFinishingAssignment()}
                        id={assignment.id}
                      />

                      <CheckboxLabel htmlFor={assignment.id} />
                    </CheckboxWrapper>

                    <CourseAssignmentCardText
                      style={{
                        width: "100%",
                        textAlign: "center",
                        maxWidth: "none",
                      }}
                    >
                      <CourseAssignmentCardTitle>I'm Done</CourseAssignmentCardTitle>
                    </CourseAssignmentCardText>
                  </CourseAssignmentCardAction>
                )}
              </CourseAssignmentCard>
            ))}
          </Fragment>
        );

      default:
        return (
          <Fragment>
            {assets.map((asset) => (
              <CourseAssignmentCardLink
                to={{
                  pathname: getRoute(assignment.assignmentType, asset),
                }}
                key={asset.id}
                onClick={null}
              >
                <CourseAssignmentCard key={asset.id}>
                  {asset.img && <CourseAssignmentCardImage image={asset.img} />}

                  <CourseAssignmentCardContent>
                    <IconSvgComponent
                      additionalClass={"course-video-assignment-details-icon"}
                      iconPath={asset.icon}
                    />

                    <CourseAssignmentCardText>
                      <CourseAssignmentCardTitle>{asset.title}</CourseAssignmentCardTitle>

                      <CourseAssignmentCardEntry>{asset.description}</CourseAssignmentCardEntry>
                    </CourseAssignmentCardText>
                  </CourseAssignmentCardContent>
                </CourseAssignmentCard>
              </CourseAssignmentCardLink>
            ))}
          </Fragment>
        );
    }
  };

  const openEditAssignmentModal = () => {
    editAssignment(assignment.assignmentType);
  };

  const openCopyAssignmentModal = () => {
    dispatch(
      openModal({
        type: MODAL_CONFIRMATION,
        data: {
          id: assignment.id,
          title: assignment.name,
          icon: "ico-type-copy.svg",
          type: "copy",
          action: () => console.log("copied assignment!"),
        },
      })
    );
  };

  const openRemoveAssginmentModal = () => {
    dispatch(
      openModal({
        type: MODAL_CONFIRMATION,
        data: {
          id: assignment.id,
          title: assignment.name,
          icon: "ico-type-delete.svg",
          type: "remove",
          action: () => dispatch(deleteAssignment(assignment.id)),
        },
      })
    );
  };

  const handleExpandToggle = async () => {
    if (!canExpandCard()) return;

    try {
      if (!assets.length) {
        const getAssets = async () => {
          switch (assignment.assignmentType) {
            case ASSIGNMENT_TYPES.VIDEO:
              return assignment.videoId == null || assignment.videoId == 0
                ? []
                : await dispatch(getVideoDetailsById({ videoId: assignment.videoId }));

            case ASSIGNMENT_TYPES.PRACTICE:
            case ASSIGNMENT_TYPES.EXAM:
              return assignment.assessmentSeriesId == null || assignment.assessmentSeriesId == 0
                ? []
                : await dispatch(
                    getAssessmentDetailsById({
                      accountId: 1,
                      id: assignment.assessmentSeriesId,
                      selector: "assessmentDetails",
                    })
                  );
            case ASSIGNMENT_TYPES.LINK:
              return assignment.link ? getLinkAsset(assignment.link) : [];

            case ASSIGNMENT_TYPES.OPENSTAX:
              return [
                {
                  id: assignment.openstax_title,
                  img: assignment.openstax_thumbnail,
                  title: assignment.openstax_title,
                  description: assignment.openstax_description,
                  url: assignment.openstax_url,
                  icon: "svg/ico-openstax-assignment-type-small.svg",
                },
              ];

            default:
              return [];
          }
        };
        const assets = await getAssets();
        setAssets(assets);
      }

      setIsExpanded((prevIsExpanded) => !prevIsExpanded);
    } catch (e) {
      console.log(e);
    }
  };

  const getLinkAsset = (link) => {
    return [
      {
        title: link,
        icon: "svg/link_ic_small.svg",
      },
    ];
  };

  const getAssignmentIconPath = (assignmentType) => {
    switch (assignmentType) {
      case ASSIGNMENT_TYPES.VIDEO:
        return "svg/video_sm_ic.svg";

      case ASSIGNMENT_TYPES.PRACTICE:
        return "svg/practice_sm_ic.svg";

      case ASSIGNMENT_TYPES.EXAM:
        return "svg/exam_sm_ic.svg";

      case ASSIGNMENT_TYPES.OPENSTAX:
        return "svg/ico-openstax-assignment-type-small.svg";

      default:
        return "svg/ico-colored-sheet.svg";
    }
  };

  const getRoute = (assignmentType, asset) => {
    switch (assignmentType) {
      case ASSIGNMENT_TYPES.VIDEO:
        setIsNavigationFromTopicFilter({
          isFilterByTopic: false,
          type: null,
          id: null,
          filterTopic: null,
        });

        return `${stemifyRoutes.courses}/${assignment.sectionId}/coursework/${assignment.unitId}/${assignment.videoId}`;

      case ASSIGNMENT_TYPES.PRACTICE:
        return `${stemifyRoutes.courses}/${courseId}/coursework/${assignment.id}`;

      case ASSIGNMENT_TYPES.EXAM:
        return `${stemifyRoutes.courses}/${courseId}/coursework/${assignment.id}/exam/${asset.id}`;

      case ASSIGNMENT_TYPES.OPENSTAX:
        return `${stemifyRoutes.courses}/${courseId}/coursework`;

      default:
        break;
    }
  };

  // Resize window on any changes to isExpanded
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [isExpanded]);

  if (!assignment) {
    return null;
  }

  const isReleased =
    moment(assignment.startTs).isSameOrBefore(moment(), "day") &&
    moment(assignment.endTs).isSameOrAfter(moment(), "day");

  const isScheduled = moment(assignment.startTs).isAfter(moment(), "day");

  return (
    <CourseAssignmentItem expanded={isExpanded} zIndex={index}>
      {user.role !== ROLES.STUDENT && (
        <Fragment>
          <TableDropdown
            options={{
              items: [
                {
                  callback: openEditAssignmentModal,
                  btnText: "Edit",
                },
                /*{
                  callback: openCopyAssignmentModal,
                  btnText: "Copy",
                },*/
                {
                  callback: openRemoveAssginmentModal,
                  btnText: "Remove",
                },
              ],
            }}
          />
        </Fragment>
      )}

      <CourseAssignmentItemHead onClick={handleExpandToggle} expanded={isExpanded}>
        <CourseAssignmentItemTitle>
          <IconSvgComponent iconPath={getAssignmentIconPath(assignment.assignmentType)} />
          {assignment.name}

          <AssignmentStatusPill
            startDate={moment(assignment.startTs).format("YYYY-MM-DD")}
            isScheduled={isScheduled}
            isReleased={isReleased}
            isDraft={assignment.isDraft}
          />
        </CourseAssignmentItemTitle>

        <CourseAssignmentItemDateAnalyticIcon>
          Due: {moment(assignment.endTs).format("MM.DD.YY")}{" "}
          {user.role !== ROLES.STUDENT && assignment.assignmentType !== ASSIGNMENT_TYPES.OPENSTAX && (
            <ButtonLink
              transparent
              isCardRedirectLink
              to={`/courses/${courseId}/analytics/${assignment.id}`}
            >
              <IconSvgComponent iconPath="svg/analytics.svg" />
            </ButtonLink>
          )}
        </CourseAssignmentItemDateAnalyticIcon>
      </CourseAssignmentItemHead>

      {isExpanded && (
        <CourseAssignmentItemBody>
          <CourseAssignmentItemMeta>
            <CourseAssignmentItemMetaContent>
              <span>Posted {moment(assignment.createdOn).format("MM.DD.YY")}</span>
              <span> | Assigned: {moment(assignment.startTs).format("MM.DD.YY")}</span>
              <p>{assignment.description}</p>
            </CourseAssignmentItemMetaContent>
            {user.role !== ROLES.STUDENT && assignment.assignmentType !== ASSIGNMENT_TYPES.EXAM ? (
              <CourseAssignmentItemMetaStats>
                <CourseAssignmentItemMetaStat>
                  <strong>
                    {gradesByAssignment[assignment.id]
                      ? gradesByAssignment[assignment.id].completed_count
                      : 0}
                  </strong>
                  Completed
                </CourseAssignmentItemMetaStat>

                <CourseAssignmentItemMetaStat>
                  <strong>{assignment.assignedUsers ? assignment.assignedUsers.length : 0}</strong>
                  Assigned
                </CourseAssignmentItemMetaStat>
              </CourseAssignmentItemMetaStats>
            ) : (
              <CourseAssignmentItemMetaStats>
                <CourseAssignmentItemMetaStat>
                  {/* {For exam & practice types, student_grades.grade object will contain the result for the exam and practice types.
                 For an instructor, grades for all students assigned will be listed.
                 For a student, it will be specific to that student(i.e. 0th index object of student_grades)} */}
                  <strong style={{ textAlign: "right" }}>
                    {gradesByAssignment[assignment.id]
                      ? getTopScore(gradesByAssignment[assignment.id].student_grades)
                      : 0}
                  </strong>
                  <CourseAssignmentItemMetaContent>
                    <span>Top Score</span>
                  </CourseAssignmentItemMetaContent>
                </CourseAssignmentItemMetaStat>
              </CourseAssignmentItemMetaStats>
            )}
          </CourseAssignmentItemMeta>
          {assets.length > 0 && (
            <AttachmentCard
              type={assignment.assignmentType}
              assets={assets}
              userRole={user.role}
              assignmentGrade={gradesByAssignment[assignment.id]}
            />
          )}
        </CourseAssignmentItemBody>
      )}
    </CourseAssignmentItem>
  );
};

export default CourseItemUnitAssignment;
