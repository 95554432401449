import { handleActions } from "redux-actions";
import { errorCodesPageNotFound } from "constants/api";

import {
  create,
  createMultiple,
  update,
  remove,
  removeAll,
  request,
  reject,
  list,
  listParentWise,
  get,
  getDetails,
  closeModal,
  resetApiErrors,
  updateAll,
  rejectPageNotFound,
  resetPageNotFound,
  clearUpdateRowId,
  clearAPIError,
  receivePlacementAssessments, // For listing at create deployment form,
  rejectPlacementAssessments,
  requestPlacementAssessments,
  receiveGetBanner,
  dismissBanner
} from "./actions";
import { setAccountId } from "../user/actions";

import { requestLogin, successLogout } from "../user/actions";
import { errorData } from "constants/api";
import { dataTableItems } from "constants/common";

const operations = {
  list: errorData,
  create: errorData,
  update: errorData,
  changeStatus: errorData,
  remove: errorData,
  attachToEntity: errorData,
};

const defaultTable = {
  pageSize: 10,
  page: 0,
  pages: null,
  count: 0,
};

const defaultState = {
  updatedRowId: null,
  isApiResponsePageNotFound: false,
  accounts: [],
  accountList: [],
  accountsTable: defaultTable,
  selectedUsers: [],
  users: [],
  usersTable: defaultTable,
  apiCallInProgress: false,
  placementApiCallInProgress: null,
  apiErrors: {
    accounts: {
      ...operations,
      get: errorData,
    },
    users: operations,
    students: operations,
    cohorts: {
      get: errorData,
      create: errorData,
      update: errorData,
    },
    deployments: {
      create: errorData,
      update: errorData,
    },
  },
  cohorts: [],
  cohortsTable: defaultTable,
  cohortDetails: [],
  students: [],
  studentsTable: defaultTable,
  account: 0, // just for MVP will change later to below key
  program: null, // just for MVP will change later to below key
  programs: [],
  deployments: [],
  deploymentsTable: defaultTable,
  placementAssessments: [],
  banner: {},
};

const administration = handleActions(
  {
    [request]: (state, { payload: { selector, operation, resetData, isTableLoading } }) => ({
      ...state,
      apiCallInProgress: isTableLoading ? false : true,
      isTableLoading,
      [selector]: resetData && resetData === true ? [] : state[selector],
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: errorData,
        },
      },
    }),
    [list]: (
      state = defaultState,
      {
        type,
        payload: { selector, items, doNotUpdateAPIProgress, page, count, appendRecords, pageSize },
      }
    ) => {
      const isSelectorTable = dataTableItems.includes(selector);
      const configuredTableProps = isSelectorTable
        ? (() => {
            const tableName = `${selector}Table`;
            return {
              [tableName]: {
                ...state[tableName],
                count,
                page: page - 1,
                pages: Math.ceil(count / pageSize),
                pageSize: pageSize ? pageSize : 10,
              },
            };
          })()
        : {};

      return {
        ...state,
        [selector]: appendRecords ? [...state[selector], ...items] : items,
        apiCallInProgress:
          doNotUpdateAPIProgress && doNotUpdateAPIProgress === true
            ? state.apiCallInProgress
            : false,
        isTableLoading: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            list: errorData,
          },
        },
        ...configuredTableProps,
      };
    },
    [listParentWise]: (
      state,
      {
        payload: {
          // will implement this after MVP to store students by cohort.
          selector,
          items,
          parentId,
        },
      }
    ) => ({
      ...state,
      [selector]: ([selector][parentId] = items),
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          list: errorData,
        },
      },
    }),
    [getDetails]: (state, { payload: { details, selector } }) => ({
      ...state,
      [selector]: details,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          get: errorData,
        },
      },
    }),

    [get]: (state, { payload: { id, details, selector } }) => ({
      ...state,
      [selector]:
        state[selector].length > 0
          ? state[selector].map((item, index) => {
              const newIndex = state[selector].findIndex((item) => item.id === id);
              if (index !== newIndex) {
                return item;
              }
              return {
                ...item,
                ...details,
              };
            })
          : [...state[selector], details],
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          update: errorData,
        },
      },
    }),
    [create]: (state, { payload: { selector, item } }) => {
      const isSelectorTable = ["accounts", "users", "cohorts", "students"].includes(selector);
      const configuredTableProps = isSelectorTable
        ? (() => {
            const tableName = `${selector}Table`;
            return {
              [tableName]: {
                ...state[tableName],
                count: state[tableName].count + 1,
              },
            };
          })()
        : {};

      return {
        ...state,
        [selector]: [item, ...state[selector]],
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            create: errorData,
          },
        },
        ...configuredTableProps,
      };
    },

    [createMultiple]: (state, { payload: { items, selector } }) => ({
      ...state,
      [selector]: [...state[selector], ...items],
      apiCallInProgress: false,
    }),
    [update]: (state, { payload: { id, updates, selector, page } }) => {
      return {
        ...state,
        [selector]: state[selector].map((item, index) => {
          const newIndex = state[selector].findIndex((item) => item.id === id);
          if (index !== newIndex) {
            return item;
          }
          return {
            ...item,
            ...updates,
          };
        }),
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            update: errorData,
          },
        },
        updatedRowId: id,
      };
    },
    [updateAll]: (state, { payload: { selector, updates } }) => ({
      ...state,
      [selector]: state[selector].map((item) => {
        return {
          ...item,
          ...updates,
        };
      }),
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          update: errorData,
        },
      },
    }),
    [remove]: (state, { payload: { id, selector } }) => ({
      ...state,
      [selector]: state[selector].filter((item) => item.id !== id),
      apiCallInProgress: false,
    }),
    [removeAll]: (state, { payload: { selector } }) => ({
      ...state,
      [selector]: [],
      apiCallInProgress: false,
    }),
    [reject]: (state, { payload: { selector, operation, errorItem } }) => ({
      ...state,
      apiCallInProgress: false,
      isTableLoading: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: {
            code: 1,
            message: errorItem && errorItem.message,
          },
        },
      },
    }),
    [closeModal]: (state) => ({
      ...state,
      apiErrors: defaultState.apiErrors,
    }),
    [resetApiErrors]: (state) => ({
      ...state,
      apiErrors: defaultState.apiErrors,
    }),
    [requestLogin]: () => ({
      ...defaultState,
    }),
    [successLogout]: () => ({
      ...defaultState,
    }),
    [clearUpdateRowId]: (state) => {
      return {
        ...state,
        updatedRowId: null,
      };
    },
    [rejectPageNotFound]: (state, { payload: { errorCode } }) => ({
      ...state,
      isApiResponsePageNotFound: errorCodesPageNotFound.includes(errorCode) ? true : false,
    }),
    [resetPageNotFound]: (state) => ({
      ...state,
      isApiResponsePageNotFound: false,
    }),
    [clearAPIError]: (state, { payload: { selector, operation } }) => ({
      ...state,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: errorData,
        },
      },
    }),
    [receivePlacementAssessments]: (state, { payload: { data } }) => ({
      ...state,
      placementAssessments: data,
      placementApiCallInProgress: false,
    }),
    [requestPlacementAssessments]: (state) => ({
      ...state,
      placementApiCallInProgress: true,
    }),
    [rejectPlacementAssessments]: (state) => ({
      ...state,
      placementApiCallInProgress: false,
    }),
    [setAccountId]: (state) => ({
      ...state,
      cohorts: [],
    }),
    [receiveGetBanner]: (state, { payload: banner }) => ({
      ...state,
      banner,
    }),
    [dismissBanner]: (state) => ({
      ...state,
      banner: {
        ...state.banner,
        dismissed: true,
      }
    }),
  },
  defaultState
);

export default administration;
