/**
 * The external dependencies.
 */
import React from "react";
import styled from "styled-components";

/**
 * The internal dependencies.
 */
import IconSvgComponent from "components/common/IconSvgComponent";

/**
 * @class      SearchField (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */
const SearchField = ({
  maxWidth,
  centered,
  onChange,
  id,
  type,
  required,
  placeholder,
  value,
  small,
}) => {
  return (
    <Wrapper maxWidth={maxWidth} centered={centered} small={small}>
      <Field
        id={id}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        small={small}
      />

      <Icon small={small}>
        <IconSvgComponent iconPath="svg/ico-search-dark.svg" />
      </Icon>
    </Wrapper>
  );
};

const Wrapper = styled.div`
	position: relative;

	${(props) =>
    props.maxWidth &&
    `
		max-width: ${props.maxWidth}px;
	`}

	${(props) =>
    props.centered &&
    `
		margin: 0 auto;
	`}
	${(props) =>
    props.small &&
    `
		padding-left:20px;
		padding-right:20px;
	`}	
`;

const Icon = styled.div`
  position: absolute;
  left: 13px;
  top: 55%;
  transform: translateY(-50%);
  padding-bottom: 5px;
  pointer-events: none;
  user-select: none;
  ${(props) =>
    props.small &&
    `
		left: 28px;		
	`}
  div {
    display: inline-flex;
    vertical-align: middle;
  }
`;

const Field = styled.input.attrs({
  type: "text",
})`
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 10px 0 41px;
  border: 1px solid rgba(138, 144, 156, 0.5);
  border-radius: 5px;
  font-size: 14px;
  color: #9b9b9b;
  ${(props) =>
    props.small &&
    `
		height: 30px;
		padding: 0 9px 0 28px;
	`}
`;

export default SearchField;
