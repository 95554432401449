/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */

import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Switch from "components/common/Switch";

import Form, {
  FormHead,
  FormLogo,
  FormBody,
  FormTitle,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
} from "styles/components/Form";

import {
  createBuilderAssessment,
  updateBuilderAssessment,
} from "store/state/builderAssessment/actions";

/**
 * Class for form create cohort.
 *
 * @class      FormCreateCohort (name)
 */
class FormCreateBuilderAssessment extends Component {
  state = {
    id: "",
    name: "",
    randomizeOrder: false,
  };

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  isEdit() {
    return this.props.modal.data && this.props.modal.data.editItem;
  }

  handleStaticChange = (key) => (value) => {
    this.setState({
      [key]: value,
    });
  };

  /**
   * Handles Form Submit
   *
   */
  handleFormSubmit = (e) => {
    e.preventDefault();

    const { createBuilderAssessment, updateBuilderAssessment } = this.props;

    if (this.isEdit()) {
      updateBuilderAssessment({
        ...this.state,
      });
    } else {
      createBuilderAssessment({
        ...this.state,
      });
    }

    this.props.closeModal();
  };

  /**
   * Handles the switch input field change.
   *
   * @param {String} / The key
   * @return {Void}
   */
  handleSwitchChange(key, value) {
    this.setState({
      [key]: value,
    });
  }

  componentDidMount() {
    if (this.isEdit()) {
      this.setState({
        ...this.props.modal.data.editItem,
      });
    }
  }

  render() {
    const { name, randomizeOrder } = this.state;

    return (
      <Form colSpacing fulloNmobile tabletSize1 onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            {this.isEdit() ? (
              <IconSvgComponent iconPath="svg/ico_assessment_settings.svg" />
            ) : (
              <IconSvgComponent iconPath="svg/board-add.svg" />
            )}
          </FormLogo>

          <FormTitle>{this.isEdit() ? "Assessment Settings" : "Create Assessment"}</FormTitle>
        </FormHead>

        <FormBody size1>
          <FormRowGrid justifyCenter>
            <FormCol size1>
              <FormControls>
                <Field
                  id="name"
                  name="name"
                  onChange={this.handleInputChange("name")}
                  value={name}
                  placeholder="Assessment Name"
                  required
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol size1>
              <Switch
                onChange={(value) => this.handleSwitchChange("randomizeOrder", value)}
                id="completed-before-retake"
                value={randomizeOrder}
              >
                Randomize Question Order
              </Switch>
            </FormCol>
          </FormRowGrid>
        </FormBody>

        <FormActions>
          <ButtonLink type="button" transparent onClick={() => this.props.closeModal()}>
            <span>Cancel</span>
          </ButtonLink>

          {this.isEdit() ? (
            <ButtonLink type="submit" violet>
              <span>Update</span>
            </ButtonLink>
          ) : (
            <ButtonLink type="submit" violet hasIcon>
              <span>Create</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          )}
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    apiCallInProgress: state.administration.apiCallInProgress,
    cohortsApiErrors: state.administration.apiErrors.cohorts,
    accountId: state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId,
  }),
  {
    createBuilderAssessment,
    updateBuilderAssessment,
    closeModal,
  }
)(FormCreateBuilderAssessment);
