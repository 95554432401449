import { handleActions } from "redux-actions";
import {
  setPracticeAssignmentCurrentQuestion,
  attemptQuestion,
  requestPracticeAssignment,
  receivePracticeAssignment,
  rejectPracticeAssignment,
  requestAttemptQuestion,
  receiveAttemptQuestion,
  rejectAttemptQuestion,
  requestSubmitAssignment,
  receiveSubmitAssignment,
  rejectSubmitAssignment,
  clearPracticeAssignment,
} from "./actions";

import { errorData } from "constants/api";

const operations = {
  list: errorData,
  get: errorData,
};

const defaultQuestion = {
  id: null,
  attempts: 0,
  isCorrect: false,
  content: {},
  answers: [],
};

const Question = (state = defaultQuestion, action) => {
  switch (action.type) {
    case "test":
      return state;
    default:
      return state;
  }
};

const defaultState = {
  assignmentId: null,
  assignmentName: "",
  assignmentAttemptId: null,
  assignmentQuestions: [],
  currentQuestionId: 0,
  currentQuestionIndex: 0,
  isAnimationForward: true,
  apiCallInProgress: false,
  submitPracticeAssignmentLoading: false,
  markModuleComplete: false,
  apiErrors: {
    assessment: operations,
  },
};

const practiceAssignment = handleActions(
  {
    /** Get Practice Assignment Questions and Answers */
    [requestPracticeAssignment]: (state, action) => {
      const { assignmentId } = action.payload;
      return {
        ...state,
        assignmentId,
        apiCallInProgress: true,
        currentQuestionIndex: 0,
      };
    },
    [receivePracticeAssignment]: (state, action) => {
      const {
        assignmentName,
        assignmentQuestions,
        assignmentAttemptId,
        markModuleComplete,
      } = action.payload;
      return {
        ...state,
        assignmentName,
        assignmentQuestions,
        assignmentAttemptId,
        currentQuestionId: assignmentQuestions[0].id,
        markModuleComplete,
        apiCallInProgress: false,
      };
    },
    [rejectPracticeAssignment]: (state, action) => {
      return { ...defaultState, apiCallInProgress: false };
    },
    [clearPracticeAssignment]: (state) => {
      return { ...defaultState };
    },

    /** Attempt Practice Assignment Question */
    [requestAttemptQuestion]: (state, action) => {
      return state;
    },
    [receiveAttemptQuestion]: (state, action) => {
      const { questionId, attempts, isCorrect } = action.payload;
      const assignmentQuestions = state.assignmentQuestions.map((q) => {
        // TODO: Backend needs to standardize IDs as integers
        if (q.id == questionId && q.isCorrect === false) {
          return {
            ...q,
            attempts,
            isCorrect,
          };
        }
        return q;
      });

      return { ...state, assignmentQuestions };
    },
    [rejectAttemptQuestion]: (state, action) => {
      return state;
    },

    /** Get Practice Assignment Results */
    [requestSubmitAssignment]: (state, action) => {
      return { ...state, submitPracticeAssignmentLoading: true };
    },
    [receiveSubmitAssignment]: (state, { payload: { data } }) => {
      return {
        ...state,
        submitPracticeAssignmentLoading: false,
        improvementTags: data.improvementTags,
      };
    },
    [rejectSubmitAssignment]: (state, action) => {
      return { ...state, submitPracticeAssignmentLoading: false };
    },

    /** Set the current question in practice assignment UI */
    [setPracticeAssignmentCurrentQuestion]: (state, action) => {
      const { questionId, questionIndex } = action.payload;
      const oldIndex = state.currentQuestionIndex;
      const isAnimationForward = oldIndex > questionIndex ? false : true;

      return {
        ...state,
        currentQuestionId: questionId,
        currentQuestionIndex: questionIndex,
        isAnimationForward,
      };
    },
  },
  defaultState
);

export default practiceAssignment;
