import { handleActions } from "redux-actions";
import {
  startCountdown,
  sidebarToggle,
  successSetActiveAssessment,
  successSetCourseCurrentQuestion,
  successSetCourseCompletedQuestion,
  successSetCoursePinnedQuestion,
  requestAssessmentSeries,
  listAssessmentSeries,
  rejectAssessmentSeries,
  requestCourseAssessmentExam,
  receiveCourseAssessmentExam,
  rejectCourseAssessment,
  requestCompleteCourseAssessment,
  receiveCompleteCourseAssessment,
  rejectCompleteCourseAssessment,
  selectAssessmentSeries,
} from "./actions";
import { errorData } from "constants/api";

const operations = {
  list: errorData,
  get: errorData,
};

const defaultState = {
  data: [],
  assessmentSeriesId: null,
  activeAssessment: false,
  assessmentQuestions: [],
  assessmentId: null,
  assignmentId: null,
  courseId: null,
  attemptId: null,
  assessmentName: "",
  assessmentVersion: null,
  pinnedQuestions: [],
  completedQuestions: [],
  currentQuestionId: null,
  currentQuestionIndex: 0,
  isCountdownRunning: false,
  isSidebarOpen: true,
  isAccessCodeRequired: false,
  assessmentTimeLimit: null,
  progress: 0,
  isAnimationForward: true,
  remainingAttempts: null,
  cooldown: null,
  attemptResult: null,
  attemptScore: null,
  topicInfo: [],
  apiCallInProgress: false,
  apiErrors: {
    assessment: operations,
  },
};

const assessment = handleActions(
  {
    [startCountdown]: (state) => ({
      ...state,
      isCountdownRunning: true,
    }),
    [sidebarToggle]: (state) => ({
      ...state,
      isSidebarOpen: !state.isSidebarOpen,
    }),
    [successSetActiveAssessment]: (state, action) => {
      const {
        payload: { assessmentId, courseId, assignmentTimeLimit },
      } = action;

      const assessment =
        state.data &&
        state.data.find((assessment) => {
          return assessment.id == assessmentId;
        });

      // if there is local time then its stored in seconds
      // if its the beginning then its in minutes
      const assessmentTimeLimit = assignmentTimeLimit * 60;

      return {
        ...state,
        courseId,
        isAccessCodeRequired: assessment?.isAccessCodeRequired,
        assessmentTimeLimit,
      };
    },
    [successSetCourseCurrentQuestion]: (
      state,
      { payload: { questionId, questionIndex, isAnimationForward } }
    ) => {
      return {
        ...state,
        isAnimationForward,
        currentQuestionId: questionId,
        currentQuestionIndex: questionIndex,
      };
    },
    [successSetCourseCompletedQuestion]: (state, { payload }) => {
      return {
        ...state,
        completedQuestions: payload.completedQuestions,
        progress: payload.progress,
      };
    },
    [successSetCoursePinnedQuestion]: (state, { payload }) => {
      const { pinnedQuestions } = payload;
      return {
        ...state,
        pinnedQuestions,
      };
    },
    [requestAssessmentSeries]: (state, { payload: { operation } }) => ({
      ...state,
      data: [],
      apiCallInProgress: true,
      isCountdownRunning: false,
      currentQuestionId: null,
      currentQuestionIndex: 0,
      assessmentId: null,
      attemptId: null,
      assessmentName: null,
      assessmentVersion: null,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: errorData,
        },
      },
    }),
    [listAssessmentSeries]: (state, { payload: { items } }) => {
      return {
        ...state,
        data: items,
        assessmentTimeLimit: null,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [rejectAssessmentSeries]: (state, { payload: { operation, errorItem } }) => ({
      ...state,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: {
            code: 1,
            message: errorItem.message,
          },
        },
      },
    }),
    [requestCourseAssessmentExam]: (state, { payload: { operation, assessmentSeriesId } }) => ({
      ...state,
      isSidebarOpen: true,
      pinnedQuestions: [],
      completedQuestions: [],
      currentQuestionIndex: 0,
      progress: 0,
      activeAssessment: false,
      assessmentQuestions: [],
      currentQuestionId: null,
      isCountdownRunning: false,
      assessmentSeriesId,
      apiCallInProgress: true,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: errorData,
        },
      },
    }),
    [receiveCourseAssessmentExam]: (state, { payload: { data, assessmentId, courseId } }) => {
      const currentQuestionId = data.questions[0].id;
      const currentQuestionIndex = 0;
      const isAnimationForward = true;
      const pinnedQuestions = [];
      const completedQuestions = [];
      const progress = 0;

      return {
        ...state,
        courseId,
        activeAssessment: true,
        assessmentQuestions: data.questions,
        currentQuestionId,
        currentQuestionIndex,
        pinnedQuestions,
        isAnimationForward,
        completedQuestions,
        progress,
        assessmentId: assessmentId,
        assignmentId: data.id,
        attemptId: data.attemptId,
        assessmentName: data.name,
        assessmentVersion: data.version,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [rejectCourseAssessment]: (state, { payload: { operation, errorItem } }) => ({
      ...state,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: {
            code: 1,
            message: errorItem.message,
          },
        },
      },
    }),
    [requestCompleteCourseAssessment]: (state, { payload: { operation } }) => ({
      ...state,
      isSidebarOpen: true,
      pinnedQuestions: [],
      currentQuestionIndex: 0,
      activeAssessment: false,
      assessmentQuestions: [],
      currentQuestionId: null,
      isCountdownRunning: false,
      apiCallInProgress: true,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: errorData,
        },
      },
    }),
    [receiveCompleteCourseAssessment]: (state, { payload: { data } }) => {
      const newState = {
        remainingAttempts: data.assignment.attemptLimit,
        cooldown: data.assignment.cooldownDays,
        attemptResult: data.attemptResult,
        attemptScore: data.attemptScore,
        topicInfo: data.topicInfo,
        progress: 0,
        assessmentId: data.assessmentId,
      };

      return {
        ...state,
        ...newState,
        assessmentQuestions: [],
        isCountdownRunning: false,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [rejectCompleteCourseAssessment]: (state, { payload: { operation, errorItem } }) => ({
      ...state,
      assessmentQuestions: [],
      isCountdownRunning: false,
      progress: 0,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: {
            code: 1,
            message: errorItem.message,
          },
        },
      },
    }),
    [requestCourseAssessmentExam]: (state, { payload: { operation, assessmentSeriesId } }) => ({
      ...state,
      isSidebarOpen: true,
      pinnedQuestions: [],
      completedQuestions: [],
      currentQuestionIndex: 0,
      progress: 0,
      activeAssessment: false,
      assessmentQuestions: [],
      currentQuestionId: null,
      isCountdownRunning: false,
      assessmentSeriesId,
      apiCallInProgress: true,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: errorData,
        },
      },
    }),
    [rejectCourseAssessment]: (state, { payload: { operation, errorItem } }) => ({
      ...state,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: {
            code: 1,
            message: errorItem.message,
          },
        },
      },
    }),
    [selectAssessmentSeries]: (state, { payload: { assessmentId } }) => ({
      ...state,
      assessmentId,
    }),
  },
  defaultState
);

export default assessment;
