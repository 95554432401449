import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";

import Wrapper from "styles/components/Wrapper";
import Shell from "styles/components/Shell";
import Loader from "components/common/loader";
import AssessmentResults from "components/assessment/AssessmentResults";
import { getExpiredAttemptAsResult } from "store/state/assessment/actions";
import { getSectionById, getAssignmentsBySectionId } from "store/state/courses/actions";
import stemifyRoutes from "../../constants/routes";

class AssessmentOverview extends Component {
  state = { isLoaded: false };

  async componentDidMount() {
    const {
      getExpiredAttemptAsResult,
      result,
      score,
      getSectionById,
      getAssignmentsBySectionId,
    } = this.props;
    const {
      deploymentId: deploymentConfigId,
      assessmentId: assessmentSeriesId,
      assignmentId,
      attemptId,
      courseId,
    } = this.props.match.params;

    if ((!assignmentId && !result) || (assignmentId && !score)) {
      assignmentId && getSectionById({ courseId });
      const { data: courseAssignments } = assignmentId
        ? await getAssignmentsBySectionId({ courseId })
        : {};

      const response = assignmentId
        ? await getExpiredAttemptAsResult({ attemptId, assignmentId })
        : await getExpiredAttemptAsResult({ attemptId, assessmentSeriesId });
    }
    this.setState({ isLoaded: true });
  }

  render() {
    const { isLoaded } = this.state;
    const {
      name,
      score,
      result,
      cooldown,
      attemptsRemaining,
      totalQuestions,
      questionsCorrect,
      topicInfo,
      apiCallInProgress,
      match: {
        params: { courseId },
      },
    } = this.props;

    const redirectPath = !!courseId
      ? `${stemifyRoutes.courses}/${courseId}/coursework`
      : `${stemifyRoutes.assessments}`;

    return isLoaded && score === undefined ? (
      <Redirect to={redirectPath} />
    ) : (
      <DocumentTitle title={`Stemify | ${!!courseId ? name || "Exam" : "Placement"}`}>
        <Wrapper>
          <Shell large>
            {isLoaded ? (
              <AssessmentResults
                name={name}
                result={result}
                score={score}
                cooldown={cooldown}
                attemptsRemaining={attemptsRemaining}
                totalQuestions={totalQuestions}
                questionsCorrect={questionsCorrect}
                topicInfo={topicInfo}
                apiCallInProgress={apiCallInProgress}
              />
            ) : (
              <Loader />
            )}
          </Shell>
        </Wrapper>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      name: state.assessment.assessmentName,
      result: state.assessment.attemptResult,
      score: state.assessment.attemptScore,
      totalQuestions: state.assessment.attemptTotalQuestions,
      questionsCorrect: state.assessment.attemptQuestionsCorrect,
      cooldown: state.assessment.cooldown,
      attemptsRemaining: state.assessment.remainingAttempts,
      apiCallInProgress: state.assessment.apiCallInProgress,
      topicInfo: state.assessment.topicInfo,
    };
  },
  {
    getExpiredAttemptAsResult,
    getSectionById,
    getAssignmentsBySectionId,
  }
)(withRouter(AssessmentOverview));
