/**
 * The external dependencies.
 */
import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { isEmpty } from "ramda";
import { createFilter } from "react-search-input";
import Highlighter from "react-highlight-words";

/**
 * The internal dependencies.
 */
import Field from "components/common/Field";
import {
  DropdownWrapper,
  Dropdown,
  DropdownItem,
  DropdownButton,
} from "components/elements/TableDropdown";
import { StyledErrorIconWrapper } from "styles/components/Form";
import IconSvgComponent from "components/common/IconSvgComponent";
import { fieldInvalidIconPath } from "constants/paths";

/**
 * Class for search autocomplete.
 *
 * @class      SearchAutocomplete (name)
 */
class SearchAutocomplete extends Component {
  /**
   * The internal state of the component.
   *
   * @type {Object}
   */
  state = {
    activeIndex: 0,
    isActive: false,
    filteredData: [],
    userInput: "",
  };

  /**
   * The properties that can be passed to the component.
   *
   * @type {Object}
   */
  static propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    keysToFilter: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    apiCallInProgress: PropTypes.bool,
  };

  /**
   * The default properties that can be passed to the component.
   *
   * @type {Object}
   */
  static defaultProps = {
    data: [],
    keysToFilter: [],
  };

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const {
      handleSearchUpdate,
      handleButtonClick,
      handleOnKeyDown,
      props: { id, placeholder, name, error, apiCallInProgress },
      state: { isActive, activeIndex, userInput, filteredData },
    } = this;

    const searchWords = userInput.split(" ");

    return (
      <Wrapper className="dropdown-wrapper" innerRef={(el) => (this.dropdownWrapper = el)}>
        <Field
          icon="ico-search"
          id={id}
          name={name}
          onChange={handleSearchUpdate}
          onKeyDown={handleOnKeyDown}
          value={userInput}
          placeholder={placeholder}
          autoComplete="off"
          required
          error={error}
        />

        {error && (
          <StyledErrorIconWrapper>
            <IconSvgComponent iconPath={fieldInvalidIconPath} />
          </StyledErrorIconWrapper>
        )}

        <DropdownWrapper auto active={isActive} short>
          {!isEmpty(filteredData) ? (
            <Dropdown>
              {filteredData.map((item, index) => (
                <DropdownItem key={item.title}>
                  <DropdownButton
                    active={index === activeIndex}
                    onClick={handleButtonClick(item.title)}
                    type="button"
                  >
                    <Highlighter
                      highlightClassName="text-highlight"
                      searchWords={searchWords}
                      autoEscape={true}
                      textToHighlight={item.title}
                    />
                  </DropdownButton>
                </DropdownItem>
              ))}
            </Dropdown>
          ) : (
            <Dropdown>
              <DropdownItem>
                <DropdownButton type="button">
                  {apiCallInProgress ? "Loading..." : "No data found!"}
                </DropdownButton>
              </DropdownItem>
            </Dropdown>
          )}
        </DropdownWrapper>
      </Wrapper>
    );
  }

  handleButtonClick = (value) => (e) => {
    e.preventDefault();

    this.setState({
      activeIndex: 0,
      isActive: false,
      userInput: value,
      filteredData: [],
    });

    this.props.onChange(value);
  };

  handleSearchUpdate = (e) => {
    const {
      props: { data, keysToFilter, onChange },
    } = this;

    const filteredData = data.filter(createFilter(e.currentTarget.value, keysToFilter));

    this.setState({
      activeIndex: 0,
      isActive: true,
      userInput: e.currentTarget.value,
      filteredData,
    });

    onChange(e.currentTarget.value);
  };

  handleOnKeyDown = (e) => {
    const { activeIndex, filteredData } = this.state;

    if (e.keyCode === 13) {
      e.preventDefault();

      if (filteredData[activeIndex] && filteredData[activeIndex].title) {
        this.setState({
          activeIndex: 0,
          isActive: false,
          userInput: filteredData[activeIndex].title,
        });

        this.props.onChange(filteredData[activeIndex].title);
      }
    } else if (e.keyCode === 38) {
      if (activeIndex === 0) {
        return;
      }

      this.setState({ activeIndex: activeIndex - 1 });
    } else if (e.keyCode === 40) {
      if (activeIndex - 1 === filteredData.length) {
        return;
      }

      this.setState({ activeIndex: activeIndex + 1 });
    }
  };

  documentClick = (e) => {
    const el = this.dropdownWrapper;
    const target = e.target;

    if (el !== target && !el.contains(target)) {
      this.setState({
        isActive: false,
      });
    }
  };

  componentDidMount() {
    const { prefillValue } = this.props;
    document.addEventListener("click", this.documentClick);

    if (prefillValue) {
      this.setState({ userInput: prefillValue });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.documentClick);
  }
}

const Wrapper = styled.div`
  position: relative;
`;

export default SearchAutocomplete;
