import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import useAPIData from "components/useAPIData";
import { Card, styles } from "./styles";
import { LoadingSpinner } from "components/card/withSpinnerHOC";
import { StudentBreakdownCard } from "components/card/StudentBreakdownCard";
import { getVideoStudentBreakdownData } from "store/state/courses/actions";

const getWatchedCellValue = ({ value }) => {
  return value === false ? "No" : "Yes";
};

const getMinutesWatchedCellValue = ({ value }) => {
  const valueAsFloat = parseFloat(value);
  const minuteValue = Math.floor(valueAsFloat);
  const secondValue = Math.floor((valueAsFloat - minuteValue) * 60);
  return `${minuteValue}:${secondValue.toString().length < 2 ? `0${secondValue}` : secondValue}`;
};

const getDateLastWatchedCellValue = ({ value }) => (value ? value : "N/A");

const columns = [
  {
    id: "studentName",
    Header: "Student Name",
    accessor: "studentName",
  },
  {
    id: "watched",
    Header: "Watched",
    accessor: "watched",
    Cell: getWatchedCellValue,
  },
  {
    id: "minutesWatched",
    Header: "Minutes Watched",
    accessor: "watchedInMins",
    Cell: getMinutesWatchedCellValue,
  },
  {
    id: "dateLastWatched",
    Header: "Date Last Watched",
    accessor: "dateLastWatched",
    Cell: getDateLastWatchedCellValue,
  },
];

const mobileColumns = (student) => ({
  studentName: {
    title: "Student Name",
    value: student.studentName,
  },
  watched: {
    title: "Watched",
    value: getWatchedCellValue({ value: student.watched }),
  },
  minutesWatched: {
    title: "Minutes Watched",
    value: getMinutesWatchedCellValue({ value: student.watchedInMins }),
  },
  dateLastWatched: {
    title: "Date Last Watched",
    value: getDateLastWatchedCellValue({ value: student.dateLastWatched }),
  },
});

export const VideoStudentBreakdownCard = ({ assignmentId }) => {
  const tableParams = useSelector((state) => state.storedCourses.studentBreakDownTable);
  const dispatch = useDispatch();

  const getDataCallback = useCallback(() => {
    return dispatch(getVideoStudentBreakdownData({ assignmentId }));
  }, [assignmentId]);

  return (
    <StudentBreakdownCard
      columns={columns}
      mobileColumns={mobileColumns}
      getDataCallback={getDataCallback}
      cardStyles={{ height: "570px" }}
      tableClasses={"video-student-breakdown-table"}
    />
  );
};
