import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  CourseTabsNav,
  CourseTabsNavItem,
  CourseTabsNavButton,
} from "styles/components/CourseTabs";

class CourseSubnav extends Component {
  state = {
    tabs: [
      {
        id: "tab-1",
        text: "Coursework",
        route: "coursework",
      },
      {
        id: "tab-2",
        text: "People",
        route: "people",
      },
      {
        id: "tab-3",
        text: "Grades",
        route: "grades/assignments",
        disabled: false,
      },
      // {
      //   id: "tab-4",
      //   text: "Settings",
      //   route: "settings",
      // },
    ],
  };

  handleTabChange = (e, route) => {
    e.preventDefault();
    const { courseId } = this.props.match.params;

    this.props.history.push(`/courses/${courseId}/${route}`);
  };

  render() {
    const { tabs } = this.state;
    const { match } = this.props;

    return (
      <CourseTabsNav>
        {tabs &&
          tabs.map((tab) => (
            <CourseTabsNavItem key={tab.id}>
              <CourseTabsNavButton
                disabled={tab.disabled}
                active={match.url.includes(tab.route.split("/")[0])}
                onClick={(e) => this.handleTabChange(e, tab.route)}
              >
                {tab.text}
              </CourseTabsNavButton>
            </CourseTabsNavItem>
          ))}
      </CourseTabsNav>
    );
  }
}

export default withRouter(CourseSubnav);
