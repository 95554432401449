import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Grid, { Col, Row } from "styles/components/Grid";
import SectionDashboard, { SectionBody } from "styles/components/SectionDashboard";
import Loader from "components/common/loader";
import { NoDataMessageContainer } from "styles/components/Card";
import { NoDataMessage } from "styles/components/Card";
import MetricCard from "components/card/MetricCard";
import AttemptsDonutChart from "components/chart/AttemptsDonutChart";
import AttemptsChartCard from "components/card/AttemptsChartCard";
import {
  getSectionById,
  getAssignmentById,
  getStudentCount,
  getAverageTime,
  getAverageScore,
  getStandardDeviation,
  getAssessmentDetailsById,
  getAssignmentAttempts,
  nonRecursingGetAssignmentAttempts,
  getAssignmentAttemptsAnalytics,
  requestActionAssignmentDashboard,
  listActionAssignmentDashboard,
  rejectActionAssignmentDashboard,
} from "store/state/courses/actions";
import { STATUSES } from "components/elements/StatusPill";
import DashboardDetailsCard from "components/card/DashboardDetailsCard";
import ScoreDistributionCard from "components/card/ScoreDistributionCard";
import { CourseAssessmentTopicBreakdownCard } from "components/card/AssessmentTopicBreakdownCard";
import {
  AssessmentStudentBreakdownCard,
  AssessmentStudentBreakdownCardWithDuration,
} from "components/card/AssessmentStudentBreakdownCard";
import { ASSIGNMENT_TYPES } from "constants/common";

// Discipline API not yet available
const getDiscipline = (disciplineId) => {
  switch (disciplineId) {
    case "1":
      return "Mathematics";
    case "2":
      return "Physics";
    case "3":
      return "Chemistry";
    case "4":
      return "Science";
    case "5":
      return "Biology";
    default:
      return "Mathematics";
  }
};

const CourseAssessmentDashboard = () => {
  const { courseId, assignmentId } = useParams();
  const dispatch = useDispatch();
  const course = useSelector((state) => state.storedCourses.sections[courseId]);
  const assignment = useSelector((state) => state.storedCourses.assignments[assignmentId]);
  const assessmentSeriesId = assignment?.assessmentSeriesId;
  const assessment = useSelector((state) => state.storedCourses.assessments[assessmentSeriesId]);

  // TODO: Redirect if course or assignment don't exist
  // TODO: Stay loading until both have been fetched

  const isLoading = useSelector((state) => {
    const { apiCallInProgress } = state.storedCourses;
    return apiCallInProgress || !assignment || !assessment || !course;
  });

  useEffect(() => {
    if (!course) {
      dispatch(getSectionById({ courseId }));
    }
    if (!assignment) {
      dispatch(getAssignmentById({ sectionId: courseId, assignmentId }));
    }
    if (assessmentSeriesId && !assessment) {
      dispatch(getAssessmentDetailsById({ id: assessmentSeriesId, selector: "assessments" }));
    }
  }, [dispatch, courseId, course, assignment, assignmentId, assessmentSeriesId, assessment]);

  const getStudentCountData = useCallback(() => {
    dispatch(getStudentCount(assignmentId));
  }, [dispatch, assignmentId]);

  const getAverageTimeData = useCallback(() => {
    dispatch(getAverageTime(assignmentId));
  }, [dispatch, assignmentId]);

  const getAverageScoreData = useCallback(() => {
    dispatch(getAverageScore({ assignmentId }));
  }, [dispatch, assignmentId]);

  const getStandardDeviationData = useCallback(() => {
    dispatch(getStandardDeviation({ assignmentId }));
  }, [dispatch, assignmentId]);

  const getAttemptsData = useCallback(() => {
    dispatch(getAssignmentAttemptsAnalytics({ assignmentId }));
  }, [dispatch, assignmentId]);

  //const setAttemptsFilter = useCallback((attempts) => {
  //setFilters({
  //...filters,
  //attempts,
  //});
  //}, [filters]);

  return (
    <SectionDashboard modified>
      {!isLoading ? (
        <SectionBody>
          <Grid gutterSmall colSpacing>
            <Row>
              <Col flex>
                <DashboardDetailsCard
                  title={assignment.name}
                  status={assignment.isDraft ? STATUSES.DRAFT : STATUSES.LIVE}
                  detailsList={[
                    { name: "Course", value: course.name },
                    {
                      name: "Discipline",
                      value: `${getDiscipline(course.discipline)} ${course.courseNumber}`,
                    },
                    { name: "Term/Year", value: `${course.term} ${course.year}` },
                  ]}
                  iconPath="svg/ico-assessment-lg.svg"
                  iconClass="dashboard-assessment-icon"
                />
              </Col>
            </Row>

            <Row>
              <Col flex>
                <MetricCard
                  iconPath="svg/ico-metric-total-students.svg"
                  unit="Total Students"
                  getDataHandler={getStudentCountData}
                  valueSelector={(state) => state.storedCourses.studentTotalCount}
                />
              </Col>
              <Col flex>
                <MetricCard
                  iconPath="svg/ico-metric-avg.svg"
                  unit="avg"
                  getDataHandler={getAverageScoreData}
                  valueSelector={(state) => state.storedCourses.avgScore}
                />
              </Col>
              {assignment.assignmentType !== ASSIGNMENT_TYPES.PRACTICE && (
                <Col flex>
                  <MetricCard
                    iconPath="svg/ico-metric-avg-time.svg"
                    unit="avg time"
                    getDataHandler={getAverageTimeData}
                    valueSelector={(state) => state.storedCourses.avgTime}
                  />
                </Col>
              )}
              <Col flex>
                <MetricCard
                  iconPath="svg/ico-metric-std-dev.svg"
                  unit="std"
                  getDataHandler={getStandardDeviationData}
                  valueSelector={(state) => state.storedCourses.stdDev}
                />
              </Col>
            </Row>

            <Row>
              <Col size="33.33" flex>
                <AttemptsDonutChart assignmentId={assignmentId} />
              </Col>

              <Col size="33.33" flex>
                <ScoreDistributionCard assignmentId={assignmentId} assessment={assessment} />
              </Col>
              {assignment.attemptLimit >= 1 ? (
                <Col size="33.33" flex>
                  <AttemptsChartCard
                    getDataHandler={getAttemptsData}
                    assignmentId={assignmentId}
                    valueSelector={(state) => state.storedCourses.attemptsAnalytics.value}
                  />
                </Col>
              ) : null}
            </Row>

            <Row>
              <Col flex>
                <CourseAssessmentTopicBreakdownCard assignmentId={assignmentId} />
              </Col>
              {assignment.assignmentType === ASSIGNMENT_TYPES.PRACTICE ? (
                <Col flex>
                  <AssessmentStudentBreakdownCard assignmentId={assignmentId} />
                </Col>
              ) : (
                <Col flex>
                  <AssessmentStudentBreakdownCardWithDuration assignmentId={assignmentId} />
                </Col>
              )}
            </Row>
          </Grid>
        </SectionBody>
      ) : (
        <NoDataMessageContainer noIcon>
          {isLoading ? (
            <Loader />
          ) : (
            <NoDataMessage>
              No Dashboard content <br /> available at this time.
            </NoDataMessage>
          )}
        </NoDataMessageContainer>
      )}
    </SectionDashboard>
  );
};

export default CourseAssessmentDashboard;
