import React from "react";
import Lottie from "react-lottie";
import * as animationData from "./data.json";

const Loader = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
  };

  return (
    <Lottie
      options={defaultOptions}
      height={props.height ? props.height : 300}
      width={props.width ? props.width : 500}
    />
  );
};

export default Loader;
