/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */
import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Form, { FormHead, FormLogo, FormTitle, FormActions } from "styles/components/Form";

/**
 * Class for form archive.
 *
 * @class      FormConfirmation (name)
 */
class FormConfirmation extends Component {
  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props.modal.data.action();

    this.props.closeModal();
  };

  render() {
    const { closeModal, modal } = this.props;

    return (
      <Form onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            {modal.data.icon ? (
              <IconSvgComponent iconPath={`svg/${modal.data.icon}`} />
            ) : (
              <IconSvgComponent iconPath="svg/ico-device-hub-close.svg" />
            )}
          </FormLogo>

          <FormTitle>
            Are you sure you want to {modal.data.type} {modal.data.title}?
          </FormTitle>
        </FormHead>

        <FormActions>
          <ButtonLink transparent onClick={() => closeModal()} type="button">
            Cancel
          </ButtonLink>

          <ButtonLink type="submit" violet>
            <span>{modal.data.type.charAt(0).toUpperCase() + modal.data.type.slice(1)}</span>
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    closeModal,
  }
)(FormConfirmation);
