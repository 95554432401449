import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Switch, Route, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DocumentTitle from "react-document-title";

import { PaginatedTable, NoDataComponent } from "components/common/table";
import { MODAL_ACTIVATION, MODAL_CREATE_USER } from "lib/constants";
import { fetchAccountUsers, getAccounts, getCohorts } from "store/state/administration/actions";
import { openModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import SectionTable, { SectionBody } from "styles/components/SectionTable";
import Loader from "components/common/loader";
import { Pill } from "styles/components/Btns";
import TabNav from "components/elements/TabNav";
import Form, {
  FormActions,
  FormBody,
  FormCol,
  FormControls,
  FormRowGrid,
  FormIcon,
} from "styles/components/Form";
import Field from "components/common/Field";
import styled from "styled-components";
import { callUpdateAccountSettingsAPI } from "services/accounts";
import { LEVELS, createNotification } from "utilities/notification";
import { addNotification } from "store/state/ui/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Popover from "components/common/Popover";

const Button = styled.div`
  width: fit-content;
  padding: 4px 12px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #2049e6;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: #e6eaf1;
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.75;
    pointer-events: none;
    cursor: default;
  `}
`;

const debounce = (callback, delay) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

const getSearchParams = (filter) => {
  return Object.values(filter)
    .map((set, i) => {
      if (i === 0) return `${set.id}=${set.value}`;
      return `&${set.id}=${set.value}`;
    })
    .join("");
};

const InputFilterComponent = (props) => {
  const columnId = props.column.id;
  const { searchFilter, handleFilterChange } = props.meta;
  const matchingFilter = searchFilter[columnId];

  return (
    <input
      type="text"
      onChange={(event) => handleFilterChange(event, matchingFilter, columnId)}
      value={matchingFilter?.value || ""}
      style={{
        fontSize: "14px",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, .1)",
        backgroundColor: "#fff",
        padding: "5px 7px",
        borderRadius: "3px",
        fontWeight: 400,
        outlineWidth: 0,
      }}
      autoComplete="no"
    />
  );
};

const Users = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountId: viewingAccountId } = useParams();

  const accountId = useSelector((state) =>
    !!state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId
  );
  const users = useSelector((state) => state.administration.users);
  const usersTable = useSelector((state) => state.administration.usersTable);
  const accounts = useSelector((state) => state.administration.accounts);
  const cohorts = useSelector((state) => state.administration.cohorts);

  const { count } = usersTable;
  const currentAccount = accounts[0];
  const currentAccountSettings = currentAccount?.settings;
  const defaultCohortId = currentAccountSettings?.defaultCohortId || null;
  const assessmentTimeMultipliers =
    currentAccountSettings?.accommodations?.assessmentTimeMultipliers || [];
  const groupTags = currentAccountSettings?.groupTags || {};
  const hasGroupTags = Object.keys(groupTags).length > 0;
  const [searchFilter, setSearchFilter] = useState({});
  const [draftSettings, setDraftSettings] = useState({
    defaultCohortId,
    groupTags,
    orderedTagKeys: [""],
    groupTagsTouched: false,
    assessmentTimeMultipliers,
    settingsLoaded: false,
  });
  const [isPageLoading, setPageLoading] = useState(true);
  const [isSettingsLoading, setSettingsLoading] = useState(false);
  const [settingsError, setSettingsError] = useState("");

  const fetchData = useCallback(
    async ({ pageSize, pageIndex }) => {
      const searchParams = getSearchParams(searchFilter);
      setPageLoading(true);

      try {
        const users = dispatch(
          fetchAccountUsers({
            selector: "users",
            account: viewingAccountId,
            pageSize,
            page: pageIndex + 1,
            searchParams,
          })
        );
        const cohorts =
          pageIndex > 0
            ? Promise.resolve()
            : dispatch(getCohorts({ accountId: viewingAccountId, page: 1, pageSize: 100 }));
        const accounts =
          pageIndex > 0
            ? Promise.resolve()
            : dispatch(getAccounts({ selector: "accounts", accountId: viewingAccountId }));

        await Promise.all([users, cohorts, accounts]);

        setPageLoading(false);
      } catch (error) {
        console.log({ error });
        setPageLoading(false);
      }
    },
    [dispatch, searchFilter, viewingAccountId]
  );

  useEffect(() => {
    if (!isPageLoading && !draftSettings.settingsLoaded) {
      setDraftSettings({
        groupTags,
        orderedTagKeys: Object.keys(groupTags),
        defaultCohortId,
        assessmentTimeMultipliers,
        settingsLoaded: true,
      });
    }
    // if (
    //   (!draftSettings.groupTagsTouched && hasGroupTags) ||
    //   (draftSettings.assessmentTimeMultipliers.length === 0 &&
    //     assessmentTimeMultipliers.length !== 0)
    // ) {
    //   setDraftSettings((previous) => {
    //     return {
    //       ...previous,
    //       groupTags,
    //       orderedTagKeys: Object.keys(groupTags),
    //       defaultCohortId,
    //       assessmentTimeMultipliers,
    //     };
    //   });
    // }
  }, [
    hasGroupTags,
    draftSettings.groupTagsTouched,
    groupTags,
    defaultCohortId,
    assessmentTimeMultipliers,
    draftSettings.assessmentTimeMultipliers.length,
    isPageLoading,
    draftSettings.settingsLoaded,
  ]);

  useEffect(() => {
    if (history.location.pathname.includes("settings")) {
      fetchData({ pageSize: 10, pageIndex: 0 });
    }
  }, [fetchData, history.location.pathname]);

  const debouncedSearch = useCallback(
    debounce(async (searchParams) => {
      setPageLoading(true);

      await dispatch(
        fetchAccountUsers({
          selector: "users",
          account: viewingAccountId,
          resetData: false,
          appendRecords: false,
          searchParams,
        })
      );

      setPageLoading(false);
    }, 500),
    []
  );

  const handleFilterChange = useCallback(
    async (event, matchingFilter, columnId) => {
      const newFilter = {
        id: columnId,
        value: event.target.value,
      };

      // If the filter key already exists and the value has been updated, updating the matchiing filter
      // If the filter key already exists but the value is empty (deleted/reset) then remove the filter
      // If the filter is new add it
      const { [columnId]: matchFilter, ...restOfFilter } = searchFilter;

      const newSearchFilter = matchingFilter
        ? newFilter.value
          ? { ...searchFilter, [columnId]: newFilter }
          : restOfFilter
        : { ...searchFilter, [columnId]: newFilter };

      setSearchFilter(newSearchFilter);

      const searchParams = getSearchParams(newSearchFilter);

      if (["userRole", "status"].includes(columnId)) {
        setPageLoading(true);
        await dispatch(
          fetchAccountUsers({
            selector: "users",
            account: viewingAccountId,
            resetData: false,
            appendRecords: false,
            searchParams,
          })
        );
        setPageLoading(false);
      } else {
        debouncedSearch(searchParams);
      }
    },
    [debouncedSearch, dispatch, searchFilter, viewingAccountId]
  );

  const columns = useMemo(() => {
    return [
      {
        accessor: "studentId",
        Header: "Student ID",
        Filter: InputFilterComponent,
        minWidth: 75,
      },
      {
        accessor: "firstName",
        Header: "First Name",
        Filter: InputFilterComponent,
        minWidth: 125,
      },
      {
        accessor: "lastName",
        Header: "Last Name",
        Filter: InputFilterComponent,
        minWidth: 125,
      },
      {
        accessor: "email",
        Header: "Email",
        minWidth: 200,
        Filter: InputFilterComponent,
      },
      {
        id: "enrollmentId",
        accessor: "enrollments",
        Header: "Cohort Enrollment",
        minWidth: 200,
        Cell: (props) => {
          return (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              {props.row.original.enrollments?.map((enrollment) => {
                return (
                  <Pill key={enrollment.cohortId} small default>
                    {enrollment.cohortName}
                  </Pill>
                );
              })}
            </div>
          );
        },
        Filter: (props) => {
          const columnId = props.column.id;
          const { searchFilter, handleFilterChange, cohorts } = props.meta;
          const matchingFilter = searchFilter[columnId];

          return (
            <select
              onChange={(event) => handleFilterChange(event, matchingFilter, columnId)}
              value={matchingFilter?.value || ""}
              style={{
                fontSize: "14px",
                width: "100%",
                border: "1px solid rgba(0, 0, 0, .1)",
                backgroundColor: "#fff",
                padding: "5px 7px",
                borderRadius: "3px",
                fontWeight: 400,
                outlineWidth: 0,
              }}
            >
              <option value="">Show All</option>
              {cohorts.length === 0 && <option value="">Loading Cohorts...</option>}
              {cohorts.map((cohort) => {
                return (
                  <option key={cohort.id} value={cohort.id}>
                    {cohort.name}
                  </option>
                );
              })}
            </select>
          );
        },
      },
      {
        accessor: "assessmentTimeMultiplier",
        Header: "Time Multiplier",
        minWidth: 75,
        disableFilters: true,
        Cell: (props) => (props.value ? `${props.value}x` : "—"),
      },
      {
        id: "userRole",
        accessor: "role.label",
        Header: "Role",
        minWidth: 125,
        Filter: (props) => {
          const columnId = props.column.id;
          const { searchFilter, handleFilterChange } = props.meta;
          const matchingFilter = searchFilter[columnId];

          return (
            <select
              onChange={(event) => handleFilterChange(event, matchingFilter, columnId)}
              value={matchingFilter?.value || ""}
              style={{
                fontSize: "14px",
                width: "100%",
                border: "1px solid rgba(0, 0, 0, .1)",
                backgroundColor: "#fff",
                padding: "5px 7px",
                borderRadius: "3px",
                fontWeight: 400,
                outlineWidth: 0,
              }}
            >
              <option value="">Show All</option>
              <option value="Master Admin">Master Admin</option>
              <option value="Account Admin">Account Admin</option>
              <option value="Instructor">Instructor</option>
              <option value="Student">Student</option>
            </select>
          );
        },
        filterMethod: (filter, row) => {
          switch (filter.value) {
            case "Master Admin":
            case "Account Admin":
            case "Instructor":
            case "Student":
              return String(row[filter.id]) === filter.value;

            case "all":
            default:
              return true;
          }
        },
      },
      {
        accessor: "status",
        Header: "Status",
        minWidth: 50,
        Cell: (props) => {
          const iconPath =
            props.value === "active" ? "svg/ico-success.svg" : "svg/ico-not-active.svg";

          return <IconSvgComponent iconPath={iconPath} />;
        },
        Filter: (props) => {
          const columnId = props.column.id;
          const { searchFilter, handleFilterChange } = props.meta;
          const matchingFilter = searchFilter[columnId];

          return (
            <select
              onChange={(event) => handleFilterChange(event, matchingFilter, columnId)}
              value={matchingFilter?.value || ""}
              style={{
                fontSize: "14px",
                width: "100%",
                border: "1px solid rgba(0, 0, 0, .1)",
                backgroundColor: "#fff",
                padding: "5px 7px",
                borderRadius: "3px",
                fontWeight: 400,
                outlineWidth: 0,
              }}
            >
              <option value="">Show All</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          );
        },
        filterMethod: (filter, row) => {
          switch (filter.value) {
            case "active":
            case "not-active":
              return String(row[filter.id]) === filter.value;

            case "all":
            default:
              return true;
          }
        },
      },
      {
        accessor: "dropdown",
        Header: "",
        minWidth: 75,
        style: {
          display: "flex",
          justifyContent: "flex-end",
        },
        disableFilters: true,
        Cell: (props) => {
          const {
            yPositionStart,
            tableBodyRef,
            row: { original: user },
          } = props;

          return (
            <Popover
              placement="bottom-start"
              portalRef={tableBodyRef}
              yPositionStart={yPositionStart}
              TriggerComponent={(props) => {
                const { setRef, ...triggerProps } = props;

                return (
                  <ButtonLink setRef={setRef} {...triggerProps} isTableDropdownButton>
                    <span />
                    <span />
                    <span />
                  </ButtonLink>
                );
              }}
              actions={[
                {
                  name: "Edit",
                  onClick: () =>
                    dispatch(
                      openModal({
                        type: MODAL_CREATE_USER,
                        data: { id: user.id, editItem: user },
                      })
                    ),
                },
                {
                  name: user.status === "active" ? "Deactivate" : "Activate",
                  onClick: () => {
                    dispatch(
                      openModal({
                        type: MODAL_ACTIVATION,
                        data: {
                          type: "users",
                          id: user.id,
                          accountId,
                          status: user.status,
                          title: `${user.firstName} ${user.lastName}`,
                        },
                      })
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ];
  }, [accountId, dispatch]);

  const tabRoutes = [
    { name: "Users", path: `/accounts/${viewingAccountId}` },
    { name: "Settings", path: `/accounts/${viewingAccountId}/settings` },
  ];

  const cohortOptions = cohorts.map((cohort) => {
    return { value: cohort.id, label: cohort.name };
  });
  const selectedDefaultCohort = cohortOptions.find(
    (option) => option.value === draftSettings.defaultCohortId
  );

  const multiplierOptions = Array.from(Array(40).keys())
    .map((k) => {
      return {
        label: k * 0.25,
        value: k * 0.25,
      };
    })
    .filter((option) => option.value >= 1);
  const selectedMultipliers = multiplierOptions.filter((option) =>
    draftSettings.assessmentTimeMultipliers.includes(option.value)
  );

  return (
    <DocumentTitle title="Stemify | Administration">
      <SectionTable>
        <TabNav routes={tabRoutes} alignment="center" />

        <Switch>
          <Route exact path="/accounts/:accountId">
            <ButtonLink
              className="button-add"
              onClick={() =>
                dispatch(
                  openModal({
                    type: MODAL_CREATE_USER,
                    data: { accountId: viewingAccountId },
                  })
                )
              }
              violet
              fab
              size="43"
            />

            <SectionBody>
              <PaginatedTable
                data={users}
                columns={columns}
                isFilteringEnabled={true}
                serverSidePagination={true}
                fetchData={fetchData}
                count={count}
                meta={{
                  searchFilter,
                  handleFilterChange,
                  cohorts,
                }}
                isLoading={isPageLoading}
                NoDataComponent={() => (
                  <div style={{ height: "487px", padding: "40px 24px" }}>
                    <NoDataComponent type="User" iconPath="svg/ico-user.svg" />
                  </div>
                )}
                // defaultFilterMethod={(filter, row) => {
                //   return (
                //     String(row[filter.id])
                //       .toLowerCase()
                //       .search(filter.value.toLowerCase()) !== -1
                //   );
                // }}
              />
            </SectionBody>
          </Route>

          <Route exact path="/accounts/:accountId/settings">
            <SectionBody style={{ minHeight: "500px", padding: "40px 24px" }}>
              <h3>Account Settings</h3>

              {isPageLoading ? (
                <div style={{ height: "487px" }}>
                  <Loader />
                </div>
              ) : (
                <Form
                  style={{ maxWidth: "600px", margin: "0 auto" }}
                  onSubmit={async (event) => {
                    event.preventDefault();

                    setSettingsLoading(true);
                    setSettingsError("");

                    try {
                      const trimmedTags = Object.entries(draftSettings.groupTags).reduce(
                        (tags, entry) => {
                          const [key, value] = entry;
                          tags[key.trim()] = value;
                          return tags;
                        },
                        {}
                      );

                      await callUpdateAccountSettingsAPI({
                        account_id: viewingAccountId,
                        settings: {
                          default_cohort_id: draftSettings.defaultCohortId,
                          group_tags: trimmedTags,
                          accommodations: {
                            assessment_time_multipliers: draftSettings.assessmentTimeMultipliers,
                          },
                        },
                      });
                      await dispatch(
                        getAccounts({ selector: "accounts", accountId: viewingAccountId })
                      );

                      setDraftSettings((previous) => {
                        return {
                          ...previous,
                          groupTagsTouched: false,
                        };
                      });

                      setSettingsLoading(false);
                      dispatch(
                        addNotification({
                          notification: createNotification(
                            LEVELS.SUCCESS,
                            "Success",
                            "Settings updated successfully!",
                            5
                          ),
                        })
                      );
                    } catch (error) {
                      console.log("SETTINGS_ERROR", { error });
                      setSettingsLoading(false);
                      setSettingsError("There was an issue updating settings.");
                    }
                  }}
                >
                  <FormBody>
                    {settingsError && (
                      <div
                        style={{
                          backgroundColor: "#FF3E1D",
                          padding: "12px 16px",
                          textAlign: "center",
                          color: "#ffffff",
                          marginBottom: "15px",
                        }}
                      >
                        {settingsError}
                      </div>
                    )}

                    <FormRowGrid style={{ marginBottom: "25px" }}>
                      <h5>Default Cohort</h5>
                      <FormCol>
                        <FormControls>
                          <Field
                            id="default_cohort_id"
                            isSelect={true}
                            onChange={(selectedCohort) => {
                              setDraftSettings((prevState) => {
                                return {
                                  ...prevState,
                                  defaultCohortId: selectedCohort?.value || null,
                                };
                              });
                            }}
                            value={selectedDefaultCohort}
                            options={cohortOptions}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select Cohort"
                            noOptionsMessage={() =>
                              isPageLoading ? "Loading..." : "No cohorts found"
                            }
                          />
                        </FormControls>
                      </FormCol>
                    </FormRowGrid>

                    <FormRowGrid>
                      <h5>Group Tag Mapping</h5>
                      <FormCol>
                        <FormControls>
                          {draftSettings.orderedTagKeys.map((groupTagKey, index) => {
                            const selectedGroupTagValues =
                              cohortOptions.filter((option) => {
                                const tagKeyValues =
                                  draftSettings.groupTags[groupTagKey]
                                    ?.split(/[ ,]+/)
                                    .filter((s) => s) || "";

                                return tagKeyValues.includes(option.value);
                              }) || [];

                            return (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <div style={{ position: "absolute", right: "-30px", zIndex: 100 }}>
                                  <FormIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) => {
                                      setDraftSettings((prevState) => {
                                        const {
                                          [groupTagKey]: currentTagValue,
                                          ...restOfTags
                                        } = prevState.groupTags;

                                        return {
                                          ...prevState,
                                          groupTags: restOfTags,
                                          orderedTagKeys: prevState.orderedTagKeys.filter(
                                            (k) => k !== groupTagKey
                                          ),
                                          groupTagsTouched: true,
                                        };
                                      });
                                    }}
                                  >
                                    <IconSvgComponent iconPath="svg/ico-delete.svg" />
                                  </FormIcon>
                                </div>

                                <Field
                                  type="text"
                                  placeholder="Group Tag"
                                  id={`${groupTagKey}-${index}`}
                                  required={true}
                                  value={groupTagKey}
                                  onChange={(event) => {
                                    const rawValue = event.target.value;

                                    const updatedTagKeyName =
                                      rawValue.trim().length === 1 ? rawValue.trim() : rawValue;

                                    setDraftSettings((prevState) => {
                                      const prevTags = prevState.groupTags;
                                      const {
                                        [groupTagKey]: groupTagValue,
                                        ...restOfTags
                                      } = prevTags;

                                      const newState = {
                                        ...prevState,
                                        groupTags: {
                                          ...restOfTags,
                                          [updatedTagKeyName]: prevTags[groupTagKey],
                                        },
                                        orderedTagKeys: prevState.orderedTagKeys.map(
                                          (orderedKey) => {
                                            if (orderedKey === groupTagKey) {
                                              return updatedTagKeyName;
                                            }

                                            return orderedKey;
                                          }
                                        ),
                                        groupTagsTouched: true,
                                      };

                                      return newState;
                                    });
                                  }}
                                  fieldWidth={200}
                                />

                                <div style={{ width: "20px" }}>
                                  <FormIcon>
                                    <IconSvgComponent iconPath="svg/ico-arrow-right-color.svg" />
                                  </FormIcon>
                                </div>

                                <Field
                                  placeholder="Cohort"
                                  id={`${groupTagKey}-cohorts-${index}`}
                                  value={selectedGroupTagValues}
                                  required={true}
                                  isSelect={true}
                                  isMultiselect={true}
                                  fieldWidth={250}
                                  style={{ flex: 1 }}
                                  onChange={(selectedCohorts) => {
                                    setDraftSettings((prevState) => {
                                      return {
                                        ...prevState,
                                        groupTags: {
                                          ...prevState.groupTags,
                                          [groupTagKey]:
                                            selectedCohorts.length > 0
                                              ? selectedCohorts.map((c) => c.value).join(",")
                                              : null,
                                        },
                                        groupTagsTouched: true,
                                      };
                                    });
                                  }}
                                  options={cohortOptions}
                                  isSearchable={true}
                                  isClearable={true}
                                  noOptionsMessage={() =>
                                    isPageLoading ? "Loading..." : "No cohorts found"
                                  }
                                />
                              </div>
                            );
                          })}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          >
                            <Button
                              disabled={isSettingsLoading || settingsError}
                              onClick={() => {
                                setDraftSettings((prevState) => {
                                  return {
                                    ...prevState,
                                    // Hack: add a space per existing row into the new field so they don't get duplicated onChange
                                    orderedTagKeys: prevState.orderedTagKeys.concat(
                                      prevState.orderedTagKeys.map((k) => " ").join("")
                                    ),
                                    groupTagsTouched: true,
                                  };
                                });
                              }}
                            >
                              Add Row
                            </Button>
                          </div>
                        </FormControls>
                      </FormCol>
                    </FormRowGrid>

                    <FormRowGrid style={{ marginTop: "25px" }}>
                      <h5>Assessment Time Multipliers</h5>

                      <FormCol>
                        <FormControls>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <Field
                              placeholder="Multipliers"
                              id={"assessment-time-multipliers"}
                              value={selectedMultipliers}
                              isSelect={true}
                              isMultiselect={true}
                              fieldWidth={250}
                              style={{ flex: 1 }}
                              onChange={(selectedMultipliers) => {
                                setDraftSettings((prevState) => {
                                  return {
                                    ...prevState,
                                    assessmentTimeMultipliers: selectedMultipliers.map(
                                      (option) => option.value
                                    ),
                                  };
                                });
                              }}
                              options={multiplierOptions}
                              isSearchable={true}
                              isClearable={true}
                            />
                          </div>
                        </FormControls>
                      </FormCol>
                    </FormRowGrid>
                  </FormBody>
                  <FormActions>
                    <ButtonLink disabled={isSettingsLoading || settingsError} type="submit" violet>
                      <span>Update</span>
                    </ButtonLink>
                  </FormActions>
                </Form>
              )}
            </SectionBody>
          </Route>
        </Switch>
      </SectionTable>
    </DocumentTitle>
  );
};

export default Users;
