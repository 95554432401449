/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import FormContentTemplate from "components/form/FormContentTemplate";
import { SectionWizard, SectionTitle, SectionContent } from "styles/components/Section";

const ProgramContentFromTemplate = ({ changeStep, programType }) => {
  return (
    <SectionWizard>
      <SectionTitle>Create Content</SectionTitle>

      <SectionContent>
        <FormContentTemplate programType={programType} />
      </SectionContent>
    </SectionWizard>
  );
};

export default ProgramContentFromTemplate;
