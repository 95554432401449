import React from "react";
import { connect } from "react-redux";
import { BreadcrumbsContainer } from "styles/components/BreadcrumbsContainer";

export const PracticeAssignment = connect((state, ownProps) => {
  const name = state.practiceAssignment.assignmentName;
  return { name };
})(({ name }) => (
  <BreadcrumbsContainer>
    <span>{name}</span>
  </BreadcrumbsContainer>
));

export const PracticeAssignmentHome = ({ name }) => (
  <BreadcrumbsContainer>
    <span>Practice Assignment</span>
  </BreadcrumbsContainer>
);

export const PracticeAssignmentResults = ({ name }) => (
  <BreadcrumbsContainer>
    <span>Results</span>
  </BreadcrumbsContainer>
);
