import React, { createRef, PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, Legend } from "recharts";

class DonutChart extends PureComponent {
  containerRef = createRef(null);

  state = {
    innerPieIndex: -1,
    outerPieIndex: -1,
    containerWidth: null,
    containerHeight: null,
  };

  timer = null;

  debounce = (callback, delay) => {
    return (...args) => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => callback(...args), delay);
    };
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    const containerWidth = this.containerRef?.current?.offsetWidth;
    const containerHeight = this.containerRef?.current?.offsetHeight;

    this.setState({ containerWidth, containerHeight });
  };

  onInnerPieEnter = (data, index) => {
    this.setState({
      innerPieIndex: index,
      outerPieIndex: -1,
    });
  };

  onInnerPieLeave = (data) => {
    this.setState({
      innerPieIndex: -1,
    });
  };

  onOuterPieEnter = (data, index) => {
    this.setState({
      outerPieIndex: index,
      innerPieIndex: -1,
    });
  };

  onOuterPieLeave = (data) => {
    this.setState({
      outerPieIndex: -1,
    });
  };

  processLegendData = (data) => {
    this.setState({
      outerPieIndex: -1,
    });
  };

  processLegendData = (data, charts) => {
    const legendData = data.map((item, i) => ({
      name: item.name,
      value: item.value,
      color: charts.colors[i],
    }));
    return legendData;
  };

  getConcatenatedLegendData = () => {
    const { data, options } = this.props;
    let legendData = this.processLegendData(data[0], options.charts[0]);
    return legendData;
  };

  renderActiveShape = (props, total, width) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      percent,
      payload,
    } = props;
    const { highlightRadius } = this.props.options;
    const percentValue = Math.round(percent * 100);
    const deltaFontSize = width < 400 ? 18 : 22;
    const percentSignDeltaX =
      percentValue.toString().length * deltaFontSize + percentValue.toString().length;

    return (
      <g>
        <text x={cx} y={cy} dy={24} textAnchor="middle" fill={"#4A4A4A"} fontWeight="bold">
          <tspan x={cx} y={cy - 10} fontSize={width < 400 ? "60px" : "72px"}>
            {percentValue}
          </tspan>
          <tspan x={cx + percentSignDeltaX} y={cy - 20} fontSize={width < 400 ? "18" : "24px"}>
            %
          </tspan>
          <tspan x={cx} y={cy + 40} fontSize={width < 400 ? "16" : "22px"}>
            {payload.value} / {total}
          </tspan>
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - highlightRadius}
          outerRadius={outerRadius + highlightRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  render() {
    const { containerWidth, containerHeight } = this.state;
    const { options, data, margin, renderActiveShape } = this.props;
    const legendData = this.getConcatenatedLegendData();
    const total = legendData.reduce((total, prop) => total + prop.value, 0);

    const shrinkFactor = 1;
    const defaultWidth = this.containerRef?.current?.offsetWidth
      ? Math.round(this.containerRef?.current?.offsetWidth * shrinkFactor)
      : 300;
    const width = containerWidth ? Math.round(containerWidth * shrinkFactor) : defaultWidth;

    const defaultHeight = this.containerRef?.current?.offsetHeight
      ? Math.round(this.containerRef?.current?.offsetHeight * shrinkFactor)
      : 300;
    const height = containerHeight ? Math.round(containerHeight * shrinkFactor) : defaultHeight;

    return (
      <div
        ref={this.containerRef}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PieChart width={width} height={height} margin={margin}>
          <Pie
            activeIndex={this.state.innerPieIndex}
            activeShape={(props) => {
              if (renderActiveShape) return renderActiveShape(props);
              else return this.renderActiveShape(props, total, width);
            }}
            data={data[0]}
            innerRadius={options.charts[0].innerRadius}
            outerRadius={options.charts[0].outerRadius}
            dataKey="value"
            onMouseEnter={this.onInnerPieEnter}
            onMouseLeave={this.onInnerPieLeave}
          >
            {data[0].map((entry, index) => (
              <Cell key={"innerpie" + index} fill={options.charts[0].colors[index]} />
            ))}
          </Pie>

          {data.length > 1 && options.charts.length > 1 && (
            <Pie
              activeIndex={this.state.outerPieIndex}
              activeShape={renderActiveShape ? renderActiveShape : this.renderActiveShape}
              data={data[1]}
              innerRadius={options.charts[1].innerRadius}
              outerRadius={options.charts[1].outerRadius}
              dataKey="value"
              onMouseEnter={this.onOuterPieEnter}
              onMouseLeave={this.onOuterPieLeave}
            >
              {data[1].map((entry, index) => (
                <Cell key={"outerpie" + index} fill={options.charts[1].colors[index]} />
              ))}
            </Pie>
          )}

          <Legend
            wrapperStyle={{
              columnCount: options.legend.columnCount ? options.legend.columnCount : 1,
            }}
            layout="vertical"
            verticalAlign={width < 400 ? "bottom" : "middle"}
            align={width < 400 ? "center" : "right"}
            payload={legendData.map((item) => ({
              id: item.name,
              type: "circle",
              value: (
                <span style={{ color: "#000000" }}>
                  {options.legend.singularFormat && options.legend.pluralFormat
                    ? item.value > 1
                      ? eval(options.legend.pluralFormat)
                      : eval(options.legend.singularFormat)
                    : item.name}
                </span>
              ),
              color: item.color,
            }))}
          />
        </PieChart>
      </div>
    );
  }
}

export default DonutChart;
