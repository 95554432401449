import React, { Component } from "react";
import PropTypes from "prop-types";
import { SplitFieldSlot } from "styles/components/Form";
import { limitText } from "utilities/commonFunctions";
import { selectInputText } from "../../utilities/commonFunctions";

/*
Usage - Example :
    <SplitField
        fieldName="accessCode"
        inputType="password" //Type that is accepted in input field
        numberOfSlots={6}
        charactersPerSlot={1}
        getValue={this.getAccessCodeValue} //To send final value to calling component
                - In calling component define getAccessCodeValue() to set the local state manually
        isValid={this.isAccessCodeValid} //To send if value is valid to calling component
                - In calling component define isAccessCodeValid() to set the local state manually
    >
</SplitField>
*/

class SplitField extends Component {
  state = {
    value: "",
    isComplete: false,
  };

  static propTypes = {
    fieldName: PropTypes.string,
    numberOfSlots: PropTypes.number,
    charactersPerSlot: PropTypes.number,
    inputType: PropTypes.string,
  };

  render() {
    return <React.Fragment>{this.fieldSlots()}</React.Fragment>;
  }

  fieldSlots = () => {
    const { numberOfSlots } = this.props;
    var fieldSlots = [];
    for (var i = 0; i < numberOfSlots; i++) {
      fieldSlots.push(this.fieldSlot(i));
    }
    return fieldSlots;
  };

  fieldSlot = (i) => {
    const { fieldName, inputType, charactersPerSlot } = this.props;
    const id = fieldName + i;
    return (
      <SplitFieldSlot
        key={i}
        id={id}
        name={id}
        type={"text"}
        onChange={(e) => this.handleInputChange(e, i)}
        onClick={(e) => this.handleInputClick(e)}
        styleName={charactersPerSlot === 1 && "single"}
        secure={inputType === "password"}
        autocomplete={"off"}
      ></SplitFieldSlot>
    );
  };

  handleInputChange = (event, i) => {
    event.target.setAttribute("type", "password");
    const { fieldName, charactersPerSlot } = this.props;
    limitText(event, event.target.id, charactersPerSlot, true, fieldName + (i + 1));
    this.setValuesInState();
  };

  setValuesInState = () => {
    const { fieldName, numberOfSlots, charactersPerSlot } = this.props;
    let concatenatedValue = "",
      isValueComplete = false;
    for (var j = 0; j < numberOfSlots; j++) {
      let slot = document.getElementById(fieldName + j);
      if (slot.value) {
        concatenatedValue = concatenatedValue + slot.value;
      }
    }
    if (concatenatedValue.length === numberOfSlots * charactersPerSlot) {
      isValueComplete = true;
    } else {
      isValueComplete = false;
    }

    this.setState({
      value: concatenatedValue,
      isComplete: isValueComplete,
    });

    this.props.getValue(concatenatedValue); //Return concatenatedValue to parent
    this.props.isValid(isValueComplete); //Return isValueComplete to parent
  };

  handleInputClick = (event) => {
    selectInputText(event);
  };
}

export default SplitField;
