import { createAction } from "redux-actions";
import { COMMON_MESSAGES } from "constants/messages";
import {
  callGetLearningUnitsOfProgramAPI,
  callgetLearningModulesByFilter,
  fetchLearningModuleById,
  fetchLearningUnitById,
} from "services/learningLibrary";
import { getUserProgram, getUserCurrentAccount } from "services/users";
import { list, rejectPageNotFound } from "store/state/administration/actions";
import { setIsNavigationFromTopicFilter } from "utilities/localStore";

export const requestLearningLibrary = createAction("REQUEST_LEARNING_LIBRARY");
export const rejectLearningLibrary = createAction("REJECT_LEARNING_LIBRARY");
export const listLearningLibrary = createAction("LIST_LEARNING_LIBRARY");
export const requestLearningUnit = createAction("REQUEST_LEARNING_UNIT");
export const requestLearningModule = createAction("REQUEST_LEARNING_MODULE");
export const receiveLearningModule = createAction("RECEIVE_LEARNING_MODULE");
export const receiveLearningUnit = createAction("RECEIVE_LEARNING_UNIT");
export const rejectLearningUnit = createAction("REJECT_LEARNING_UNIT");
export const rejectLearningModule = createAction("REJECT_LEARNING_MODULE");

export const resetApiErrors = createAction("RESET_API_ERRORS");
export const successLogout = createAction("SUCCESS_LOGOUT");
// export const isNavigatedUsingTopicID = createAction("IS_NAVIGATE_USING_TOPICID");

//Learning Library
export const getLearningUnitsOfProgram = ({ programName = "" }) => {
  return async (dispatch, getState) => {
    dispatch(
      requestLearningLibrary({ resetData: true, isFilteredByTopic: false, filterTopic: "" })
    );
    const state = getState();
    const accountId = getUserCurrentAccount(state);

    try {
      // first fetch program if we don't have it yet (page refresh / non-login)
      const programsResponse = await getUserProgram({ accountId });
      let resultProgramId;
      if (programsResponse.status == "success") {
        resultProgramId = programsResponse.data.find((program) => program.name === programName)?.id;
        if (resultProgramId) {
          dispatch(list({ selector: "program", items: resultProgramId }));
        }
        dispatch(list({ selector: "programs", items: programsResponse.data }));
        dispatch(list({ selector: "account", items: accountId }));
      }
      if (resultProgramId) {
        const response = await callGetLearningUnitsOfProgramAPI(accountId, resultProgramId);
        if (response.status === "success") {
          let learningLibrary = decorateLearningLibraryResponse(response.data);
          dispatch(listLearningLibrary({ items: learningLibrary }));
          return response;
        } else {
          dispatch(rejectLearningLibrary({ errorItem: response.data }));
          dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
          return response;
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectLearningLibrary({
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

// Learning Unit
export const getLearningUnit = ({ unitId }) => {
  return async (dispatch, getState) => {
    dispatch(requestLearningUnit({ resetData: true }));
    const state = getState();
    var accountId = getUserCurrentAccount(state);
    const programIdExists = !!state.administration.program;

    try {
      // On page reload update the user program in state
      !programIdExists &&
        (await getUserProgram({ accountId }).then((res) => {
          if (res.status == "success") {
            dispatch(list({ selector: "program", items: res.data[0].id }));
            dispatch(list({ selector: "account", items: accountId }));
          } else {
          }
        }));

      const response = await fetchLearningUnitById({ unitId, accountId });
      if (response.status === "success") {
        const learningUnit = decorateLearningUnitResponse(response.data);
        setIsNavigationFromTopicFilter({
          isFilterByTopic: false,
          type: null,
          id: null,
          filterTopic: null,
        });
        dispatch(receiveLearningUnit({ learningUnit, isFilteredByTopic: false, filterTopic: "" }));
      } else {
        dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
        dispatch(rejectLearningUnit({ errorItem: response.data }));
      }

      return response;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectLearningUnit({
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

// Learning Modules by Topic
export const getLearningModulesByFilter = ({ type, id }) => {
  return async (dispatch, getState) => {
    dispatch(requestLearningLibrary({ resetData: true, isFilteredByTopic: true, filterTopic: "" }));
    const state = getState();
    const programIdExists = !!state.administration.program;
    const accountId = getUserCurrentAccount(state);

    try {
      // first fetch program if we don't have it yet (page refresh / non-login)
      !programIdExists &&
        (await getUserProgram({ accountId }).then((res) => {
          if (res.status == "success") {
            dispatch(list({ selector: "program", items: res.data[0].id }));
            dispatch(list({ selector: "account", items: accountId }));
          } else {
          }
        }));

      const programId = getState().administration.program;

      if (programId) {
        const response = await callgetLearningModulesByFilter({ accountId, type, id });

        if (response.status === "success") {
          const learningUnit = decorateLearningUnitByTopicResponse(response.data, type, id);

          setIsNavigationFromTopicFilter({
            isFilterByTopic: true,
            type,
            id,
            filterTopic: learningUnit.name,
          });
          dispatch(
            receiveLearningUnit({
              learningUnit,
              isFilteredByTopic: true,
              filterTopic: learningUnit.name,
            })
          );
        } else {
          dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
          dispatch(rejectLearningUnit({ errorItem: response.data }));
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectLearningUnit({
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getLearningModule = ({ moduleId }) => {
  return async (dispatch, getState) => {
    dispatch(requestLearningModule({ resetData: true }));
    try {
      const response = await fetchLearningModuleById({ moduleId });
      if (response.status === "success") {
        let learningModule = response.data.learning_modules[0];
        const unitName = response.data.name;
        const unitId = response.data.id;
        dispatch(receiveLearningModule({ learningModule, unitId, unitName }));
      } else {
        dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
        dispatch(rejectLearningModule({ errorItem: response.data }));
      }
      return response;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectLearningModule({
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

const decorateLearningLibraryResponse = (obj) => {
  return {
    id: obj.id,
    name: obj.name,
    learningUnits: obj.learning_units.map((learning_unit) => ({
      id: learning_unit.id,
      name: learning_unit.name,
      image: learning_unit.image,
      lessonsCount: learning_unit.lessonsCount,
    })),
  };
};

const decorateLearningUnitResponse = (obj) => {
  return {
    id: obj.learning_units[0].id,
    name: obj.learning_units[0].name,
    learningModules: obj.learning_units[0].learning_modules.map((learning_module) => ({
      id: learning_module.id,
      name: learning_module.name,
      image: learning_module.image,
      completionTimeMins: Math.round(learning_module.completionTimeMins),
      isRecent: learning_module.is_recent,
      isComplete: learning_module.is_complete,
      progressPercent: learning_module.progressPercent,
      openstax_resource: learning_module.openstax_resource,
    })),
  };
};

const decorateLearningUnitByTopicResponse = (obj) => {
  return {
    id: obj.learning_library[0].id,
    name: obj.learning_library[0].name,
    learningModules: obj.learning_library[0].learning_modules.map((learning_module) => ({
      id: learning_module.id,
      name: learning_module.name,
      image: learning_module.image,
      completionTimeMins: learning_module.completionTimeMins
        ? Math.round(learning_module.completionTimeMins)
        : 0,
      isRecent: learning_module.is_recent,
      isComplete: learning_module.is_complete,
      progressPercent: learning_module.progressPercent,
      openstax_resource: learning_module.openstax_resource,
    })),
  };
};
