/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import AutosizeInput from "react-input-autosize";
import IconSvgComponent from "components/common/IconSvgComponent";

import TitleEditable, {
  TitleContent,
  TitleField,
  ButtonEdit,
} from "styles/components/TitleEditable";

import {
  BuilderAnswer,
  BuilderAnswerContent,
  BuilderAnswerActions,
} from "styles/components/BuilderAnswer";

import { QuestionItem } from "styles/components/SectionBuilder";
import Tooltip from "styles/components/Tooltip";

import { deleteBuilderAnswer, updateBuilderAnswer } from "store/state/builderAssessment/actions";

class BuilderAnswerBlock extends Component {
  state = {
    answerEdit: false,
    answerText: "",
    answerStatus: false,
    answerLetter: "",
    answerPlaceholder: "Type your answer here.",
  };

  componentDidMount() {
    this.setState({
      answerText: this.props.answerText,
      answerStatus: this.props.isAnswerRight,
      answerLetter: this.props.letter,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.answerText !== prevProps.answerText) {
      this.setState({
        answerText: this.props.answerText,
      });
    }

    if (this.props.isAnswerRight !== prevProps.isAnswerRight) {
      this.setState({
        answerStatus: this.props.isAnswerRight,
      });
    }

    if (this.props.letter !== prevProps.letter) {
      this.setState({
        answerLetter: this.props.letter,
      });
    }
  }

  editAnswer = (e) => {
    this.setState({ answerEdit: true });
  };

  handleAnswerChange = (event) => {
    this.setState({ answerText: event.target.value });
  };

  handleFieldFocusOut = (event) => {
    this.setState({ answerEdit: false });

    this.props.updateBuilderAnswer({
      id: this.props.id,
      answerText: this.state.answerText,
    });
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.setState({ answerEdit: false });

      this.props.updateBuilderAnswer({
        id: this.props.id,
        answerText: this.state.answerText,
      });
    }
  };

  deleteAnswer = (e) => {
    this.props.deleteBuilderAnswer(this.props.id);
  };

  toggleAnswerRightWrong = (e) => {
    this.setState({ answerStatus: !this.state.answerStatus }, () => {
      this.props.updateBuilderAnswer({
        id: this.props.id,
        answerStatus: this.state.answerStatus,
      });
    });
  };

  render() {
    const { answerEdit, answerText, answerStatus, answerLetter, answerPlaceholder } = this.state;

    return (
      <BuilderAnswer>
        <BuilderAnswerContent>
          {this.props.children}

          <QuestionItem noBorder>
            <span>{answerLetter}</span>
          </QuestionItem>

          <TitleEditable isBlock editMode={answerEdit} onClick={(e) => this.editAnswer(e)}>
            {!answerEdit ? (
              <TitleContent isAnswer>{answerText ? answerText : answerPlaceholder}</TitleContent>
            ) : (
              <TitleField isAnswer>
                <AutosizeInput
                  name="form-field-name"
                  value={answerText}
                  placeholder={answerPlaceholder}
                  onChange={(e) => this.handleAnswerChange(e)}
                  onBlur={this.handleFieldFocusOut}
                  onKeyDown={this.handleKeyDown}
                />
              </TitleField>
            )}
          </TitleEditable>
        </BuilderAnswerContent>

        <BuilderAnswerActions>
          <ButtonEdit
            isAlt
            onClick={() => this.toggleAnswerRightWrong()}
            data-tip="Right/Wrong"
            data-place={"bottom"}
          >
            {answerStatus && (
              <IconSvgComponent
                iconPath="svg/radio-btn-true.svg"
                additionalClass={"icon-answer-state"}
              />
            )}
            {!answerStatus && (
              <IconSvgComponent
                iconPath="svg/ico-incorrect.svg"
                additionalClass={"icon-answer-state"}
              />
            )}
          </ButtonEdit>

          <ButtonEdit isAlt data-tip="Add Image" data-place={"bottom"}>
            <IconSvgComponent iconPath="svg/ico-picture.svg" additionalClass={"icon-pic"} />
          </ButtonEdit>

          <ButtonEdit
            isAlt
            data-tip="Tags"
            data-place={"bottom"}
            onClick={() => this.props.onTagsClick()}
          >
            <IconSvgComponent iconPath="svg/ico-tags.svg" />
          </ButtonEdit>

          <ButtonEdit
            isAlt
            onClick={() => this.deleteAnswer()}
            data-tip="Delete"
            data-place={"bottom"}
          >
            <IconSvgComponent iconPath="svg/ico-close-thick.svg" additionalClass={"icon-remove"} />
          </ButtonEdit>
        </BuilderAnswerActions>

        <Tooltip />
      </BuilderAnswer>
    );
  }
}

export default connect(null, {
  deleteBuilderAnswer,
  updateBuilderAnswer,
})(BuilderAnswerBlock);
