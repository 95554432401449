import React from "react";
import { SpinnerOverlay, SpinnerContainer } from "styles/components/Courses";

const withSpinner = (WrapperComponent) => ({ isLoading, ...otherProps }) => {
  return isLoading ? (
    <SpinnerOverlay>
      <SpinnerContainer />
    </SpinnerOverlay>
  ) : (
    <WrapperComponent {...otherProps} />
  );
};

export const LoadingSpinner = ({ isLoading, children }) => {
  return isLoading ? (
    <SpinnerOverlay>
      <SpinnerContainer />
    </SpinnerOverlay>
  ) : (
    children
  );
};

export default withSpinner;
