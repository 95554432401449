/**
 * The external dependecies
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import FormCreateBuilderAssessment from "components/form/FormCreateBuilderAssessment";

/**
 * @class      ModalCreateBuilderAssessment (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */
const ModalCreateBuilderAssessment = (props) => {
  return (
    <ModalFrame>
      <FormCreateBuilderAssessment />
    </ModalFrame>
  );
};

export default ModalCreateBuilderAssessment;
