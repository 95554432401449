import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Zendesk from "react-zendesk";

const openZendesk = () => window.zE("webWidget", "open");

const configureZendesk = (name, email) => {
  window.zE("webWidget", "prefill", {
    name: {
      value: name,
    },
    email: {
      value: email,
    },
  });
  window.zESettings = {
    webWidget: {
      color: { theme: "#808898" },
      launcher: {
        label: {
          "*": "Help",
        },
        mobile: {
          labelVisible: false,
        },
      },
    },
  };
};

const mapStateToProps = (state) => {
  return {
    name: state.user.name,
    email: state.user.email,
  };
};

const mapDisptachToProps = () => ({});

const ZendeskForm = connect(
  mapStateToProps,
  mapDisptachToProps
)(({ name, email }) => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      configureZendesk(name, email);
    }
  }, [isLoaded, name, email]);

  return (
    <Zendesk zendeskKey="38da32c6-7e28-492c-8fd8-cb080de3700f" onLoaded={() => setLoaded(true)} />
  );
});

export { openZendesk, ZendeskForm };
