/*
JSON Format for rules to be given outside the state :
this.rules = {
            fieldname:{
            //For validation based on 'required' attribute
                requiredMessage: PAGE_NAME_MESSAGES.EMAIL_REQUIRED, //'Please enter an email'
            //For validation based on 'type' attribute
                typeEmailMessage: PAGE_NAME_MESSAGES.EMAIL_INVALID, //'Please enter a valid email ID'
            //For validation to allow input that satisfies regular expression
                expression :/^[a-z A-Z]*$/,
                expressionMessage: PAGE_NAME_MESSAGES.NAME_ALPHABETS_ONLY, //'Please enter only alphabets'
            //For validation to disallow input that satisfies regular expression
                violatingExpression :/^[0-9]*$/,
                expressionMessage: PAGE_NAME_MESSAGES.NAME_CANNOT_HAVE_ALL_DIGITS, //'Name should not be numeric'
            //For validation based on 'min' attribute
                minMessage: PAGE_NAME_MESSAGES.PHONE_NUMBER_MIN, //'Phone number is too short'
            //For validation based on 'max' attribute
                maxMessage: PAGE_NAME_MESSAGES.PHONE_MAX //'Phone number is too long'
            },
            anotherFieldName : {
                ...
            },
            ...,
            formValid: true
        }
If one or all of the above is/are missing, default error messages will be set.
The constants are to be defined under 'src/constants/messages'.
*/

export const validateSelectField = (formErrors, rules, properties) => {
  let { name, label, value, required } = properties;
  let errorFound = false;

  //Required field validation
  if (required && (!value || !value.value || value.value.trim() === "")) {
    setValidityAndMessage(
      name,
      formErrors,
      false,
      rules && rules[name] && rules[name].requiredMessage
        ? rules[name].requiredMessage
        : label
        ? label + " is a required field"
        : "This field is required"
    );
    errorFound = true;
  }

  if (!errorFound) {
    setValidityAndMessage(name, formErrors, true, "");
  }

  return formErrors;
};

export const validateAutoCompleteField = (formErrors, rules, properties) => {
  let { name, value, required } = properties;

  if (required && (!value || (typeof value === "string" && value.trim() === ""))) {
    setValidityAndMessage(name, formErrors, false, rules[name].requiredMessage);
  } else {
    setValidityAndMessage(name, formErrors, true, "");
  }

  return formErrors;
};

export const validateDateField = (formErrors, rules, properties) => {
  let { name, value, required } = properties;

  if (required && !value) {
    setValidityAndMessage(name, formErrors, false, rules[name].requiredMessage);
  } else if (value && !value.isValid()) {
    setValidityAndMessage(name, formErrors, false, rules[name].typeDateMessage);
  } else if (rules[name].hasOwnProperty("valueGreaterThan")) {
    if (value.diff(properties["compareWith"], "minutes") >= 0) {
      setValidityAndMessage(name, formErrors, false, rules[name].fromToInvalidMessage);
    } else {
      setValidityAndMessage(name, formErrors, true, "");
    }
  } else {
    setValidityAndMessage(name, formErrors, true, "");
  }
  return formErrors;
};

export const validateField = (formErrors, rules, element, label, trimValues, element2) => {
  var name2, value2;

  if (element) {
    let { name, value, required, type, min, max } = element;

    if (element2) {
      name2 = element2.name;
      value2 = element2.value;
    }

    if (trimValues) {
      value = value.trim();
    }

    return validateFieldContents(formErrors, rules, {
      name,
      value,
      required,
      type,
      min,
      max,
      label,
      name2,
      value2,
    });
  }

  return formErrors;
};

const validateFieldContents = (formErrors, rules, properties) => {
  const { name, value, required, type, min, max, label, name2, value2 } = properties;
  let errorFound = false;
  //Validation based on required attribute
  if (required && (!value || value.trim() === "")) {
    setValidityAndMessage(
      name,
      formErrors,
      false,
      rules && rules[name] && rules[name].requiredMessage
        ? rules[name].requiredMessage
        : label
        ? label + " is a required field"
        : "This field is required"
    );
    errorFound = true;
  }

  //Validation based on type
  else if (
    type === "email" &&
    !value.match(/^[A-Z0-9+_-]([\w.%+-]*[A-Z0-9+_-]+)*@([\w-]+\.)+([\w]{2,})$/i)
  ) {
    setValidityAndMessage(
      name,
      formErrors,
      false,
      rules && rules[name] && rules[name].typeEmailMessage
        ? rules[name].typeEmailMessage
        : label
        ? label + " must be a valid email format"
        : "Email formatting is invalid"
    );
    errorFound = true;
  }
  //Validation based on regular expression
  else if (rules && rules[name] && rules[name].expression) {
    if (!value.match(rules[name].expression)) {
      setValidityAndMessage(
        name,
        formErrors,
        false,
        rules[name].expressionMessage
          ? rules[name].expressionMessage
          : label
          ? label + " is in invalid format"
          : "Invalid format"
      );
      errorFound = true;
    }
  } else if (rules && rules[name] && rules[name].violatingExpression) {
    if (value.match(rules[name].violatingExpression)) {
      setValidityAndMessage(
        name,
        formErrors,
        false,
        rules[name].expressionMessage
          ? rules[name].expressionMessage
          : label
          ? label + " is in invalid format"
          : "Invalid format"
      );
      errorFound = true;
    }
  }

  if (name2 && !errorFound) {
    if (value2 !== value) {
      setValidityAndMessage(
        name,
        formErrors,
        false,
        rules[name].dontMatch
          ? rules[name].dontMatch
          : label
          ? "values for " + name + " and " + name2 + "Should be identical"
          : "Mismatch"
      );
      setValidityAndMessage(name2, formErrors, false, "");
      errorFound = true;
    } else {
      setValidityAndMessage(name, formErrors, true, "");
      setValidityAndMessage(name2, formErrors, true, "");
    }
  }

  //Min-max length/value validation
  if (!errorFound) {
    //Validation based on min attribute
    if (min && type === "number") {
      if (Number(value) < Number(min)) {
        setValidityAndMessage(
          name,
          formErrors,
          false,
          rules && rules[name] && rules[name].minMessage
            ? rules[name].minMessage
            : label
            ? label + " should be at least " + min
            : "Value should be at least " + min
        );
        errorFound = true;
      }
    } else if (min && value.trim().length !== 0 && value.trim().length < min) {
      setValidityAndMessage(
        name,
        formErrors,
        false,
        rules && rules[name] && rules[name].minMessage
          ? rules[name].minMessage
          : label
          ? label + " should have minimum " + min + " characters"
          : "The minimum number of characters is " + min
      );
      errorFound = true;
    }
    //Validation based on max attribute
    else if (max && type === "number") {
      if (Number(value) > Number(max)) {
        setValidityAndMessage(
          name,
          formErrors,
          false,
          rules && rules[name] && rules[name].maxMessage
            ? rules[name].maxMessage
            : label
            ? label + " should not be greater than " + max
            : "Value should not be greater than " + max
        );
        errorFound = true;
      }
    } else if (max && value.trim().length > max) {
      setValidityAndMessage(
        name,
        formErrors,
        false,
        rules && rules[name] && rules[name].maxMessage
          ? rules[name].maxMessage
          : label
          ? label + " has a character limit of " + max
          : "The maximum number of characters is " + max
      );
      errorFound = true;
    }
  }

  if (!errorFound) {
    setValidityAndMessage(name, formErrors, true, "");
  }

  return formErrors;
};

export const setValidityAndMessage = (name, formErrors, valid, message) => {
  if (formErrors[name]) {
    formErrors[name].valid = valid;
    if (message !== undefined && message !== null) {
      formErrors[name].message = message;
    }
    formErrors.formValid = true;
    for (var index in formErrors) {
      if (formErrors[index].valid === false) {
        formErrors.formValid = false;
        break;
      }
    }
  }
};
