import React from "react";
import { connect } from "react-redux";
import { FormattedText } from "../common/FormattedText";
import { BreadcrumbsContainer } from "styles/components/BreadcrumbsContainer";

const AssignmentVideoLearningModuleBreadcrumb = ({ learningModuleName }) => (
  <span>{learningModuleName}</span>
);

const AssignmentVideoLearningUnitBreadcrumb = ({ lerarningUnitName }) => (
  <span>{lerarningUnitName}</span>
);

export default {
  LearningUnitName: connect((state) => {
    const lerarningUnitName = state.learningLibrary.learningUnit.name;
    return { lerarningUnitName };
  })(AssignmentVideoLearningUnitBreadcrumb),
  LearningModuleName: connect((state) => {
    const learningModuleName = state.learningLibrary.learningModule.name;
    return { learningModuleName };
  })(AssignmentVideoLearningModuleBreadcrumb),
};
