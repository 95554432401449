/**
 * @ External Dependecies
 */
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { media } from "../media";

export const BreadcrumbsInner = styled.div`
  margin-left: 40px;

  ${media.mobile`
		max-width: 100%;
		flex: 0 0 100%;
		margin: 10px 0 0;
		padding-left: 0;
	`}
`;

export const BreadcrumbsHolder = styled.div.attrs({
  className: "breadcrumbs",
})`
  display: flex;
  align-items: center;
  min-height: 47px;
  padding: 0 25px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  z-index: 20;

  ${media.mobile`
		flex-wrap: wrap;
		padding: 10px;
	`}
`;

export const CrumbItem = styled(NavLink)`
  postition: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #747474;
  background: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
  }

  &:after {
    content: ">";
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
    margin: 0 8px 0 7px;
    color: #747474;
  }

  &:last-of-type:after {
    display: none;
  }

  ${(props) =>
    props.disabled &&
    `
		pointer-events: none;
    `}

  ${(props) =>
    props.hidearrow &&
    `
    &:after {
        content: none;
  }`}
`;

export const BreadcrumbsContainer = styled.span.attrs({
  id: "breadCrumbContainer",
})`
  text-transform: none;
`;

export const Formula = styled.span`
  ${(props) =>
    props.adjustFont &&
    `
		margin-left:5px;
	`}

  span {
    ${(props) =>
      props.adjustFont &&
      `
			vertical-align:baseline;
		`}
  }
`;

export default BreadcrumbsHolder;
