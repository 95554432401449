/**
 * The external dependencies.
 */
import React from "react";
import { connect } from "react-redux";

/**
 * Returns the account id
 *
 * @param {Object} / The router match
 * @return {JSX}
 */
const DeploymentBreadcrumb = ({ name }) => <span>{name}</span>;

export default connect((state) => {
  const name = state.facultyDashboard.deployment.deploymentName;

  return { name };
})(DeploymentBreadcrumb);
