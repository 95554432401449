/**
 * The external dependencies.
 */
import React, { Component } from "react";

/**
 * The internal dependencies.
 */
import Grid, { Col } from "styles/components/Grid";
import SectionDashboard, { SectionBody } from "styles/components/SectionDashboard";
import { isEmpty } from "ramda";
import {
  FrameBox,
  FrameCell,
  FrameCaption,
  FrameLabel,
  FrameTitle,
  FrameSpan,
  QuestionAnswer,
  Question,
  Answer,
  AnswerPara,
  AnswerList,
  AnswerListItem,
  Hyperlink,
  StrongText,
} from "styles/components/Frame";
import { StyledUserIcon } from "styles/components/ProfileWidget";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import { getAccountSupportContent } from "store/state/user/actions";
import Loader from "components/common/loader";
import { NoDataMessageContainer, NoDataMessage } from "styles/components/Card";
/**
 * Class for FAQ details.
 *
 * @class      FAQ (name)
 */
class FAQ extends Component {
  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  async componentDidMount() {
    await this.props.getAccountSupportContent();
  }

  render() {
    const {
      faqData: { faq, intro },
      apiCallInprogress,
      account_name,
    } = this.props;
    const { name, email } = intro;
    let arrProperties = Object.keys(intro);
    let arrVal = arrProperties.filter((k) => {
      return !k.includes("label") && k !== "name";
    });
    let chunks = [];
    let accountName = name ? name : account_name;
    while (arrVal.length > 0) {
      chunks.push(arrVal.splice(0, 2));
    }

    return !apiCallInprogress ? (
      faq.length && !isEmpty(intro) ? (
        <DocumentTitle title="Stemify | FAQ">
          <SectionDashboard modified id={"faqContainer"}>
            <SectionBody>
              <Grid gutterSmall colSpacing>
                {intro && (
                  <Col flex>
                    <FrameBox flex>
                      <FrameCell flex alignmiddle percent="45">
                        <StyledUserIcon larger>{accountName[0].toUpperCase()}</StyledUserIcon>
                        <FrameCell sideGap>
                          <FrameCaption nomargin>{accountName}</FrameCaption>
                          {/* <Hyperlink caption email href={`mailto:${email}`}>
                                                        {email}
                                                    </Hyperlink> */}
                        </FrameCell>
                      </FrameCell>
                      {chunks.map((arr) => {
                        return (
                          <FrameCell percent="27.5">
                            {arr.map((v) => (
                              <FrameSpan block padding>
                                <FrameLabel caps>{intro[v + "_label"]} : </FrameLabel>
                                {v.includes("email") ? (
                                  <Hyperlink email href={`mailto:${intro[v]}`}>
                                    {intro[v]}
                                  </Hyperlink>
                                ) : (
                                  <FrameSpan>{intro[v]}</FrameSpan>
                                )}
                              </FrameSpan>
                            ))}
                          </FrameCell>
                        );
                      })}
                    </FrameBox>
                  </Col>
                )}
                <Col flex>
                  <FrameBox>
                    <FrameTitle>Frequently Asked Questions</FrameTitle>
                    {faq.map((item) => {
                      return (
                        <QuestionAnswer>
                          <Question dangerouslySetInnerHTML={{ __html: item.question }}></Question>
                          <Answer dangerouslySetInnerHTML={{ __html: item.answer }}></Answer>
                        </QuestionAnswer>
                      );
                    })}
                  </FrameBox>
                </Col>
              </Grid>
            </SectionBody>
          </SectionDashboard>
        </DocumentTitle>
      ) : (
        <NoDataMessageContainer noIcon>
          <NoDataMessage>No FAQs available at this time.</NoDataMessage>
        </NoDataMessageContainer>
      )
    ) : (
      <Loader />
    );
  }
}

export default connect(
  (state) => ({
    faqData: state.user.faqData,
    apiCallInprogress: state.user.loadingFaqs,
    account_name: state.user.currentAccountName
      ? state.user.currentAccountName
      : state.user.accountName,
  }),
  {
    getAccountSupportContent,
  }
)(FAQ);
// export default FAQ;
