import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import DocumentTitle from "react-document-title";
import styled from "styled-components";
import { media } from "styles/media";
import theme from "styles/theme";
import { isEmpty } from "ramda";
import {
  getPracticeAssignment,
  submitPracticeAssignment,
  clearPracticeAssignment,
} from "store/state/practiceAssignment/actions";

import Wrapper from "styles/components/Wrapper";
import QuestionSummary from "components/practiceAssignment/QuestionSummary";
import ReviewQuestion from "components/practiceAssignment/ReviewQuestion";
import ImprovementTopics from "components/practiceAssignment/ImprovementTopics";

import IconSvgComponent from "components/common/IconSvgComponent";
import {
  SectionContainer,
  SectionContent,
  SectionHead,
  SectionBody,
  SectionOuter,
} from "styles/components/AssessmentTaker";
import { NoDataMessageContainer } from "styles/components/Card";
import { NoDataMessage } from "styles/components/Card";
import Loader from "components/common/loader";
import { ButtonUpload } from "styles/components/QuestionsNavigation";
import stemifyRoutes from "constants/routes";

import DropdownMenu, {
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuBody,
} from "styles/components/DropdownMenu";
import ButtonLink from "components/common/ButtonLink";

import GlobalOverlay from "styles/components/GlobalOverlay";
import { Dropdown, DropdownItem, DropdownButton } from "components/elements/TableDropdown";
import { toggleGlobalOverlay, openModal } from "store/state/ui/actions";
import ModalLearningVideoTransition from "components/modal/ModalLearningVideoTransition";

// TODO: Show loader while fetching results
// TODO: Update question navigator styles

class PracticeAssignmentResults extends Component {
  state = {
    selectedTopic: null,
    selectedQuestion: null,
    isQuestionMaximized: false,
    isAnswerCorrect: false,
    buttonState: "default",
    disableAnswers: false,
    selectedAnswer: null,
    dropdownActive: null,
    shouldShowPracticeAssignmentCompletePopup: false,
    isLastLearningModule: false,
  };

  componentDidMount = async () => {
    const { submitPracticeAssignment } = this.props;
    const { assignmentId } = this.props.match.params;
    submitPracticeAssignment({ assignmentId });
  };

  componentWillUnmount = () => {
    this.props.clearPracticeAssignment();
  };

  isChecked = (value) => {
    return this.state.selectedAnswer === value;
  };

  handleInputChange = (e) => {
    const { selectedQuestion } = this.state;
    const correctAnswer = selectedQuestion.answers.find((item) => item.correct === true);
    const isAnswerCorrect = correctAnswer.id === e.target.value ? true : false;

    this.setState({
      isAnswerCorrect,
      buttonState: "answered",
      selectedAnswer: e.target.value,
    });
  };

  handleCheckButtonClick = () => {
    const { isAnswerCorrect } = this.state;
    const buttonState = isAnswerCorrect ? "correct" : "incorrect";

    this.setState({ buttonState, disableAnswers: false });
  };

  handleTryAgainButtonClick = () => {
    this.setState({
      buttonState: "default",
      disableAnswers: false,
      selectedAnswer: null,
    });
  };

  handleSelectTopic = (selectedTopic) => {
    this.setState({ selectedTopic });
  };

  handleSelectQuestion = (selectedQuestion) => {
    this.setState({
      selectedQuestion,
      isQuestionMaximized: false,
      isAnswerCorrect: false,
      buttonState: "default",
      disableAnswers: false,
      selectedAnswer: null,
    });
  };

  handleToggleQuestionMaximized = () => {
    this.setState((prevState) => ({
      isQuestionMaximized: !prevState.isQuestionMaximized,
    }));
  };

  handleToggleDropdown = () => {
    this.setState(({ dropdownActive }) => ({
      dropdownActive: !dropdownActive,
    }));
    this.props.toggleGlobalOverlay();
  };

  handleRedirectToCourses = () => {
    const courseId = this.props.match.params.courseId;
    this.props.history.push({
      pathname: `${stemifyRoutes.courses}/${courseId}/coursework`,
    });
  };

  handleStartNewLesson = () => {
    this.setState({
      shouldShowPracticeAssignmentCompletePopup: true,
    });
  };

  handleRetakePractie = () => {
    const path = this.props.location.state.referrer;
    this.props.history.push({
      pathname: path,
    });
  };

  closeModal = () => {
    this.setState({
      shouldShowPracticeAssignmentCompletePopup: false,
    });
  };

  redirectToLearingLibrary = () => {
    this.setState(
      {
        shouldShowPracticeAssignmentCompletePopup: false,
      },
      () => {
        const path = `${stemifyRoutes.learningLibrary}`;
        this.props.history.push({
          pathname: path,
        });
      }
    );
  };

  loadNextModule = (id) => {
    this.setState(
      {
        shouldShowPracticeAssignmentCompletePopup: false,
      },
      () => {
        const { match } = this.props;
        const { unitId } = match.params;
        const pathname = this.props.location.pathname;
        let pathToRedirect = "";
        if (pathname.includes(stemifyRoutes.learningLibraryLearningModule)) {
          pathToRedirect = `${stemifyRoutes.learningLibraryLearningModule}/${id}`;
        } else {
          pathToRedirect = `${stemifyRoutes.learningLibrary}/${unitId}/${id}`;
        }
        this.props.history.push({
          pathname: pathToRedirect,
          state: { referrer: pathToRedirect },
        });
      }
    );
  };

  takePracticeAssignment = (id) => {
    this.setState(
      {
        shouldShowPracticeAssignmentCompletePopup: false,
      },
      () => {
        const { match } = this.props;
        const {
          params: { unitId, moduleId },
        } = match;
        const pathname = this.props.location.pathname;
        let pathToRedirect = "";

        if (pathname.includes(stemifyRoutes.learningLibraryLearningModule)) {
          // `${stemifyRoutes.learningLibraryLearningModule}/:moduleId/:assignmentId`
          pathToRedirect = `${stemifyRoutes.learningLibraryLearningModule}/${moduleId}/${id}`;
        } else {
          // `${stemifyRoutes.learningLibrary}/:unitId/:moduleId/:assignmentId`
          pathToRedirect = `${stemifyRoutes.learningLibrary}/${unitId}/${moduleId}/${id}`;
        }
        this.props.history.push({
          pathname: pathToRedirect,
          state: { referrer: pathToRedirect },
        });
      }
    );
  };

  render() {
    const {
      questions,
      isLoading,
      improvementTags,
      learningModule,
      learningModuleList,
      learningUnit,
    } = this.props;

    const { courseId: isRouteFromCourses } = this.props.match.params;

    const { assignmentId } = this.props.match.params;

    const {
      selectedTopic,
      selectedQuestion,
      isQuestionMaximized,
      isAnswerCorrect,
      buttonState,
      disableAnswers,
      selectedAnswer,
      shouldShowPracticeAssignmentCompletePopup,
      isLastLearningModule,
    } = this.state;

    let currentPracticeAssignment = {};
    let arrNextModulesList = [];
    let arrAssignments = [];
    const { assignments } = learningModule;

    if (!isEmpty(learningModule) && assignments) {
      currentPracticeAssignment = assignments && assignments.find((e) => e.id === assignmentId);
      if (currentPracticeAssignment) {
        currentPracticeAssignment["text"] = learningModule.name;
        currentPracticeAssignment.isAssignment = true;
      }

      // Check if there's any further practice assignments
      arrAssignments =
        assignments && assignments.filter((e) => e.is_complete === false && e.id !== assignmentId);

      if (arrAssignments?.length > 0) {
        const assignment = {
          ...arrAssignments[0],
          text: learningModule.name,
        };
        arrNextModulesList.push(assignment);
      }

      const moduleIndex = learningModuleList.findIndex((e) => e.id === learningModule.id);

      if (moduleIndex !== -1) {
        if (arrAssignments.length === 0) {
          // no further practice assignment available. So show next 2 learning modules(if available)
          if (moduleIndex < learningModuleList.length - 2) {
            arrNextModulesList = arrNextModulesList.concat([
              learningModuleList[moduleIndex + 1],
              learningModuleList[moduleIndex + 2],
            ]);
          } else if (moduleIndex < learningModuleList.length - 1) {
            let nextModule = learningModuleList[moduleIndex + 1];
            arrNextModulesList.push(nextModule);
          } else {
            // no more further learning modules available
            !isLastLearningModule && this.setState({ isLastLearningModule: true });
          }
        } else {
          if (moduleIndex < learningModuleList.length - 1) {
            // practice assignments is available, so add next module to be displayed
            let nextModule = learningModuleList[moduleIndex + 1];
            arrNextModulesList.push(nextModule);
          }
        }
      } else {
        !isLastLearningModule && this.setState({ isLastLearningModule: true });
      }
    }

    return !isLoading ? (
      <React.Fragment>
        <ModalLearningVideoTransition
          active={shouldShowPracticeAssignmentCompletePopup}
          subject={currentPracticeAssignment}
          onCloseModal={this.closeModal}
          firstStepTitle={
            questions.every((ques) => ques.isCorrect === true)
              ? "Good job, you’ve finished the practice assignment!"
              : "You’ve not finished the practice assignment!"
          }
          secondStepTitle={
            !!arrAssignments.length
              ? "Complete the lesson by taking a practice assignment."
              : "Coming up..."
          }
          showRewatchButton={false}
          subsequentModelItems={[...arrNextModulesList]}
          loadNextModule={(id) => this.loadNextModule(id)}
          takePracticeAssignment={(id) => this.takePracticeAssignment(id)}
          hasPracticeAssignment={!!arrAssignments.length}
          isLastLearningModule={isLastLearningModule}
          onCompleteLearningMessage={`Good job! You have completed your ${learningUnit.name} lessons.`}
          redirectToLearningLibrary={this.redirectToLearingLibrary}
          hasCompletedPracticeAssignment={questions.every((ques) => ques.isCorrect === true)}
          continueLabel={"Continue"}
        />
        <DocumentTitle title={`Stemify | ${learningModule?.name}`}>
          <Wrapper>
            {
              <DropdownMenu active={this.state.dropdownActive}>
                <DropdownMenuTrigger onClick={this.handleToggleDropdown}>
                  <MediaQuery minWidth={768}>
                    <ButtonUpload isPracticeAssessment>
                      Continue
                      <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
                    </ButtonUpload>
                  </MediaQuery>
                  <DropdownMenuContent small isPracticeAssessmentDropdown>
                    <Dropdown>
                      <DropdownItem>
                        {isRouteFromCourses ? (
                          <DropdownButton onClick={this.handleRedirectToCourses} last>
                            Return to Course
                          </DropdownButton>
                        ) : (
                          <DropdownButton onClick={this.handleStartNewLesson} last>
                            Start Next Lesson
                          </DropdownButton>
                        )}
                      </DropdownItem>
                      <DropdownItem>
                        <DropdownButton onClick={this.handleRetakePractie} last>
                          Retake Practice
                        </DropdownButton>
                      </DropdownItem>
                    </Dropdown>
                  </DropdownMenuContent>
                </DropdownMenuTrigger>
              </DropdownMenu>
            }
            <GlobalOverlay
              onClick={this.handleToggleDropdown}
              visible={this.state.dropdownActive}
            />

            <SectionOuter display="block">
              <SectionContainer style={{ flexDirection: "column", padding: "30px 50px" }}>
                <TopContainer>
                  {improvementTags?.length > 0 && (
                    <ImprovementSection>
                      <ImprovementTopics
                        selectedTopic={selectedTopic}
                        handleTopicSelection={this.handleSelectTopic}
                      />
                    </ImprovementSection>
                  )}
                  <QuestionSection>
                    <QuestionSummary
                      questions={questions}
                      selectedQuestion={selectedQuestion}
                      selectedTopic={selectedTopic}
                      handleSelectQuestion={this.handleSelectQuestion}
                    />
                  </QuestionSection>
                </TopContainer>

                {selectedQuestion ? (
                  <SectionContent noOverflow dontCalcHeight padding="0px 30px">
                    <SectionBody position="initial">
                      <ReviewQuestion
                        selectedQuestion={selectedQuestion}
                        isQuestionMaximized={isQuestionMaximized}
                        handleToggleQuestionMaximized={this.handleToggleQuestionMaximized}
                        isAnswerCorrect={isAnswerCorrect}
                        buttonState={buttonState}
                        disableAnswers={disableAnswers}
                        selectedAnswer={selectedAnswer}
                        isChecked={this.isChecked}
                        handleInputChange={this.handleInputChange}
                        handleCheckButtonClick={this.handleCheckButtonClick}
                      />
                    </SectionBody>
                  </SectionContent>
                ) : (
                  <SectionContent
                    noOverflow
                    dontCalcHeight
                    padding="0px 30px"
                    style={styles.questionReviewSection}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <IconSvgComponent
                        iconPath="svg/review-question-empty.svg"
                        additionalClass="space-above"
                      />
                      <div
                        style={{
                          marginTop: "12px",
                          marginLeft: "5px",
                          fontSize: "18px",
                          fontWeight: "bold",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        Select a question to review.
                      </div>
                    </div>
                  </SectionContent>
                )}
              </SectionContainer>
            </SectionOuter>
          </Wrapper>
        </DocumentTitle>
      </React.Fragment>
    ) : (
      <NoDataMessageContainer noIcon>
        {isLoading == true && <Loader />}
        {isLoading == false && (
          <NoDataMessage>
            No Assessments <br /> available at this time.
          </NoDataMessage>
        )}
      </NoDataMessageContainer>
    );
  }
}

const TopContainer = styled.div`
  display: flex;

  ${media.tablet`
      flex-wrap: wrap;
    `}
`;

const ImprovementSection = styled(SectionContent)`
  background-color: #fff;
  border-radius: ${theme.border_radius};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  margin-right: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  height: unset;
  max-height: unset;

  ${media.tablet`
      margin-right: 0px;
    `}
`;

const QuestionSection = styled(SectionContent)`
  background-color: #fff;
  border-radius: ${theme.border_radius};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 25px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: unset;
  max-height: unset;
`;

const styles = {
  questionReviewSection: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.04)",
    padding: "0px",
    minHeight: "305px",
  },
};

export default connect(
  (state) => {
    const learningModule = state.learningLibrary.learningModule;
    return {
      learningModule,
      questions: state.practiceAssignment.assignmentQuestions,
      currentQuestionId: state.practiceAssignment.currentQuestionId,
      isLoading: state.practiceAssignment.submitPracticeAssignmentLoading,
      improvementTags: state.practiceAssignment.improvementTags,
      learningModuleList: state.learningLibrary.learningUnit.learningModules.filter(
        (e) => e.isComplete === false || e.id === learningModule.id
      ),
      learningUnit: state.learningLibrary.learningUnit,
    };
  },
  (dispatch) => ({
    getPracticeAssignment,
    submitPracticeAssignment,
    clearPracticeAssignment,
    toggleGlobalOverlay: () => dispatch(toggleGlobalOverlay()),
  })
)(PracticeAssignmentResults);
