/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media, mediaMin } from "../media";
import theme from "../theme";

export const MessageContainer = styled.div`
  padding: 22px 30px 30px 35px;
  border-radius: ${theme.border_radius};
  margin-bottom: 30px;
  background: ${theme.color.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

  ${media.mobile`
		padding: 20px 20px;
	`}
`;
export const MessageHead = styled.div`
  margin-bottom: 43px;

  ${media.mobile`
		margin-bottom: 30px;
	`}
`;
export const MessageTitle = styled.h3.attrs({
  id: (props) => props.id,
})`
  font-size: 28px;
  font-weight: 500;
`;
export const MessageBody = styled.div`
  margin-bottom: 31px;
  font-size: 19px;
  font-weight: 500;
  line-height: 1.3;
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  ${mediaMin.tablet`
      max-width: 80%;
  `}
`;
export const MessageText = styled.p`
  margin-bottom: 24px;
`;
export const MessageWarning = styled.p`
  color: ${theme.color.red};
`;
export const MessageActions = styled.div`
  text-align: right;

  .btn {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
