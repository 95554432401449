import React, { Component } from "react";
import { connect } from "react-redux";
import { sortBy } from "ramda";
import { playerScript, playerId } from "constants/video";
import "styles/JWPlayer.css";
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  getSignedURLByToken,
  submitQuizAnswer,
  getQuizData,
  clearCuePoints,
  skipCuePoint,
} from "store/state/video/actions";
import {
  Overlay,
  OverlayClose,
  CardQuizVideo,
  VideoPlayer,
  VideoPlaybackNetworkError,
  FeedBackWrap,
} from "styles/components/Quiz";
import QuizScreen from "components/quiz/Quiz";
import Can from "../common/Can";
import ButtonLink from "../common/ButtonLink";

class Video extends Component {
  state = {
    onFeedback: false,
    feedbackMessage: "",
    fileUrl: null,
    prevTime: 0,
    isCorrect: false,
    fileUrl: null,
    customSeek: false,
    preventSeek: false,
    trackRecentModule: false,
    closeQuizOverlay: false,
    feedbackText: "",
    quizId: null,
    playerWidth: 99,
    videoPlaybackError: false,
    videoPlaybackErrorMessage: "",
    shouldToggleToFullScreen: false,
  };

  _isMounted = false;
  currentPosition = 0;
  isKeyPressedEventFired = false;
  getSignedURL = () => {
    return this.props.getSignedURLByToken({ mediaId: this.props.mediaId });
  };

  getVideoResult = (signedURL) => {
    return fetch(signedURL).then((res) => res.json());
  };

  getQuizData = () => {
    const { videoId } = this.props;
    return this.props.getQuizData({ videoId });
  };

  getVideo = async () => {
    try {
      // 1. Get signed url for video
      const signedUrlResponse = await this.getSignedURL();

      // 2. Use the signed url to fetch the video file
      const videoResponse = await this.getVideoResult(signedUrlResponse.data);

      // 3. Extract video file from response
      const file = videoResponse.playlist[0].sources[0].file;
      const tracks = videoResponse.playlist[0].tracks;
      const captionsTracks = tracks.filter((i) => i.kind == "captions");
      const trackFile = null;
      // if (constCaptionsTracks && constCaptionsTracks.length) {
      //     trackFile = constCaptionsTracks[0].file;
      // }
      // 4. Update state with file url
      if (this._isMounted) {
        this.setState({ fileUrl: file, captionsTracks: captionsTracks });
      }
    } catch (err) {
      console.log(err);
    }
  };

  setCuePointMarkers = (videoDuration) => {
    let cueTimes = sortBy((obj) => obj.cueTime, this.props.cuePoints)
      .map((e) => {
        if (e.isAnswered != true && e.isSkipped !== true) {
          let width = ((e.cueTime / videoDuration) * 100).toFixed(2);
          return `<div id="qmarker_${e.id}" class="jw-cue jw-reset" style="left: ${width}%;"></div>`;
        }
      })
      .join("");
    document
      .getElementsByClassName("jw-slider-container")[0]
      .insertAdjacentHTML("beforeend", cueTimes);
  };

  keyboardEventHandler = (e) => {
    if (e.which === 39 || e.which === 37 || e.which === 32 || e.which === 13) {
      this.isKeyPressedEventFired = true;
    }
  };

  componentDidMount = async () => {
    this._isMounted = true;
    document.addEventListener("keydown", this.keyboardEventHandler, false);

    if (window.innerWidth > 1366) {
      var percentage = 100 - Math.floor(((window.innerWidth - 1366) / 1366) * 100);
      percentage = percentage > 56 ? percentage : 56;
      this.setState({ playerWidth: percentage });
    }
    this.getVideo();
  };

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keydown", this.keyboardEventHandler, false);

    if (this.props.cuePoints.length > 0) {
      this.props.clearCuePoints();
    }
  }

  onAutoStart = () => {
    this.setState({ closeQuizOverlay: false });
    this.player = window.jwplayer(playerId);
    const { seekDuration, moduleId, activityTracker, trackVideoProgress } = this.props;

    if (seekDuration && trackVideoProgress) {
      this.player.seek(seekDuration);
    }

    if (!this.state.trackRecentModule) {
      activityTracker({ type: "LM", id: moduleId });
      this.setState({ trackRecentModule: true });
    }
  };

  onPlay = () => {
    var videoDuration = this.player.getDuration();
    this.setCuePointMarkers(videoDuration);
    this.setState({ closeQuizOverlay: false });
    const { seekDuration, moduleId, activityTracker, trackVideoProgress } = this.props;
    this.player = window.jwplayer(playerId);
    if (seekDuration) {
      if (trackVideoProgress) {
        this.player.seek(seekDuration);
      }
      activityTracker({ type: "LM", id: moduleId });
    }
  };

  onReady = ({ disableSeek }) => {
    // Add the screen size (window/fullScreen) change listener
    this.addFullScreenChangeListener();

    // document.getElementsByClassName("jw-slider-time")[0].remove(); // remove seek bar.
    const { seekDuration, moduleId, activityTracker, autoPlay, trackVideoProgress } = this.props;
    if (disableSeek) {
      var slider = document.getElementsByClassName("jw-slider-time");
      if (slider && slider.length > 0) {
        slider[0].classList.add("disable-seek");
      }
    }

    this.player = window.jwplayer(playerId);
    if (autoPlay && seekDuration != null) {
      if (trackVideoProgress) {
        this.currentPosition = seekDuration;
        this.player.seek(seekDuration);
      }
      activityTracker({ type: "LM", id: moduleId });
    } else if (!autoPlay && seekDuration != null && trackVideoProgress) {
      this.currentPosition = seekDuration;
      this.player.seek(seekDuration);
      this.player.pause();
    }
  };

  onError = ({ message }) => {
    let jwPlayer = document.getElementById(playerId);
    jwPlayer.getElementsByClassName("jw-error-msg jw-info-overlay jw-reset")[0].style.display =
      "none";
    this.setState({ videoPlaybackError: true, videoPlaybackErrorMessage: message });
  };

  handleTryAgain = () => {
    this.props.relaodData();
  };

  getLastMaxCuePoint = (cuePoints, position, offset) => {
    var q = Math.max(
      ...cuePoints.map((p) => {
        return p.cueTime >= offset && p.cueTime <= position ? p.cueTime : -1;
      })
    );

    if (q != -1) {
      return q;
    }
  };

  onTime = ({ position }) => {
    var positionDiff = position - this.currentPosition;

    if (positionDiff > 1) {
      if (this.isKeyPressedEventFired == true) {
        window.jwplayer(playerId).seek(this.currentPosition);
        if (this.state.cuePoint) {
          this.setState({ preventSeek: true });
        }
        this.isKeyPressedEventFired = false;
      } else {
        this.currentPosition = position;
      }
    } else {
      this.currentPosition = position;
    }

    if (this.state.preventSeek) return false;

    const {
      moduleId,
      videoId,
      activityTracker,
      mediaId,
      trackVideoProgress,
      cuePoints,
    } = this.props;

    if (moduleId && videoId) {
      var currentTime = Math.floor(position);
      if (currentTime === 1 && !this.state.trackRecentModule) {
        activityTracker({ type: "LM", id: moduleId });
        this.setState({ trackRecentModule: true });
      }
      if (trackVideoProgress && currentTime % 3 === 0 && this.state.prevTime != currentTime) {
        // made 3 seconds delay between each tracking API call / need to change letter for quiz points
        this.setState({ prevTime: currentTime });
        activityTracker({
          type: "VIDEO",
          id: mediaId,
          seekDuration: Math.floor(position),
        });
      }
    }

    // 1. Check to see if time is passed next unanswered cuePoint
    //    (assumes cuePoints are sorted by cueTime)
    const nextCuePoint = cuePoints.find((p) => !p.isAnswered && p.isSkipped !== true);

    // 2. Trigger cue point if there is a next cue point
    //    and the player time has passed its cue time
    if (nextCuePoint && position >= nextCuePoint.cueTime) {
      this.setState({ closeQuizOverlay: false });
      this.triggerCuePoint(nextCuePoint);
    }
  };

  onSeek = async ({ position, offset }) => {
    const { cuePoints } = this.props;
    if (this.state.closeQuizOverlay == true) {
      return true;
    }

    if (
      offset >= position ||
      (offset < position &&
        this.state.customSeek == false &&
        parseInt(position) - parseInt(offset) != 10 &&
        !(position < 10 && Math.abs(position - offset) < 10))
    ) {
      this.setState(
        () => ({ preventSeek: true, customSeekDuration: position }),
        () => {}
      );
      return false;
    }
    if (this.state.customSeek == true) {
      this.setState({ customSeek: false });
      return false;
    }

    if (position) {
      var qp = this.getLastMaxCuePoint(cuePoints, position, offset);

      if (qp != undefined) {
        this.setState(
          () => ({ customSeek: true, customSeekDuration: qp }),
          () => {}
        );
        return false;
      }
    }
    return true;
  };

  triggerFeedbackCorrect = (answer) => {
    // 1. Trigger the feedback screen
    // 2. Update the feedback screen message
    // 3. Update the feedback screen button text
    // 4. Reset the cuePoint to null to hide the quiz screen
    // 5. Update the question isAnswered prop (correct === true)
    const { cuePoint } = this.state;
    this.setState((prev) => ({
      onFeedback: true,
      isCorrect: true,
      feedbackAnswer: answer.text,
      handleFeedbackButton: this.handleFeedbackButton,
      // cuePoint: null,
      feedbackText: answer.explanation,
    }));
  };

  triggerFeedbackIncorrect = async (cuePoint, answer) => {
    const { activityTracker, mediaId, trackVideoProgress, cuePoints } = this.props;
    // 1. Get the current cuePoint's index in cuePoints
    const currentIndex = cuePoints.findIndex((p) => p.id === cuePoint.id);

    const answerCueTime = answer.cueTime;

    this.setState({
      answer: answer,
      feedbackText: answer.explanation,
      // cuePoint: null,
      isCorrect: false,
      feedbackAnswer: answer.text,
      onFeedback: true,
      currentIndex: currentIndex,
    });
  };

  // Gets called when user clicks on "Continue button on feedback screen"
  handleFeedbackButton = () => {
    // 1. Hide the feedback screen
    // 2. Clear the feedback screen message
    // 3. Then resume the video (with the update time based on answer)
    const { activityTracker, mediaId, trackVideoProgress, cuePoints } = this.props;
    const { cuePoint, answer } = this.state;
    if (this.state.isCorrect) {
      this.player.seek(cuePoint.cueTime);
      const { activityTracker, mediaId, trackVideoProgress } = this.props;
      if (trackVideoProgress) {
        activityTracker({
          type: "VIDEO",
          id: mediaId,
          seekDuration: Math.floor(cuePoint.cueTime),
        });
      }
    } else {
      const currentIndex = cuePoints.findIndex((p) => p.id === cuePoint.id);
      const answerCueTime = answer.cueTime;
      //4. Find the last correct (isAnswered === true) question
      //5. Get the cueTime from the answer submitted
      const lastCorrectCuePoint = (() => {
        if (currentIndex === 0) return null;
        for (let i = currentIndex - 1; i <= 0; i--) {
          // Not sure on adding check for isSkipped here --
          if (cuePoints[i].isAnswered || cuePoints[i].isSkipped === true) return cuePoints[i];
        }
      })();
      // 6. If the answer has a cueTime set the player time to the answer's cueTime
      // 7. If not, then set to last correct question cueTime
      // 8. If not either of those, reset player time back to beginning
      // 9. customseek : true state needed to distingwish seek triggred by code vs seek trigger
      this.setState(
        () => ({ customSeek: true }),
        () => {
          if (answerCueTime) {
            this.player.seek(answerCueTime);
            if (trackVideoProgress) {
              activityTracker({
                type: "VIDEO",
                id: mediaId,
                seekDuration: Math.floor(answerCueTime),
              });
            }
          } else if (lastCorrectCuePoint) {
            if (trackVideoProgress) {
              activityTracker({
                type: "VIDEO",
                id: mediaId,
                seekDuration: Math.floor(lastCorrectCuePoint.cueTime),
              });
            }
            this.player.seek(lastCorrectCuePoint.cueTime);
          } else {
            if (trackVideoProgress) {
              activityTracker({ type: "VIDEO", id: mediaId, seekDuration: 0 });
            }
            this.player.seek(0);
          }
        }
      );
    }
    // 10. Trigger the feedback screen
    // 11. Update the feedback screen message
    // 12. Update the feedback screen button text
    // 13. Reset the cuePoint to null to hide the quiz screen
    this.setState(
      {
        onFeedback: false,
        feedbackMessage: "",
        cuePoint: null,
      },
      () => {
        let videoPosition = parseInt(window.jwplayer(playerId).getPosition());
        let videoDuration = parseInt(window.jwplayer(playerId).getDuration());
        if (this.state.isCorrect) {
          this.player.play();

          if (videoPosition >= videoDuration) {
            this.props.onComplete();
          }
        } else {
          if (this.state.shouldToggleToFullScreen) {
            document.getElementById(playerId).requestFullscreen();
            this.setState({ shouldToggleToFullScreen: false }, () => {
              this.player.play();
            });
          } else {
            this.player.play();
          }
        }
      }
    );
  };

  skipCuePoint = async () => {
    const { activityTracker, mediaId, skipCuePoint, trackVideoProgress, quizId } = this.props;
    const { cuePoint } = this.state;
    await skipCuePoint(cuePoint.id);
    if (document.getElementById("qmarker_" + cuePoint.id)) {
      document.getElementById("qmarker_" + cuePoint.id).remove();
    }
    if (trackVideoProgress) {
      var l = activityTracker({
        type: "VIDEO",
        id: mediaId,
        quizId: quizId,
        cuepoint: { id: cuePoint.id, cueTime: cuePoint.cueTime },
        action: "SKIP_VIDEO_QUIZ",
      });
    }
    this.setState(
      {
        onFeedback: false,
        feedbackMessage: "",
        isCorrect: false,
        cuePoint: null,
      },
      () => {
        if (this.state.shouldToggleToFullScreen) {
          document.getElementById(playerId).requestFullscreen();
          this.setState({ shouldToggleToFullScreen: false }, () => {
            this.player.play();
          });
        } else {
          this.player.play();
        }
      }
    );
  };

  submitQuizAnswer = async (answer) => {
    const { submitQuizAnswer } = this.props;
    const { cuePoint } = this.state;
    const { trackVideoProgress } = this.props;
    const isCorrect = answer.id === cuePoint.correctId;
    // 2. If correct, trigger the correct answer feedback screen
    // 3. If incorrect, trigger the incorrect answer feedback screen

    await submitQuizAnswer({
      question_id: cuePoint.id,
      answer_id: answer.id,
      quiz_id: this.props.quizId,
      isCorrect,
      trackVideoProgress,
    });

    this.setState({ isCorrect: isCorrect });
    if (isCorrect) {
      if (document.getElementById("qmarker_" + cuePoint.id)) {
        document.getElementById("qmarker_" + cuePoint.id).remove();
      }
      this.triggerFeedbackCorrect(answer);
    } else {
      this.triggerFeedbackIncorrect(cuePoint, answer);
    }
  };

  triggerCuePoint = async (cuePoint) => {
    // Pause video
    this.player.pause();
    if (this.isDocumentInFullScreenMode()) {
      if (document.exitFullscreen !== undefined) {
        try {
          await document.exitFullscreen();

          // Provide cuePoint to state
          this.setState({ cuePoint, shouldToggleToFullScreen: true });
        } catch (err) {
          console.error(err);
        }
      } else if (document.webkitExitFullscreen !== undefined) {
        try {
          await document.webkitExitFullscreen();

          // Provide cuePoint to state
          this.setState({ cuePoint, shouldToggleToFullScreen: true });
        } catch (err) {
          console.error(err);
        }
      }
    } else {
      // Provide cuePoint to state
      this.setState({ cuePoint });
    }
  };

  isDocumentInFullScreenMode = () => {
    // document.fullscreenElement will point to the element that
    // is in fullscreen mode if there is one. If not, the value
    // of the property is null.
    if (document.fullscreenElement !== undefined) {
      return document.fullscreenElement !== null;
    } else if (document.webkitFullscreenElement !== undefined) {
      return document.webkitFullscreenElement !== null;
    }
  };

  addFullScreenChangeListener = () => {
    // Add listener to fullscreenchange event
    document.getElementById(playerId).addEventListener("fullscreenchange", this.handleScreenChange);
  };

  handleScreenChange = (event) => {
    if (this.isDocumentInFullScreenMode()) {
      const { cuePoints } = this.props;
      let videoDuration = window.jwplayer(playerId).getPosition();

      // 1. Check to see if time is passed next unanswered cuePoint
      //    (assumes cuePoints are sorted by cueTime)
      const nextCuePoint = cuePoints.find((p) => !p.isAnswered && p.isSkipped !== true);

      // 2. Trigger cue point if there is a next cue point
      //    and the player time has passed its cue time
      if (nextCuePoint && videoDuration >= nextCuePoint.cueTime) {
        this.setState({ closeQuizOverlay: false });
        this.triggerCuePoint(nextCuePoint);
      }
    }
  };

  handleCloseQuizOverlay = () => {
    window.jwplayer(playerId).seek(this.state.cuePoint.cueTime - 0.5);
    this.setState({ closeQuizOverlay: true, preventSeek: false });
  };

  onSeeked = async () => {
    if (this.state.customSeekDuration !== false) {
      const { activityTracker, mediaId, trackVideoProgress } = this.props;
      this.setState({ customSeek: false });
      this.player.seek(this.state.customSeekDuration);
      if (trackVideoProgress) {
        activityTracker({
          type: "VIDEO",
          id: mediaId,
          seekDuration: Math.floor(this.state.customSeekDuration),
        });
      }
      this.setState({ customSeekDuration: false });
      this.setState(() => ({ preventSeek: false }));
    }
  };

  onComplete = (event) => {
    const {
      cuePoints,
      activityTracker,
      moduleActivityTracker,
      mediaId,
      moduleId,
      markModuleComplete,
    } = this.props;

    // 1. Check to see if time is passed next unanswered cuePoint
    //    (assumes cuePoints are sorted by cueTime)
    let position = window.jwplayer(playerId).getPosition();
    const nextCuePoint = cuePoints.find((p) => !p.isAnswered && p.isSkipped !== true);

    // 2. Trigger cue point if there is a next cue point
    //    and the player time has passed its cue time
    // 3. If there's no cue point trigger onComplete to serve next lesson
    if (nextCuePoint && position >= nextCuePoint.cueTime) {
      this.setState({ closeQuizOverlay: false });
      this.triggerCuePoint(nextCuePoint);
    } else {
      this.props.onComplete();
    }

    activityTracker({
      type: "VIDEO",
      id: mediaId,
      seekDuration: Math.floor(this.currentPosition),
      isComplete: true,
    });
    if (markModuleComplete) {
      moduleActivityTracker({
        type: "LM",
        id: moduleId,
        isComplete: true,
      });
    }
  };

  render() {
    const {
      fileUrl,
      cuePoint,
      onFeedback,
      feedbackMessage,
      isCorrect,
      feedbackAnswer,
      feedbackText,
      closeQuizOverlay,
      playerWidth,
      captionsTracks,
      videoPlaybackError,
      videoPlaybackErrorMessage,
    } = this.state;
    const { image, userRole } = this.props;
    const playList = [
      {
        file: fileUrl,
        tracks: captionsTracks,
      },
    ];

    return (
      fileUrl && (
        <CardQuizVideo
          large
          videoquiz
          positionRelative
          isQuizOverlayOn={(cuePoint || onFeedback) && !closeQuizOverlay}
        >
          {cuePoint && !onFeedback && !closeQuizOverlay && (
            <React.Fragment>
              <OverlayClose onClick={this.handleCloseQuizOverlay}>
                <IconSvgComponent iconPath="svg/ico-close-white.svg" />
              </OverlayClose>
              <Overlay>
                <QuizScreen
                  cuePoint={cuePoint}
                  submitQuizAnswer={this.submitQuizAnswer}
                  onFeedback={onFeedback}
                  feedbackMessage={feedbackMessage}
                  isCorrect={isCorrect}
                  skipCuePoint={this.skipCuePoint}
                />
              </Overlay>
            </React.Fragment>
          )}
          {onFeedback && (
            <Overlay>
              <QuizScreen
                onFeedback={onFeedback}
                isCorrect={isCorrect}
                feedbackAnswer={feedbackAnswer}
                handleFeedback={this.handleFeedbackButton}
                feedbackText={feedbackText}
                skipCuePoint={this.skipCuePoint}
              />
            </Overlay>
          )}
          {videoPlaybackError && (
            <Overlay>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FeedBackWrap>
                  <VideoPlaybackNetworkError>{videoPlaybackErrorMessage}</VideoPlaybackNetworkError>
                  <ButtonLink margintop={30} type="submit" violet onClick={this.handleTryAgain}>
                    <span>Try Again</span>
                  </ButtonLink>
                </FeedBackWrap>
              </div>
            </Overlay>
          )}
          <Can
            role={userRole}
            perform={"learning-module-video:seek"}
            yes={() => (
              <VideoPlayer
                width={playerWidth}
                playerScript={playerScript}
                playerId={playerId}
                playlist={playList}
                onReady={() => this.onReady({ disableSeek: false })}
                onPlay={this.onPlay}
                onTime={this.onTime}
                onError={this.onError}
                image={image}
                onAutoStart={this.onAutoStart}
                onOneHundredPercent={this.onComplete}
              />
            )}
            no={() => (
              <VideoPlayer
                width={playerWidth}
                playerScript={playerScript}
                playerId={playerId}
                playlist={playList}
                onReady={() => this.onReady({ disableSeek: true })}
                onPlay={this.onPlay}
                onTime={this.onTime}
                onSeek={this.onSeek}
                onError={this.onError}
                image={image}
                onSeeked={this.onSeeked}
                // isAutoPlay={this.props.autoPlay}
                onAutoStart={this.onAutoStart}
                onOneHundredPercent={this.onComplete}
              />
            )}
          />
        </CardQuizVideo>
      )
    );
  }
}

export default connect(
  (state) => {
    return {
      cuePoints: state.video.cuePoints,
      quizId: state.video.quizId,
      userRole: state.user.role,
    };
  },
  {
    getSignedURLByToken,
    getQuizData,
    submitQuizAnswer,
    clearCuePoints,
    skipCuePoint,
  }
)(Video);
