import styled from "styled-components";
import theme from "../theme";
import { media } from "../media";

export const AssignmentTypesWrapper = styled.div`
  padding: 18vh 0 50px;
  max-width: 1050px;
  margin: 0 auto;
  text-align: center;

  ${media.mobile`
    padding-top: 50px;
  `}
`;

export const AssignmentTypesTitle = styled.h3`
  margin: 0 0 60px;
  font-size: ${theme.font_size.h3};
  font-weight: 700;
  color: ${theme.color.dark_gray};
`;

export const AssignmentTypes = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(163px, 1fr));
  padding: 0 10px;

  ${media.mobile`
    grid-row-start: initial;
    grid-template-columns: repeat( 2, minmax(163px, 1fr) );
  `}
`;

export const AssignmentTypesContent = styled.div`
  grid-column: 1 / -1;
  grid-row-start: 10;
  margin: 0 0 20px;
  background: rgba(150, 160, 178, 0.15);
  padding: 21px 25px;
  font-size: 20px;
  font-weight: 300;
  border-radius: 10px;

  ${media.mobile`
    ${(props) =>
      props.row &&
      `
      grid-row-start: ${props.row};
    `}
  `}
`;

export const Assignment = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding: 21px 16px;
  margin: 0 0 42px;
  transition: box-shadow 0.3s;
  border-radius: 10px;
  position: relative;

  button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &:hover {
    box-shadow: 0 11px 20px 1px rgba(0, 0, 0, 0.15);

    button {
      opacity: 1;
      visibility: visible;
    }

    .color1 {
      fill: ${theme.color.light_blue};
    }

    .color2-stroke {
      stroke: #078cbf;
    }

    .color2 {
      fill: #078cbf;
    }

    .color3 {
      fill: ${theme.color.dark_light_blue};
    }
  }

  .btn-submit:hover {
    background: ${theme.color.red};
  }

  .svg-icon {
    display: flex;
    height: 84px;
    margin: 0 auto 5px;
    align-items: center;
  }
`;
