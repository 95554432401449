/**
 * The external dependencies.
 */
import { injectGlobal } from "styled-components";

injectGlobal`
	.ReactModal__Wizard {
		padding: 0;
		background: #fff;

		.ReactModal__Content {
			max-width: 100%;
			flex: 1 1 100%;
			margin: 0;
		}
	}
`;
