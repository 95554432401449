/**
 * @ External Dependencies
 */
import React, { Component } from "react";
import moment from "moment";

/**
 * The internal dependencies.
 */
import IconSvgComponent from "components/common/IconSvgComponent";
import StatusPill from "components/elements/StatusPill";
import { FrameBox, FrameCell, FrameCaption, FrameLabel, FrameSpan } from "styles/components/Frame";
import { FORMAT } from "constants/common";
/**
 * Class for BasicDeploymentDetails component.
 *
 * @class      BasicDeploymentDetails (name)
 */
class BasicDeploymentDetails extends Component {
  renderDeploymentStatus = (status) => {
    return <StatusPill type="deployment" status={status}></StatusPill>;
  };

  getDateRange = (startDate, endDate) => {
    var dateRange = "";
    if (startDate || endDate) {
      if (startDate) {
        dateRange = dateRange + moment(startDate).format(FORMAT.DATETIME_DEPLOYMENT);
      }
      dateRange = dateRange + "  -  ";
      if (endDate) {
        dateRange = dateRange + moment(endDate).format(FORMAT.DATETIME_DEPLOYMENT);
      }
    }
    return dateRange;
  };

  render() {
    const { displayName, deploymentDetails, iconURL, additionalIconClass } = this.props;

    return deploymentDetails ? (
      <FrameBox flex>
        <FrameCell flex percent="45">
          <IconSvgComponent additionalClass={additionalIconClass} iconPath={iconURL} />
          <FrameCell sideGap>
            <FrameCaption>
              {displayName === "assessment"
                ? deploymentDetails.assessmentName
                : deploymentDetails.deploymentName}
            </FrameCaption>
            {this.renderDeploymentStatus(deploymentDetails.status)}
          </FrameCell>
        </FrameCell>

        <FrameCell percent="55">
          <FrameCell flex percent="100">
            <FrameSpan inlineblock padding percent="50">
              <FrameLabel caps>Program: </FrameLabel>
              <FrameSpan>{deploymentDetails.program}</FrameSpan>
            </FrameSpan>
            <FrameSpan inlineblock padding percent="50">
              <FrameLabel caps>Program Owner: </FrameLabel>
              <FrameSpan>{deploymentDetails.ownerName} </FrameSpan>
              <FrameSpan>
                {deploymentDetails.ownerEmail && `(${deploymentDetails.ownerEmail})`}
              </FrameSpan>
            </FrameSpan>
          </FrameCell>
          <FrameCell flex percent="100">
            <FrameSpan inlineblock padding percent="50">
              <FrameLabel caps>Cohort: </FrameLabel>
              <FrameSpan>{deploymentDetails.cohort}</FrameSpan>
            </FrameSpan>
            <FrameSpan inlineblock padding percent="50">
              <FrameLabel caps>Start &amp; End: </FrameLabel>
              <FrameSpan>
                {this.getDateRange(deploymentDetails.startDate, deploymentDetails.endDate)}
              </FrameSpan>
            </FrameSpan>
          </FrameCell>
        </FrameCell>
      </FrameBox>
    ) : (
      ""
    );
  }
}

export default BasicDeploymentDetails;
