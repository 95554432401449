import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import useAPIData from "components/useAPIData";
import { LoadingSpinner } from "components/card/withSpinnerHOC";
import { Card, styles } from "./styles";
import { BreakdownTable } from "components/card/BreakdownTable";

export default ({
  assignmentId,
  getDataCallback = () => {},
  selector = () => {},
  columns = [],
  mobileColumns = () => {},
}) => {
  const [topicBreakdownDataLoaded, topicBreakdownData] = useAPIData({
    fetchDataCallback: getDataCallback,
    dependencies: [getDataCallback],
  });
  const tableParams = useSelector((state) => state.storedCourses.topicBreakDownTable);

  return (
    <Card style={{ height: "765px", width: "100%" }}>
      <div style={styles.textContainer}>TOPIC BREAKDOWN</div>
      <LoadingSpinner isLoading={!topicBreakdownDataLoaded}>
        <BreakdownTable
          data={topicBreakdownData || []}
          tableParams={tableParams}
          columns={columns}
          mobileColumns={mobileColumns}
        />
      </LoadingSpinner>
    </Card>
  );
};
