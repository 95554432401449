import { handleActions } from "redux-actions";

import {
  requestLearningLibrary,
  rejectLearningLibrary,
  listLearningLibrary,
  requestLearningUnit,
  receiveLearningUnit,
  receiveLearningModule,
  rejectLearningUnit,
  rejectLearningModule,
  requestLearningModule,
  setQuizData,
} from "./actions";
import { receivePracticeAssignment } from "store/state/practiceAssignment/actions";
import { requestLogin, successLogout } from "../user/actions";
import { errorData } from "constants/api";

const operations = {
  list: errorData,
};

const defaultState = {
  program: {},
  learningUnit: { learningModules: [] },
  learningModule: {},
  apiCallInProgress: {
    learningLibrary: null,
    learningUnit: null,
    learningModule: null,
  },
  isFilteredByTopic: false,
  filterTopic: "",
  apiErrors: {
    program: operations,
  },
};

const learningLibrary = handleActions(
  {
    [requestLearningLibrary]: (
      state,
      { payload: { resetData, isFilteredByTopic, filterTopic } }
    ) => ({
      ...state,
      isFilteredByTopic: isFilteredByTopic,
      filterTopic,
      learningModule: {},
      learningUnit: defaultState.learningUnit,
      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningLibrary: true,
        learningUnit: false,
        learningModule: true,
      },
      apiErrors: {
        ...state.apiErrors,
        program: {
          ...state.apiErrors["program"],
          list: errorData,
        },
      },
      program: resetData && resetData == true ? [] : state.program,
    }),
    [listLearningLibrary]: (state, { payload: { items } }) => ({
      ...state,
      program: items,
      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningLibrary: false,
      },
      apiErrors: {
        ...state.apiErrors,
        program: {
          ...state.apiErrors["program"],
          list: errorData,
        },
      },
    }),
    [rejectLearningLibrary]: (state, { payload: { errorItem } }) => ({
      ...state,
      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningLibrary: false,
      },
      apiErrors: {
        ...state.apiErrors,
        program: {
          ...state.apiErrors["program"],
          list: {
            code: 1,
            message: errorItem.message,
          },
        },
      },
    }),
    [requestLearningUnit]: (state, { payload: { resetData } }) => ({
      ...state,
      learningModule: {},
      learningUnit: { learningModules: [] },
      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningUnit: true,
        learningModule: true,
      },
      apiErrors: {
        ...state.apiErrors,
        program: {
          ...state.apiErrors["program"],
          list: errorData,
        },
      },
    }),
    [receiveLearningUnit]: (
      state,
      { payload: { learningUnit, isFilteredByTopic, filterTopic } }
    ) => ({
      ...state,
      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningUnit: false,
        learningLibrary: false,
      },
      learningUnit,
      isFilteredByTopic,
      filterTopic,
      apiErrors: {
        ...state.apiErrors,
        program: {
          ...state.apiErrors["program"],
          list: errorData,
        },
      },
    }),
    [rejectLearningUnit]: (state, { payload: { errorItem } }) => ({
      ...state,
      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningUnit: false,
        learningLibrary: false,
        learningModule: false,
      },
      apiErrors: {
        ...state.apiErrors,
        program: {
          ...state.apiErrors["program"],
          list: {
            code: errorItem.errorCode,
            message: errorItem.message,
          },
        },
      },
    }),
    [requestLearningModule]: (state, { payload: { resetData } }) => ({
      ...state,
      learningModule: {},
      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningModule: true,
      },
      apiErrors: {
        ...state.apiErrors,
        program: {
          ...state.apiErrors["program"],
          list: errorData,
        },
      },
    }),
    [receiveLearningModule]: (state, { payload: { learningModule, unitId, unitName } }) => ({
      ...state,
      learningUnit: { ...state.learningUnit, id: unitId, name: unitName },
      learningModule,

      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningUnit: false,
        learningLibrary: false,
        learningModule: false,
      },
    }),
    [rejectLearningModule]: (state, { payload: { errorItem } }) => ({
      ...state,
      apiCallInProgress: {
        ...state.apiCallInProgress,
        learningUnit: false,
        learningLibrary: false,
        learningModule: false,
      },
      apiErrors: {
        ...state.apiErrors,
        program: {
          ...state.apiErrors["program"],
          list: {
            code: errorItem.errorCode,
            message: errorItem.message,
          },
        },
      },
    }),
    [receivePracticeAssignment]: (state, { payload: { learningModule, learningUnit } }) => ({
      ...state,
      learningModule: { ...state.learningModule, ...learningModule },
      learningUnit: { ...state.learningUnit, ...learningUnit },
    }),
    [successLogout]: () => ({
      ...defaultState,
    }),
  },
  defaultState
);

export default learningLibrary;
