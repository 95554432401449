import React from "react";
import { connect } from "react-redux";
import { FormattedText } from "../common/FormattedText";
import { BreadcrumbsContainer } from "styles/components/BreadcrumbsContainer";
import { getIsNavigationFromTopicFilter } from "utilities/localStore";
import { capitalizeFirstChar } from "utilities/commonFunctions";

const LearningTopicBreadcrump = ({ filteredTopicName }) => {
  const { filterTopic } = getIsNavigationFromTopicFilter();
  return (
    <BreadcrumbsContainer>
      <FormattedText
        text={`${capitalizeFirstChar(filteredTopicName)}`}
        adjustFont="true"
      ></FormattedText>
    </BreadcrumbsContainer>
  );
};

export default connect((state) => {
  const filteredTopicName = state.learningLibrary.filterTopic;
  return { filteredTopicName };
})(LearningTopicBreadcrump);
