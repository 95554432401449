import React, { Component } from "react";
import { connect } from "react-redux";
import { validateField } from "utilities/validation";
import { closeModal } from "store/state/ui/actions";

import { updateDeployment } from "store/state/deployment/actions";
import { resetApiErrors } from "store/state/administration/actions";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import Switch from "components/common/Switch";
import Field from "components/common/Field";
import Loader from "components/common/loader";
import {
  FormDefault as Form,
  FormBody,
  FormSection,
  FormRowGrid,
  FormCol,
  FormControls,
  FormActions,
  StyledFieldset,
  FormFieldStatic,
  CuttoffContainer,
  CuttoffWrapper,
  CuttOffTitle,
  SwitchTitle,
  CuttoffLink,
} from "styles/components/Form";
import FieldErrors from "../common/FieldErrors";

class FormCreateDeploymentAssessment extends Component {
  state = {
    assessments:
      this.props.deployment && this.props.deployment.assessments
        ? this.props.deployment.assessments
        : [],
    accessingAssessments: true,
    formErrors: {
      formValid: true,
    },
    defaultCutoffs: [],
  };

  rules = [];

  handleSwitchChange(key, value, assessmentId) {
    this.props.resetApiErrors();
    this.setState((prevState) => ({ ...prevState, [key]: !prevState[key] }));
    this.setState((prevState) => {
      let cutOffFieldsFormErrors = [];
      prevState.assessments.map(({ id, cutoff_range }) => {
        if (assessmentId == id) {
          cutoff_range.map((c, k) => {
            cutOffFieldsFormErrors[`score_${assessmentId}_${k}`] = {
              valid: true,
              is_required: value,
              message: "",
            };
            cutOffFieldsFormErrors[`coursename_${assessmentId}_${k}`] = {
              valid: true,
              is_required: value,
              message: "",
            };
          });
        }
      });

      return {
        ...prevState,
        assessments: prevState.assessments.map((i) => {
          if (i.id == assessmentId) {
            return { ...i, is_active: value };
          }
          return i;
        }),
        formErrors: {
          ...{
            ...prevState.formErrors,
            ...cutOffFieldsFormErrors,
            [`coolingPeriod_${assessmentId}`]: {
              ...prevState.formErrors[`coolingPeriod_${assessmentId}`],
              is_required: value,
            },
          },
          ...{
            [`retakeNumber_${assessmentId}`]: {
              ...prevState.formErrors[`retakeNumber_${assessmentId}`],
              is_required: value,
            },
          },
          ...{
            [`assessmentTime_${assessmentId}`]: {
              ...prevState.formErrors[`assessmentTime_${assessmentId}`],
              is_required: value,
            },
          },
        },
      };
    });
  }

  handleInputChange(key, name, assessmentId, e) {
    this.props.resetApiErrors();
    const element = e.nativeEvent.target ? e.nativeEvent.target : e.target;
    this.setState({ [key]: element.value });
    this.setState((prevState) => {
      let { assessments } = prevState;
      let newAssessments = [];
      newAssessments = assessments.map((item) => {
        if (item.id == assessmentId) {
          return { ...item, [name]: element.value };
        }
        return item;
      });
      return {
        ...prevState,
        assessments: newAssessments,
      };
    });
  }

  handleCutoffInputChange(key, field, assessmentId, index, e) {
    this.props.resetApiErrors();
    const element = e.nativeEvent.target ? e.nativeEvent.target : e.target;
    let { value } = element;
    if (field != "course") {
      value = parseInt(value);
    }
    this.setState({ [key]: value });
    this.setState((prevState) => {
      let { assessments } = prevState;
      let newAssessments = [];
      newAssessments = assessments.map((item) => {
        if (item.id == assessmentId) {
          var newArray = item.cutoff_range.map((c, k) => {
            if (k == index) {
              return { ...c, [field]: value };
            } else {
              return c;
            }
          });
          return { ...item, cutoff_range: newArray };
        }
        return item;
      });
      return {
        ...prevState,
        assessments: newAssessments,
      };
    });
  }

  handleSearchChange = (key) => (val) => {};

  validateAutoCompleteDropdownField(name) {}

  validateInputField = (id, trim) => {
    this.setState({
      formErrors: validateField(
        this.state.formErrors,
        this.rules,
        document.getElementById(id),
        "",
        trim
      ),
    });
  };

  validateAllFields = () => {
    let fields = [];
    this.state.assessments.map(({ is_active, cutoff_range, name, id }) => {
      if (is_active) {
        this.rules[[`coolingPeriod_${id}`]] = {
          requiredMessage: "Cooling period is a required field.",
        };
        this.rules[[`retakeNumber_${id}`]] = {
          requiredMessage: "Retake number is a required field.",
        };
        this.rules[[`assessmentTime_${id}`]] = {
          requiredMessage: "Assessment time is a required field.",
        };
        fields.push(`coolingPeriod_${id}`);
        fields.push(`retakeNumber_${id}`);
        fields.push(`assessmentTime_${id}`);

        if (cutoff_range) {
          cutoff_range.map((cf, i) => {
            const key = i + 1;
            this.rules[[`coursename_${id}_${i}`]] = {
              requiredMessage: `Coursename for cutoff #${key} for assessment ${name} is required field`,
            };
            this.rules[[`score_${+id}_${i}`]] = {
              requiredMessage: `Score for cutoff #${key} for assessment ${name} is required field`,
            };
            fields.push(`coursename_${id}_${i}`);
            fields.push(`score_${id}_${i}`);
          });
        }
      }
    });
    fields.map((i) => {
      this.validateInputField(i, true);
    });
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.validateAllFields();

    if (this.state.formErrors.formValid) {
      const { updateDeployment, nextStep } = this.props;

      const updatedDeployment = {
        assessments: this.state.assessments,
      };

      var reqParams = {
        ...updatedDeployment,
        is_draft: true,
      };

      const resp = await updateDeployment(reqParams);

      if (resp.status == "success") {
        nextStep();
      }
    }
  };

  handleDraftSave = async (e) => {
    e.preventDefault();
    this.validateAllFields();
    if (this.state.formErrors.formValid) {
      const { updateDeployment, modal } = this.props;
      const updatedDeployment = {
        assessments: this.state.assessments,
      };
      var reqParams = {
        ...updatedDeployment,
        is_draft: true,
      };

      await updateDeployment(reqParams);
    }
  };

  handleResetCutOff = (assessmentId, index, e) => {
    // TO DO Update cuttoff fields and default cutoff
    const { placementAssessments } = this.props;
    try {
      this.setState((prevState) => {
        const targetAssessmentObj = placementAssessments.filter(({ id }) => id == assessmentId);
        let default_cuttoffs = [...targetAssessmentObj[0].default_cuttoff];
        let cutOffFieldsFormErrors = [];
        let cutOffFields = [];
        default_cuttoffs.map((cf, k) => {
          cutOffFieldsFormErrors[`score_${assessmentId}_${k}`] = {
            valid: true,
            is_required: true,
            message: "",
          };
          cutOffFieldsFormErrors[`coursename_${assessmentId}_${k}`] = {
            valid: true,
            is_required: true,
            message: "",
          };
          cutOffFields[`coursename_${assessmentId}_${k}`] = cf.course;
          cutOffFields[`score_${assessmentId}_${k}`] = cf.min_score;
        });
        return {
          ...prevState,
          ...cutOffFields,
          formErrors: { ...prevState.formErrors, ...cutOffFieldsFormErrors },
          assessments: prevState.assessments.map((as) => {
            if (as.id == assessmentId) {
              return { ...as, cutoff_range: default_cuttoffs ? [...default_cuttoffs] : [] };
            }
            return as;
          }),
        };
      });
      e.preventDefault();
    } catch (e) {
      return false;
      e.preventDefault();
    }
  };

  handleDeleteCuttOff = (assessmentId, index, e) => {
    try {
      this.setState((prevState) => {
        let newAssments = [];
        let newCutOffs = [];
        let assessments = [...prevState.assessments];
        let cutOffFieldsFormErrors = [];
        let cutOffFields = [];
        newAssments = assessments.map((item) => {
          if (item.id == assessmentId) {
            newCutOffs = item.cutoff_range.filter((cf, k) => {
              if (k != index) {
                return true;
              }
              return false;
            });
            return { ...item, cutoff_range: [...newCutOffs] };
          }
          return item;
        });
        newCutOffs.map((cf, k) => {
          cutOffFields[`coursename_${assessmentId}_${k}`] = cf.course;
          cutOffFields[`score_${assessmentId}_${k}`] = cf.min_score;
          cutOffFieldsFormErrors[`score_${assessmentId}_${k}`] = {
            valid: true,
            is_required: true,
            message: "",
          };
          cutOffFieldsFormErrors[`coursename_${assessmentId}_${k}`] = {
            valid: true,
            is_required: true,
            message: "",
          };
        });
        return {
          ...prevState,
          ...cutOffFields,
          formErrors: { ...prevState.formErrors, cutOffFieldsFormErrors },
          assessments: newAssments,
        };
      });
      e.preventDefault();
    } catch (e) {
      return false;
      e.preventDefault();
    }
  };

  handleAddCuttOff = (assessmentId, e) => {
    try {
      this.setState((prevState) => {
        let length = 0;
        let assessMents = [...prevState.assessments];
        let newAssessments = assessMents.map((item) => {
          if (item.id == assessmentId) {
            var newArray = [...item.cutoff_range];
            length = newArray.length;
            newArray.push({
              min_score: "",
              course: "",
            });
            return {
              ...item,
              cutoff_range: newArray,
            };
          }
          return item;
        });
        return {
          ...prevState,
          defaultCutoffs: prevState.defaultCutoffs,
          assessments: newAssessments,
          [`coursename_${assessmentId}_${length}`]: "",
          [`score_${assessmentId}_${length}`]: "",
          formErrors: {
            ...prevState.formErrors,
            ...{
              [`coursename_${assessmentId}_${length}`]: {
                valid: true,
                is_required: true,
                message: "",
              },
              [`score_${assessmentId}_${length}`]: {
                valid: true,
                is_required: true,
                message: "",
              },
            },
          },
        };
      });
      e.preventDefault();
    } catch (ex) {
      return false;
      e.preventDefault();
    }
  };

  toggleCoolingPeriod = (key, coolingPeriodField, value, assessmentId) => {
    const { modal, deployment } = this.props;

    this.setState((prevState) => ({ [key]: !prevState[key] }));

    if (!value) {
      // cooling period field switch is OFF
      this.setState((prevState) => {
        let { assessments } = prevState;
        let newAssessments = [];
        newAssessments = assessments.map((item) => {
          if (item.id == assessmentId) {
            return {
              ...item,
              cooling_period: 0,
            };
          }
          return item;
        });

        return {
          ...prevState,
          [coolingPeriodField]: 0,
          assessments: newAssessments,
          formErrors: {
            ...prevState.formErrors,
            [coolingPeriodField]: {
              valid: true,
              is_required: false,
              message: "",
            },
          },
        };
      });
    } else {
      // cooling period field switch is ON
      this.setState((prevState) => {
        let { assessments } = prevState;

        if (modal.data.editDeployment) {
          const plAssessmentMent = deployment.assessments.filter((as) => as.id == assessmentId);
          const savedAssessmentConfig = plAssessmentMent[0] || {};
          const coolingPeriod_ = savedAssessmentConfig.cooling_period;
          let newAssessments = [];
          newAssessments = assessments.map((item) => {
            if (item.id == savedAssessmentConfig.id) {
              return {
                ...item,
                cooling_period: coolingPeriod_,
              };
            }
            return item;
          });

          return {
            ...prevState,
            [coolingPeriodField]: "",
            assessments: newAssessments,
            [`coolingPeriod_${savedAssessmentConfig.id}`]: coolingPeriod_ ? coolingPeriod_ : "",
            formErrors: {
              ...prevState.formErrors,
              [coolingPeriodField]: {
                valid: true,
                is_required: true,
                message: "",
              },
            },
          };
        } else {
          let newAssessments = [];
          newAssessments = assessments.map((item) => {
            if (item.id == assessmentId) {
              return {
                ...item,
                cooling_period: "",
              };
            }
            return item;
          });
          return {
            ...prevState,
            assessments: newAssessments,
            formErrors: {
              ...prevState.formErrors,
              [coolingPeriodField]: {
                valid: true,
                is_required: true,
                message: "",
              },
            },
          };
        }
      });
    }
  };

  toggleRandomDelivery = (key, randomDeliveryField, value, assessmentId) => {
    const { modal, deployment } = this.props;

    this.setState((prevState) => ({ [key]: !prevState[key] }));

    if (!value) {
      // random delivery switch is OFF
      this.setState((prevState) => {
        let { assessments } = prevState;
        let newAssessments = [];

        newAssessments = assessments.map((item) => {
          if (item.id == assessmentId) {
            return {
              ...item,
              deliver_versions_randomly: false,
            };
          }
          return item;
        });

        return {
          ...prevState,
          [randomDeliveryField]: false,
          assessments: newAssessments,
          formErrors: {
            ...prevState.formErrors,
            [randomDeliveryField]: {
              valid: true,
              is_required: false,
              message: "",
            },
          },
        };
      });
    } else {
      // random delivery switch is ON
      this.setState((prevState) => {
        let { assessments } = prevState;

        if (modal.data.editDeployment) {
          const plAssessmentMent = deployment.assessments.filter((as) => as.id == assessmentId);
          const savedAssessmentConfig = plAssessmentMent[0] || {};
          const randomDelivery_ = savedAssessmentConfig.deliver_versions_randomly || true;
          let newAssessments = [];
          newAssessments = assessments.map((item) => {
            if (item.id == assessmentId) {
              return {
                ...item,
                deliver_versions_randomly: true,
              };
            }
            return item;
          });

          return {
            ...prevState,
            [randomDeliveryField]: true,
            assessments: newAssessments,
            [`randomDelivery_${savedAssessmentConfig.id}`]: randomDelivery_,
            formErrors: {
              ...prevState.formErrors,
              [randomDeliveryField]: {
                valid: true,
                is_required: true,
                message: "",
              },
            },
          };
        } else {
          let newAssessments = [];
          newAssessments = assessments.map((item) => {
            if (item.id == assessmentId) {
              return {
                ...item,
                deliver_versions_randomly: false,
              };
            }
            return item;
          });

          return {
            ...prevState,
            assessments: newAssessments,
            formErrors: {
              ...prevState.formErrors,
              [randomDeliveryField]: {
                valid: true,
                is_required: true,
                message: "",
              },
            },
          };
        }
      });
    }
  };

  updateAssessmentFields = () => {
    const { placementAssessments, deployment } = this.props;

    let assessmentsArray = [];
    let defaultCutoffs = [];
    let dynamicState = { formValid: true };
    let fields = {};
    this.setState({ accessingAssessments: true });

    placementAssessments &&
      placementAssessments.map(({ id, name, default_cuttoff }) => {
        const plAssessmentMent = deployment.assessments.filter((as) => as.id == id);
        let assessmentCutOffs = [];
        let isActive_ = false;
        let coolingPeriod_ = "";
        let randomDelivery_ = false;
        let retakeNumber_ = "";
        let assessmentTime_ = "";
        let program_cohort_enrollment_id_ = null;
        let deployment_config_id = null;

        if (plAssessmentMent && plAssessmentMent[0]) {
          // If prop has saved deployment data.
          assessmentCutOffs = [];
          const savedAssessmentConfig = plAssessmentMent[0];

          isActive_ = savedAssessmentConfig.is_active ? savedAssessmentConfig.is_active : false;
          coolingPeriod_ = savedAssessmentConfig.cooling_period
            ? savedAssessmentConfig.cooling_period
            : "";
          randomDelivery_ = savedAssessmentConfig.deliver_versions_randomly
            ? savedAssessmentConfig.deliver_versions_randomly
            : false;

          retakeNumber_ = savedAssessmentConfig.retake_number
            ? savedAssessmentConfig.retake_number
            : "";
          assessmentTime_ = savedAssessmentConfig.assessment_time
            ? savedAssessmentConfig.assessment_time
            : "";
          program_cohort_enrollment_id_ = savedAssessmentConfig.program_cohort_enrollment_id
            ? savedAssessmentConfig.program_cohort_enrollment_id
            : null;
          deployment_config_id = savedAssessmentConfig.deployment_config_id
            ? savedAssessmentConfig.deployment_config_id
            : null;

          fields[`switchcoolingPeriod_${id}`] = !!coolingPeriod_ ? true : false;
          fields[`switchrandomDelivery_${id}`] = !!randomDelivery_;

          if (savedAssessmentConfig.cutoff_range) {
            savedAssessmentConfig.cutoff_range.map((item, j) => {
              dynamicState[`coursename_${id}_${j}`] = {
                valid: true,
                is_required: isActive_,
                message: "",
              };
              dynamicState[`score_${id}_${j}`] = {
                valid: true,
                is_required: isActive_,
                message: "",
              };
              fields[`coursename_${id}_${j}`] = item.course;
              fields[`score_${id}_${j}`] = item.min_score;
              assessmentCutOffs.push({
                min_score: item.min_score,
                course: item.course,
              });
            });
          }
        } else {
          // For create deployment -> first visit to assessment tab
          assessmentCutOffs = default_cuttoff; // For prepopulating cutoffs on asessment tab
          isActive_ = false;
          // isCoolingPeriodActive_ = true;
          coolingPeriod_ = "";
          randomDelivery_ = false;
          retakeNumber_ = "";
          assessmentTime_ = "";

          default_cuttoff.map((df, dfIndex) => {
            dynamicState[`coursename_${id}_${dfIndex}`] = {
              valid: true,
              is_required: isActive_,
              message: "",
            };
            dynamicState[`score_${id}_${dfIndex}`] = {
              valid: true,
              is_required: isActive_,
              message: "",
            };
            fields[`coursename_${id}_${dfIndex}`] = df.course;
            fields[`score_${id}_${dfIndex}`] = df.min_score;
          });
          fields[`switchcoolingPeriod_${id}`] = true;
          fields[`switchrandomDelivery_${id}`] = false;
        }

        fields[`is_active_${id}`] = isActive_;
        fields[`coolingPeriod_${id}`] = coolingPeriod_;
        fields[`randomDelivery_${id}`] = randomDelivery_;
        fields[`retakeNumber_${id}`] = retakeNumber_;
        fields[`assessmentTime_${id}`] = assessmentTime_;

        dynamicState[`coolingPeriod_${id}`] = {
          valid: true,
          is_required: !!coolingPeriod_ ? true : false,
          message: "",
        };
        dynamicState[`randomDelivery_${id}`] = {
          valid: true,
          is_required: !!randomDelivery_,
          message: "",
        };
        dynamicState[`retakeNumber_${id}`] = {
          valid: true,
          is_required: isActive_,
          message: "",
        };
        dynamicState[`assessmentTime_${id}`] = {
          valid: true,
          is_required: isActive_,
          message: "",
        };

        const asObj = {
          id,
          name,
          retake_number: retakeNumber_ == "" ? null : parseInt(retakeNumber_),
          cooling_period: !!coolingPeriod_ ? parseInt(coolingPeriod_) : 0,
          deliver_versions_randomly: !!randomDelivery_,
          assessment_time: assessmentTime_ == "" ? null : parseInt(assessmentTime_),
          is_active: isActive_,
          cutoff_range: assessmentCutOffs,
        };

        if (program_cohort_enrollment_id_) {
          asObj.program_cohort_enrollment_id = program_cohort_enrollment_id_;
        }

        if (deployment_config_id) {
          asObj.deployment_config_id = deployment_config_id;
        }

        assessmentsArray.push(asObj);
        defaultCutoffs[id] = default_cuttoff;
      });
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          ...fields,
          assessments: assessmentsArray,
          formErrors: dynamicState,
          defaultCutoffs: defaultCutoffs || prevState.defaultCutoffs,
        };
      },
      () => {
        this.setState({ accessingAssessments: false });
      }
    );
  };

  componentDidMount() {
    this.props.resetApiErrors();
    this.updateAssessmentFields();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.placementAssessments.length > 0 &&
      Object.keys(this.state.formErrors).length < 2
    ) {
      this.updateAssessmentFields();
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.deployment.assessments !== prevProps.deployment.assessments) {
      this.setState({
        assessments:
          this.props.deployment && this.props.deployment.assessments
            ? this.props.deployment.assessments
            : [],
      });
      this.updateAssessmentFields();
    }
  }

  render() {
    const {
      props: {
        modal,
        placementAssessments,
        prevStep,
        apiCallInProgress,
        deploymentsApiErrors,
        placementApiCallInProgress,
      },
      state: { formErrors, accessingAssessments, assessments },
    } = this;
    const { create: createApiErrors, update: updateApiErrors } = deploymentsApiErrors;

    return placementApiCallInProgress === true ||
      this.state.assessments.length === 0 ||
      apiCallInProgress === true ? (
      <Loader height={100} width={250} />
    ) : (
      !accessingAssessments && placementApiCallInProgress === false && apiCallInProgress === false && (
        <Form fulloNmobile onSubmit={this.handleFormSubmit} colSpacing>
          <FieldErrors
            formErrors={formErrors}
            apiErrorMessage={createApiErrors.message || updateApiErrors.message}
          />
          <FormBody>
            {placementAssessments &&
              placementAssessments.map(({ id, name }) => {
                const is_active_ = `is_active_${id}`;
                const coolingPeriod_ = `coolingPeriod_${id}`;
                const randomDelivery_ = `randomDelivery_${id}`;
                const retakeNumber_ = `retakeNumber_${id}`;
                const assessmentTime_ = `assessmentTime_${id}`;
                const isDisabled = !this.state[is_active_];
                const targetAssessmentObj = assessments.filter((x) => x.id == id);
                const assessmentsCutoffs =
                  targetAssessmentObj && targetAssessmentObj[0]
                    ? targetAssessmentObj[0].cutoff_range
                    : [];

                const coolingPeriodFieldOptionalProps = {
                  isHidden: !this.state[`switch${coolingPeriod_}`],
                };

                if (this.state[`switch${coolingPeriod_}`]) {
                  coolingPeriodFieldOptionalProps.required = formErrors[coolingPeriod_].is_required;
                  coolingPeriodFieldOptionalProps.error = !formErrors[coolingPeriod_].valid;
                  coolingPeriodFieldOptionalProps.min = "1";
                }

                return (
                  <FormSection key={id}>
                    <StyledFieldset>
                      <FormFieldStatic>
                        <SwitchTitle>{name}</SwitchTitle>
                        <Switch
                          dir="right"
                          onChange={(value) => this.handleSwitchChange(is_active_, value, id)}
                          id={is_active_}
                          value={this.state[is_active_]}
                        />
                      </FormFieldStatic>
                    </StyledFieldset>
                    <StyledFieldset hidden={isDisabled}>
                      <FormRowGrid>
                        <FormCol half>
                          <FormControls isCoolingPeriodField={true}>
                            <Field
                              id={coolingPeriod_}
                              type="number"
                              name={coolingPeriod_}
                              suffix="Days"
                              onChange={(e) =>
                                this.handleInputChange(coolingPeriod_, "cooling_period", id, e)
                              }
                              value={this.state[coolingPeriod_]}
                              placeholder="Cooling Period"
                              {...coolingPeriodFieldOptionalProps}
                            />
                            <Switch
                              dir="right"
                              onChange={(value) =>
                                this.toggleCoolingPeriod(
                                  `switch${coolingPeriod_}`,
                                  coolingPeriod_,
                                  value,
                                  id
                                )
                              }
                              id={`switch${coolingPeriod_}`}
                              value={this.state[`switch${coolingPeriod_}`]}
                            />
                          </FormControls>
                        </FormCol>
                        <FormCol half>
                          <FormControls>
                            <Field
                              id={retakeNumber_}
                              type="number"
                              name={retakeNumber_}
                              min="1"
                              onChange={(e) =>
                                this.handleInputChange([retakeNumber_], "retake_number", id, e)
                              }
                              value={this.state[retakeNumber_]}
                              placeholder="Retake Limit"
                              required={formErrors[retakeNumber_].is_required}
                              error={!formErrors[retakeNumber_].valid}
                            />
                          </FormControls>
                        </FormCol>
                        <FormCol half>
                          <FormControls>
                            <Field
                              id={assessmentTime_}
                              name={assessmentTime_}
                              type="number"
                              min="1"
                              onChange={(e) =>
                                this.handleInputChange(assessmentTime_, "assessment_time", id, e)
                              }
                              value={this.state[assessmentTime_]}
                              placeholder="Time Limit"
                              suffix="Minutes"
                              required={formErrors[assessmentTime_].is_required}
                              error={!formErrors[assessmentTime_].valid}
                            />
                          </FormControls>
                        </FormCol>
                        <FormCol half>
                          <FormControls>
                            <div style={{ marginTop: "20px" }}>
                              <label htmlFor={`switch${randomDelivery_}`}>
                                Random Version Delivery
                              </label>
                              <Switch
                                id={`switch${randomDelivery_}`}
                                dir="right"
                                onChange={(value) =>
                                  this.toggleRandomDelivery(
                                    `switch${randomDelivery_}`,
                                    randomDelivery_,
                                    value,
                                    id
                                  )
                                }
                                value={this.state[`switch${randomDelivery_}`]}
                              />
                            </div>
                          </FormControls>
                        </FormCol>
                      </FormRowGrid>

                      <StyledFieldset>
                        <FormRowGrid displayProp="inherit">
                          <FormCol>
                            <CuttOffTitle>{`${name} Cutoff`}</CuttOffTitle>
                            <FormControls dir="right">
                              <CuttoffLink
                                floatdir="right"
                                size="55"
                                to="#"
                                onClick={(e) => {
                                  if (!isDisabled) {
                                    this.handleAddCuttOff(id, e);
                                  }
                                }}
                              >
                                ADD
                              </CuttoffLink>
                              <CuttoffLink
                                floatdir="right"
                                size="55"
                                to="#"
                                onClick={(e) => {
                                  if (!isDisabled) {
                                    this.handleResetCutOff(id, e);
                                  }
                                }}
                              >
                                RESET
                              </CuttoffLink>
                            </FormControls>
                          </FormCol>
                        </FormRowGrid>
                        <CuttoffWrapper>
                          {assessmentsCutoffs &&
                            assessmentsCutoffs.map((item, k) => {
                              const assessment_cuttoff_courseName_ = "coursename_" + id + "_" + k;
                              const assessment_cuttoff_score_ = "score_" + id + "_" + k;
                              return (
                                <CuttoffContainer key={k} floatdir={k % 2 == 0 ? "left" : "right"}>
                                  <Field
                                    fieldWidth={65}
                                    smallLabel
                                    inline
                                    id={assessment_cuttoff_score_}
                                    name={assessment_cuttoff_score_}
                                    type="number"
                                    placeholder="Cutoff Score"
                                    min="0"
                                    onChange={(e) =>
                                      this.handleCutoffInputChange(
                                        assessment_cuttoff_score_,
                                        "min_score",
                                        id,
                                        k,
                                        e
                                      )
                                    }
                                    value={this.state[assessment_cuttoff_score_]}
                                    required={
                                      this.state.formErrors[assessment_cuttoff_score_].is_required
                                    }
                                    error={!this.state.formErrors[assessment_cuttoff_score_].valid}
                                  />
                                  <Field
                                    fieldWidth={97}
                                    id={assessment_cuttoff_courseName_}
                                    name={assessment_cuttoff_courseName_}
                                    smallLabel
                                    inline
                                    type="text"
                                    placeholder="Course Name"
                                    onChange={(e) =>
                                      this.handleCutoffInputChange(
                                        assessment_cuttoff_courseName_,
                                        "course",
                                        id,
                                        k,
                                        e
                                      )
                                    }
                                    value={this.state[assessment_cuttoff_courseName_]}
                                    required={
                                      this.state.formErrors[assessment_cuttoff_courseName_]
                                        .is_required
                                    }
                                    error={
                                      !this.state.formErrors[assessment_cuttoff_courseName_].valid
                                    }
                                  />
                                  <FormCol>
                                    <CuttoffLink
                                      floatdir={"right"}
                                      size="55"
                                      to="#"
                                      remove={"true"}
                                      onClick={(e) => {
                                        if (!isDisabled) {
                                          this.handleDeleteCuttOff(id, k, e);
                                        }
                                      }}
                                    >
                                      {"DELETE"}
                                    </CuttoffLink>
                                  </FormCol>
                                </CuttoffContainer>
                              );
                            })}
                        </CuttoffWrapper>
                      </StyledFieldset>
                    </StyledFieldset>
                  </FormSection>
                );
              })}
          </FormBody>

          <FormActions flex>
            <ButtonLink type="submit" violet hasIcon isPrevious onClick={prevStep}>
              <IconSvgComponent iconPath="svg/ico-arrow-left-large.svg" />
              <span>Previous</span>
            </ButtonLink>
            <ButtonLink
              disabled={apiCallInProgress}
              transparent
              onClick={this.handleDraftSave}
              type="button"
            >
              {modal.data.editDeployment ? "Save Changes" : "Save Draft"}
            </ButtonLink>
            <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
              <span>Next Step</span>
              <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
            </ButtonLink>
          </FormActions>
        </Form>
      )
    );
  }
}

export default connect(
  (state) => {
    const deployment = state.deployment;
    const placementAssessments = state.administration.placementAssessments.filter(
      (a) => a.programId === deployment.program.value
    );

    return {
      placementAssessments,
      cohorts: state.administration.cohorts.map((item) => ({
        title: item.name,
        cohortNumber: item.number,
      })),
      deployment,
      modal: state.ui.modal,
      apiCallInProgress: state.administration.apiCallInProgress,
      deploymentsApiErrors: state.administration.apiErrors.deployments,
      placementApiCallInProgress: state.administration.placementApiCallInProgress,
    };
  },
  {
    updateDeployment,
    resetApiErrors,
    closeModal,
  }
)(FormCreateDeploymentAssessment);
