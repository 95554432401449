import React, { Component } from "react";
import { connect } from "react-redux";

import { coohorts } from "constants/modal";
import { closeModal, openModal } from "store/state/ui/actions";
import { MODAL_ACTIVATION } from "lib/constants";
import { update, remove, removeStudentFromCohort } from "store/state/administration/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Form, {
  FormHead,
  FormLogo,
  FormTitle,
  FormActions,
  StyledGenericError,
} from "styles/components/Form";
import { addNotification } from "store/state/ui/actions";
import { LEVELS, createNotification } from "utilities/notification";

class FormRemoveStudent extends Component {
  handleFormSubmit = (e) => {
    e.preventDefault();
    const { modal, closeModal } = this.props;

    var resp = this.props.removeStudentFromCohort(modal.data);

    resp.then((obj) => {
      closeModal();

      if (obj) {
        this.props.addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            "Success",
            "Removed student successfully!",
            5
          ),
        });
      } else {
        this.props.addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            "Error",
            "Something went wrong while trying to remove student.",
            5
          ),
        });
      }

      if (obj?.deactivate === true) {
        this.props.openModal({
          type: MODAL_ACTIVATION,
          data: {
            id: obj.userHash,
            accountId: obj.accountId,
            status: "active",
            formTitle: coohorts.removeuser,
            type: "users",
          },
        });
      }
    });
  };

  render() {
    const { closeModal, modal, removeStudentApiError, apiCallInProgress } = this.props;

    return (
      <Form onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            <IconSvgComponent iconPath="svg/ico-user-x.svg" />
          </FormLogo>
          {removeStudentApiError &&
            removeStudentApiError.code !== -1 &&
            removeStudentApiError.message && (
              <StyledGenericError>{removeStudentApiError.message}</StyledGenericError>
            )}
          <FormTitle>Are you sure you want to remove {modal.data.name}?</FormTitle>
        </FormHead>

        <FormActions>
          <ButtonLink
            transparent
            onClick={() => closeModal()}
            type="button"
            disabled={apiCallInProgress}
          >
            Cancel
          </ButtonLink>

          <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
            <span>Remove</span>
            <IconSvgComponent iconPath="svg/ico-check-white.svg" />
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    removeStudentApiError: state.administration.apiErrors[state.ui.modal.data.type].remove
      ? state.administration.apiErrors[state.ui.modal.data.type].remove
      : null,
    apiCallInProgress: state.administration.apiCallInProgress,
  }),
  {
    update,
    remove,
    openModal,
    closeModal,
    removeStudentFromCohort,
    addNotification,
  }
)(FormRemoveStudent);
