// ModalLearningVideoTransition

/**
 * The external dependencies.
 */
import React, { useState, useEffect } from "react";

/**
 * The internal dependencies.
 */
import IconSvgComponent from "components/common/IconSvgComponent";

import ButtonLink from "components/common/ButtonLink";

import ModalLearning, {
  ModalBackLink,
  ModalLearningBody,
  ModalLearningContent,
  ModalLearningTitle,
  ModalLearningActions,
  ModalLearningStep,
} from "styles/components/ModalLearning";

import SubjectCard, {
  SubjectCardTitle,
  SubjectCardMedia,
  SubjectCardImage,
  SubjectCardContent,
  SubjectCardRoute,
  SubjectCardEntry,
  SubjectCardBadge,
  SubjectCardProgress,
  SubjectCardProgressIndicator,
} from "styles/components/SubjectCard";
import { element } from "prop-types";
import { prop } from "ramda";
import { IMAGE_S3_SERVER, THUMBNAILS } from "constants/api";
import stemifyRoutes from "constants/routes";

/**
 * @class      ModalActivation (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */
const ModalLearningVideoTransition = (props) => {
  const [activeModal, setActiveModal] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [modalItems, setModalItems] = useState([]);
  const [continueLabel, setContinueLabel] = useState(props.continueLabel);

  useEffect(() => {
    setActiveModal(props.active);
    if (props.subject) {
      setModalItems([props.subject, ...props.subsequentModelItems]);
    }
  }, [props.active, props.subject, props.subsequentModelItems]);

  const closeModal = (e) => {
    e.preventDefault();
    setStepIndex(0);
    setModalItems([]);
    props.onCloseModal();
  };

  const takePracticeAssignment = (e) => {
    e.preventDefault();
    if (props.hasPracticeAssignment) {
      if (modalItems.length >= 2) {
        if (modalItems[1].name === "Practice Problems") {
          props.takePracticeAssignment(modalItems[1].id);
        }
      }
    } else {
      if (props.isLastLearningModule) {
        props.redirectToLearningLibrary();
      } else {
        props.loadNextModule(modalItems[1].id);
      }
    }
    // setStepIndex(0);
    // setModalItems([]);
    // props.takePracticeAssignment();
  };

  return (
    <ModalLearning active={activeModal}>
      <ModalBackLink to={stemifyRoutes.home}>
        <IconSvgComponent iconPath="svg/ico-arrow-left.svg" />
        Home
      </ModalBackLink>

      <ModalLearningBody>
        <ModalLearningStep className={activeModal && stepIndex === 0 ? "step-0" : ""}>
          <ModalLearningTitle>
            {props.isLastLearningModule ? props.onCompleteLearningMessage : props.firstStepTitle}
            {props.hasCompletedPracticeAssignment === undefined ? (
              <IconSvgComponent iconPath="svg/ico-party-emoticon.svg" />
            ) : props.hasCompletedPracticeAssignment === true ? (
              <IconSvgComponent iconPath="svg/ico-party-emoticon.svg" />
            ) : (
              ""
            )}
          </ModalLearningTitle>

          <ModalLearningContent>
            {props.subject && (
              <SubjectCard className="subject-card--primary">
                <SubjectCardRoute to={`/learning-discipline/1`}>
                  <SubjectCardMedia>
                    {props.subject.isAssignment ? (
                      <SubjectCardImage image={"/images/practice-assignment-bg.png"} />
                    ) : (
                      <SubjectCardImage
                        image={`${IMAGE_S3_SERVER}${THUMBNAILS}${props.subject.image}`}
                      />
                    )}
                    {props.subject.progressPercent > 0 && (
                      <SubjectCardProgress>
                        <SubjectCardProgressIndicator value={props.subject.progressPercent} />
                      </SubjectCardProgress>
                    )}
                  </SubjectCardMedia>

                  <SubjectCardContent>
                    <SubjectCardTitle>{props.subject.name}</SubjectCardTitle>

                    {props.subject.completionTimeMins && (
                      <SubjectCardEntry>
                        Average time to complete: {parseInt(props.subject.completionTimeMins)} mins
                      </SubjectCardEntry>
                    )}

                    {props.subject.text && (
                      <SubjectCardEntry>{props.subject.text}</SubjectCardEntry>
                    )}
                  </SubjectCardContent>
                </SubjectCardRoute>
              </SubjectCard>
            )}
          </ModalLearningContent>

          <ModalLearningActions>
            {props.showRewatchButton && (
              <ButtonLink isUnstyledButton onClick={(e) => closeModal(e)}>
                Rewatch Video
              </ButtonLink>
            )}

            <ButtonLink
              type="button"
              violet
              hasIcon
              onClick={() =>
                props.isCourseWork
                  ? props.redirectToCourseWork()
                  : props.isLastLearningModule && !props.hasPracticeAssignment
                  ? props.redirectToLearningLibrary()
                  : setStepIndex(stepIndex + 1)
              }
            >
              <span>{continueLabel}</span>

              <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
            </ButtonLink>
          </ModalLearningActions>
        </ModalLearningStep>

        <ModalLearningStep className={activeModal && stepIndex === 1 ? "step-1" : ""}>
          <ModalLearningTitle>{props.secondStepTitle}</ModalLearningTitle>

          <ModalLearningContent>
            {modalItems.map((item, itemIndex) => (
              <SubjectCard
                className={itemIndex === 1 ? "subject-card--focus" : "subject-card--faded"}
                key={item.id}
              >
                <SubjectCardRoute to={`/learning-discipline/1`}>
                  <SubjectCardMedia>
                    {item.isAssignment ? (
                      <SubjectCardImage image={"/images/practice-assignment-bg.png"} />
                    ) : (
                      <SubjectCardImage image={`${IMAGE_S3_SERVER}${THUMBNAILS}${item.image}`} />
                    )}
                    {item.progressPercent > 0 && (
                      <SubjectCardProgress>
                        <SubjectCardProgressIndicator value={item.progressPercent} />
                      </SubjectCardProgress>
                    )}
                  </SubjectCardMedia>

                  <SubjectCardContent>
                    <SubjectCardTitle>{item.name}</SubjectCardTitle>

                    {item.completionTimeMins && (
                      <SubjectCardEntry>
                        Average time to complete: {parseInt(item.completionTimeMins)} mins
                      </SubjectCardEntry>
                    )}

                    {item.text && <SubjectCardEntry>{item.text}</SubjectCardEntry>}
                  </SubjectCardContent>
                </SubjectCardRoute>
              </SubjectCard>
            ))}
          </ModalLearningContent>

          <ModalLearningActions>
            <ButtonLink type="button" violet hasIcon onClick={(e) => takePracticeAssignment(e)}>
              <span>Continue</span>

              <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
            </ButtonLink>
          </ModalLearningActions>
        </ModalLearningStep>
      </ModalLearningBody>
    </ModalLearning>
  );
};

export default ModalLearningVideoTransition;
