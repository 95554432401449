import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

const CourseBreadcrumb = ({ courseName }) => <span>{courseName}</span>;

export default connect((state, ownProps) => {
  const courseName = state.storedCourses.sections[ownProps.match.params.courseId]?.name;
  return { courseName };
})(CourseBreadcrumb);

export const AssignmentBreadcrumb = connect((state, ownProps) => {
  return {
    assignmentName: state.storedCourses.assignments[ownProps.match.params.assignmentId]?.name
  };
})(({ assignmentName }) => {
  return <span>{assignmentName}</span>
});
