/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import { media } from "../media";

/**
 * The internal dependencies.
 */
import Grid, { Col } from "../components/Grid";

const SectionDashboard = styled.section`
  position: relative;
  width: 100%;
  flex: 1 1 100%;
  padding: 32px 30px 22px;

  .button-add {
    position: absolute;
    right: 38px;
    top: -20px;
  }

  ${media.tablet_portrait`
		padding: 30px 15px;

		.button-add {
			right: 15px;
		}
	`};

  ${(props) =>
    props.modified &&
    `
		padding: 25px 30px 32px;
	`}

  ${media.tablet_portrait`
		${(props) =>
      props.modified &&
      css`
        ${Col} {
          max-width: 100%;
          flex: 1 1 100%;
        }
      `}
	`};
`;

export const SectionBody = styled.div``;

export const SectionGroup = styled.div`
  margin-bottom: 22px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.tablet`
		${(props) =>
      props.withGrid &&
      css`
        ${Grid} {
          display: block;
        }

        ${Col} {
          max-width: 100%;
          flex: 1 1 100%;
          margin-bottom: 22px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      `}
	`};
`;

export default SectionDashboard;
