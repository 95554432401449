import styled from "styled-components";
import theme from "../theme";
import { media } from "../media";

export const CTAbtn = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 20px;

  button {
    padding-left: 20px;
    padding-right: 66px;
  }
`;

export const ToggleDropdown = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  border-left: 2px solid #b11e1f;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
  }
`;

export const BtnDropdown = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;
  top: 100%;
  right: 0;
  width: 220px;
  display: none;
  border-radius: 8px;
  box-shadow: 0 41px 51px rgba(0, 0, 0, 0.27);
  background: #fff;

  &.is-active {
    display: block;
  }

  &:before {
    content: "";
    position: absolute;
    top: -3px;
    right: 14px;
    width: 20px;
    height: 22px;
    border-radius: 2px;
    transform: rotate(45deg);
    background: #fff;
  }

  li {
    list-style: none;
    text-align: left;

    button {
      appearance: none;
      border: none;
      border-radius: 0;
      background: transparent;
      display: block;
      width: 100%;
      padding: 20px 25px;
      font-size: 14px;
      text-decoration: none;
      text-align: left;
    }
  }

  li + li {
    border-top: 1px solid rgba(155, 155, 155, 0.15);
  }

  button:hover {
    color: ${theme.color.red};
  }
`;

export const UploadField = styled.div`
  position: relative;
  display: inline-block;
  padding: 10px 23px;
  background: #96a0b2;
  border-radius: 20px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  span {
    margin: 0 17px 0 0;
  }

  .svg-icon {
    display: inline-block;
    vertical-align: middle;
  }
`;
