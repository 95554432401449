import { programPath, learningUnitPath, contentType, learningModulePath } from "../constants/api";
import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";

export const callGetLearningUnitsOfProgramAPI = async (accountId, programId) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: programPath + "/" + programId + learningUnitPath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const callgetLearningModulesByFilter = async ({ accountId, id, type }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/learningLibrary/${id}?groupBy=${type}`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const callGetLearningModulesOfLearningUnit = async ({ unitId, programId, accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath:
      programPath + "/" + programId + learningUnitPath + "/" + unitId + learningModulePath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const fetchLearningUnitById = async ({ unitId, accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: learningUnitPath + "/" + unitId,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const fetchLearningModuleById = async ({ moduleId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: learningModulePath + "/" + moduleId,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};

export const callGetSingleLearningModule = async ({ unitId, moduleId, programId, accountId }) => {
  const resourcePath = `${programPath}/${programId}${learningUnitPath}/${unitId}${learningModulePath}/${moduleId}`;
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};
