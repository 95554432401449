/**
 * @ External Dependecies
 */
import styled from "styled-components";

import { media } from "../media";

const Shell = styled.section`
  width: 100%;
  max-width: ${(props) => (props.large ? "1250px" : "1070px")};
  margin: 0 auto;
  padding: 0 15px ${media.mobile`
		padding: 0;
	`};
`;

export default Shell;
