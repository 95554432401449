/**
 * The external dependencies.
 */
import styled from "styled-components";

/**
 * The internal dependencies.
 */
import { media } from "styles/media";
import theme from "styles/theme";

const CardInfo = styled.div.attrs({
  id: (props) => props.id,
})`
  position: relative;
  min-height: 197px;
  min-width: 386px;
  padding: 18px 50px 20px 35px;
  background: ${theme.color.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border-radius: ${theme.border_radius};
  color: ${theme.color.darker_gray};

  ${(props) =>
    props.flexSpaceBetween &&
    `
    display: inline-flex !important;
    flex-direction: column;
    justify-content: space-between;
  `}

  ${(props) =>
    props.fullWidth &&
    `
    min-width: inherit;
    width: 100%;
  `}

  ${media.tablet_mid`
    text-align: center;
  `};
`;

CardInfo.Button = styled.div.attrs({
  id: (props) => props.id,
})`
  display: inline-block;
  vertical-align: middle;
  margin-right: 23px;
  padding: 3px 18px;
  background: ${theme.color.red};
  border-radius: 13px;
  font-size: 14px;
  font-weight: 800;
  text-decoration: none;
  color: ${theme.color.white};
  cursor: pointer;
  transition: background 0.2s;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: ${theme.color.red_faded};
  }

  ${(props) =>
    props.text &&
    `
    background: none;
    padding: 0;
    border-radius: 0;
    font-size: 15px;
    color: #2049E6;

    &:hover {
      background: none;
    }
  `}

  ${(props) =>
    props.uppercase &&
    `
    font-weight: 500;
    text-transform: uppercase;
    width: 100px;
    text-align: right;
  `}
`;
CardInfo.Title = styled.h4.attrs({
  id: (props) => props.id,
})`
  margin-bottom: 0;
  paddint-right: 10px;
  font-size: ${theme.font_size.h4};
  font-weight: 500;
  color: ${theme.color.darker_gray};
`;
CardInfo.LargeTitle = styled.div`
  font-size: 33px;
  font-weight: 700;
  line-height: 1;
  color: ${theme.color.darker_gray};

  ${(props) =>
    props.inlineBlock &&
    `
    display: inline-block;
    vertical-align: middle;
  `};
`;
CardInfo.Text = styled.p`
	margin-bottom: 4px;
	font-size: ${theme.font_size.small};
	line-height: 1.5;
	font-weight: 400;

  ${(props) =>
    props.small &&
    `
    font-size: 10px;
  `}

	${(props) =>
    props.paddingX &&
    `
    padding: 0 ${props.paddingX}px;
  `} ${(props) =>
  props.large &&
  `
    font-size: 14px;
  `} ${(props) =>
  props.bold &&
  `
    font-weight: 700;
  `} ${(props) =>
  props.uppercase &&
  `
    text-transform: uppercase;
  `} ${(props) =>
  props.inlineBlock &&
  `
    display: inline-block;
    vertical-align: middle;
  `};
`;
CardInfo.Entry = styled.div`
  flex: 0 0 auto;
`;
CardInfo.Inner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -5px -15px;

  ${media.tablet_mid`
    justify-content: center;
  `};

  ${CardInfo.Entry} {
    padding: 0 5px 15px;
  }
`;
CardInfo.Group = styled.div.attrs({
  id: "cardInfoGroup",
})`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StatusBadge = styled.span`
  padding: 1px 8px;
  background: ${theme.color.gray};
  border: 1px solid ${theme.color.gray};
  border-radius: 6px;
  font-size: 11px;
  line-height: 1.45;
  font-weight: 500;
  color: ${theme.color.white};

  ${(props) =>
    props.absolute &&
    `
    position: absolute;
    top: 24px;
    right: 20px;
  `}

  ${(props) =>
    props.success &&
    `
    background: ${theme.color.success};
    border-color: ${theme.color.success};
  `}
  ${(props) =>
    props.danger &&
    `
    background: ${theme.color.red};
    border-color: ${theme.color.red};
  `} ${(props) =>
  props.warning &&
  `
    background: ${theme.color.white};
    border-color: ${theme.color.orange};
    color: ${theme.color.orange};
  `};

  ${media.tablet_mid`
    ${(props) =>
      props.absolute &&
      `
      position: static;
    `}
  `};
`;

export { CardInfo, StatusBadge };
