/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import AssessmentPasscodeForm from "../assessment/AssessmentPasscodeForm";

/**
 * @class      ModalAssessmentPasscode (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */

function ModalAssessmentPasscode() {
  return (
    <ModalFrame>
      <AssessmentPasscodeForm />
    </ModalFrame>
  );
}

export default ModalAssessmentPasscode;
