import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { media } from "../media";
import theme from "styles/theme";

export const ErrorButtonLink = styled(Link)`
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 5px 16px;
  background: ${theme.color.red};
  border-radius: 16px;
  font-size: 12px;
  font-weight: 480;
  color: #fff;
  text-align: center;
  transition: opacity 0.3s;
  border: 2px solid transparent !important;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    border: 2px solid #2049e6 !important;
  }
`;

export const TextLink = styled.span`
  margin-right: 30px;
  display: inline-block;
  border: none;
  font-size: 12px;
  line-height: 1.25;
  padding: 8px 35px;
  font-weight: 500;
  border-radius: 22px;
  border: 2px solid transparent !important;
  padding: 6px 20px;
  font-size: 13px;
  cursor: pointer;
  ${(props) =>
    props.violet &&
    `
background: #8013E0;
color: #fff;
transition: background .4s;`}
`;

export const ErrorH1 = styled.p`
  color: #4a4a4a;
  font-size: 110px;
  margin-top: 100px;
  margin-bottom: 0px;
  font-weight: 500;
`;

export const ErrorH2 = styled.span`
  color: #3269d6;
  font-size: 110px;
  margin-top: 100px;
  margin-bottom: 0px;
  font-weight: 500;
`;

export const ErrorP = styled.p`
  color: #4a4a4a;
  font-size: 25px;
  margin-bottom: 20px;
`;

export const ErrorBody = styled.div`
  flex: 1 1 100%;
  border-radius: 2px;
  text-align: center;
`;

export const ErrorTable = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;

  ${media.tablet_portrait`
		padding: 30px 15px;
	`};
`;
