/**
 * @ The external dependencies.
 */
import { handleActions } from "redux-actions";

/**
 * @ The internal dependencies.
 */
import {
  openModal,
  closeModal,
  toggleGlobalOverlay,
  addNotification,
  successAddNotification,
  successRemoveNotification,
  clearNotifications,
} from "./actions";

import { requestLogin, successLogout } from "../user/actions";

const defaultState = {
  modal: {
    isOpen: false,
    type: null,
    data: null,
  },
  globalOverlayIsVisible: false,
  notificationsToAdd: [],
  notifications: [],
};

const ui = handleActions(
  {
    [openModal]: (state, { payload }) => ({
      ...state,
      modal: {
        isOpen: true,
        type: payload.type,
        data: payload.data ? payload.data : null,
      },
    }),
    [closeModal]: (state) => ({
      ...state,
      modal: defaultState.modal,
    }),
    [toggleGlobalOverlay]: (state) => ({
      ...state,
      globalOverlayIsVisible: !state.globalOverlayIsVisible,
    }),
    [addNotification]: (state, { payload: { notification } }) => ({
      ...state,
      notificationsToAdd: state.notificationsToAdd.concat([notification]),
    }),
    [successAddNotification]: (state, { payload: { notificationUid } }) => ({
      ...state,
      notificationsToAdd: state.notificationsToAdd.filter(
        (notification) => notification.uid !== notificationUid
      ),
      notifications: state.notifications.concat([notificationUid]),
    }),
    [successRemoveNotification]: (state, { payload: { notificationUid } }) => ({
      ...state,
      notifications: state.notifications.filter((uid) => uid !== notificationUid),
    }),
    [clearNotifications]: (state, action) => {
      return {
        ...state,
        notificationsToAdd: [],
        notifications: [],
      };
    },
    [requestLogin]: () => ({
      ...defaultState,
    }),
    [successLogout]: () => ({
      ...defaultState,
    }),
  },
  defaultState
);

export default ui;
