import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import statesData from "data/states.json";

import { createAccount, updateAccount } from "store/state/administration/actions";
import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Form, {
  FormBody,
  FormActions,
  FormHead,
  FormLogo,
  FormTitle,
  FormRowGrid,
  FormCol,
  FormControls,
} from "styles/components/Form";
import { validateField } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { FORM_CREATE_ACCOUNT_MESSAGES } from "constants/messages";
import { OPERATIONS } from "constants/common";
import { addNotification } from "store/state/ui/actions";
import { LEVELS, createNotification } from "utilities/notification";

class FormCreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      userLimit: "",
      activeUsers: 0,
      status: "active",
      formErrors: {
        organization: {
          valid: true,
          message: "",
        },
        contactName: {
          valid: true,
          message: "",
        },
        contactEmail: {
          valid: true,
          message: "",
        },
        contactPhone: {
          valid: true,
          message: "",
        },
        address1: {
          valid: true,
          message: "",
        },
        address2: {
          valid: true,
          message: "",
        },
        city: {
          valid: true,
          message: "",
        },
        state: {
          valid: true,
          message: "",
        },
        userLimit: {
          valid: true,
          message: "",
        },
        formValid: true,
      },
    };
  }

  rules = {
    organization: {
      requiredMessage: FORM_CREATE_ACCOUNT_MESSAGES.ORGANIZATION_REQUIRED,
      maxMessage: FORM_CREATE_ACCOUNT_MESSAGES.ORGANIZATION_MAX,
    },
    contactName: {
      requiredMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_NAME_REQUIRED,
      violatingExpression: /^[0-9]*$/,
      expressionMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_NAME_INVALID,
      maxMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_NAME_MAX,
    },
    contactEmail: {
      requiredMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_EMAIL_REQUIRED,
      typeEmailMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_EMAIL_INVALID,
      maxMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_EMAIL_MAX,
    },
    contactPhone: {
      requiredMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_PHONE_REQUIRED,
      minMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_PHONE_MIN,
      maxMessage: FORM_CREATE_ACCOUNT_MESSAGES.CONTACT_PHONE_MAX,
    },
    address1: {
      maxMessage: FORM_CREATE_ACCOUNT_MESSAGES.ADDRESS1_MAX,
    },
    address2: {
      maxMessage: FORM_CREATE_ACCOUNT_MESSAGES.ADDRESS2_MAX,
    },
    city: {
      maxMessage: FORM_CREATE_ACCOUNT_MESSAGES.CITY_MAX,
    },
    userLimit: {
      requiredMessage: FORM_CREATE_ACCOUNT_MESSAGES.USER_LIMIT_REQUIRED,
      minMessage: FORM_CREATE_ACCOUNT_MESSAGES.USER_LIMIT_MIN,
    },
  };

  handleInputChange = (e) => {
    const element = e.target;

    this.setState({
      [element.name]: element.value,
    });
  };

  handleSelectChange = (key) => (value) => {
    this.setState({
      [key]: value,
    });
  };

  validateInputField = (id, trim) => {
    this.setState({
      formErrors: validateField(
        this.state.formErrors,
        this.rules,
        document.getElementById(id),
        "",
        trim
      ),
    });
  };

  validateAllFields = () => {
    this.validateInputField("organization", true);
    this.validateInputField("contactName", true);
    this.validateInputField("contactEmail", true);
    this.validateInputField("contactPhone", true);
    this.validateInputField("address1", true);
    this.validateInputField("address2", true);
    this.validateInputField("city", true);
    this.validateInputField("userLimit", true);
  };

  isEdit() {
    let result = this.props.modal.data && this.props.modal.data.editItem;

    if (this.props.edit) {
      result = this.props.edit.data && this.props.edit.data.editItem;
    }

    return result;
  }

  handleSubmit = (e) => {
    this.validateAllFields();
    if (this.state.formErrors.formValid) {
      let apiCall = null;
      e.preventDefault();
      if (this.isEdit()) {
        apiCall = this.props.updateAccount({
          selector: "accounts",
          id: this.props.edit ? this.props.edit.data.id : this.props.modal.data.id,
          updates: {
            ...this.state,
          },
        });
      } else {
        apiCall = this.props.createAccount({
          selector: "accounts",
          item: {
            ...this.state,
          },
        });
      }
      apiCall.then(({ status }) => {
        if (status === "success") {
          this.props.addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              "Success",
              this.isEdit() ? "Updated account successfully!" : "Created account successfully!",
              5
            ),
          });

          if (this.props.onSave) {
            this.props.onSave();
          } else {
            this.props.closeModal();
          }
        }
      });
    }
    e.preventDefault();
  };

  componentDidMount() {
    if (this.isEdit()) {
      const item = this.props.edit ? this.props.edit.data.editItem : this.props.modal.data.editItem;

      this.setState({
        ...item,
      });
    }
  }

  render() {
    const {
      organization,
      contactName,
      contactEmail,
      contactPhone,
      address1,
      address2,
      city,
      state,
      userLimit,
      formErrors,
    } = this.state;

    const { apiCallInProgress } = this.props;

    const states = statesData.map((state) => ({
      label: state.name,
      value: state.code,
    }));

    let operation = this.isEdit() ? OPERATIONS.UPDATE : OPERATIONS.CREATE;

    return (
      <Form className="form" edit={this.props.edit} onSubmit={this.handleSubmit}>
        {!this.props.edit && (
          <React.Fragment>
            {!this.isEdit() ? (
              <FormHead>
                <FormLogo>
                  <IconSvgComponent iconPath="svg/ico-create-acct.svg" />
                </FormLogo>

                <FormTitle>Create Account</FormTitle>
              </FormHead>
            ) : (
              <FormHead>
                <FormLogo>
                  <IconSvgComponent iconPath="svg/ico-edit-acct.svg" />
                </FormLogo>

                <FormTitle>Edit Account</FormTitle>
              </FormHead>
            )}
          </React.Fragment>
        )}

        <MediaQuery maxWidth={1023}>
          <FieldErrors
            formErrors={formErrors}
            apiErrorMessage={this.props.accountsApiErrors[operation].message}
          ></FieldErrors>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <FieldErrors
            formErrors={formErrors}
            apiErrorMessage={this.props.accountsApiErrors[operation].message}
            slider={this.props.edit && "slider"}
          ></FieldErrors>
        </MediaQuery>

        {!this.props.edit ? (
          <FormBody>
            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="organization"
                    name="organization"
                    onChange={this.handleInputChange}
                    value={organization}
                    type="text"
                    placeholder="Organization"
                    required
                    max="255"
                    error={!formErrors.organization.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="contactName"
                    name="contactName"
                    onChange={this.handleInputChange}
                    value={contactName}
                    type="text"
                    placeholder="Contact Name"
                    required
                    max="255"
                    error={!formErrors.contactName.valid}
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>

            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="contactEmail"
                    name="contactEmail"
                    onChange={this.handleInputChange}
                    value={contactEmail}
                    type="email"
                    placeholder="Contact Email"
                    required
                    max="128"
                    error={!formErrors.contactEmail.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="contactPhone"
                    name="contactPhone"
                    onChange={this.handleInputChange}
                    value={contactPhone}
                    placeholder="Contact Phone"
                    required
                    min="10"
                    max="21"
                    type="tel"
                    error={!formErrors.contactPhone.valid}
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>

            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="address1"
                    name="address1"
                    onChange={this.handleInputChange}
                    value={address1}
                    type="text"
                    placeholder="Address 1"
                    max="255"
                    error={!formErrors.address1.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="address2"
                    name="address2"
                    onChange={this.handleInputChange}
                    value={address2}
                    type="text"
                    placeholder="Address 2"
                    max="255"
                    error={!formErrors.address2.valid}
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>

            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="city"
                    name="city"
                    onChange={this.handleInputChange}
                    value={city}
                    type="text"
                    placeholder="City"
                    max="255"
                    error={!formErrors.city.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="state"
                    name="state"
                    isSelect
                    onChange={this.handleSelectChange("state")}
                    options={states}
                    value={state}
                    placeholder="State"
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>

            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="userLimit"
                    name="userLimit"
                    onChange={this.handleInputChange}
                    value={userLimit}
                    type="number"
                    min="1"
                    placeholder="User Limit"
                    error={!formErrors.userLimit.valid}
                    required
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>
          </FormBody>
        ) : (
          <FormBody className="form-body">
            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="organization"
                    name="organization"
                    onChange={this.handleInputChange}
                    value={organization}
                    type="text"
                    placeholder="Organization"
                    required
                    max="255"
                    error={!formErrors.organization.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="contactName"
                    onChange={this.handleInputChange}
                    value={contactName}
                    type="text"
                    name="contactName"
                    placeholder="Contact Name"
                    required
                    max="255"
                    error={!formErrors.contactName.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="contactEmail"
                    name="contactEmail"
                    onChange={this.handleInputChange}
                    value={contactEmail}
                    type="email"
                    placeholder="Contact Email"
                    required
                    max="128"
                    error={!formErrors.contactEmail.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="contactPhone"
                    name="contactPhone"
                    onChange={this.handleInputChange}
                    value={contactPhone}
                    placeholder="Contact Phone"
                    required
                    type="tel"
                    min="10"
                    max="21"
                    error={!formErrors.contactPhone.valid}
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>

            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="address1"
                    name="address1"
                    onChange={this.handleInputChange}
                    value={address1}
                    type="text"
                    placeholder="Address 1"
                    max="255"
                    error={!formErrors.address1.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="address2"
                    name="address2"
                    onChange={this.handleInputChange}
                    value={address2}
                    type="text"
                    placeholder="Address 2"
                    max="255"
                    error={!formErrors.address2.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="city"
                    name="city"
                    onChange={this.handleInputChange}
                    value={city}
                    type="text"
                    placeholder="City"
                    max="255"
                    error={!formErrors.city.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="state"
                    name="state"
                    isSelect
                    onChange={this.handleSelectChange("state")}
                    options={states}
                    value={state}
                    placeholder="State"
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="userLimit"
                    name="userLimit"
                    onChange={this.handleInputChange}
                    value={userLimit}
                    type="number"
                    min="1"
                    placeholder="User Limit"
                    error={!formErrors.userLimit.valid}
                    required
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>
          </FormBody>
        )}

        {this.props.edit ? (
          <FormActions className="form-actions-edit">
            <ButtonLink isStyledAnchor gray onClick={this.props.onCancel}>
              Cancel
            </ButtonLink>

            <ButtonLink isLinkButton type="submit">
              Save Changes
            </ButtonLink>
          </FormActions>
        ) : (
          <FormActions>
            <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
              {this.isEdit() ? <span>Update Account</span> : <span>Create Account</span>}

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          </FormActions>
        )}
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    accountsApiErrors: state.administration.apiErrors.accounts,
    apiCallInProgress: state.administration.apiCallInProgress,
  }),
  {
    createAccount,
    updateAccount,
    closeModal,
    addNotification,
  }
)(FormCreateAccount);
