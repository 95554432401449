/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import Masonry from "react-masonry-component";

/**
 * The internal dependencies.
 */

import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";

import ButtonLink from "components/common/ButtonLink";

import ImageFile from "components/builderAssessment/ImageFile";

import Form from "styles/components/Form";

import {
  updateActiveQuestion,
  setActiveImageFolder,
  toggleActiveImageFile,
  moveFileToFolder,
  deleteFileFromFolder,
  updateFileName,
} from "store/state/builderAssessment/actions";

import {
  ImageLibrary,
  ImageLibraryAside,
  ImageLibraryContent,
  ImageLibrarySearch,
  ImageLibrarySearchField,
  ImageLibraryFolderItem,
  ImageLibraryFiles,
  ImageLibraryFile,
} from "styles/components/ImageManager";
/**
 * Class for form create cohort.
 *
 * @class      FormCreateCohort (name)
 */
class FormImageLibrary extends Component {
  state = {
    searchValue: "",
    selectedFileId: "",
    layoutReady: false,
    selectedFile: null,
    seachPlaceholder: "",
  };

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  setActiveFolder = (id) => {
    this.props.setActiveImageFolder(id);
  };

  /**
   * Handles Form Submit
   *
   */
  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props.updateActiveQuestion({
      questionImageSrc: this.state.selectedFile.fileSrc,
    });

    this.props.toggleActiveImageFile(this.state.selectedFileId);

    this.props.closeModal();
  };

  setActiveImage = (file) => {
    this.props.toggleActiveImageFile(file.id);

    const activeId = this.state.selectedFileId === file.id ? "" : file.id;
    const activeFile = this.state.selectedFileId === file.id ? null : file;

    this.setState({
      selectedFileId: activeId,
      selectedFile: activeFile,
    });
  };

  componentDidMount() {
    const { folders, activeFolderId } = this.props;

    const activeFolder = folders.find((folder) => folder.id === activeFolderId);

    if (activeFolder) {
      const uploadedFile = activeFolder.files.find((file) => file.selected === true);

      this.setState({
        selectedFileId: uploadedFile.id,
        selectedFile: uploadedFile,
      });
    }
  }

  handleLayoutReady() {
    if (!this.state.layoutReady) {
      this.setState({ layoutReady: true });
    }
  }

  onDragStart = (event, fileId) => {
    event.dataTransfer.setData("fileId", fileId);
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event, folderId) => {
    let fileId = event.dataTransfer.getData("fileId");

    this.props.moveFileToFolder({
      fileId,
      destinationFolderId: folderId,
    });

    event.target.classList.remove("is-drag-over");
  };

  onDragEnter = (event) => {
    event.target.classList.add("is-drag-over");
  };

  onDragLeave = (event) => {
    event.target.classList.remove("is-drag-over");
  };

  handleFileDelete = (id) => {
    this.props.deleteFileFromFolder(id);
  };

  handleFileRename = (data, id) => {
    this.props.updateFileName({
      fileId: id,
      fileName: data,
    });
  };

  render() {
    const masonryOptions = {
      transitionDuration: 0,
    };

    const { folders, activeFolderId } = this.props;
    const { searchValue, selectedFileId, seachPlaceholder } = this.state;
    let activeFolder = null;

    if (activeFolderId === "all") {
      activeFolder = {
        id: "all",
        files: folders.flatMap((folder) => folder.files),
      };
    } else {
      activeFolder = folders.find((folder) => folder.id === activeFolderId);
    }

    const filteredFilesByQuery = activeFolder.files.filter((file) =>
      file.name.includes(searchValue)
    );

    return (
      <Form colSpacing fulloNmobile onSubmit={this.handleFormSubmit} className="form-image-library">
        <ImageLibrary>
          <ImageLibraryAside>
            <ImageLibraryFolderItem
              active={activeFolderId === "all"}
              onClick={() => this.setActiveFolder("all")}
            >
              <IconSvgComponent iconPath="svg/ico-grid.svg" />

              <span>All Images</span>
            </ImageLibraryFolderItem>

            {folders.map((folder) => (
              <ImageLibraryFolderItem
                key={folder.id}
                active={folder.id === activeFolderId}
                onClick={() => this.setActiveFolder(folder.id)}
                onDragOver={(event) => this.onDragOver(event)}
                onDrop={(event) => this.onDrop(event, folder.id)}
                onDragEnter={(event) => this.onDragEnter(event)}
                onDragLeave={(event) => this.onDragLeave(event)}
              >
                <IconSvgComponent iconPath="svg/ico-folder-lg.svg" />

                <span>{folder.name}</span>
              </ImageLibraryFolderItem>
            ))}

            <ImageLibraryFolderItem>
              <IconSvgComponent iconPath="svg/ico-plus.svg" additionalClass="icon-plus" />

              <span>New Folder</span>
            </ImageLibraryFolderItem>
          </ImageLibraryAside>

          <ImageLibraryContent>
            <ImageLibrarySearch>
              <IconSvgComponent iconPath="svg/ico-search-dark.svg" />

              <ImageLibrarySearchField
                value={searchValue}
                placeholder={
                  window.innerWidth < 767
                    ? "Search to filter results"
                    : "Search by Image Name to filter results…"
                }
                onChange={this.handleInputChange("searchValue")}
              />
            </ImageLibrarySearch>

            <ImageLibraryFiles
              style={{
                visibility: this.state.layoutReady ? "visible" : "hidden",
              }}
            >
              <Masonry
                className={"files-masonry"}
                elementType={"div"}
                options={masonryOptions}
                onLayoutComplete={() => this.handleLayoutReady()}
              >
                {activeFolder &&
                  filteredFilesByQuery.map((file) => (
                    <ImageFile
                      key={file.id}
                      selected={file.selected}
                      name={file.name}
                      src={file.fileSrc}
                      onClick={() => this.setActiveImage(file)}
                      onDragStart={(event) => this.onDragStart(event, file.id)}
                      onDelete={() => this.handleFileDelete(file.id)}
                      onNameChange={(data) => this.handleFileRename(data, file.id)}
                    />
                  ))}
              </Masonry>
            </ImageLibraryFiles>

            {selectedFileId && (
              <ButtonLink type="submit" violet className="btn-apply">
                <span>Use Image</span>
              </ButtonLink>
            )}
          </ImageLibraryContent>
        </ImageLibrary>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    folders: state.builderAssessment.folders,
    activeFolderId: state.builderAssessment.activeFolderId,
  }),
  {
    closeModal,
    setActiveImageFolder,
    toggleActiveImageFile,
    moveFileToFolder,
    updateActiveQuestion,
    deleteFileFromFolder,
    updateFileName,
  }
)(FormImageLibrary);
