import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FrameBox, FrameCell } from "styles/components/Frame";
import TableDropdown from "components/elements/TableDropdown";
import BarCharts from "components/chart/Bar";
import { AssessmentAttemptsTooltip } from "../chart/ToolTips";
import { Card, styles } from "./styles";

export default ({ getDataHandler, setAttemptsFilter, valueSelector, assignmentId }) => {
  const bucketedAttempts = useSelector((state) => {
    return Object.values(valueSelector(state)).sort((a, b) => (a.attempts >= b.attempts ? -1 : 1));
  });

  useEffect(() => {
    getDataHandler();
  }, [getDataHandler]);

  return (
    <Card style={{ height: "475px", minWidth: "0", flex: "1 1 100%" }}>
      <div style={styles.textContainer}>ASSESSMENT ATTEMPTS</div>
      <BarCharts
        config={{
          options: {
            dimension: {
              width: "100%",
              height: "100%",
            },
            margin: {
              top: 0,
              right: 10,
              left: 0,
              bottom: 5,
            },
          },
        }}
        xAxis={{
          axisLine: true,
          tickLine: false,
          labelText: "NUMBER OF STUDENTS",
          type: "category",
        }}
        yAxis={{
          axisLine: true,
          tickLine: true,
          labelText: "NUMBER OF ATTEMPTS ",
        }}
        grid={true}
        barSize={6}
        maxBarSize={90}
        barRadius={20}
        isGroupBarChart={false}
        isHorizontalChart={true}
        isHorizontalCategoryChart={true}
        dataSet={bucketedAttempts}
        dataKeys={{
          xAxisKey: "attempts",
          yAxisKeys: ["studentCount"],
        }}
        CustomTooltip={<AssessmentAttemptsTooltip />}
        CustomTooltipCursor={{ fill: "#E3EBFA" }}
      />
    </Card>
  );
};
