/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "styles/theme";

const TitleEditable = styled.div`
  margin-bottom: 17px;
  padding-right: 6px;
  display: inline-flex;
  align-items: center;

	${(props) =>
    props.editMode &&
    `
		background: #fff;
	`}

	${(props) =>
    props.isBlock &&
    `
		display: block;
        margin - bottom: 0;
        cursor: pointer;
    `}

	${(props) =>
    props.isBlock &&
    props.editMode &&
    `
		background: transparent;
	`}

  ${media.mobile`
		padding-left: 10px;
		margin-bottom: 10px;
	`}
`;

export const TitleContent = styled.h3`
  display: block;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
  color: #484848;
  padding-right: 15px;
  border: 1px solid #000;
  border: none;
  margin: 0;

  ${(props) =>
    props.isAnswer &&
    `
      font-size: 16px
    `}
`;

export const TitleField = styled.div`
  input {
    font-size: 24px;
    height: 40px;
    line-height: 40px;
    font-weight: 700;
    color: #484848;
    padding-right: 13px;
    background: none;
    border: none;
    width: auto;
    flex-shrink: 0;
  }

  ${(props) =>
    props.isAlt &&
    `
  		input {
  			background: #E3EBFA;
  		}
  	`}

  ${(props) =>
    props.isQuestion &&
    `
    input {
      font-size: 15px;
      background: #E3EBFA;
      padding-left: 5px;
      font-weight: 400;
    }
  `}

  ${(props) =>
    props.isAnswer &&
    `
    input {
      font-size: 16px;
      background: #E3EBFA;
      padding-left: 5px;
    }
  `}
`;

export const ButtonEdit = styled.button`
  border: none;
  background: none;

  &:hover {
    .icon-download rect {
      fill: ${theme.color.darker_gray};
    }
  }

  ${(props) =>
    props.isAlt &&
    `
		display: flex;
		align-items: center;
		font-size: 15px;
		color: #4A4A4A;

		span { margin-right: 10px; }
	`}

  ${(props) =>
    props.isAlt &&
    props.iconBlack &&
    `
      svg g { fill: #000; }
    `}
`;

export const TitleHolder = styled.div`
  display: flex;
  align-items: center;

  svg g {
    fill: #000;
  }

  ${TitleContent} {
    font-size: 15px;
    color: #4a4a4a;
    font-weight: 400;
  }
`;
export default TitleEditable;
