import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import { IMAGE_S3_SERVER, THUMBNAILS } from "constants/api";
import FullscreenModalItem from "components/courses/FullscreenModal";
import IconSvgComponent from "components/common/IconSvgComponent";
import { MODAL_CONFIRMATION } from "lib/constants";
import { openModal, closeModal } from "store/state/ui/actions";

import { FullscreenModalContent } from "styles/components/FullscreenModal";
import SearchContainer, { SearchField, SearchButton } from "styles/components/Search";
import {
  Row,
  Title,
  RowSidebar,
  SidebarTitle,
  RowContent,
  Accordion,
  AccordionInfoTitle,
  AccordionInfoList,
  AccordionInfoItem,
  PanelList,
  PanelListItem,
  Tab,
  Videos,
  Video,
  VideoInner,
  VideoContent,
  VideoImage,
  Expander,
} from "styles/components/ModalCoursesAddToAssignment";
import { CheckboxWrapper, Checkbox, CheckboxLabel } from "styles/components/Form";
import { setAssignmentAssets, getVideos } from "store/state/courses/actions";
import { FormattedText } from "../common/FormattedText";

//TODO: Component and file to be renamed to ModalCourseAddVideos after demo
class ModalCourseAssignmentPractice extends Component {
  state = {
    filtered_videos: this.props.videos,
    video_search: "",
    currentConcept: "",
    active_tab_link: 0,
    selected_videos: [],
    selected_assessments: [],
    accordion_active_objective: false,
    accordion_assignment: false,
    pageSize: 5,
    page: 0,
    pages: null,
    count: 0,
  };

  componentDidMount() {
    const { getVideos } = this.props;
    getVideos();
    this.setState({
      filtered_videos: this.props.videos,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.videos !== this.props.videos) {
      this.setState({
        filtered_videos: this.props.videos,
      });
    }
  }

  /**
   * Handle concept filter
   * @param {object} event
   */
  handleConceptSearch = (currentConcept) => {
    const { videos } = this.props;
    if (currentConcept === this.state.currentConcept) {
      this.setState({
        currentConcept: "",
        filtered_videos: videos,
      });
    } else {
      this.setState({
        currentConcept,
        filtered_videos: videos.filter((item) =>
          item.concepts.map((c) => c.name).includes(currentConcept)
        ),
      });
    }
  };

  /**
   * Handle filtration
   * @param {object} event
   */
  handleSearchChange = (event) => {
    const { videos } = this.props;
    const currentValue = event.target.value;
    const filteredVideos = videos.filter((item) =>
      item.name.toLowerCase().includes(currentValue.toLowerCase())
    );
    this.setState({
      video_search: currentValue,
      filtered_videos: filteredVideos,
    });
  };

  toggleObjectivesAccordian(event, vIndex) {
    if (this.state.accordion_active_objective === vIndex) {
      this.setState({
        accordion_active_objective: -1,
      });
    } else {
      this.setState({
        accordion_active_objective: vIndex,
      });
    }
    event.stopPropagation();
  }

  saveAssets = (videoId) => {
    const { videos } = this.props;
    this.props.setAssignmentAssets(videos.find((video) => video.id === videoId));
    this.props.onClose();
  };

  /**
   * Render component
   */
  render() {
    const { currentConcept } = this.state;
    const { concepts } = this.props;
    return (
      <FullscreenModalItem active={this.props.active} onClose={() => this.props.onClose()}>
        <FullscreenModalContent max_width="100%">
          <Row>
            <Title>Select Video for Assignment</Title>
            {/* Temporarily commented for demo */}
            {/* <RowSidebar>
              <SidebarTitle>Concept Filter</SidebarTitle>
              <PanelList>
                {concepts.map((concept) => {
                  return (
                    <PanelListItem
                      key={"concept_" + concept.id}
                      onClick={() => this.handleConceptSearch(concept.name)}
                      selected={concept.name === currentConcept}
                    >
                      {concept.name}
                    </PanelListItem>
                  );
                })}
              </PanelList>
            </RowSidebar> */}

            <RowContent>
              <div className="tabs__content">
                <Tab className={classNames({ "is-active": true })}>
                  <SearchContainer>
                    <SearchField
                      placeholder="Search by video or concept name…"
                      onChange={this.handleSearchChange}
                      value={this.state.video_search}
                    />
                    <SearchButton>
                      <IconSvgComponent iconPath="svg/ico-magnifier.svg" />
                    </SearchButton>
                  </SearchContainer>

                  <Videos>
                    {this.state.filtered_videos?.map((video, vIndex) => (
                      <Video
                        key={video.id}
                        className={classNames({
                          "is-active": this.state.selected_videos.includes(video.id),
                        })}
                        onClick={() => this.saveAssets(video.id)}
                      >
                        <VideoInner>
                          <VideoImage
                            style={{
                              backgroundImage: `url(${IMAGE_S3_SERVER +
                                THUMBNAILS +
                                video.video_thumbnail})`,
                            }}
                          ></VideoImage>

                          <VideoContent>
                            <p>
                              <strong>{video.name}</strong>
                            </p>
                          </VideoContent>
                          {/* Temporarily commented for demo */}
                          {/* <Accordion
                              className={classNames({
                                "is-hidden": !(this.state.accordion_active_objective === vIndex),
                              })}
                            >
                              <AccordionInfoTitle
                                onClick={(event) => this.toggleObjectivesAccordian(event, vIndex)}
                              >
                                Learning Objectives <span></span>
                              </AccordionInfoTitle>
                              <AccordionInfoList>
                                {video.learningObjectives.map((objective) => (
                                  <AccordionInfoItem>
                                    <IconSvgComponent
                                      iconPath="svg/ico-dblue-check.svg"
                                      additionalClass="learning-objectives-icon-small"
                                    />
                                    <FormattedText text={objective}></FormattedText>
                                  </AccordionInfoItem>
                                ))}
                              </AccordionInfoList>
                            </Accordion> */}
                        </VideoInner>
                      </Video>
                    ))}
                  </Videos>
                </Tab>
              </div>
            </RowContent>
          </Row>
        </FullscreenModalContent>
      </FullscreenModalItem>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    videos: Object.values(state.storedCourses.videos),
    concepts: Object.values(state.storedCourses.concepts),
  }),
  {
    openModal,
    closeModal,
    setAssignmentAssets,
    getVideos,
  }
)(ModalCourseAssignmentPractice);
