import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";
let colormap = require("colormap");

export default class LineGraph extends PureComponent {
  constructor(props) {
    super(props);
  }

  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {};

  render() {
    const {
      props: {
        config: {
          options: {
            dimension: { width, height },
            margin: { top, right, left, bottom },
          },
        },
        xAxis,
        yAxis,
        type,
        strokeColor,
        dataSet,
        dataKeys: { xAxisKey, yAxisKeys },
      },
    } = this;

    return (
      <ResponsiveContainer width={width} height={height} debounce={10}>
        <LineChart
          data={dataSet}
          margin={{
            top,
            right,
            left,
            bottom,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisKey} axisLine={xAxis.axisLine} tickLine={xAxis.tickLine} />
          <YAxis axisLine={yAxis.axisLine} tickLine={yAxis.tickLine} />
          <Tooltip />
          <Line type={type} dataKey={yAxisKeys} stroke={strokeColor} />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

LineGraph.propTypes = {
  config: PropTypes.shape({
    options: PropTypes.shape({
      dimension: PropTypes.exact({
        width: PropTypes.string,
        height: PropTypes.string,
      }).isRequired,
      margin: PropTypes.exact({
        top: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  xAxis: PropTypes.shape({
    axisLine: PropTypes.bool,
    tickLine: PropTypes.bool,
  }),
  yAxis: PropTypes.shape({
    axisLine: PropTypes.bool,
    tickLine: PropTypes.bool,
  }),
  type: PropTypes.string,
  strokeColor: PropTypes.string,
  dataSet: PropTypes.arrayOf(PropTypes.object),
  dataKeys: PropTypes.exact({
    xAxisKey: PropTypes.string,
    yAxisKeys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

LineGraph.defaultProps = {
  xAxis: {
    axisLine: true,
    tickLine: true,
  },
  yAxis: {
    axisLine: true,
    tickLine: true,
  },
  type: "linear",
  strokeColor: "#8884d8",
};
