/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */

import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import ColorPickerBlock from "components/courses/ColorPickerBlock";

import Form, {
  FormHead,
  FormLogo,
  FormBody,
  FormTitle,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
} from "styles/components/Form";

import { createSection, updateSection } from "store/state/courses/actions/index";
import { getAllDisciplines } from "store/state/courses/actions";
import { validateField, validateSelectField } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { FORM_CREATE_COURSE_MESSAGES } from "constants/messages";
import { OPERATIONS } from "constants/common";

/**
 * Class for form create cohort.
 *
 * @class      FormCreateCourse (name)
 */
class FormCreateCourse extends Component {
  state = {
    formValues: {
      id: "",
      name: "",
      courseNumber: "",
      sectionNumber: "",
      discipline: "",
      room: "",
      year: "",
      term: "",
      background: "",
      schedule: "",
      isActive: this.props.isActive || true,
    },
    showColorPicker: false,
    formErrors: {
      name: {
        valid: true,
        message: "",
      },
      courseNumber: {
        valid: true,
        message: "",
      },
      discipline: {
        valid: true,
        message: "",
      },
      sectionNumber: {
        valid: true,
        message: "",
      },
      room: {
        valid: true,
        message: "",
      },
      term: {
        valid: true,
        message: "",
      },
      year: {
        valid: true,
        message: "",
      },
      schedule: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };

  rules = {
    name: {
      requiredMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_NAME_REQUIRED,
      maxMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_NAME_MAX,
    },
    courseNumber: {
      requiredMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_NUMBER_REQUIRED,
      maxMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_NUMBER_MAX,
    },
    discipline: {
      requiredMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_DISCIPLINE_REQUIRED,
    },
    sectionNumber: {
      requiredMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_SECTION_REQUIRED,
      maxMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_SECTION_MAX,
    },
    room: {
      requiredMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_ROOM_REQUIRED,
      maxMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_ROOM_MAX,
    },
    term: {
      maxMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_TERM_MAX,
    },
    background: {
      requiredMessage: FORM_CREATE_COURSE_MESSAGES.COURSE_BACKGROUND_REQUIRED,
    },
  };

  handleInputChange = (key) => (e) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [key]: e.target.value,
      },
    });
  };

  isEdit() {
    return this.props.modal.data && this.props.modal.data.editItem;
  }

  handleStaticChange = (key) => (value) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [key]: value,
      },
    });
  };

  /*Form Validation*/

  validateAllFields = () => {
    this.validateTextField(document.getElementById("name"), true);
    this.validateTextField(document.getElementById("courseNumber"), true);
    this.validateTextField(document.getElementById("sectionNumber"), true);
    this.validateTextField(document.getElementById("course-room"), true);
    this.validateTextField(document.getElementById("course-term"), true);
    this.validateTextField(document.getElementById("background"), true);
    this.validateDropDownField({
      name: "discipline",
      value: this.state.formValues.discipline,
      required: true,
    });
  };

  validateTextField = (element, trimValues) => {
    this.setState({
      formErrors: validateField(this.state.formErrors, this.rules, element, "", trimValues),
    });
  };

  validateDropDownField = (properties) => {
    this.setState({
      formErrors: validateSelectField(this.state.formErrors, this.rules, properties),
    });
  };

  /**
   * Handles Form Submit
   *
   */
  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.validateAllFields();
    if (this.state.formErrors.formValid) {
      const { createSection, updateSection } = this.props;
      const response = this.isEdit()
        ? await updateSection({ ...this.state.formValues })
        : await createSection({
            ...this.state.formValues,
            background: this.state.background || "#2049E6",
          });
      response !== undefined && response.status === "success" && this.props.closeModal();
    }
  };

  handlePickerOpen = (e) => {
    e.preventDefault();
    this.setState({
      showColorPicker: true,
    });
  };

  handlePickerClose = () => {
    this.setState({
      showColorPicker: false,
    });
  };

  handleChangeComplete = (color, event) => {
    this.setState({
      background: color.hex,
    });
  };

  componentDidMount() {
    const { getAllDisciplines } = this.props;
    // getAllDisciplines();

    if (this.isEdit()) {
      this.setState({
        formValues: {
          ...this.props.modal.data.editItem,
        },
      });
    }
  }

  render() {
    const { showColorPicker, formValues, formErrors } = this.state;
    const {
      name,
      courseNumber,
      sectionNumber,
      discipline,
      room,
      term,
      background,
      year,
      schedule,
    } = formValues;
    const { apiCallInProgress, disciplines } = this.props;
    // For demo purpose added the hardcoded list .
    const disciplineList = [
      {
        value: "1",
        label: "Mathematics",
      },
      {
        value: "2",
        label: "Physics",
      },
      {
        value: "3",
        label: "Chemistry",
      },
      {
        value: "4",
        label: "Science",
      },
      {
        value: "5",
        label: "Biology",
      },
    ];
    let operation = this.isEdit() ? OPERATIONS.UPDATE : OPERATIONS.CREATE;

    return (
      <Form hasLargeFields fulloNmobile tabletSize1 onSubmit={this.handleFormSubmit} noValidate>
        <FormHead bottomOffset="20">
          <FormLogo>
            {this.isEdit() ? (
              <IconSvgComponent iconPath="svg/ico-book-edit-lg.svg" />
            ) : (
              <IconSvgComponent iconPath="svg/ico-book-add-lg.svg" />
            )}
          </FormLogo>

          <FormTitle>{this.isEdit() ? "Edit Course" : "Create Course"}</FormTitle>
          <FieldErrors
            formErrors={formErrors}
            apiErrors={this.props.courseApiErrors[operation]?.message}
          ></FieldErrors>
        </FormHead>

        <FormBody size1>
          <FormRowGrid justifyStart>
            <FormCol half>
              <FormControls>
                <Field
                  id="name"
                  name="name"
                  onChange={this.handleInputChange("name")}
                  value={name}
                  placeholder="Course Name"
                  required
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="courseNumber"
                  name="courseNumber"
                  onChange={this.handleInputChange("courseNumber")}
                  value={courseNumber}
                  placeholder="Course #"
                  required
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="sectionNumber"
                  name="sectionNumber"
                  onChange={this.handleInputChange("sectionNumber")}
                  value={sectionNumber}
                  placeholder="Lecture Section #"
                  required
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="discipline"
                  name="discipline"
                  isSelect
                  onChange={this.handleStaticChange("discipline")}
                  options={disciplineList}
                  value={discipline}
                  required
                  placeholder="Discipline"
                  apiCallInProgress={apiCallInProgress}
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="course-room"
                  name="room"
                  onChange={this.handleInputChange("room")}
                  value={room}
                  placeholder="Room"
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="course-year"
                  name="year"
                  onChange={this.handleInputChange("year")}
                  value={year}
                  placeholder="Year"
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="course-term"
                  name="term"
                  onChange={this.handleInputChange("term")}
                  value={term}
                  placeholder="Term"
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="course-background"
                  name="background"
                  onChange={this.handleInputChange("background")}
                  value={background}
                  placeholder="Background"
                  max="255"
                />

                <ColorPickerBlock
                  onOpen={(e) => this.handlePickerOpen(e)}
                  onClose={(e) => this.handlePickerClose(e)}
                  background={background ? background : "#2049E6"}
                  showPicker={showColorPicker}
                  onChange={(color, event) => this.handleChangeComplete(color, event)}
                />

                <ButtonLink
                  transparent
                  onClick={this.handleGoBack}
                  type="button"
                  className="form-btn-image"
                >
                  <IconSvgComponent iconPath="svg/ico-picture-alt.svg" />
                </ButtonLink>
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="course-schedule"
                  name="schedule"
                  onChange={this.handleInputChange("schedule")}
                  value={schedule}
                  placeholder="Schedule (MWF, TR, etc.)"
                  max="255"
                />
              </FormControls>
            </FormCol>
          </FormRowGrid>
        </FormBody>

        <FormActions>
          {this.isEdit() && (
            <ButtonLink type="button" transparent onClick={() => this.props.closeModal()}>
              <span>Cancel</span>
            </ButtonLink>
          )}

          {this.isEdit() ? (
            <ButtonLink type="submit" violet hasIcon>
              <span>Update Course</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          ) : (
            <ButtonLink type="submit" violet hasIcon>
              <span>Create Course</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          )}
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    apiCallInProgress: state.storedCourses.apiCallInProgress,
    courseApiErrors: state.storedCourses.apiErrors.course,
    accountId: state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId,
    disciplines: state.storedCourses.disciplines,
  }),
  (dispatch) => ({
    createSection: async (section) => await dispatch(createSection(section)),
    updateSection: async (section) => await dispatch(updateSection(section)),
    closeModal: () => dispatch(closeModal()),
    getAllDisciplines: () => dispatch(getAllDisciplines()),
  })
)(FormCreateCourse);
