import React from "react";
import { connect } from "react-redux";

import ModalFrame from "./ModalFrame";
import { closeModal } from "store/state/ui/actions";
import AssessmentSubmit from "../assessment/AssessmentSubmit";

function ModalAssessmentPasscode() {
  return (
    <ModalFrame>
      <AssessmentSubmit />
    </ModalFrame>
  );
}

export default connect(null, {
  closeModal,
})(ModalAssessmentPasscode);
