import React from "react";
import { connect } from "react-redux";
import { FormattedText } from "../common/FormattedText";
import { BreadcrumbsContainer } from "styles/components/BreadcrumbsContainer";

const LearningUnitBreadcrumb = ({ name }) => (
  <BreadcrumbsContainer>
    <FormattedText text={name} adjustFont="true"></FormattedText>
  </BreadcrumbsContainer>
);

export default connect((state) => {
  const name = state.learningLibrary.learningUnit.name;

  return { name };
})(LearningUnitBreadcrumb);
