import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledButton, {
  UnstyledButton,
  StyledAnchor,
  LinkButton,
  RouterLink,
} from "styles/components/Btns";
import { TableDropdownButton } from "components/elements/TableDropdown";
import {
  CardLink,
  CardOrderedListLink,
  CardVideoLink,
  CardDownloadLink,
  CardRedirectLink,
} from "styles/components/Card";
import { StyledLink } from "styles/components/Form";
import {
  DrawerListButton,
  DrawerListLink,
  SectionAsideButton,
  SectionAsideHead,
} from "components/layouts/DrawerLayout";
import { FrameLink } from "components/modal/ModalFrame";
import { NavTrigger } from "styles/components/NavTrigger";
import { BtnUser } from "styles/components/Header";

class ButtonLink extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    form: PropTypes.string,
    formAction: PropTypes.string,
    formNoValidate: PropTypes.bool,
    formTarget: PropTypes.string,
    focussed: PropTypes.bool,
    transparent: PropTypes.bool,
    xs: PropTypes.bool,
    violet: PropTypes.bool,
    fab: PropTypes.bool,
    hasIcon: PropTypes.bool,
    reverse: PropTypes.bool,
    widgetButton: PropTypes.bool,
    size: PropTypes.any,
    isLinkButton: PropTypes.bool,
    isStyledAnchor: PropTypes.bool,
    isStyledLink: PropTypes.bool,
    isRouterLink: PropTypes.bool,
    isFrameLink: PropTypes.bool,
    isTableDropdownButton: PropTypes.bool,
    isCardLink: PropTypes.bool,
    isCardOrderedListLink: PropTypes.bool,
    isCardVideoLink: PropTypes.bool,
    isCardDownloadLink: PropTypes.bool,
    isCardRedirectLink: PropTypes.bool,
    isSectionAsideButton: PropTypes.bool,
    isSectionAsideHead: PropTypes.bool,
    isDrawerListButton: PropTypes.bool,
    isDrawerListLink: PropTypes.bool,
    isBtnUser: PropTypes.bool,
    isNavTrigger: PropTypes.bool,
    isHighlighted: PropTypes.any,
    isUnstyledButton: PropTypes.bool,
    gray: PropTypes.bool,
    red: PropTypes.bool,
    blue: PropTypes.bool,
    isbuttonstyle: PropTypes.string,
    float: PropTypes.bool,
    smallgap: PropTypes.any,
    smallergap: PropTypes.any,
    margintop: PropTypes.any,
    color: PropTypes.any,
    isOpen: PropTypes.bool,
    to: PropTypes.any,
    download: PropTypes.string,
    href: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.string,
    floatdir: PropTypes.string,
    isPrevious: PropTypes.bool,
    isAssessmentSubmissionGuide: PropTypes.bool,
    noLeftPadding: PropTypes.bool,
    positionTop: PropTypes.bool,
  };

  state = {
    mouseDown: false,
  };

  handleMouseDown = () => {
    this.setState({ mouseDown: true });
  };

  handleMouseUp = () => {
    this.setState({ mouseDown: false });
  };

  handleFocus = (e, onFocus) => {
    if (this.state.mouseDown) {
      e.target.blur();
    }
    onFocus && onFocus();
  };

  render() {
    const {
      id,
      name,
      value,
      className,
      onFocus,
      onBlur,
      onClick,
      onChange,
      onKeyUp,
      onKeyDown,
      autoFocus,
      disabled,
      type,
      form,
      formAction,
      formNoValidate,
      formTarget,
      focussed,
      transparent,
      xs,
      violet,
      fab,
      hasIcon,
      reverse,
      widgetButton,
      size,
      isLinkButton,
      isStyledAnchor,
      isStyledLink,
      isRouterLink,
      isFrameLink,
      isTableDropdownButton,
      isCardLink,
      isCardOrderedListLink,
      isCardVideoLink,
      isCardDownloadLink,
      isCardRedirectLink,
      isSectionAsideButton,
      isSectionAsideHead,
      isDrawerListButton,
      isDrawerListLink,
      isBtnUser,
      isNavTrigger,
      isHighlighted,
      isUnstyledButton,
      gray,
      red,
      blue,
      isbuttonstyle,
      float,
      smallgap,
      smallergap,
      margintop,
      isOpen,
      to,
      download,
      href,
      rel,
      target,
      color,
      danger,
      floatdir,
      isHidden,
      isPrevious,
      isAssessmentSubmissionGuide,
      noLeftPadding,
      positionTop,
      setRef = () => null,
      style = {},
    } = this.props;

    if (isStyledAnchor) {
      return (
        <StyledAnchor
          id={id}
          name={name}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </StyledAnchor>
      );
    } else if (isStyledLink) {
      return (
        <StyledLink
          id={id}
          floatdir={floatdir}
          name={name}
          to={to}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          size={size}
          gray={gray}
          color={color}
          style={style}
        >
          {this.props.children}
        </StyledLink>
      );
    } else if (isLinkButton) {
      return (
        <LinkButton
          id={id}
          name={name}
          value={value}
          className={className}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          transparent={transparent}
          xs={xs}
          violet={violet}
          fab={fab}
          hasIcon={hasIcon}
          reverse={reverse}
          widgetButton={widgetButton}
          size={size}
          gray={gray}
          positionTop={positionTop}
          style={style}
        >
          {this.props.children}
        </LinkButton>
      );
    } else if (isRouterLink) {
      return (
        <RouterLink
          id={id}
          name={name}
          to={to}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          gray={gray}
          red={red}
          isbuttonstyle={isbuttonstyle}
          float={float}
          className={noLeftPadding ? "no-left-padding" : ""}
          style={style}
        >
          {this.props.children}
        </RouterLink>
      );
    } else if (isFrameLink) {
      return (
        <FrameLink
          id={id}
          name={name}
          to={to}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </FrameLink>
      );
    } else if (isTableDropdownButton) {
      return (
        <TableDropdownButton
          id={id}
          name={name}
          value={value}
          className={className}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          transparent={transparent}
          xs={xs}
          violet={violet}
          fab={fab}
          hasIcon={hasIcon}
          positionTop={positionTop}
          reverse={reverse}
          widgetButton={widgetButton}
          size={size}
          gray={gray}
          innerRef={setRef}
          style={style}
        >
          {this.props.children}
        </TableDropdownButton>
      );
    } else if (isCardLink) {
      return (
        <CardLink
          id={id}
          name={name}
          to={to}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </CardLink>
      );
    } else if (isCardOrderedListLink) {
      return (
        <CardOrderedListLink
          id={id}
          name={name}
          to={to}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          smallgap={smallgap}
          smallergap={smallergap}
          style={style}
        >
          {this.props.children}
        </CardOrderedListLink>
      );
    } else if (isCardVideoLink) {
      return (
        <CardVideoLink
          id={id}
          name={name}
          to={to}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </CardVideoLink>
      );
    } else if (isCardDownloadLink) {
      return (
        <CardDownloadLink
          id={id}
          name={name}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </CardDownloadLink>
      );
    } else if (isCardRedirectLink) {
      return (
        <CardRedirectLink
          id={id}
          name={name}
          to={to}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          gray={gray}
        >
          {this.props.children}
        </CardRedirectLink>
      );
    } else if (isSectionAsideButton) {
      return (
        <SectionAsideButton
          id={id}
          name={name}
          value={value}
          className={className}
          isOpen={isOpen}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          transparent={transparent}
          xs={xs}
          violet={violet}
          fab={fab}
          hasIcon={hasIcon}
          positionTop={positionTop}
          reverse={reverse}
          widgetButton={widgetButton}
          size={size}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </SectionAsideButton>
      );
    } else if (isSectionAsideHead) {
      return (
        <SectionAsideHead
          id={id}
          name={name}
          value={value}
          className={className}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          transparent={transparent}
          xs={xs}
          violet={violet}
          fab={fab}
          hasIcon={hasIcon}
          positionTop={positionTop}
          reverse={reverse}
          widgetButton={widgetButton}
          size={size}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </SectionAsideHead>
      );
    } else if (isDrawerListButton) {
      return (
        <DrawerListButton
          id={id}
          name={name}
          value={value}
          className={className}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          transparent={transparent}
          xs={xs}
          violet={violet}
          fab={fab}
          hasIcon={hasIcon}
          positionTop={positionTop}
          reverse={reverse}
          widgetButton={widgetButton}
          size={size}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </DrawerListButton>
      );
    } else if (isDrawerListLink) {
      return (
        <DrawerListLink
          id={id}
          name={name}
          to={to}
          download={download}
          href={href}
          rel={rel}
          target={target}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </DrawerListLink>
      );
    } else if (isBtnUser) {
      return (
        <BtnUser
          id={id}
          name={name}
          value={value}
          className={className}
          isHighlighted={isHighlighted}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          transparent={transparent}
          widgetButton={widgetButton}
          size={size}
          style={style}
        >
          {this.props.children}
        </BtnUser>
      );
    } else if (isNavTrigger) {
      return (
        <NavTrigger
          id={id}
          name={name}
          value={value}
          className={className}
          isHighlighted={isHighlighted}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          transparent={transparent}
          xs={xs}
          violet={violet}
          fab={fab}
          hasIcon={hasIcon}
          positionTop={positionTop}
          reverse={reverse}
          widgetButton={widgetButton}
          size={size}
          gray={gray}
          style={style}
        >
          {this.props.children}
        </NavTrigger>
      );
    } else if (isUnstyledButton) {
      return (
        <UnstyledButton
          id={id}
          name={name}
          value={value}
          className={className}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          size={size}
          margintop={margintop}
          isHidden={isHidden}
          blue={blue}
          style={style}
        >
          {this.props.children}
        </UnstyledButton>
      );
    } else {
      return (
        <StyledButton
          id={id}
          name={name}
          value={value}
          className={className}
          onFocus={(e) => this.handleFocus(e, onFocus)}
          onBlur={onBlur}
          onClick={onClick}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          autoFocus={autoFocus}
          disabled={disabled}
          type={type}
          form={form}
          formAction={formAction}
          formNoValidate={formNoValidate}
          formTarget={formTarget}
          focussed={focussed}
          transparent={transparent}
          xs={xs}
          violet={violet}
          fab={fab}
          hasIcon={hasIcon}
          positionTop={positionTop}
          reverse={reverse}
          widgetButton={widgetButton}
          size={size}
          color={color}
          danger={danger}
          floatdir={floatdir}
          isHidden={isHidden}
          isPrevious={isPrevious}
          margintop={margintop}
          isAssessmentSubmissionGuide={isAssessmentSubmissionGuide}
          innerRef={setRef}
          style={style}
        >
          {this.props.children}
        </StyledButton>
      );
    }
  }
}
export default ButtonLink;
