/**
 * @ The external dependecies
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import FormCreateAccount from "components/form/FormCreateAccount";

/**
 * Class for modal create account.
 *
 * @class      ModalCreateAccount (name)
 */
const ModalCreateAccount = (props) => {
  return (
    <ModalFrame>
      <FormCreateAccount />
    </ModalFrame>
  );
};

export default ModalCreateAccount;
