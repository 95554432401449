import { createAction } from "redux-actions";
import { updateAll } from "store/state/administration/actions";

export const openModal = createAction("ui/open-modal");
export const closeModal = createAction("ui/close-modal");
export const toggleGlobalOverlay = createAction("TOGGLE_GLOBAL_OVERLAY");

export const addNotification = createAction("ADD_NOTIFICATION");
export const successAddNotification = createAction("SUCCESS_ADD_NOTIFICATION");
export const successRemoveNotification = createAction("SUCCESS_REMOVE_NOTIFICATION");
export const clearNotifications = createAction("CLEAR_NOTIFICATIONS");

export const asyncCloseModal = ({ selector, updates }) => (dispatch) => {
  dispatch(updateAll({ selector, updates }));
  dispatch(closeModal());
};
