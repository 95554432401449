/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "ramda";

/**
 * The internal dependencies.
 */
import BarChartSmall from "components/chart/BarChartSmall";
import DeploymentChart from "components/chart/DeploymentChart";
import Grid, { Col, Row } from "styles/components/Grid";
import SectionDashboard, { SectionBody } from "styles/components/SectionDashboard";
import {
  FrameBox,
  FrameTitle,
  FrameCell,
  FrameHeading,
  FrameLabel,
  FrameValue,
} from "styles/components/Frame";
import { getDeploymentDashboardData } from "store/state/facultyDashboard/actions";
import Loader from "components/common/loader";
import { NoDataMessageContainer } from "styles/components/Card";
import { NoDataMessage } from "styles/components/Card";

import { ListStats, ListItem } from "styles/components/List";
import Stat, { StatTitle, StatEntry } from "styles/components/Stat";

import IconSvgComponent from "components/common/IconSvgComponent";

import ButtonLink from "components/common/ButtonLink";
import { StyledSpan } from "styles/components/TableDefault";

import BasicDeploymentDetails from "components/elements/BasicDeploymentDetails";

import stemifyRoutes from "constants/routes";
import DocumentTitle from "react-document-title";

/**
 * Class for deployment details.
 *
 * @class      DeploymentDetails (name)
 */
class DeploymentDetails extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    selectedChartArcNumber: null,
  };

  chartColors = ["#2645EF", "#FF5A62"];
  barChartColors = ["#FF5F65", "#9242A1", "#FF9F4D", "#E267A3"];

  onMouseEnter = (idx, number) => () => {
    const { chartInstance } = this.chartReference.chartReference;
    const id = chartInstance.id;
    const dataset = chartInstance.data.datasets[0];
    const borderColor = dataset.hoverBorderColor[idx];
    const borderWidth = number > 0 ? dataset.hoverBorderWidth : 0;
    const element = dataset._meta[id].data[idx];

    const model = element._model;
    const view = element._view;

    model.borderWidth = borderWidth;
    model.borderColor = borderColor;

    view.borderWidth = borderWidth;
    view.borderColor = borderColor;

    element.draw();

    this.setState({
      selectedChartArcNumber: number,
    });
  };

  onMouseLeave = () => {
    const { chartInstance } = this.chartReference.chartReference;

    if (chartInstance) {
      chartInstance.update();
    }

    this.setState({
      selectedChartArcNumber: null,
    });
  };

  componentDidMount() {
    const { deploymentId } = this.props.match.params;
    this.props.getDeploymentDashboardData({ deploymentId });
  }

  componentWillUnmount() {
    const chartInstance = this.chartReference?.chartReference.chartInstance;
    chartInstance && chartInstance.destroy();
  }

  getDeploymentCompletionChartData = (deploymentDetails) => {
    return {
      chartTotalEntry: `${deploymentDetails.percentageOfCompletion}%`,
      chartEntries: [
        { label: "Completed", number: deploymentDetails.numberOfStudentsCompleted },
        { label: "Incomplete", number: deploymentDetails.numberOfStudentsPending },
      ],
    };
  };

  getChartColours = (count) => {
    let colors = [];
    while (colors.length < count) {
      colors.push(...this.barChartColors);
    }
    return colors;
  };

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const { selectedChartArcNumber } = this.state;
    const { deploymentDetails, apiCallInProgress, deploymentApiErrors } = this.props;

    return !apiCallInProgress &&
      deploymentApiErrors.get.code === -1 &&
      !isEmpty(deploymentDetails) ? (
      <DocumentTitle title={`Stemify | ${deploymentDetails.deploymentName}`}>
        <SectionDashboard modified>
          <SectionBody>
            <Grid gutterSmall colSpacing>
              <Row>
                <Col flex>
                  <BasicDeploymentDetails
                    displayName="deployment"
                    deploymentDetails={deploymentDetails}
                    iconURL="svg/ico-deployment-lg.svg"
                  ></BasicDeploymentDetails>
                </Col>
              </Row>

              <Row>
                {deploymentDetails.assessments &&
                  deploymentDetails.assessments.map((assessment, index) => (
                    <Col key={"assessment" + index} style={{ maxWidth: "50%" }}>
                      <FrameBox noPadding>
                        <div style={{ display: "flex", padding: "20px 10px 20px 10px" }}>
                          <div style={{ flex: "0 1 20%" }}>
                            <IconSvgComponent
                              additionalClass={"details-assessment-icon"}
                              iconPath="svg/ico-assessment-lg.svg"
                            />
                          </div>
                          <div style={{ flex: "0 1 80%", flexDirection: "column" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                <FrameHeading>{assessment.name}</FrameHeading>
                              </div>
                              <div>
                                <ButtonLink
                                  isRouterLink
                                  isbuttonstyle="isbuttonstyle"
                                  to={`${stemifyRoutes.deployments}/${deploymentDetails.id}/${assessment.id}`}
                                >
                                  <StyledSpan wrappedText hasIconOnLeft bold>
                                    View Details
                                  </StyledSpan>
                                </ButtonLink>
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <FrameCell percent="50">
                                <FrameCell style={{ wordBreak: "normal" }}>
                                  <FrameLabel caps># of students completed</FrameLabel>
                                </FrameCell>
                                <FrameCell style={{ wordBreak: "normal" }}>
                                  <FrameValue>{`${assessment.percentageOfCompletion}%`}</FrameValue>
                                  <FrameValue small upWithGap>
                                    {`${assessment.numberOfStudentsCompleted} / ${assessment.totalNumberofStudents}`}
                                  </FrameValue>
                                </FrameCell>
                              </FrameCell>

                              <FrameCell percent="50">
                                <FrameCell>
                                  <FrameLabel caps>Median Score</FrameLabel>
                                </FrameCell>
                                <FrameCell>
                                  <FrameValue>{assessment.medianScore}</FrameValue>
                                </FrameCell>
                              </FrameCell>
                            </div>
                          </div>
                        </div>
                        <FrameCell
                          variant="primary"
                          padding
                          borderRadius
                          style={{ wordBreak: "normal" }}
                        >
                          <ListStats stats>
                            <ListItem key={"stats_avg"}>
                              <Stat>
                                <StatTitle>{assessment.averageScore}</StatTitle>
                                <StatEntry>AVG</StatEntry>
                              </Stat>
                            </ListItem>
                            <ListItem key={"stats_std"}>
                              <Stat>
                                <StatTitle>{assessment.standardDeviation}</StatTitle>
                                <StatEntry>STD</StatEntry>
                              </Stat>
                            </ListItem>
                            <ListItem key={"stats_skw"}>
                              <Stat>
                                <StatTitle>{assessment.skewnessScore}</StatTitle>
                                <StatEntry>SKW</StatEntry>
                              </Stat>
                            </ListItem>
                            <ListItem key={"stats_krt"}>
                              <Stat>
                                <StatTitle>{assessment.kurtosisScore}</StatTitle>
                                <StatEntry>KRT</StatEntry>
                              </Stat>
                            </ListItem>
                          </ListStats>
                        </FrameCell>
                      </FrameBox>
                    </Col>
                  ))}
              </Row>

              <Row>
                <Col half flex>
                  <FrameBox>
                    <FrameTitle>Deployment Completion</FrameTitle>

                    <DeploymentChart
                      ref={(reference) => (this.chartReference = reference)}
                      chartData={this.getDeploymentCompletionChartData(deploymentDetails)}
                      chartColors={this.chartColors}
                      onMouseEnter={this.onMouseEnter}
                      onMouseLeave={this.onMouseLeave}
                      selected={selectedChartArcNumber}
                    />
                  </FrameBox>
                </Col>

                <Col half flex>
                  <FrameBox>
                    <FrameTitle>Placement Summary</FrameTitle>

                    <BarChartSmall
                      xAxisLabels={
                        deploymentDetails.placementSummary &&
                        deploymentDetails.placementSummary.map((p) => p.course)
                      }
                      data={
                        deploymentDetails.placementSummary &&
                        deploymentDetails.placementSummary.map((p) => p.numberOfStudents)
                      }
                      chartColors={
                        deploymentDetails.placementSummary &&
                        this.getChartColours(deploymentDetails.placementSummary.length)
                      }
                    />
                  </FrameBox>
                </Col>
              </Row>
            </Grid>
          </SectionBody>
        </SectionDashboard>
      </DocumentTitle>
    ) : (
      <NoDataMessageContainer noIcon>
        {apiCallInProgress == true && <Loader />}
        {apiCallInProgress == false && (
          <NoDataMessage>
            No Deployment Dashboard content <br /> available at this time.
          </NoDataMessage>
        )}
      </NoDataMessageContainer>
    );
  }
}
export default connect(
  (state) => {
    return {
      deploymentDetails: state.facultyDashboard.deployment,
      apiCallInProgress: state.facultyDashboard.apiCallInProgress,
      deploymentApiErrors: state.facultyDashboard.apiErrors.deployment,
    };
  },
  {
    getDeploymentDashboardData,
  }
)(DeploymentDetails);
