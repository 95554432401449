/**
 * @ The external dependecies
 */
import { closeModal } from "store/state/ui/actions";
import React from "react";
import IconSvgComponent from "components/common/IconSvgComponent";
import { connect } from "react-redux";
import { create } from "store/state/administration/actions";
import { withRouter } from "react-router-dom";
import ButtonLink from "components/common/ButtonLink";
import { AssessmentContainer } from "../../styles/components/SelectAssessment";
import { FormHead, FormLogo, FormActions } from "styles/components/Form";
/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
/**
 * Class for modal create user.
 *
 * @class      ModalCreateUser (name)
 */

class ModalShowNoNetworkErrorMessage extends React.Component {
  handleOnClick = async () => {
    // const { location, history } = this.props;
    // await history.push("/");
    // history.push(location.pathname);
    window.location.reload();
  };

  render() {
    return (
      <ModalFrame hideCloseBtn>
        <FormHead>
          <FormLogo>
            {/* <IconSvgComponent iconPath="svg/ico-user-limit-reached.svg" /> */}
          </FormLogo>
          <h3>Unable to connect</h3>
          <AssessmentContainer>{"Server can't be reached!"}</AssessmentContainer>
        </FormHead>
        <FormActions>
          <ButtonLink violet onClick={this.handleOnClick} type="button">
            Retry
          </ButtonLink>
        </FormActions>
      </ModalFrame>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    create,
    closeModal,
  }
)(withRouter(ModalShowNoNetworkErrorMessage));
