import React from "react";
import { connect } from "react-redux";
import NotificationSystem from "react-notification-system";

import { successAddNotification, successRemoveNotification } from "../store/state/ui/actions";
import theme from "styles/theme";

export const getNotificationId = (() => {
  let nextNotificationId = 1;
  return () => {
    const notificationId = nextNotificationId;
    nextNotificationId += 1;
    return notificationId;
  };
})();

const NotificationStyles = {
  NotificationItem: {
    DefaultStyle: {
      borderTop: "none",
      borderRadius: "8px",
    },
    info: {
      backgroundColor: "#9DAAC3",
      color: "#16181C",
    },
    success: {
      backgroundColor: "#1CC379",
      color: "#032113",
    },
    warning: {
      backgroundColor: "#FA9E3B",
      color: "#3E1F14",
    },
    error: {
      backgroundColor: "#FF6E73",
      color: "#200202",
    },
  },
  Title: {
    DefaultStyle: {
      backgroundColor: "none",
    },
    info: {
      color: "#16181C",
    },
    success: {
      color: "#032113",
    },
    warning: {
      color: "#3E1F14",
    },
    error: {
      color: "#200202",
    },
  },
  Dismiss: {
    DefaultStyle: {
      fontFamily: "sans-serif",
      backgroundColor: "none",
    },
    info: {
      color: "#16181C",
    },
    success: {
      color: "#032113",
    },
    warning: {
      color: "#3E1F14",
    },
    error: {
      color: "#200202",
    },
  },
};

class Notifier extends React.Component {
  notificationSystem = React.createRef();

  addNotification(notification) {
    const system = this.notificationSystem.current;
    system.addNotification({
      ...notification,
      onAdd: (notification) => {
        this.props.successAddNotification(notification.uid);
      },
      onRemove: ({ uid }) => {
        this.props.successRemoveNotification(uid);
      },
    });
  }

  componentDidUpdate(lastProps) {
    const system = this.notificationSystem.current;
    const shouldClearAllNotifications =
      this.props.notifications.length === 0 &&
      this.props.notificationsToAdd.length === 0 &&
      lastProps.notifications > 0;

    if (shouldClearAllNotifications) system.clearNotifications();

    const newNotificationsToAdd = this.props.notificationsToAdd.filter(
      (notification) =>
        !lastProps.notificationsToAdd.find(
          (lastNotification) => notification.uid === lastNotification.uid
        )
    );
    newNotificationsToAdd.forEach((notification) => {
      this.addNotification(notification);
    });
  }

  render() {
    return <NotificationSystem ref={this.notificationSystem} style={NotificationStyles} />;
  }
}

export default connect(
  (state) => ({
    notificationsToAdd: state.ui.notificationsToAdd,
    notifications: state.ui.notifications,
  }),
  (dispatch) => ({
    successAddNotification: (notificationUid) =>
      dispatch(successAddNotification({ notificationUid })),
    successRemoveNotification: (notificationUid) =>
      dispatch(successRemoveNotification({ notificationUid })),
  })
)(Notifier);
