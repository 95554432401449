/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";

export const Col = styled.div`
	max-width: 100%;
	flex: 1 1 100%;
	padding: 0 20px 40px;
	${(props) =>
    props.noPadding &&
    `
		padding: 0px !important;
	`}

	${(props) =>
    props.flex &&
    `
		display: flex;

		& > * {
			max-width: 100%;
			flex: 1 1 100%;
		}
	`}

	${(props) =>
    props.half &&
    `
		max-width: 50%;
		flex: 0 0 50%;
	`}

	${(props) =>
    props.third &&
    `
		max-width: 33.33%;
		flex: 0 0 33.33%;
	`}

	${(props) =>
    props.size1 &&
    `
		max-width: 297px;
		flex: 0 0 297px;
	`}

	${(props) =>
    props.size &&
    `
		max-width: ${props.size}%;
		flex: 0 0 ${props.size}%;
	`}
`;

const Grid = styled.div`
	display: flex;
	margin: 0 -20px -40px;
  flex-direction: column;

	${(props) =>
    props.justifyCenter &&
    `
		justify-content: center;
	`}

	${(props) =>
    props.alignCenter &&
    `
		align-items: center;
	`}

	${(props) =>
    props.gutterSmall &&
    css`
      margin: 0 -11px;

      ${Col} {
        padding: 0 11px;
      }
    `}

	${(props) =>
    props.colSpacing &&
    css`
      margin: 0 -11px -24px;

      ${Col} {
        padding: 0 11px 24px;
      }
    `}
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  margin-bottom: 20px;
`;

export default Grid;
