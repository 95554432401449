// import { getAccessToken } from './localStore';
import { pick } from "ramda";
import {
  usersPath,
  userPath,
  userFields,
  programPath,
  ssoTokenPath,
  accountPath,
} from "../constants/api";
import { trimStrings } from "../utilities/commonFunctions";
import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";
import { ROLES } from "../constants/roles";

const createUser = async (userData) => {
  let data = trimStrings(pick(userFields, userData));
  data = {
    ...data,
    role: data.role.value,
    external_id: data.studentId,
    year: data.year.value,
    userName: data.id,
    assessment_time_multiplier: data.assessmentTimeMultiplier,
  };

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: userPath,
    method: "post",
    body: JSON.stringify(data),
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": "application/x-www-form-urlencoded",
      account_id: userData.account,
    },
  });
};

const updateUser = async (id, userData) => {
  let data = trimStrings(pick(userFields, userData));
  data = {
    ...data,
    role: data.role.value,
    year: data.year.value,
    external_id: data.studentId,
    assessment_time_multiplier: data.assessmentTimeMultiplier,
  };

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${userPath}/${id}`,
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: getAccessToken(),
    },
  });
};

const updateUserStatusValue = async (id, account, data) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${userPath}/${id}/status`,
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": "application/x-www-form-urlencoded",
      account_id: account,
    },
  });
};

const getUsersForAccount = async (account, page, pageSize, searchParams = "") => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath:
      pageSize === -1
        ? usersPath
        : `${usersPath}?pageSize=${pageSize}&page=${page}&${searchParams}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: account,
    },
  });
};

const setUserRole = (role) => {
  switch (role) {
    case "Master Admin":
      return ROLES.MASTER_ADMIN;
    case "Account Admin":
      return ROLES.ACCOUNT_ADMIN;
    case "Instructor":
      return ROLES.INSTRUCTOR;
    case "Student":
      return ROLES.STUDENT;
    default:
  }
};

const decorateUserObject = ({ user }) => {
  return {
    username: user["cognito:username"],
    email: user.email,
    currentAccountId: user.currentAccountId,
    accountSwitched: Boolean(user.currentAccountId),
    currentAccountName: user.currentAccountName,
    accountId: user["custom:account_id"],
    accountName: user.defaultAccountName,
    // active: user.is_active, // I'm not sure this is being used???
    role: setUserRole(user["custom:role"]),
    name: `${user.name} ${user.family_name}`,
  };
};

const getUserProgram = ({ accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: programPath,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const getUserCurrentAccount = (state) => {
  const { user } = state;
  const { accountId, currentAccountId } = user;
  return currentAccountId || accountId;
};

const callSSOLoginApi = ({ code, url }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: ssoTokenPath + "?code=" + code + "&redirect_url=" + url,
    method: "GET",
  });
};

export {
  decorateUserObject,
  createUser,
  updateUser,
  updateUserStatusValue,
  getUsersForAccount,
  getUserProgram,
  callSSOLoginApi,
};

export const callAccountInformationApi = ({ accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: accountPath + "/info",
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};
