import React, { useState, useEffect } from "react";
import { LoadingSpinner } from "components/card/withSpinnerHOC";
import BarCharts from "components/chart/Bar";
import ScoreDistributionTooltip from "components/chart/ToolTips";
import { useSelector, useDispatch } from "react-redux";
import { getCourseAssessmentScoreDistributionData } from "store/state/courses/actions";
import { Card, styles } from "./styles";
let colormap = require("colormap");

const ScoreDistributionGraph = ({ scoreDistribution, plasmaColors }) => {
  return (
    <BarCharts
      config={{
        options: {
          dimension: {
            width: "100%",
            height: "100%",
          },
          margin: {
            top: 0,
            right: 0,
            left: -5,
            bottom: 40,
          },
        },
      }}
      xAxis={{
        axisLine: false,
        tickLine: true,
        ticks: [],
        labelText: "SCORE",
        allowDecimals: false,
        domain: [],
        position: "bottom",
        offset: 10,
      }}
      yAxis={{
        axisLine: true,
        tickLine: false,
        labelText: "# OF STUDENTS ",
        allowDecimals: false,
        position: "left",
        offset: -20,
      }}
      CustomTooltip={<ScoreDistributionTooltip plasmaColors={plasmaColors} />}
      CustomTooltipCursor={{ fill: "#E3EBFA" }}
      grid={true}
      barSize={6}
      maxBarSize={90}
      barRadius={10}
      isGroupBarChart={false}
      isHorizontalChart={false}
      dataSet={scoreDistribution}
      dataKeys={{
        xAxisKey: "score",
        yAxisKeys: ["studentCount"],
      }}
    />
  );
};

const ScoreDistributionCard = ({ assignmentId, assessment }) => {
  const dispatch = useDispatch();
  const scoreDistribution = useSelector((state) =>
    Object.values(state.storedCourses.scoreDistribution)
  );

  const questionCount = assessment.versions[0].question_count;

  const colors = colormap({
    colormap: "plasma",
    nshades: scoreDistribution?.length > 9 ? scoreDistribution?.length + 1 : 101,
    format: "hex",
    alpha: 1,
  });

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getCourseAssessmentScoreDistributionData({ assignmentId, questionCount }));
      setLoading(false);
    }
    fetchData();
  }, [dispatch, assignmentId, questionCount]);

  return (
    <Card style={{ height: "475px", minWidth: "0", flex: "1 1 100%" }}>
      <div style={styles.textContainer}>SCORE DISTRIBUTION</div>
      <LoadingSpinner isLoading={isLoading}>
        <ScoreDistributionGraph scoreDistribution={scoreDistribution} plasmaColors={colors} />
      </LoadingSpinner>
    </Card>
  );
};

export default ScoreDistributionCard;
