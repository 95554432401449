import { createAction } from "redux-actions";
import * as practiceAssignmentAPI from "services/practiceAssignment";
import { getUserCurrentAccount } from "utilities/commonFunctions";

export const setPracticeAssignmentCurrentQuestion = createAction(
  "SET_PRACTICE_ASSIGNMENT_CURRENT_QUESTION"
);

export const requestPracticeAssignment = createAction("REQUEST_PRACTICE_ASSIGNMENT");
export const receivePracticeAssignment = createAction("RECEIVE_PRACTICE_ASSIGNMENT");
export const rejectPracticeAssignment = createAction("REJECT_PRACTICE_ASSIGNMENT");
export const clearPracticeAssignment = createAction("CLEAR_PRACTICE_ASSIGNMENT");

export const getPracticeAssignment = ({ assignmentId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = getUserCurrentAccount(state);

    dispatch(requestPracticeAssignment({ assignmentId }));

    try {
      const params = {
        accountId,
        assignmentId,
      };

      const response = await practiceAssignmentAPI.fetchQuestions(params);

      if (response && response.status === "success") {
        const {
          name,
          questions,
          student_practice_attempt_id,
          learningUnit,
          learningModule,
        } = response.data;

        const getAnswerDisplay = (answer) => {
          if (answer.data.find((i) => i.type === "IMAGE") !== undefined) {
            return "image";
          } else {
            return "radio";
          }
        };

        const decorateAnswers = (answers) => {
          return answers.map((answer) => {
            return {
              ...answer,
              correct: answer.is_correct,
              displayType: getAnswerDisplay(answer),
            };
          });
        };

        const assignmentQuestions = questions.map((q) => {
          return {
            id: q.id,
            layout: q.layout,
            attempts: q.attempt_count,
            isCorrect: q.is_answered,
            content: q.data,
            answers: decorateAnswers(q.answers),
            isAnswered: q.is_answered,
          };
        });

        dispatch(
          receivePracticeAssignment({
            assignmentName: name,
            assignmentQuestions,
            assignmentAttemptId: student_practice_attempt_id,
            learningUnit,
            learningModule: {
              id: learningModule.id,
              name: learningModule.name,
              openstax_resource: learningModule.openstax_resource,
            },
            markModuleComplete:
              learningModule.videos?.length > 0 ? learningModule.videos[0].is_complete : true,
          })
        );
        return response;
      } else {
        if (response && response.status === "error") {
          dispatch(rejectPracticeAssignment());
          return response;
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(rejectPracticeAssignment());
    }
  };
};

export const getPracticeAssignmentForCourse = ({ assignmentId }) => {
  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());
    dispatch(requestPracticeAssignment({ assignmentId }));

    try {
      const response = await practiceAssignmentAPI.fetchQuestionsForAssignment({
        accountId,
        assignmentId,
      });

      if (response && response.status === "success") {
        const { name } = response.data.assignment;
        const { questions } = response.data.attempt;

        const getAnswerDisplayType = (answer) => {
          return answer.description.find((i) => i.type === "IMAGE") !== undefined
            ? "image"
            : "radio";
        };

        const decorateAnswers = (answers) => {
          return answers.map((answer) => {
            return {
              ...answer,
              correct: answer.is_correct,
              data: answer.description,
              displayType: getAnswerDisplayType(answer),
            };
          });
        };

        const assignmentQuestions = questions.map((q) => {
          return {
            id: q.question.id,
            layout: q.question.layout,
            attempts: q.question.attempt_count,
            isCorrect: q.question.is_answered,
            content: q.question.description,
            answers: decorateAnswers(q.question.answers),
            isAnswered: q.question.is_answered,
          };
        });

        dispatch(
          receivePracticeAssignment({
            assignmentName: name,
            assignmentQuestions,
            assignmentAttemptId: response.data.attempt.attempt_id,
          })
        );
        return response;
      } else {
        if (response && response.status === "error") {
          dispatch(rejectPracticeAssignment());
          return response;
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(rejectPracticeAssignment());
    }
  };
};

export const requestAttemptQuestion = createAction("REQUEST_ATTEMPT_QUESTION");
export const receiveAttemptQuestion = createAction("RECEIVE_ATTEMPT_QUESTION");
export const rejectAttemptQuestion = createAction("REJECT_ATTEMPT_QUESTION");

export const attemptQuestion = ({ answerId, fromCourse }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = getUserCurrentAccount(state);
    const { assignmentId, assignmentAttemptId, currentQuestionId } = state.practiceAssignment;

    dispatch(requestAttemptQuestion());

    try {
      const params = {
        accountId,
        assignmentId,
        attemptId: assignmentAttemptId,
        questionId: currentQuestionId,
        answerId,
      };

      const response = !fromCourse
        ? await practiceAssignmentAPI.attemptQuestion(params)
        : await practiceAssignmentAPI.attemptQuestionForAssignment(params);

      if (response && response.status === "success") {
        const { question_id, attempt_count, is_correct, attempt_number } = response.data;
        dispatch(
          receiveAttemptQuestion({
            questionId: question_id,
            attempts: !fromCourse ? attempt_count : attempt_number,
            isCorrect: is_correct,
          })
        );
        return response;
      } else {
        if (response && response.status === "error") {
          dispatch(rejectAttemptQuestion());
          return response;
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(rejectAttemptQuestion());
    }
  };
};

export const requestSubmitAssignment = createAction("REQUEST_SUBMIT_ASSIGNMENT");
export const receiveSubmitAssignment = createAction("RECEIVE_SUBMIT_ASSIGNMENT");
export const rejectSubmitAssignment = createAction("REJECT_SUBMIT_ASSIGNMENT");

export const submitPracticeAssignment = ({ assignmentId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = getUserCurrentAccount(state);
    const attemptId = state.practiceAssignment.assignmentAttemptId;

    dispatch(requestSubmitAssignment());

    try {
      if (assignmentId && attemptId) {
        const params = {
          accountId,
          assignmentId,
          attemptId,
        };

        const response = await practiceAssignmentAPI.submit(params);
        if (response && response.status === "success") {
          const data = decorateAssignmentSubmitResponse({ data: response.data });
          dispatch(receiveSubmitAssignment({ data }));
          return response;
        } else {
          if (response && response.status === "error") {
            dispatch(rejectSubmitAssignment());
            return response;
          }
        }
      } else {
        await dispatch(getPracticeAssignment({ assignmentId }));

        const state = getState();
        const accountId = getUserCurrentAccount(state);
        const attemptId = state.practiceAssignment.assignmentAttemptId;

        const params = {
          accountId,
          assignmentId,
          attemptId,
        };

        const response = await practiceAssignmentAPI.submit(params);

        if (response && response.status === "success") {
          const data = decorateAssignmentSubmitResponse({ data: response.data });
          dispatch(receiveSubmitAssignment({ data }));
          return response;
        } else {
          if (response && response.status === "error") {
            dispatch(rejectSubmitAssignment());
            return response;
          }
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(rejectSubmitAssignment());
    }
  };
};

export const decorateAssignmentSubmitResponse = ({ data }) => {
  return {
    ...data,
    improvementTags: data.improvementTags.map((tag) => {
      return {
        id: tag.tag_id,
        title: tag.tag_name,
        questionIds: tag.question_id,
      };
    }),
  };
};
