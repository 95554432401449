import React, { Component } from "react";
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";
import PropTypes from "prop-types";
import styled from "styled-components";
let colormap = require("colormap");

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartLabel = (props) => {
  const {
    circleSize,
    offsetX,
    offsetY,
    fontWeight,
    fontFill,
    secondaryTextFontWeight,
    secondaryTextFontSize,
    fontSize,
    primaryText,
    primaryTextUnit,
    secondaryText,
  } = props;

  return primaryText ? (
    <text
      x={circleSize / 2 - offsetX}
      y={circleSize / 2 - offsetY}
      textAnchor="middle"
      dominantBaseline="middle"
      className="progress-label"
      fontWeight={fontWeight}
      fill={fontFill}
      fontSize={fontSize + "px"}
    >
      {primaryText && (
        <tspan>
          {primaryText}
          <tspan fontSize={fontSize / 2} alignmentBaseline="text-after-edge">
            {`${primaryTextUnit}`}
          </tspan>
        </tspan>
      )}
      {secondaryText && (
        <tspan
          fontSize={secondaryTextFontSize}
          x={circleSize / 2 - offsetX}
          dy="1.5em"
          fontWeight={secondaryTextFontWeight}
        >
          {secondaryText}
        </tspan>
      )}
    </text>
  ) : null;
};

export class CircularChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.exact({
        name: PropTypes.any,
        value: PropTypes.number,
        fill: PropTypes.string,
      })
    ),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fill: PropTypes.string,
    circleSize: PropTypes.number,
    innerRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    outerRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    barSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    startAngle: PropTypes.number,
    endAngle: PropTypes.number,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
    domainMin: PropTypes.number,
    domainMax: PropTypes.number,
    tick: PropTypes.bool,
    displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.number,
    fontFill: PropTypes.string,
    isPercentageValue: PropTypes.bool,
    secondaryText: PropTypes.string,
    secondaryTextFontSize: PropTypes.number,
    secondaryTextFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    circleSize: 200,
    innerRadius: "70%",
    outerRadius: "100%",
    barSize: 30,
    startAngle: 90,
    endAngle: -270,
    offsetX: 0,
    offsetY: 0,
    domainMin: 0,
    domainMax: 100,
    tick: false,
    displayText: "",
    fontWeight: 900,
    fontSize: 30,
    fontFill: "#4a4a4a",
    isPercentageValue: false,
    secondaryText: "",
    secondaryTextFontSize: 15,
    secondaryTextFontWeight: 600,
  };

  colors = colormap({
    colormap: "plasma",
    nshades: 101,
    format: "hex",
    alpha: 1,
  });

  render() {
    const {
      data,
      value,
      fill,
      circleSize,
      innerRadius,
      outerRadius,
      barSize,
      startAngle,
      endAngle,
      offsetX,
      offsetY,
      domainMin,
      domainMax,
      tick,
    } = this.props;

    const ChartData = data
      ? data
      : [
          {
            value: value ? value : 0,
            fill: fill ? fill : this.colors[Math.floor((value / domainMax) * 100)],
          },
        ];

    return (
      <CenteredDiv>
        <RadialBarChart
          width={circleSize}
          height={circleSize}
          cx={circleSize / 2 - offsetX}
          cy={circleSize / 2 - offsetY}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          barSize={barSize}
          data={ChartData}
          startAngle={startAngle}
          endAngle={endAngle}
        >
          <PolarAngleAxis
            type="number"
            domain={[domainMin, domainMax]}
            angleAxisId={0}
            tick={tick}
          />
          <RadialBar background clockWise dataKey="value" cornerRadius={circleSize / 2} />
          {ChartLabel(this.props)}
        </RadialBarChart>
      </CenteredDiv>
    );
  }
}

export default CircularChart;
