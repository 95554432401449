/**
 * @ External Dependencies
 */
import { createAction } from "redux-actions";
import moment from "moment";

/**
 * @ Internal Dependencies
 */
import { COMMON_MESSAGES } from "constants/messages";
import {
  callCreateAccountAPI,
  callUpdateAccountAPI,
  callUpdateAccountStatusAPI,
  callGetAccountsAPI,
  callGetAccountAPI,
} from "services/accounts";
import { getStateName, getLabel } from "utilities/commonFunctions";
import { convertKeysToUnderscore, convertKeysToCamelCase } from "utilities/API";
import { studentYears } from "constants/dropdownOptions";
import { isEmpty, contains } from "ramda";
import { getUserProgram } from "services/users";
import {
  createUser,
  updateUser,
  updateUserStatusValue,
  getUsersForAccount,
  getUserCurrentAccount,
} from "services/users";
import { callGetDeploymentsAPI, decorateDeploymentListResponse } from "services/deployments";
import {
  callGetCohortAPI,
  callGetCohortByIdAPI,
  callCreateCohortAPI,
  callUpdateCohortAPI,
  getStudentsForCohort,
  removeCohortStudent,
  addCohortStudent,
} from "services/cohorts";
import { getBannerAPI, editBannerAPI } from "services/administration";
import { OPERATIONS } from "constants/common";
import { getPlacementAssessments, decorateAssessmentsForDeploymentForm } from "services/assessment";
import { userHierarchy } from "constants/users";
import { getAllPrograms } from "../deployment/actions";

export const rejectPageNotFound = createAction("rejectPageNotFound");
export const resetPageNotFound = createAction("resetPageNotFound");
/**
 * @ Action creators
 */
export const createMultiple = createAction("administration/create-miltiple");
export const remove = createAction("administration/remove");
export const removeAll = createAction("administration/remove-all");
export const request = createAction("REQUEST");
export const create = createAction("CREATE");
export const update = createAction("UPDATE");
export const reject = createAction("REJECT");
export const list = createAction("LIST");
export const listParentWise = createAction("listParentWise");
export const get = createAction("GET");
export const getDetails = createAction("GET_DETAILS");
export const closeModal = createAction("ui/close-modal");
export const resetApiErrors = createAction("RESET_API_ERRORS");
export const updateAll = createAction("UPDATE_ALL");
export const clearUpdateRowId = createAction("CLEAR_UPDATE_ROW_ID");
export const clearAPIError = createAction("CLEAR_API_ERROR");

export const requestPlacementAssessments = createAction("REQUEST_PLACEMENT_ASSESSMENTS");

export const receivePlacementAssessments = createAction("RECEIVE_PLACEMENT_ASSESSMENTS");

export const rejectPlacementAssessments = createAction("REJECT_PLACEMENT_ASSESSMENTS");

export const requestClearUpdateRowId = () => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(clearUpdateRowId());
    }, 2500);
  };
};

export const requestGetBanner = createAction("REQUEST_GET_BANNER");
export const receiveGetBanner = createAction("RECEIVE_GET_BANNER");
export const rejectGetBanner = createAction("REJECT_GET_BANNER");
export const requestEditBanner = createAction("REQUEST_EDIT_BANNER");
export const receiveEditBanner = createAction("RECEIVE_EDIT_BANNER");
export const rejectEditBanner = createAction("REJECT_EDIT_BANNER");
export const dismissBanner = createAction("DISMISS_BANNER");

export const createAccount = ({ selector, item }) => async (dispatch) => {
  dispatch(request({ selector, operation: OPERATIONS.CREATE }));
  try {
    const request = buildCreateAccountRequest(item);
    const response = await callCreateAccountAPI(request);
    if (response.status === "success") {
      const createdAccount = decorateAccountResponse(response.data);
      dispatch(create({ item: createdAccount, selector }));
      return response;
    } else {
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.CREATE,
          errorItem: response.data,
        })
      );
      return response;
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.CREATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

function buildCreateAccountRequest(item) {
  return {
    name: item.organization,
    contact_name: item.contactName,
    contact_email: item.contactEmail,
    contact_phone: item.contactPhone,
    address_line1: item.address1,
    address_line2: item.address2,
    website: "",
    city: item.city,
    state: item.state ? item.state.value : "",
    user_limit: item.userLimit,
  };
}

export const updateAccount = ({ selector, id, updates }) => {
  return async (dispatch, getState) => {
    dispatch(request({ selector, operation: OPERATIONS.UPDATE }));
    try {
      const request = buildUpdateAccountRequest(updates);
      const response = await callUpdateAccountAPI(id, request);
      if (response.status === "success") {
        const updatedAccount = decorateAccountResponse(response.data);
        dispatch(update({ id, updates: updatedAccount, selector }));
        return response;
      } else {
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.UPDATE,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.UPDATE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

function buildUpdateAccountRequest(item) {
  return {
    id: item.id,
    name: item.organization,
    contact_name: item.contactName,
    contact_email: item.contactEmail,
    contact_phone: item.contactPhone,
    address_line1: item.address1,
    address_line2: item.address2,
    website: "",
    city: item.city,
    state: item.state ? item.state.value : "",
    user_limit: item.userLimit,
  };
}

export const getAccounts = ({ selector, page = 1, pageSize, accountId, appendRecords = false }) => {
  return async (dispatch) => {
    dispatch(request({ selector, operation: OPERATIONS.LIST, resetData: !appendRecords }));

    try {
      const response = await callGetAccountsAPI({ page, pageSize, accountId });
      if (response.status === "success") {
        const accounts = decorateAccountListResponse(response.data.rows);
        dispatch(
          list({
            selector,
            items: accounts,
            page,
            count: response.data.count,
            appendRecords,
            pageSize,
          })
        );
        return response;
      } else {
        dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.LIST,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

//Common
//Activate / Deactivate - Accounts / Users
export const updateEntityStatus = ({ selector, id, account, updates }) => async (dispatch) => {
  switch (selector) {
    case "accounts":
      dispatch(request({ selector, operation: OPERATIONS.CHANGE_STATUS }));
      try {
        const response = await callUpdateAccountStatusAPI(id, updates);
        if (response.status === "success") {
          const accountObj = decorateAccountResponse(response.data);
          dispatch(update({ id, updates: accountObj, selector }));
          return response;
        } else {
          dispatch(
            reject({
              selector,
              operation: OPERATIONS.CHANGE_STATUS,
              errorItem: response.data,
            })
          );
          return response;
        }
      } catch (e) {
        console.log(e.message);
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.CHANGE_STATUS,
            errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
          })
        );
        return false;
      }
    case "users":
      dispatch(request({ selector, operation: OPERATIONS.CHANGE_STATUS }));
      try {
        const response = await updateUserStatusValue(id, account, updates);
        if (response.status === "success") {
          const updatedStatus = updates.status ? "active" : "not-active";
          dispatch(
            update({
              ...response,
              id: id,
              updates: { status: updatedStatus },
              selector: "users",
            })
          );
          return response;
        } else {
          if (response.status === "error") {
            dispatch(
              reject({
                selector,
                operation: OPERATIONS.CHANGE_STATUS,
                errorItem: response.data,
              })
            );
            return response;
          }
        }
      } catch (e) {
        console.log(e.message);
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.CHANGE_STATUS,
            errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
          })
        );
        return false;
      }
      break;
    default:
      break;
  }
};

export const getAccount = ({ accountId }) => async (dispatch) => {
  const selector = "accounts";
  dispatch(request({ selector, operation: OPERATIONS.LIST }));

  try {
    const response = await callGetAccountAPI(accountId);

    if (response.status === "success") {
      const accounts = decorateAccountListResponse(response.data.rows);

      dispatch(
        list({
          selector,
          items: accounts,
          count: response.data.count,
        })
      );
      return response;
    } else {
      dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: response.data,
        })
      );
      return response;
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.LIST,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
      })
    );
    return false;
  }
};

//User Management
export const createUserItem = ({ selector, item }) => async (dispatch) => {
  dispatch(request({ selector, operation: OPERATIONS.CREATE }));
  try {
    const response = await createUser(item);
    if (response.status === "success") {
      const userObj = {
        selector: selector,
        item: getUserObjectSuccess(response.data),
      };
      dispatch(create(userObj));
      return response;
    } else {
      if (response.status === "error" && response.data.errorCode == "165") {
        const userObj = {
          selector: selector,
          item: getUserObjectSuccess(response.data.detail),
        };
        dispatch(create(userObj));
        return response;
      } else {
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.CREATE,
            errorItem: response.data,
          })
        );
        return response;
      }
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.CREATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

function getUserObjectSuccess(obj) {
  return {
    id: obj.user_name,
    firstName: obj.name,
    lastName: obj.family_name,
    email: obj.email,
    status: obj.is_active ? "active" : "not-active",
    role: {
      label: obj.role,
      value: obj.role,
    },
    studentId: obj.external_id,
    year: obj.year
      ? {
          label: getLabel(studentYears, obj.year),
          value: obj.year.toLowerCase(),
        }
      : "",
    assessmentTimeMultiplier: obj.assessment_time_multiplier || 1,
    createdAt: obj.created_at || "",
    createdBy: obj.created_by || "",
    modifiedAt: obj.modified_at || "",
    modifiedBy: obj.modified_by || "",
  };
}

function getUsersListingData(users) {
  return users.map((item) => {
    return {
      id: item.user_name,
      avatarUrl: "/images/image-placeholder.png",
      firstName: item.name,
      lastName: item.family_name,
      email: item.email,
      studentId: item.external_id ? item.external_id : "",
      year: item.year
        ? {
            value: item.year.toLowerCase(),
            label: getLabel(studentYears, item.year),
          }
        : "",
      role: { value: item.role, label: item.role },
      status: item.is_active ? "active" : "not-active",
      enrollments: item.cohort_enrollment.map((enrollment) => {
        return {
          cohortId: enrollment.cohort_id,
          cohortName: enrollment.cohort.name,
        };
      }),
      groupTag: item.group_tag || "",
      assessmentTimeMultiplier: item.assessment_time_multiplier || 1,
      createdAt: item.created_at || "",
      createdBy: item.created_by || "",
      modifiedAt: item.modified_at || "",
      modifiedBy: item.modified_by || "",
    };
  });
}

export const updateUserItem = ({ selector, id, updates }) => async (dispatch) => {
  dispatch(request({ selector, operation: OPERATIONS.UPDATE }));
  try {
    let response = await updateUser(id, updates);

    if (response.status === "success") {
      response.updates = getUserObjectSuccess(response.data);
      response.id = id;
      response.selector = selector;
      dispatch(update(response));

      return response;
    } else {
      if (response.status === "error") {
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.UPDATE,
            errorItem: response.data,
          })
        );
        return response;
      }
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.UPDATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

function decorateAccountListResponse(list) {
  return list.map((item) => {
    return decorateAccountResponse(item);
  });
}

const defaultSettings = {
  defaultCohortId: null,
  groupTags: {},
  accommodations: {
    assessment_time_multipliers: [],
  },
};

function decorateAccountResponse(obj) {
  return {
    id: obj.id,
    organization: obj.name,
    contactName: obj.contact_name,
    contactEmail: obj.contact_email,
    contactPhone: obj.contact_phone,
    address1: obj.address_line1,
    address2: obj.address_line2,
    city: obj.city,
    state:
      obj.state === ""
        ? ""
        : {
            value: obj.state,
            label: getStateName(obj.state),
          },
    userLimit: obj.active_user_limit,
    activeUsers: obj.active_user_count,
    status: obj.is_active ? "active" : "",
    settings: obj.account_settings
      ? {
          ...defaultSettings,
          defaultCohortId: obj.account_settings.default_cohort_id || null,
          groupTags: obj.account_settings.group_tags || {},
          accommodations: {
            assessmentTimeMultipliers:
              obj.account_settings.accommodations?.assessment_time_multipliers || [],
          },
        }
      : defaultSettings,
  };
}

export const fetchAccountUsers = ({
  selector,
  account,
  page = 1,
  pageSize = 10,
  appendRecords = false,
  resetData = true,
  userRole = "Student",
  implementUserHierarchy = false,
  includeInactiveUsers = false,
  searchParams,
}) => async (dispatch, getState) => {
  dispatch(
    request({
      selector,
      operation: OPERATIONS.LIST,
      resetData: resetData,
      isTableLoading: !!searchParams,
    })
  );

  try {
    const response = await getUsersForAccount(account, page, pageSize, searchParams);

    if (response.status === "success") {
      const UsersList = getUsersListingData(response.data.rows);
      await dispatch(
        list({
          selector,
          items: UsersList,
          page,
          count: response.data.count,
          pageSize,
          appendRecords,
        })
      );

      // Create selectedUser entry in redux state needed in ModalAddUsers.js
      const state = getState();
      const students = state.administration.users.filter(
        (user) =>
          ((implementUserHierarchy && contains(user.role.value, userHierarchy[userRole])) ||
            user.role.value === userRole) &&
          (includeInactiveUsers ? includeInactiveUsers : user.status == "active") &&
          !contains(
            user.email,
            state.administration.students.map((st) => {
              return st.email;
            })
          )
      );

      const formattedStudents = students.map((item) => ({
        id: item.id,
        selected: false,
        values: item,
      }));

      dispatch(
        createMultiple({
          items: formattedStudents,
          selector: "selectedUsers",
        })
      );

      return response;
    } else {
      if (response.status === "error") {
        dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.LIST,
            errorItem: response.data,
          })
        );
        return response;
      }
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.LIST,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

// Cohorts
export const getCohorts = ({
  page = 1,
  pageSize = 10,
  accountId = null,
  appendRecords = false,
} = {}) => {
  return async (dispatch, getState) => {
    const selector = "cohorts";
    const currentAccountId = accountId || getUserCurrentAccount(getState());
    dispatch(request({ selector, operation: OPERATIONS.GET, resetData: !appendRecords }));

    try {
      const response = await callGetCohortAPI(currentAccountId, page, pageSize);

      if (response.status === "success") {
        dispatch(
          list({
            selector,
            items: response.data.rows,
            count: response.data.count,
            page,
            pageSize,
            appendRecords,
          })
        );
        return response;
      } else {
        dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.GET,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
        })
      );
      return false;
    }
  };
};

export const getCohortDetailsById = ({ accountId, id }) => {
  const selector = "cohortDetails";
  return async (dispatch, getState) => {
    dispatch(request({ selector, operation: OPERATIONS.GET, resetData: true }));
    try {
      if (!accountId) {
        const state = getState();
        accountId = getUserCurrentAccount(state);
      }

      const response = await callGetCohortByIdAPI(accountId, id);

      if (response.status === "success") {
        dispatch(getDetails({ selector, details: response.data.rows }));
        return response;
      } else {
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.GET,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
        })
      );
      return false;
    }
  };
};

export const clearCohortDetails = () => (dispatch) => {
  dispatch(getDetails({ selector: "cohortDetails", details: [] }));
};

export const createCohort = ({ selector, accountId, item }) => async (dispatch) => {
  dispatch(request({ selector, operation: OPERATIONS.CREATE }));
  try {
    const request = buildCreateCohortRequest(item);
    const response = await callCreateCohortAPI(request, accountId);
    if (response.status === "success") {
      const createdCohort = decorateCohortResponse(response.data);
      dispatch(create({ item: createdCohort, selector }));
      return response;
    } else {
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.CREATE,
          errorItem: response.data,
        })
      );
      return response;
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.CREATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const updateCohort = ({ accountId, cohort, cohortId }) => async (dispatch) => {
  const selector = "cohorts";
  dispatch(request({ selector, operation: OPERATIONS.UPDATE }));
  try {
    const response = await callUpdateCohortAPI(
      convertKeysToUnderscore(cohort),
      cohortId,
      accountId
    );
    if (response.status === "success") {
      dispatch(update({ id: cohortId, updates: decorateCohortResponse(response.data), selector }));
      return response;
    } else {
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.UPDATE,
          errorItem: response.data,
        })
      );
      return response.data;
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.UPDATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

function buildCreateCohortRequest(item) {
  return {
    name: item.name,
    start_date: item.startDate,
    end_date: item.endDate,
    semester: item.semester.value,
    is_active: true,
  };
}

function decorateCohortResponse(obj) {
  return {
    id: obj.id,
    name: obj.name,
    start_date: obj.start_date,
    end_date: obj.end_date,
    account_id: obj.account_id,
    semester: obj.semester,
    is_active: obj.is_active,
    created_by: obj.created_by,
    date_created: obj.date_created,
    date_modified: obj.date_modified,
    modified_by: obj.modified_by,
    student_count: obj.student_count,
    createdByName: obj.createdByName,
    modifiedByName: obj.modifiedByName,
  };
}

export const fetchCohortStudents = ({
  selector,
  cohort,
  accountId,
  page = 1,
  pageSize,
  appendRecords = false,
  searchParams,
}) => async (dispatch) => {
  dispatch(request({ selector, operation: OPERATIONS.LIST }));

  try {
    const response = await getStudentsForCohort(cohort, accountId, page, pageSize, searchParams);

    if (response.status === "success") {
      const items = response.data.rows.map((item) => {
        return {
          ...item,
          studentId: item.studentId || "",
          year: {
            value: item.year || "",
            label: item.year ? item.year.charAt(0).toUpperCase() + item.year.slice(1) : "",
          },
          id: item.user_id,
        };
      });

      dispatch(
        list({
          selector,
          page,
          count: response.data.count,
          items,
          pageSize,
          appendRecords,
        })
      );

      return response;
    } else {
      if (response.status === "error") {
        dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.LIST,
            errorItem: response.data,
          })
        );
        return response;
      }
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.LIST,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
      })
    );
    return false;
  }
};

export const removeStudentFromCohort = ({
  id,
  userHash,
  cohortId,
  accountId,
  firstName,
  lastName,
  selector,
}) => async (dispatch) => {
  try {
    dispatch(request({ selector, operation: OPERATIONS.REMOVE, resetData: false }));
    const response = await removeCohortStudent(id, cohortId, accountId);
    console.log({ response });

    if (response.status === "success") {
      dispatch(remove({ id: id, selector }));

      if (response.data[0].cohort_count === 0) {
        return {
          deactivate: true,
          id,
          userHash,
          cohortId,
          accountId,
          firstName,
          lastName,
        };
      }

      return {
        deactivate: false,
        id,
        userHash,
        cohortId,
        accountId,
        firstName,
        lastName,
      };
    }

    if (response.status === "error") {
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.REMOVE,
          errorItem: response.data,
        })
      );

      return {
        deactivate: false,
        id,
        userHash,
        cohortId,
        accountId,
        firstName,
        lastName,
      };
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.REMOVE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
      })
    );
    return false;
  }
};

export const addUsersToCohorts = ({ selectedItems, cohortId, accountId, selector }) => async (
  dispatch
) => {
  try {
    dispatch(
      request({
        selector,
        operation: OPERATIONS.ATTACH_TO_ENTITY,
        resetData: false,
      })
    );
    const arrUserHashIds = selectedItems.map((user) => {
      return user.id;
    });
    const response = await addCohortStudent({
      arrUserHashIds,
      cohortId,
      accountId,
    });

    if (response.status === "success") {
      let successUsers = response.data.map((user) => {
        if (user.status === "success") {
          return user.userId;
        }
      });

      const successUsersItems = selectedItems.filter((item) => {
        if (contains(item.id, successUsers)) {
          return item;
        }
      });

      successUsers = successUsers.filter(Boolean);

      if (!successUsers.length) {
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.ATTACH_TO_ENTITY,
            errorItem: { message: COMMON_MESSAGES.ERROR_OCCURED },
          })
        );

        return false;
      } else {
        dispatch(
          createMultiple({
            items: successUsersItems.map((user) => {
              return {
                id: user.id,
                cohort_id: cohortId,
                user_id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                studentId: user.studentId,
                is_active: user.status === "active" ? true : false,
                role: user.role,
                year: user.year,
              };
            }),
            selector: "students",
          })
        );

        return true;
      }
    }
  } catch (e) {
    console.log("addUsersToCohort", { e });
    return false;
  }
};

// Deployments
export const getDeployments = ({
  selector,
  page = 1,
  pageSize,
  accountId,
  appendRecords = false,
}) => {
  return async (dispatch) => {
    dispatch(request({ selector, operation: OPERATIONS.LIST, resetData: !appendRecords }));

    try {
      const response = await callGetDeploymentsAPI({ page, pageSize, accountId });

      if (response.status === "success") {
        const deployments = decorateDeploymentListResponse({
          deployments: response.data.rows,
        });
        dispatch(
          list({
            selector,
            items: deployments,
            page,
            count: response.data.count,
            pageSize,
            appendRecords,
          })
        );
        return response;
      } else {
        dispatch(rejectPageNotFound({ errorCode: response.data.errorCode }));
        dispatch(
          reject({
            selector,
            operation: OPERATIONS.LIST,
            errorItem: response.data,
          })
        );
        return response;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        reject({
          selector,
          operation: OPERATIONS.LIST,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getPlacementAssessmentsByProgram = () => {
  return async (dispatch, getState) => {
    dispatch(requestPlacementAssessments({ operation: OPERATIONS.REQUEST }));

    try {
      await dispatch(getAllPrograms());

      const state = getState();
      const accountId = getUserCurrentAccount(state);
      const programIds = state.administration.programs.map((p) => p.id);

      const response = await Promise.all(
        programIds.map((programId) =>
          getPlacementAssessments({
            programId,
            accountId,
          })
        )
      );

      if (response && response.every((r) => r.status === "success")) {
        const data = response.map((r) => decorateAssessmentsForDeploymentForm(r.data)).flat();

        dispatch(
          receivePlacementAssessments({
            data,
          })
        );

        return response;
      } else {
        dispatch(
          rejectPlacementAssessments({
            operation: OPERATIONS.GET,
            errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
          })
        );

        return false;
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectPlacementAssessments({
          operation: OPERATIONS.UPDATE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const getBanner = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const accountId = getUserCurrentAccount(state);

      const response = await getBannerAPI(accountId);
      if (response.status === "success") {
        const banner = convertKeysToCamelCase(response && response.data);

        dispatch(receiveGetBanner(banner));

        return banner;
      }

      return response;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectGetBanner({
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const editBanner = (bannerData) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const accountId = getUserCurrentAccount(state);

      const response = await editBannerAPI({
        accountId,
        bannerData: {
          ...bannerData,
          displayStartTime: moment(bannerData.displayStartTime)
            .utc()
            .format(),
          displayEndTime: moment(bannerData.displayEndTime)
            .utc()
            .format(),
          mxStartTime: moment(bannerData.mxStartTime)
            .utc()
            .format(),
          mxEndTime: moment(bannerData.mxEndTime)
            .utc()
            .format(),
        },
      });
      if (response.status === "success") {
        const banner = convertKeysToCamelCase(response && response.data);
        dispatch(receiveGetBanner(banner));
      }
      return response;
    } catch (e) {
      console.log(e);
    }
  };
};
