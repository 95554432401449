/**
 * @ The external dependecies
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import FormCreateStudent from "components/form/FormCreateStudent";

/**
 * @class      ModalCreateStudent (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */
const ModalCreateStudent = (props) => {
  return (
    <ModalFrame>
      <FormCreateStudent />
    </ModalFrame>
  );
};

export default ModalCreateStudent;
