import {
  requestActionCourse,
  listActionCourse,
  rejectActionCourse,
  decorateSectionRequest,
  decorateSectionResponse,
} from "../actions";
import {
  getSectionsAPI,
  getSectionByIdAPI,
  createSectionAPI,
  updateSectionAPI,
} from "services/courses";
import { getUserCurrentAccount } from "utilities/commonFunctions";
import { OPERATIONS } from "constants/common";
import { COMMON_MESSAGES } from "constants/messages";

/**
 * ACTIONS
 */
export const getSections = ({ page = 1, pageSize = 10 } = {}) => {
  const selector = "sections";
  const operation = OPERATIONS.LIST;
  return async (dispatch, getState) => {
    const accountId = getUserCurrentAccount(getState());
    dispatch(
      requestActionCourse({
        selector,
        operation,
        resetData: false,
      })
    );

    try {
      const response = await getSectionsAPI({ accountId });
      if (response.status === "success") {
        dispatch(
          listActionCourse({
            selector,
            items: response.data.map(decorateSectionResponse),
          })
        );
        return response;
      } else {
        rejectActionCourse({
          selector,
          operation,
          errorItem: { message: response.data },
        });
      }
    } catch (e) {
      dispatch(
        rejectActionCourse({
          selector,
          operation,
          errorItem: { message: e.data },
        })
      );
    }
  };
};

export const getSectionById = ({ accountId, courseId }) => {
  const selector = "sections";

  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.GET }));

    try {
      const sectionResponse = await getSectionByIdAPI({ accountId, sectionId: courseId });

      sectionResponse.status &&
        sectionResponse.status === "success" &&
        dispatch(
          listActionCourse({
            selector,
            items: decorateSectionResponse(sectionResponse.data),
          })
        );

      return sectionResponse;
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectActionCourse({
          selector,
          operation: OPERATIONS.GET,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export const createSection = (formValue) => {
  const request = decorateSectionRequest(formValue);
  const selector = "sections";
  const rejectionPayloadTemplate = {
    selector,
    operation: OPERATIONS.CREATE,
    errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
  };
  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.CREATE }));
    const state = getState();
    const accountId = getUserCurrentAccount(state);
    try {
      const createSectionResponse = await createSectionAPI(request, accountId);
      createSectionResponse.status === "success"
        ? dispatch(
            listActionCourse({
              selector,
              items: decorateSectionResponse(createSectionResponse.data),
            })
          )
        : dispatch(
            rejectActionCourse({
              ...rejectionPayloadTemplate,
              errorItem: createSectionResponse.data,
            })
          );
      return createSectionResponse;
    } catch (e) {
      dispatch(
        rejectActionCourse({
          ...rejectionPayloadTemplate,
          errorItem: e,
        })
      );
      return false;
    }
  };
};

export const updateSection = (formValue) => {
  const request = decorateSectionRequest(formValue);
  const selector = "sections";

  return async (dispatch, getState) => {
    dispatch(requestActionCourse({ selector, operation: OPERATIONS.UPDATE }));
    const state = getState();
    const accountId = getUserCurrentAccount(state);
    const errorObj = {
      selector,
      operation: OPERATIONS.UPDATE,
      errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
    };

    try {
      const updateSectionResponse = await updateSectionAPI(request, formValue.id, accountId);
      updateSectionResponse.status === "success"
        ? dispatch(
            listActionCourse({
              selector,
              items: decorateSectionResponse(updateSectionResponse.data),
            })
          )
        : dispatch(
            rejectActionCourse(...errorObj, (errorObj.errorItem = updateSectionResponse.data))
          );
      return updateSectionResponse;
    } catch (e) {
      dispatch(rejectActionCourse(errorObj));
      return false;
    }
  };
};
