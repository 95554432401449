/**
 * The external dependencies.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Doughnut } from "react-chartjs-2";

/**
 * The internal dependencies.
 */
import { media } from "styles/media";
import chartRounderCornersBuilder from "lib/helpers/chart-rounded-corners-builder";
import chartCenteredTextBuilder from "lib/helpers/chart-centered-text-builder";

/**
 * This class describes a circle chart.
 *
 * @class CircleChart (name)
 */
class CircleChart extends Component {
  /**
   * The properties that can be passed to the component.
   *
   * @type {Object}
   */
  static propTypes = {
    value: PropTypes.number.isRequired,
    size: PropTypes.number,
    maxValue: PropTypes.number,
    valueType: PropTypes.oneOf(["percentage", "numeric"]),
    textFontSize: PropTypes.number,
  };

  /**
   * The default properties of the component.
   *
   * @type {Object}
   */
  static defaultProps = {
    size: 205,
    maxValue: 100,
    valueType: "percentage",
    textFontSize: 43,
  };

  /**
   * Constructs a new instance.
   *
   * @param {Object} The properties
   */
  constructor(props) {
    super(props);

    chartRounderCornersBuilder();
    chartCenteredTextBuilder();

    this.state = {
      data: {
        datasets: [
          {
            data: [props.value, props.maxValue - props.value],
            borderWidth: 0,
            backgroundColor: [props.color, "#DBECF8"],
            hoverBorderColor: [props.color, "#DBECF8"],
            hoverBackgroundColor: [props.color, "#DBECF8"],
          },
        ],
      },
      options: {
        elements: {
          arc: {
            roundedCornersFor: 0,
          },
          center: {
            maxText: this.renderMaxText(),
            text: this.renderText(),
            fontColor: "#4A4A4A",
            fontFamily: "'Circular Pro', 'Helvetica', 'Arial', sans-serif",
            fontStyle: "600",
            fontSize: props.textFontSize,
            minFontSize: 1,
            maxFontSize: 256,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      },
    };
    this.chartReference = React.createRef();
  }

  /**
   * Life-cycle hook. Called on component's first mount
   *
   */
  componentDidMount() {
    this.createDataset();
  }

  /**
   * Life-cycle hook. Called on compoent's update (props change)
   *
   * @param {Object} The previous properties
   */
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.createDataset();
    }
  }

  componentWillUnmount() {
    this.chartReference.chartInstance.destroy();
  }

  /**
   * Performs setState
   *
   */
  createDataset = () => {
    this.setState({
      data: {
        datasets: [
          {
            data: [this.props.value, this.props.maxValue - this.props.value],
            borderWidth: 0,
            backgroundColor: [this.props.color, "#DBECF8"],
            hoverBorderColor: [this.props.color, "#DBECF8"],
            hoverBackgroundColor: [this.props.color, "#DBECF8"],
          },
        ],
      },
      options: {
        elements: {
          arc: {
            roundedCornersFor: 0,
          },
          center: {
            maxText: this.renderMaxText(),
            text: this.renderText(),
            fontColor: "#4A4A4A",
            fontFamily: "'Circular Pro', 'Helvetica', 'Arial', sans-serif",
            fontStyle: "600",
            fontSize: this.props.textFontSize,
            minFontSize: 1,
            maxFontSize: 256,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  };

  /**
   * Render the max text displayed
   *
   * @return {String}
   */
  renderMaxText() {
    if (this.props.valueType === "numeric") {
      return `${this.props.maxValue}`;
    }

    return `${this.props.maxValue}%`;
  }

  /**
   * Render the text based on the passed type.
   *
   * @return {String}
   */
  renderText() {
    const value = parseInt(this.props.value);

    if (this.props.valueType === "numeric") {
      return `${value}/${this.props.maxValue}`;
    }

    return `${value}%`;
  }

  /**
   * The render method of the component.
   *
   * @return {Object}
   */
  render() {
    const {
      state: { data, options },
      props: { size, value, valueType },
    } = this;
    return (
      <ChartWrapper>
        <Doughnut
          ref={(reference) => (this.chartReference = reference)}
          data={data}
          height={size}
          options={options}
        />
      </ChartWrapper>
    );
  }
}

const ChartWrapper = styled.div`
  position: relative;
  pointer-events: none;
  user-select: none;

  ${media.tablet_portrait`
    margin-bottom: 20px;
    max-width: 320px;
    margin: 0 auto;
  `}
`;

export default CircleChart;
