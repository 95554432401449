import React, { Component } from "react";
import { connect } from "react-redux";
import { sortBy } from "ramda";
import { withRouter } from "react-router-dom";
import moment from "moment";

import chartSliderSettings from "styles/slider-configs";
import sliderConfigs from "lib/slider-configs/base";
import stemifyRoutes from "constants/routes";
import {
  progressWheelColorRange,
  CalculateProgressIndicatorColor,
  OPERATIONS,
  ATTEMPT_STATUSES,
} from "constants/common";
import { MODAL_COOLDOWN_PERIOD, MODAL_DASHBOARD_RESUME_ATTEMPT } from "lib/constants";
import Section, { SlickSlider, Grid, Card } from "styles/components/studentDashboard";
import CircleChart from "components/chart/CircleChart";
import AssessmentCard from "./AssessmentCard";
import NoAssessmentView from "./NoAssessmentView";
import CourseItem from "components/courses/CourseItem";
import { openModal } from "store/state/ui/actions";
import { getAttempts, clearAttempt } from "store/state/assessment/actions";
import { getAssignments, getSchedules } from "store/state/courses/actions";

class Intro extends Component {
  state = {
    colorRange: progressWheelColorRange,
  };

  componentDidMount = async () => {
    const { sections, getAssignments, getSchedules, getAttempts, openModal } = this.props;
    const sectionIdsArray = sections.map((section) => section.id).sort();

    getAssignments({ sectionIds: sectionIdsArray });
    getSchedules({ sectionIds: sectionIdsArray });
    /*
     *const openAttempts = await getAttempts({ status: ATTEMPT_STATUSES.OPEN });
     *if (openAttempts.length) {
     *  const openAttempt = openAttempts[0];
     *  openModal({
     *    type: MODAL_DASHBOARD_RESUME_ATTEMPT,
     *    data: {
     *      attemptId: openAttempt.id,
     *      assessmentSeriesId: openAttempt.assessmentSeriesId,
     *      deploymentId: openAttempt.deploymentId,
     *      assignemntId: openAttempt.assignmentId,
     *    },
     *  });
     *}
     */
  };

  viewResultClickHandler = (assessment) => {
    const { history } = this.props;
    const { id: assessmentSeriesId, deployment_id: deploymentId } = assessment;

    history.push(
      `${stemifyRoutes.studentPerformanceDetails}/${deploymentId}/${assessmentSeriesId}`
    );
  };

  takeAssessmentClickHandler = ({
    id,
    deployment_id,
    deployment_config_id,
    next_attempt_date: nextAttemptDate,
    assessment_name,
  }) => {
    const { openModal, clearAttempt, history } = this.props;

    // If current date is before next attempt date show a modal warning for cooling period
    if (moment().isBefore(nextAttemptDate, "day")) {
      openModal({
        type: MODAL_COOLDOWN_PERIOD,
        data: {
          nextAttemptDate,
          assessmentName: assessment_name,
        },
      });
    } else {
      clearAttempt();

      // Otherwise allow route to assessments
      history.push(`${stemifyRoutes.assessments}/${deployment_config_id}/${id}`);
    }
  };

  render() {
    const {
      props: { assessmentList, topicInfoList, sections, assignments, schedules },
      state: { colorRange },
    } = this;

    const pathToProgress = topicInfoList.map((item) => {
      return {
        ...item,
        url: `/learning-library?id=${item.topic_id}&type=${item.type}`,
      };
    });
    // Sorting to show progress card from lowest to highest percent_score
    const myPathToProgress = sortBy((obj) => obj.percent_score, pathToProgress);

    return (
      <React.Fragment>
        <Section>
          <Section.Head>
            <Section.HeadContent>
              <Section.Title>My Assessments</Section.Title>
            </Section.HeadContent>
          </Section.Head>

          <Section.Body id="assessmentCardList">
            <SlickSlider {...sliderConfigs}>
              {assessmentList.map((assessment, index) => (
                <AssessmentCard
                  key={index}
                  assessmentDetails={assessment}
                  viewResultClickHandler={() => this.viewResultClickHandler(assessment)}
                  takeAssessmentClickHandler={() => this.takeAssessmentClickHandler(assessment)}
                  fullWidth
                />
              ))}
            </SlickSlider>
          </Section.Body>
        </Section>
        <Section>
          <Section.Head>
            <Section.HeadContent>
              <Section.Title>Courses</Section.Title>
            </Section.HeadContent>
          </Section.Head>

          <Section.Body id="assessmentCardList">
            <SlickSlider {...sliderConfigs}>
              {sections.map((section) => {
                const filteredAssignments = assignments.filter(
                  (assignment) => assignment.sectionId === section.id
                );
                const filteredSchedules = schedules.filter(
                  (schedule) =>
                    schedule.sectionId === section.id && moment(schedule.endTs).isAfter(moment())
                );

                return (
                  <CourseItem
                    isStudentView
                    fullWidth
                    onEdit={() => {}}
                    onDelete={() => {}}
                    onCopy={() => {}}
                    data={{
                      ...section,
                      assignments: filteredAssignments,
                      schedules: filteredSchedules,
                    }}
                    key={section.id}
                  />
                );
              })}
            </SlickSlider>
          </Section.Body>
        </Section>
        {myPathToProgress.length === 0 ? (
          <NoAssessmentView />
        ) : (
          <Section>
            <Grid>
              <Grid.Col size="100">
                <Section.Head>
                  <Section.HeadContent>
                    <Section.Title>Topic Progress</Section.Title>
                  </Section.HeadContent>
                </Section.Head>

                <Section.Body id="myStudyPlan">
                  <SlickSlider {...chartSliderSettings}>
                    {myPathToProgress.map((item) => (
                      <Card key={item.topic_name} small minHeight="299">
                        <Card.Body>
                          <Card.Inner>
                            <CircleChart
                              size={170}
                              textFontSize={31}
                              value={!!item.percent_score ? parseInt(item.percent_score) : 0}
                              color={CalculateProgressIndicatorColor(
                                item.percent_score,
                                colorRange
                              )}
                            />
                          </Card.Inner>

                          <Card.SubTitle>{item.topic_name}</Card.SubTitle>
                        </Card.Body>

                        <Card.Foot>
                          <Card.Actions>
                            <Card.Link to={item.url}>Review</Card.Link>
                          </Card.Actions>
                        </Card.Foot>
                      </Card>
                    ))}
                  </SlickSlider>
                </Section.Body>
              </Grid.Col>
            </Grid>
          </Section>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      assessmentList: state.studentDashboard.DashboardAssessmentList.assessments,
      topicInfoList: state.studentDashboard.DashboardAssessmentList.topicInfo,
      apiError: state.studentDashboard.apiErrors.DashboardAssessmentList[OPERATIONS.GET],
      sections: Object.values(state.storedCourses.sections),
      assignments: Object.values(state.storedCourses.assignments),
      schedules: Object.values(state.storedCourses.schedules),
      openAttempts: Object.values(state.assessment.attempts)
        .filter((attempt) => attempt.status === ATTEMPT_STATUSES.OPEN)
        .sort((a, b) => moment(a.start_time) - moment(b.start_time)),
    }),
    {
      openModal,
      getAssignments,
      getSchedules,
      getAttempts,
      clearAttempt,
    }
  )(Intro)
);
