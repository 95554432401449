/**
 * @ External Dependencies
 */
import { createAction } from "redux-actions";
import {
  callGetSignedURLByTokenAPI,
  callGetvideoQuizDataAPI,
  callAddQuizResponseAPI,
  resetVideoQuizDataAPI,
} from "services/video";
import { decorateQnAResponse } from "utilities/commonFunctions";
/**
 * @ Action creators
 */
export const requestSignedURL = createAction("REQUEST_SIGNED_URL");
export const getSignedURL = createAction("GET_SIGNED_URL");
export const rejectGetSignedURL = createAction("REJECT_GET_SIGNED_URL");
export const skipCuePoint = createAction("SKIP_VIDEO_QUIZ_QUESTION");

export const requestVideoURL = createAction("REQUEST_VIDEO_URL");
export const getVideoURL = createAction("REQUEST_VIDEO_URL");
export const rejectGetVideoURL = createAction("REJECT_GET_VIDEO_URL");
export const setQuizData = createAction("SET_QUIZ_DATA");
export const requestUpdateQuestionAttempt = createAction("REQUEST_UPDATE_QUESTION_ATTEMPT");
export const updateQuestionAttempt = createAction("UPDATE_QUESTION_ATTEMPT");
export const rejectUpdateQuestionAttempt = createAction("REJECT_UPDATE_QUESTION_ATTEMPT");
export const clearCuePoints = createAction("CLEAR_CUE_POINTS");

export const getSignedURLByToken = ({ mediaId }) => async (dispatch) => {
  dispatch(requestSignedURL());
  try {
    let request = buildGetSignedURLByTokenRequest(mediaId);
    let response = await callGetSignedURLByTokenAPI(request);
    if (response.status === "success") {
      dispatch(getSignedURL());
      return response;
    } else {
      dispatch(rejectGetSignedURL());
      return response;
    }
  } catch (e) {
    dispatch(rejectGetSignedURL());
    return false;
  }
};

function buildGetSignedURLByTokenRequest(mediaId) {
  return {
    resource: "/v2/media",
    mediaId: mediaId,
    sources: "hls,dash,mp4",
    //'poster_width':400
  };
}

export const getVideoURLResult = ({ signedURL }) => (dispatch) => {
  var result;
  dispatch(requestVideoURL());
  try {
    result = fetch(signedURL).then((res) => res.json());
    dispatch(getVideoURL());
    return result;
  } catch (e) {
    console.log(e.message);
    dispatch(rejectGetVideoURL());
    return false;
  }
};

export const submitQuizAnswer = ({
  question_id,
  answer_id,
  quiz_id,
  trackVideoProgress,
  isCorrect,
}) => async (dispatch) => {
  try {
    if (trackVideoProgress) {
      dispatch(requestUpdateQuestionAttempt());

      const response = await callAddQuizResponseAPI({
        question_id,
        answer_id,
        quiz_id,
      });

      dispatch(
        updateQuestionAttempt({
          id: response.data.question_id,
          isAnswered: response.data.is_correct,
          attemptCount: response.data.attempt_count,
          updateAPIProgress: true,
        })
      );
      return response;
    } else {
      dispatch(
        updateQuestionAttempt({
          id: question_id,
          isAnswered: isCorrect,
          attemptCount: +1,
          updateAPIProgress: false,
        })
      );
      return true;
    }
  } catch (e) {
    console.log(e);
    dispatch(rejectUpdateQuestionAttempt());
  }
};

export const resetVideoQuiz = ({ videoId }) => async (dispatch) => {
  try {
    const response = await resetVideoQuizDataAPI({ videoId });
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const getQuizData = ({ videoId }) => async (dispatch, getState) => {
  try {
    const response = await callGetvideoQuizDataAPI({ videoId });
    const state = getState();
    var res = response.data;
    if (response.status == "success") {
      var resp = decorateQnAResponse(response.data);
      res = resp;
      dispatch(
        setQuizData({
          selector: "cuePoints",
          quizId: resp.id,
          items: resp.questions,
        })
      );
    }
    return { ...response, data: res };
  } catch (e) {
    console.log(e);
  }
};
