/**
 * @ The supported modal types
 */
export const MODAL_CREATE_ACCOUNT = "ModalCreateAccount";
export const MODAL_CREATE_USER = "ModalCreateUser";
export const MODAL_CREATE_COHORT = "ModalCreateCohort";
export const MODAL_CREATE_STUDENT = "ModalCreateStudent";
export const MODAL_REMOVE_STUDENT = "ModalRemoveStudent";
export const MODAL_ACTIVATION = "ModalActivation";
export const MODAL_WIZARD = "ModalWizard";
export const MODAL_USER_LIMIT_REACHED = "ModalUserLimitReached";
export const MODAL_FORM_DEPLOYMENT_CANCELLATION = "ModalDeploymentCancellation";
export const MODAL_ARCHIVE = "ModalArchive";
export const MODAL_ADD_USERS = "ModalAddUsers";
export const MODAL_ASSESSMENT_PASSCODE = "ModalAssessmentPasscode";
export const MODAL_ASSESSMENT_SUBMIT = "ModalAssessmentSubmit";
export const MODAL_ASSESSMENT_RESUME = "ModalAssessmentResume";
export const MODAL_ASSESSMENT_TIME_END = "ModalAssessmentTimeEnd";
export const MODAL_SHOW_ERROR_MESSAGE = "ModalShowErrorMessage";
export const MODAL_COOLDOWN_PERIOD = "ModalCooldownPeriod";
export const MODAL_SUBMIT_ASSESSMENT_GUIDE = "ModalAssessmentSubmitGuide";

export const MODAL_CREATE_COURSE = "ModalCreateSection";
export const MODAL_CONFIRMATION = "ModalConfirmation";
export const MODAL_CREATE_COURSE_UNIT = "ModalCreateCourseUnit";
export const MODAL_REMOVE_SELECTED = "ModalRemoveSelected";
export const MODAL_CREATE_BUILDER_ASSESSMENT = "ModalCreateBuilderAssessment";
export const MODAL_DELETE_BUILDER_QUESTION = "ModalDeleteBuilderQuestion";
export const MODAL_DASHBOARD_RESUME_ATTEMPT = "ModalDashboardResumeAttempt";

export const MODAL_IMAGE_MANAGER = "ModalImageManager";
export const MODAL_NO_NETWORK = "ModalShowNoNetworkErrorMessage";

/**
 * @ The supported wizard types
 */
export const WIZARD_PROGRAMS = "WizardPrograms";
export const WIZARD_DEPLOYMENT = "WizardDeployment";

/**
 * @ The supported wizard step types
 */
export const WIZARD_PROGRAM_TYPE = "ProgramType";

export const WIZARD_PROGRAM_ASSESSEMENT = "ProgramAssessment";
export const WIZARD_PROGRAM_ASSESSEMENT_FROM_TEMPLATE = "ProgramAssessmentFromTemplate";

export const WIZARD_PROGRAM_CONTENT = "ProgramContent";
export const WIZARD_PROGRAM_CONTENT_FROM_TEMPLATE = "ProgramContentFromTemplate";

export const WIZARD_CREATE_DEPLOYMENT = "CreateDeployment";
export const WIZARD_DEPLOYMENT_SUMMARY = "DeploymentSummary";
