import React, { Component } from "react";
import { FormattedText } from "../common/FormattedText";
import { IMAGE_S3_SERVER } from "constants/api";
import {
  AssessmentAnswerContainer,
  AssessmentAnswer,
  AnswerLabel,
  AnswerInput,
  AnswerImage,
  AnswerText,
  OptionImage,
  VideoOptionImage,
} from "styles/components/AssessmentQuestions";

class AnswerOptionsContainer extends Component {
  state = {
    focusAnswer: 0,
  };

  radioClicked = (e) => {
    if (e.key == "Enter") {
      return this.props.onInputChange(e);
    }
  };

  render() {
    const { type, isVideoQuiz } = this.props;
    return this.props.answers.map((answer) => {
      const { id, displayType } = answer;
      return (
        <AssessmentAnswerContainer
          key={id}
          displayType={displayType}
          dataCount={answer.description.length}
          focusOn={id == this.state.focusAnswer}
        >
          <AssessmentAnswer
            displayType={displayType}
            quizType={type}
            onlyText={
              answer.description.find((i) => i.type == "TEXT") && answer.description.length == 1
            }
            focusOn={id == this.state.focusAnswer}
            dataCount={answer.description.length}
          >
            <AnswerInput
              type="radio"
              name={`answer-${id}`}
              value={id}
              id={id}
              onFocus={() => {
                this.setState({ focusAnswer: id });
              }}
              onBlur={() => {
                this.setState({ focusAnswer: "" });
              }}
              onChange={(e) => this.props.onInputChange(e)}
              checked={this.props.isChecked(id) ? true : false}
              onKeyPress={(e) => this.radioClicked(e)}
            />
            <AnswerLabel
              focusOn={id == this.state.focusAnswer}
              htmlFor={id}
              displayType={displayType}
              quizType={type}
              layout={answer.layout}
            >
              <AnswerImage>
                {answer.description &&
                  answer.description.map((data, k) => {
                    if (data.type === "TEXT") {
                      return (
                        <AnswerText
                          key={data.text}
                          layout={answer.layout}
                          widthCounter={answer.description.length}
                          floatCounter={k}
                        >
                          <FormattedText text={data.text}></FormattedText>
                        </AnswerText>
                      );
                    } else {
                      return isVideoQuiz ? (
                        <VideoOptionImage
                          widthCounter={answer.description.length}
                          layout={answer.layout}
                          dataCount={answer.description.length}
                          checked={this.props.isChecked(id) ? true : false}
                        >
                          <img
                            key={data.src}
                            src={`${IMAGE_S3_SERVER}${data.src}`}
                            alt={data.src}
                          />
                        </VideoOptionImage>
                      ) : (
                        <OptionImage
                          widthCounter={answer.description.length}
                          layout={answer.layout}
                        >
                          {data.src.trim() ? (
                            <img
                              key={data.src}
                              src={`${IMAGE_S3_SERVER}${data.src}`}
                              alt={data.src}
                            />
                          ) : null}
                        </OptionImage>
                      );
                    }
                  })}
              </AnswerImage>
            </AnswerLabel>
          </AssessmentAnswer>
        </AssessmentAnswerContainer>
      );
    });
  }
}
export default AnswerOptionsContainer;
