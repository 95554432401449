import { userActivityPath } from "../constants/api";
import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";

export const saveUserActivity = async ({
  type,
  id,
  seekDuration,
  quizId,
  cuepoint,
  action,
  isComplete,
  status,
}) => {
  var body =
    type === "VIDEO"
      ? [
          {
            type: type,
            id: id,
            seek_duration: seekDuration,
            quizId,
            cuepoint,
            action,
          },
        ]
      : [{ type: type, id: id, quizId, cuepoint, action, status }];

  if (isComplete) {
    //Mark complete only when isComplete is true so that already completed entity (video/assignment/module) is never made incomplete
    body[0].is_complete = isComplete;
  }

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: userActivityPath,
    method: "post",
    headers: {
      Authorization: getAccessToken(),
    },
    body: JSON.stringify({ activity: body }),
  });
};
