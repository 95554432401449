import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";
import DrawerLayout from "components/layouts/DrawerLayout";
import { connect } from "react-redux";
import Loader from "components/common/loader";
import MediaQuery from "react-responsive";
import IconSvgComponent from "components/common/IconSvgComponent";
import { getLearningUnit } from "store/state/learningLibrary/actions";
import { JW_ASSET_BASEURL } from "constants/api";
import Card, {
  CardHead,
  CardTitle,
  CardLink,
  CardBody,
  CardEntry,
  CardHolder,
  CardColumns,
  CardColumn,
  CardVideo,
  CardVideoButton,
  CardContent,
  CardSubtitle,
  CardListIcons,
  CardListIconsItem,
  CardContentList,
  CardListDescription,
} from "styles/components/Card";
import ButtonLink from "../common/ButtonLink";
import { NoDataMessageContainer, NoDataMessage } from "styles/components/Card";
import { FormattedText } from "../common/FormattedText";
import queryString from "query-string";

class LearningUnit extends Component {
  componentDidMount() {
    const { getLearningUnit, match, isFilteredByTopic } = this.props;
    const { unitId } = match.params;

    if (!isFilteredByTopic) getLearningUnit({ unitId });
  }

  render() {
    const { learningUnit, apiCallInProgress, isFilteredByTopic, match } = this.props;
    var practiceAssignmentUrl = match.url;
    if (isFilteredByTopic) {
      practiceAssignmentUrl = practiceAssignmentUrl + "/learning-module";
    }
    const recentUnits =
      learningUnit &&
      learningUnit.learning_modules &&
      learningUnit.learning_modules.filter((item) => item.is_recent === true);

    const drawerTitle = isFilteredByTopic ? "Topic Contents" : "Module Contents";

    return learningUnit && learningUnit.learning_modules ? (
      <DocumentTitle title={`Stemify | ${learningUnit.name}`}>
        <div>
          <MediaQuery maxWidth={768}>
            <DrawerLayout data={learningUnit.learning_modules} title={drawerTitle} isOpen={false}>
              {recentUnits &&
                recentUnits.map(
                  (
                    { id, url, name, description, assignments, worksheets, is_recent, videos },
                    idx
                  ) => (
                    <Card key={`featured-${url}`} id={id}>
                      <CardHead noBorder="true">
                        <CardTitle>Recent Module</CardTitle>
                      </CardHead>
                      <CardBody>
                        <CardHolder>
                          <CardColumns>
                            <CardColumn size="40%">
                              <ButtonLink
                                isCardVideoLink
                                to={{
                                  pathname: url,
                                  state: { autoPlay: 1 },
                                }}
                              >
                                <CardVideo
                                  image={`${JW_ASSET_BASEURL +
                                    "/thumbs/" +
                                    videos[0].external_id +
                                    "-480.jpg"}`}
                                  large
                                >
                                  <CardVideoButton size="108" />
                                </CardVideo>
                              </ButtonLink>
                            </CardColumn>
                            <CardColumn size="60%">
                              <CardColumn flex spaceBetween>
                                <CardTitle paddingRight>
                                  <FormattedText text={name}></FormattedText>
                                </CardTitle>
                                <CardLink
                                  minwidth
                                  to={{
                                    pathname: url,
                                  }}
                                >
                                  Open Module
                                </CardLink>
                              </CardColumn>

                              <CardColumns flexWrap padding="0 32px 0 0">
                                <CardColumn size="50%">
                                  <CardContent>
                                    <CardSubtitle>Video</CardSubtitle>
                                    <CardListIcons horizontal noSpace sizeSm>
                                      <ButtonLink
                                        isCardOrderedListLink
                                        smallgap="smallgap"
                                        to={{
                                          pathname: url,
                                          state: { autoPlay: true },
                                        }}
                                      >
                                        <CardListIconsItem>
                                          <IconSvgComponent iconPath="svg/ico-angle-play.svg" />
                                          <span>Resume</span>
                                        </CardListIconsItem>
                                      </ButtonLink>

                                      <ButtonLink
                                        isCardOrderedListLink
                                        smallgap="smallgap"
                                        to={{
                                          pathname: url,
                                          state: {
                                            autoPlay: true,
                                            seekDuration: 0,
                                          },
                                        }}
                                      >
                                        <CardListIconsItem>
                                          <IconSvgComponent iconPath="svg/ico-replay.svg" />
                                          <span>Start from beginning</span>
                                        </CardListIconsItem>
                                      </ButtonLink>
                                    </CardListIcons>
                                  </CardContent>
                                </CardColumn>
                                <CardColumn size="50%">
                                  <CardContent>
                                    <CardSubtitle>Practice Assignments</CardSubtitle>
                                    <CardListIcons horizontal large>
                                      {assignments && assignments.length > 0 ? (
                                        assignments.map((assignment, idx) => (
                                          <CardListIconsItem large space key={idx}>
                                            <ButtonLink
                                              isCardRedirectLink
                                              to={`${practiceAssignmentUrl}/${id}/${assignment.id}`}
                                            >
                                              <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                              <span>{assignment.name}</span>
                                            </ButtonLink>
                                          </CardListIconsItem>
                                        ))
                                      ) : worksheets && worksheets.length > 0 ? (
                                        worksheets.map(({ id, file_location, title }, idx) => (
                                          <CardListIconsItem large space key={idx}>
                                            <ButtonLink
                                              isCardDownloadLink
                                              target="_blank"
                                              href={file_location}
                                            >
                                              <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                              <span>{title}</span>
                                            </ButtonLink>
                                          </CardListIconsItem>
                                        ))
                                      ) : (
                                        <span>Coming Soon</span>
                                      )}
                                    </CardListIcons>
                                  </CardContent>
                                </CardColumn>
                                <CardColumn size="100%">
                                  <CardContent>
                                    <CardSubtitle>Learning Objectives</CardSubtitle>
                                    <CardListDescription custombullets>
                                      {description.map((objective) => (
                                        <CardContentList large key={objective}>
                                          <IconSvgComponent
                                            iconPath="svg/ico-dblue-check.svg"
                                            additionalClass="learning-unit-learning-objectives-icon"
                                          />
                                          <FormattedText text={objective}></FormattedText>
                                        </CardContentList>
                                      ))}
                                    </CardListDescription>
                                  </CardContent>
                                </CardColumn>
                              </CardColumns>
                            </CardColumn>
                          </CardColumns>
                        </CardHolder>
                      </CardBody>
                    </Card>
                  )
                )}

              {learningUnit &&
                learningUnit.learning_modules.map(
                  (
                    { id, url, name, description, assignments, worksheets, is_recent, videos },
                    idx
                  ) => (
                    <Card key={idx} id={id}>
                      <CardHead>
                        <CardTitle>
                          {idx + 1}. <FormattedText text={name}></FormattedText>
                        </CardTitle>
                        <CardLink
                          to={{
                            pathname: url,
                          }}
                        >
                          Open Module
                        </CardLink>
                      </CardHead>
                      <CardBody>
                        <CardHolder>
                          <CardColumns>
                            <CardColumn flex sizeLg="50%">
                              <ButtonLink
                                isCardOrderedListLink
                                to={{
                                  pathname: url,
                                  state: { autoPlay: true },
                                }}
                              >
                                <CardVideo
                                  image={`${JW_ASSET_BASEURL +
                                    "/thumbs/" +
                                    videos[0].external_id +
                                    "-320.jpg"}`}
                                >
                                  <CardVideoButton size="56" />
                                </CardVideo>
                              </ButtonLink>
                              <CardContent>
                                <CardSubtitle>Video</CardSubtitle>
                                <CardListIcons>
                                  <CardListIconsItem>
                                    <ButtonLink
                                      isCardOrderedListLink
                                      to={{
                                        pathname: url,
                                        state: { autoPlay: true },
                                      }}
                                    >
                                      <IconSvgComponent iconPath="svg/ico-angle-play.svg" />
                                      {videos[0].seek_duration !== 0 ? (
                                        <span>Resume</span>
                                      ) : (
                                        <span>Start Video</span>
                                      )}
                                    </ButtonLink>
                                  </CardListIconsItem>
                                </CardListIcons>
                              </CardContent>
                            </CardColumn>

                            <CardColumn sizeLg="50%">
                              <CardContent>
                                <CardSubtitle>Practice Assignments</CardSubtitle>
                                <CardListIcons large>
                                  {assignments && assignments.length > 0 ? (
                                    assignments.map((assignment, idx) => (
                                      <CardListIconsItem large key={idx}>
                                        <ButtonLink
                                          isCardRedirectLink
                                          to={`${practiceAssignmentUrl}/${id}/${assignment.id}`}
                                        >
                                          <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                          <span>{assignment.name}</span>
                                        </ButtonLink>
                                      </CardListIconsItem>
                                    ))
                                  ) : worksheets && worksheets.length > 0 ? (
                                    worksheets.map(({ id, file_location, title }, idx) => (
                                      <CardListIconsItem large key={idx}>
                                        <ButtonLink
                                          isCardDownloadLink
                                          target="_blank"
                                          href={file_location}
                                        >
                                          <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                          <span>{title}</span>
                                        </ButtonLink>
                                      </CardListIconsItem>
                                    ))
                                  ) : (
                                    <span>Coming Soon</span>
                                  )}
                                </CardListIcons>
                              </CardContent>
                            </CardColumn>
                            <CardColumn sizeLg="100%">
                              <CardContent>
                                <CardSubtitle>Learning Objectives</CardSubtitle>
                                <CardListDescription custombullets>
                                  {description.map((objective) => (
                                    <CardContentList large key={objective}>
                                      <IconSvgComponent
                                        iconPath="svg/ico-dblue-check.svg"
                                        additionalClass="learning-unit-learning-objectives-icon"
                                      />
                                      <FormattedText text={objective}></FormattedText>
                                    </CardContentList>
                                  ))}
                                </CardListDescription>
                              </CardContent>
                            </CardColumn>
                          </CardColumns>
                        </CardHolder>
                      </CardBody>
                    </Card>
                  )
                )}
            </DrawerLayout>
          </MediaQuery>
          <MediaQuery minWidth={769}>
            <DrawerLayout data={learningUnit.learning_modules} title={drawerTitle} isOpen={true}>
              {recentUnits &&
                recentUnits.map(
                  (
                    { id, url, name, description, assignments, worksheets, is_recent, videos },
                    idx
                  ) => (
                    <Card key={`featured-${url}`} id={id}>
                      <CardHead noBorder="true">
                        <CardTitle>Recent Module</CardTitle>
                      </CardHead>
                      <CardBody>
                        <CardHolder>
                          <CardColumns>
                            <CardColumn size="40%">
                              <ButtonLink
                                isCardVideoLink
                                to={{
                                  pathname: url,
                                  state: { autoPlay: 1 },
                                }}
                              >
                                <CardVideo
                                  image={`${JW_ASSET_BASEURL +
                                    "/thumbs/" +
                                    videos[0].external_id +
                                    "-480.jpg"}`}
                                  large
                                >
                                  <CardVideoButton size="108" />
                                </CardVideo>
                              </ButtonLink>
                            </CardColumn>
                            <CardColumn size="60%">
                              <CardColumn flex spaceBetween>
                                <CardTitle paddingRight>
                                  <FormattedText text={name}></FormattedText>
                                </CardTitle>
                                <CardLink
                                  minwidth
                                  to={{
                                    pathname: url,
                                  }}
                                >
                                  Open Module
                                </CardLink>
                              </CardColumn>

                              <CardColumns flexWrap padding="0 32px 0 0">
                                <CardColumn size="50%">
                                  <CardContent>
                                    <CardSubtitle>Video</CardSubtitle>
                                    <CardListIcons horizontal noSpace sizeSm>
                                      <ButtonLink
                                        isCardOrderedListLink
                                        smallgap="smallgap"
                                        to={{
                                          pathname: url,
                                          state: { autoPlay: true },
                                        }}
                                      >
                                        <CardListIconsItem>
                                          <IconSvgComponent iconPath="svg/ico-angle-play.svg" />
                                          <span>Resume</span>
                                        </CardListIconsItem>
                                      </ButtonLink>

                                      <ButtonLink
                                        isCardOrderedListLink
                                        smallgap="smallgap"
                                        to={{
                                          pathname: url,
                                          state: {
                                            autoPlay: true,
                                            seekDuration: 0,
                                          },
                                        }}
                                      >
                                        <CardListIconsItem>
                                          <IconSvgComponent iconPath="svg/ico-replay.svg" />
                                          <span>Start from beginning</span>
                                        </CardListIconsItem>
                                      </ButtonLink>
                                    </CardListIcons>
                                  </CardContent>
                                </CardColumn>
                                <CardColumn size="50%">
                                  <CardContent>
                                    <CardSubtitle>Practice Assignments</CardSubtitle>
                                    <CardListIcons horizontal large>
                                      {assignments && assignments.length > 0 ? (
                                        assignments.map((assignment, idx) => (
                                          <CardListIconsItem large space key={idx}>
                                            <ButtonLink
                                              isCardRedirectLink
                                              to={`${practiceAssignmentUrl}/${id}/${assignment.id}`}
                                            >
                                              <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                              <span>{assignment.name}</span>
                                            </ButtonLink>
                                          </CardListIconsItem>
                                        ))
                                      ) : worksheets && worksheets.length > 0 ? (
                                        worksheets.map(({ id, file_location, title }, idx) => (
                                          <CardListIconsItem large space key={idx}>
                                            <ButtonLink
                                              isCardDownloadLink
                                              target="_blank"
                                              href={file_location}
                                            >
                                              <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                              <span>{title}</span>
                                            </ButtonLink>
                                          </CardListIconsItem>
                                        ))
                                      ) : (
                                        <span>Coming Soon</span>
                                      )}
                                    </CardListIcons>
                                  </CardContent>
                                </CardColumn>
                                <CardColumn size="100%">
                                  <CardContent>
                                    <CardSubtitle>Learning Objectives</CardSubtitle>
                                    <CardListDescription custombullets>
                                      {description.map((objective) => (
                                        <CardContentList large key={objective}>
                                          <IconSvgComponent
                                            iconPath="svg/ico-dblue-check.svg"
                                            additionalClass="learning-unit-learning-objectives-icon"
                                          />
                                          <FormattedText text={objective}></FormattedText>
                                        </CardContentList>
                                      ))}
                                    </CardListDescription>
                                  </CardContent>
                                </CardColumn>
                              </CardColumns>
                            </CardColumn>
                          </CardColumns>
                        </CardHolder>
                      </CardBody>
                    </Card>
                  )
                )}

              {learningUnit &&
                learningUnit.learning_modules.map(
                  (
                    { id, url, name, description, assignments, worksheets, is_recent, videos },
                    idx
                  ) => (
                    <Card key={idx} id={id}>
                      <CardHead>
                        <CardTitle>
                          {idx + 1}. <FormattedText text={name}></FormattedText>
                        </CardTitle>
                        <CardLink
                          to={{
                            pathname: url,
                          }}
                        >
                          Open Module
                        </CardLink>
                      </CardHead>
                      <CardBody>
                        <CardHolder>
                          <CardColumns>
                            <CardColumn flex sizeLg="50%">
                              <ButtonLink
                                isCardOrderedListLink
                                to={{
                                  pathname: url,
                                  state: { autoPlay: true },
                                }}
                              >
                                <CardVideo
                                  image={`${JW_ASSET_BASEURL +
                                    "/thumbs/" +
                                    videos[0].external_id +
                                    "-320.jpg"}`}
                                >
                                  <CardVideoButton size="56" />
                                </CardVideo>
                              </ButtonLink>
                              <CardContent>
                                <CardSubtitle>Video</CardSubtitle>
                                <CardListIcons>
                                  <CardListIconsItem>
                                    <ButtonLink
                                      isCardOrderedListLink
                                      to={{
                                        pathname: url,
                                        state: { autoPlay: true },
                                      }}
                                    >
                                      <IconSvgComponent iconPath="svg/ico-angle-play.svg" />
                                      {videos[0].seek_duration !== 0 ? (
                                        <span>Resume</span>
                                      ) : (
                                        <span>Start Video</span>
                                      )}
                                    </ButtonLink>
                                  </CardListIconsItem>
                                </CardListIcons>
                              </CardContent>
                            </CardColumn>

                            <CardColumn sizeLg="50%">
                              <CardContent>
                                <CardSubtitle>Practice Assignments</CardSubtitle>
                                <CardListIcons large>
                                  {assignments && assignments.length > 0 ? (
                                    assignments.map((assignment, idx) => (
                                      <CardListIconsItem large key={idx}>
                                        <ButtonLink
                                          isCardRedirectLink
                                          to={`${practiceAssignmentUrl}/${id}/${assignment.id}`}
                                        >
                                          <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                          <span>{assignment.name}</span>
                                        </ButtonLink>
                                      </CardListIconsItem>
                                    ))
                                  ) : worksheets && worksheets.length > 0 ? (
                                    worksheets.map(({ id, file_location, title }, idx) => (
                                      <CardListIconsItem large key={idx}>
                                        <ButtonLink
                                          isCardDownloadLink
                                          target="_blank"
                                          href={file_location}
                                        >
                                          <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                          <span>{title}</span>
                                        </ButtonLink>
                                      </CardListIconsItem>
                                    ))
                                  ) : (
                                    <span>Coming Soon</span>
                                  )}
                                </CardListIcons>
                              </CardContent>
                            </CardColumn>
                            <CardColumn sizeLg="100%">
                              <CardContent>
                                <CardSubtitle>Learning Objectives</CardSubtitle>
                                <CardListDescription custombullets>
                                  {description.map((objective) => (
                                    <CardContentList large key={objective}>
                                      <IconSvgComponent
                                        iconPath="svg/ico-dblue-check.svg"
                                        additionalClass="learning-unit-learning-objectives-icon"
                                      />
                                      <FormattedText text={objective}></FormattedText>
                                    </CardContentList>
                                  ))}
                                </CardListDescription>
                              </CardContent>
                            </CardColumn>
                          </CardColumns>
                        </CardHolder>
                      </CardBody>
                    </Card>
                  )
                )}
            </DrawerLayout>
          </MediaQuery>
        </div>
      </DocumentTitle>
    ) : (
      <NoDataMessageContainer>
        {apiCallInProgress == true && <Loader />}
        {apiCallInProgress == false && (
          <NoDataMessage>
            No Learning Unit content <br /> available at this time.
          </NoDataMessage>
        )}
      </NoDataMessageContainer>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const learningUnit = state.learningLibrary.learningUnit;

      return {
        learningUnit,
        apiCallInProgress: state.learningLibrary.apiCallInProgress.learningUnit,
      };
    },
    {
      getLearningUnit,
    }
  )(LearningUnit)
);
