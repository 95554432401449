import React, { Component } from "react";
import { connect } from "react-redux";

import { closeModal } from "store/state/ui/actions";
import { updateEntityStatus } from "store/state/administration/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Form, {
  FormHead,
  FormLogo,
  FormTitle,
  FormActions,
  StyledGenericError,
} from "styles/components/Form";
import { addNotification } from "store/state/ui/actions";
import { LEVELS, createNotification } from "utilities/notification";

class FormActivation extends Component {
  state = {
    activeIcon:
      this.props.modal.data.type === "accounts" ? "ico-account-active.svg" : "ico-user-active.svg",
    notActiveIcon:
      this.props.modal.data.type === "accounts" ? "ico-account-x.svg" : "ico-user-x.svg",
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props
      .updateEntityStatus({
        selector: this.props.modal.data.type,
        id: this.props.modal.data.id,
        account:
          this.props.modal.data.type === "accounts"
            ? this.props.modal.data.id
            : this.props.modal.data.accountId,
        updates: {
          status: this.props.modal.data.status === "active" ? false : true,
        },
      })
      .then(({ status }) => {
        if (status === "success") {
          this.props.addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              "Success",
              this.props.modal.data.status === "active"
                ? "Deactivated successfully!"
                : "Activated successfully!",
              5
            ),
          });

          this.props.closeModal();
        }
      });
  };

  render() {
    const { closeModal, modal, changeStatusApiError, apiCallInProgress } = this.props;

    return (
      <Form onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            {modal.data.status === "active" ? (
              <IconSvgComponent iconPath={`svg/${this.state.notActiveIcon}`} />
            ) : (
              <IconSvgComponent iconPath={`svg/${this.state.activeIcon}`} />
            )}
          </FormLogo>

          {changeStatusApiError.code !== -1 && (
            <StyledGenericError>{changeStatusApiError.message}</StyledGenericError>
          )}
          {!modal.data.formTitle && (
            <FormTitle>
              Are you sure you want to {modal.data.status === "active" ? "deactivate" : "activate"}{" "}
              {modal.data.title}?
            </FormTitle>
          )}
          {modal.data.formTitle && <FormTitle small={true}>{modal.data.formTitle}</FormTitle>}
        </FormHead>

        <FormActions>
          <ButtonLink
            transparent
            onClick={() => closeModal()}
            type="button"
            disabled={apiCallInProgress}
          >
            Cancel
          </ButtonLink>

          {modal.data.status === "active" ? (
            <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
              <span>Deactivate</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          ) : (
            <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
              <span>Activate</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          )}
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    changeStatusApiError: state.administration.apiErrors[state.ui.modal.data.type].changeStatus
      ? state.administration.apiErrors[state.ui.modal.data.type].changeStatus
      : null,
    apiCallInProgress: state.administration.apiCallInProgress,
  }),
  {
    updateEntityStatus,
    closeModal,
    addNotification,
  }
)(FormActivation);
