import React, { Component, Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import { sortBy } from "ramda";
import { withRouter } from "react-router-dom";

import { openModal } from "store/state/ui/actions";
import { getAssessmentDetails } from "store/state/studentDashboard/action";

import sliderSettings from "styles/slider-configs";
import {
  SCORE_HISTORY_BAR_CHART_STEP_SIZE,
  progressWheelColorRange,
  CalculateProgressIndicatorColor,
  OPERATIONS,
} from "constants/common";
import stemifyRoutes from "constants/routes";
import { MODAL_COOLDOWN_PERIOD } from "lib/constants";
import { ATTEMPT_STATUSES } from "constants/common";

import IconSvgComponent from "components/common/IconSvgComponent";
import CircleChart from "components/chart/CircleChart";
import ScoreHistoryChart from "components/chart/ScoreHistoryChart";
import Section, { SlickSlider, Grid, Button, Card } from "styles/components/studentDashboard";
import TableDefault, {
  TableContainer,
  TableWrapper,
  TableHead,
  TableBody,
  TableRow,
  TableTh,
  TableTd,
  TableCheck,
  TableCheckWrap,
  TableCheckLabel,
} from "styles/components/TableDefault";
import ButtonLink from "components/common/ButtonLink";
import Loader from "components/common/loader";

class Main extends Component {
  state = {
    colorRange: progressWheelColorRange,
  };

  componentDidMount = () => {
    const { deploymentId, assessmentSeriesId } = this.props.match.params;
    this.props.getAssessmentDetails({
      selector: "selectedAssessmentDetails",
      deploymentId,
      assessmentSeriesId,
    });
  };

  handleTakeAssessment = () => {
    const {
      assessmentName,
      nextAttemptDate,
      assessmentId,
      deploymentId,
      openModal,
      history,
    } = this.props;

    // If current date is before next attempt date show a modal warning for cooling period
    if (moment().isBefore(nextAttemptDate, "day")) {
      openModal({
        type: MODAL_COOLDOWN_PERIOD,
        data: {
          nextAttemptDate,
          assessmentName,
        },
      });
    } else {
      // Otherwise allow route to assessments
      history.push(`${stemifyRoutes.assessments}/${deploymentId}/${assessmentId}`);
    }
  };

  render() {
    const {
      props: {
        assessmentName,
        latestScore,
        totalScore,
        courseAssessmentData,
        scoreHistoryData,
        nextAttemptDate,
        lastAttemptDate,
        topAttemptDate,
        remainingAttempt,
        topicInfo,
        isLoading,
        status,
      },
      state: { colorRange },
    } = this;

    const shouldHideTakeAssessment = status.toLowerCase() === "closed" ? true : false;
    const maxYOffset =
      totalScore % SCORE_HISTORY_BAR_CHART_STEP_SIZE === 0
        ? 0
        : SCORE_HISTORY_BAR_CHART_STEP_SIZE - (totalScore % SCORE_HISTORY_BAR_CHART_STEP_SIZE);
    const arrStudentScores = scoreHistoryData.map(({ score }) => score);
    const arrBarChartLabels = scoreHistoryData.map(({ attempt_date }) => attempt_date);

    const pathToProgress = topicInfo.map((item) => {
      return {
        ...item,
        url: `/learning-library?id=${item.topic_id}&type=${item.type}`,
      };
    });
    // Sorting to show progress card from lowest to highest percent_score
    const myPathToProgress = sortBy((obj) => obj.percent_score, pathToProgress);

    const sliderConfig = {
      ...sliderSettings,
      slidesToShow: 5,
      responsive: sliderSettings.responsive.concat({
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      }),
    };

    return isLoading === true ? (
      <Loader />
    ) : (
      <DocumentTitle title={`Stemify | ${assessmentName}`}>
        <Fragment>
          <Section>
            <Section.Head>
              <Section.HeadContent>
                <Section.Title>{assessmentName}</Section.Title>

                <Section.Entry>Next attempt: {nextAttemptDate}</Section.Entry>
              </Section.HeadContent>

              <Section.HeadActions>
                <ButtonLink
                  id={"takeAssessment"}
                  violet
                  onClick={this.handleTakeAssessment}
                  type="button"
                  isHidden={shouldHideTakeAssessment}
                >
                  <span style={{ marginRight: "14px " }}>Take Assessment</span>
                  <IconSvgComponent iconPath="svg/ico-notebook.svg" />
                </ButtonLink>
              </Section.HeadActions>
            </Section.Head>

            <Section.Body>
              <Grid>
                <Grid.Col size="33.33">
                  <Card minHeight="317">
                    <Card.Head>
                      <Card.Title>My Top Score</Card.Title>
                    </Card.Head>

                    <Card.Body flexCentered>
                      <CircleChart
                        valueType="numeric"
                        maxValue={!!totalScore ? parseInt(totalScore) : 0}
                        value={!!latestScore ? parseInt(latestScore) : 0}
                        color="#1CC379"
                      />
                    </Card.Body>

                    <Card.Foot>
                      <p>Attempt taken on {topAttemptDate}</p>
                    </Card.Foot>
                  </Card>
                </Grid.Col>

                <Grid.Col size="33.33">
                  <Card tableContainer minHeight="317">
                    <Card.Head tableContainer>
                      <Card.Title>Course Placement</Card.Title>
                    </Card.Head>

                    <Card.Body>
                      <TableContainer>
                        <TableWrapper height="236" noTopBorder>
                          <TableDefault boxed>
                            <TableHead>
                              <TableRow>
                                <TableTh fontWeight="800">Cutoff</TableTh>
                                <TableTh fontWeight="800">Course</TableTh>
                                <TableTh fontWeight="800">Qualified</TableTh>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {courseAssessmentData.map((item) => (
                                <TableRow key={item.id}>
                                  <TableTd>{item.score}</TableTd>

                                  <TableTd>{item.course}</TableTd>

                                  <TableTd>
                                    <TableCheckWrap className="checkbox">
                                      <TableCheck readOnly checked={item.qualified} />

                                      <TableCheckLabel htmlFor={item.id} disabled />
                                    </TableCheckWrap>
                                  </TableTd>
                                </TableRow>
                              ))}
                            </TableBody>
                          </TableDefault>
                        </TableWrapper>
                      </TableContainer>
                    </Card.Body>
                  </Card>
                </Grid.Col>

                <Grid.Col size="33.33">
                  <Card minHeight="317">
                    <Card.Head>
                      <Card.Title>Score History</Card.Title>
                    </Card.Head>

                    <Card.Body>
                      <ScoreHistoryChart
                        labels={arrBarChartLabels}
                        minY={0}
                        maxY={parseInt(totalScore + maxYOffset)}
                        data={arrStudentScores}
                      />
                    </Card.Body>

                    <Card.Foot>
                      <p>Attempts Remaining: {remainingAttempt}</p>
                    </Card.Foot>
                  </Card>
                </Grid.Col>
              </Grid>
            </Section.Body>
          </Section>

          <Section>
            <Grid>
              <Grid.Col size="66.66">
                <Section.Head>
                  <Section.HeadContent>
                    <Section.Title>My Path to Progress</Section.Title>
                  </Section.HeadContent>
                </Section.Head>

                <Section.Body id="myPathToProgress" csliderWrapper>
                  <SlickSlider {...sliderConfig}>
                    {myPathToProgress.map((item) => (
                      <Card small key={item.topic_id}>
                        <Card.Body>
                          <Card.Inner>
                            <CircleChart
                              size={170}
                              textFontSize={31}
                              value={!!item.percent_score ? item.percent_score : 0}
                              color={CalculateProgressIndicatorColor(
                                item.percent_score,
                                colorRange
                              )}
                            />
                          </Card.Inner>

                          <Card.SubTitle>{item.topic_name}</Card.SubTitle>
                        </Card.Body>

                        <Card.Foot>
                          <Card.Actions>
                            <Card.Link to={item.url}>Review</Card.Link>
                          </Card.Actions>
                        </Card.Foot>
                      </Card>
                    ))}
                  </SlickSlider>
                </Section.Body>
              </Grid.Col>

              <Grid.Col size="33.33">
                <Section.Head>
                  <Section.HeadContent>
                    <Section.Title>Full Learning Library</Section.Title>
                  </Section.HeadContent>
                </Section.Head>

                <Section.Body id="learningLibrary">
                  <Card noPadding>
                    <Card.Body>
                      <Card.Image image="/images/card-image.png" />

                      <Card.Content>
                        <Card.Entry>
                          <p>
                            Use interactive video lessons and practice problems to learn and review!
                          </p>
                        </Card.Entry>

                        <Card.Actions>
                          <Card.Link to={stemifyRoutes.learningLibrary}>Get Started</Card.Link>
                        </Card.Actions>
                      </Card.Content>
                    </Card.Body>
                  </Card>
                </Section.Body>
              </Grid.Col>
            </Grid>
          </Section>
        </Fragment>
      </DocumentTitle>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      assessmentId: state.studentDashboard.selectedAssessmentDetails.assessmentId,
      assessmentName: state.studentDashboard.selectedAssessmentDetails.assessmentName,
      deploymentId: state.studentDashboard.selectedAssessmentDetails.deploymentId,
      latestScore: state.studentDashboard.selectedAssessmentDetails.score.top_score,
      topAttemptDate: state.studentDashboard.selectedAssessmentDetails.topAttemptDate,
      totalScore: state.studentDashboard.selectedAssessmentDetails.score.total_score,
      courseAssessmentData: state.studentDashboard.selectedAssessmentDetails.assessment_course,
      scoreHistoryData: state.studentDashboard.selectedAssessmentDetails.attempt_history,
      nextAttemptDate: state.studentDashboard.selectedAssessmentDetails.nextAttemptDate,
      lastAttemptDate: state.studentDashboard.selectedAssessmentDetails.lastAttemptDate,
      remainingAttempt: state.studentDashboard.selectedAssessmentDetails.remainingAttempt,
      status: state.studentDashboard.selectedAssessmentDetails.status,
      topicInfo: state.studentDashboard.selectedAssessmentDetails.topicInfo,
      isLoading: state.studentDashboard.isLoading,
      apiError: state.studentDashboard.apiErrors.selectedAssessmentDetails[OPERATIONS.GET],
    }),
    {
      getAssessmentDetails,
      openModal,
    }
  )(Main)
);
