import styled from "styled-components";
import theme from "../theme";
import { media } from "../media";
import { Link as ReactRouterLink } from "react-router-dom";

export const Course = styled.div`
  font-size: 16px;
  border-radius: 8px;
  color: ${theme.color.darker_gray};
  text-align: left;
  position: relative;
  width: 386px;
  max-width: 386px;

  .dropdown-wrapper {
    position: absolute;
    top: 25px;
    right: 15px;
    margin-right: 0;

    > button {
      height: auto;
      margin: 0 -9px 0 0;
      transform: rotate(90deg);

      span {
        background: ${theme.color.white};
      }
    }
  }

  ${(props) =>
    props.isCopy &&
    `
    box-shadow: 0px 0px 30px -5px ${props.background};
  `}

  ${(props) =>
    props.fullWidth &&
    `
    width: 100%;
    max-width: inherit;
  `}
`;

export const CourseTitle = styled.h3`
  font-size: 22px;
  margin: 0;
  padding-right: 15px;
`;

export const CourseBody = styled.div`
  padding: 15px 20px;

  ${(props) =>
    props.background &&
    `
    background: ${props.background};
    color: ${theme.color.white};
  `}
`;

export const CourseFoot = styled.div`
  background: ${theme.color.white};
  padding: 15px 20px;
  min-height: 110px;
`;

export const CourseFootFlex = styled.div`
  display: flex;
`;

export const CourseFootFlexItem = styled.div`
  flex: 0 0 112px;
  margin: 0 35px 0 0;

  p {
    margin: 0;
  }

  h4 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
  }
`;

export const CourseAssignmentStatus = styled.div`
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 6px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  ${(props) =>
    props.complete &&
    `
    color: ${theme.color.white};
    background-color: ${theme.color.success};//#1cc379;
  `}
  ${(props) =>
    props.incomplete &&
    `
    border-color: ${theme.color.gray}
    color: ${theme.color.gray}
  `}
`;

export const CourseFootAnalyticsLink = styled(ReactRouterLink)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CourseInnerLink = styled(ReactRouterLink)`
  display: block;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
`;

export const CourseInner = styled.div`
  display: block;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  overflow: hidden;
`;

export const CourseEntry = styled.p`
  margin: 0;

  & + p {
    margin-top: 5px;
  }
`;

export const SpinnerOverlay = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerContainer = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 2px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

const Courses = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: calc(100%);
  margin: 0 -15px -30px;

  ${Course} {
    margin: 0 15px 30px;

    ${media.desktop_md`
      width: calc(33.33% - 30px);
    `}

    ${media.tablet`
      width: calc(50% - 30px);
    `}

    ${media.mobile`
      width: calc(100% - 30px);
    `}
  }
`;

export default Courses;
