/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */
import { MODAL_ADD_USERS, MODAL_USER_LIMIT_REACHED } from "lib/constants";
import { create, update, createUserItem, updateUserItem } from "store/state/administration/actions";
import { openModal, closeModal } from "store/state/ui/actions";
import { OPERATIONS } from "constants/common";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Form, {
  FormHead,
  FormLogo,
  FormBody,
  FormTitle,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
} from "styles/components/Form";
import { FORM_CREATE_STUDENT_MESSAGES } from "constants/messages";
import { validateField, validateSelectField } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { studentYears } from "constants/dropdownOptions";
/**
 * Class for form create student.
 *
 * @class      FormCreateStudent (name)
 */
class FormCreateStudent extends Component {
  state = {
    avatarUrl: "/images/image-placeholder.png",
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: { value: "Student", label: "Student" },
    dicipline: "",
    status: "active",
    studentId: "",
    year: { value: "", label: "" },
    formErrors: {
      userName: {
        valid: true,
        message: "",
      },
      firstName: {
        valid: true,
        message: "",
      },
      lastName: {
        valid: true,
        message: "",
      },
      email: {
        valid: true,
        message: "",
      },
      studentId: {
        valid: true,
        message: "",
      },
      year: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };

  rules = {
    userName: {
      requiredMessage: FORM_CREATE_STUDENT_MESSAGES.USER_NAME_REQUIRED,
      typeUsernameMessage: FORM_CREATE_STUDENT_MESSAGES.USER_NAME_FORMAT_INVALID,
      maxMessage: FORM_CREATE_STUDENT_MESSAGES.USER_NAME_MAX,
    },
    firstName: {
      requiredMessage: FORM_CREATE_STUDENT_MESSAGES.FIRST_NAME_REQUIRED,
      maxMessage: FORM_CREATE_STUDENT_MESSAGES.FIRST_NAME_MAX,
    },
    lastName: {
      requiredMessage: FORM_CREATE_STUDENT_MESSAGES.LAST_NAME_REQUIRED,
      maxMessage: FORM_CREATE_STUDENT_MESSAGES.LAST_NAME_MAX,
    },
    email: {
      requiredMessage: FORM_CREATE_STUDENT_MESSAGES.EMAIL_REQUIRED,
      typeEmailMessage: FORM_CREATE_STUDENT_MESSAGES.EMAIL_FORMAT_INVALID,
      maxMessage: FORM_CREATE_STUDENT_MESSAGES.EMAIL_MAX,
    },
    studentId: {
      requiredMessage: FORM_CREATE_STUDENT_MESSAGES.STUDENT_ID_REQUIRED,
      maxMessage: FORM_CREATE_STUDENT_MESSAGES.STUDENT_ID_MAX,
    },
    year: {
      requiredMessage: FORM_CREATE_STUDENT_MESSAGES.YEAR_REQUIRED,
    },
  };
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSelectChange = (key) => (value) => {
    this.setState({ [key]: !!value ? value : { value: "", label: "" } });
  };

  isEdit() {
    return this.props.modal.data && this.props.modal.data.editItem;
  }

  validateTextField = (element, trimValues) => {
    this.setState({
      formErrors: validateField(this.state.formErrors, this.rules, element, "", trimValues),
    });
  };

  validateDropDownField = (properties) => {
    this.setState({
      formErrors: validateSelectField(this.state.formErrors, this.rules, properties),
    });
  };

  validateAllFields = () => {
    this.validateTextField(document.getElementById("userName"), true);
    this.validateTextField(document.getElementById("firstName"), true);
    this.validateTextField(document.getElementById("lastName"), true);
    this.validateTextField(document.getElementById("email"), true);
    this.validateTextField(document.getElementById("studentId"), true);
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.validateAllFields();
    if (this.state.formErrors.formValid) {
      e.preventDefault();
      const { create, modal, closeModal, createUserItem, updateUserItem, openModal } = this.props;
      if (this.isEdit()) {
        updateUserItem({
          selector: "students",
          id: modal.data.id,
          updates: {
            ...this.state,
          },
        }).then(({ status }) => {
          if (status === "success") {
            closeModal();
          }
        });
      } else {
        createUserItem({
          selector: "users",
          item: {
            ...this.state,
            account: modal.data.accountId,
          },
        }).then(({ status, data }) => {
          if (status === "success" || data.errorCode == 165) {
            create({
              selector: "selectedUsers",
              item: {
                id: data.user_name,
                selected: true,
                values: this.state,
              },
            });
            closeModal();
            openModal({
              type: MODAL_ADD_USERS,
              data: {
                ...modal.data,
                modalSize: "mdx",
              },
            });
            if (data.errorCode == 165) {
              this.props.openModal({ type: MODAL_USER_LIMIT_REACHED, data: { user: this.state } });
            }
          }
        });
      }
    }

    openModal({
      type: MODAL_ADD_USERS,
      data: {
        modalSize: "mdx",
      },
    });
  };

  componentDidMount() {
    const { userRole } = this.props.modal.data;

    if (userRole) {
      this.setState({
        role: { value: userRole, label: userRole },
      });
    }
    if (this.isEdit()) {
      this.setState({
        ...this.props.modal.data.editItem,
      });
    }
  }

  render() {
    const { id, firstName, lastName, studentId, email, role, year, formErrors } = this.state;

    const { apiCallInProgress } = this.props;
    const userRole = this.props.modal.data.userRole ? this.props.modal.data.userRole : "Student";

    let operation = this.isEdit() ? OPERATIONS.UPDATE : OPERATIONS.CREATE;
    return (
      <Form userForm onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            {this.isEdit() ? (
              <IconSvgComponent iconPath="svg/ico-user-edit.svg" />
            ) : (
              <IconSvgComponent iconPath="svg/ico-user-add.svg" />
            )}
          </FormLogo>

          <FormTitle>{this.isEdit() ? "Edit User" : "Create User"}</FormTitle>
          <FieldErrors
            formErrors={formErrors}
            apiErrorMessage={this.props.usersApiErrors[operation].message}
          ></FieldErrors>
        </FormHead>

        <FormBody>
          <FormRowGrid>
            <FormCol>
              <FormControls>
                <Field
                  id="userName"
                  name="id"
                  onChange={this.handleInputChange}
                  value={id}
                  placeholder="User Name"
                  required
                  patternAvailable={true}
                  patternString="^[^ ]{6,255}$"
                  disabled={this.isEdit()}
                  errorTitle={"Minimum 6 letters required without space."}
                />
              </FormControls>
            </FormCol>
            <FormCol half>
              <FormControls>
                <Field
                  id="firstName"
                  name="firstName"
                  onChange={this.handleInputChange}
                  value={firstName}
                  placeholder="First Name"
                  required
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="lastName"
                  name="lastName"
                  onChange={this.handleInputChange}
                  value={lastName}
                  placeholder="Last Name"
                  required
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="email"
                  name="email"
                  onChange={this.handleInputChange}
                  value={email}
                  placeholder="Email"
                  required
                  type="email"
                  max="128"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="role"
                  name="role"
                  isSelect
                  onChange={this.handleSelectChange("role")}
                  options={[{ value: userRole, label: userRole }]}
                  value={role}
                  placeholder="Role"
                  required
                  disabled
                />
              </FormControls>
            </FormCol>

            {userRole === "Student" && (
              <React.Fragment>
                <FormCol half>
                  <FormControls>
                    <Field
                      id="studentId"
                      name="studentId"
                      onChange={this.handleInputChange}
                      value={studentId}
                      placeholder="Student ID"
                      max="255"
                    />
                  </FormControls>
                </FormCol>

                <FormCol half>
                  <FormControls>
                    <Field
                      id="year"
                      name="year"
                      isSelect
                      onChange={this.handleSelectChange("year")}
                      options={studentYears}
                      value={year}
                      placeholder="Year"
                    />
                  </FormControls>
                </FormCol>
              </React.Fragment>
            )}
          </FormRowGrid>
        </FormBody>

        <FormActions>
          <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
            <span>{this.isEdit() ? "Update User" : "Create User"}</span>

            <IconSvgComponent iconPath="svg/ico-check-white.svg" />
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    usersApiErrors: state.administration.apiErrors.users,
    apiCallInProgress: state.administration.apiCallInProgress,
  }),
  {
    create,
    update,
    updateUserItem,
    openModal,
    closeModal,
    createUserItem,
  }
)(FormCreateStudent);
