/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

export const Navigation = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 0px;
  background: ${theme.color.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11);

  .slick-list {
    margin: 0 -8px;
  }

  .slick-arrow {
    top: 0;
    transform: none;
    width: 33px;
    height: 33px;
  }

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -30px;
  }
`;
export const NavigationTitle = styled.h6`
  padding: 10px 15px 8px;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;

  ${media.mobile`
		padding: 10px 60px;
	`}
`;
export const NavigationInner = styled.div`
  position: relative;
  padding: 0 50px;
`;

export const NavigationList = styled.div``;
export const NavigationListItem = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
export const NavigationPrev = styled.button`
  position: absolute;
  top: 0;
  left: 10px;
  border: 0;
  border-radius: 50%;
  background: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11);
`;
export const NavigationNext = styled.button`
  position: absolute;
  top: 0;
  right: 10px;
  border: 0;
  border-radius: 50%;
  background: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11);
`;

export const ButtonUpload = styled.button`
  position: absolute;
  top: -22px;
  right: 30px;
  z-index: 21;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 22px;
  border: 0;
  border-radius: 22px;
  background: ${theme.color.red};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  font-weight: 700;
  color: ${theme.color.white};

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
  }

  ${media.mobile`
		top: 0;
		right: 10px;
		padding: 5px 10px;
		font-size: 0;

		svg {
			margin: 0;
		}
    `}

  ${(props) =>
    props.isPracticeAssessment &&
    `
		  right: 55px;

	`}
`;
