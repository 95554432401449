import { useState, useEffect } from "react";

export default ({ fetchDataCallback, dependencies = [], initialDataLoaded = false }) => {
  const [dataLoaded, setDataLoaded] = useState(initialDataLoaded || null);
  const [dataOrSuccess, setDataOrSuccess] = useState(initialDataLoaded || null);
  const fetchData = async () => {
    setDataLoaded(false);
    const response = await fetchDataCallback();
    setDataLoaded(response || response !== null ? true : false);
    setDataOrSuccess(response);
  };

  useEffect(() => {
    if (!initialDataLoaded) {
      fetchData();
    }
  }, [].concat(dependencies));

  return [dataLoaded, dataOrSuccess];
};
