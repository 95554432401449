import React, { useEffect, useState, useRef } from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";

import { ROLES } from "constants/roles";
import { NoDataComponent } from "components/common/table";
import ButtonLink from "components/common/ButtonLink";
import CourseItem from "components/courses/CourseItem";
import IconSvgComponent from "components/common/IconSvgComponent";
import Courses from "styles/components/Courses";
import Loader from "components/common/loader";
import SectionCourses, { SectionBody, SectionTextblock } from "styles/components/SectionCourses";
import { MODAL_CREATE_COURSE, MODAL_CONFIRMATION } from "lib/constants";
import { openModal, closeModal } from "store/state/ui/actions";
import {
  deleteSection,
  copySection,
  getSections,
  getAssignments,
  getSchedules,
} from "store/state/courses/actions";
import moment from "moment";

const CoursesList = ({
  getAssignments,
  getSections,
  getSchedules,
  openModal,
  deleteSection,
  copySection,
  sections,
  assignments,
  schedules,
  userRole,
  isLoading,
}) => {
  const bottomOfPageRef = useRef();

  const [sectionDataLoaded, setSectionDataLoaded] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(null);
  const [newCopyId, setNewCopyId] = useState(null);

  const fetchSections = async () => {
    setSectionDataLoaded(false);
    setDataLoaded(false);
    const success = await getSections();
    setSectionDataLoaded(success);
  };

  useEffect(() => {
    if (newCopyId) {
      bottomOfPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [newCopyId, bottomOfPageRef]);

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if (sectionDataLoaded && sections.length > 0) {
      const sectionIds = sections.map((section) => section.id);
      getAssignments({ sectionIds });
      getSchedules({ sectionIds });
      setDataLoaded(true);
    }
  }, [sectionDataLoaded]);

  const handleCourseEdit = (course) => {
    openModal({
      type: MODAL_CREATE_COURSE,
      data: {
        id: course.id,
        editItem: course,
      },
    });
  };

  const handleCourseCopy = (course) => {
    openModal({
      type: MODAL_CONFIRMATION,
      data: {
        id: course,
        title: course.name,
        icon: "ico-course-copy.svg",
        type: "copy",
        action: async () => {
          const newSectionResponse = await copySection(course);
          if (newSectionResponse && newSectionResponse.status === "success") {
            // Update the new section copy id for scrolling and styles
            setNewCopyId(newSectionResponse.data.id);
          } else {
            setNewCopyId(null);
          }
        },
      },
    });
  };

  const handleCourseDelete = (course) => {
    openModal({
      type: MODAL_CONFIRMATION,
      data: {
        id: course,
        title: course.name,
        icon: "ico-course-delete.svg",
        type: "remove",
        action: () => deleteSection(course.id),
      },
    });
  };

  return (
    <DocumentTitle title="Stemify | Courses">
      <SectionCourses>
        {userRole !== ROLES.STUDENT && (
          <ButtonLink
            onClick={() => openModal({ type: MODAL_CREATE_COURSE })}
            hasIcon
            violet
            className="btn-add has-widtget"
            positionTop
          >
            <span>Create </span>

            <IconSvgComponent iconPath={`svg/ico-plus.svg`} />
          </ButtonLink>
        )}
        {!isLoading && sections.length > 0 ? (
          <SectionBody>
            <Courses>
              {sections.map((section, index) => {
                const filteredAssignments = assignments.filter(
                  (assignment) => assignment.sectionId === section.id
                );
                const filteredSchedules = schedules.filter(
                  (schedule) =>
                    schedule.sectionId === section.id && moment(schedule.endTs).isAfter(moment())
                );

                return (
                  <CourseItem
                    onEdit={(section) => handleCourseEdit(section)}
                    onDelete={(section) => handleCourseDelete(section)}
                    onCopy={(section) => handleCourseCopy(section)}
                    data={{
                      ...section,
                      assignments: filteredAssignments,
                      schedules: filteredSchedules,
                    }}
                    key={section.id}
                    innerRef={index === sections.length - 1 ? bottomOfPageRef : null}
                    isCopy={newCopyId === section.id}
                  />
                );
              })}
            </Courses>
          </SectionBody>
        ) : isLoading ? (
          <Loader />
        ) : (
          <SectionTextblock>
            <NoDataComponent
              type="Course"
              iconPath="svg/ico-book-lg.svg"
              textColor="#4A4A4A"
              onClickAction={
                userRole !== ROLES.STUDENT ? () => openModal({ type: MODAL_CREATE_COURSE }) : ""
              }
              customMessage={
                userRole === ROLES.STUDENT ? "You don’t have any courses right now" : ""
              }
              noSubMessage={userRole === ROLES.STUDENT ? true : false}
            />
          </SectionTextblock>
        )}
      </SectionCourses>
    </DocumentTitle>
  );
};

export default connect(
  (state) => ({
    modal: state.ui.modal,
    userRole: state.user.role,
    currentAccountId: state.user.currentAccountId,
    accountId: state.user.accountId,
    isLoading: state.storedCourses.isLoading.sections,
    sections: Object.values(state.storedCourses.sections),
    assignments: Object.values(state.storedCourses.assignments),
    schedules: Object.values(state.storedCourses.schedules),
  }),
  (dispatch) => ({
    openModal: (obj) => dispatch(openModal({ ...obj })),
    closeModal: () => dispatch(closeModal()),
    deleteSection: (sectionId) => dispatch(deleteSection(sectionId)),
    copySection: (section) => dispatch(copySection(section)),
    getSections: () => dispatch(getSections()),
    getAssignments: ({ sectionIds, limit, offset }) => dispatch(getAssignments({ sectionIds })),
    getSchedules: ({ sectionIds, limit, offset }) => dispatch(getSchedules({ sectionIds })),
  })
)(CoursesList);
