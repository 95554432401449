import React, { Component, useCallback, useEffect, useMemo, useState } from "react";
import MediaQuery from "react-responsive";
import DocumentTitle from "react-document-title";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";

import { PaginatedTable, NoDataComponent } from "components/common/table";
import { MODAL_CREATE_COHORT, MODAL_ARCHIVE } from "lib/constants";
import { openModal, closeModal } from "store/state/ui/actions";
import { getCohorts } from "store/state/administration/actions";
import SectionTable, { SectionBody } from "styles/components/SectionTable";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import TableDropdown from "components/elements/TableDropdown";
import { StyledSpan } from "styles/components/TableDefault";
import Can from "components/common/Can";
import { semesters } from "constants/dropdownOptions";
import { getLabel } from "utilities/commonFunctions";
import Loader from "components/common/loader";
import stemifyRoutes from "../../constants/routes";
import Popover from "components/common/Popover";

const Cohorts = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accountId = useSelector((state) =>
    !!state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId
  );
  const userRole = useSelector((state) => state.user.role);
  const cohorts = useSelector((state) => state.administration.cohorts);
  const cohortsTable = useSelector((state) => state.administration.cohortsTable);
  const { count } = cohortsTable;

  const [isPageLoading, setPageLoading] = useState(true);

  const editCohort = useCallback(
    (cohort) => (e) => {
      dispatch(
        openModal({
          type: MODAL_CREATE_COHORT,
          data: {
            id: cohort.id,
            editItem: {
              name: cohort.name,
              student_count: cohort.student_count,
              createdBy: cohort.created_by,
              modifiedBy: cohort.modified_by,
              dateCreated: cohort.date_created,
              dateModified: cohort.date_modified,
              startDate: moment(cohort.start_date),
              endDate: moment(cohort.end_date),
              semester: cohort.semester,
              is_active: cohort.is_active,
            },
          },
        })
      );
    },
    [dispatch]
  );

  const manageStudents = useCallback(
    (id) => (e) => {
      history.push(`${stemifyRoutes.cohorts}/${id}`);
    },
    [history]
  );

  // archive = (title) => (e) => {
  //   this.props.openModal({
  //     type: MODAL_ARCHIVE,
  //     data: {
  //       title: title,
  //       icon: "ico-users-drak-remove.svg",
  //     },
  //   });
  // };

  const fetchData = useCallback(
    async ({ pageSize, pageIndex }) => {
      setPageLoading(true);

      try {
        await dispatch(getCohorts({ pageSize, page: pageIndex + 1 }));

        setPageLoading(false);
      } catch (error) {
        console.log({ error });
        setPageLoading(false);
      }
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    return [
      {
        accessor: "name",
        Header: "Name",
        minWidth: 200,
        Cell: (props) => (
          <ButtonLink
            isRouterLink
            noLeftPadding
            to={`${stemifyRoutes.cohorts}/${props.row.original.id}`}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <IconSvgComponent iconPath="svg/ico-users-colored.svg" />
            <StyledSpan
              hasIconOnLeft
              bold
              style={{
                width: "fit-content",
                whiteSpace: "nowrap",
                overflowX: "clip",
                textOverflow: "ellipsis",
              }}
            >
              {props.value}
            </StyledSpan>
          </ButtonLink>
        ),
      },
      {
        accessor: "semester",
        Header: "Semester",
        minWidth: 100,
        Cell: (props) => getLabel(semesters, props.value),
      },
      {
        accessor: "student_count",
        Header: "# in Cohort",
        minWidth: 75,
      },
      {
        accessor: "start_date",
        Header: "Start Date",
        minWidth: 75,
        Cell: (props) => moment(props.value).format("MM.DD.YY"),
      },
      {
        accessor: "end_date",
        Header: "End Date",
        minWidth: 75,
        Cell: (props) => {
          return moment(props.value).format("MM.DD.YY");
        },
      },
      {
        accessor: "dropdown",
        Header: "",
        minWidth: 75,
        style: {
          display: "flex",
          justifyContent: "flex-end",
        },
        Cell: (props) => {
          const {
            yPositionStart,
            tableBodyRef,
            row: { original: cohort },
          } = props;

          return (
            <Can
              role={userRole}
              perform={"cohorts-page:create"}
              yes={() => (
                <Popover
                  placement="bottom-start"
                  portalRef={tableBodyRef}
                  yPositionStart={yPositionStart}
                  TriggerComponent={(props) => {
                    const { setRef, ...triggerProps } = props;

                    return (
                      <ButtonLink setRef={setRef} {...triggerProps} isTableDropdownButton>
                        <span />
                        <span />
                        <span />
                      </ButtonLink>
                    );
                  }}
                  actions={[
                    {
                      name: "Edit",
                      onClick: editCohort(cohort),
                    },
                    {
                      name: "Manage Students",
                      onClick: manageStudents(cohort.id),
                    },
                  ]}
                />
              )}
              no={() => (
                <Popover
                  placement="bottom-start"
                  portalRef={tableBodyRef}
                  yPositionStart={yPositionStart}
                  TriggerComponent={(props) => {
                    const { setRef, ...triggerProps } = props;

                    return (
                      <ButtonLink setRef={setRef} {...triggerProps} isTableDropdownButton>
                        <span />
                        <span />
                        <span />
                      </ButtonLink>
                    );
                  }}
                  actions={[
                    {
                      name: "Manage Students",
                      // { callback: this.manageStudents(cohort.id), btnText: "Manage Students" },
                    },
                  ]}
                />
              )}
            />
          );
        },
      },
    ];
  }, [editCohort, manageStudents, userRole]);

  return (
    <DocumentTitle title="Stemify | Cohorts">
      <SectionTable>
        <Can
          role={userRole}
          perform={"cohorts-page:create"}
          yes={() => (
            <ButtonLink
              className="button-add"
              onClick={() => dispatch(openModal({ type: MODAL_CREATE_COHORT }))}
              violet
              fab
              size="43"
            />
          )}
        />

        <SectionBody>
          <PaginatedTable
            data={cohorts}
            columns={columns}
            fetchData={fetchData}
            serverSidePagination={true}
            count={count}
            isLoading={isPageLoading}
            NoDataComponent={() => (
              <div style={{ height: "487px", padding: "65px 24px" }}>
                <NoDataComponent
                  type="Cohort"
                  iconPath="svg/ico_cohorts_people.svg"
                  onClickAction={() => openModal({ type: MODAL_CREATE_COHORT })}
                />
              </div>
            )}
          />
        </SectionBody>
      </SectionTable>
    </DocumentTitle>
  );
};

export default Cohorts;
