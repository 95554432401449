import React, { useState, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";
import { isEmpty } from "ramda";

import IconSvgComponent from "components/common/IconSvgComponent";
import { openModal } from "store/state/ui/actions";
import { MODAL_CONFIRMATION } from "lib/constants";
import { IMAGE_S3_SERVER, THUMBNAILS } from "constants/api";
import { getAssignmentById } from "store/state/courses/actions";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import FieldErrors from "../common/FieldErrors";
import Form, {
  FormBody,
  FormHead,
  FormTitle,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
  StyledFieldset,
} from "styles/components/Form";
import {
  CTAbtn,
  ToggleDropdown,
  BtnDropdown,
} from "styles/components/ModalCourseAssignmentPractice";
import { validateField, validateDateField } from "utilities/validation";
import { FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES } from "constants/messages";
import {
  CourseAssignmentCard,
  CourseAssignmentCardImage,
  CourseAssignmentCardContent,
  CourseAssignmentCardText,
  CourseAssignmentCardTitle,
  CourseAssignmentCardEntry,
} from "styles/components/CourseAssignments";
import { resetAsset, setAssignmentAssets, getAssessments } from "store/state/courses/actions";
import { ASSIGNMENT_TYPES } from "constants/common";

const initialFormState = {
  name: "",
  description: "",
  startDate: "None",
  endDate: "None",
  formErrors: {
    name: {
      valid: true,
      message: "",
    },
    description: {
      valid: true,
      message: "",
    },
    startDate: {
      valid: true,
      message: "",
    },
    endDate: {
      valid: true,
      message: "",
    },
    startDateScope: {
      valid: true,
      message: "",
    },
    endDateScope: {
      valid: true,
      message: "",
    },
    formValid: true,
  },
};

const baseRules = {
  name: {
    requiredMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_NAME_REQUIRED,
    maxMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_NAME_MAX,
  },
  description: {
    maxMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_INSTRUCTION_MAX,
  },
  startDate: {
    requiredMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_START_DATE_REQUIRED,
    typeDateMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_DATE_INVALID,
    scopeDateMessage:
      FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_UNIT_START_DATE_SCOPE,
  },
  endDate: {
    requiredMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_END_DATE_REQUIRED,
    typeDateMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_DATE_INVALID,
    scopeDateMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_UNIT_END_DATE_SCOPE,
  },
  startDateScope: {
    scopeDateMessage:
      FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_UNIT_START_DATE_SCOPE,
  },
  endDateScope: {
    scopeDateMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_UNIT_END_DATE_SCOPE,
  },
};

/*
 * Returns custom date string with 'at' as a moment object.
 * @return {moment}
 */
// TODO: Probably a good utility function to abstract
const getMomentObjectFromDateString = (value) => {
  if (typeof value === "string") {
    const dateTimeParts = value.split(" at ");
    const dateTime = dateTimeParts.join(" ");
    value = moment(dateTime);
  }
  return value;
};

const AttachmentCard = ({ type, asset, removeCard }) => {
  switch (type) {
    case ASSIGNMENT_TYPES.OPENSTAX:
      return (
        <CourseAssignmentCard key={asset.id}>
          <ButtonLink
            transparent
            onClick={() => removeCard(asset)}
            type="button"
            className="btn-remove"
          >
            <IconSvgComponent iconPath="svg/ico-close-thick.svg" />
          </ButtonLink>
          <CourseAssignmentCardImage image={asset.openstax_thumbnail} />

          <CourseAssignmentCardContent>
            <IconSvgComponent iconPath="svg/ico-openstax-assignment-type-small.svg" />

            <CourseAssignmentCardText>
              <CourseAssignmentCardTitle>{asset.openstax_title}</CourseAssignmentCardTitle>
              <CourseAssignmentCardEntry>{asset.openstax_description}</CourseAssignmentCardEntry>
            </CourseAssignmentCardText>
          </CourseAssignmentCardContent>
        </CourseAssignmentCard>
      );

    case ASSIGNMENT_TYPES.VIDEO:
      return (
        <CourseAssignmentCard key={asset.id}>
          <ButtonLink
            transparent
            onClick={() => removeCard(asset)}
            type="button"
            className="btn-remove"
          >
            <IconSvgComponent iconPath="svg/ico-close-thick.svg" />
          </ButtonLink>

          <CourseAssignmentCardImage image={IMAGE_S3_SERVER + THUMBNAILS + asset.video_thumbnail} />

          <CourseAssignmentCardContent>
            <IconSvgComponent iconPath="svg/video_sm_ic.svg" />

            <CourseAssignmentCardText>
              <CourseAssignmentCardTitle>{asset.name}</CourseAssignmentCardTitle>
              <CourseAssignmentCardEntry>{asset.text}</CourseAssignmentCardEntry>
            </CourseAssignmentCardText>
          </CourseAssignmentCardContent>
        </CourseAssignmentCard>
      );

    default:
      return (
        <CourseAssignmentCard key={asset.id}>
          <ButtonLink
            transparent
            onClick={() => removeCard(asset)}
            type="button"
            className="btn-remove"
          >
            <IconSvgComponent iconPath="svg/ico-close-thick.svg" />
          </ButtonLink>

          <CourseAssignmentCardImage image="/images/assignment-placeholder.jpg" />

          <CourseAssignmentCardContent>
            <IconSvgComponent iconPath="svg/assessments_nav_ic.svg" />

            <CourseAssignmentCardText>
              <CourseAssignmentCardTitle>{asset.assessment_name}</CourseAssignmentCardTitle>
              <CourseAssignmentCardEntry>{asset.created_by}</CourseAssignmentCardEntry>
            </CourseAssignmentCardText>
          </CourseAssignmentCardContent>
        </CourseAssignmentCard>
      );
  }
};

const BaseAssignmentForm = ({
  type,
  onCancel,
  onSubmit,
  onAddAssets,
  customProperties,
  isEditMode,
  activeAssignmentId,
  activeUnitId,
}) => {
  const rules = {
    ...baseRules,
    ...customProperties?.rules,
  };

  const dispatch = useDispatch();
  const { courseId } = useParams();

  // Selectors
  const createAssignmentApiError = useSelector(
    (state) => state.storedCourses.apiErrors.assignments.create.message
  );
  const isLoading = useSelector((state) => state.storedCourses.apiCallInProgress);
  const unit = useSelector((state) => state.storedCourses.units[activeUnitId]);
  const assignment = useSelector((state) => state.storedCourses.assignments[activeAssignmentId]);
  const schedule = useSelector((state) =>
    Object.values(state.storedCourses.schedules).find((s) => s.assignmentId === activeAssignmentId)
  );
  const videos = useSelector((state) => Object.values(state.storedCourses.videos));
  const assessments = useSelector((state) => Object.values(state.storedCourses.assessments));
  const asset = useSelector((state) => state.storedCourses.assignmentAssets);

  // Refs
  const endDateRef = useRef();

  // Local State
  const [formState, setFormState] = useState({
    ...initialFormState,
    ...customProperties?.formState,
    formErrors: {
      ...initialFormState.formErrors,
      ...customProperties?.formErrors,
    },
  });
  // The custom form state is currently nested in customProperties so we are setting that here so that we can prevent side effects from running on every render
  const [customFormState, setCustomFormState] = useState(customProperties?.formState);
  // Need to keep check here for when asset has been set so that we can remove the attached asset without triggering setting it again
  const [hasAssetLoaded, setHasAssetLoaded] = useState(false);

  // const [toggleDropdownActive, setToggleDropdownActive] = useState(false);
  const { name, description, startDate, endDate, formErrors } = formState;

  // Methods
  // const handleDropdownToggleClick = () =>
  //   setToggleDropdownActive((prevToggleState) => !prevToggleState);

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        [name]: value,
      };
    });
  };

  const handleSliderChange = (value) => {
    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        attempts: value,
      };
    });
  };

  const onChangeRadioSelection = (e) => {
    const { name, value } = e.target;
    if (name === "uniqueFormPerAttempt") {
      setFormState((prevFormState) => {
        return {
          ...prevFormState,
          attempts: 1,
          [name]: value,
        };
      });
    } else {
      setFormState((prevFormState) => {
        return {
          ...prevFormState,
          [name]: value,
        };
      });
    }
  };

  const handleDateChange = (name) => (value) => {
    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        [name]: value,
      };
    });
  };

  const addAssets = (event) => {
    event.preventDefault();

    onAddAssets();
  };

  const handleCardDelete = () => {
    dispatch(resetAsset());
  };

  const removeCard = (data) => {
    dispatch(
      openModal({
        type: MODAL_CONFIRMATION,
        data: {
          id: data.id,
          title: data.name,
          icon: "ico-module-remove.svg",
          type: "delete",
          action: handleCardDelete,
        },
      })
    );
  };

  const validateTextField = (element, trimValues) => {
    const updatedFormErrors = validateField(formErrors, rules, element, "", trimValues);

    setFormState((prevFormState) => ({
      ...prevFormState,
      formErrors: {
        ...prevFormState.formErrors,
        [element.name]: updatedFormErrors[element.name],
      },
    }));

    return updatedFormErrors[element.name];
  };

  const validateFormDateField = (name, value) => {
    value =
      !value || (typeof value === "string" && (value.trim() === "" || value.trim() === "None"))
        ? undefined
        : getMomentObjectFromDateString(value);

    const updatedFormErrors = validateDateField(formErrors, rules, {
      name,
      value,
      required: true,
    });

    setFormState((prevFormState) => ({
      ...prevFormState,
      formErrors: {
        ...prevFormState.formErrors,
        [name]: updatedFormErrors[name],
      },
    }));

    return updatedFormErrors[name];
  };

  const validateWithinUnitDateScope = () => {
    const { startDate, endDate, formErrors } = formState;
    const { startDate: unitStartDate, endDate: unitEndDate } = unit;

    const isStartDateValid = moment(startDate).isSameOrAfter(unitStartDate, "day");
    const isEndDateValid = moment(endDate).isSameOrBefore(unitEndDate, "day");

    const unitScopeErrors = {
      startDateScope: isStartDateValid
        ? initialFormState.formErrors.startDateScope
        : {
            valid: false,
            message: `${rules.startDateScope.scopeDateMessage} ${moment(unitStartDate).format(
              "MM/DD/YYYY"
            )}`,
          },
      endDateScope: isEndDateValid
        ? initialFormState.formErrors.endDateScope
        : {
            valid: false,
            message: `${rules.endDateScope.scopeDateMessage} ${moment(unitEndDate).format(
              "MM/DD/YYYY"
            )}`,
          },
    };

    setFormState((prevFormState) => ({
      ...prevFormState,
      formErrors: {
        ...prevFormState.formErrors,
        ...unitScopeErrors,
      },
    }));

    return {
      valid: unitScopeErrors.startDateScope.valid && unitScopeErrors.endDateScope.valid,
    };
  };

  const customValidation = () => {
    if (customProperties?.validation) {
      const customValidFields = customProperties?.validation(
        validateTextField,
        validateFormDateField
      );

      return { valid: customValidFields.every((field) => field.valid) };
    }

    return { valid: true };
  };

  const validateAllFields = () => {
    const validatedFields = [
      validateTextField(document.getElementById("name"), true),
      validateTextField(document.getElementById("description"), true),
      validateFormDateField("startDate", startDate),
      validateFormDateField("endDate", endDate),
      validateWithinUnitDateScope(),
      customValidation(),
    ];

    const isFormValid = validatedFields.every((field) => field.valid);

    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        formErrors: {
          ...prevFormState.formErrors,
          formValid: isFormValid,
        },
      };
    });

    return isFormValid;
  };

  const getAssestId = () => {
    // Here in case of video edit, if we pass the assetId as undefined the api is returning
    // previously stored video id . So passing the id as 0.
    return !asset.id ? "0" : asset.id;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const areAllFieldsValid = validateAllFields();

    if (areAllFieldsValid) {
      const { formErrors, ...formData } = formState;

      switch (type) {
        case ASSIGNMENT_TYPES.OPENSTAX:
          return onSubmit({
            type,
            ...formData,
            openstax_thumbnail: asset.openstax_thumbnail || null,
            openstax_title: asset.openstax_title || null,
            openstax_description: asset.openstax_description || null,
            openstax_url: asset.openstax_url || null,
            assetId: null,
          });

        default:
          return onSubmit({
            type,
            ...formData,
            assetId: asset
              ? type === ASSIGNMENT_TYPES.VIDEO
                ? getAssestId()
                : asset.assessment_id
              : null,
          });
      }
    }
  };

  // Side Effects
  // Fetch fresh assignment (schedules included in getAssignmentById) when editing assignment
  // Then update form with fetched assignment/schedule
  useEffect(() => {
    const updateAssignmentWithSchedule = async () => {
      const assignmentWithSchedule = await dispatch(
        getAssignmentById({ sectionId: courseId, assignmentId: activeAssignmentId })
      );
      const { name, description, startTs, endTs } = assignmentWithSchedule;
      const updatedCustomFormState = customFormState
        ? Object.keys(customFormState).reduce((customState, key) => {
            return {
              ...customState,
              [key]: assignmentWithSchedule[key],
            };
          }, {})
        : {};

      setFormState((prevFormState) => {
        return {
          ...prevFormState,
          name,
          description,
          startDate: moment(startTs),
          endDate: moment(endTs),
          ...updatedCustomFormState,
        };
      });
    };

    if (isEditMode && activeAssignmentId && courseId) {
      updateAssignmentWithSchedule();
    }
  }, [dispatch, isEditMode, activeAssignmentId, courseId, customFormState]);

  // Fetch assessments if edit mode and they haven't been fetched yet
  useEffect(() => {
    if (assignment) {
      const { assessmentSeriesId } = assignment;
      if (assessmentSeriesId !== "0" && !assessments.length) {
        dispatch(getAssessments());
      }
    }
  }, [dispatch, assignment, assessments.length]);

  // Set assignment assets needed to show attached asset
  useEffect(() => {
    if (assignment) {
      const {
        videoId,
        assessmentSeriesId,
        assignmentType,
        openstax_thumbnail,
        openstax_title,
        openstax_description,
        openstax_url,
      } = assignment;
      const shouldFetchAsset = !Object.keys(asset).length && !hasAssetLoaded;

      if (
        assignmentType === ASSIGNMENT_TYPES.VIDEO &&
        videoId !== "0" &&
        shouldFetchAsset &&
        videos.length
      ) {
        dispatch(setAssignmentAssets(videos.find((video) => video.id === videoId)));
        setHasAssetLoaded(true);
      }

      if (
        assignmentType === ASSIGNMENT_TYPES.OPENSTAX &&
        openstax_title !== null &&
        shouldFetchAsset
      ) {
        dispatch(
          setAssignmentAssets({
            openstax_thumbnail,
            openstax_title,
            openstax_description,
            openstax_url,
          })
        );
        setHasAssetLoaded(true);
      }

      if (
        [ASSIGNMENT_TYPES.PRACTICE, ASSIGNMENT_TYPES.EXAM].includes(assignmentType) &&
        assessmentSeriesId !== "0" &&
        shouldFetchAsset &&
        assessments.length
      ) {
        dispatch(
          setAssignmentAssets(
            assessments.find(
              (assessmentobj) => assessmentobj.assessment_id === Number(assessmentSeriesId)
            )
          )
        );
        setHasAssetLoaded(true);
      }
    }
  }, [dispatch, assignment, assessments, videos, asset, hasAssetLoaded]);

  // Render
  return (
    <Form hasLargeFields fulloNmobile tabletSize1 onSubmit={(e) => e.preventDefault()}>
      <FormHead>
        <FormTitle>
          {isEditMode ? "Edit" : "Create"} Assignment -{" "}
          {type === ASSIGNMENT_TYPES.VIDEO ? "Video" : type.charAt(0).toUpperCase() + type.slice(1)}
        </FormTitle>
        <FieldErrors formErrors={formErrors} apiErrorMessage={createAssignmentApiError} />
      </FormHead>
      <FormBody size1>
        <StyledFieldset disabled={isLoading}>
          <FormRowGrid justifyStart>
            <FormCol two_thirds>
              <FormControls bottomOffset="25">
                <Field
                  id="name"
                  name="name"
                  onChange={handleInputChange}
                  value={name}
                  placeholder="Assignment Name"
                  required
                  max="255"
                />
              </FormControls>
              <FormControls>
                <Field
                  id="description"
                  name="description"
                  onChange={handleInputChange}
                  value={description}
                  placeholder="Instructions"
                  isTextarea
                  max="255"
                />
              </FormControls>
              {customProperties?.LeftColumnFields &&
                customProperties?.LeftColumnFields(formState, handleInputChange)}
              {customProperties?.showAttachButton && (
                <Fragment>
                  {isEmpty(asset) && (
                    <FormControls>
                      <ButtonLink className="gray-btn" onClick={(e) => addAssets(e)}>
                        Add <IconSvgComponent iconPath="svg/ico-file.svg" />
                      </ButtonLink>
                    </FormControls>
                  )}
                  <FormRowGrid justifyStart>
                    <FormCol>
                      {asset && !isEmpty(asset) && (
                        <AttachmentCard type={type} asset={asset} removeCard={removeCard} />
                      )}
                    </FormCol>
                  </FormRowGrid>
                </Fragment>
              )}
            </FormCol>

            <FormCol third>
              <FormControls bottomOffset="20">
                <Field
                  id="startDate"
                  name="startDate"
                  icon="ico-calendar"
                  isDateTimePicker
                  onChange={(value) => {
                    handleDateChange("startDate")(value);
                    return endDateRef.current.setViewDate?.(value);
                  }}
                  value={startDate}
                  placeholder="Start Date"
                  showTimeSelect={false}
                  required
                />
              </FormControls>

              <FormControls>
                <Field
                  id="endDate"
                  name="endDate"
                  datePickerRef={endDateRef}
                  icon="ico-calendar"
                  isDateTimePicker
                  onChange={handleDateChange("endDate")}
                  value={endDate}
                  placeholder="End Date"
                  showTimeSelect={false}
                  required
                />
              </FormControls>

              {customProperties?.RightColumnFields &&
                customProperties?.RightColumnFields(
                  formState,
                  handleInputChange,
                  handleSliderChange,
                  onChangeRadioSelection
                )}

              <FormControls topOffset="25">
                <FormActions>
                  <ButtonLink isUnstyledButton onClick={onCancel}>
                    <span>Cancel</span>
                  </ButtonLink>
                  <CTAbtn>
                    <ButtonLink violet disabled={isLoading} onClick={handleFormSubmit}>
                      {isLoading ? (
                        <div style={{ width: "45px", height: "22px" }}>
                          <span className="loader" />
                        </div>
                      ) : (
                        <span>Assign</span>
                      )}
                    </ButtonLink>
                    <ToggleDropdown
                    // onClick={handleDropdownToggleClick}
                    />
                    {/* <BtnDropdown
                      className={classNames({
                        "is-active": toggleDropdownActive,
                      })}
                    >
                      <ul onClick={handleDropdownToggleClick}>
                        <li>
                          <button type="submit" onClick={handleFormSubmit}>
                            Assign
                          </button>
                        </li>
                        <li>
                          <button type="submit" disabled onClick={handleSaveDraft}>
                            Save Draft
                          </button>
                        </li>
                      </ul>
                    </BtnDropdown> */}
                  </CTAbtn>
                </FormActions>
              </FormControls>
            </FormCol>
          </FormRowGrid>
        </StyledFieldset>
      </FormBody>
    </Form>
  );
};

export default BaseAssignmentForm;
