import styled from "styled-components";
import theme from "../theme";
import Form, { FormControls } from "styles/components/Form";
import { media } from "../media";

export const CourseDetailsBlock = styled.div`
  width: 100%;
  display: flex;

  p {
    margin: 0;
  }

  .board-row-content {
    width: 100%;
  }

  ${media.mobile`
    flex-wrap: wrap;
 `}
`;

export const CourseDetailsBody = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const CourseDetailsAside = styled.aside`
  width: 210px;
  font-size: 16px;

  ${media.mobile`
    width: 100%;
    margin-bottom: 20px;
 `}
`;

export const CourseDetailsContent = styled.div`
  width: calc(100% - 210px);
  padding: 0 240px 0 30px;

  .btn-add-unit-block {
    width: 100%;
    background: ${theme.color.white};
    height: 68px;
    border-radius: 8px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s, background 0.3s;

    span {
      margin-right: 5px;
    }

    path {
      stroke: ${theme.color.red};
      transition: stroke 0.3s;
    }

    &.with-shadow {
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
    }

    &:hover {
      background: ${theme.color.red};
      color: ${theme.color.white};

      path {
        stroke: ${theme.color.white};
      }
    }
  }

  ${media.desktop`
    padding: 0 30px;
 `}

  ${media.tablet`
    padding: 0 10px;
 `}

 ${media.mobile`
    width: 100%;
    padding: 0;
 `}
`;

export const CourseDetailsTitle = styled.h3`
  font-size: 22px;
  margin: 0;
`;

export const CourseDetailsTitleSmall = styled.small`
  display: block;
  font-size: 16px;
  font-weight: 300;
`;

export const CourseDetailsFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 0 0 100%;
  align-items: center;

  ${CourseDetailsTitle} {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const CourseDetailsBack = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;

  .svg-icon {
    margin: 0 10px 0 0;
    svg {
      width: 15px;
      height: 11px;
    }
  }
`;

export const CourseDetailsGroup = styled.div`
  font-size: 16px;
  margin: 0 0 30px;

  .dropdown-wrapper {
    position: relative;
    margin-right: 0;
  }

  .btn-add-item {
    font-size: 14px;
    color: ${theme.color.darker_gray};
    text-transform: uppercase;
    padding: 0;

    span {
      margin-left: 7px;
    }

    path {
      stroke: ${theme.color.red};
    }
  }

  ${media.mobile`
    font-size: 14px;
 `}
`;

export const CourseDetailsGroupHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const CourseDetailsGroupBody = styled.div``;

export const CourseDetailsUnitsList = styled.div`
  padding-top: 25px;
  font-size: 18px;

  .btn-add-unit-item {
    display: flex;
    align-items: center;
    margin-top: 8px;

    span {
      margin-right: 5px;
    }

    path {
      stroke: ${theme.color.red};
    }

    &:hover {
      color: ${theme.color.red};
    }
  }
`;

export const CourseDetailsUnitsListTitle = styled.h4`
  font-size: 18px;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    color: ${theme.color.blue};
  }

  ${(props) =>
    props.active &&
    `
    color: ${theme.color.blue};
  `}
`;

export const CourseDetailsUnitsListItem = styled.div`
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: ${theme.color.blue};
  }

  ${(props) =>
    props.active &&
    `
    color: ${theme.color.blue};
  `}
`;

export const CourseDetailsUnitBlock = styled.div`
  background: ${theme.color.white};
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 15px 20px;
  position: relative;
  width: 100%;

  > .dropdown-wrapper {
    position: absolute;
    top: 20px;
    right: 10px;
    margin-right: 0;

    > button {
      height: auto;
      margin: 0 -9px 0 0;
      transform: rotate(90deg);

      span {
        background: #9b9b9b;
      }
    }
  }

  .units-drag-container {
    padding-top: 10px;
  }

  .units-drag-placeholder {
    background: #e3ebfa;
    border-radius: 8px;
  }

  ${(props) =>
    props.isEmpty &&
    `
		display: flex;
		justify-content: space-around;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 30px;

    `}

    ${media.tablet`
        ${(props) =>
          props.isEmpty &&
          `
		    padding: 20px;

        `}
    `}

  ${media.mobile`
        padding: 15px;
    `}
`;

export const CourseDetailsUnitBlockNotice = styled.div`
  width: 33.33%;
  max-width: 174px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 4px;

  .svg-icon {
    margin-bottom: 28px;
  }

  ${media.mobile`
       width: 100%;
       max-width: 100%;
       margin-bottom: 20px;

       .svg-icon {
            margin-bottom: 20px;
        }
    `};
`;

export const CourseDetailsGroupCard = styled.div`
  margin: 0 0 26px;
  padding: 10px 30px 25px;
  border-radius: 8px;
  background: #fff;

  ${FormControls} {
    margin: 0 0 10px;

    input {
      padding: 16px 0 12px;
    }

    input,
    label {
      font-size: 20px;
    }

    .is-focused,
    .is-filled {
      label {
        transform: translateY(-18px) scale(0.6);
      }
    }
  }

  button {
    padding: 0;
    color: ${theme.color.darker_gray};

    span {
      font-size: 14px;
      font-weight: 300;
    }

    &:hover {
      color: ${theme.color.red};
    }
  }

  .icon-add {
    margin: 0 5px 0 0;
    path {
      stroke: ${theme.color.red};
    }
  }
`;

export const CourseDetailsGroupCardTitle = styled.div`
  margin: 10px 0 5px;
  font-size: 18px;
  font-weight: 700;
  color: ${theme.color.darker_gray};
`;

export const CourseDetailsGroupCardFlex = styled.div`
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  font-size: 20px;
  line-height: 1;
`;

export const CourseDetailsAlign = styled.div`
  ${(props) =>
    props.align &&
    `
    text-align: ${props.align};
  `}

  ${(props) =>
    props.sticky &&
    `
    position: sticky;
    top: 10px;
    z-index: 99;
  `}

  ${(props) =>
    props.margin &&
    `
    margin: ${props.margin};
  `}

  ${media.mobile`
    margin: 0 0 10px;

    button {
      width: 100%;
      max-width: 100%;
    }
  `}
`;

export default CourseDetailsBlock;
