import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import FormLogin from "components/form/FormLogin";
import FormCreatePassword from "components/form/FormCreatePassword";
import FormPasswordReset from "components/form/FormPasswordReset";
import FormChangePassword from "components/form/FormChangePassword";
import FormUconnSSO from "components/form/FormUconnSSO";

const Login = () => {
  return (
    <Switch>
      <Route exact path="/login" component={FormLogin} />
      <Route exact path="/login/sso-redirect" component={FormUconnSSO} />
      <Route exact path="/login/password-reset" component={FormPasswordReset} />
      <Route exact path="/login/create-password" component={FormCreatePassword} />
      <Route exact path="/login/first-time-login" component={FormCreatePassword} />

      <Route
        exact
        path="/login/change-password"
        render={(props) => {
          return <FormChangePassword {...props} />;
        }}
      />
    </Switch>
  );
};

export default Login;
