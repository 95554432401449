import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MODAL_ASSESSMENT_PASSCODE, MODAL_SHOW_ERROR_MESSAGE } from "lib/constants";
import { openModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import StyledLink from "components/common/ButtonLink";
import { AssessmentContainer } from "styles/components/SelectAssessment";
import {
  getPlacementAssessment,
  startCountdown,
  beginAttempt,
} from "store/state/assessment/actions";
import { getCourseAssessment } from "store/state/courseAssessment/actions";
import { MODAL_CREATE_STUDENT } from "lib/constants";

import {
  MessageContainer,
  MessageHead,
  MessageTitle,
  MessageBody,
  MessageText,
  MessageWarning,
  MessageActions,
} from "styles/components/AssessmentWelcomeMessage";
import { ASSESSMENT_TYPES } from "constants/common";
import stemifyRoutes from "../../constants/routes";

class AssessmentWelcomeMessage extends Component {
  state = { isLoading: false };

  handleButtonClick = async () => {
    const { openModal, startCountdown, isAccessCodeRequired, beginAttempt, history } = this.props;
    const {
      assessmentId,
      deploymentId: deploymentConfigId,
      courseId,
      assignmentId,
    } = this.props.match.params;

    const handleBeginAttempt = async (accessCode = "") => {
      this.setState({ isLoading: true });

      const beginQuery = assignmentId
        ? {
            type: ASSESSMENT_TYPES.EXAM,
            assessmentSeriesId: assessmentId,
            assignmentId,
            courseId,
            accessCode,
          }
        : {
            type: ASSESSMENT_TYPES.PLACEMENT,
            deploymentConfigId,
            assessmentSeriesId: assessmentId,
            accessCode,
          };

      try {
        const { status, data } = await beginAttempt(beginQuery);

        if (status === "success") {
          startCountdown();
          // redirect to route with attemptId
          history.push(
            assignmentId
              ? `${stemifyRoutes.courses}/${courseId}/coursework/${assignmentId}/exam/${assessmentId}/${data.attempt.id}`
              : `${stemifyRoutes.assessments}/${deploymentConfigId}/${assessmentId}/${data.attempt.id}`
          );
        }

        if (status === "error") {
          // Error status
          openModal({
            type: MODAL_SHOW_ERROR_MESSAGE,
            data: {
              errorMessage: data?.message,
              shouldRedirect: true,
              redirectTo: `${stemifyRoutes.assessments}`,
            },
          });
        }
      } catch (error) {
        console.log({ error });
        openModal({
          type: MODAL_SHOW_ERROR_MESSAGE,
          data: {
            errorMessage: error?.message || "System Error.",
            shouldRedirect: true,
            redirectTo: `${stemifyRoutes.assessments}`,
          },
        });
      } finally {
        this.setState({ isLoading: false });
      }
    };

    if (isAccessCodeRequired) {
      openModal({
        type: MODAL_ASSESSMENT_PASSCODE,
        data: {
          handleBeginAttempt: (accessCode) => handleBeginAttempt(accessCode),
        },
      });
    } else {
      handleBeginAttempt();
    }
  };

  render = () => {
    const { assessments, assignments, match } = this.props;
    const { assignmentId, deploymentId: deploymentConfigId, assessmentId } = match.params;
    const { isLoading } = this.state;

    const selectedAssessment = assessments.find(
      (assessment) =>
        assessment.id === assessmentId && assessment.deployment_config_id === deploymentConfigId
    );

    const welcomeMessage = assignmentId
      ? assignments[assignmentId]?.description
      : selectedAssessment?.message;
    const welcomeHeadline = assignmentId
      ? assignments[assignmentId]?.name
      : "Welcome to your math placement exam!";

    return (
      <AssessmentContainer>
        <MessageContainer>
          <MessageHead>
            <MessageTitle>{welcomeHeadline}</MessageTitle>
          </MessageHead>
          {welcomeMessage && (
            <MessageBody>
              <MessageText
                className="welcome-message"
                {...{
                  ...(welcomeMessage
                    ? { dangerouslySetInnerHTML: { __html: welcomeMessage } }
                    : {}),
                  ...(deploymentConfigId ? { style: { textAlign: "left" } } : {}),
                }}
              />
            </MessageBody>
          )}
          <MessageActions>
            <StyledLink
              danger
              hasIcon
              className="btn"
              onClick={this.handleButtonClick}
              disabled={isLoading}
            >
              Begin Assessment <IconSvgComponent iconPath="svg/ico-pointer-right.svg" />
            </StyledLink>
          </MessageActions>
        </MessageContainer>
      </AssessmentContainer>
    );
  };
}

export default connect(
  (state) => ({
    isAccessCodeRequired:
      state.assessment.data.find(
        (row) => row.deployment_config_id === state.assessment.deploymentId
      )?.isAccessCodeRequired || false,
    apiCallInProgress: state.assessment.apiCallInProgress,
    assessments: state.assessment.data,
    assignments: state.storedCourses.assignments,
  }),
  {
    openModal,
    getCourseAssessment,
    getPlacementAssessment,
    startCountdown,
    beginAttempt,
  }
)(withRouter(AssessmentWelcomeMessage));
