/**
 * @ The external dependecies
 */
import { closeModal } from "store/state/ui/actions";
import React from "react";
import IconSvgComponent from "components/common/IconSvgComponent";
import { connect } from "react-redux";
import { create } from "store/state/administration/actions";
import ButtonLink from "components/common/ButtonLink";
import { FormHead, FormLogo, FormActions, LabelBoldBlue } from "styles/components/Form";
/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
/**
 * Class for modal create user.
 *
 * @class      ModalCreateUser (name)
 */
class ModalUserLimitReached extends React.Component {
  render() {
    return (
      <ModalFrame>
        <FormHead>
          <FormLogo>
            <IconSvgComponent iconPath="svg/ico-user-limit-reached.svg" />
          </FormLogo>
          <h3>User limit has been reached</h3>
          <p>Your user limit for this account has been already reached.</p>
          {this.props.modal.data && this.props.modal.data.user && (
            <p>
              The user <LabelBoldBlue>{this.props.modal.data.user.firstName}</LabelBoldBlue> has
              been created, but their status has been set to inactive. To activate this user you
              must first deactivate an existing active user.
            </p>
          )}
        </FormHead>
        <FormActions>
          <ButtonLink
            violet
            onClick={() => {
              this.props.closeModal();
            }}
            type="button"
          >
            Dismiss
          </ButtonLink>

          {/* {modal.data.status === 'active' ? (
                        <ButtonLink type="submit" violet hasIcon>
                            <span>Deactivate</span>

                            <IconSvgComponent iconPath="svg/ico-check-white.svg" />
                        </ButtonLink>
                    ) : (
                            <ButtonLink type="submit" violet hasIcon>
                                <span>Activate</span>

                                <IconSvgComponent iconPath="svg/ico-check-white.svg" />
                            </ButtonLink>
                        )} */}
        </FormActions>
      </ModalFrame>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    create,
    closeModal,
  }
)(ModalUserLimitReached);
