/**
 * The external dependencies.
 */
import React from "react";
import styled from "styled-components";

/**
 * The internal dependencies.
 */
import StyledButton from "styles/components/Btns";
import IconSvgComponent from "components/common/IconSvgComponent";

/**
 * @class      Box (name)
 * @param      {String} / The icon
 * @param      {String} / The title
 * @param      {String} / The entry button text
 * @param      {Function} / The button click
 * @return     {JSX}
 */
const Box = ({ icon, title, entry, btnText, btnClick }) => {
  return (
    <Wrapper>
      <Icon>
        <span>
          <IconSvgComponent iconPath={`svg/${icon}.svg`} />
        </span>

        <span>
          <IconSvgComponent iconPath={`svg/${icon}-colored.svg`} />
        </span>
      </Icon>

      <Title>{title}</Title>

      <Entry>{entry}</Entry>

      <Actions>
        <BoxButton onClick={btnClick} violet>
          {btnText}
        </BoxButton>
      </Actions>
    </Wrapper>
  );
};

/**
 * The supported styles of the component.
 *
 * @type {StyledComponent Function}
 */
const Icon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  margin-bottom: 9px;
  padding: 0 31px;

  span {
    transition: opacity 0.3s, visibility 0.3s;
  }

  span + span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
  }
`;
const Title = styled.h4`
  padding: 0 15px;
  margin-bottom: 5px;
  line-height: 2;
  color: #484848;
  font-weight: bolder;
  font-size: large;
`;
const Entry = styled.p`
  padding: 0 31px;
  margin-bottom: 24px;
`;
const Actions = styled.div`
  padding: 0 31px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
`;
const BoxButton = styled(StyledButton)`
  width: 100%;
`;
const Wrapper = styled.div`
  padding: 20px 0 26px;
  text-align: center;
  color: #a3aaad;
  transition: background 0.3s, box-shadow 0.3s;

  &:hover {
    background: #fff;
    box-shadow: 0 11px 20px 1px rgba(0, 0, 0, 0.15);

    ${Icon} {
      span + span {
        opacity: 1;
        visibility: visible;
      }
    }

    ${Actions} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export default Box;
