/**
 * @ External Dependencies
 */
import { createAction } from "redux-actions";

import { OPERATIONS } from "constants/common";
import { COMMON_MESSAGES } from "constants/messages";
import {
  request,
  reject,
  create,
  update,
  list,
  clearAPIError,
} from "store/state/administration/actions";
import {
  callCreateDeploymentAPI,
  callUpdateDeploymentAPI,
  callGetDeploymentByIdAPI,
} from "services/deployments";
import { getUserCurrentAccount, removeKeysByValues } from "utilities/commonFunctions";

import { getUserProgram } from "services/users";

/**
 * @ Action creators
 */
export const updateDeploymentState = createAction("deployment/update");
export const clearDeployment = createAction("deployment/clear");
export const closeModal = createAction("ui/close-modal");

export const clearAPIErrors = () => async (dispatch) => {
  dispatch(clearAPIError({ selector: "deployments", operation: OPERATIONS.CREATE }));
  dispatch(clearAPIError({ selector: "deployments", operation: OPERATIONS.UPDATE }));
};

export const createDeployment = ({ selector, item }) => async (dispatch, getState) => {
  try {
    dispatch(request({ selector, operation: OPERATIONS.CREATE }));

    const state = getState();

    var accountId = getUserCurrentAccount(state);

    let apiRequest = buildDeploymentRequest(item);

    let apiResponse = await callCreateDeploymentAPI(apiRequest, accountId);

    const programLabel = item.program ? item.program.label : "";
    const cohortLabel = item.cohort ? item.cohort.label : "";

    if (apiResponse.status === "success") {
      let createdDeployment = decorateDeploymentResponse(
        apiResponse.data,
        programLabel,
        cohortLabel
      );
      if (item.is_draft == true) {
        dispatch(
          updateDeploymentState(
            buildDeploymentDataForFormState(apiResponse.data, programLabel, cohortLabel)
          )
        );
      }
      dispatch(create({ item: createdDeployment, selector }));
      return apiResponse;
    } else {
      dispatch(reject({ selector, operation: OPERATIONS.CREATE, errorItem: apiResponse.data }));
      return apiResponse;
    }
  } catch (e) {
    console.log(e.stack);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.CREATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const updateDeployment = (deployment) => async (dispatch, getState) => {
  const state = getState();
  var requestObject = { ...deployment, id: state.deployment.id };
  if (!parseInt(state.deployment.id)) {
    dispatch(updateDeploymentState({ ...deployment }));
    return dispatch(createDeployment({ selector: "deployments", item: requestObject }));
  } else {
    dispatch(updateDeploymentState({ ...deployment }));
    return dispatch(editUpdateDeployment({ selector: "deployments", item: requestObject }));
  }
};

function buildDeploymentRequest(item) {
  var jsonObject = {
    name: item.name ? item.name : null,
    program_id: item.program && item.program.value ? parseInt(item.program.value) : null,
    is_draft: item.is_draft,
    owner_name: item.ownerName ? item.ownerName : null,
    owner_email: item.ownerEmail ? item.ownerEmail : null,
    pretest_content_review:
      typeof item.completed_before_retake != undefined ? item.completed_before_retake : null,
    is_access_code_required: typeof item.restricted != undefined ? item.restricted : null,
    access_code: item.accessCode ? item.accessCode : null,
    start_date: item.startDate ? item.startDate : null,
    end_date: item.endDate ? item.endDate : null,
    cohort_id: item.cohort && item.cohort.value ? item.cohort.value : null,
    message: item.message ? item.message : null,
    assessments: item.assessments ? item.assessments : [],
  };
  if (item.is_draft) {
    const requestParams = removeKeysByValues(jsonObject, [null]); // remove all keys having null values to submit only formfields on current tab.
    return requestParams;
  } else {
    return jsonObject;
  }
}

export const editUpdateDeployment = ({ selector, item }) => async (dispatch, getState) => {
  try {
    dispatch(request({ selector, operation: OPERATIONS.UPDATE }));

    const state = getState();

    var accountId = getUserCurrentAccount(state);

    let apiRequest = buildDeploymentRequest(item);
    let apiResponse = await callUpdateDeploymentAPI(item.id, apiRequest, accountId);

    const programLabel = item.program ? item.program.label : null;
    const cohortLabel = item.cohort ? item.cohort.label : null;

    if (apiResponse.status === "success") {
      let updatedDeployment = decorateDeploymentResponse(
        apiResponse.data,
        programLabel,
        cohortLabel
      );
      dispatch(update({ id: updatedDeployment.id, updates: updatedDeployment, selector }));
      const apiResponseObject = buildDeploymentDataForFormState(
        apiResponse.data,
        programLabel,
        cohortLabel
      );
      dispatch(updateDeploymentState(apiResponseObject));
      return apiResponse;
    } else {
      dispatch(reject({ selector, operation: OPERATIONS.UPDATE, errorItem: apiResponse.data }));
      return apiResponse;
    }
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.UPDATE,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

function buildDeploymentDataForFormState(item, program_name, cohort_name) {
  var obj = removeKeysByValues(item, [null]);
  obj.program = { label: program_name ? program_name : obj.program_name, value: obj.program_id };
  obj.cohort = { label: cohort_name ? cohort_name : obj.cohort_name, value: obj.cohort_id };
  obj.restricted = item.is_access_code_required;
  return obj;
}

function decorateDeploymentResponse(obj, program, cohort) {
  return {
    id: obj.id,
    name: obj.name,
    programId: obj.program_id,
    program: program ? program : obj.program_name,
    retakeNumber: obj.retake_number,
    coolingPeriod: obj.cooling_period,
    deliverVersionsRandomly: obj.deliver_versions_randomly,
    ownerName: obj.owner_name,
    ownerEmail: obj.owner_email,
    pretestContentReview: obj.pretest_content_review,
    isAccessCodeRequired: obj.is_access_code_required,
    restricted: obj.is_access_code_required,
    accessCode: obj.access_code,
    assessmentTime: obj.assessment_time ? obj.assessment_time / 60 : "",
    startDate: obj.start_date,
    endDate: obj.end_date,
    cohortId: obj.cohort_id,
    cohort: cohort ? cohort : obj.cohort_name,
    message: obj.message ? obj.message : "",
    account_id: obj.account_id,
    status: obj.status,
  };
}

export const getAllPrograms = () => (dispatch, getState) => {
  const selector = "programs";
  try {
    dispatch(request({ selector, operation: OPERATIONS.LIST }));

    const state = getState();
    var accountId = getUserCurrentAccount(state);

    return getUserProgram({ accountId }).then((response) => {
      if (response.status == "success") {
        dispatch(list({ selector, items: response.data }));
      } else {
        dispatch(reject({ selector, operation: OPERATIONS.LIST, errorItem: response.data }));
      }
    });
  } catch (e) {
    console.log(e.message);
    dispatch(
      reject({
        selector,
        operation: OPERATIONS.LIST,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const getDeploymentById = ({ selector, id }) => {
  return async (dispatch, getState) => {
    const state = getState();
    var accountId = getUserCurrentAccount(state);
    dispatch(request({ selector, operation: OPERATIONS.GET }));
    const response = await callGetDeploymentByIdAPI({ id, accountId, selector });
    if (response.status == "success") {
      const deploymentData = buildDeploymentDataForFormState(response.data[0], false, false);
      dispatch(updateDeploymentState(deploymentData));
    }
  };
};
