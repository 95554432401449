import { cohortsPath, cohortByIdPath, cohorts } from "../constants/api";
import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";

const callGetCohortAPI = async (accountId, page, pageSize) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath:
      pageSize === -1 ? cohortsPath : `${cohortsPath}?pageSize=${pageSize}&page=${page}`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const callGetCohortByIdAPI = async (accountId, id) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${cohortsPath}/${id}`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const callCreateCohortAPI = async (cohortData, accountId) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: cohortsPath,
    method: "post",
    body: JSON.stringify(cohortData),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const callUpdateCohortAPI = async (cohortData, cohortId, accountId) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: cohortByIdPath(cohortId),
    method: "put",
    body: JSON.stringify(cohortData),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getStudentsForCohort = async (cohort, accountId, page, pageSize = 10, searchParams = "") => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath:
      pageSize === -1
        ? `${cohortsPath}/${cohort}/user`
        : `${cohortsPath}/${cohort}/user?pageSize=${pageSize}&page=${page}&${searchParams}`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const removeCohortStudent = async (studentId, cohortId, accountId) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: cohortsPath + "/" + cohortId + "/user",
    method: "delete",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
    body: JSON.stringify({ users: [studentId] }),
  });
};

const addCohortStudent = async ({ arrUserHashIds, cohortId, accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: cohortsPath + "/" + cohortId + "/user",
    method: "post",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
    body: JSON.stringify({ users: arrUserHashIds }),
  });
};

const callMigrateCohortEnrollmentAPI = async ({ body, accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: "/user/cohort/migrate",
    method: "put",
    body: JSON.stringify(body),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export {
  getStudentsForCohort,
  callGetCohortAPI,
  callGetCohortByIdAPI,
  callCreateCohortAPI,
  callUpdateCohortAPI,
  removeCohortStudent,
  addCohortStudent,
  callMigrateCohortEnrollmentAPI,
};
