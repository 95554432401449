import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import ui from "./state/ui/reducer";
import questions from "./state/questions/reducer";
import administration from "./state/administration/reducer";
import user from "./state/user/reducer";
import deployment from "./state/deployment/reducer";
import learningLibrary from "./state/learningLibrary/reducer";
import video from "./state/video/reducer";
import assessment from "./state/assessment/reducer";
import practiceAssignment from "./state/practiceAssignment/reducer";
import studentDashboard from "./state/studentDashboard/reducer";
import facultyDashboard from "./state/facultyDashboard/reducer";
import storedCourses from "./state/courses/reducer";
import builderAssessment from "./state/builderAssessment/reducer";
import courseAssessment from "store/state/courseAssessment/reducer";
import { successLogout } from "store/state/user/actions";

import { appEnv } from "utilities/API.js";

const appReducer = combineReducers({
  questions,
  administration,
  ui,
  user,
  deployment,
  learningLibrary,
  video,
  assessment,
  courseAssessment,
  practiceAssignment,
  studentDashboard,
  facultyDashboard,
  storedCourses,
  builderAssessment,
});

const rootReducer = (state, action) => {
  if (action.type === successLogout().type) {
    state = undefined;
  }
  return appReducer(state, action);
};

let componseObj = null;
if (appEnv !== "PROD") {
  const composeEnhancers = composeWithDevTools({
    trace: true,
    // actionSanitizer: (action) => {
    //   const type = action.payload?.selector
    //     ? `${action.payload.selector.toUpperCase()} - ${action.type}`
    //     : action.type;

    //   return { ...action, type };
    // },
  });

  componseObj = compose(applyMiddleware(thunkMiddleware), composeEnhancers());
} else {
  componseObj = compose(applyMiddleware(thunkMiddleware));
}

export default createStore(rootReducer, {}, componseObj);
