import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { equals } from "ramda";
import FullscreenModalItem from "components/courses/FullscreenModal";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import { openModal, closeModal } from "store/state/ui/actions";
import { PaginatedTable, NoDataComponent } from "components/common/table";
import { FullscreenModalContent } from "styles/components/FullscreenModal";
import SearchContainer, { SearchField, SearchButton } from "styles/components/Search";
import {
  Row,
  Title,
  RowSidebar,
  SidebarTitle,
  RowContent,
  Tab,
  Expander,
  SubComponent,
  FilterBtn,
  PanelList,
  PanelListItem,
} from "styles/components/ModalCoursesAddToAssignment";
import { setAssignmentAssets } from "store/state/courses/actions";
import "styles/assessmentList_react_table_override.css";
import { ASSIGNMENT_TYPES } from "constants/common";

const getFilteredAssessments = (search, assessments) => {
  return assessments.filter((assessment) => {
    return (
      assessment?.assessment_name?.toLowerCase().includes(search.toLowerCase()) ||
      assessment?.concept?.findIndex(
        (concept) => concept && concept.toLowerCase().includes(search.toLowerCase())
      ) !== -1
    );
  });
};

const getPaginationProperties = ({ filteredData, pageSize }) => {
  // console.log("getPaginationPropertiews", { filteredData, pageSize });
  return {
    count: filteredData.length,
    pages: Math.ceil(filteredData.length / pageSize),
  };
};

const getPageData = ({ data, pageSize, page }) => {
  const skip = pageSize * page;
  return data.slice(skip, skip + pageSize);
};

class ModalCourseAddAssessments extends Component {
  state = {
    selectedConcept: {},
    search: "",
    pageSize: 5,
    page: 0,
    pages: null,
    count: 0,
  };

  columns = [
    {
      id: "assessmentName",
      Header: "ASSESSMENT NAME",
      accessor: "assessment_name",
      minWidth: 160,
    },
    {
      id: "createdBy",
      Header: "CREATED BY",
      accessor: "created_by",
      minWidth: 160,
    },
    {
      expander: true,
      id: "concept",
      Header: "CONCEPTS",
      accessor: "concept_count",
      width: 84,
      Expander: ({ isExpanded, ...rest }) => (
        <Expander>
          {rest.original?.concept?.length}
          <span className={classNames({ "is-expanded": isExpanded })}></span>
        </Expander>
      ),
    },
    {
      id: "selectAssessment",
      Header: "",
      minWidth: 100,
      Cell: (props) => {
        return (
          <ButtonLink transparent xs style={{ color: "blue" }}>
            <span onClick={() => this.saveAssets(props)}>SELECT</span>
          </ButtonLink>
        );
      },
    },
  ];

  handleConceptSearch = (currentConcept) => {
    const { selectedConcept } = this.state;

    if (currentConcept.name === selectedConcept.name) {
      this.setState({
        selectedConcept: {},
      });
    } else {
      this.setState({
        selectedConcept: currentConcept,
      });
    }
  };

  handleSearchChange = (event) => {
    const { value } = event.target;

    this.setState({
      search: value,
    });
  };

  saveAssets = (props) => {
    const { row } = props;
    this.props.setAssignmentAssets(row.original);
    this.setState(
      {
        pageSize: 5,
        page: 0,
        pages: null,
        count: 0,
      },
      () => {
        this.props.onClose();
      }
    );
  };

  render() {
    const { search, pageSize, page } = this.state;
    const { assessments } = this.props;
    const filteredAssessments = getFilteredAssessments(search, assessments);
    const { count } = getPaginationProperties({
      filteredData: filteredAssessments,
      pageSize,
    });
    const pageData = getPageData({ data: filteredAssessments, pageSize, page });

    return (
      <FullscreenModalItem
        active={this.props.active}
        onClose={() => {
          this.setState(
            {
              pageSize: 5,
              page: 0,
              pages: null,
              count: 0,
            },
            () => {
              this.props.onClose();
            }
          );
        }}
      >
        <FullscreenModalContent max_width="100%">
          <Row>
            <Title>Select Assessment for Assignment</Title>

            {/* Concept filter implemetation code. Uncomment the below code after scoped demo */}
            {/* <RowSidebar>
              <SidebarTitle>Concept Filter</SidebarTitle>
              <PanelList>
                {conceptFilter.map((concept, index) => {
                  return (
                    <PanelListItem
                      key={"concept" + index}
                      onClick={() => this.handleConceptSearch(concept)}
                      selected={concept.name === selectedConcept.name}
                    >
                      {concept.name}
                    </PanelListItem>
                  );
                })}
              </PanelList>
            </RowSidebar> */}

            <RowContent>
              <div className="tabs__content">
                <Tab className={classNames({ "is-active": true })}>
                  <SearchContainer>
                    <SearchField
                      placeholder="Search by assessment name, concept, etc…"
                      onChange={this.handleSearchChange}
                      value={search}
                    />
                    <FilterBtn>
                      <IconSvgComponent iconPath="svg/ico-filter.svg" />
                    </FilterBtn>
                    <SearchButton>
                      <IconSvgComponent iconPath="svg/ico-magnifier.svg" />
                    </SearchButton>
                  </SearchContainer>
                  <div style={{ height: "500px" }}>
                    <PaginatedTable
                      data={filteredAssessments}
                      columns={this.columns}
                      count={filteredAssessments.length}
                      isLoading={false}
                      style={{ fontSize: "14px", fontWeight: 500 }}
                      NoDataComponent={() => <NoDataComponent type="Topic" />}
                      SubComponent={(row, item) => {
                        return (
                          <SubComponent key={`subrow-${row.original.id}`}>
                            {row.original.concept.map((item, index) => (
                              <React.Fragment>
                                <span key={`subrow-item-${row.original.id}-${index}`}>{item}</span>
                                <br />
                              </React.Fragment>
                            ))}
                          </SubComponent>
                        );
                      }}
                    />
                  </div>
                </Tab>
              </div>
            </RowContent>
          </Row>
        </FullscreenModalContent>
      </FullscreenModalItem>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    modal: state.ui.modal,
    assessments:
      ownProps.assignmentType === ASSIGNMENT_TYPES.PRACTICE
        ? Object.values(state.storedCourses.assessments).filter(
            (assessment) => assessment.type === "Practice Assessment"
          )
        : Object.values(state.storedCourses.assessments).filter(
            (assessment) => assessment.type === "Exam"
          ),
    // conceptFilter: state.storedCourses.assessments.concept,
  }),
  {
    openModal,
    closeModal,
    setAssignmentAssets,
  }
)(ModalCourseAddAssessments);
