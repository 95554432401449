/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import FormRemoveStudent from "components/form/FormRemoveStudent";

/**
 * @class      ModalRemoveStudent (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */
const ModalRemoveStudent = (props) => {
  return (
    <ModalFrame>
      <FormRemoveStudent />
    </ModalFrame>
  );
};

export default ModalRemoveStudent;
