/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */
import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Form, { FormHead, FormLogo, FormTitle, FormActions } from "styles/components/Form";

import { deleteBuilderQuestion } from "store/state/builderAssessment/actions";

/**
 * Class for form archive.
 *
 * @class      FormArchive (name)
 */
class FormArchive extends Component {
  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props.deleteBuilderQuestion();

    this.props.closeModal();
  };

  render() {
    const { closeModal, modal } = this.props;

    return (
      <Form onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            <IconSvgComponent iconPath="svg/review-question-error.svg" />
          </FormLogo>

          <FormTitle>Are you sure you want to delete this Question?</FormTitle>
        </FormHead>

        <FormActions>
          <ButtonLink transparent onClick={() => closeModal()} type="button">
            Cancel
          </ButtonLink>

          <ButtonLink type="submit" violet hasIcon>
            <span>Delete</span>

            <IconSvgComponent iconPath="svg/ico-check-white.svg" />
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    closeModal,
    deleteBuilderQuestion,
  }
)(FormArchive);
