/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";

const SectionCourses = styled.section`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  padding: 30px;

  .btn-add {
    position: fixed;
    bottom: 20px;
    right: 20px;

    &.has-widtget {
      bottom: 60px;
    }
  }

  ${media.mobile`
		padding: 10px;
	`}
`;

export const SectionBody = styled.div`
  flex: 1 1 100%;
  text-align: center;
  display: flex;
`;

export const SectionTextblock = styled.div`
  max-width: 400px;
  margin: 0 auto;
  align-self: center;
  text-align: center;
`;

export default SectionCourses;
