/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { isEmpty } from "ramda";
import MediaQuery from "react-responsive";
import moment from "moment";
import { connect } from "react-redux";

import { FORMAT } from "constants/common";
import DeploymentChartStudent from "components/chart/DeploymentChartStudent";
import { StyledSpan } from "styles/components/TableDefault";
import { PaginatedTable, NoDataComponent } from "components/common/table";
import Grid, { Col, Row } from "styles/components/Grid";
import SectionDashboard, { SectionBody } from "styles/components/SectionDashboard";
import Frame, { FrameBox, FrameTitle } from "styles/components/Frame";
import List, { ListStats, ListItem } from "styles/components/List";
import Stat, { StatTitle, StatEntry } from "styles/components/Stat";
import IconSvgComponent from "components/common/IconSvgComponent";
import { FormattedText } from "../common/FormattedText";
import { IMAGE_S3_SERVER } from "constants/api";

import StudentInfo, {
  StudentInfoTitle,
  StudentInfoEntry,
  StudentInfoIcon,
  StudentInfoContent,
  StudentInfoQuestion,
  StudentInfoQuestionNumber,
  StudentInfoQuestionHead,
  StudentInfoQuestionTitle,
  StudentInfoQuestionList,
  StudentInfoQuestionListItem,
  StudentInfoQuestionListIndicator,
  StudentInfoQuestionNotice,
} from "styles/components/StudentInfo";
import AnswerOptionsContainer from "components/views/AnswerOptionsContainer";

import { NavigationList, NavigationListItem } from "styles/components/QuestionsNavigation";
import NavigationQuestion from "components/practiceAssignment/NavigationQuestion";

import Loader from "components/common/loader";
import { NoDataMessageContainer } from "styles/components/Card";
import { NoDataMessage } from "styles/components/Card";

import {
  getStudentAttemptDashboardData,
  clearStudentAttemptDashboardData,
  getAttemptPerformanceData,
} from "store/state/facultyDashboard/actions";

import {
  AssessmentQuestionNumber,
  AssessmentQuestionImage,
  AssessmentQuestionText,
  OptionImage,
  AnswerLabel,
  AnswerImage,
  AnswerText,
} from "styles/components/AssessmentQuestions";
import DataTable from "./DataTable";

class AssessmentDashboardStudentAttempt extends Component {
  state = {
    table: {
      pageSize: 5,
      page: 0,
      pages: null,
      count: 0,
    },
    chartColors: ["#2645EF", "#FF5A62", "#96A0B2"],
    selectedChartArcNumber: null,
    showQuestionIndex: null,
  };

  getData = ({ topicName, classPerformance, studentPerformance }) => {
    return {
      topicName,
      classPerformance,
      studentPerformance,
    };
  };

  columns = [
    {
      id: "topicName",
      Header: "Topic",
      minWidth: 200,
      accessor: "topicName",
      Cell: (props) => {
        const { original } = props.row;
        return (
          <StyledSpan wrappedText bold>
            {original.topicName}
          </StyledSpan>
        );
      },
    },
    {
      id: "classPerformance",
      Header: "Class Performance",
      accessor: "classPerformance",
      minWidth: 75,
      Cell: (props) => {
        const { original } = props.row;
        return <span>{original.classPerformance}%</span>;
      },
    },
    {
      id: "studentPerformance",
      Header: "Student Performance",
      accessor: "studentPerformance",
      minWidth: 75,
      Cell: (props) => {
        const { original } = props.row;
        return <span>{original.studentPerformance}%</span>;
      },
    },
  ];

  getMobileData = ({ topicName, classPerformance, studentPerformance }) => {
    return {
      topicName: {
        title: "Topic",
        value: topicName,
      },
      classPerformance: {
        title: "Class Performance",
        value: classPerformance + "%",
      },
      studentPerformance: {
        title: "Student Performance",
        value: studentPerformance + "%",
      },
    };
  };

  renderMobileItems = (data, item, i) => {
    var dataItem = this.getMobileData(data)[item];
    if (isEmpty(dataItem.value)) {
      return (
        <ListItem key={item}>
          <strong>{dataItem.title}:</strong>
          <span>—</span>
        </ListItem>
      );
    } else if (item === "latestAttempt") {
      return (
        <ListItem key={item}>
          <strong>{dataItem.title}:</strong>
          <span>{moment(dataItem.value).format(dataItem.format)}</span>
        </ListItem>
      );
    } else {
      return (
        <ListItem key={item}>
          <strong>{dataItem.title}:</strong>
          <span>{dataItem.value}</span>
        </ListItem>
      );
    }
  };

  onMouseEnter = (idx, number) => (event) => {
    const { chartInstance } = this.chartReference.chartReference;
    const id = chartInstance.id;
    const dataset = chartInstance.data.datasets[0];
    const borderColor = dataset.hoverBorderColor[idx];
    const borderWidth = number > 0 ? dataset.hoverBorderWidth : 0;
    const element = dataset._meta[id].data[idx];

    const model = element._model;
    const view = element._view;

    model.borderWidth = borderWidth;
    model.borderColor = borderColor;

    view.borderWidth = borderWidth;
    view.borderColor = borderColor;

    element.draw();

    this.setState({
      selectedChartArcNumber: number,
    });
  };

  onMouseLeave = () => {
    const { chartInstance } = this.chartReference.chartReference;

    if (chartInstance) {
      chartInstance.update();
    }

    this.setState({
      selectedChartArcNumber: null,
    });
  };

  handleQuestionButtonClick = (index) => {
    this.setState({
      showQuestionIndex: index,
    });
  };

  async componentDidMount() {
    const { match, getStudentAttemptDashboardData, getAttemptPerformanceData } = this.props;
    const { assessmentId, attemptId } = match.params;

    await getStudentAttemptDashboardData({ assessmentId, attemptId });

    getAttemptPerformanceData({ attemptId });
    this.setPaginationDefaults();
  }

  componentWillUnmount() {
    const { clearStudentAttemptDashboardData } = this.props;

    if (this.chartReference) {
      const { chartInstance } = this.chartReference.chartReference;
      chartInstance.destroy();
    }

    clearStudentAttemptDashboardData();
  }

  setPaginationDefaults = () => {
    const { studentAttempt } = this.props;
    this.setState({
      table: {
        pageSize: 5,
        page: 0,
        pages: Math.ceil(studentAttempt.topicBreakdown?.length / 5),
        count: studentAttempt.topicBreakdown?.length,
      },
    });
  };

  getQuestionSummaryChartData = (studentAttempt) => {
    const {
      correctQuestionsCount,
      incorrectQuestionsCount,
      unansweredQuestionsCount,
    } = studentAttempt;
    return [
      { label: "CORRECT", number: correctQuestionsCount },
      { label: "INCORRECT", number: incorrectQuestionsCount },
      { label: "UNANSWERED", number: unansweredQuestionsCount },
    ];
  };

  getPaginatedData = (list) => {
    const { table } = this.state;
    const startIndex = table.page * table.pageSize;
    const endIndex = startIndex + table.pageSize;
    return list.slice(startIndex, endIndex);
  };

  handlePreviousButtonClick = () => {
    this.setState((prevState) => ({
      table: {
        ...prevState.table,
        page: prevState.table.page - 1,
      },
    }));
  };

  handleNextButtonClick = () => {
    this.setState((prevState) => ({
      table: {
        ...prevState.table,
        page: prevState.table.page + 1,
      },
    }));
  };

  getDisplayType = (data) => {
    let display = "radio";
    if (data.find((i) => i.type === "IMAGE") != undefined) {
      display = "image";
    }
    return display;
  };

  render() {
    const { chartColors, selectedChartArcNumber, showQuestionIndex } = this.state;

    const { count } = this.state.table;
    let {
      studentAttempt,
      attemptPerformance,
      apiCallInProgress,
      studentAttemptApiErrors,
    } = this.props;

    return !apiCallInProgress &&
      studentAttemptApiErrors.get.code === -1 &&
      !isEmpty(studentAttempt) ? (
      <SectionDashboard modified>
        <SectionBody>
          <Grid gutterSmall colSpacing>
            <Row>
              <Col flex>
                <FrameBox variant="primary">
                  <ListStats stats>
                    <ListItem key={"student-info"}>
                      <StudentInfo>
                        <StudentInfoIcon>{studentAttempt.studentName?.charAt(0)}</StudentInfoIcon>
                        <StudentInfoContent>
                          <StudentInfoTitle>{studentAttempt.studentName}</StudentInfoTitle>
                          <StudentInfoEntry>{studentAttempt.email}</StudentInfoEntry>
                        </StudentInfoContent>
                      </StudentInfo>
                    </ListItem>

                    <ListItem key="attemptScore">
                      <Stat>
                        <StatTitle>{studentAttempt.scorePercent}%</StatTitle>

                        <StatEntry>ATTEMPT SCORE</StatEntry>
                      </Stat>
                    </ListItem>

                    <ListItem key="attemptNumber">
                      <Stat>
                        <StatTitle>{studentAttempt.attemptNumber}</StatTitle>

                        <StatEntry>ATTEMPT #</StatEntry>
                      </Stat>
                    </ListItem>

                    <ListItem key="versionNumber">
                      <Stat>
                        <StatTitle>{studentAttempt.versionNumber}</StatTitle>

                        <StatEntry>VERSION</StatEntry>
                      </Stat>
                    </ListItem>

                    <ListItem key="attemptDate">
                      <Stat>
                        <StatTitle>
                          {moment(studentAttempt.attemptDate).format(FORMAT.SHORT_DATE)}
                        </StatTitle>

                        <StatEntry>ATTEMPT DATE</StatEntry>
                      </Stat>
                    </ListItem>

                    <ListItem key="attemptDuration">
                      <Stat>
                        <StatTitle>{studentAttempt.attemptDuration}</StatTitle>

                        <StatEntry>ATTEMPT DURATION</StatEntry>
                      </Stat>
                    </ListItem>
                  </ListStats>
                </FrameBox>
              </Col>
            </Row>
            <Row>
              <Col size="30" flex>
                <FrameBox>
                  <FrameTitle>Question Summary</FrameTitle>
                  {!isEmpty(studentAttempt) && (
                    <DeploymentChartStudent
                      ref={(reference) => (this.chartReference = reference)}
                      chartData={this.getQuestionSummaryChartData(studentAttempt)}
                      chartColors={chartColors}
                      onMouseEnter={this.onMouseEnter}
                      onMouseLeave={this.onMouseLeave}
                      selected={selectedChartArcNumber}
                    />
                  )}
                </FrameBox>
              </Col>

              <Col size="70" flex>
                <FrameBox>
                  <FrameTitle marginleft>Topic Breakdown</FrameTitle>
                  <MediaQuery minWidth={1024}>
                    <DataTable
                      classNames={`smaller-font react-table-no-min-width`}
                      data={attemptPerformance.topicPerformance}
                      columns={this.columns}
                      count={count}
                      loading={attemptPerformance.isLoading}
                    />
                  </MediaQuery>
                </FrameBox>
              </Col>
            </Row>

            <Row>
              <Col flex>
                <FrameBox>
                  <FrameTitle>Questions</FrameTitle>

                  <NavigationList>
                    {studentAttempt.questions.map((question, index) => {
                      const getNavQuestionType = (isAttempted, isCorrect) => {
                        return isAttempted === false
                          ? "unanswered"
                          : isCorrect === true
                          ? "correct"
                          : "incorrect";
                      };
                      const navQuestionType = getNavQuestionType(
                        question.isAttempted,
                        question.isCorrect
                      );

                      return (
                        <NavigationListItem key={question.id}>
                          <NavigationQuestion
                            type={navQuestionType}
                            attempts={0}
                            text={index + 1}
                            current={showQuestionIndex === index}
                            onClick={() => this.handleQuestionButtonClick(index)}
                          />
                        </NavigationListItem>
                      );
                    })}
                  </NavigationList>
                </FrameBox>
              </Col>
            </Row>

            <Row>
              <Col flex>
                <FrameBox>
                  {showQuestionIndex === null && (
                    <StudentInfoQuestionNotice>
                      <IconSvgComponent iconPath="svg/review-question-empty.svg" />
                      Select a question to view.
                    </StudentInfoQuestionNotice>
                  )}

                  {studentAttempt.questions && showQuestionIndex != null && (
                    <StudentInfoQuestion>
                      <StudentInfoQuestionHead>
                        <AssessmentQuestionImage fullwidth>
                          {studentAttempt.questions[showQuestionIndex] &&
                            studentAttempt.questions[showQuestionIndex].data.map((dataItem, k) => {
                              if (dataItem.type === "TEXT") {
                                return (
                                  <AssessmentQuestionText
                                    key={dataItem.text}
                                    layout={studentAttempt.questions[showQuestionIndex].layout}
                                    widthCounter={
                                      studentAttempt.questions[showQuestionIndex].data.length
                                    }
                                    floatCounter={k}
                                  >
                                    {k == 0 && (
                                      <AssessmentQuestionNumber>
                                        {showQuestionIndex + 1}.
                                      </AssessmentQuestionNumber>
                                    )}
                                    <FormattedText text={dataItem.text} />
                                  </AssessmentQuestionText>
                                );
                              }
                              return (
                                <AssessmentQuestionText
                                  key={dataItem.text}
                                  layout={studentAttempt.questions[showQuestionIndex].layout}
                                  widthCounter={
                                    studentAttempt.questions[showQuestionIndex].data.length
                                  }
                                  floatCounter={k}
                                >
                                  {k == 0 && (
                                    <AssessmentQuestionNumber>
                                      {showQuestionIndex + 1}.
                                    </AssessmentQuestionNumber>
                                  )}
                                  <OptionImage
                                    widthCounter={
                                      studentAttempt.questions[showQuestionIndex].data.length
                                    }
                                    layout={studentAttempt.questions[showQuestionIndex].layout}
                                  >
                                    <img
                                      key={dataItem.src}
                                      src={`${IMAGE_S3_SERVER}${dataItem.src}`}
                                      alt={dataItem.src}
                                    />
                                  </OptionImage>
                                </AssessmentQuestionText>
                              );
                            })}
                        </AssessmentQuestionImage>
                      </StudentInfoQuestionHead>

                      <StudentInfoQuestionList>
                        {studentAttempt.questions[showQuestionIndex].answers.map(
                          (answer, aIndex) => (
                            <StudentInfoQuestionListItem
                              key={aIndex}
                              correct={answer.isCorrect}
                              selected={answer.isSelected}
                              containsImage={answer.data[0]?.type === "IMAGE"}
                            >
                              <StudentInfoQuestionListIndicator
                                correct={answer.isCorrect}
                                selected={answer.isSelected}
                              >
                                {answer.isCorrect && (
                                  <IconSvgComponent
                                    additionalClass="correct"
                                    iconPath="svg/ico-correct.svg"
                                  />
                                )}
                                {studentAttempt.questions[showQuestionIndex].isAttempted &&
                                  !studentAttempt.questions[showQuestionIndex].isCorrect &&
                                  answer.isSelected && (
                                    <IconSvgComponent
                                      additionalClass="correct"
                                      iconPath="svg/ico-incorrect.svg"
                                    />
                                  )}
                              </StudentInfoQuestionListIndicator>
                              <AnswerLabel
                                displayType={this.getDisplayType(answer.data)}
                                quizType={"assessment"}
                                layout={answer.layout}
                              >
                                <AnswerImage>
                                  {answer.data &&
                                    answer.data.map((data, k) => {
                                      if (data.type === "TEXT") {
                                        return (
                                          <AnswerText
                                            key={data.text}
                                            layout={answer.layout}
                                            widthCounter={answer.data.length}
                                            floatCounter={k}
                                          >
                                            <FormattedText text={data.text}></FormattedText>
                                          </AnswerText>
                                        );
                                      } else {
                                        return (
                                          <OptionImage
                                            widthCounter={answer.data.length}
                                            layout={answer.layout}
                                          >
                                            {data.src.trim() ? (
                                              <img
                                                key={data.src}
                                                src={`${IMAGE_S3_SERVER}${data.src}`}
                                                alt={data.src}
                                              />
                                            ) : null}
                                          </OptionImage>
                                        );
                                      }
                                    })}
                                </AnswerImage>
                              </AnswerLabel>
                            </StudentInfoQuestionListItem>
                          )
                        )}
                      </StudentInfoQuestionList>
                    </StudentInfoQuestion>
                  )}
                </FrameBox>
              </Col>
            </Row>
          </Grid>
        </SectionBody>
      </SectionDashboard>
    ) : (
      <NoDataMessageContainer noIcon>
        {apiCallInProgress == true && <Loader />}
        {apiCallInProgress == false && (
          <NoDataMessage>
            No Student Attempt Dashboard content <br /> available at this time.
          </NoDataMessage>
        )}
      </NoDataMessageContainer>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      studentAttempt: state.facultyDashboard.studentAttempt,
      attemptPerformance: state.facultyDashboard.attemptPerformance,
      apiCallInProgress: state.facultyDashboard.apiCallInProgress,
      studentAttemptApiErrors: state.facultyDashboard.apiErrors.studentAttempt,
    };
  },
  {
    getStudentAttemptDashboardData,
    clearStudentAttemptDashboardData,
    getAttemptPerformanceData,
  }
)(AssessmentDashboardStudentAttempt);
