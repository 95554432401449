/**
 * The external dependencies.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
/**
 * The internal dependencies.
 */
import Tabs from "components/tabs";
import Tab from "components/tabs/Tab";
import FormCreateDeploymentDetails from "components/form/FormCreateDeploymentDetails";
import FormCreateDeploymentSchedule from "components/form/FormCreateDeploymentSchedule";
import FormCreateDeploymentCohort from "components/form/FormCreateDeploymentCohort";
import FormCreateDeploymentAssessment from "components/form/FormCreateDeploymentAssessment";
import { SectionWizard, SectionTitle, SectionBody } from "styles/components/Section";
import { getCohorts, getPlacementAssessmentsByProgram } from "store/state/administration/actions";
import { getDeploymentById } from "store/state/deployment/actions";

/**
 * Class for create deployment.
 *
 * @class      CreateDeployment (name)
 */
class CreateDeployment extends Component {
  static propTypes = {
    changeStep: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const {
      modal: {
        data: { id, status },
      },
      placementAssessments,
      getCohorts,
      accountId,
    } = this.props;

    getCohorts({
      selector: "cohorts",
      accountId,
      pageSize: -1,
    });

    if (this.props.isEdit && id) {
      await this.props.getDeploymentById({ id, selector: "deployment" });
    }

    if (!placementAssessments.length) {
      await this.props.getPlacementAssessmentsByProgram();
    }
  }

  render() {
    return (
      <SectionWizard lg>
        <SectionTitle>{this.props.isEdit ? "Edit Deployment" : "Create Deployment"}</SectionTitle>
        <SectionBody>
          <Tabs isSteps>
            <Tab title="Details">
              <FormCreateDeploymentDetails />
            </Tab>

            <Tab title="Assessments">
              <FormCreateDeploymentAssessment />
            </Tab>

            <Tab title="Cohort">
              <FormCreateDeploymentCohort />
            </Tab>

            <Tab title="Schedule">
              <FormCreateDeploymentSchedule changeStep={this.props.changeStep} />
            </Tab>
          </Tabs>
        </SectionBody>
      </SectionWizard>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    placementAssessments: state.administration.placementAssessments,
    accountId: state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId,
  }),
  {
    getPlacementAssessmentsByProgram,
    getDeploymentById,
    getCohorts,
  }
)(CreateDeployment);
