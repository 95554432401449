import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { closeModal } from "store/state/ui/actions";
import { completePlacementAssessment, submitAttempt } from "store/state/assessment/actions";
import { completeCourseAssessment } from "store/state/courseAssessment/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import stemifyRoutes from "constants/routes";

import {
  SubmitContainer,
  SubmitIcon,
  SubmitTitle,
  SubmitText,
  SubmitActions,
} from "styles/components/AssessmentSubmit";

class AssessmentModalContent extends Component {
  handleSubmission = async () => {
    const {
      isExpired,
      closeModal,
      //completePlacementAssessment,
      //completeCourseAssessment,
      submitAttempt,
      history,
      attemptId,
      assessmentSeriesId,
      deploymentConfigId,
      courseId,
      assignmentId,
    } = this.props;

    if (!isExpired) {
      const submitResponse = await submitAttempt({
        attemptId,
        assessmentSeriesId,
        assignmentId,
        deploymentConfigId,
      });
    }

    closeModal();

    if (!!assignmentId) {
      history.push(
        `${stemifyRoutes.courses}/${courseId}/coursework/${assignmentId}/exam/${assessmentSeriesId}/${attemptId}/results`
      );
    } else {
      history.push(
        `${stemifyRoutes.assessments}/${deploymentConfigId}/${assessmentSeriesId}/${attemptId}/results`
      );
    }
  };

  handleGoBack = () => {
    const { closeModal } = this.props;

    closeModal();
  };

  render() {
    const { icon, title, description, isExpired } = this.props;
    return (
      <SubmitContainer>
        <SubmitIcon>
          <IconSvgComponent iconPath={icon} />
        </SubmitIcon>
        <SubmitTitle>{title}</SubmitTitle>
        <SubmitText>{description}</SubmitText>
        <SubmitActions>
          {!isExpired && (
            <ButtonLink transparent onClick={this.handleGoBack} type="button">
              Go Back
            </ButtonLink>
          )}
          <ButtonLink className="btn-submit" onClick={this.handleSubmission}>
            <span>{isExpired ? "View My Results" : "Submit"}</span>
            {!isExpired && <IconSvgComponent iconPath="svg/ico-check-white.svg" />}
          </ButtonLink>
        </SubmitActions>
      </SubmitContainer>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      modal: state.ui.modal,
      deploymentId: state.deployment.id,
    }),
    {
      closeModal,
      //completePlacementAssessment,
      //completeCourseAssessment,
      submitAttempt,
    }
  )(AssessmentModalContent)
);
