import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import useAPIData from "components/useAPIData";
import TopicBreakdownCard from "./TopicBreakdownCard";
import { getCourseAssessmentTopicBreakdownData as getCourseAssessmentTopicBreakdownDataAction } from "store/state/courses/actions";

const columns = [
  {
    id: "topic_name",
    Header: "Concept Name",
    accessor: "topic_name",
  },

  {
    id: "class_performance",
    Header: "Class Performance",
    accessor: "class_performance",
  },
];

const mobileColumns = (topic) => {
  return {
    topicName: {
      title: "Topic Name",
      value: topic.topic_name,
    },
    classPerformance: {
      title: "Class Performance",
      value: topic.class_performance,
    },
  };
};

export const CourseAssessmentTopicBreakdownCard = ({ assignmentId }) => {
  const dispatch = useDispatch();
  const getCourseAssessmentTopicBreakdownData = useCallback(() => {
    return dispatch(getCourseAssessmentTopicBreakdownDataAction({ assignmentId }));
  }, [dispatch, assignmentId]);

  return (
    <TopicBreakdownCard
      getDataCallback={getCourseAssessmentTopicBreakdownData}
      columns={columns}
      mobileColumns={mobileColumns}
    />
  );
};
