/**
 * The external dependencies.
 */
import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import moment from "moment";
import { isEmpty } from "ramda";

/**
 * The internal dependencies.
 */

import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import IconSvgComponent from "components/common/IconSvgComponent";

import { openModal, closeModal } from "store/state/ui/actions";
import { resetAsset } from "store/state/courses/actions";
import { MODAL_CONFIRMATION } from "lib/constants";

import Form, {
  FormBody,
  FormHead,
  FormTitle,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
} from "styles/components/Form";
import {
  CTAbtn,
  ToggleDropdown,
  BtnDropdown,
  UploadField,
} from "styles/components/ModalCourseAssignmentPractice";

import {
  CourseAssignmentCard,
  CourseAssignmentCardImage,
  CourseAssignmentCardContent,
  CourseAssignmentCardText,
  CourseAssignmentCardTitle,
  CourseAssignmentCardEntry,
} from "styles/components/CourseAssignments";

import { validateField, validateDateField } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES } from "constants/messages";

const pointsOptions = [
  {
    label: "100",
    value: "100",
  },
  {
    label: "200",
    value: "200",
  },
];

const initialState = {
  name: "",
  instructions: "",
  due_date: "No Due Date",
  points: "",
  toggleDropdownActive: false,
  isDraft: false,
  isScheduled: false,
  formErrors: {
    name: {
      valid: true,
      message: "",
    },
    instructions: {
      valid: true,
      message: "",
    },
    due_date: {
      valid: true,
      message: "",
    },
    points: {
      valid: true,
      message: "",
    },
    formValid: true,
  },
};

class FormCreateAssignment extends Component {
  state = {
    ...initialState,
  };

  rules = {
    name: {
      requiredMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_NAME_REQUIRED,
      maxMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_NAME_MAX,
    },
    instructions: {
      maxMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_INSTRUCTION_MAX,
    },
    due_date: {
      requiredMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_DATE_REQUIRED,
      typeDateMessage: FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES.COURSE_ASSIGNMENT_DATE_INVALID,
    },
  };

  /*Form Validation*/

  validateAllFields = () => {
    this.validateTextField(document.getElementById("name"), true);
    this.validateTextField(document.getElementById("instructions"), true);
    this.validateFormDateField("due_date", this.state.due_date);
  };

  validateTextField = (element, trimValues) => {
    this.setState({
      formErrors: validateField(this.state.formErrors, this.rules, element, "", trimValues),
    });
  };

  validateFormDateField = (name, value) => {
    value =
      !value ||
      (typeof value === "string" && (value.trim() === "" || value.trim() === "No Due Date"))
        ? undefined
        : this.getMomentObjectFromDateString(value);

    this.setState({
      formErrors: validateDateField(this.state.formErrors, this.rules, {
        name,
        value,
        required: true,
      }),
    });
  };

  /* Returns custom date string with 'at' as a moment object.
   *
   * @return {moment}
   */

  getMomentObjectFromDateString(value) {
    if (typeof value === "string") {
      const dateTimeParts = value.split(" at ");
      const dateTime = dateTimeParts.join(" ");
      value = moment(dateTime);
    }
    return value;
  }

  /**
   * Handle events
   */
  handleDropdownToggleClick() {
    this.setState({
      toggleDropdownActive: !this.state.toggleDropdownActive,
    });
  }

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleStaticChange = (key) => (value) => {
    this.setState({
      [key]: value,
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.validateAllFields();

    if (this.state.formErrors.formValid) {
      const formData = Object.assign({}, this.state);

      delete formData.toggleDropdownActive;

      this.props.onSubmit(formData);

      this.setState((prevState) => ({
        ...prevState,
        ...initialState,
      }));
    }
  };

  handleDatepickerChange = () => {
    this.setState({
      showDatepicker: true,
    });
  };

  handleCardDelete = (id) => {
    this.props.resetAsset();
  };

  removeCard = (data) => {
    this.props.openModal({
      type: MODAL_CONFIRMATION,
      data: {
        id: data.id,
        title: data.name,
        icon: "ico-module-remove.svg",
        type: "delete",
        action: () => this.handleCardDelete(data.id),
      },
    });
  };

  addAssets = (event) => {
    event.preventDefault();

    this.props.onAddAssets();
  };

  setDraft = () => {
    this.setState({
      isDraft: true,
      isScheduled: false,
    });
  };

  setScheduled = () => {
    this.setState({
      isDraft: false,
      isScheduled: true,
    });
  };

  render() {
    const { name, instructions, due_date, points, formErrors } = this.state;

    const { assessmentAsset } = this.props;
    return (
      <Form
        hasLargeFields
        fulloNmobile
        tabletSize1
        onSubmit={this.handleFormSubmit}
        innerRef={(comp) => {
          this.form = comp;
        }}
      >
        <FormHead>
          <FormTitle>Create Assignment - Practice</FormTitle>
          <FieldErrors formErrors={formErrors} apiErrors=""></FieldErrors>
        </FormHead>
        <FormBody size1>
          <FormRowGrid justifyStart>
            <FormCol two_thirds>
              <FormControls bottomOffset="25">
                <div style={{ maxWidth: "261px" }}>
                  <Field
                    id="name"
                    name="name"
                    onChange={this.handleInputChange("name")}
                    value={name}
                    placeholder="Assignment Name"
                    required
                    max="255"
                  />
                </div>
              </FormControls>
              <FormControls>
                <Field
                  id="instructions"
                  name="instructions"
                  onChange={this.handleInputChange("instructions")}
                  value={instructions}
                  placeholder="Instructions"
                  isTextarea
                  max="255"
                />
              </FormControls>
              <FormControls>
                <ButtonLink className="gray-btn" onClick={(e) => this.addAssets(e)}>
                  Add <IconSvgComponent iconPath="svg/ico-file.svg" />
                </ButtonLink>
              </FormControls>
            </FormCol>

            <FormCol third>
              <FormControls bottomOffset="20">
                <Field
                  icon="ico-calendar"
                  id="due_date"
                  isDateTimePicker
                  onChange={this.handleStaticChange("due_date")}
                  value={due_date}
                  placeholder="Date"
                  showTimeSelect={false}
                  required
                />
              </FormControls>
              <FormControls>
                <Field
                  id="points"
                  name="points"
                  isSelect
                  onChange={this.handleStaticChange("points")}
                  options={pointsOptions}
                  value={points}
                  placeholder="Points"
                />
              </FormControls>
              <FormControls>
                <FormActions>
                  <ButtonLink isUnstyledButton onClick={this.props.onCancel}>
                    <span>Cancel</span>
                  </ButtonLink>
                  <CTAbtn>
                    <ButtonLink violet>
                      <span>Assign</span>
                    </ButtonLink>
                    <ToggleDropdown onClick={(e) => this.handleDropdownToggleClick(e)} />
                    <BtnDropdown
                      className={classNames({ "is-active": this.state.toggleDropdownActive })}
                    >
                      <ul onClick={(e) => this.handleDropdownToggleClick(e)}>
                        <li>
                          <button type="submit">Assign</button>
                        </li>
                        <li>
                          <button type="submit" onClick={this.setScheduled}>
                            Schedule
                          </button>
                        </li>
                        <li>
                          <button type="submit" onClick={this.setDraft}>
                            Save Draft
                          </button>
                        </li>
                      </ul>
                    </BtnDropdown>
                  </CTAbtn>
                </FormActions>
              </FormControls>
            </FormCol>
          </FormRowGrid>

          <FormRowGrid justifyStart>
            <FormCol two_thirds>
              {!isEmpty(assessmentAsset) && (
                <CourseAssignmentCard key={assessmentAsset.id}>
                  <ButtonLink
                    transparent
                    onClick={() => this.removeCard(assessmentAsset)}
                    type="button"
                    className="btn-remove"
                  >
                    <IconSvgComponent iconPath="svg/ico-close-thick.svg" />
                  </ButtonLink>

                  <CourseAssignmentCardImage image={"/images/assignment-placeholder.jpg"} />

                  <CourseAssignmentCardContent>
                    <IconSvgComponent iconPath={"svg/assessments_nav_ic.svg"} />

                    <CourseAssignmentCardText>
                      <CourseAssignmentCardTitle>
                        {assessmentAsset.assessment_name}
                      </CourseAssignmentCardTitle>

                      <CourseAssignmentCardEntry>{assessmentAsset.type}</CourseAssignmentCardEntry>
                    </CourseAssignmentCardText>
                  </CourseAssignmentCardContent>
                </CourseAssignmentCard>
              )}
            </FormCol>
          </FormRowGrid>
        </FormBody>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    assessmentAsset: state.storedCourses.assignmentAssets,
  }),
  {
    openModal,
    closeModal,
    resetAsset,
  }
)(FormCreateAssignment);
