import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import MediaQuery from "react-responsive";
import { saveUserActivityAction } from "store/state/user/actions";

import {
  setPracticeAssignmentCurrentQuestion,
  getPracticeAssignment,
  getPracticeAssignmentForCourse,
} from "store/state/practiceAssignment/actions";
import Wrapper from "styles/components/Wrapper";
import QuestionNavigator from "components/practiceAssignment/QuestionNavigator";
import IconSvgComponent from "components/common/IconSvgComponent";
import { ButtonUpload } from "styles/components/QuestionsNavigation";

import {
  SectionContainer,
  SectionContent,
  SectionBody,
  SectionOuter,
} from "styles/components/AssessmentTaker";
import PracticeQuestions from "../practiceAssignment/PracticeQuestions";
import { NoDataMessageContainer } from "styles/components/Card";
import { NoDataMessage } from "styles/components/Card";
import Loader from "components/common/loader";

class PracticeAssignment extends Component {
  componentDidMount = () => {
    const { assignmentId, courseId } = this.props.match.params;
    !courseId
      ? this.props.getPracticeAssignment({ assignmentId })
      : this.props.getPracticeAssignmentForCourse({ assignmentId });
  };

  handleNextQuestionButtonClick = () => {
    const { setPracticeAssignmentCurrentQuestion, questions, currentQuestionIndex } = this.props;

    setPracticeAssignmentCurrentQuestion({
      questionId: questions[currentQuestionIndex + 1].id,
      questionIndex: currentQuestionIndex + 1,
    });
  };

  handlePreviousQuestionButtonClick = () => {
    const { setPracticeAssignmentCurrentQuestion, questions, currentQuestionIndex } = this.props;

    setPracticeAssignmentCurrentQuestion({
      questionId: questions[currentQuestionIndex - 1].id,
      questionIndex: currentQuestionIndex - 1,
    });
  };

  areAllAnswersCorrect = () => {
    const { questions } = this.props;
    return questions.every((q) => q.isCorrect === true);
  };

  handleViewResultsClick = () => {
    const {
      learningModule: { id },
      markModuleComplete,
    } = this.props;
    const { assignmentId } = this.props.match.params;

    if (this.areAllAnswersCorrect()) {
      this.saveUserActivity({ type: "ASSIGNMENT", id: assignmentId, isComplete: true });

      if (markModuleComplete) {
        this.saveUserActivity({ type: "LM", id, isComplete: true });
      }
    }

    this.props.history.push({
      pathname: `${this.props.match.url}/results`,
      state: { referrer: `${this.props.match.url}` },
    });
  };

  saveUserActivity = ({ type, id, isComplete }) => {
    const { saveUserActivityAction } = this.props;
    saveUserActivityAction({
      type,
      id,
      isComplete,
    });
  };

  render() {
    const {
      questions,
      isLoading,
      currentQuestionIndex,
      isAnimationForward,
      learningModule,
      assignmentName,
    } = this.props;
    const { assignmentId, courseId } = this.props.match.params;
    const documentTitleDescription = courseId ? assignmentName : learningModule.name;

    return questions.length > 0 && !isLoading ? (
      <DocumentTitle title={`Stemify | ${documentTitleDescription}`}>
        <Wrapper>
          <MediaQuery minWidth={768}>
            <ButtonUpload onClick={() => this.handleViewResultsClick({ assignmentId })}>
              View Results
              <IconSvgComponent iconPath="svg/ico-upload.svg" />
            </ButtonUpload>
          </MediaQuery>

          <SectionOuter display="block">
            <QuestionNavigator />

            <SectionContainer withNav padding="26px 0">
              <SectionContent noOverflow padding="0px 30px">
                <SectionBody>
                  {questions.length > 0 && !isLoading && (
                    <PracticeQuestions
                      questions={questions}
                      currentQuestionIndex={currentQuestionIndex}
                      isAnimationForward={isAnimationForward}
                      handlePreviousQuestionButtonClick={this.handlePreviousQuestionButtonClick}
                      handleNextQuestionButtonClick={this.handleNextQuestionButtonClick}
                      openstax_resource={learningModule.openstax_resource}
                    />
                  )}
                </SectionBody>
              </SectionContent>
            </SectionContainer>
          </SectionOuter>
        </Wrapper>
      </DocumentTitle>
    ) : (
      <NoDataMessageContainer noIcon>
        {isLoading == true && <Loader />}
        {isLoading == false && (
          <NoDataMessage>
            No Assessments <br /> available at this time.
          </NoDataMessage>
        )}
      </NoDataMessageContainer>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      learningModule: state.learningLibrary.learningModule,
      questions: state.practiceAssignment.assignmentQuestions,
      currentQuestionIndex: state.practiceAssignment.currentQuestionIndex,
      currentQuestionId: state.practiceAssignment.currentQuestionId,
      isAnimationForward: state.practiceAssignment.isAnimationForward,
      isLoading: state.practiceAssignment.apiCallInProgress,
      markModuleComplete: state.practiceAssignment.markModuleComplete,
      assignmentName: state.practiceAssignment.assignmentName,
    }),
    {
      setPracticeAssignmentCurrentQuestion,
      getPracticeAssignment,
      saveUserActivityAction,
      getPracticeAssignmentForCourse,
    }
  )(PracticeAssignment)
);
