/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { SlideToggle } from "react-slide-toggle";
import { pick } from "ramda";

/**
 * The internal dependencies.
 */
import { closeModal } from "store/state/ui/actions";
import { updateDeployment } from "store/state/deployment/actions";
import { resetApiErrors } from "store/state/administration/actions";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Switch from "components/common/Switch";
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  FormDefault as Form,
  FormBody,
  FormSection,
  FormTitle,
  FormTitleWithClick,
  FormEntry,
  FormRow,
  FormRowGrid,
  FormCol,
  FormControls,
  FormActions,
} from "styles/components/Form";
import { validateField, validateSelectField } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { FORM_DEPLOYMENT_MESSAGES } from "constants/messages";
import { getAllPrograms } from "store/state/deployment/actions";
/**
 * Class for form create deployment details.
 *
 * @class      FormCreateDeploymentDetails (name)
 */
class FormCreateDeploymentDetails extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    name: "",
    program: "",
    ownerName: "",
    ownerEmail: "",
    message: "",
    accessCode: "",
    completed_before_retake: false,
    restricted: false,
    advancedFieldsVisible: false,
    formErrors: {
      name: {
        valid: true,
        message: "",
      },
      program: {
        valid: true,
        message: "",
      },
      ownerName: {
        valid: true,
        message: "",
        validEarlier: true,
      },
      ownerEmail: {
        valid: true,
        message: "",
        validEarlier: true,
      },
      accessCode: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };
  rules = {
    name: {
      requiredMessage: FORM_DEPLOYMENT_MESSAGES.DEPLOYMENT_NAME_REQUIRED,
      maxMessage: FORM_DEPLOYMENT_MESSAGES.DEPLOYMENT_NAME_MAX,
    },
    program: {
      requiredMessage: FORM_DEPLOYMENT_MESSAGES.PROGRAM_REQUIRED,
    },
    ownerEmail: {
      requiredMessage: FORM_DEPLOYMENT_MESSAGES.OWNER_EMAIL_REQUIRED,
      typeEmailMessage: FORM_DEPLOYMENT_MESSAGES.OWNER_EMAIL_FORMAT_INVALID,
      maxMessage: FORM_DEPLOYMENT_MESSAGES.OWNER_EMAIL_MAX,
    },
    ownerName: {
      requiredMessage: FORM_DEPLOYMENT_MESSAGES.OWNER_NAME_REQUIRED,
      maxMessage: FORM_DEPLOYMENT_MESSAGES.OWNER_NAME_MAX,
    },
    accessCode: {
      requiredMessage: FORM_DEPLOYMENT_MESSAGES.ACCESS_CODE_REQUIRED,
      minMessage: FORM_DEPLOYMENT_MESSAGES.ACCESS_CODE_LENGTH,
      maxMessage: FORM_DEPLOYMENT_MESSAGES.ACCESS_CODE_LENGTH,
      expression: /^[0-9]*$/,
      expressionMessage: FORM_DEPLOYMENT_MESSAGES.ACCESS_CODE_NUMERIC_ONLY,
    },
  };

  /**
   * Validates the form fields.
   *
   * @return {Boolean} / If fields are valid or not
   */
  validateTextField = (name, trimValues) => {
    this.setState({
      formErrors: validateField(
        this.state.formErrors,
        this.rules,
        document.getElementById(name),
        "",
        trimValues
      ),
    });
  };

  validateDropDownField = (properties) => {
    this.setState({
      formErrors: validateSelectField(this.state.formErrors, this.rules, properties),
    });
  };

  validateAllFields = () => {
    this.validateTextField("name", true);
    this.validateDropDownField({ name: "program", value: this.state.program, required: true });
    this.validateTextField("ownerName", true);
    this.validateTextField("ownerEmail", true);
    this.validateTextField("accessCode", true);
  };

  /**
   * Handles the input field change.
   *
   * @param {String} / The key
   * @return {Void}
   */
  handleInputChange(key, e) {
    this.props.resetApiErrors();
    const element = e.nativeEvent.target ? e.nativeEvent.target : e.target;
    this.setState({
      [key]: element.value,
    });
  }

  /**
   * Handles the switch input field change.
   *
   * @param {String} / The key
   * @return {Void}
   */
  handleSwitchChange(key, value) {
    this.props.resetApiErrors();
    this.setState({
      [key]: value,
    });
  }

  /**
   * Handles the dropdown field change.
   *
   * @param {String} / The key
   * @return {Void}
   */
  handleSelectChange = (key) => (value) => {
    this.props.resetApiErrors();
    this.setState({
      [key]: value,
    });
  };

  /**
   * Handles the form submit.
   *
   * @param {Object} / The form event
   * @return {Void}
   */
  handleFormSubmit = async (e) => {
    const { updateDeployment, nextStep, modal, closeModal } = this.props;
    e.preventDefault();
    this.validateAllFields();
    const deploymentObj = pick(
      [
        "name",
        "program",
        "ownerName",
        "ownerEmail",
        "message",
        "completed_before_retake",
        "restricted",
        "accessCode",
      ],
      this.state
    );
    var reqParams = {
      ...deploymentObj,
      id: modal.data.editDeployment && this.props.deployment ? this.props.deployment.id : null,
      is_draft: true,
    };

    if (this.state.formErrors.formValid) {
      const resp = await updateDeployment(reqParams);
      if (resp.status == "success") {
        nextStep();
      }
    }
  };

  handleDraftSave = async (e) => {
    const { updateDeployment, modal } = this.props;
    e.preventDefault();
    const deploymentObj = pick(
      [
        "name",
        "program",
        "ownerName",
        "ownerEmail",
        "message",
        "completed_before_retake",
        "restricted",
        "accessCode",
      ],
      this.state
    );
    var reqParams = {
      ...deploymentObj,
      id: modal.data.editDeployment && this.props.deployment ? this.props.deployment.id : null,
      is_draft: true,
    };
    await updateDeployment(reqParams);
  };

  /**
   * Toggles active state for addition form fields.
   *
   * @param {Object} / The form event
   * @return {Void}
   */
  toggleAdvancedFields = (e) => {
    e.preventDefault();

    this.setState(({ advancedFieldsVisible }) => ({
      advancedFieldsVisible: !advancedFieldsVisible,
    }));
  };

  /**
   * The componentDidMount lifecycle hook of the component.
   *
   * @return {Void}
   */
  componentDidMount() {
    this.props.resetApiErrors();
    this.setState({
      ...pick(
        [
          "name",
          "program",
          "ownerName",
          "ownerEmail",
          "message",
          "completed_before_retake",
          "restricted",
          "accessCode",
        ],
        this.props.deployment
      ),
    });

    if (!this.props.deployment.message) {
      this.setState({
        message: FORM_DEPLOYMENT_MESSAGES.ASSESSMENT_DEFAULT_WELCOME_MESSAGE,
      });
    }

    this.props.getAllPrograms().then(() => {
      this.setState({
        programs: this.props.programs,
      });
    });
  }

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const {
      name,
      ownerName,
      ownerEmail,
      message,
      completed_before_retake,
      restricted,
      accessCode,
      programs,
      program,
      formErrors,
    } = this.state;
    const { modal, apiCallInProgress, deploymentsApiErrors } = this.props;
    const { create: createApiErrors, update: updateApiErrors } = deploymentsApiErrors;
    return (
      <Form fulloNmobile onSubmit={this.handleFormSubmit} colSpacing>
        <FieldErrors
          formErrors={formErrors}
          apiErrorMessage={createApiErrors.message || updateApiErrors.message}
        ></FieldErrors>
        <FormBody>
          <FormSection>
            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="name"
                    name="name"
                    onChange={(e) => this.handleInputChange("name", e)}
                    value={name}
                    placeholder="Deployment Name"
                    required
                    max="255"
                    error={!formErrors.name.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="program"
                    name="program"
                    isSelect
                    onChange={this.handleSelectChange("program")}
                    options={programs}
                    value={program}
                    placeholder="Select Program"
                    required
                    error={!formErrors.program.valid}
                    apiCallInProgress={apiCallInProgress}
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>
          </FormSection>

          <FormSection>
            <FormTitle>Program Owner</FormTitle>

            <FormEntry>
              The program owner will receive communications from students regarding questions or
              concerns.
            </FormEntry>

            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    id="ownerName"
                    name="ownerName"
                    onChange={(e) => this.handleInputChange("ownerName", e)}
                    value={ownerName}
                    placeholder="Full Name"
                    required
                    max="255"
                    error={!formErrors.ownerName.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="ownerEmail"
                    name="ownerEmail"
                    type="email"
                    onChange={(e) => this.handleInputChange("ownerEmail", e)}
                    value={ownerEmail}
                    placeholder="Email"
                    required
                    max="128"
                    error={!formErrors.ownerEmail.valid}
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>
          </FormSection>

          <FormSection>
            <SlideToggle
              collapsed
              render={({ onToggle, setCollapsibleElement, toggleState }) => (
                <div className="my-collapsible">
                  <FormTitleWithClick
                    className="my-collapsible__toggle"
                    type="button"
                    tabindex="-1"
                    active={toggleState === "EXPANDED" ? true : false}
                    onClick={onToggle}
                  >
                    Advanced Options
                  </FormTitleWithClick>

                  <div className="my-collapsible__content" ref={setCollapsibleElement}>
                    <div className="my-collapsible__inner">
                      <FormTitle>Welcome Message</FormTitle>

                      <FormRow textarea>
                        <FormControls>
                          <FormEntry>
                            {
                              "The welcome message will be shown to students before they take an assessment. There are some placeholders you can use that will be automatically replaced by relevant information when students see this message: <assessment_name> will show the name of the assessment; <max_attempt> will show the number of attempts allowed on the assessment; <remaining_attempt> will show the number of attempts the student has left; <time_limit> will show how long the student has to complete the assessment; and for placement assessments <course_cutoff_list> will show a list of cutoff scores and the courses those cutoffs correspond to."
                            }
                          </FormEntry>
                          <Field
                            isTextarea
                            id="message"
                            onChange={(e) => this.handleInputChange("message", e)}
                            value={message ? message : ""}
                            placeholder="Welcome Message…"
                          />
                        </FormControls>
                      </FormRow>

                      <FormRowGrid switch displayProp="none">
                        <FormCol size2>
                          <FormControls>
                            <Switch
                              onChange={(value) =>
                                this.handleSwitchChange("completed_before_retake", value)
                              }
                              id="completed-before-retake"
                              value={completed_before_retake}
                            >
                              Prescribed learning modules need to be completed before retake.
                            </Switch>
                          </FormControls>
                        </FormCol>
                      </FormRowGrid>

                      <FormTitle>Cooling Period</FormTitle>
                      <FormEntry>
                        {
                          "You can optionally choose a 6-digit access code to lock down the assessment. If you select this option, students will not be able to start the assessment until the code is entered."
                        }
                      </FormEntry>
                      <FormRowGrid switch tinyBottomSpace={this.state.restricted}>
                        <FormCol size2>
                          <FormControls>
                            <Switch
                              onChange={(value) => this.handleSwitchChange("restricted", value)}
                              id="restricted"
                              value={restricted}
                              showIcon={true}
                              iconOffPath="svg/ico_unlocked.svg"
                              iconOnPath="svg/ico_locked.svg"
                              iconOffAdditionalClass="foreground-icon-off"
                              iconOnAdditionalClass="foreground-icon-on"
                            >
                              Restrict test with access code.
                            </Switch>
                          </FormControls>
                        </FormCol>

                        {this.state.restricted && (
                          <FormCol size3>
                            <FormControls>
                              <Field
                                id="accessCode"
                                name="accessCode"
                                type="text"
                                required
                                min="6"
                                max="6"
                                onChange={(e) => this.handleInputChange("accessCode", e)}
                                value={accessCode}
                                placeholder="Access Code"
                                isNumericTextField
                                shiftUp
                                error={!formErrors.accessCode.valid}
                              />
                            </FormControls>
                          </FormCol>
                        )}
                      </FormRowGrid>
                    </div>
                  </div>
                </div>
              )}
            />
          </FormSection>
        </FormBody>

        <FormActions>
          <ButtonLink
            disabled={apiCallInProgress}
            transparent
            onClick={this.handleDraftSave}
            type="button"
          >
            {" "}
            {modal.data.editDeployment ? "Save Changes" : "Save Draft"}
          </ButtonLink>
          <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
            <span>Next Step</span>
            <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    programs: state.administration.programs.map((item) => ({
      label: item.name,
      value: item.id,
    })),
    deployment: state.deployment,
    apiCallInProgress: state.administration.apiCallInProgress,
    modal: state.ui.modal,
    deploymentsApiErrors: state.administration.apiErrors.deployments,
  }),
  {
    getAllPrograms,
    updateDeployment,
    resetApiErrors,
    closeModal,
  }
)(FormCreateDeploymentDetails);
