import {
  programPath,
  beginPlacementAssessmentPath,
  submitAttemptPath,
  assessmentSeriesPathAll,
  assessmentPath,
  contentType,
  courseAssessmentBeginPath,
  assignmentPath,
  contentTypeJSON,
  attemptsPath,
  beginAttemptPath,
  resumeAttemptPath,
  assessmentQuestionResponsePath,
  assignmentByIdPath,
  attemptStatsPath,
} from "constants/api";
import { fetchAPICall, apiUrl } from "utilities/API";
import { getAccessToken } from "utilities/localStore";

export const fetchAssessmentSeries = async ({ accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assessmentSeriesPathAll,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const fetchPlacementAssessment = async ({
  deploymentId,
  assessmentSeriesId,
  accessCode,
}) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: beginPlacementAssessmentPath({ deploymentId, assessmentSeriesId }),
    method: "POST",
    body: JSON.stringify({ access_code: accessCode }),
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};

export const fetchCourseAssessment = async ({ assignmentId, accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: courseAssessmentBeginPath({ assignmentId }),
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const submitPlacementAssessment = async ({ accountId, attemptId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: submitAttemptPath(attemptId),
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentTypeJSON,
      account_id: accountId,
    },
  });
};

export const submitAttempt = async ({ accountId, attemptId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: submitAttemptPath(attemptId),
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentTypeJSON,
      account_id: accountId,
    },
  });
};

export const submitCourseAssessment = async ({ accountId, attemptId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: submitAttemptPath(attemptId),
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentTypeJSON,
      account_id: accountId,
    },
  });
};

export const getPlacementAssessments = async ({ programId, accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${programPath}/${programId}${assessmentPath}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const decorateAssessmentsForDeploymentForm = (data) => {
  return data.map((item) => {
    return {
      name: item.name,
      default_cuttoff: item.cutoff_range,
      id: item.assessment_series_id,
      programId: item.program_id,
    };
  });
};

export const deleteAttemptById = async ({ accountId, type, attemptId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/attempt/${attemptId}?type=${type}`,
    method: "DELETE",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const getAttempts = async ({
  accountId,
  username,
  status,
  assessmentSeriesId,
  deploymentId,
  assignmentId,
  attemptId,
  includeModel,
  page,
  pageSize,
}) => {
  const queryParamString =
    "?" +
    Object.entries({
      ...(status ? { status } : {}),
      ...(assessmentSeriesId ? { assessmentSeriesId } : {}),
      ...(assignmentId ? { assignmentId } : {}),
      ...(attemptId ? { attemptId } : {}),
      ...(includeModel ? { includeModel } : {}),
      ...(deploymentId ? { deploymentId } : {}),
      ...(username ? { username } : {}),
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize } : {}),
    })
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${attemptsPath}${queryParamString}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const getAttemptExportData = async ({ accountId, page = 1, pageSize = 1000 }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/attempts/export?page=${page}&pageSize=${pageSize}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const resumeAttempt = async ({ type, accountId, deploymentId, assignmentId }) => {
  const queryParamString =
    "?" +
    Object.entries({
      ...(type ? { type } : {}),
      ...(deploymentId ? { deploymentId } : {}),
      ...(assignmentId ? { assignmentId } : {}),
    })
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${resumeAttemptPath}${queryParamString}`,
    method: "GET",
    headers: {
      "Content-Type": contentTypeJSON,
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const beginAttempt = async ({
  accountId,
  type,
  assignmentId,
  deploymentId,
  deploymentConfigId,
  assessmentSeriesId,
  accessCode,
}) => {
  const queryParamString =
    "?" +
    Object.entries({
      type,
      ...(deploymentConfigId ? { deploymentId: deploymentConfigId } : {}),
      ...(assignmentId ? { assignmentId } : {}),
    })
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  const body = accessCode
    ? JSON.stringify({
        access_code: accessCode,
      })
    : null;

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${beginAttemptPath}${queryParamString}`,
    method: "POST",
    headers: {
      "Content-Type": contentTypeJSON,
      Authorization: getAccessToken(),
      account_id: accountId,
    },
    body,
  });
};

export const updateQuestionResponse = async ({
  type,
  assignmentId,
  deploymentId,
  assessmentId,
  questionId,
  answerId,
  attemptId,
  accountId,
  isPinned,
}) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assessmentQuestionResponsePath(attemptId),
    method: "POST",
    body: JSON.stringify({
      question_id: questionId,
      answer_id: answerId,
      is_pinned: isPinned,
    }),
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentTypeJSON,
      account_id: accountId,
    },
  });
};

export const getAssignmentById = async ({ assignmentId, accountId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assignmentByIdPath(assignmentId),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const getAttemptStats = async ({ accountId, attemptId, assessmentId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: attemptStatsPath({ assessmentId, attemptId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const getAssessments = async ({ type }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/admin/assessments?type=${type}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: 1,
    },
  });
};

export const updateAssessment = async ({ updates }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: "/admin/assessment",
    method: "PUT",
    headers: {
      Authorization: getAccessToken(),
      account_id: 1,
      "Content-Type": contentTypeJSON,
    },
    body: JSON.stringify(updates),
  });
};
