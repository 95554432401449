import React from "react";
import { connect } from "react-redux";

const AssessmentBreadCrumb = ({ name }) => <span>{name}</span>;

export default connect((state, props) => {
  const { assessmentId } = props.match.params;
  let name;
  let assessments =
    state.assessment.data && state.assessment.data.length > 0
      ? state.assessment.data.find((assessment) => assessment.id === assessmentId)
      : "";
  name = assessments?.name ?? state.assessment.assessmentName;

  return { name };
})(AssessmentBreadCrumb);

export const AssessmentResultsBreadCrumb = ({ name }) => <span>Results</span>;
