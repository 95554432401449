import React, { Component } from "react";
import { Sector } from "recharts";
import { connect } from "react-redux";

import DonutChart from "components/chart/DonutChart";
import { getCourseAssessmentCompletionData } from "store/state/courses/actions";
import { Card, styles } from "../card/styles";
import { CourseFrameTitle, FrameBox, FrameCell } from "styles/components/Frame";

class AttemptsDonutChart extends Component {
  state = {
    donutChartData: [
      [
        { name: "Complete", value: 0 },
        { name: "Incomplete", value: 0 },
      ],
    ],
  };

  donutChartOptions = {
    charts: [
      {
        colors: ["#ffff00", "#0000ff"],
        innerRadius: "60%",
        outerRadius: "80%",
      },
    ],
    highlightRadius: 2.5,
    legend: {
      singularFormat: "`${item.name} : ${item.value}`",
      pluralFormat: " `${item.name} : ${item.value}`",
      columnCount: 1,
    },
  };

  async componentDidMount() {
    const { getCourseAssessmentCompletionData, assignmentId } = this.props;
    await getCourseAssessmentCompletionData({ assignmentId: assignmentId });
    this.setState({
      donutChartData: [
        [
          {
            name: "Complete",
            value: this.props.assessmentDashboardCompletionDonutChart.complete,
          },
          {
            name: "Incomplete",
            value: this.props.assessmentDashboardCompletionDonutChart.incomplete,
          },
        ],
      ],
    });
  }

  render() {
    const {
      donutChartOptions,
      state: { donutChartData },
    } = this;

    return (
      <FrameBox
        style={{
          height: "475px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CourseFrameTitle style={{ alignSelf: "flex-start" }}>
          ASSESSMENT COMPLETED
        </CourseFrameTitle>
        <FrameCell
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <DonutChart options={donutChartOptions} data={donutChartData} />
        </FrameCell>
      </FrameBox>
    );
  }
}
export default connect(
  (state) => ({
    assessmentDashboardCompletionDonutChart:
      state.storedCourses.assessmentDashboardCompletionDonutChart,
  }),
  (dispatch) => ({
    getCourseAssessmentCompletionData: ({ assignmentId }) =>
      dispatch(getCourseAssessmentCompletionData({ assignmentId })),
  })
)(AttemptsDonutChart);
