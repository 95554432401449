/**
 * @ External Dependecies
 */
import styled from "styled-components";
import Dropdown, { DropdownTrigger, DropdownContent } from "react-simple-dropdown";
import { media } from "../media";
import theme from "../theme";

export const DropdownMenuTrigger = styled(DropdownTrigger)`
  display: block;
  text-decoration: none;

  .Icon-Centre-Grey {
    display: block;
    color: #96a0b2;
  }

  .LinkBtn-Help {
    width: 24px;
    height: 24px;
  }
`;

export const AccountsConnectedTrigger = styled(DropdownTrigger)`
  display: block;
  text-decoration: none;
  cursor: default;
`;

export const DropdownMenuHead = styled.div`
  padding: 25px 30px 22px;

  ${(props) =>
    props.large &&
    `
		padding: 48px 45px 27px;
	`};
`;

export const DropdownMenuTitle = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.38px;
  color: #211f50;

  ${(props) =>
    props.md &&
    `
		margin-bottom: 16px !important;
		font-size: 24px;
		font-weight: 600;
		color: #211f50;
	`} ${(props) =>
    props.sm &&
    `
		margin-bottom: 10px !important;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.2;
		color: #211f50;
	`};
`;

export const DropdownMenuBody = styled.div`
  padding: 10px;

  ${(props) =>
    props.isHelpBtn &&
    `
        padding: 0px;
        right: -2px;
        top: calc(100% + 10px);
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 41px 51px rgba(0, 0, 0, 0.27);
        margin-right: 7
	`}
`;

export const DropdownMenuEntry = styled.p`
  max-width: 376px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: #8a909c;
`;

export const DropdownMenuIcon = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

export const DropdownMenuFoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 31px 27px 32px 31px;

  ${(props) =>
    props.large &&
    `
		padding: 37px 27px 32px 47px;
	`} ${(props) =>
    props.noFlex &&
    `
		display: block;
	`};
`;

export const DropdownMenuActions = styled.div`
  padding: 10px 15px;
  text-align: center;

  ${(props) =>
    props.alignRight &&
    `
		text-align: right;
	`} ${(props) =>
    props.alt &&
    `
		padding: 30px 19px 3px;
	`};
`;

export const DropdownMenuPrepend = styled.span`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #d82237;
`;

export const DropdownMenuInput = styled.input.attrs({
  type: "text",
})`
  display: block;
  width: 100%;
  padding: 0 20px 15px 24px;
  border: none;
  border-bottom: 1px solid rgba(138, 144, 156, 0.25);
  border-radius: 0;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #8a909c;

  &::placeholder {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #8a909c;
  }
`;

export const DropdownMenuField = styled.div`
  position: relative;

  ${DropdownMenuPrepend} {
    position: absolute;
    left: 0;
    top: 1px;
  }
`;

export const DropdownMenuContent = styled(DropdownContent)`
	display: none;
	position: absolute;
	right: -2px;
	top: calc(100% + 10px);
	background: #fff;
	border-radius: 3px;
	box-shadow: 0 41px 51px rgba(0, 0, 0, 0.27);
    margin-right: 7

	&:after {
		content: '';
		position: absolute;
		bottom: 100%;
		right: 5px;
		width: 0;
		height: 0;
		border-width: 0 10px 10px 10px;
		border-style: solid;
		border-color: transparent;
		border-bottom-color: #fff;
	}

	${(props) =>
    props.small &&
    `
		top: calc(100% + 9px);
    width: 215px;
	`}

	${(props) =>
    props.large &&
    `
		top: calc(100% + 13px);
		width: 680px;
	`}

	${(props) =>
    props.right &&
    `
		right: 21px;

		&:after {
			right: 10px;
		}
	`}

	${(props) =>
    props.rightcenter &&
    `
		right: -94px;

		&:after {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		}
    `}
    ${(props) =>
      props.isPracticeAssessmentDropdown &&
      `
		right: 65px;

		top: calc(100% + 32px);
    `}
    ${(props) =>
      props.isHelpBtn &&
      `
		right: -10px;
	`}
`;

export const SimpleDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;

  & > a.active {
    use {
      fill: #d82237;
    }
  }
`;

export const SimpleDropdown = styled.ul`
  list-style-type: none;
  position: absolute;
  right: -10px;
  top: calc(100% + 20px);
  width: 221px;
  box-shadow: 0 41px 51px rgba(0, 0, 0, 0.27);
  border-radius: 2px;
  background: #fff;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    right: 10px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-width: 0 15px 15px 15px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #fff;
  }

  ${media.tablet_portrait`
		right: 10px;

		&:before {
			right: 3px;
		}
	`};
`;

export const SimpleDropdownItem = styled.li`
  border-bottom: 2px solid rgba(138, 144, 156, 0.15);

  &:last-child {
    border-bottom: none;
  }
`;

export const SimpleDropdownBtn = styled.a.attrs({
  href: "#",
})`
  display: block;
  padding: 18px 25px;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  color: #8a909c;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: #f9f9f9;
    color: #211f50;
  }
`;

const DropdownMenu = styled(Dropdown)`
  position: relative;

  &.dropdown--active {
    ${DropdownMenuContent} {
      display: block;
      z-index: 11101;
      border-radius: ${theme.border_radius};
    }
  }
`;

export default DropdownMenu;
