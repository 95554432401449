import React from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import MediaQuery from "react-responsive";

import { setPracticeAssignmentCurrentQuestion } from "store/state/practiceAssignment/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import NavigationQuestion from "./NavigationQuestion";
import {
  Navigation,
  NavigationTitle,
  NavigationInner,
  NavigationList,
  NavigationListItem,
  NavigationPrev,
  NavigationNext,
  ButtonUpload,
} from "styles/components/QuestionsNavigation";

const PrevArrowCustom = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <IconSvgComponent iconPath="svg/ico-right.svg" />
    </div>
  );
};

const NextArrowCustom = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <IconSvgComponent iconPath="svg/ico-right.svg" />
    </div>
  );
};

const QuestionsNavigation = ({
  questions,
  currentQuestionIndex,
  setPracticeAssignmentCurrentQuestion,
}) => {
  const sliderSettings = {
    infinite: false,
    slidesToShow: 26,
    nextArrow: <NextArrowCustom />,
    prevArrow: <PrevArrowCustom />,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 24,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 22,
        },
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 20,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 20,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 18,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 14,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 12,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const handleQuestionButtonClick = (questionId, index) => {
    setPracticeAssignmentCurrentQuestion({
      questionId: questionId,
      questionIndex: index,
    });
  };

  return (
    <Navigation>
      <NavigationTitle>Question Navigator</NavigationTitle>

      <NavigationInner>
        <NavigationList>
          <Slider {...sliderSettings}>
            {questions.map((question, index) => {
              const getNavQuestionType = (attempts, isCorrect) => {
                if (attempts === 0) return "";
                if (attempts === 1 && isCorrect === true) return "perfect";
                if (attempts > 0 && isCorrect === false) return "incorrect";
                if (attempts > 1 && isCorrect === true) return "correct";
              };
              const navQuestionType = getNavQuestionType(question.attempts, question.isCorrect);

              return (
                <NavigationListItem key={question.id}>
                  <NavigationQuestion
                    type={navQuestionType}
                    attempts={question.attempts}
                    text={index + 1}
                    current={currentQuestionIndex === index}
                    onClick={() => handleQuestionButtonClick(question.id, index)}
                  />
                </NavigationListItem>
              );
            })}
          </Slider>
        </NavigationList>
      </NavigationInner>
    </Navigation>
  );
};

export default connect(
  (state) => ({
    questions: state.practiceAssignment.assignmentQuestions,
    currentQuestionIndex: state.practiceAssignment.currentQuestionIndex,
  }),
  { setPracticeAssignmentCurrentQuestion }
)(QuestionsNavigation);
