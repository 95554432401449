import styled from "styled-components";
import theme from "../theme";
import { media } from "../media";
import SearchContainer, { SearchField } from "styles/components/Search";

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 120px 0 100px;
  justify-content: center;

  ${media.tablet`
    padding: 0 20px;
  `};

  ${media.mobile`
    padding: 0;
  `};
`;

export const Title = styled.h3`
  flex: 0 0 100%;
  margin: 0 0 36px;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
`;

export const RowSidebar = styled.div`
  flex: 0 0 250px;
  padding: 62px 0 0;

  ${media.tablet`
    flex: 0 0 220px;
  `};

  ${media.mobile`
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    padding: 20px 0 0;
  `};
`;

export const SidebarTitle = styled.h4`
  margin: 0 0 8px;
  font-size: 17px;
  font-weight: 500;
`;

export const ButtonHolder = styled.h4`
  max-width: 146px;
  padding: 10px 0;

  button {
    padding: 10px 23px;
  }
`;

export const RowContent = styled.div`
  flex: 0 1 calc(100% - 320px - 30px);

  ${media.tablet`
    flex: 0 1 calc(100% - 220px - 30px);
  `};

  ${media.mobile`
    flex: 0 0 100%;
    max-width: 100%;
  `};
`;

export const AccordionContent = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: #9b9b9b;
`;

export const AccordionTitle = styled.div`
  font-size: 20px;
  font-weight: 500;

  span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4.5px 0 4.5px;
      border-color: #9b9b9b transparent transparent transparent;
    }
  }
`;

export const AccordionInfoTitle = styled.div`
  color: ${theme.color.blue};
  padding: 0px 17px;
  height: 22px;

  span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 5px;
    height: 16px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4.5px 0 4.5px;
      border-color: ${theme.color.blue} transparent transparent transparent;
    }
  }
`;

export const AccordionInfoList = styled.ul`
  list-style-type: none;
  padding: 10px 17px;
  font-size: 15px;
  .svg-icon {
    display: inline-block;
    margin-right: 5px;
    width: 14px;
    height: 14px;
  }
`;

export const AccordionInfoItem = styled.li``;

export const Accordion = styled.div`
  margin: 0 0 10px;

  &.accordion--space {
    margin: 0 0 30px;

    ${AccordionTitle} {
      margin: 0 0 10px;
    }
  }

  &.is-hidden {
    ${AccordionContent} {
      display: none;
    }

    ${AccordionTitle} {
      span {
        transform: rotate(-90deg);
      }
    }

    ${AccordionInfoList} {
      display: none;
    }

    ${AccordionInfoTitle} {
      span {
        transform: rotate(-90deg);
      }
    }
  }
`;

export const Remove = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  cursor: pointer;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    background: ${theme.color.gray};
    width: 2px;
    height: 14px;
    margin-left: -1px;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
`;

export const Selected = styled.div`
  position: relative;
  padding: 1px 12px;
  color: ${theme.color.darker_gray};
  margin: 0 0 0 -12px;

  &:hover {
    background: #e3ebfa;
    border-radius: 8px;

    ${Remove} {
      &:after,
      &:before {
        background: ${theme.color.red};
      }
    }
  }
`;

export const TabHead = styled.div`
  margin: 0 0 30px;

  ul {
    display: flex;
  }

  li {
    position: relative;
    padding: 0 15px;
    list-style: none;
    font-size: 20px;
    font-weight: 300;
    color: ${theme.color.darker_gray};
    margin: 0 40px 0 10px;
    cursor: pointer;

    &:hover {
      color: ${theme.color.blue};
    }

    &:after {
      content: "";
      opacity: 0;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${theme.color.blue};
      border-radius: 5px;
      transition: opacity 0.3s;
    }

    &.is-active {
      color: ${theme.color.blue};
      &:after {
        opacity: 1;
      }
    }

    ${media.mobile`
        font-size: 16px;
        margin: 0 15px;
    `};
  }
`;

export const Tab = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: none;

  &.is-active {
    display: block;
  }

  ${SearchContainer} {
    visibility: visible;
    margin: 0 0 24px;
    max-width: 100%;
    border-radius: 8px;

    ${SearchField} {
      height: 48px;
      max-width: 100%;
      padding-right: 60px;
      border-radius: 8px;
      border: 0;
      font-size: 16px;
      font-weight: 300;
      color: ${theme.color.darker_gray};
    }
  }

  .ReactTable {
    border: 1px solid rgba(151, 151, 151, 0.44);
    border-radius: 8px;
  }
`;

export const Videos = styled.div`
  display: grid;
  grid-gap: 17px;
  grid-template-columns: repeat(auto-fill, minmax(231px, 1fr));

  ${media.tablet`
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  `};
`;

export const VideoInner = styled.div`
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const Video = styled.div`
  border: 8px solid transparent;
  border-radius: 18px;
  cursor: pointer;

  &:hover,
  &.is-active {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    border-color: #9cd4ff;

    ${VideoInner} {
      box-shadow: none;
    }
  }
`;

export const VideoImage = styled.div`
  padding-bottom: 60.1732%;
  background-size: cover;
`;

export const VideoContent = styled.div`
  padding: 10px 17px;
  font-size: 15px;

  p {
    margin: 0;
  }
`;

export const Expander = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 0 0 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0 4.5px;
    border-color: #9b9b9b transparent transparent transparent;
    vertical-align: middle;

    &.is-expanded {
      border-width: 0 4.5px 5px 4.5px;
      border-color: transparent transparent #9b9b9b transparent;
    }
  }

  p {
    margin: 0;
  }
`;

export const SubComponent = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  padding: 0 20px 15px;
`;

export const FilterBtn = styled.button`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 49px;
  transform: translateY(-50%);
  background: none;
  border: 0;
`;

export const PanelList = styled.div`
  font-size: 17px;
  font-weight: 300;
  color: #9b9b9b;
`;

export const PanelListItem = styled.div`
  position: relative;
  padding: 1px 12px;
  color: ${theme.color.darker_gray};
  margin: 0 0 0 -12px;
  cursor: pointer;
  &:hover {
    background: #e3ebfa;
    border-radius: 8px;
  }
  ${(props) =>
    props.selected &&
    `
    background: #e3ebfa;
    border-radius: 8px;
    color: blue;
  `}
`;
