/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import { NextArrow, PrevArrow } from "components/common/SliderArrow";

/**
 * The slider configurations.
 *
 * @type {Object}
 */
export default {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
