/**
 * @ External Dependecies
 */
import styled from "styled-components";
import theme from "../theme";

export const SectionContainer = styled.div`
  text-align: center;
`;

export const AssessmentPasscodeContainer = styled.div`
  padding: 30px 0;
`;
export const AssessmentIcon = styled.div`
  margin-bottom: 16px;
  text-align: center;
`;
export const AssessmentTitle = styled.h3`
  margin-bottom: 5px;
  text-align: center;
`;
export const AssessmentForm = styled.form`
  max-width: 486px;
  margin: 0 auto;

  label span {
    color: ${theme.color.red};
  }
`;
export const AssessmentFormActions = styled.div`
  margin-top: 14px;
  text-align: center;
  margin-bottom: 11px;

  .btn-cancel {
    margin: 0 14px;
    font-family: ${theme.font};
    font-size: 16px;
    font-weight: 700;
  }

  .btn-submit {
    padding-right: 15px;
    margin: 0 14px;

    .svg-icon {
      margin-left: 15px;
    }
  }
`;
