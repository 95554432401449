import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Courses, {
  Course,
  CourseTitle,
  CourseInner,
  CourseBody,
  CourseFoot,
  CourseEntry,
  CourseFootFlex,
  CourseFootFlexItem,
  CourseAssignmentStatus,
} from "styles/components/Courses";
import TableDropdown from "components/elements/TableDropdown";
import IconSvgComponent from "components/common/IconSvgComponent";
import stemifyRoutes from "../../constants/routes";
import { ROLES } from "constants/roles";
import { isEmpty } from "ramda";
import { ASSIGNMENT_TYPES } from "constants/common";

const AssignmentStudentGradesCard = ({ assignments }) => {
  const gradesByAssignment = useSelector((state) => state.storedCourses.gradesByAssignment);

  const user = useSelector((state) => state.user);
  const { courseId } = useParams();

  const getGradePointsValues = (id) => {
    const assignmentGradeData = gradesByAssignment[id];
    return assignmentGradeData !== undefined
      ? assignmentGradeData["assignment"]["point_value"]
      : "No";
  };
  /*
    Grades by assignment for a section.
    The response structure differs for assignment types.
    For exam & practice types, student_grades.grade object will contain the result for the exam and practice types.
    student_grades.video will contain the result for video assignment type.
    Only exam will have class_avg. completed_count will be updated for all 3 types.
  */
  const getStatusForNonGradedAssignment = (assignmentGrades) => {
    const studentGrade = assignmentGrades.student_grades;
    switch (assignmentGrades.assignment.assignment_type) {
      case ASSIGNMENT_TYPES.OPENSTAX:
        return studentGrade.length > 0
          ? studentGrade[0].activity.status === "DONE"
            ? "Complete"
            : "Incomplete"
          : null;

      case ASSIGNMENT_TYPES.VIDEO:
        return studentGrade.length > 0 ? studentGrade[0].video.status : null;

      case ASSIGNMENT_TYPES.PRACTICE:
      case ASSIGNMENT_TYPES.EXAM:
        return studentGrade.length > 0 ? studentGrade[0].grade.status : null;
      default:
        return null;
    }
  };

  const getScoreForGradedAssignment = (assignmentGrades) => {
    const studentGrade = assignmentGrades.student_grades;
    switch (assignmentGrades.assignment.assignment_type) {
      case ASSIGNMENT_TYPES.VIDEO:
        return studentGrade.length > 0 ? (
          studentGrade[0].video.status === "Complete" ? (
            <CourseAssignmentStatus complete> Complete </CourseAssignmentStatus>
          ) : (
            <CourseAssignmentStatus incomplete> Incomplete </CourseAssignmentStatus>
          )
        ) : null;

      case ASSIGNMENT_TYPES.PRACTICE:
      case ASSIGNMENT_TYPES.EXAM:
        return studentGrade.length > 0 ? <h4>{`${studentGrade[0].grade.score} %`}</h4> : null;
      default:
        return null;
    }
  };

  const getScore = (id) => {
    const assignmentGradeData = gradesByAssignment[id];

    if (assignmentGradeData !== undefined) {
      if (assignmentGradeData["assignment"]["graded_indicator"] === false) {
        // non graded assignment
        const completionStatus = getStatusForNonGradedAssignment(assignmentGradeData);

        if (completionStatus === "Complete") {
          return <CourseAssignmentStatus complete> Complete </CourseAssignmentStatus>;
        } else if (completionStatus === "Incomplete") {
          return <CourseAssignmentStatus incomplete> Incomplete </CourseAssignmentStatus>;
        } else {
          return null;
        }
      } else {
        // graded assignment
        return getScoreForGradedAssignment(assignmentGradeData);
      }
    }
    return null;
  };

  return (
    <Courses>
      {assignments.items.map((assignment) => {
        const assignmentGrades = gradesByAssignment[assignment.id];

        return (
          <Course key={assignment.id}>
            <CourseInner>
              <CourseBody background={assignment.background}>
                <CourseTitle>{assignment.name}</CourseTitle>

                <CourseEntry>{assignment.title}</CourseEntry>
                {![ASSIGNMENT_TYPES.VIDEO, ASSIGNMENT_TYPES.OPENSTAX].includes(
                  assignment.assignmentType
                ) && <CourseEntry>{getGradePointsValues(assignment.id)} Points</CourseEntry>}
              </CourseBody>

              <CourseFoot>
                <CourseFootFlex>
                  {/* the average grade across all students who have attempted the assignment should be
                displayed to the student only if at least 3 students have completed the assignment */}
                  {assignmentGrades &&
                    assignmentGrades.completed_count > 2 &&
                    assignment.assignmentType === ASSIGNMENT_TYPES.EXAM && (
                      <CourseFootFlexItem>
                        <p>Class Average</p>
                        <h4>
                          {assignmentGrades
                            ? assignmentGrades.class_avg
                              ? assignmentGrades.class_avg
                              : 0
                            : 0}
                        </h4>
                      </CourseFootFlexItem>
                    )}
                  <CourseFootFlexItem>
                    <p>My Grade</p>
                    {getScore(assignment.id) || <h4>{"--"}</h4>}
                  </CourseFootFlexItem>
                </CourseFootFlex>
              </CourseFoot>
            </CourseInner>
          </Course>
        );
      })}
    </Courses>
  );
};

export default AssignmentStudentGradesCard;
