/**
 * @ External Dependecies
 */
import styled, { css } from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";
import theme from "styles/theme";

export const UnstyledButton = styled.button.attrs({
  id: (props) => props.id,
})`
  background-color: transparent;
  border-radius: 22px;
  border: 2px solid transparent !important;

  &:focus {
    border: 2px solid ${theme.color.blue} !important;
  }

  ${(props) =>
    props.margintop &&
    `
        margin-top:${props.margintop}px;
    `}

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.75;
  `}
   ${(props) =>
     props.isHidden &&
     `
    visibility: hidden;
  `}
  ${(props) =>
    props.blue &&
    `
    color: ${theme.color.blue} !important;

  `}
`;

const StyledButton = styled.button.attrs({
  id: (props) => props.id,
})`
	border: none;
	font-size: 16px;
	line-height: 1.25;
	padding: 8px 35px;
	font-weight: 450;
	border-radius: 22px;
    border: 2px solid transparent !important;

     &.gray-btn {
    padding: 8px 21px;
    color: #fff;
    background: ${theme.color.gray};

    .svg-icon  {
      margin-left: 10px;
    }
  }

	.svg-icon,
	svg,
	span {
		display: inline-block;
		vertical-align: middle;
  }

  ${(props) =>
    props.isHidden &&
    `
    visibility: hidden;
  `}

	${(props) =>
    props.transparent &&
    `
		background: transparent !important;
		box-shadow: none !important;
	`}

	${(props) =>
    props.xs &&
    `
		padding: 4px 19px;
		font-size: 14px;
	`}

	${(props) =>
    props.violet &&
    `
		background: ${theme.color.red};
		color: #FFFFFF;
		transition: background .4s;
  `}

  ${(props) =>
    props.color &&
    `
		background: ${props.color};
		color: #FFFAF9;
		transition: background .4s;
  `}


  ${(props) =>
    props.violet &&
    !props.disabled &&
    `
		&:hover {
			background: ${theme.color.red_faded};
		}
  `}

	${(props) =>
    props.transparent &&
    !props.color &&
    `
		color: #9B9B9B;
    `}

    ${(props) =>
      props.color &&
      `
		color: ${props.color};
	`}

	${(props) =>
    props.fab &&
    `
		position: relative;
		width: ${props.size}px;
		height: ${props.size}px;
		padding: 0;
		border-radius: 50%;
		font-size: 0;
		line-height: 0;
		box-shadow: 0px 2px 4px 0px rgba(0,0,0, .5);

		&:after,
		&:before {
			content: '';
			position: absolute;
			width: 25px;
			height: 4px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 3px;
			background: #fff;
		}

		&:after {
			height: 25px;
			width: 4px;
		}
	`}

	${(props) =>
    props.transparent &&
    `
		background: transparent;
	`}

	${(props) =>
    props.hasIcon &&
    `
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 8px 12px 8px 20px;

		.svg-icon {
			margin: 0 0 0 16px;
		}
  `}

  ${(props) =>
    props.positionTop &&
    `
      z-index: 1;
  `}

  ${(props) =>
    props.isPrevious &&
    `
    padding: 8px 20px 8px 12px;

    .svg-icon {
      margin: 0 16px 0 0;
    }
  `}

	${(props) =>
    props.reverse &&
    `
		background: #AD50FF;
		color: #8013E0;
		transition: background .4s;
		border: 2px solid #8013E0 !important;
	`}

	${(props) =>
    props.reverse &&
    !props.disabled &&
    `
		&:hover {
			background: #8013E0;
			color: #FFFFFF;
		}
	`}

	${(props) =>
    props.widgetButton &&
    `
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 8px 10px 10px 10px;
		width: 130px;
		height:40px;
		background: ${theme.color.red} !important;
		color: #FFFFFF;
		transition: opacity .4s;
	`}

	${(props) =>
    props.widgetButton &&
    !props.disabled &&
    `
		&:hover {
			background:${theme.color.red_faded}
			opacity: 0.75;
		}
	`}

	${(props) =>
    props.red &&
    `
		background: #e70f0e;
		color: #FFFFFF;
		transition: background .4s;
		font-family: "Circular Pro";
		font-size: 19.92px;
		font-weight: 500;
		line-height: 25px;
	`}

	${(props) =>
    props.danger &&
    `
		padding: 9px 20px;
		background: ${theme.color.red};
		color: #FFFFFF;
		transition: background .4s;
		font-family: "Circular Pro";
		font-size: 16px;
		font-weight: 700;
		line-height: 1.1;
	`}

	&:focus {
		border: 2px solid #0b289b !important;
  }

  ${(props) =>
    props.floatdir &&
    `
    float: ${props.floatdir}
    `}

    ${(props) =>
      props.margintop &&
      `
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-top:${props.margintop}px;
    `}

    ${(props) =>
      props.isAssessmentSubmissionGuide &&
      `
        color: #4A4A4A;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px
    `}

    &.btn-add-item:hover {
    color: ${theme.color.red};
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;

    &.btn-submit {
      opacity: .5 !important;
    }

    &.btn-add-item:hover {
      color: inherit;
    }
  `}

`;

export const StyledAnchor = styled.a`
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.04em;
  color: #8013e0;
  border: 2px solid transparent !important;
  border-radius: 4px;
  padding: 2px 6px 2px 6px;
  margin-top: -2px;
  margin-right: -8px;

  ${(props) =>
    props.gray &&
    `
		color: #9B9B9B;
	`}

  &:focus {
    border: 2px solid #0b289b !important;
  }
`;

export const Link = styled.button`
  display: inline-block;
  vertical-align: middle;
  background: none;
  border: none;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.04em;
  color: #8013e0;
  border: 2px solid transparent !important;
  border-radius: 15px;
  padding: 2px 6px 2px 6px;
  margin-top: -4px;
  margin-right: -8px;

  ${(props) =>
    props.gray &&
    `
		color: #9B9B9B;
	`}

  &:focus {
    border: 2px solid #0b289b !important;
  }
`;

export const LinkButton = styled.button`
  display: inline-block;
  vertical-align: middle;
  background: none;
  border: none;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.04em;
  color: ${theme.color.red};
  border: 2px solid transparent !important;
  border-radius: 4px;
  padding: 2px 6px 2px 6px;
  margin-top: -2px;
  margin-right: -8px;

  ${(props) =>
    props.gray &&
    `
		color: #9B9B9B;
	`}

  &:focus {
    border: 2px solid #0b289b !important;
  }
`;

export const TextLink = styled.a`
  border: 2px solid transparent !important;
  border-radius: 4px;
  padding-left: 10px;
  padding-bottom: 1px;

  ${(props) =>
    props.noLeftPadding &&
    `
		padding-left: 0px !important;
	`}
`;

export const RouterLink = styled(ReactRouterLink)`
  text-decoration: none;
  border: 2px solid transparent !important;

  & > span {
    padding-top: 0px;
  }

  &:focus {
    border: 2px solid #0b289b !important;
  }

  &:hover {
    text-decoration: underline;
  }

  border-radius: 4px;
  padding-left: 10px;
  padding-bottom: 1px;

  ${(props) =>
    props.isbuttonstyle &&
    `
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		padding: 1px 17px;
		background-color: ${theme.color.red};
		background: ${theme.color.red};
		border-radius: 13px;
		font-size: 14px;
		font-weight: 900;
		color: ${theme.color.white};
		text-align: center;
		transition: opacity .3s;
		border: 2px solid transparent !important;
		min-width: 118px;
		height: 26px;

		&:hover {
			opacity: .7;
		}

		&:focus {
			border: 2px solid ${theme.color.red_faded} !important;
		}
	`}

  ${(props) =>
    props.float &&
    `
		float: right;
    `}

    &.no-left-padding {
    padding-left: 0px;
  }
`;

export const Pill = styled.span`
	display: inline-block;
	vertical-align: middle;
	margin: 0 7px;
	padding: 4px 12px;
	background: ${theme.color.red};
	border-radius: 10px;
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	color: #fff;

	${(props) =>
    props.small &&
    css`
      font-size: 11px;
      margin: 0;
      padding: 0px 9px;
      border-radius: 7px;
      border: 1px solid transparent;
    `}

	${(props) =>
    props.primary &&
    css`
      background: ${theme.color.red};
    `}

	${(props) =>
    props.primaryOutline &&
    css`
      background: #fff;
      border: 1px solid ${theme.color.orange};
      color: ${theme.color.orange};
    `}

	${(props) =>
    props.default &&
    css`
      background: ${theme.color.gray};
    `}

	${(props) =>
    props.defaultOutline &&
    css`
      background: #fff;
      border: 1px solid ${theme.color.gray};
      color: ${theme.color.gray};
    `}

  ${(props) =>
    props.big &&
    css`
      font-size: 11px;
    `}

  ${(props) =>
    props.defaultSuccessOutline &&
    css`
      border: 1px solid ${theme.color.success};
      color: ${theme.color.white};
      background-color: ${theme.color.success};
      margin-left: 5px;
      white-space: nowrap;
    `}

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: 24px;
      right: 20px;
    `}
`;

export const BtnGroupFloat = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${StyledButton} {
    transition: transform 0.3s;
  }

  ${(props) =>
    props.active &&
    css`
      & > ${StyledButton} {
        transform: rotate(45deg);
      }
    `}
`;

export const BtnWithText = styled(StyledButton).attrs({
  className: "btn-text",
})`
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background: #fff;

  span {
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 8px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    font-size: 9px;
    line-height: 1.22;
    white-space: nowrap;
    color: #4a4a4a;
  }

  ${(props) =>
    props.fab &&
    `
		box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.50);

		&:before,
		&:after {
			display: none;
		}
	`}
`;

export const BtnGroupFloatWrap = styled.div`
  .btn-text {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }

  .btn-text:nth-child(2) {
    top: 89px;
    transition-delay: 0.2s;
  }

  .btn-text:nth-child(3) {
    top: 129px;
    transition-delay: 0.3s;
  }

  .btn-text:nth-child(4) {
    top: 169px;
    transition-delay: 0.4s;
  }

  .btn-text:nth-child(5) {
    top: 209px;
    transition-delay: 0.5s;
  }

  .btn-text:nth-child(6) {
    top: 249px;
    transition-delay: 0.6s;
  }

  ${(props) =>
    props.active &&
    `
		.btn-text {
			opacity: 1;
			visibility: visible;
		}
	`}
`;

export default StyledButton;
