import React, { useState, useEffect, useCallback, useMemo } from "react";
import classNames from "classnames";
import { connect, useDispatch, useSelector } from "react-redux";
import { equals } from "ramda";
import FullscreenModalItem from "components/courses/FullscreenModal";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import { openModal, closeModal } from "store/state/ui/actions";
import { PaginatedTable, NoDataComponent } from "components/common/table";
import { FullscreenModalContent } from "styles/components/FullscreenModal";
import SearchContainer, { SearchField, SearchButton } from "styles/components/Search";
import {
  Row,
  Title,
  RowSidebar,
  SidebarTitle,
  RowContent,
  Tab,
  Expander,
  SubComponent,
  FilterBtn,
  PanelList,
  PanelListItem,
} from "styles/components/ModalCoursesAddToAssignment";
import { setAssignmentAssets } from "store/state/courses/actions";
import "styles/assessmentList_react_table_override.css";
import { ASSIGNMENT_TYPES } from "constants/common";
import Field from "components/common/Field";
import { FormControls } from "styles/components/Form";
import { getOpenStaxReleases } from "services/courses";

const getFilteredSections = (search, sections) => {
  return sections.filter((section) => {
    return section.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  });
};

const getPaginationProperties = ({ filteredData, pageSize }) => {
  return {
    count: filteredData.length,
    pages: Math.ceil(filteredData.length / pageSize),
  };
};

const getPageData = ({ data, pageSize, page }) => {
  const skip = pageSize * page;
  return data.slice(skip, skip + pageSize);
};

const defaultTableState = {
  selectedConcept: {},
  search: "",
  pageSize: 10,
  page: 0,
  pages: null,
  count: 0,
};

const ModalCourseAddOpenStax = ({ onClose, active }) => {
  const dispatch = useDispatch();
  const accountId = useSelector((state) =>
    !!state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId
  );
  const [subjects, updateSubjects] = useState([]);
  const [books, updateBooks] = useState([]);
  const [releases, updateReleases] = useState({});
  const [filteredBooks, updateFilteredBooks] = useState([]);
  const [selectedSubject, updateSelectedSubject] = useState({ label: "Math", value: "Math" });
  const [selectedBookSlug, updateSelectedBookSlug] = useState(null);
  const [selectedBook, updateSelectedBook] = useState(null);
  const [bookContents, updateBookContents] = useState([]);
  const [tableState, updateTableState] = useState(defaultTableState);

  const fetchSubjectsAndBooks = useCallback(async () => {
    const releasesRequest = getOpenStaxReleases({ accountId });
    const booksRequest = fetch("https://openstax.org/apps/cms/api/books").then((res) => res.json());
    const [releasesResponse, booksResponse] = await Promise.all([releasesRequest, booksRequest]);

    const subjects = Object.values(
      booksResponse.books.reduce((subjects, book) => {
        const newSubjects = book.subjects.reduce((list, subject) => {
          return { ...list, [subject]: subject };
        }, {});

        return {
          ...subjects,
          ...newSubjects,
        };
      }, {})
    );
    const books = booksResponse.books;

    updateSubjects(subjects);
    updateBooks(books);
    updateReleases(releasesResponse.data);
    const filteredBooks = books.filter((b) => {
      return b.subjects.includes("Math");
    });
    updateFilteredBooks(filteredBooks);
  }, [accountId]);

  const fetchBookDetails = async (slug) => {
    const bookDetails = await fetch(`https://openstax.org/apps/cms/api/${slug}`).then((res) =>
      res.json()
    );

    updateSelectedBook({
      ...bookDetails,
      cover_url: books.find((b) => b.slug === slug)?.cover_url,
    });
  };

  const fetchBookContents = useCallback(
    async (bookId) => {
      const releaseId = releases.books[bookId].defaultVersion;
      const result = await fetch(
        `https://openstax.org/apps/archive/20240226.174525/contents/${bookId}@${releaseId}.json`
      ).then((res) => res.json());
      // const result = await fetch(
      //   `https://archive.cnx.org/contents/${bookId}@${releaseId}.json`
      // ).then((res) => res.json());
      const outline = result.tree.contents;
      let abstractedOutline = [];

      const handleOutlineObject = (level) => (contentItem) => {
        if (level === "ROOT" && contentItem.contents) {
          abstractedOutline.push({
            title: new DOMParser().parseFromString(contentItem.title, "text/html").documentElement
              .textContent,
            slug: contentItem.slug,
            level,
          });
        } else if (level === "CHILD") {
          abstractedOutline.push({
            title: new DOMParser().parseFromString(contentItem.title, "text/html").documentElement
              .textContent,
            slug: contentItem.slug,
            level,
          });
        }

        if (contentItem.contents) {
          contentItem.contents.forEach(handleOutlineObject("CHILD"));
        }
      };
      outline.forEach(handleOutlineObject("ROOT"));
      updateBookContents(abstractedOutline);
    },
    [releases.books]
  );

  const reset = () => {
    updateSubjects([]);
    updateBooks([]);
    updateFilteredBooks([]);
    updateSelectedSubject({ label: "Math", value: "Math" });
    updateSelectedBookSlug(null);
    updateSelectedBook(null);
    updateBookContents([]);
    updateTableState(defaultTableState);
  };

  useEffect(() => {
    if (active) {
      fetchSubjectsAndBooks();
    } else {
      reset();
    }
  }, [active, fetchSubjectsAndBooks]);

  useEffect(() => {
    if (selectedBook) {
      fetchBookContents(selectedBook.cnx_id);
    }
  }, [fetchBookContents, selectedBook]);

  const handleSelectSubject = (selectedItem) => {
    updateSelectedSubject(selectedItem);

    const subject = selectedItem?.value;
    if (subject) {
      const filteredBooks = books.filter((b) => {
        return b.subjects.includes(subject);
      });
      updateFilteredBooks(filteredBooks);
    }
  };

  const handleSelectBook = (selectedItem) => {
    updateSelectedBookSlug(selectedItem);

    const slug = selectedItem?.value;
    if (slug) fetchBookDetails(slug);
  };

  const handleClose = useCallback(() => {
    updateTableState(defaultTableState);
    onClose();
  }, [onClose]);

  const handleSearchChange = (event) => {
    const search = event.target.value;

    updateTableState((prevState) => {
      return {
        ...prevState,
        search,
      };
    });
  };

  const saveSection = useCallback(
    (props) => {
      const { row } = props;

      if (selectedBook && selectedBookSlug) {
        dispatch(
          setAssignmentAssets({
            openstax_thumbnail: selectedBook.cover_url,
            openstax_title: selectedBook.title,
            openstax_description: row.original.title,
            openstax_url: `https://openstax.org/${selectedBookSlug.value}/pages/${row.original.slug}`,
          })
        );
        handleClose();
      }
    },
    [dispatch, handleClose, selectedBook, selectedBookSlug]
  );

  const columns = useMemo(() => {
    return [
      {
        id: "title",
        Header: "Section",
        accessor: "title",
        minWidth: 160,
      },
      {
        id: "selectSection",
        Header: "",
        minWidth: 100,
        Cell: (props) => {
          if (props.row.original.level === "CHILD") {
            return (
              <ButtonLink transparent xs style={{ color: "blue" }}>
                <span onClick={() => saveSection(props)}>SELECT</span>
              </ButtonLink>
            );
          } else {
            return null;
          }
        },
      },
    ];
  }, [saveSection]);

  const { search, pageSize, page } = tableState;
  const filteredSections = getFilteredSections(search, bookContents);
  const { count } = getPaginationProperties({
    filteredData: filteredSections,
    pageSize,
  });
  const pageData = getPageData({ data: filteredSections, pageSize, page });

  return (
    <FullscreenModalItem active={active} onClose={handleClose}>
      <FullscreenModalContent max_width="100%">
        <Row>
          <Title>Select OpenStax Section</Title>

          <RowContent>
            <div className="tabs__content">
              <Tab className={classNames({ "is-active": true })}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <FormControls
                    style={{
                      marginBottom: "0px",
                      marginRight: "20px",
                      width: "25%",
                    }}
                  >
                    <Field
                      id="subject"
                      name="subject"
                      isSelect
                      onChange={handleSelectSubject}
                      options={subjects.map((s) => ({ label: s, value: s }))}
                      value={selectedSubject || ""}
                      placeholder="Subject"
                      required
                    />
                  </FormControls>
                  <FormControls
                    style={{
                      flexGrow: "1",
                      marginBottom: "0px",
                    }}
                  >
                    <Field
                      id="book"
                      name="book"
                      isSelect
                      onChange={handleSelectBook}
                      options={filteredBooks.map((b) => ({ label: b.title, value: b.slug }))}
                      value={selectedBookSlug || ""}
                      placeholder="Textbook"
                      required
                    />
                  </FormControls>
                </div>

                <SearchContainer>
                  <SearchField
                    placeholder="Search by concept, section, or chapter..."
                    // disabled={!bookContents.length}
                    onChange={handleSearchChange}
                    value={search}
                  />
                  <SearchButton>
                    <IconSvgComponent iconPath="svg/ico-magnifier.svg" />
                  </SearchButton>
                </SearchContainer>

                <PaginatedTable
                  data={pageData}
                  columns={columns}
                  count={count}
                  isLoading={false}
                  NoDataComponent={() => {
                    return (
                      <div
                        style={{
                          height: "487px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingTop: "168px",
                        }}
                      >
                        <NoDataComponent
                          customMessage="Select a textbook to view the list of chapters and sections."
                          noSubMessage
                        />
                      </div>
                    );
                  }}
                  style={{ fontSize: "14px", fontWeight: 500 }}
                  getTrProps={(state, rowInfo, column, instance) => {
                    if (rowInfo?.original?.level === "ROOT") {
                      return {
                        style: {
                          backgroundColor: "#4A4A4A",
                          color: "#fff",
                          height: "30px",
                        },
                      };
                    } else {
                      return {};
                    }
                  }}
                  getTdProps={(state, rowInfo, column, instance) => {
                    if (rowInfo?.original?.level === "ROOT") {
                      return {
                        style: {
                          color: "#fff",
                        },
                      };
                    } else {
                      return {};
                    }
                  }}
                />
              </Tab>
            </div>
          </RowContent>
        </Row>
      </FullscreenModalContent>
    </FullscreenModalItem>
  );
};

export default ModalCourseAddOpenStax;
