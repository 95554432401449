import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import { Transition, animated } from "react-spring/renderprops";

import {
  MODAL_ASSESSMENT_SUBMIT,
  MODAL_SUBMIT_ASSESSMENT_GUIDE,
  MODAL_ASSESSMENT_TIME_END,
} from "lib/constants";
import { openModal } from "store/state/ui/actions";
import {
  setCompletedQuestion,
  setCurrentQuestion,
  setPinnedQuestion,
  updateQuestionResponse,
  getAssessmentSeries,
  selectAssessmentSeries,
  setActiveAssessment,
  getAttempts,
  resumeAttempt,
  startCountdown,
  clearAttempt,
} from "store/state/assessment/actions";

import {
  getAssignmentsBySectionId,
  getAssessmentDetailsById,
  getAssessments,
  getSectionById,
} from "store/state/courses/actions";

/* END Course Assessment */

import IconSvgComponent from "components/common/IconSvgComponent";
import Wrapper from "styles/components/Wrapper";
import ProgressBar from "../elements/ProgressBar";
import Countdown from "../elements/Countdown";
import AssessmentWelcomeMessage from "components/assessment/AssessmentWelcomeMessage";
import AssessmentQuestion from "components/assessment/AssessmentQuestion";
import AssessmentSidebar from "components/assessment/AssessmentSidebar";
import {
  SectionContainer,
  SectionContent,
  SectionHead,
  SectionBody,
  SectionOuter,
  AssessmentUpload,
} from "styles/components/AssessmentTaker";
import { NoDataMessageContainer } from "styles/components/Card";
import Loader from "components/common/loader";
import { ASSESSMENT_TYPES } from "constants/common";
import { renewAccessToken } from "services/login";
import stemifyRoutes from "constants/routes";
import moment from "moment";

class AssessmentTaker extends Component {
  state = {
    barTopPosition: 0,
    initialDataLoaded: false,
  };

  scrollableNode = null;

  async componentDidMount() {
    /*
    set time interval to refresh the access token after every 45mins,
    so that user doesn't logout when ideal for an hour.
    */
    this.refreshTokenInterval = setInterval(async () => {
      renewAccessToken();
    }, 2700000); // Refresh the access token after every 45 minutes (2700000)

    const {
      match,
      history,
      getAssessmentSeries,
      selectAssessmentSeries,
      setActiveAssessment,
      getAssignmentsBySectionId,
      getAttempts,
      getAssessments,
      getSectionById,
      resumeAttempt,
      startCountdown,
      clearAttempt,
      openModal,
    } = this.props;
    const {
      assessmentId,
      deploymentId: deploymentConfigId,
      courseId,
      assignmentId,
      attemptId,
    } = match.params;
    const isCourseAssessment = courseId && assignmentId && assessmentId;

    clearAttempt();

    if (isCourseAssessment) {
      const assessmentSeriesPromise = getAssessmentSeries();
      const assessmentsPromise = getAssessments();

      const attemptQueryObject = attemptId
        ? { attemptId }
        : {
            assignmentId,
            assessmentSeriesId: assessmentId,
          };
      const openAttemptsPromise = attemptId
        ? getAttempts({ attemptId })
        : getAttempts({
            ...attemptQueryObject,
            status: "open",
            pageSize: 1,
            page: 0,
          });
      const selectSeriesPromise = selectAssessmentSeries({ assessmentId });

      const setActiveAssessmentPromise = setActiveAssessment({
        assessmentId,
        courseId,
        assignmentId,
      });
      const assignmentsPromise = getAssignmentsBySectionId({ courseId });

      const [
        assessmentSeries,
        assessments,
        openAttempts,
        selectedSeries,
        activeAssessment,
        { data: courseAssignments },
      ] = await Promise.all([
        assessmentSeriesPromise,
        assessmentsPromise,
        openAttemptsPromise,
        selectSeriesPromise,
        setActiveAssessmentPromise,
        assignmentsPromise,
      ]);

      const openAttempt = openAttempts && openAttempts.length > 0 && openAttempts[0];

      // Breadcrumbs
      getSectionById({ courseId });

      const assignment = courseAssignments.find(
        (courseAssignment) => courseAssignment.id === assignmentId
      );

      const assessmentTimeLimit = assignment.time_limit * 60;

      // if attempt is expired, show the expired attempt modal
      if (
        openAttempt &&
        assessmentTimeLimit - (moment() - moment(openAttempt.start_time)) / 1000 <= 0
      ) {
        openModal({
          type: MODAL_ASSESSMENT_TIME_END,
          data: {
            shouldNotCloseOnEsc: true,
            attemptId,
            assignmentId,
            assessmentSeriesId: assessmentId,
          },
        });
      }

      if (openAttempt) {
        await resumeAttempt({
          type: ASSESSMENT_TYPES.EXAM,
          assignmentId,
        });

        history.push(
          `${stemifyRoutes.courses}/${courseId}/coursework/${assignmentId}/exam/${assessmentId}/${openAttempt.id}`
        );

        this.setState({ ...this.state });
        startCountdown();
      }
    } else {
      // Cohort / Deployment / Placement Assessment
      const assessmentSeriesPromise = getAssessmentSeries();
      const assessmentsPromise = getAssessments();

      const attemptQueryObject = attemptId
        ? { attemptId }
        : {
            assessmentSeriesId: assessmentId,
            deploymentConfigId,
          };
      const openAttemptsPromise = attemptId
        ? getAttempts({ attemptId })
        : getAttempts({
            ...attemptQueryObject,
            status: "open",
            pageSize: 1,
            page: 0,
          });

      const selectSeriesPromise = selectAssessmentSeries({
        assessmentId,
        deploymentId: deploymentConfigId,
      });

      const setActiveAssessmentPromise = setActiveAssessment({
        assessmentId,
        deploymentId: deploymentConfigId,
      });

      const [openAttempts] = await Promise.all([
        openAttemptsPromise,
        assessmentSeriesPromise,
        assessmentsPromise,
        selectSeriesPromise,
        setActiveAssessmentPromise,
      ]);

      const openAttempt = openAttempts && openAttempts.length > 0 && openAttempts[0];

      // Time limit for attempt in minutes
      const assessmentTimeLimit = openAttempt?.time_limit;
      const timeHasExpired =
        openAttempt &&
        assessmentTimeLimit - (moment() - moment(openAttempt.start_time)) / 1000 / 60 <= 0;

      // console.log({
      //   openAttempt,
      //   timeHasExpired,
      //   now: moment().format(),
      //   startTime: moment(openAttempt.start_time).format(),
      //   nowMinusStartTimeMillis: moment() - moment(openAttempt.start_time),
      //   nowMinusStartTimeSeconds: (moment() - moment(openAttempt.start_time)) / 1000,
      //   nowMinusStartTimeMinutes: (moment() - moment(openAttempt.start_time)) / 1000 / 60,
      // });

      // if attempt is expired, show the expired attempt modal
      if (timeHasExpired) {
        openModal({
          type: MODAL_ASSESSMENT_TIME_END,
          data: {
            shouldNotCloseOnEsc: true,
            attemptId,
            deploymentConfigId,
            assessmentSeriesId: assessmentId,
          },
        });
      }

      if (openAttempt) {
        await resumeAttempt({
          type: ASSESSMENT_TYPES.PLACEMENT,
          deploymentId: deploymentConfigId,
          assignmentId,
        });

        history.push(
          `${stemifyRoutes.assessments}/${deploymentConfigId}/${assessmentId}/${openAttempt.id}`
        );

        this.setState({ ...this.state });
        startCountdown();
      }
    }

    this.setState({ initialDataLoaded: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.progress !== prevProps.progress) {
      this.shouldShowAssessmentCompletionModal();
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshTokenInterval);
  }

  isCourseAssessment() {
    const {
      match: {
        params: { courseId, assignmentId },
      },
    } = this.props;
    return courseId && assignmentId;
  }

  handleUploadClick = () => {
    const { openModal, attemptId } = this.props;
    const {
      deploymentId: deploymentConfigId,
      assessmentId,
      courseId,
      assignmentId,
    } = this.props.match.params;

    openModal({
      type: MODAL_ASSESSMENT_SUBMIT,
      data: this.isCourseAssessment()
        ? { assessmentSeriesId: assessmentId, courseId, assignmentId, attemptId }
        : { deploymentConfigId, assessmentSeriesId: assessmentId, attemptId },
    });
  };

  handleNextQuestionButtonClick = () => {
    const { setCurrentQuestion, assessmentQuestions, currentQuestionIndex } = this.props;

    setCurrentQuestion({
      questionIndex: currentQuestionIndex + 1,
      questionId: assessmentQuestions[currentQuestionIndex + 1].id,
    });
  };

  handlePreviousQuestionButtonClick = () => {
    const { setCurrentQuestion, assessmentQuestions, currentQuestionIndex } = this.props;

    setCurrentQuestion({
      questionIndex: currentQuestionIndex - 1,
      questionId: assessmentQuestions[currentQuestionIndex - 1].id,
    });
  };

  handleInputChange = (e) => {
    const {
      setCompletedQuestion,
      currentQuestionId,
      assessmentQuestions,
      updateQuestionResponse,
      attemptId,
      match,
    } = this.props;
    setCompletedQuestion({
      questionId: currentQuestionId,
      selectedAnswerId: e.target.value,
    });

    const { deploymentId: deploymentConfigId, assessmentId, assignmentId } = match.params;
    const answerId = e.target.value;

    updateQuestionResponse({
      type: assignmentId ? "exam" : "assessment",
      deploymentId: deploymentConfigId,
      assessmentId,
      assignmentId,
      questionId: currentQuestionId,
      answerId,
      attemptId,
    });
  };

  shouldShowAssessmentCompletionModal = () => {
    const { openModal, progress } = this.props;
    const {
      deploymentId: deploymentConfigId,
      assessmentId,
      attemptId,
      courseId,
      assignmentId,
    } = this.props.match.params;

    if (progress === 100) {
      openModal({
        type: MODAL_SUBMIT_ASSESSMENT_GUIDE,
        data: this.isCourseAssessment()
          ? { assessmentSeriesId: assessmentId, courseId, assignmentId, attemptId }
          : { deploymentConfigId, assessmentSeriesId: assessmentId, attemptId },
      });
    }
  };

  handlePinClick = () => {
    const {
      setPinnedQuestion,
      pinnedQuestions,
      updateQuestionResponse,
      attemptId,
      currentQuestionId,
      match,
    } = this.props;
    const { deploymentId, assessmentId, assignmentId } = match.params;

    setPinnedQuestion(currentQuestionId);
    updateQuestionResponse({
      type: assignmentId ? "exam" : "assessment",
      deploymentId,
      assessmentId,
      assignmentId,
      questionId: currentQuestionId,
      attemptId,
      isPinned: pinnedQuestions.indexOf(currentQuestionId) >= 0 ? false : true,
    });
  };

  renderQuestions = () => {
    const {
      pinnedQuestions,
      assessmentQuestions,
      currentQuestionId,
      currentQuestionIndex,
      isAnimationForward,
      match,
    } = this.props;

    const animationIn = {
      from: { opacity: 0, top: "150px", left: "0px" },
      enter: { opacity: 1, top: "0px", left: "0px" },
      leave: { opacity: 0, top: "0px", left: "500px" },
    };

    const animationOut = {
      from: { opacity: 0, top: "0px", left: "500px" },
      enter: { opacity: 1, top: "0px", left: "0px" },
      leave: { opacity: 0, top: "150px", left: "0px" },
    };

    const animation = isAnimationForward ? animationIn : animationOut;

    return (
      <Transition unique reset native items={currentQuestionIndex} {...animation}>
        {(currentQuestionIndex) => (style) => {
          return (
            <animated.div style={{ position: "relative", ...style }}>
              {assessmentQuestions[currentQuestionIndex] && (
                <AssessmentQuestion
                  courseAssessment={match.params.courseId && match.params.assignmentId}
                  question={assessmentQuestions[currentQuestionIndex]}
                  index={currentQuestionIndex}
                  onNextQuestionButtonClick={this.handleNextQuestionButtonClick}
                  onPreviousQuestionButtonClick={this.handlePreviousQuestionButtonClick}
                  onInputChange={this.handleInputChange}
                  onPinClick={this.handlePinClick}
                  isPinned={pinnedQuestions.indexOf(currentQuestionId) >= 0}
                />
              )}
            </animated.div>
          );
        }}
      </Transition>
    );
  };

  onKeyPressHandleUPload = (e) => {
    if (e.which == 13 || e.which == 32) {
      this.handleUploadClick();
    }
  };

  setScrollableNodeRef = (element) => {
    this.scrollableNode = element;

    if (this.scrollableNode) {
      this.setState({
        barTopPosition: this.scrollableNode.getBoundingClientRect().top,
      });
    }
  };

  render() {
    const {
      isCountdownRunning,
      assessmentQuestions,
      assessmentTimeLimit,
      isSidebarOpen,
      match,
    } = this.props;
    const { deploymentId, assessmentId, courseId, attemptId } = match.params;
    const { initialDataLoaded } = this.state;

    return (
      <DocumentTitle title="Stemify | Placement">
        {initialDataLoaded ? (
          <Wrapper>
            <SectionOuter>
              <SectionContainer className="no-horizontal-scroll">
                <SectionContent innerRef={this.setScrollableNodeRef}>
                  <SectionHead
                    paddingRight={isCountdownRunning}
                    top={this.state.barTopPosition}
                    hasSidebar={isCountdownRunning}
                    sidebarOpen={isCountdownRunning && isSidebarOpen}
                  >
                    <ProgressBar
                      title="Assessment Progress"
                      isFromCourse={!!this.isCourseAssessment()}
                    />
                    {assessmentTimeLimit ? (
                      <Countdown
                        title="Time Remaining"
                        counter={assessmentTimeLimit}
                        deploymentId={deploymentId}
                        assessmentId={assessmentId}
                        isCountdownRunning={isCountdownRunning}
                        courseId={courseId}
                      />
                    ) : null}
                    <AssessmentUpload
                      className={isCountdownRunning ? "active" : ""}
                      focus={isCountdownRunning ? "active" : ""}
                      onKeyPress={(e) => this.onKeyPressHandleUPload(e)}
                      onClick={this.handleUploadClick}
                    >
                      Finish Assessment
                      <IconSvgComponent iconPath="svg/ico-upload.svg" />
                    </AssessmentUpload>
                  </SectionHead>

                  <SectionBody>
                    {assessmentQuestions.length && (attemptId || isCountdownRunning) ? (
                      this.renderQuestions()
                    ) : (
                      <AssessmentWelcomeMessage />
                    )}
                  </SectionBody>
                </SectionContent>
                {isCountdownRunning && (
                  <AssessmentSidebar courseAssessment={this.isCourseAssessment()} />
                )}
              </SectionContainer>
            </SectionOuter>
          </Wrapper>
        ) : (
          <NoDataMessageContainer noIcon>
            <Loader />
          </NoDataMessageContainer>
        )}
      </DocumentTitle>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const {
      match: {
        params: { assignmentId, courseId },
      },
    } = ownProps;
    const isCourseAssessment = assignmentId && courseId;
    const assignment = state.storedCourses.assignments[assignmentId];
    const assessmentTimeLimit = isCourseAssessment
      ? state.storedCourses.assignments[assignmentId]?.timeLimit * 60
      : state.assessment?.attempt?.timeLimit * 60;
    // This is where we need to update

    const { assessmentQuestions, currentQuestionId, attempt } = state.assessment;
    const currentQuestionIndex = currentQuestionId
      ? // if currentQuestionId is defined, use it
        assessmentQuestions.findIndex(
          (assessmentQuestion) => assessmentQuestion.id === currentQuestionId
        )
      : // if currentQuestionId is not defined, check if there's an attempt with responses and we have assessmentQuestions
      attempt?.attemptQuestionResponses?.length && assessmentQuestions
      ? // if we have responses, find the position of the last question for which there is a response within assessmentQuestions,
        // add one, and you have the currentQuestionIndex
        attempt?.attemptQuestionResponses.reduce((highestQuestionIndex, attemptResponse) => {
          const responseQuestionIndex = assessmentQuestions.findIndex(
            (assessmentQuestion) => assessmentQuestion.id === attemptResponse.questionId
          );
          return Math.max(highestQuestionIndex, responseQuestionIndex);
        })
      : // otherwise, use 0 (index of next question is first question)
        0;

    return {
      assessmentQuestions,
      assessmentName: state.assessment.assessmentName,
      isSidebarOpen: state.assessment.isSidebarOpen,
      isCountdownRunning: state.assessment.isCountdownRunning,
      currentQuestionId: assessmentQuestions[currentQuestionIndex]?.id,
      currentQuestionIndex,
      pinnedQuestions: state.assessment.pinnedQuestions,
      isAnimationForward: state.assessment.isAnimationForward,
      assessmentTimeLimit,
      progress: state.assessment.progress,
      assignment,
      attemptId: state.assessment.attempt.id,
    };
  },
  {
    setCompletedQuestion,
    setCurrentQuestion,
    setPinnedQuestion,
    openModal,
    setActiveAssessment,
    selectAssessmentSeries,
    getAssessmentSeries,
    getAssessmentDetailsById,
    getAssignmentsBySectionId,
    getAttempts,
    getAssessments,
    getSectionById,
    resumeAttempt,
    startCountdown,
    clearAttempt,
    updateQuestionResponse,
  }
)(AssessmentTaker);
