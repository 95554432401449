import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect, Router } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { isEmpty } from "ramda";

import { saveUserActivityAction } from "store/state/user/actions";
import { resetVideoQuiz, getQuizData } from "store/state/video/actions";
import {
  getLearningModule,
  getLearningUnit,
  getLearningModulesByFilter,
} from "store/state/learningLibrary/actions";
import stemifyRoutes from "constants/routes";
import { getIsNavigationFromTopicFilter } from "utilities/localStore";
import IconSvgComponent from "components/common/IconSvgComponent";
import Video from "components/video/JWPlayer";
import Loader from "components/common/loader";
import { JW_ASSET_BASEURL } from "constants/api";
import "styles/JWPlayer.css";
import Can from "../common/Can";
import queryString from "query-string";

import Card, {
  CardEntry,
  CardHolder,
  CardColumns,
  CardColumn,
  CardContent,
  CardSubtitle,
  CardListIcons,
  CardListIconsItem,
  CardRibbon,
  CardMessage,
  CardContentList,
  CardListDescription,
} from "styles/components/Card";
import ButtonLink from "components/common/ButtonLink";
import { NoDataMessageContainer, NoDataMessage } from "styles/components/Card";
import { FormattedText } from "../common/FormattedText";
import ModalLearningVideoTransition from "components/modal/ModalLearningVideoTransition";
import assessment from "store/state/assessment/reducer";
import { saveOpenStaxClick } from "services/courses";

class LearningModule extends React.Component {
  state = {
    videoPlaybackComplete: false,
    hasPracticeAssignment: false,
    isLastLearningModule: false,
  };

  async componentDidMount() {
    this.initVideoData();
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.moduleId !== prevProps.match.params.moduleId) {
      this.initVideoData();
    }
  };
  initVideoData = async () => {
    this.setState({ isLastLearningModule: false });

    const {
      getLearningModule,
      getLearningUnit,
      location,
      getQuizData,
      resetVideoQuiz,
      match,
      learningModuleList,
      getLearningModulesByFilter,
    } = this.props;
    const { moduleId, unitId } = match.params;

    const { isFilterByTopic, type, id } = getIsNavigationFromTopicFilter();
    if (isFilterByTopic) {
      // Fetch learning modules by topic
      await getLearningModulesByFilter({ type, id });
    } else {
      // Fetch learning modules by unitId
      await getLearningUnit({ unitId });
    }

    const moduleResponse = await getLearningModule({ moduleId });
    if (moduleResponse.data?.learning_modules) {
      const learningModule = moduleResponse.data.learning_modules[0];
      const videoId = learningModule.videos[0].id;

      // get seekDuration from route state
      const seekDuration = location.state && location.state.seekDuration;

      // if 0 then reset the video quiz data
      if (seekDuration === 0) await resetVideoQuiz({ videoId });

      // fetch fresh quiz data
      await getQuizData({ videoId });
    }
  };

  saveUserActivity = ({ type, id, seekDuration, quizId, cuepoint, action, isComplete }) => {
    const { saveUserActivityAction } = this.props;
    saveUserActivityAction({ type, id, seekDuration, quizId, cuepoint, action, isComplete });
  };

  saveModuleUserActivity = ({ type, id, isComplete }) => {
    const { saveUserActivityAction } = this.props;
    saveUserActivityAction({
      type,
      id,
      isComplete,
    });
  };

  onCompleteVideoPlayback = () => {
    this.setState({ videoPlaybackComplete: true });
  };

  closeModal = () => {
    this.setState({
      videoPlaybackComplete: false,
    });
  };

  redirectToLearningLibrary = () => {
    this.setState(
      {
        videoPlaybackComplete: false,
      },
      () => {
        this.props.history.push({
          pathname: `${stemifyRoutes.learningLibrary}`,
        });
      }
    );
  };

  redirectToCourseWork = () => {
    const courseId = this.props.match.params.courseId;
    this.setState(
      {
        videoPlaybackComplete: false,
      },
      () => {
        this.props.history.push({
          pathname: `${stemifyRoutes.courses}/${courseId}/coursework`,
        });
      }
    );
  };

  loadNextModule = (id) => {
    this.setState(
      {
        videoPlaybackComplete: false,
      },
      () => {
        const { match } = this.props;
        const { unitId } = match.params;
        const pathname = this.props.location.pathname;
        let pathToRedirect = "";
        if (pathname.includes(stemifyRoutes.learningLibraryLearningModule)) {
          pathToRedirect = `${stemifyRoutes.learningLibraryLearningModule}/${id}`;
        } else {
          pathToRedirect = `${stemifyRoutes.learningLibrary}/${unitId}/${id}`;
        }
        this.props.history.push({
          pathname: pathToRedirect,
          state: { referrer: pathToRedirect },
        });
      }
    );
  };

  takePracticeAssignment = (id) => {
    this.setState(
      {
        videoPlaybackComplete: false,
      },
      () => {
        const { match } = this.props;
        const { url } = match;
        this.props.history.push({
          pathname: `${url}/${id}`,
          state: { referrer: `${url}` },
        });
      }
    );
  };

  getLabel = () => {
    return !!this.props.match.params.courseId ? "Return to Course" : "Continue";
  };

  render() {
    const { videoPlaybackComplete, isLastLearningModule } = this.state;
    const {
      location,
      learningModule,
      apiCallInProgress,
      userRole,
      match,
      learningModuleList,
      learningUnit,
      accountId,
    } = this.props;
    const { url } = match;
    const { moduleId } = match.params;
    const seekDuration = location.state?.seekDuration;
    const isCourseWork = !!this.props.match.params.courseId;

    let arrNextModulesList = [];
    let arrAssignments = [];
    const { assignments } = learningModule;

    if (!isEmpty(learningModule) && assignments) {
      arrAssignments = assignments && assignments.filter((e) => e.is_complete === false);
      if (arrAssignments.length > 0) {
        const assignment = {
          ...arrAssignments[0],
          text: learningModule.name,
          isAssignment: true,
        };
        arrNextModulesList.push(assignment);
      }

      const moduleIndex = learningModuleList.findIndex((e) => e.id === learningModule.id);
      if (moduleIndex !== -1) {
        if (arrAssignments.length === 0) {
          // no practice assignment available. So show next 2 learning modules(if available)
          if (moduleIndex < learningModuleList.length - 2) {
            arrNextModulesList = arrNextModulesList.concat([
              learningModuleList[moduleIndex + 1],
              learningModuleList[moduleIndex + 2],
            ]);
          } else if (moduleIndex < learningModuleList.length - 1) {
            let nextModule = learningModuleList[moduleIndex + 1];
            arrNextModulesList.push(nextModule);
          } else {
            // no more further learning modules available
            !isLastLearningModule && this.setState({ isLastLearningModule: true });
          }
        } else {
          if (moduleIndex < learningModuleList.length - 1) {
            // practice assignments is available, so add next module to be displayed
            let nextModule = learningModuleList[moduleIndex + 1];
            arrNextModulesList.push(nextModule);
          }
        }
      } else {
        !isLastLearningModule && this.setState({ isLastLearningModule: true });
      }
    }

    if (this.props.apiError.code == 168) {
      return (
        <Redirect
          to={{
            pathname: stemifyRoutes.learningLibrary,
          }}
        ></Redirect>
      );
    } else {
      return !apiCallInProgress && !isEmpty(learningModule) ? (
        <React.Fragment>
          <ModalLearningVideoTransition
            active={videoPlaybackComplete}
            subject={learningModule}
            onCloseModal={this.closeModal}
            firstStepTitle="Good job, you’ve finished the video lesson"
            secondStepTitle={
              !!arrAssignments.length
                ? "Complete the lesson by taking a practice assignment."
                : "Coming up..."
            }
            showRewatchButton={true}
            subsequentModelItems={[...arrNextModulesList]}
            loadNextModule={(id) => this.loadNextModule(id)}
            takePracticeAssignment={(id) => this.takePracticeAssignment(id)}
            hasPracticeAssignment={!!arrAssignments.length}
            isLastLearningModule={isLastLearningModule}
            onCompleteLearningMessage={
              isCourseWork
                ? `Good job, you've finished the video lesson`
                : `Good job! You have completed your ${learningUnit.name} lessons.`
            }
            redirectToLearningLibrary={this.redirectToLearningLibrary}
            redirectToCourseWork={this.redirectToCourseWork}
            continueLabel={this.getLabel}
            isCourseWork={isCourseWork}
          />
          <DocumentTitle title={`Stemify | ${learningModule.name}`}>
            <Card large>
              {learningModule.videos && learningModule.videos.length > 0 && (
                <CardRibbon>
                  <Video
                    image={`${JW_ASSET_BASEURL +
                      "/thumbs/" +
                      learningModule.videos[0].external_id +
                      "-1280.jpg"}`}
                    moduleId={moduleId}
                    markModuleComplete={
                      learningModule.assignments.length > 0
                        ? learningModule.assignments[0].is_complete
                        : true
                    }
                    videoId={learningModule.videos[0].id}
                    mediaId={learningModule.videos[0].external_id}
                    activityTracker={this.saveUserActivity}
                    moduleActivityTracker={this.saveModuleUserActivity}
                    relaodData={this.initVideoData}
                    trackVideoProgress={Can({
                      role: userRole,
                      perform: "learning-module-video:trackProgress",
                      yes: () => {
                        return true;
                      },
                      no: () => {
                        return false;
                      },
                    })}
                    seekDuration={
                      seekDuration == null
                        ? learningModule.videos[0].seek_duration
                        : location.state.seekDuration
                    }
                    autoPlay={
                      location.state && location.state.autoPlay ? location.state.autoPlay : 0
                    }
                    onComplete={this.onCompleteVideoPlayback}
                  />
                </CardRibbon>
              )}
              <CardHolder>
                <CardColumns>
                  <CardColumn size="50%">
                    <CardContent>
                      <CardSubtitle textBold>Learning Objectives</CardSubtitle>
                      <CardListDescription custombullets>
                        {learningModule.description &&
                          learningModule.description.map((objective) => (
                            <CardContentList larger key={objective}>
                              <IconSvgComponent iconPath="svg/ico-dblue-check.svg" />
                              <FormattedText text={objective} />
                            </CardContentList>
                          ))}
                      </CardListDescription>
                    </CardContent>
                  </CardColumn>
                  {!isCourseWork && (
                    <CardColumn size="50%">
                      <CardColumns>
                        <CardColumn size="50%">
                          <CardContent>
                            <CardSubtitle textBold>Practice Assignments</CardSubtitle>
                            {learningModule.assignments && learningModule.assignments.length > 0 ? (
                              <CardListIcons vertical larger>
                                {learningModule.assignments.map((assignment) => (
                                  <CardListIconsItem larger key={assignment.id}>
                                    <ButtonLink isCardRedirectLink to={`${url}/${assignment.id}`}>
                                      <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                      {assignment.name}
                                    </ButtonLink>
                                  </CardListIconsItem>
                                ))}
                              </CardListIcons>
                            ) : learningModule.worksheets &&
                              learningModule.worksheets.length > 0 ? (
                              <CardListIcons vertical larger>
                                {learningModule.worksheets.map((worksheet) => (
                                  <CardListIconsItem larger key={worksheet.id}>
                                    <ButtonLink
                                      isCardDownloadLink
                                      href={worksheet.file_location}
                                      target="_blank"
                                    >
                                      <IconSvgComponent iconPath="svg/ico-colored-sheet.svg" />
                                      {worksheet.title}
                                    </ButtonLink>
                                  </CardListIconsItem>
                                ))}
                              </CardListIcons>
                            ) : (
                              <CardMessage>Coming Soon</CardMessage>
                            )}
                          </CardContent>
                        </CardColumn>
                        <CardColumn size="50%">
                          <CardContent>
                            <CardSubtitle textBold>Additional Reading</CardSubtitle>

                            {learningModule.openstax_resource ? (
                              <div style={{ marginTop: "20px" }}>
                                <a
                                  href={learningModule.openstax_resource}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={() => {
                                    saveOpenStaxClick({ accountId, feature: "Learning Module" });
                                  }}
                                >
                                  <img
                                    src="https://openstax.org/rex/releases/v4/fbee54c/static/media/logo.6064f4d8.svg"
                                    alt="openstax"
                                    width="150px"
                                    height="50px"
                                  />
                                </a>
                              </div>
                            ) : (
                              <CardMessage>There is no additional reading.</CardMessage>
                            )}
                          </CardContent>
                        </CardColumn>
                      </CardColumns>
                    </CardColumn>
                  )}
                </CardColumns>
              </CardHolder>
            </Card>
          </DocumentTitle>
        </React.Fragment>
      ) : (
        <NoDataMessageContainer>
          {apiCallInProgress == true && <Loader />}
          {apiCallInProgress == false && (
            <NoDataMessage>
              No Learning Module content <br /> available at this time.
            </NoDataMessage>
          )}
        </NoDataMessageContainer>
      );
    }
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const learningModule = state.learningLibrary.learningModule;
      return {
        learningModule,
        apiCallInProgress: state.learningLibrary.apiCallInProgress.learningModule,
        userRole: state.user.role,
        accountId: state.user.accountId,
        learningModuleList: state.learningLibrary.learningUnit.learningModules.filter(
          (e) => e.isComplete === false || e.id === learningModule.id
        ),
        learningUnit: state.learningLibrary.learningUnit,
        apiError: state.learningLibrary.apiErrors.program.list,
      };
    },
    {
      saveUserActivityAction,
      getLearningModule,
      resetVideoQuiz,
      getQuizData,
      getLearningUnit,
      getLearningModulesByFilter,
    }
  )(LearningModule)
);
