import React from "react";
import { connect } from "react-redux";
import { BreadcrumbsContainer } from "styles/components/BreadcrumbsContainer";

export const StudentHome = () => (
  <BreadcrumbsContainer>
    <span>My Dashboard</span>
  </BreadcrumbsContainer>
);

export const StudentSelectedDeployment = connect((state, props) => {
  const deploymentName = state.studentDashboard.selectedAssessmentDetails.deploymentName;
  return { deploymentName };
})(({ deploymentName }) => (
  <BreadcrumbsContainer>
    <span>{deploymentName}</span>
  </BreadcrumbsContainer>
));

export const StudentSelectedAssessmentSeries = connect((state, props) => {
  const assessmentName = state.studentDashboard.selectedAssessmentDetails.assessmentName;
  return { assessmentName };
})(({ assessmentName }) => (
  <BreadcrumbsContainer>
    <span>{assessmentName}</span>
  </BreadcrumbsContainer>
));
