/**
 * The external dependencies.
 */
import React from "react";
import styled from "styled-components";

/**
 * @class      Tab (name)
 * @param      {Array} / The children
 * @param      {Number} / The index
 * @param      {Function} / The next step
 * @param      {Function} / The previous step
 * @param      {Boolean} / Indicates if active
 * @return     {JSX}
 */
const Tab = ({ children, index, nextStep, prevStep, toggleStepValidState }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      index,
      nextStep,
      prevStep,
      toggleStepValidState,
    });
  });

  return <TabContent>{childrenWithProps}</TabContent>;
};

const TabContent = styled.div`
  width: 500px;
  margin: auto;
`;

export default Tab;
