import styled from "styled-components";
import theme from "../theme";
import { media } from "../media";
import { Link } from "react-router-dom";

export const CourseAssignmentItemHead = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  min-height: 57px;
  padding: 0 40px 0 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s, border-radius 0.3s;

  .svg-icon path {
    transition: fill 0.3s;
  }

  > span {
    min-width: 115px;
  }

  ${(props) =>
    props.expanded &&
    `
    border-radius: 8px 8px 0 0;
	  background: ${theme.color.sky_blue};
	  color: ${theme.color.white};

    .svg-icon path,
    .svg-icon polygon { fill: ${theme.color.white}}
    `}

  ${media.mobile`
        padding: 5px 30px 5px 10px;
        flex-wrap: wrap;
        min-height: 0;
        font-size: 14px;
    `}
`;

export const CourseAssignmentItemBody = styled.div`
  padding: 5px 20px 25px;

  .btn-view {
    display: block;
    text-align: left;
    text-transform: uppercase;
    padding: 0;
    color: ${theme.color.blue}
    font-weight: 500;

    &:hover {
      color: ${theme.color.red}
    }
  }

  ${media.mobile`
        padding: 5px 10px 15px;
    `}
`;

export const CourseAssignmentItemMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

export const CourseAssignmentItemMetaContent = styled.div`
  font-size: 16px;

  span {
    color: #9b9b9b;
  }

  ${media.mobile`
      width: 100%;
      margin-bottom: 10px;
    `}
`;

export const CourseAttemptLinkButton = styled(Link)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 23px;
  padding: 3px 18px;
  background: ${theme.color.red};
  border-radius: 13px;
  font-size: 14px;
  font-weight: 800;
  text-decoration: none;
  width: fit-content;
  color: ${theme.color.white};
  cursor: pointer;
  transition: background 0.2s;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: ${theme.color.red_faded};
  }
`;
export const CourseAssignmentItemMetaStats = styled.div`
  display: flex;
  align-items: baseline;
  ${media.mobile`
      justify-content: center;
      width: 100%;
    `}
`;

export const CourseAssignmentItemMetaStat = styled.div`
  font-size: 16px;
  color: #9b9b9b;
  margin-left: 20px;

  strong {
    font-size: 50px;
    font-weight: 500;
    line-height: 1;
    display: block;
    color: ${theme.color.darker_gray};
  }

  ${media.mobile`
    margin: 0 10px;
    text-align: center;
    `}
`;

export const CourseAssignmentItemTitle = styled.h4`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0;

  .svg-icon {
    margin-right: 5px;
  }

  ${media.mobile`
        width: 100%;
    `}
`;

export const CourseAssignmentItemDateAnalyticIcon = styled.span`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px 5px 0px 5px;
  .svg-icon {
    margin-left: 15px;
  }
`;

export const CourseAssignmentItemTag = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 6px;
  border: 1px solid transparent;
  font-size: 11px;
  font-weight: 500;
  margin-left: 15px;

  ${(props) =>
    props.isDraft &&
    `
    border-color: ${theme.color.gray}
    color: ${theme.color.gray}
  `}

  ${(props) =>
    props.isScheduled &&
    `
    border-color: ${theme.color.orange};
    color: ${theme.color.orange}
  `}
`;

const CourseAssignmentItem = styled.div`
  width: 100%;
  background: ${theme.color.white};
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  margin: 10px 0;
  position: relative;

  &:last-child {
    margin-bottom: 20px;
  }

  .dropdown-wrapper {
    position: absolute;
    top: 22px;
    right: 15px;
    margin-right: 0;
    z-index: 1;
    opacity: 0;
    visibility: hidden;

    > button {
      height: auto;
      margin: 0 -9px 0 0;
      transform: rotate(90deg);

      span {
        background: ${theme.color.white};
      }
    }
  }

  &:hover {
    ${CourseAssignmentItemHead} {
      background: ${theme.color.sky_blue};
      color: ${theme.color.white};

      .svg-icon path,
      .svg-icon polygon {
        fill: ${theme.color.white};
      }

    }

    ${CourseAssignmentItemTag} {
        border-color: ${theme.color.white}
        color: ${theme.color.white}
    }

    .dropdown-wrapper {
      opacity: 1;
      visibility: visible;
    }
  }

  ${(props) =>
    props.expanded &&
    `
    .dropdown-wrapper {
      opacity: 1;
      visibility: visible;
    }
  `}

  ${(props) =>
    props.zIndex &&
    `
    z-index: ${props.zIndex}
  `}
`;

export const CourseAssignmentCard = styled.div`
  height: 55px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;

  .btn-remove {
    padding: 0;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }

  ${(props) =>
    props.addHover &&
    `
      &:hover {
        box-shadow: 0 1px 12px 6px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
      }
  `}

  ${media.mobile`
    padding-right: 35px;

    .btn-remove {
        right: 10px;
    }
 `}
`;

export const CourseAssignmentCardImage = styled.div`
  flex-shrink: 0;
  background-size: cover;
  width: 84px;
  align-self: stretch;
  background-image: url(${(props) => props.image});
  background-position: center;
  height: 100%;

  ${media.mobile`
    width: 50px;
 `}
`;

export const CourseAssignmentCardContent = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  .svg-icon {
    min-width: 17px;
    margin-right: 10px;

    svg {
      width: 17px;
      height: auto;
    }

    path {
      fill: ${theme.color.blue};
      fill-opacity: 1;
    }
  }
`;
export const CourseAssignmentCardAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 100%;
  border-left: 1px solid #e4e4e4;

  .checkbox {
    display: block;
    width: 100%;
    text-align: center;
    margin-right: 0px;
  }

  ${(props) =>
    props.disabled &&
    `
      opacity: .5;

      .checkbox label {
        cursor: default;
        opacity: .7;
      }
  `}
`;

export const CourseAssignmentCardTitle = styled.h5`
  margin: 0;
  line-height: 1.25;
  ${(props) =>
    props.isAttemptText &&
    `
    font-weight: 100;
  `}

  ${media.mobile`
    font-size: 13px;
 `}
`;

export const CourseAssignmentLink = styled(Link)``;

export const CourseAssignmentCardEntry = styled.p`
  margin: 0;
  font-size: 12px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CourseAssignmentCardText = styled.div`
  width: 100%;
  max-width: calc(100% - 30px);

  ${media.mobile`
    font-size: 12px;
 `}
`;

export const CourseAssignmentCardLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;

  &:hover {
    display: block;
    box-shadow: 0 1px 12px 6px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
  }

  ${(props) =>
    props.partial &&
    `
    &:hover {
      display: flex;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      box-shadow: none;
  }
  `}
`;

export const CourseAssignmentCardAnchor = styled.a`
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;

  &:hover {
    display: block;
    box-shadow: 0 1px 12px 6px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
  }

  ${(props) =>
    props.partial &&
    `
    &:hover {
      display: flex;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      box-shadow: none;
  }
  `}
`;

export default CourseAssignmentItem;
