import React, { Fragment, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sliderConfigs from "components/instructorDashboard/slider-configs";
import Section, { SlickSlider, Grid, Card } from "styles/components/studentDashboard";
import { CardInfo } from "styles/components/CardInfo";
import StatusPill from "components/elements/StatusPill";
import { getFacultyDashboard } from "store/state/facultyDashboard/actions";
import Loader from "components/common/loader";
import { NoDataMessageContainer } from "styles/components/Card";
import { NoDataMessage } from "styles/components/Card";
import stemifyRoutes from "constants/routes";
import CourseItem from "components/courses/CourseItem";
import Courses from "styles/components/Courses";
import { MODAL_CREATE_COURSE } from "lib/constants";
import { openModal, closeModal } from "store/state/ui/actions";

import { getSections, getAssignments, getSchedules } from "store/state/courses/actions";
import moment from "moment";

const PlacementCard = (props) => {
  return (
    <CardInfo>
      <CardInfo.Group>
        <CardInfo.Title>{props.assessmentName}</CardInfo.Title>
        <CardInfo.Text>Deployment: {props.deploymentName}</CardInfo.Text>
        <StatusPill type="deployment" status={props.deploymentStatus} isAbsolute={true} />
      </CardInfo.Group>

      <CardInfo.Group>
        <CardInfo.Inner>
          <CardInfo.Entry>
            <CardInfo.Text bold uppercase>
              # of Students Completed
            </CardInfo.Text>

            <CardInfo.LargeTitle inlineBlock>{props.percentComplete}%</CardInfo.LargeTitle>

            <CardInfo.Text bold large inlineBlock paddingX="12">
              {props.studentsCompleted} / {props.totalStudents}
            </CardInfo.Text>
          </CardInfo.Entry>

          <CardInfo.Entry>
            <CardInfo.Text bold uppercase>
              Median Score
            </CardInfo.Text>

            <CardInfo.LargeTitle>{props.medianScore}</CardInfo.LargeTitle>
          </CardInfo.Entry>
        </CardInfo.Inner>
      </CardInfo.Group>

      <CardInfo.Group>
        <CardInfo.Button onClick={props.navigateToDeploymentDetails}>View Details</CardInfo.Button>
      </CardInfo.Group>
    </CardInfo>
  );
};

const InstructorHome = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const placements = useSelector((state) => state.facultyDashboard.placements);
  const isLoading = useSelector((state) => state.facultyDashboard.apiCallInProgress);
  const error = useSelector((state) => state.facultyDashboard.apiErrors.placements.get.message);

  const sections = useSelector((state) => state.storedCourses.sections);
  const currentAccountId = useSelector((state) => state.user.currentAccountId);

  // Matt: these should probably be memoized because they do return a new ref each time
  const sectionsArray = Object.values(sections);
  const assignments = useSelector((state) => Object.values(state.storedCourses.assignments));
  const schedules = useSelector((state) => Object.values(state.storedCourses.schedules));

  const handleDeploymentNavigation = (deploymentId) => {
    return () => history.push(`${stemifyRoutes.deployments}/${deploymentId}`);
  };

  // ComponentDidMount
  useEffect(() => {
    dispatch(getFacultyDashboard());
    dispatch(getSections());
  }, [dispatch]);

  // re-fetch the sections and falculty details whenever there's account switch
  useEffect(() => {
    dispatch(getFacultyDashboard());
    dispatch(getSections());
  }, [currentAccountId]);

  // ComponentDidUpdate
  useEffect(() => {
    if (sectionsArray.length) {
      const sectionIds = sectionsArray.map((section) => section.id);
      dispatch(getAssignments({ sectionIds }));
      dispatch(getSchedules({ sectionIds }));
    }
  }, [dispatch, sections]);

  if (isLoading) {
    return (
      <NoDataMessageContainer noIcon>
        <Loader />
      </NoDataMessageContainer>
    );
  }

  const getEditState = (course) => {
    return {
      ...course,
    };
  };
  const handleCourseEdit = (course) => {
    dispatch(
      openModal({
        type: MODAL_CREATE_COURSE,
        data: {
          id: course.id,
          editItem: getEditState(course),
        },
      })
    );
  };

  return (
    <Fragment>
      <Section>
        <Section.Head>
          <Section.HeadContent>
            <Section.Title>My Courses</Section.Title>
          </Section.HeadContent>
        </Section.Head>

        <Section.Body id="assessmentCardList">
          <SlickSlider {...sliderConfigs}>
            {sectionsArray.map((section) => (
              <CourseItem
                isInstructorView={true}
                onEdit={(section) => handleCourseEdit(section)}
                data={{
                  ...section,
                  assignments: assignments.filter(
                    (assignment) => assignment.sectionId === section.id
                  ),
                  schedules: schedules.filter(
                    (schedule) =>
                      schedule.sectionId === section.id && moment(schedule.endTs).isAfter(moment())
                  ),
                }}
                key={section.id}
              />
            ))}
          </SlickSlider>
        </Section.Body>
      </Section>
      <Section>
        <Section.Head>
          <Section.HeadContent>
            <Section.Title>Placement & Diagnostics</Section.Title>
          </Section.HeadContent>
        </Section.Head>

        <Section.Body>
          {error ? (
            <NoDataMessage style={{ textAlign: "center" }}>{error}</NoDataMessage>
          ) : (
            <SlickSlider {...sliderConfigs}>
              {placements
                .filter((placement) => placement.deploymentStatus === "Live")
                .map((placement) => {
                  return (
                    <PlacementCard
                      key={placement.deploymentId + placement.assessmentName}
                      assessmentName={placement.assessmentName}
                      medianScore={placement.medianScore}
                      studentsCompleted={placement.studentsCompleted}
                      totalStudents={placement.totalStudents}
                      percentComplete={placement.percentComplete}
                      deploymentId={placement.deploymentId}
                      deploymentStatus={placement.deploymentStatus}
                      deploymentName={placement.deploymentName}
                      navigateToDeploymentDetails={handleDeploymentNavigation(
                        placement.deploymentId
                      )}
                    />
                  );
                })}
            </SlickSlider>
          )}
        </Section.Body>
      </Section>
    </Fragment>
  );
};

export default InstructorHome;
