/**
 * @ External Dependecies
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import clonedeep from "lodash.clonedeep";

/**
 * @ Internal Dependecies
 */
import IconSvgComponent from "components/common/IconSvgComponent";
import { createNewQuestion, setActiveIndex } from "store/state/questions/actions";
import Modal, {
  ModalHead,
  AddTagButton,
  AddTagButtonText,
  ModalClose,
  ModalEntry,
  ModalEntryParagraph,
  ModalEntryTitle,
} from "styles/components/Modal";
import TagsBar, {
  TagsItemBtnRemove,
  TagsItemText,
  TagsItem,
  TagsList,
  SearchBar,
  SearchCategories,
} from "styles/components/BuilderTagsBar";

import ButtonLink from "components/common/ButtonLink";
import CategoryBreadcrumbs, {
  Category,
  CategoryFolder,
  CatergoryItem,
} from "styles/components/CategoryBreadcrumbs";

import { updateActiveQuestion, updateBuilderAnswer } from "store/state/builderAssessment/actions";

const dataAutocomplete = [
  {
    id: "section1",
    subject: "Precalculus",
    items: [
      {
        id: "suggestion1-1",
        subject: "Precalculus",
        concept: "Exponential & logarithmic functions",
        topic: "Exponential & logarithmic functions",
        subtopic: "Exponential functions",
      },
      {
        id: "suggestion1-2",
        subject: "Precalculus",
        concept: "Solving exponential equations",
        topic: "Equations",
        subtopic: "Solving Equations",
      },
    ],
  },
  {
    id: "section2",
    subject: "Algebra 2",
    items: [
      {
        id: "suggestion2-1",
        subject: "Algebra 2",
        concept: "Radical functions and rational exponents",
        topic: "Radical functions and rational exponents",
        subtopic: "Radical functions",
      },
      {
        id: "suggestion2-2",
        subject: "Algebra 2",
        concept: "Exponentail & logarithmic functions",
        topic: "Exponentail & logarithmic functions",
        subtopic: "Functions",
      },
      {
        id: "suggestion2-3",
        subject: "Algebra 2",
        concept: "Algebra",
        topic: "Algebra",
        subtopic: "Algebra",
      },
    ],
  },
  {
    id: "section3",
    subject: "Algebra 1",
    items: [
      {
        id: "suggestion3-1",
        subject: "Algebra 1",
        concept: "Exponents and exponential functions",
        topic: "Exponents and exponential functions",
        subtopic: "Exponential functions",
      },
      {
        id: "suggestion3-2",
        subject: "Algebra 1",
        concept: " Exponential decay",
        topic: "Exponents and exponential functions",
        subtopic: "Exponential functions",
      },
      {
        id: "suggestion3-3",
        subject: "Algebra 1",
        concept: " Exponents",
        topic: "Exponents and exponential functions",
        subtopic: "Exponential functions",
      },
    ],
  },
];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return [];
  }

  return dataAutocomplete
    .map((section) => {
      return {
        subject: section.subject,
        items: section.items.filter(
          (item) => item.concept.toLowerCase().indexOf(inputValue) !== -1
        ),
      };
    })
    .filter((section) => section.items.length > 0);
};

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const suggestionText = `${suggestion.concept}`;
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  return (
    <div id={suggestion.id} className="autosuggest-item">
      {parts.map((part, index) => {
        const className = part.highlight ? "highlight" : null;

        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        );
      })}
    </div>
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.concept;

function renderSectionTitle(section) {
  return <strong>{section.subject}</strong>;
}

function getSectionSuggestions(section) {
  return section.items;
}

/**
 * Class for application.
 *
 * @class      App (name)
 */

//let tagsInitial = []

class ModalTagsBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      value: "",
      suggestions: [],
      isSearching: false,
      tags: [],
      tagsInitial: [],
      hoveredSuggestionId: "",
      hasError: false,
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
      isSearching: true,
    });
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      isSearching: false,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    //Here you do whatever you want with the values
    //console.log(suggestion);
    //console.log(event)
    this.createNewTag(suggestion.concept);
  };

  // Remove tag
  removeTag = (index) => () => {
    const currentTags = this.state.tags;
    currentTags.splice(index, 1);

    this.setState({
      tags: currentTags,
    });
  };

  // Create tag
  createNewTag = (tagName) => {
    if (this.state.value === "") {
      return;
    }

    const updatedTags = this.state.tags;
    updatedTags.push(tagName);

    this.setState({
      tags: updatedTags,
    });

    this.setState({
      value: "",
    });
  };

  applyTags = () => {
    const { type } = this.props;

    if (type === "question") {
      this.props.updateActiveQuestion({
        tags: this.state.tags,
      });
    } else {
      this.props.updateBuilderAnswer({
        id: this.props.data.id,
        tags: this.state.tags,
      });
    }

    this.setState({
      hasError: false,
    });

    this.props.closeModal();
  };

  handleModalClose = () => {
    const savedTagsLength = this.props.data.tags.length;
    const currentTagsLength = this.state.tags.length;

    if (this.state.hasError) {
      this.setState(
        {
          hasError: false,
          tags: clonedeep(this.props.data.tags),
        },
        () => {
          this.props.closeModal();
        }
      );

      return;
    }

    if (savedTagsLength !== currentTagsLength) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        hasError: false,
      });

      this.props.closeModal();
    }
  };

  componentDidMount() {
    document.addEventListener("mouseover", this.handleAutosuggestItemsHover);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseover", this.handleAutosuggestItemsHover);
  }

  handleAutosuggestItemsHover = (e) => {
    const target = e.target || e.target.parentNode;

    if (target.classList.contains("autosuggest-item")) {
      this.setState({
        hoveredSuggestionId: target.id,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { data } = prevProps;

    if (data) {
      if (this.props.data.tags !== data.tags) {
        this.setState({
          tags: clonedeep(this.props.data.tags),
        });
      }
    }
  }

  renderTitleText = () => {
    const { type, data, index } = this.props;
    let text = "";

    if (type === "question") {
      text = data.name ? ` ${data.name}` : ` Question ${index + 1}`;
    } else {
      text = data.answerText ? ` ${data.answerText}` : ` Answer ${index + 1}`;
    }

    return text;
  };

  render() {
    const { showModal, type, index, data } = this.props;
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "Type tag name to search",
      value,
      onChange: this.onChange,
    };

    return (
      <div>
        <Modal
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          className="Modal modal-tags"
          overlayClassName="Overlay"
        >
          <div className="ReactModal__Inner">
            {this.state.hasError && (
              <React.Fragment>
                <ModalClose onClick={this.handleModalClose} className="close-modal-absolute">
                  <IconSvgComponent iconPath="svg/ico-close.svg" />
                </ModalClose>

                <ModalEntry>
                  <IconSvgComponent iconPath="svg/ico-price-tag-info.svg" />

                  <ModalEntryTitle>You forgot to apply all your tags!</ModalEntryTitle>

                  <ModalEntryParagraph>
                    You need to apply tags to your question and all answers before you can create
                    the next question.
                  </ModalEntryParagraph>

                  {this.state.tags.length > 0 && (
                    <ButtonLink violet onClick={this.applyTags} className="btn-add-tag">
                      <span>Apply Tags</span>
                    </ButtonLink>
                  )}
                </ModalEntry>
              </React.Fragment>
            )}

            {!this.state.hasError && (
              <React.Fragment>
                <ModalHead>
                  <AddTagButton>
                    <AddTagButtonText>
                      Apply Tag to
                      {data && this.renderTitleText()}
                    </AddTagButtonText>
                  </AddTagButton>

                  <ModalClose onClick={this.handleModalClose}>
                    <IconSvgComponent iconPath="svg/ico-close.svg" />
                  </ModalClose>
                </ModalHead>

                <TagsBar isVisible={this.state.tags.length}>
                  <TagsList>
                    {this.state.tags.map((tag, i) => (
                      <TagsItem key={tag + "" + i}>
                        <TagsItemText>{tag}</TagsItemText>

                        <TagsItemBtnRemove onClick={this.removeTag(i)}>
                          <IconSvgComponent iconPath="svg/ico-close.svg" />
                        </TagsItemBtnRemove>
                      </TagsItem>
                    ))}
                  </TagsList>
                </TagsBar>

                <SearchBar isVisible={true} isExpanded={this.state.isSearching}>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    alwaysRenderSuggestions={false}
                    focusInputOnSuggestionClick={false}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    onSuggestionSelected={this.onSuggestionSelected}
                    multiSection={true}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                  />
                </SearchBar>

                {!this.state.isSearching && (
                  <ModalEntry>
                    <IconSvgComponent iconPath="svg/ico-price-tag-add.svg" />

                    <ModalEntryTitle>Enter tag name in the text field</ModalEntryTitle>

                    <ModalEntryParagraph>
                      Reference site about Lorem Ipsum, giving information on its origins, as well
                      as a random Lipsum generator.
                    </ModalEntryParagraph>

                    {this.state.tags.length > 0 && (
                      <ButtonLink violet hasIcon onClick={this.applyTags} className="btn-add-tag">
                        <span>Apply Tags</span>

                        <IconSvgComponent iconPath="svg/ico-check-white.svg" />
                      </ButtonLink>
                    )}
                  </ModalEntry>
                )}

                {this.state.isSearching && (
                  <SearchCategories>
                    {suggestions.length > 0 &&
                      suggestions.map((suggestion) => {
                        return suggestion.items.map((item) => {
                          return item.id === this.state.hoveredSuggestionId ? (
                            <CategoryBreadcrumbs key={item.id} className={"category-breadcrumbs"}>
                              <Category>
                                <IconSvgComponent iconPath="svg/ico-connected-dots.svg" />

                                <CategoryFolder>Subject</CategoryFolder>

                                <CatergoryItem>{item.subject}</CatergoryItem>
                              </Category>

                              <Category>
                                <IconSvgComponent iconPath="svg/ico-connected-dots.svg" />

                                <CategoryFolder>Topic</CategoryFolder>

                                <CatergoryItem>{item.topic}</CatergoryItem>
                              </Category>

                              <Category>
                                <IconSvgComponent iconPath="svg/ico-connected-dots.svg" />

                                <CategoryFolder>Subtopic</CategoryFolder>

                                <CatergoryItem>{item.subtopic}</CatergoryItem>
                              </Category>

                              <Category>
                                <IconSvgComponent iconPath="svg/ico-connected-dots.svg" />

                                <CategoryFolder>Concept</CategoryFolder>

                                <CatergoryItem>{item.concept}</CatergoryItem>
                              </Category>
                            </CategoryBreadcrumbs>
                          ) : null;
                        });
                      })}
                  </SearchCategories>
                )}
              </React.Fragment>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    // questions: state.questions.items,
    // activeQuestionIndex: state.questions.activeQuestionIndex
  }),
  {
    updateActiveQuestion,
    updateBuilderAnswer,
  }
)(ModalTagsBuilder);
