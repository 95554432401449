/**
 * The external dependencies.
 */
import React, { Component } from "react";

import DocumentTitle from "react-document-title";
import SectionDashboard from "styles/components/SectionDashboard";
import styled, { css } from "styled-components";

import BarCharts from "../chart/Bar";
import AttemptsDonutChart from "components/chart/AttemptsDonutChart";
import LineGraph from "../chart/LineGraph";

import ScoreDistributionTooltip, { GroupBarChartToolTip } from "../chart/ToolTips";
import CircularChart from "components/chart/CircularChart";
import { progressWheelColorRange, CalculateProgressIndicatorColor } from "constants/common";
import { FrameCell, FrameBox, FrameTitle } from "styles/components/Frame";
let colormap = require("colormap");

const SectionDiv = styled.div`
    background-color: white;
    height: 300px;
    display: flex;
    flexDirection: row,
    justifyContent: 'flex-end',
    alignItems: 'center',
`;

const SectionGroupChart = styled.div`
    background-color: transparent;
    height: 100%;
    width: 50%;
    display: flex;
    flexDirection: row,
    justifyContent: 'center',
    alignItems: 'center',
`;

const SectionDivider = styled.div`
  background-color: transparent;
  height: 25px;
`;

/**
 * Class for FAQ details.
 *
 * @class      FAQ (name)
 */
class GraphDemo extends Component {
  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  componentDidMount() {}
  handleBarClickEvent = ({ data, shouldReset }) => {};
  colors = colormap({
    colormap: "plasma",
    nshades: 101,
    format: "hex",
    alpha: 1,
  });

  render() {
    const groupedChartDataSource = [
      { id: "419", calculusScore: 2, algebraScore: 3, attempt_date: "07/23/2020" },
      { id: "415", calculusScore: 1, algebraScore: 2, attempt_date: "07/22/2020" },
      { id: "415", calculusScore: 3, algebraScore: 1, attempt_date: "07/29/2020" },
      { id: "415", calculusScore: 2, algebraScore: 4, attempt_date: "07/25/2020" },
    ];

    const scoreDistributionChartDataSource = [
      { studentCount: 1, percentage: 0 },
      { studentCount: 0, percentage: 1 },
      { studentCount: 0, percentage: 2 },
      { studentCount: 1, percentage: 3 },
      { studentCount: 0, percentage: 4 },
      { studentCount: 2, percentage: 5 },
      { studentCount: 0, percentage: 6 },
      { studentCount: 3, percentage: 7 },
      { studentCount: 4, percentage: 8 },
      { studentCount: 0, percentage: 9 },
      { studentCount: 5, percentage: 10 },
      { studentCount: 4, percentage: 11 },
      { studentCount: 0, percentage: 12 },
      { studentCount: 15, percentage: 13 },
      { studentCount: 38, percentage: 14 },
      { studentCount: 20, percentage: 15 },
      { studentCount: 0, percentage: 16 },
      { studentCount: 15, percentage: 17 },
      { studentCount: 23, percentage: 18 },
      { studentCount: 0, percentage: 19 },
      { studentCount: 20, percentage: 20 },
      { studentCount: 10, percentage: 21 },
      { studentCount: 13, percentage: 22 },
      { studentCount: 22, percentage: 23 },
      { studentCount: 11, percentage: 24 },
      { studentCount: 17, percentage: 25 },
      { studentCount: 23, percentage: 26 },
      { studentCount: 38, percentage: 27 },
      { studentCount: 22, percentage: 28 },
      { studentCount: 5, percentage: 29 },
      { studentCount: 1, percentage: 30 },
      { studentCount: 0, percentage: 31 },
      { studentCount: 27, percentage: 32 },
      { studentCount: 0, percentage: 33 },
      { studentCount: 0, percentage: 34 },
      { studentCount: 20, percentage: 35 },
      { studentCount: 0, percentage: 36 },
      { studentCount: 23, percentage: 37 },
      { studentCount: 34, percentage: 38 },
      { studentCount: 28, percentage: 39 },
      { studentCount: 32, percentage: 40 },
      { studentCount: 3, percentage: 41 },
      { studentCount: 30, percentage: 42 },
      { studentCount: 45, percentage: 43 },
      { studentCount: 33, percentage: 44 },
      { studentCount: 65, percentage: 45 },
      { studentCount: 14, percentage: 46 },
      { studentCount: 7, percentage: 47 },
      { studentCount: 43, percentage: 48 },
      { studentCount: 67, percentage: 49 },
      { studentCount: 45, percentage: 50 },
      { studentCount: 20, percentage: 51 },
      { studentCount: 33, percentage: 52 },
      { studentCount: 21, percentage: 53 },
      { studentCount: 60, percentage: 54 },
      { studentCount: 45, percentage: 55 },
      { studentCount: 15, percentage: 56 },
      { studentCount: 78, percentage: 57 },
      { studentCount: 58, percentage: 58 },
      { studentCount: 54, percentage: 59 },
      { studentCount: 0, percentage: 60 },
      { studentCount: 34, percentage: 61 },
      { studentCount: 56, percentage: 62 },
      { studentCount: 45, percentage: 63 },
      { studentCount: 100, percentage: 64 },
      { studentCount: 33, percentage: 65 },
      { studentCount: 30, percentage: 66 },
      { studentCount: 44, percentage: 67 },
      { studentCount: 66, percentage: 68 },
      { studentCount: 56, percentage: 69 },
      { studentCount: 65, percentage: 70 },
      { studentCount: 10, percentage: 71 },
      { studentCount: 21, percentage: 72 },
      { studentCount: 47, percentage: 73 },
      { studentCount: 23, percentage: 74 },
      { studentCount: 80, percentage: 75 },
      { studentCount: 40, percentage: 76 },
      { studentCount: 50, percentage: 77 },
      { studentCount: 40, percentage: 78 },
      { studentCount: 34, percentage: 79 },
      { studentCount: 22, percentage: 80 },
      { studentCount: 7, percentage: 81 },
      { studentCount: 11, percentage: 82 },
      { studentCount: 32, percentage: 83 },
      { studentCount: 14, percentage: 84 },
      { studentCount: 10, percentage: 85 },
      { studentCount: 30, percentage: 86 },
      { studentCount: 10, percentage: 87 },
      { studentCount: 20, percentage: 88 },
      { studentCount: 4, percentage: 89 },
      { studentCount: 15, percentage: 90 },
      { studentCount: 15, percentage: 91 },
      { studentCount: 0, percentage: 92 },
      { studentCount: 28, percentage: 93 },
      { studentCount: 35, percentage: 94 },
      { studentCount: 15, percentage: 95 },
      { studentCount: 0, percentage: 96 },
      { studentCount: 0, percentage: 97 },
      { studentCount: 12, percentage: 98 },
      { studentCount: 0, percentage: 99 },
      { studentCount: 10, percentage: 100 },
    ];

    const progressWheelData = [
      {
        name: "Value 1",
        value: 42.57,
        fill: CalculateProgressIndicatorColor(42.57, progressWheelColorRange),
      },
    ];
    const lineChartData = [
      {
        name: "Page A",
        pv: 2400,
        amt: 2400,
      },
      {
        name: "Page B",
        pv: 1398,
        amt: 2210,
      },
      {
        name: "Page C",
        pv: 9800,
        amt: 2290,
      },
      {
        name: "Page D",
        pv: 3908,
        amt: 2000,
      },
      {
        name: "Page E",
        pv: 4800,
        amt: 2181,
      },
      {
        name: "Page F",
        pv: 3800,
        amt: 2500,
      },
      {
        name: "Page G",
        pv: 4300,
        amt: 2100,
      },
    ];

    return (
      <DocumentTitle title="Stemify | Graph">
        <SectionDashboard modified id={"faqContainer"}>
          <SectionDiv>
            <SectionGroupChart>
              <BarCharts
                config={{
                  options: {
                    dimension: {
                      width: "95%",
                      height: "95%",
                    },
                    margin: {
                      top: 50,
                      right: 0,
                      left: 20,
                      bottom: 5,
                    },
                  },
                }}
                xAxis={{
                  axisLine: true,
                  tickLine: false,
                }}
                yAxis={{
                  axisLine: true,
                  tickLine: false,
                }}
                CustomTooltip={<GroupBarChartToolTip />}
                grid={true}
                // barSize={6}
                // maxBarSize={90}
                barRadius={20}
                isGroupBarChart={true}
                groupBarColors={["#000080", "#fff40a"]}
                isHorizontalChart={false}
                dataSet={groupedChartDataSource}
                dataKeys={{
                  xAxisKey: "attempt_date",
                  yAxisKeys: ["algebraScore", "calculusScore"],
                }}
              />
            </SectionGroupChart>
            <SectionGroupChart>
              <BarCharts
                config={{
                  options: {
                    dimension: {
                      width: "95%",
                      height: "95%",
                    },
                    margin: {
                      top: 50,
                      right: 0,
                      left: 20,
                      bottom: 5,
                    },
                  },
                }}
                xAxis={{
                  axisLine: true,
                  tickLine: false,
                }}
                yAxis={{
                  axisLine: true,
                  tickLine: false,
                }}
                CustomTooltip={<GroupBarChartToolTip />}
                grid={true}
                // barSize={6}
                // maxBarSize={90}
                barRadius={20}
                isGroupBarChart={true}
                groupBarColors={["#000080", "#fff40a"]}
                isHorizontalChart={true}
                dataSet={groupedChartDataSource}
                dataKeys={{
                  xAxisKey: "attempt_date",
                  yAxisKeys: ["algebraScore", "calculusScore"],
                }}
              />
            </SectionGroupChart>
          </SectionDiv>
          <SectionDivider></SectionDivider>
          <SectionDiv>
            <BarCharts
              config={{
                options: {
                  dimension: {
                    width: "95%",
                    height: "95%",
                  },
                  margin: {
                    top: 50,
                    right: 0,
                    left: 20,
                    bottom: 5,
                  },
                },
              }}
              xAxis={{
                axisLine: true,
                tickLine: false,
                ticks: [0, 25, 50, 75, 100],
                labelText: "SCORE",
              }}
              yAxis={{
                axisLine: true,
                tickLine: false,
                ticks: [0, 25, 50, 75, 100],
                labelText: "NUMBER OF STUDENTS ",
              }}
              CustomTooltip={<ScoreDistributionTooltip plasmaColors={this.colors} />}
              grid={true}
              barSize={6}
              maxBarSize={90}
              barRadius={20}
              isGroupBarChart={false}
              isHorizontalChart={false}
              dataSet={scoreDistributionChartDataSource}
              dataKeys={{
                xAxisKey: "percentage",
                yAxisKeys: ["studentCount"],
              }}
              onBarClick={({ data, shouldReset }) =>
                this.handleBarClickEvent({ data, shouldReset })
              }
            />
          </SectionDiv>
          <SectionDivider></SectionDivider>
          <SectionDiv>
            <BarCharts
              config={{
                options: {
                  dimension: {
                    width: "50%",
                    height: "95%",
                  },
                  margin: {
                    top: 50,
                    right: 0,
                    left: 20,
                    bottom: 5,
                  },
                },
              }}
              grid={false}
              barSize={50}
              barRadius={0}
              dataSet={groupedChartDataSource}
              dataKeys={{
                xAxisKey: "attempt_date",
                yAxisKeys: ["algebraScore"],
              }}
            />
          </SectionDiv>
          <SectionDivider></SectionDivider>
          <SectionDiv>
            <AttemptsDonutChart />
          </SectionDiv>
          <SectionDivider></SectionDivider>
          <FrameBox>
            <FrameTitle>Progress Wheel :</FrameTitle>
            <FrameCell>
              <CircularChart
                data={progressWheelData}
                circleSize={300}
                displayText={42.57}
                fontSize={50}
                isPercentageValue={true}
                bottomText="14/33"
                bottomTextFontSize={20}
                bottomTextFontWeight={600}
              />
            </FrameCell>
          </FrameBox>

          <SectionDivider></SectionDivider>

          <SectionDiv>
            <LineGraph
              config={{
                options: {
                  dimension: {
                    width: "95%",
                    height: "95%",
                  },
                  margin: {
                    top: 50,
                    right: 0,
                    left: 20,
                    bottom: 5,
                  },
                },
              }}
              xAxis={{
                axisLine: true,
                tickLine: false,
              }}
              yAxis={{
                axisLine: true,
                tickLine: false,
              }}
              type="linear"
              strokeColor={"#8884d8"}
              dataSet={lineChartData}
              dataKeys={{
                xAxisKey: "name",
                yAxisKeys: "pv",
              }}
            />
          </SectionDiv>
        </SectionDashboard>
      </DocumentTitle>
    );
  }
}

export default GraphDemo;
