import { handleActions } from "redux-actions";
import {
  requestSignedURL,
  getSignedURL,
  rejectGetSignedURL,
  requestVideoURL,
  getVideoURL,
  rejectGetVideoURL,
  setQuizData,
  requestUpdateQuestionAttempt,
  updateQuestionAttempt,
  rejectUpdateQuestionAttempt,
  clearCuePoints,
  skipCuePoint,
} from "./actions";
import { requestLogin, successLogout } from "../user/actions";
const defaultState = {
  apiCallInProgress: false,
  cuePoints: [],
  quizId: null,
  currentCuePointId: null,
};

const video = handleActions(
  {
    [requestSignedURL]: (state) => ({
      ...state,
      apiCallInProgress: true,
    }),
    [getSignedURL]: (state) => ({
      ...state,
      apiCallInProgress: false,
    }),
    [rejectGetSignedURL]: (state) => ({
      ...state,
      apiCallInProgress: false,
    }),
    [requestVideoURL]: (state) => ({
      ...state,
      apiCallInProgress: true,
    }),
    [getVideoURL]: (state) => ({
      ...state,
      apiCallInProgress: false,
    }),
    [rejectGetVideoURL]: (state) => ({
      ...state,
      apiCallInProgress: false,
    }),
    [requestLogin]: () => ({
      ...defaultState,
    }),
    [setQuizData]: (state, { payload: { selector, items, quizId, doNotUpdateAPIProgress } }) => ({
      ...state,
      [selector]: items,
      quizId,
      apiCallInProgress:
        (doNotUpdateAPIProgress && doNotUpdateAPIProgress) === true
          ? state.apiCallInProgress
          : false,
    }),
    [requestUpdateQuestionAttempt]: (state) => {
      return {
        ...state,
        apiCallInProgress: true,
      };
    },
    [skipCuePoint]: (state, { payload }) => {
      return {
        ...state,
        cuePoints: state.cuePoints.map((c) => {
          if (c.id === payload) {
            return {
              ...c,
              isSkipped: true,
            };
          }
          return c;
        }),
      };
    },
    [updateQuestionAttempt]: (state, { payload }) => {
      const { id, isAnswered, attemptCount, updateAPIProgress } = payload;

      return {
        ...state,
        cuePoints: state.cuePoints.map((c) => {
          if (c.id === id) {
            return {
              ...c,
              isAnswered,
              attemptCount: attemptCount ? attemptCount : c.attemptCount ? c.attemptCount + 1 : 1,
            };
          }
          return c;
        }),
        currentCuePointId: id,
        apiCallInProgress: updateAPIProgress === true ? false : state.apiCallInProgress,
      };
    },
    [rejectUpdateQuestionAttempt]: (state) => {
      return {
        ...state,
        apiCallInProgress: false,
      };
    },
    [clearCuePoints]: (state, { payload }) => {
      return {
        ...state,
        cuePoints: [],
      };
    },
    [successLogout]: () => ({
      ...defaultState,
    }),
  },
  defaultState
);

export default video;
