/**
 * @ External Dependecies
 */

import styled from "styled-components";
import Select from "react-select";

export const SelectField = styled(Select)`
  width: 182px;
  height: 40px;
  background: #8013e0;
  color: #fff;

  .option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 9px;
  }
  .option span {
    font-size: 14px;
    font-weight: 700;
  }
  .option .svg-icon {
    margin-right: 7px;
  }
  .option .svg-icon > div {
    font-size: 0;
  }

  .react-select__control {
    background: none;
    border: none;
    min-height: 40px;
    border-radius: 1px;
  }
  .react-select__control .react-select__value-container {
    padding: 0;
  }
  .react-select__control .react-select__single-value {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .react-select__control .option span {
    color: #fff;
  }

  .react-select__control .option .svg-icon svg g {
    fill: #fff;
  }

  .react-select__indicator-separator {
    display: none;
  }
  .react-select__dropdown-indicator {
    position: relative;
    width: 36px;
  }
  .react-select__dropdown-indicator:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0 4.5px;
    border-color: #fff transparent transparent transparent;
    transform: translate(-50%, -50%);
  }
  .react-select__dropdown-indicator svg {
    display: none;
  }

  // Dropdown

  .react-select__menu {
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0 0.12);
  }
  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__option {
    padding: 0;
    height: 40px;
    background: #fff;
  }
  .react-select__option .option {
    height: 40px;
  }
  .react-select__option .option span {
    color: #9b9b9b;
  }
  .react-select__option .option .svg-icon svg g {
    fill: #9b9b9b;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background: #e3ebfa;
  }

  .react-select__option--is-focused .option span,
  .react-select__option--is-selected .option span {
    color: #4a4a4a;
  }

  .react-select__option--is-focused .option .svg-icon svg g,
  .react-select__option--is-selected .option .svg-icon svg g {
    fill: #4a4a4a;
  }
`;

export const SelectFieldDefault = styled(Select)`
  width: 182px;
  height: 40px;
  background: transparent;
  color: #fff;

  .option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 9px;
  }
  .option span {
    font-size: 14px;
    font-weight: 700;
  }
  .option .svg-icon {
    margin-right: 7px;
  }
  .option .svg-icon > div {
    font-size: 0;
  }

  .react-select__control {
    background: none;
    border: none;
    min-height: 40px;
    border-radius: 1px;
  }
  .react-select__control--is-focused {
    box-shadow: none;
  }
  .react-select__control .react-select__value-container {
    padding: 0;
  }
  .react-select__control .react-select__single-value {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .react-select__indicator-separator {
    display: none;
  }
  .react-select__dropdown-indicator {
    position: relative;
    width: 10px;
  }
  .react-select__dropdown-indicator:after {
    position: absolute;
    top: 50%;
    right: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0 4.5px;
    border-color: #b2bbc6 transparent transparent transparent;
    transform: translate(0, -50%);
  }
  .react-select__dropdown-indicator svg {
    display: none;
  }

  // Dropdown
  .react-select__menu {
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0 0.12);
  }
  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__option {
    padding: 0;
    height: 40px;
    line-height: 40px;
    background: #fff;
    color: #484848;
    padding-left: 3px;
  }
  .react-select__option .option {
    height: 40px;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background: #3a9ef7;
    color: #fff;
  }
`;

export default SelectField;
