import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import useAPIData from "components/useAPIData";
import TopicBreakdownCard from "./TopicBreakdownCard";
import { getVideoConceptsBreakdown as getVideoConceptsBreakdownAction } from "store/state/courses/actions";

const columns = [
  {
    id: "topicName",
    Header: "Concept Name",
    accessor: "topicName",
  },

  {
    id: "classPerformance",
    Header: "Class Performance",
    accessor: "classPerformance",
  },
];

const mobileColumns = (topic) => {
  return {
    topicName: {
      title: "Topic Name",
      value: topic.topicName,
    },
    classPerformance: {
      title: "Class Performance",
      value: topic.classPerformance,
    },
  };
};

export const VideoConceptBreakdownCard = ({ assignmentId }) => {
  const dispatch = useDispatch();
  const getVideoConceptsBreakdownData = useCallback(() => {
    return dispatch(getVideoConceptsBreakdownAction({ assignmentId }));
  }, [assignmentId]);
  return (
    <TopicBreakdownCard
      getDataCallback={getVideoConceptsBreakdownData}
      columns={columns}
      mobileColumns={mobileColumns}
    />
  );
};
