/**
 * @ External Dependecies
 */
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  border: none;
`;

export default Wrapper;
