/**
 * The external dependecies
 */
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  FormHead,
  FormLogo,
  FormActions,
  FormTitle,
  FormEntry,
} from "styles/components/Form";
import stemifyRoutes from "constants/routes";
import { closeModal } from "store/state/ui/actions";

/**
 * @class      ModalDashboardResumeAttempt (name)
 */
const ModalDashboardResumeAttempt = (props) => {
  const history = useHistory();
  const { closeModal, data } = props;
  const {
    assignmentId,
    assessmentSeriesId,
    deploymentConfigId,
    attemptId,
    courseId,
  } = data;
  const handleResumeClick = () => {
    const assessmentAttemptUrl = assignmentId && assessmentSeriesId
      ? `${stemifyRoutes.courses}/${courseId}/coursework/${assignmentId}/exam/${assessmentSeriesId}/${attemptId}`
      : `${stemifyRoutes.assessments}/${deploymentConfigId}/${assessmentSeriesId}/${attemptId}`;
    history.push(assessmentAttemptUrl);
    closeModal();
  };

  return (
    <ModalFrame hideCloseBtn>
        <FormHead>
          <FormLogo>
            <IconSvgComponent iconPath="svg/board-exclamation.svg" />
          </FormLogo>
          <FormTitle>
            You never finished your last attempt!
          </FormTitle>
          <FormEntry>
            Your last attempt was not completed.  Please click resume to complete the assessment and submit your attempt. 
          </FormEntry>
        </FormHead>
        <FormActions>
          <ButtonLink onClick={handleResumeClick} type="button" violet>
            Resume
          </ButtonLink>
        </FormActions>
    </ModalFrame>
  );
};

export default connect(state => ({
  data: state.ui.modal.data,
}), {
  closeModal
})(ModalDashboardResumeAttempt);
