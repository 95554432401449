import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";

import SelectAssessment from "../assessment/SelectAssessment";
import Wrapper from "styles/components/Wrapper";
import Shell from "styles/components/Shell";

class AssessmentSelect extends Component {
  render() {
    return (
      <DocumentTitle title="Stemify | Placement">
        <Wrapper>
          <Shell>
            <SelectAssessment />
          </Shell>
        </Wrapper>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => ({
    activeAssessment: state.assessment.activeAssessment,
  }),
  {}
)(AssessmentSelect);
