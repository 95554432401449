import React from "react";
import { connect } from "react-redux";

import ModalFrame from "./ModalFrame";
import { closeModal } from "store/state/ui/actions";
import AssessmentSubmissionGuide from "../assessment/AssessmentSubmissionGuide";
import StateManager from "react-select";

function ModalAssessmentSubmitGuide(props) {
  return (
    <ModalFrame isAssessmentSubmissionGuide>
      <AssessmentSubmissionGuide
        icon="svg/board-check.svg"
        title="You have answered all questions. Do you want to finish and submit your answers for grading?"
        description="Before submitting it is advised that you double check your answers."
        {...props.modal.data}
      />
    </ModalFrame>
  );
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    closeModal,
  }
)(ModalAssessmentSubmitGuide);
