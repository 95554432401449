import React, { Fragment } from "react";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import { TableTitle, TableEntry, TableActions } from "styles/components/TableDefault";
import Can from "components/common/Can";

const NoDataComponent = ({
  iconPath,
  type,
  onClickAction = null,
  userRole,
  roleRule,
  additionalClass,
  textColor,
  customMessage,
  noSubMessage,
  titleStyle = {},
}) => {
  return (
    <Fragment>
      <IconSvgComponent iconPath={iconPath} additionalClass={additionalClass} />
      <TableTitle style={titleStyle}>
        {customMessage ? customMessage : `There are no ${type.toLowerCase()}s yet.`}
      </TableTitle>

      {onClickAction && (
        <Fragment>
          {!noSubMessage && (
            <TableEntry textColor={textColor}>
              {`Looks like you need to get started. All your ${type.toLowerCase()}s will be listed here, but first you’ll need to ${
                type.toLowerCase() === "student" ? "add" : "create"
              } one.`}
            </TableEntry>
          )}
          <TableActions>
            {userRole && roleRule ? (
              <Can
                role={userRole}
                perform={roleRule}
                yes={() => (
                  <ButtonLink onClick={onClickAction} violet>
                    {type === "Student" ? `Add Student` : `Create ${type}`}
                  </ButtonLink>
                )}
              />
            ) : null}
          </TableActions>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NoDataComponent;
