import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { attemptQuestion } from "store/state/practiceAssignment/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import { IMAGE_S3_SERVER } from "constants/api";
import {
  AssessmentQuestionContainer,
  AssessmentQuestionItem,
  AssessmentQuestionNumber,
  AssessmentQuestionItemContainer,
  AssessmentQuestionImage,
  AssessmentQuestionText,
  AssessmentAnswers,
  AssessmentAnswerContainer,
  AssessmentAnswer,
  AssessmentQuestionActions,
  AssessmentQuestionActionsInner,
  AnswerLabel,
  AnswerInput,
  AnswerImage,
  AnswerText,
  Message,
  MessageIcon,
  MessageContent,
  MessageTitle,
  MessageText,
  OptionImage,
} from "styles/components/AssessmentQuestions";
import { FormattedText } from "components/common/FormattedText";
import { saveOpenStaxClick } from "services/courses";

class PracticeQuestion extends Component {
  state = {
    isAnswerCorrect: false,
    buttonState: "default",
    disableAnswers: false,
    selectedAnswer: null,
    attemptingQuestion: false,
  };

  hasSelectedAnswer = () => {
    const { questions, currentQuestionId } = this.props;
    return !!questions[currentQuestionId];
  };

  isChecked = (id) => {
    const { selectedAnswer } = this.state;
    return parseInt(selectedAnswer) === parseInt(id);
  };

  handleInputChange = (e) => {
    const { questions, currentQuestionIndex } = this.props;
    const correctAnswer = questions[currentQuestionIndex].answers.find(
      (item) => item.correct === true
    );
    const isAnswerCorrect = !!correctAnswer
      ? parseInt(correctAnswer.id) === parseInt(e.target.value)
      : false;

    this.setState({
      isAnswerCorrect,
      buttonState: "answered",
      selectedAnswer: e.target.value,
    });
  };

  handleCheckButtonClick = async () => {
    const { isAnswerCorrect, selectedAnswer } = this.state;
    const { attemptQuestion } = this.props;
    const buttonState = isAnswerCorrect ? "correct" : "incorrect";

    this.setState({ attemptingQuestion: true, disableAnswers: true });

    await attemptQuestion({
      answerId: selectedAnswer,
      fromCourse: this.props.match.params.courseId ? true : false,
    });

    this.setState({
      buttonState,
      disableAnswers: isAnswerCorrect,
      attemptingQuestion: false,
    });
  };

  handleTryAgainButtonClick = () => {
    this.setState({
      buttonState: "default",
      disableAnswers: false,
      selectedAnswer: null,
    });
  };

  componentDidMount() {
    const { questions, currentQuestionIndex } = this.props;
    const currentQuestion = questions[currentQuestionIndex];
    const correctAnswer = currentQuestion.answers.find(
      (item) => currentQuestion.isCorrect && item.correct === true
    );
    this.setState({
      selectedAnswer: correctAnswer ? correctAnswer.id : null,
    });
  }

  renderButton = (buttonState, index) => {
    const { attemptingQuestion } = this.state;
    const { onNextQuestionButtonClick } = this.props;
    const isLastQuestion = index === this.props.questions.length - 1;

    switch (buttonState) {
      case "correct":
        return (
          <ButtonLink
            className="btn-next"
            danger
            hasIcon
            isHidden={isLastQuestion}
            onClick={onNextQuestionButtonClick}
          >
            <Fragment>
              <span>Next Question</span>
              <IconSvgComponent iconPath="svg/ico-pointer-right.svg" />
            </Fragment>
          </ButtonLink>
        );

        break;
      case "incorrect":
        return (
          <ButtonLink className="btn-next" danger hasIcon onClick={this.handleTryAgainButtonClick}>
            <span>Try Again</span>

            <IconSvgComponent iconPath="svg/ico-pointer-left.svg" />
          </ButtonLink>
        );

        break;
      case "answered":
        return (
          <ButtonLink
            className="btn-next btn-click"
            danger
            hasIcon
            onClick={this.handleCheckButtonClick}
            disabled={attemptingQuestion}
          >
            <span>Check Answer</span>

            <IconSvgComponent iconPath="svg/ico-pointer-right.svg" />
          </ButtonLink>
        );

        break;
      default:
        return (
          <ButtonLink
            className="btn-next"
            danger
            hasIcon
            isHidden={isLastQuestion}
            onClick={onNextQuestionButtonClick}
          >
            <span>Skip Question</span>

            <IconSvgComponent iconPath="svg/ico-pointer-right.svg" />
          </ButtonLink>
        );
    }
  };

  renderButtonLink = (buttonState, index) => {
    const { onNextQuestionButtonClick, onPreviousQuestionButtonClick } = this.props;

    if (buttonState === "default" && index !== 0) {
      return (
        <ButtonLink className="btn-prev" isUnstyledButton onClick={onPreviousQuestionButtonClick}>
          <span>Previous Question</span>
        </ButtonLink>
      );
    }

    if (buttonState === "answered" && index !== 0) {
      return (
        <ButtonLink className="btn-prev" isUnstyledButton onClick={onPreviousQuestionButtonClick}>
          <span>Previous Question</span>
        </ButtonLink>
      );
    }

    if (buttonState === "incorrect") {
      const isLastQuestion = index === this.props.questions.length - 1;

      return (
        <ButtonLink
          className="btn-prev"
          isUnstyledButton
          onClick={onNextQuestionButtonClick}
          isHidden={isLastQuestion}
        >
          <span style={{ color: "#4a4a4a" }}>Skip Question</span>
        </ButtonLink>
      );
    }

    return null;
  };

  renderActions = (buttonState, index) => {
    const { attempts } = this.props.question;

    if (buttonState === "correct" && attempts === 1) {
      return (
        <AssessmentQuestionActions perfect>
          <Message>
            <MessageIcon>
              <IconSvgComponent iconPath="svg/ico-perfect-star.svg" />
            </MessageIcon>

            <MessageContent>
              <MessageTitle>Perfect!</MessageTitle>
              {/* <MessageText>22% of students got this right on their first attempt</MessageText> */}
            </MessageContent>
          </Message>

          <AssessmentQuestionActionsInner>
            {this.renderButtonLink(buttonState, index)}
            {this.renderButton(buttonState, index)}
          </AssessmentQuestionActionsInner>
        </AssessmentQuestionActions>
      );
    }

    if (buttonState === "correct") {
      return (
        <AssessmentQuestionActions correct>
          <Message>
            <MessageContent>
              <MessageTitle>Correct!</MessageTitle>
              {/* <MessageText>52% of students got this right</MessageText> */}
            </MessageContent>
          </Message>

          <AssessmentQuestionActionsInner>
            {this.renderButtonLink(buttonState, index)}

            {this.renderButton(buttonState, index)}
          </AssessmentQuestionActionsInner>
        </AssessmentQuestionActions>
      );
    }

    if (buttonState === "incorrect") {
      return (
        <AssessmentQuestionActions incorrect>
          <Message>
            <MessageContent>
              <MessageTitle>Incorrect</MessageTitle>
              {/* <MessageText>52% of students got this wrong</MessageText> */}
            </MessageContent>
          </Message>

          <AssessmentQuestionActionsInner>
            {this.renderButtonLink(buttonState, index)}
            {this.renderButton(buttonState, index)}
          </AssessmentQuestionActionsInner>
        </AssessmentQuestionActions>
      );
    }

    return (
      <AssessmentQuestionActions>
        <AssessmentQuestionActionsInner>
          {this.renderButtonLink(buttonState, index)}

          {this.renderButton(buttonState, index)}
        </AssessmentQuestionActionsInner>
      </AssessmentQuestionActions>
    );
  };

  render() {
    const { question, currentQuestionIndex, openstax_resource, accountId } = this.props;
    const { content, answers } = question;
    const { buttonState, disableAnswers } = this.state;

    return (
      <AssessmentQuestionContainer>
        {openstax_resource && (
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "30px",
            }}
          >
            <a
              href={openstax_resource}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                saveOpenStaxClick({ accountId, feature: "Practice Problems" });
              }}
            >
              <IconSvgComponent iconPath="svg/ico-openstax-badge-lg.svg" />
            </a>
          </div>
        )}
        <AssessmentQuestionItem>
          <AssessmentQuestionItemContainer>
            <AssessmentQuestionImage fullwidth>
              {content.map((item, contentIndex) => {
                if (item.type === "TEXT") {
                  return (
                    <AssessmentQuestionText
                      key={item.text}
                      layout={question.layout}
                      widthCounter={content.length}
                      floatCounter={contentIndex}
                    >
                      {contentIndex === 0 && (
                        <AssessmentQuestionNumber>
                          {currentQuestionIndex + 1}.
                        </AssessmentQuestionNumber>
                      )}
                      <FormattedText text={item.text}></FormattedText>
                    </AssessmentQuestionText>
                  );
                } else {
                  return (
                    <OptionImage widthCounter={content.length} layout={question.layout}>
                      <img key={item.src} src={`${IMAGE_S3_SERVER}${item.src}`} alt={item.src} />
                    </OptionImage>
                  );
                }
              })}
            </AssessmentQuestionImage>
          </AssessmentQuestionItemContainer>
        </AssessmentQuestionItem>

        <AssessmentAnswers id={"AssessmentAnswersListContainer"} type="assignment">
          {answers.map((answer) => {
            const { id, displayType, layout } = answer;

            return (
              <AssessmentAnswerContainer
                key={id}
                type="assignment"
                displayType={displayType}
                selected={this.isChecked(id)}
              >
                <AssessmentAnswer
                  displayType={displayType}
                  correct={buttonState === "correct"}
                  incorrect={buttonState === "incorrect"}
                >
                  <AnswerInput
                    type="radio"
                    name={`answer-${id}`}
                    value={id}
                    id={id}
                    onChange={(e) => this.handleInputChange(e)}
                    checked={this.isChecked(id)}
                    disabled={disableAnswers}
                  />

                  <AnswerLabel displayType={displayType} htmlFor={id} layout={layout}>
                    <AnswerImage>
                      {answer.data &&
                        answer.data.map((data, k) => {
                          if (data.type === "TEXT") {
                            return (
                              <AnswerText
                                key={data.text}
                                layout={answer.layout}
                                widthCounter={answer.data.length}
                                floatCounter={k}
                              >
                                <FormattedText text={data.text}></FormattedText>
                              </AnswerText>
                            );
                          } else {
                            return (
                              <OptionImage widthCounter={answer.data.length} layout={answer.layout}>
                                <img
                                  key={data.src}
                                  src={`${IMAGE_S3_SERVER}${data.src}`}
                                  alt={data.src}
                                />
                              </OptionImage>
                            );
                          }
                        })}
                    </AnswerImage>
                  </AnswerLabel>
                </AssessmentAnswer>
              </AssessmentAnswerContainer>
            );
          })}
        </AssessmentAnswers>

        {this.renderActions(buttonState, currentQuestionIndex)}
      </AssessmentQuestionContainer>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      questions: state.practiceAssignment.assignmentQuestions,
      currentQuestionId: state.practiceAssignment.currentQuestionId,
      currentQuestionIndex: state.practiceAssignment.currentQuestionIndex,
      accountId: state.user.accountId,
    }),
    { attemptQuestion }
  )(PracticeQuestion)
);
