/**
 * @ External Dependecies
 */
import styled from "styled-components";

/**
 * @ Internal Dependecies
 */
import theme from "../theme";
import colors from "constants/colors";

const ProfileWidget = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ProfileWidgetHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 50px;

  ${(props) => props.borderleft && `border-left: 1px solid rgba(151, 151, 151, 0.15);`};
`;

export const ProfileWidgetAvatar = styled.button`
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #d8d8d8;
  border-radius: 50%;
  overflow: hidden;
  border: none;
  color: ${colors.cherry};
  font-weight: 900;
`;

export const ProfileWidgetNotification = styled.button`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  background: none;
  border: none;

  span {
    position: absolute;
    right: -3px;
    top: -5px;
  }
`;

export const ProfileWidgetCount = styled.span`
  display: block;
  width: 14px;
  height: 14px;
  line-height: 10px;
  border-radius: 50%;
  border: 2px solid ${theme.color.white};
  background: ${theme.color.red};
  font-size: 0;
  line-height: 0;
  text-align: center;
  color: ${theme.color.white};
`;

export const StyledUserIcon = styled.span`
  height: 36px;
  width: 36px;
  background-color: #16b5f2;
  border-radius: 50%;
  display: inline-block;
  padding-top: 7px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  min-width: 36px;

  ${(props) =>
    props.larger &&
    `
    width: 55px;
    height: 55px;
    min-width: 55px;
    padding-top: 10px;
    font-size: 26px;
    margin-top: -5px;
  `}
`;

export const UserEmail = styled.div`
  font-size: 12px;
`;

export const UserDetails = styled.div`
  font-size: 14px;
  margin-top: 4px;
  .svg-icon {
    display: inline;
    padding-top: 2px;
    position: fixed;
    margin-left: 8px;
  }
  .svg-icon div {
    display: inline;
  }

  .svg-icon svg {
    pointer-events: none;
  }
`;

//
export default ProfileWidget;
