/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import theme from "styles/theme";

export const StatTitle = styled.h1`
  margin-bottom: 0;
  font-size: 32px;
  line-height: 1.28;
  color: #fff;

  ${(props) =>
    props.inline &&
    `
		display: inline;
	`}
`;

export const StatEntry = styled.p`
  text-transform: uppercase;

  ${(props) =>
    props.inline &&
    `
		display: inline;
	`}
`;

const Stat = styled.div`
  font-size: 17px;
  text-align: center;
  color: ${theme.color.white};

  ${(props) =>
    props.modified &&
    css`
      font-size: 20px;
      line-height: 1.1;
      color: #484848;

      ${StatTitle} {
        margin-bottom: 7px;
        font-size: 77px;
        line-height: 0.8;
        color: #484848;

        span {
          font-size: 40px;
        }
      }

      ${StatEntry} {
        max-width: 272px;
        margin: 0 auto;
        text-transform: none;
      }
    `}
`;

export default Stat;
