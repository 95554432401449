import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import DocumentTitle from "react-document-title";
import moment from "moment";

import ButtonLink from "components/common/ButtonLink";
import SectionTable, { SectionBody } from "styles/components/SectionTable";
import Form, {
  FormActions,
  FormBody,
  FormCol,
  FormControls,
  FormRowGrid,
  CheckboxLabel,
  CheckboxWrapper,
  Checkbox,
} from "styles/components/Form";
import Field from "components/common/Field";
import { getBanner, editBanner } from "store/state/administration/actions";

const AdminPage = () => {
  const dispatch = useDispatch();
  const [bannerData, setBannerData] = useState({});

  const getBannerCallback = useCallback(async () => {
    const bannerResponse = await dispatch(getBanner());
    setBannerData(bannerResponse);
  });

  useEffect(() => {
    getBannerCallback();
  }, []);

  const handleBannerSubmit = (e) => {
    e.preventDefault();
    dispatch(editBanner(bannerData));
  };

  const handleBannerActiveCheckboxClick = () => {
    setBannerData({
      ...bannerData,
      isActive: !bannerData.isActive,
    });
  };

  return (
    <DocumentTitle title="Stemify | Administration">
      <SectionTable>
        <SectionBody
          style={{
            padding: "20px",
          }}
        >
          <h3>System Maintenance Window Banner</h3>
          <Form onSubmit={handleBannerSubmit}>
            <FormBody>
              <FormRowGrid>
                <FormCol half>
                  <FormControls>
                    <Field
                      icon="ico-calendar"
                      isDateTimePicker
                      showTimeSelect={true}
                      required
                      id="displayStartTime"
                      name="displayStartTime"
                      type="text"
                      onChange={(dateTime) => {
                        setBannerData({ ...bannerData, displayStartTime: dateTime });
                      }}
                      value={bannerData.displayStartTime ? moment(bannerData.displayStartTime) : ""}
                      placeholder="Display Start Time"
                    />
                    <Field
                      icon="ico-calendar"
                      isDateTimePicker
                      showTimeSelect={true}
                      required
                      id="displayEndTime"
                      name="displayEndTime"
                      type="text"
                      onChange={(dateTime) => {
                        setBannerData({ ...bannerData, displayEndTime: dateTime });
                      }}
                      value={bannerData.displayEndTime ? moment(bannerData.displayEndTime) : ""}
                      placeholder="Display End Time"
                    />
                  </FormControls>
                </FormCol>
                <FormCol half>
                  <FormControls>
                    <Field
                      icon="ico-calendar"
                      isDateTimePicker
                      showTimeSelect={true}
                      required
                      id="maintenanceStartTime"
                      name="maintenanceStartTime"
                      type="text"
                      onChange={(dateTime) => {
                        setBannerData({ ...bannerData, mxStartTime: dateTime });
                      }}
                      value={bannerData.mxStartTime ? moment(bannerData.mxStartTime) : ""}
                      placeholder="Maintenance Start Time"
                    />
                    <Field
                      icon="ico-calendar"
                      isDateTimePicker
                      showTimeSelect={true}
                      required
                      id="maintenanceEndTime"
                      name="maintenanceEndTime"
                      type="text"
                      onChange={(dateTime) => {
                        setBannerData({ ...bannerData, mxEndTime: dateTime });
                      }}
                      value={bannerData.mxEndTime ? moment(bannerData.mxEndTime) : ""}
                      placeholder="Maintenance End Time"
                    />
                  </FormControls>
                </FormCol>
              </FormRowGrid>
              <FormRowGrid>
                <FormCol>
                  <CheckboxWrapper className="checkbox">
                    <Checkbox
                      checked={bannerData.isActive || false}
                      onChange={handleBannerActiveCheckboxClick}
                      bannerActiveCheckbox
                      id={"bannerIsActive"}
                    />
                    <CheckboxLabel htmlFor={"bannerIsActive"} bannerActiveLabel>
                      Active?
                    </CheckboxLabel>
                  </CheckboxWrapper>
                </FormCol>
              </FormRowGrid>
            </FormBody>
            <FormActions>
              <ButtonLink type="submit" violet>
                <span>Update</span>
              </ButtonLink>
            </FormActions>
          </Form>
        </SectionBody>
      </SectionTable>
    </DocumentTitle>
  );
};

export default AdminPage;
