/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";

const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${(props) =>
    props.isBlured &&
    `
		filter: blur(4px);
	`}

  ${media.mobile`
		${(props) =>
      props.isBodyLocked &&
      `
			height: 100vh;
			overflow: hidden;
		`}
	`}
`;

export default PageWrap;
