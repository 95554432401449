/**
 * @ External Dependecies
 */
import { injectGlobal } from "styled-components";

/**
 * @ Internal Dependecies
 */
import theme from "./theme";
import { media, mediaMin } from "./media";

const url = window.location.host;

injectGlobal`
	@font-face {
	    font-family:'Circular Pro';
	    src: url('//${url}///${url}/fonts/CircularPro-Book.eot');
		src: url('//${url}/fonts/CircularPro-Book.eot?#iefix') format('embedded-opentype'),
			 url('//${url}/fonts/CircularPro-Book.woff2') format('woff2'),
			 url('//${url}/fonts/CircularPro-Book.woff') format('woff'),
			 url('//${url}/fonts/CircularPro-Book.ttf') format('truetype'),
			 url('//${url}/fonts/CircularPro-Book.svg#CircularPro-Book') format('svg');
	    font-weight: 400;
	    font-style: normal;
	    font-stretch: normal;
	    unicode-range: U+0020-00FE;
	}

	@font-face {
	    font-family:'Circular Pro';
	    src: url('//${url}/fonts/CircularPro-Medium.eot');
		src: url('//${url}/fonts/CircularPro-Medium.eot?#iefix') format('embedded-opentype'),
			 url('//${url}/fonts/CircularPro-Medium.woff2') format('woff2'),
			 url('//${url}/fonts/CircularPro-Medium.woff') format('woff'),
			 url('//${url}/fonts/CircularPro-Medium.ttf') format('truetype'),
			 url('//${url}/fonts/CircularPro-Medium.svg#CircularPro-Medium') format('svg');
	    font-weight: 500;
	    font-style: normal;
	    font-stretch: normal;
	    unicode-range: U+0020-00FE;
	}

	@font-face {
	    font-family:'Circular Pro';
	    src: url('//${url}/fonts/CircularPro-Bold.eot');
		src: url('//${url}/fonts/CircularPro-Bold.eot?#iefix') format('embedded-opentype'),
			 url('//${url}/fonts/CircularPro-Bold.woff2') format('woff2'),
			 url('//${url}/fonts/CircularPro-Bold.woff') format('woff'),
			 url('//${url}/fonts/CircularPro-Bold.ttf') format('truetype'),
			 url('//${url}/fonts/CircularPro-Bold.svg#CircularPro-Bold') format('svg');
	    font-weight: 700;
	    font-style: normal;
	    font-stretch: normal;
	    unicode-range: U+0020-00FE;
	}

	@font-face {
	    font-family:'Circular Pro';
	    src: url('//${url}/fonts/CircularPro-Black.eot');
		src: url('//${url}/fonts/CircularPro-Black.eot?#iefix') format('embedded-opentype'),
			 url('//${url}/fonts/CircularPro-Black.woff2') format('woff2'),
			 url('//${url}/fonts/CircularPro-Black.woff') format('woff'),
			 url('//${url}/fonts/CircularPro-Black.ttf') format('truetype'),
			 url('//${url}/fonts/CircularPro-Black.svg#CircularPro-Black') format('svg');
	    font-weight: 800;
	    font-style: normal;
	    font-stretch: normal;
	    unicode-range: U+0020-00FE;
	}

	body {
		min-width: 320px;
		background: ${theme.color.sky_blue_smoke};
		font-family: ${theme.font};
		font-size: ${theme.font_size_base};
		line-height: ${theme.line_height_base};
		color: ${theme.color.dark_gray};
		min-height: 100vh;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}

	a:hover,
	a[href^="tel"] {
		text-decoration: none;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: calc(${theme.line_height_base}em / 2);
	}

	h1 {
		font-size: ${theme.font_size.h1};
	}

	h2 {
		font-size: ${theme.font_size.h2};
	}

	h3 {
		font-size: ${theme.font_size.h3};
	}

	h4 {
		font-size: ${theme.font_size.h4};
	}

	h5 {
		font-size: ${theme.font_size.h5};
	}

	h6 {
		font-size: ${theme.font_size.h6};
	}

	p,
	ul,
	ol,
	dl,
	hr,
	table,
	blockquote {
		margin-bottom: ${theme.line_height_base}em;
	}

	h1[class]:last-child,
	h2[class]:last-child,
	h3[class]:last-child,
	h4[class]:last-child,
	h5[class]:last-child,
	h6[class]:last-child,
	h1:last-child,
	h2:last-child,
	h3:last-child,
	h4:last-child,
	h5:last-child,
	h6:last-child,
	p:last-child,
	ul:last-child,
	ol:last-child,
	dl:last-child,
	table:last-child,
	blockquote:last-child {
		margin-bottom: 0;
	}

	input:-webkit-autofill {
		-webkit-box-shadow: inset 0 0 0px 9999px white !important;
	}

	.ReactModal__Overlay--after-open {
		z-index: 99;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.ReactModal__Overlay {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		display: flex;
		width: 100%;
		height: 100%;
		padding: 40px 0;
		background: rgba(74,74,74,0.70);
		overflow: auto
	}

	.ReactModal__Content {
		position: relative;
		z-index: 10;
		width: 100%;
		max-width: 618px;
		min-width: 320px;
		margin: auto;
	}

	.ReactModal__Size-lg {
		max-width: 796px;
	}

	.ReactModal__Size-md {
		max-width: 754px;
	}

    .ReactModal__Size-mdx {
        max-width: 880px !important;
    }

    .ReactModal__Size-xl {
        max-width: 1070px !important;
    }

	.ReactModal__Size-sm {
		max-width: 520px;
	}

	.ReactModal__Size-xs {
		min-width: 300px !important;
		width: 240px !important;
	}

	.ReactModal__Body--open {
		overflow: hidden;
	}

	.ReactModal__Body--open #root {
		filter: blur(4px);
	}

	.text-highlight {
		background: none;
		font-weight: normal;
	}

	${media.tablet_portrait`
		.ReactModal__Overlay {
			padding: 0;
		}
	`}

	.slick-slider {
		.slick-list {
			margin: 0 -15px;
		}

		.slick-slide {
			padding: 0 15px;
		}

		.slick-arrow {
			display: flex !important;
			justify-content: center;
			align-items: center;
			width: 52px;
			height: 52px;
			border-radius: 50%;
			background: #FFFFFF;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.23);
			z-index: 5;

			.svg-icon {
				display: inline-block;
			}

			&:before {
				display: none;
			}
		}

		.slick-prev {
			.svg-icon {
				transform: rotate(180deg);
			}
		}

		.slick-disabled {
			opacity: 0;
			pointer-events: none;
		}
	}

	.my-collapsible__content {
		overflow: hidden;
	}

	.my-collapsible__inner {
		padding-bottom: 10px;
	}

	.svg-icon {
		font-size: 0;
	}

	.svg-icon.center {
		text-align: center;
	}

  .svg-icon.learning-unit-learning-objectives-icon svg {
    width: 16px;
    height: 16px;
  }

  .svg-icon.learning-objectives-icon-small svg {
    width: 15px;
    height: 15px;
  }

  .svg-icon.course-video-assignment-details-icon svg.course-video-assignment-details-icon {
    width: 21px;
  }

	.katex .base {
		white-space: normal !important;
		width: auto !important;
		word-break:break-word;
	}

	.mord.sqrt .mord {
		vertical-align: baseline;
	}

	.svg-icon svg {
		pointer-events: none;
	}

	.foreground-icon-on {
		position: absolute;
		left: 159px;
		margin-top: 2px;
		width: 12px;
		height: 12px;
	}

	.foreground-icon-off {
		position: absolute;
		left: 149px;
		margin-top: 2px;
		width: 12px;
		height: 12px;
	}

	.details-assessment-icon {
		width: 70px;
		margin: auto;
	}

	.dashboard-assessment-icon {
		width : 60px;
		margin-top: -5px;
		margin-left: -1px;
	}

  .dashboard-video-icon {
    width: 85px;
  }

  .faculty-assessment-details-student-list {
		display: flex;
        flex-direction: row;
        justify-content: center;
	}
	.smaller-font {
		font-size: 12px;
  }

  span.mord {
    width: max-content;
  }

  ${media.mobile`
    .sidebar-content {
        position: absolute;
    }
  `}

    ${mediaMin.mobile`
    .no-horizontal-scroll {
        overflow-x: hidden;
    }
  `}
  .space-above {
    margin-top: 40px;
  }

  .welcome-message ul, .welcome-message ol {
      margin-left: 20px;
  }

  #faqContainer a{
    color: ${theme.color.royal_blue};
    text-decoration: none;
  }

  #faqContainer ol{
    margin-left: 12px;
  }

  .rotate-right {
    transform: rotate(90deg);
  }

  .rotate-left {
    transform: rotate(-90deg);
  }

  .react-table-no-min-width {
    min-width: 0;
  }

  .maintenanceBannerIcon {
    width: 30px;
    height: 30px;
  }

  .svg-icon div {
    text-align: center;
  }
`;
