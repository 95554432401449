export const userRoles = [
  { value: "Account Admin", label: "Account Admin" },
  { value: "Instructor", label: "Instructor" },
  { value: "Student", label: "Student" },
];

export const studentYears = [
  { value: "", label: "" },
  { value: "freshman", label: "Freshman" },
  { value: "sophomore", label: "Sophomore" },
  { value: "junior", label: "Junior" },
  { value: "senior", label: "Senior" },
  { value: "other", label: "Other" },
];

export const semesters = [
  { value: "fall", label: "Fall" },
  { value: "spring", label: "Spring" },
  { value: "summer", label: "Summer" },
];
