import React from "react";

const ScoreDistributionTooltip = ({ active, payload, plasmaColors }) => {
  if (active && payload) {
    const {
      payload: { studentCount, score },
    } = payload[0];
    return (
      <div style={{ ...baseStyle, ...scoreDistributionChartContainer }}>
        <div style={{ ...subContainer, ...boldTextStyle }}>{`Score: ${score}`}</div>
        <div style={subContainer}>
          <div style={labelContainer}>
            <span
              style={{
                ...assessmentIndicator,
                backgroundColor: plasmaColors[score],
              }}
            ></span>
            <span>{`Students`}</span>
          </div>
          <div>
            <span>{studentCount}</span>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const AssessmentAttemptsTooltip = ({ active, payload, colors }) => {
  if (active && payload) {
    const {
      payload: { attempts, studentCount },
    } = payload[0];

    const colorIndex = attempts === "4+" ? colors.length - 1 : attempts;

    return (
      <div style={{ ...baseStyle, ...scoreDistributionChartContainer }}>
        <div style={{ ...subContainer, ...boldTextStyle }}>{`Students: ${studentCount}`}</div>
        <div style={subContainer}>
          <div style={labelContainer}>
            <span
              style={{
                ...assessmentIndicator,
                backgroundColor: colors[colorIndex],
              }}
            ></span>
            <span>{`Attempts`}</span>
          </div>
          <div>
            <span>{attempts}</span>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const GroupBarChartToolTip = ({ active, payload, label }) => {
  if (active) {
    const {
      fill: algebraAssessmentColorCode,
      payload: { algebraScore: algebraAssessmentScore },
    } = payload[0];
    const {
      fill: calculusAssessmentColorCode,
      payload: { calculusScore: calculusAssessmentScore, attempt_date: attemptDate },
    } = payload[1];
    return (
      <div style={{ ...baseStyle, ...groupChartContainer }}>
        <p style={{ ...subContainer, ...boldTextStyle }}>{attemptDate}</p>
        <div style={subContainer}>
          <div style={labelContainer}>
            <span
              style={{
                ...assessmentIndicator,
                backgroundColor: algebraAssessmentColorCode,
              }}
            ></span>
            <span>{`Prealgebra`}</span>
          </div>
          <div>
            <span>{algebraAssessmentScore}</span>
          </div>
        </div>

        <div style={subContainer}>
          <div style={labelContainer}>
            <span
              style={{
                ...assessmentIndicator,
                backgroundColor: calculusAssessmentColorCode,
              }}
            ></span>
            <span>{`Calculus`}</span>
          </div>
          <div>
            <span>{calculusAssessmentScore}</span>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "2px 2px 5px grey",
};

const scoreDistributionChartContainer = {
  width: "172px",
  height: "58px",
};

const groupChartContainer = {
  width: "150px",
  height: "100px",
};

const subContainer = {
  width: "80%",
  height: "20px",
  backgroundColor: "white",
  marginRight: "10px",
  marginLeft: "10px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
};

const labelContainer = {
  height: "20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
};

const assessmentIndicator = {
  width: "10px",
  height: "10px",
  marginRight: "10px",
  borderRadius: 5,
};

const boldTextStyle = {
  fontWeight: "bold",
  marginTop: "5px",
};
export default ScoreDistributionTooltip;
