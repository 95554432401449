/**
 * @ External Dependencies
 */
import { handleActions } from "redux-actions";

/**
 * @ Actions
 */
import {
  createNewQuestion,
  updateQuestionOrder,
  updateAnswers,
  setActiveIndex,
  setPageBlur,
  setPageLock,
  closeModal,
} from "./actions";

import { requestLogin, successLogout } from "../user/actions";

/**
 * @ Reducer
 */

const defaultState = {
  items: [],
  activeQuestionIndex: 0,
  isModalOpen: false,
  isLocked: false,
};

export const emptyQuestionTemplate = {
  title: "",
  desc: "",
  tags: [],
  answers: [
    {
      answerText: "Yes",
      value: "yes",
      correct: false,
      tags: ["lorem", "ipsum", "Lorem", "ipsum", "dolor", "sit", "ipsum", "dolor", "sit", "amet"],
    },
    {
      answerText: "No",
      value: "no",
      correct: false,
      tags: ["dolor", "sit", "amet"],
    },
  ],
};

/**
 * Actions Handler
 *
 * @type       {Function}
 */
const questions = handleActions(
  {
    [createNewQuestion]: (state, { payload }) => ({
      ...state,
      items: payload,
    }),
    [updateQuestionOrder]: (state, { payload }) => ({
      ...state,
      items: payload,
    }),
    [updateAnswers]: (state, { payload }) => ({
      ...state,
      items: payload,
    }),
    [setActiveIndex]: (state, { payload }) => ({
      ...state,
      activeQuestionIndex: payload,
    }),
    [setPageBlur]: (state, { payload }) => ({
      ...state,
      isModalOpen: payload,
    }),
    [setPageLock]: (state, { payload }) => ({
      ...state,
      isLocked: payload,
    }),
    [closeModal]: (state) => ({
      ...state,
      apiErrors: defaultState.apiErrors,
    }),
    [requestLogin]: () => ({
      ...defaultState,
    }),
    [successLogout]: () => ({
      ...defaultState,
    }),
  },
  defaultState
);

export default questions;
