import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { SectionHead, SectionBody } from "styles/components/AssessmentTaker";

const ImprovementRow = styled.div`
  display: flex;
  padding-left: 39px;
  padding-right: 27px;
  padding-bottom: 19px;

  ${(props) =>
    props.body &&
    `
      padding-top: 18px;
      border-top: 1px solid #e3ebfa;
      cursor: pointer;

      :last-child {
        padding-bottom: 27px;
      }

      :hover {
        background-color: #e3ebfa;
      }
  `}

  ${(props) =>
    props.body &&
    props.isSelected &&
    `
      background-color: #e3ebfa;
  `}

  ${(props) =>
    props.head &&
    `
    padding-top: 0px;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.4px;
  `}
`;

const TopicCell = styled.div`
  width: 75%;
`;

const QuestionsCell = styled.div`
  paddingleft: 38px;
`;

const findIndices = (questions, questionIds) => {
  let indices = [];
  for (var i = 0; i < questionIds?.length; i++) {
    let index = questions.findIndex((q) => q.id == questionIds[i]);
    if (index != -1) {
      indices.push(index);
    }
  }
  return indices;
};

const ImprovementTopics = ({ questions, topics, selectedTopic, handleTopicSelection }) => {
  const mockedQuestionsInTopics = topics?.map((topic) => {
    return {
      ...topic,
      questionIndexes: findIndices(questions, topic.questionIds),
    };
  });

  return (
    <Fragment>
      <SectionHead
        style={{
          fontSize: "1.5em",
          padding: "25px",
          marginBottom: "0px",
          fontWeight: "bold",
        }}
      >
        Top 5 Topics for Improvement
      </SectionHead>
      <SectionBody style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <ImprovementRow head>
            <TopicCell>TOPIC</TopicCell>
            <QuestionsCell>QUESTION(S)</QuestionsCell>
          </ImprovementRow>

          {mockedQuestionsInTopics?.map((topic) => {
            return (
              <ImprovementRow
                key={topic.id}
                body
                isSelected={selectedTopic && topic.id === selectedTopic.id}
                onClick={() => handleTopicSelection(topic)}
              >
                <TopicCell>{topic.title}</TopicCell>
                <QuestionsCell>
                  {topic.questionIndexes.map((id) => id + 1).join(", ")}
                </QuestionsCell>
              </ImprovementRow>
            );
          })}
        </div>
      </SectionBody>
    </Fragment>
  );
};

export default connect(
  (state) => ({
    questions: state.practiceAssignment.assignmentQuestions,
    topics: state.practiceAssignment.improvementTags,
  }),
  {}
)(ImprovementTopics);
