/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { prop } from "ramda";
import moment from "moment";

/**
 * The internal dependencies.
 */
import { create } from "store/state/administration/actions";
import { clearDeployment, updateDeployment } from "store/state/deployment/actions";
import { openModal, closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import { MODAL_WIZARD, WIZARD_DEPLOYMENT, WIZARD_CREATE_DEPLOYMENT } from "lib/constants";
import Form, {
  FormHead,
  FormLogo,
  FormTitle,
  FormEntry,
  FormActions,
} from "styles/components/Form";

/**
 * Class for form deployment cancellation.
 *
 * @class      FormDeploymentCancellation (name)
 */
class FormDeploymentCancellation extends Component {
  /**
   * Handles the form submit.
   *
   * @param      {Object} / The event
   * @return     {Void}
   */
  handleFormSubmit = (e) => {
    e.preventDefault();
    const { modal, openModal } = this.props;
    const hasProp = (key) => prop(key, modal.data);

    if (hasProp("editDeployment")) {
      openModal({
        type: MODAL_WIZARD,
        data: {
          wizardType: WIZARD_DEPLOYMENT,
          initialStep: WIZARD_CREATE_DEPLOYMENT,
          editDeployment: true,
        },
      });
    } else {
      openModal({
        type: MODAL_WIZARD,
        data: {
          wizardType: WIZARD_DEPLOYMENT,
          initialStep: WIZARD_CREATE_DEPLOYMENT,
        },
      });
    }
  };

  /**
   * Handles draft save.
   *
   * @param      {Object} / The event
   * @return     {Void}
   */
  handleDraftSave = async (e) => {
    e.preventDefault();
    const { deployment, modal, closeModal, clearDeployment, updateDeployment } = this.props;
    const hasProp = (key) => prop(key, modal.data);

    await updateDeployment({
      selector: "deployments",
      id: deployment.id,
      updates: {
        ...deployment,
        program: deployment.program.label,
        cohort: deployment.cohort.label,
        is_draft: true,
        status: "Draft",
      },
    });
    closeModal();
    clearDeployment();
  };

  async componentWillUnmount() {
    await this.props.clearDeployment();
  }

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    return (
      <Form onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            <IconSvgComponent iconPath="svg/ico-rocket-att.svg" />
          </FormLogo>

          <FormTitle>You weren’t finished!</FormTitle>

          <FormEntry>
            You haven’t finished creating your deployment. Would you like to save it as a draft or
            continue?
          </FormEntry>
        </FormHead>

        <FormActions>
          <ButtonLink transparent onClick={this.handleDraftSave} type="button">
            Save Draft
          </ButtonLink>

          <ButtonLink type="submit" violet hasIcon>
            <span>Continue</span>

            <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    deployment: state.deployment,
  }),
  {
    create,
    openModal,
    closeModal,
    clearDeployment,
    updateDeployment,
  }
)(FormDeploymentCancellation);
