import React from "react";
import { useSelector } from "react-redux";

import { FrameBox, FrameCell, CourseFrameTitle } from "styles/components/Frame";
import CircularChart from "components/chart/CircularChart";
import TableDropdown from "components/elements/TableDropdown";
import Loader from "components/common/loader";
import useAPIData from "components/useAPIData";

export default function ProgressCard({ title, getDataHandler, potentialSelector, actualSelector }) {
  const [dataLoaded, data] = useAPIData({
    fetchDataCallback: getDataHandler,
    dependencies: [getDataHandler],
  });
  const potentialValue = useSelector((state) => potentialSelector(data));
  const actualValue = useSelector((state) => actualSelector(data));

  return (
    <FrameBox>
      <TableDropdown
        options={{
          items: [
            {
              callback: () => {},
              btnText: "REPLACE ME",
            },
          ],
        }}
      />

      <CourseFrameTitle>{title}</CourseFrameTitle>

      {!dataLoaded ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
            backgroundColor: "rgba(0, 0, 0, .2)",
            borderRadius: "8px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : null}

      <FrameCell>
        <CircularChart
          domainMin={0}
          domainMax={potentialValue}
          value={actualValue}
          primaryText={dataLoaded && data ? `${(actualValue / potentialValue) * 100}` : ""}
          primaryTextUnit={"%"}
          secondaryText={potentialValue !== 100 ? `${actualValue}/${potentialValue}` : ""}
          circleSize={300}
          fontSize={50}
          secondaryTextFontSize={20}
          secondaryTextFontWeight={600}
        />
      </FrameCell>
    </FrameBox>
  );
}
