/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { SCORE_HISTORY_BAR_CHART_STEP_SIZE } from "../../constants/common";

const colors = ["#FCCD26", "#CC4879", "#18178C"];

/**
 * Class for bar chart small.
 *
 * @class      ScoreHistoryChart (name)
 */
class ScoreHistoryChart extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  constructor(props) {
    super(props);

    this.state = {
      data: {
        labels: props.labels,
        datasets: [
          {
            label: "Score",
            backgroundColor: colors,
            borderWidth: 0,
            hoverBackgroundColor: colors,
            data: props.data,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              categoryPercentage: 1.0,
              barPercentage: 0.9,
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                padding: 10,
                min: props.minY,
                max: props.maxY,
                stepSize: SCORE_HISTORY_BAR_CHART_STEP_SIZE,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
    this.chartReference = React.createRef();
  }

  /**
   * Life-cycle hook. Called on component's first mount
   *
   */
  componentDidMount() {
    this.createDataset();
  }

  /**
   * Life-cycle hook. Called on compoent's update (props change)
   *
   * @param {Object} The previous properties
   */
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.createDataset();
    }
  }

  componentWillUnmount() {
    this.chartReference.chartInstance.destroy();
  }

  /**
   * Performs setState
   *
   */
  createDataset = () => {
    this.setState({
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: "Score",
            backgroundColor: colors,
            borderWidth: 0,
            hoverBackgroundColor: colors,
            data: this.props.data,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              categoryPercentage: 1.0,
              barPercentage: 0.9,
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                padding: 10,
                min: this.props.minY,
                max: this.props.maxY,
                stepSize: SCORE_HISTORY_BAR_CHART_STEP_SIZE,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  };

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const { data, options } = this.state;
    return (
      <React.Fragment>
        <Wrapper>
          <Bar
            ref={(reference) => (this.chartReference = reference)}
            data={data}
            options={options}
          />
        </Wrapper>
      </React.Fragment>
    );
  }
}

const Wrapper = styled.div`
  max-height: 209px;
  margin-top: 25px;

  .chartjs-render-monitor {
    height: 209px !important;
  }
`;

export default ScoreHistoryChart;
