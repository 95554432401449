/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */

import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import Field from "components/common/Field";
import ButtonLink from "components/common/ButtonLink";

import Form, {
  FormLogo,
  FormBody,
  FormRowGrid,
  FormCol,
  FormControls,
  FormActions,
  FormTitle,
  FormBodyGroup,
} from "styles/components/Form";

import { addImageToFolder } from "store/state/builderAssessment/actions";
/**
 * Class for form create cohort.
 *
 * @class      FormCreateCohort (name)
 */
class FormImageUpload extends Component {
  state = {
    id: "",
    hasUploads: false,
    file: null,
    fileName: "",
    selectedFolder: "",
    selectedFolderId: "",
  };

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleSelectChange = (key) => (value) => {
    const { folders } = this.props;

    this.setState({ [key]: value });
  };

  handleUploadInput = (event) => {
    const file = event.target.files[0];

    this.processUploadedImage(file);
  };

  handleDragEnter = (e) => {
    const label = document.querySelector(".uploader__btn");
    label.classList.add("has-file");
  };
  handleDragLeave = (e) => {
    const label = document.querySelector(".uploader__btn");
    label.classList.remove("has-file");
  };

  handleDrop = (e) => {
    e.preventDefault();
    const label = document.querySelector(".uploader__btn");
    label.classList.remove("has-file");

    let file = e.dataTransfer.files[0];

    this.processUploadedImage(file);
  };

  processUploadedImage = (file) => {
    this.setState({
      file: URL.createObjectURL(file),
      hasUploads: true,
      fileName: file.name,
    });
  };

  /**
   * Handles Form Submit
   *
   */
  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props.addImageToFolder({
      file: this.state.file,
      fileName: this.state.fileName,
      folderId: this.state.selectedFolder.id,
    });

    this.props.onUploadComplete();
  };

  render() {
    const { hasUploads, fileName, selectedFolder } = this.state;
    const { folders } = this.props;

    const folderOptions = folders.map((folder) => {
      return {
        id: folder.id,
        label: folder.name,
        value: folder.name,
      };
    });

    return (
      <Form
        colSpacing
        fulloNmobile
        onSubmit={this.handleFormSubmit}
        className="form-upload"
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
      >
        <FormLogo>
          <IconSvgComponent iconPath="svg/upload-image.svg" />
        </FormLogo>

        <FormBody noMargin>
          {!hasUploads && (
            <FormBodyGroup>
              <FormTitle>Drag & Drop Image to Upload</FormTitle>

              <p>
                Supported image types are PNG, JPG, or GIF. <br />
                Maximum file size 600kb.
              </p>

              <FormControls className="upload-holder">
                <label htmlFor="uploader" className="uploader__btn">
                  Choose File
                </label>

                <input
                  type="file"
                  id="uploader"
                  name="uploader"
                  accept="image/*"
                  onChange={this.handleUploadInput}
                />
              </FormControls>
            </FormBodyGroup>
          )}
          {hasUploads && (
            <FormBodyGroup>
              <FormTitle className="form-title-alt">Upload Image</FormTitle>

              <FormRowGrid justifyCenter>
                <FormCol size1>
                  <FormControls>
                    <Field
                      id="name"
                      name="name"
                      onChange={this.handleInputChange("fileName")}
                      value={fileName}
                      placeholder="Image Name"
                      required
                      max="255"
                    />
                  </FormControls>
                </FormCol>

                <FormCol size1>
                  <FormControls>
                    <Field
                      isSelect
                      onChange={this.handleSelectChange("selectedFolder")}
                      options={folderOptions}
                      value={selectedFolder}
                      placeholder="Select Folder"
                    />
                  </FormControls>
                </FormCol>
              </FormRowGrid>
            </FormBodyGroup>
          )}
        </FormBody>

        {hasUploads && (
          <FormActions className="form-actions-with-offset">
            <ButtonLink type="button" transparent onClick={() => this.props.closeModal()}>
              <span>Cancel</span>
            </ButtonLink>

            <ButtonLink type="submit" violet hasIcon>
              <span>Upload</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          </FormActions>
        )}
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    folders: state.builderAssessment.folders,
  }),
  {
    closeModal,
    addImageToFolder,
  }
)(FormImageUpload);
