import React from "react";
import { isEmpty } from "ramda";
import MediaQuery from "react-responsive";
import InfiniteScroll from "react-infinite-scroll-component";

import DataTable from "components/views/DataTable";
import { FrameBox } from "styles/components/Frame";
import Loader from "components/common/loader";
import List, { ListItem } from "styles/components/List";

export const BreakdownTable = ({
  data = [],
  tableParams,
  tableClasses,
  containerStyles,
  columns = [],
  mobileColumns = () => {},
}) => {
  const getData = (student) => mobileColumns(student);

  const renderItems = (data, item, i) => {
    const dataItem = getData(data)[item];

    return (
      <ListItem key={item}>
        <strong>{dataItem.title}:</strong>
        <span>{isEmpty(dataItem.value) ? "—" : dataItem.value}</span>
      </ListItem>
    );
  };

  return (
    <FrameBox noPadding style={containerStyles}>
      <MediaQuery maxWidth={1023}>
        {data && !isEmpty(data) && (
          <InfiniteScroll
            dataLength={data.length}
            // next={this.getMoreRecords}
            hasMore={data.length !== tableParams.count}
            loader={<Loader height={100} width={250} />}
          >
            <List>
              {data.map((row, idx) => (
                <ListItem key={row.id}>
                  <ul>{Object.keys(getData(row)).map((item, i) => renderItems(row, item, i))}</ul>
                </ListItem>
              ))}
            </List>
          </InfiniteScroll>
        )}
      </MediaQuery>
      <MediaQuery minWidth={1024}>
        <DataTable
          classNames={`smaller-font react-table-no-min-width ${tableClasses}`}
          data={data}
          columns={columns}
          page={tableParams.page}
          pages={tableParams.pages}
          pageSize={tableParams.pageSize}
          count={tableParams.count}
        />
      </MediaQuery>
    </FrameBox>
  );
};
