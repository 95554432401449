/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
/**
 * The internal dependencies.
 */
import IconSvgComponent from "components/common/IconSvgComponent";

/**
 * The styled components.
 */
import Section, { SlickSlider, Grid, Button, Card } from "styles/components/studentDashboard";
import stemifyRoutes from "constants/routes";
/**
 * Renders the Intro
 *
 * @param  {Object} The properties
 * @return {JSX}
 */

export const NoAssessmentView = connect(
  (state) => ({}),
  {}
)(() => (
  <React.Fragment>
    <Section>
      <Grid>
        <Grid.Col size="66.66">
          <Section.Head>
            <Section.HeadContent>
              <Section.Title>My Study Plan</Section.Title>
            </Section.HeadContent>
          </Section.Head>

          <Section.Body>
            <Card minHeight="299" textCentered>
              <Card.Body flexCenteredColumn>
                <Card.Icon>
                  <IconSvgComponent iconPath="svg/ico-sign-right.svg" />
                </Card.Icon>

                <Card.SubTitle centered maxWidth="427">
                  Complete an assessment to receive your personalized study plan.
                </Card.SubTitle>
              </Card.Body>
            </Card>
          </Section.Body>
        </Grid.Col>

        <Grid.Col size="33.33">
          <Section.Head>
            <Section.HeadContent>
              <Section.Title>Full Learning Library</Section.Title>
            </Section.HeadContent>
          </Section.Head>

          <Section.Body>
            <Card noPadding minHeight="299">
              <Card.Body>
                <Card.Image image="/images/card-image.png" />

                <Card.Content>
                  <Card.Entry>
                    <p>Use interactive video lessons and practice problems to learn and review!</p>
                  </Card.Entry>

                  <Card.Actions>
                    <Card.Link to={stemifyRoutes.learningLibrary}>Get Started</Card.Link>
                  </Card.Actions>
                </Card.Content>
              </Card.Body>
            </Card>
          </Section.Body>
        </Grid.Col>
      </Grid>
    </Section>
  </React.Fragment>
));

export default NoAssessmentView;
