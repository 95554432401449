import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import { resetPasswordRequest } from "store/state/user/actions";
import IconSvgComponent from "components/common/IconSvgComponent";

import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Form, {
  FormBody,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
  Wrapper,
  LoginWrapper,
  StyledFormWrapper,
  StyledHead,
  StyledIconWrapper,
  StyledTitle,
  StyledFormDescription,
  StyledBody,
} from "styles/components/Form";
import { validateField } from "utilities/validation";
import { FORM_PASSWORD_RESET_MESSAGES } from "constants/messages";
import FieldErrors from "../common/FieldErrors";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      formErrors: {
        email: {
          valid: true,
          message: "",
        },
        formValid: true,
      },
      formSubmitted: false,
    };
    this.rules = {
      email: {
        requiredMessage: FORM_PASSWORD_RESET_MESSAGES.EMAIL_REQUIRED,
        typeEmailMessage: FORM_PASSWORD_RESET_MESSAGES.EMAIL_FORMAT_INVALID,
      },
    };
  }

  handleFieldChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value.trim(),
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    this.validateAllFields();
    if (this.state.formErrors.formValid) {
      await this.props.resetPasswordRequest({ email });
      this.props.apiError.code === -1 && this.setState({ formSubmitted: true });
    } else {
      this.setState({
        formSubmitted: false,
      });
    }
  };

  validateAllFields = () => {
    this.setState({
      formErrors: validateField(
        this.state.formErrors,
        this.rules,
        document.getElementById("email"),
        "",
        true
      ),
    });
  };

  render() {
    const { email, formErrors, formSubmitted } = this.state;
    const { apiCallInProgress } = this.props;

    return formSubmitted ? (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: this.props.location.pathname },
        }}
      ></Redirect>
    ) : (
      <Wrapper>
        <LoginWrapper>
          <StyledFormWrapper>
            <StyledHead>
              <StyledIconWrapper>
                <IconSvgComponent additionalClass="login" iconPath="svg/stemify-logo-login.svg" />
              </StyledIconWrapper>
              <StyledTitle top={25}>Reset My Password</StyledTitle>
              <StyledFormDescription short>
                Enter your email address to reset your password.
              </StyledFormDescription>
              <FieldErrors formErrors={formErrors} apiErrorMessage={this.props.apiError.message} />
            </StyledHead>

            <StyledBody>
              <Form onSubmit={this.handleSubmit}>
                <FormBody>
                  <FormRowGrid>
                    <FormCol>
                      <FormControls>
                        <Field
                          id="email"
                          name="email"
                          required
                          onChange={this.handleFieldChange}
                          value={email}
                          placeholder="User Id / Email"
                          required
                          max="128"
                          error={!formErrors.email.valid}
                        />
                      </FormControls>
                    </FormCol>
                  </FormRowGrid>
                </FormBody>
                <FormActions>
                  <ButtonLink type="submit" violet disabled={apiCallInProgress}>
                    <span>Reset Password</span>
                  </ButtonLink>
                </FormActions>
              </Form>
            </StyledBody>
          </StyledFormWrapper>
        </LoginWrapper>
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      apiError: state.user.apiErrors.passwordReset,
      apiCallInProgress: state.user.apiCallInProgress,
    }),
    { resetPasswordRequest }
  )(Login)
);
