/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { isEmpty } from "ramda";

/**
 * The internal dependencies.
 */
import Grid, { Col } from "styles/components/Grid";
import SectionDashboard, { SectionBody } from "styles/components/SectionDashboard";
import { FrameBox } from "styles/components/Frame";
import { ListStats, ListItem } from "styles/components/List";
import Stat, { StatTitle, StatEntry } from "styles/components/Stat";
import stemifyRoutes from "../../constants/routes";
import Loader from "components/common/loader";
import { NoDataMessageContainer } from "styles/components/Card";
import { NoDataMessage } from "styles/components/Card";
import { FORMAT } from "constants/common";

import StudentInfo, {
  StudentInfoTitle,
  StudentInfoEntry,
  StudentInfoIcon,
  StudentInfoContent,
} from "styles/components/StudentInfo";

import StudentAttempt, {
  StudentAttemptHead,
  StudentAttemptBody,
  StudentAttemptFoot,
  StudentAttemptTitle,
  StudentAttemptDetail,
  StudentAttemptDetailTitle,
  StudentAttemptDetailText,
  StudentAttemptScore,
  StudentAttemptScoreDetail,
  Button,
} from "styles/components/StudentAttempt";

import {
  getAssessmentStudentDashboardData,
  clearAssessmentStudentDashboardData,
} from "store/state/facultyDashboard/actions";

/**
 * Class for deployment dashboard.
 *
 * @class      AssessmentDashboardStudent (name)
 */
class AssessmentDashboardStudent extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */

  componentDidMount() {
    const { deploymentId, assessmentId, studentId } = this.props.match.params;

    this.props.getAssessmentStudentDashboardData({
      deploymentId,
      assessmentId,
      studentId,
    });
  }

  componentWillUnmount() {
    this.props.clearAssessmentStudentDashboardData();
    if (this.chartReference) {
      const { chartInstance } = this.chartReference.chartReference;
      chartInstance.destroy();
    }
  }

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const { deploymentId, assessmentId, studentId } = this.props.match.params;
    const { studentDetails, apiCallInProgress, studentApiErrors } = this.props;

    // const top_score = studentDetails.attempts.reduce((initialVal = 0, attempt) => { return (attempt.scorePercentage >= initialVal) ? attempt.scorePercentage : initialVal }).scorePercentage
    //code == -1 -> No error
    return !apiCallInProgress &&
      studentApiErrors.get.code === -1 &&
      !isEmpty(studentDetails) &&
      studentDetails.attempts.length > 0 ? (
      <SectionDashboard modified>
        <SectionBody>
          <Grid gutterSmall colSpacing>
            <Col flex>
              <FrameBox variant="primary">
                <ListStats stats>
                  <ListItem key={"student-info"}>
                    <StudentInfo>
                      <StudentInfoIcon>{studentDetails.name.charAt(0)}</StudentInfoIcon>
                      <StudentInfoContent>
                        <StudentInfoTitle>{studentDetails.name}</StudentInfoTitle>
                        <StudentInfoEntry>{studentDetails.email}</StudentInfoEntry>
                      </StudentInfoContent>
                    </StudentInfo>
                  </ListItem>

                  <ListItem key="latestScore">
                    <Stat>
                      <StatTitle>{studentDetails.topScorePercentage}%</StatTitle>
                      <StatEntry>TOP SCORE</StatEntry>
                    </Stat>
                  </ListItem>

                  <ListItem key="numberOfAttempts">
                    <Stat>
                      <StatTitle>{studentDetails.totalAttemptCount}</StatTitle>
                      <StatEntry># OF ATTEMPTS</StatEntry>
                    </Stat>
                  </ListItem>

                  <ListItem key="latestAttempt">
                    <Stat>
                      <StatTitle>
                        {moment(studentDetails.latestAttemptDate).format(FORMAT.SHORT_DATE)}
                      </StatTitle>
                      <StatEntry>LATEST ATTEMPT</StatEntry>
                    </Stat>
                  </ListItem>

                  <ListItem key="durationOfLatest">
                    <Stat>
                      <StatTitle>{studentDetails.latestDuration}</StatTitle>
                      <StatEntry>DURATION OF LATEST</StatEntry>
                    </Stat>
                  </ListItem>
                </ListStats>
              </FrameBox>
            </Col>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {studentDetails.attempts.map((attempt, index) => (
                <Col flex third key={attempt.duration + index} style={{ maxWidth: "400px" }}>
                  <StudentAttempt>
                    <StudentAttemptHead>
                      <StudentAttemptTitle>
                        <div>Attempt #{index + 1}</div>
                        <div
                          style={{ fontSize: "12px", textAlign: "center", fontWeight: "normal" }}
                        >
                          Version {attempt.versionNumber}
                        </div>
                      </StudentAttemptTitle>

                      <Button
                        to={`${stemifyRoutes.deployments}/${deploymentId}/${assessmentId}/${studentId}/${attempt.attemptId}`}
                      >
                        View Details
                      </Button>
                    </StudentAttemptHead>

                    <StudentAttemptBody>
                      <StudentAttemptDetail>
                        <StudentAttemptDetailTitle>ATTEMPT SCORE</StudentAttemptDetailTitle>
                        <StudentAttemptScore>{attempt.scorePercentage}%</StudentAttemptScore>
                        <StudentAttemptScoreDetail>
                          ({attempt.score} / {attempt.totalScore})
                        </StudentAttemptScoreDetail>
                      </StudentAttemptDetail>
                    </StudentAttemptBody>

                    <StudentAttemptFoot>
                      <StudentAttemptDetail>
                        <StudentAttemptDetailTitle>Date</StudentAttemptDetailTitle>
                        <StudentAttemptDetailText>
                          {moment(attempt.date).format(FORMAT.SHORT_DATE)}
                        </StudentAttemptDetailText>
                      </StudentAttemptDetail>

                      <StudentAttemptDetail>
                        <StudentAttemptDetailTitle>Duration</StudentAttemptDetailTitle>
                        <StudentAttemptDetailText>{attempt.duration}</StudentAttemptDetailText>
                      </StudentAttemptDetail>
                    </StudentAttemptFoot>
                  </StudentAttempt>
                </Col>
              ))}
            </div>
          </Grid>
        </SectionBody>
      </SectionDashboard>
    ) : (
      <NoDataMessageContainer noIcon>
        {apiCallInProgress == true && <Loader />}
        {apiCallInProgress == false && (
          <NoDataMessage>
            No Student Dashboard content <br /> available at this time.
          </NoDataMessage>
        )}
      </NoDataMessageContainer>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      studentDetails: state.facultyDashboard.student,
      apiCallInProgress: state.facultyDashboard.apiCallInProgress,
      studentApiErrors: state.facultyDashboard.apiErrors.student,
    };
  },
  {
    getAssessmentStudentDashboardData,
    clearAssessmentStudentDashboardData,
  }
)(AssessmentDashboardStudent);
