/**
 * The external dependencies.
 */
import React, { Component } from "react";

import ButtonLink from "components/common/ButtonLink";

import {
  ImageLibraryFile,
  ImageLibraryFileInput,
  ImageLibraryFileMenu,
  ImageLibraryFileMenuHolder,
} from "styles/components/ImageManager";

class ImageFile extends Component {
  state = {
    editMode: false,
    showMenu: false,
    name: "",
  };

  inputRef = React.createRef();

  onRightClick = (event) => {
    event.preventDefault();

    if (window.innerWidth < 1024) {
      return;
    }

    this.setState({
      showMenu: true,
    });
  };

  hideMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      showMenu: false,
    });
  };

  handleRename = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      showMenu: false,
      editMode: true,
    });

    setTimeout(() => {
      this.inputRef.focus();
    }, 10);
  };

  handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onDelete();
  };

  handleInputChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleNameKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleBlur(event);
    }
  };

  handleFocus = (event) => {
    event.stopPropagation();
  };

  handleBlur = (event) => {
    this.setState({
      editMode: false,
    });

    this.props.onNameChange(this.state.name);
  };

  componentDidMount() {
    this.setState({
      name: this.props.name,
    });
  }

  render() {
    const { name, editMode } = this.state;
    const { selected, src } = this.props;

    return (
      <ImageLibraryFile
        selected={selected}
        onClick={this.props.onClick}
        draggable
        onDragStart={this.props.onDragStart}
        onContextMenu={(e) => this.onRightClick(e)}
        menuActive={this.state.showMenu}
      >
        <img src={src} alt={name} />

        {editMode && (
          <ImageLibraryFileInput
            innerRef={(input) => {
              this.inputRef = input;
            }}
            value={name}
            onChange={(e) => this.handleInputChange(e)}
            onFocus={(e) => this.handleFocus(e)}
            onBlur={(e) => this.handleBlur(e)}
            onKeyDown={(e) => this.handleNameKeyDown(e)}
          />
        )}

        {!editMode && <span>{name}</span>}

        <ImageLibraryFileMenuHolder active={this.state.showMenu}>
          <div className="menu-trigger" onClick={(e) => this.hideMenu(e)}></div>

          <ImageLibraryFileMenu>
            <ButtonLink isUnstyledButton onClick={(e) => this.handleRename(e)}>
              <span>Rename</span>
            </ButtonLink>

            <ButtonLink isUnstyledButton onClick={(e) => this.handleDelete(e)}>
              <span>Archive</span>
            </ButtonLink>
          </ImageLibraryFileMenu>
        </ImageLibraryFileMenuHolder>
      </ImageLibraryFile>
    );
  }
}

export default ImageFile;
