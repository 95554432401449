/**
 * @ External Dependencies
 */
import { createAction } from "redux-actions";

/**
 * @ Action creators
 */
export const createNewQuestion = createAction("CREATE_NEW_QUESTION");
export const updateQuestionOrder = createAction("UPDATE_QUESTION_ORDER");
export const updateAnswers = createAction("UPDATE_ANSWER");
export const setActiveIndex = createAction("SET_ACTIVE_INDEX");
export const setPageBlur = createAction("SET_PAGE_BLUR");
export const setPageLock = createAction("SET_PAGE_LOCK");

export const closeModal = createAction("ui/close-modal");
