import styled from "styled-components";

export const MaintenanceBannerContainer = styled.div`
  display: none;
  background-color: rgba(255, 152, 28);

  ${(props) => props.active && `
    display: flex;
    align-items: center;
    padding: 10px 50px;
  `
  }
`;
