import { handleActions } from "redux-actions";
import {
  // submitUserRegistration,
  // completeUserRegistration,
  // submitLogin,
  completeLogin,
  rejectLogin,
  receiveFirstLogin,
  requestLoggedInStatus,
  receiveLoggedInStatus,
  rejectLoggedInStatus,
  requestLogin,
  rejectFirstSigninResetPassword,
  successFirstSigninResetPassword,
  rejectResetForgottenPassword,
  rejectResetPassword,
  successResetPassword,
  requestLogout,
  successLogout,
  rejectLogout,
  successResetForgottenPassword,
  requestResetPassword,
  requestFirstSigninResetPassword,
  setAccountId,
  requestAccountFaqs,
  rejectAccountFaqs,
  setAccountFaqs,
  noNetworkFound,
  networkRestored,
} from "./actions";
import { errorData } from "constants/api";

const defaultState = {
  loggedIn: false,
  loggingIn: false,
  loggingOut: false,
  logOutClicked: false,
  accountSwitched: null,
  currentAccountId: null,
  currentAccountName: null,
  accountId: null,
  isSSOLogin: false,
  accountName: null,
  role: null,
  loadingFaqs: true,
  faqData: {
    faq: [],
    intro: [],
  },
  apiErrors: {
    login: errorData,
    firstSignInResetPassword: errorData,
    logout: errorData,
    changePassword: errorData,
    passwordReset: errorData,
  },
  apiCallInProgress: false,
};

const user = handleActions(
  {
    [requestLogin]: (state) => ({
      ...state,
      loggingIn: true,
      logOutClicked: false,
      apiErrors: {
        ...state.apiErrors,
        login: errorData,
      },
    }),
    [completeLogin]: (state, { payload: { user, isSSOLogin } }) => ({
      ...state,
      ...user,
      isSSOLogin: isSSOLogin ? isSSOLogin : false,
      loggingIn: false,
      loggedIn: true,
      logOutClicked: false,
      apiErrors: {
        ...state.apiErrors,
        login: errorData,
      },
    }),
    [requestFirstSigninResetPassword]: (state) => ({
      ...state,
      apiCallInProgress: true,
    }),
    [rejectFirstSigninResetPassword]: (state, { payload: { message, sessionExpired } }) => ({
      ...state,
      firstTimeLogin: sessionExpired ? false : true,
      session: sessionExpired ? null : state.session,
      loggingIn: false,
      apiErrors: {
        ...state.apiErrors,
        firstSignInResetPassword: {
          code: 1,
          message: message,
        },
      },
      apiCallInProgress: false,
    }),
    [successFirstSigninResetPassword]: (state) => ({
      ...state,
      firstTimeLogin: false,
      loggingIn: false,
      loggedIn: false,
      user: null,
      apiErrors: {
        ...state.apiErrors,
        firstSignInResetPassword: errorData,
      },
      apiCallInProgress: false,
    }),
    [rejectLogin]: (state, { payload }) => ({
      ...state,
      user: null,
      isSSOLogin: false,
      loggingIn: false,
      loggedIn: false,
      apiErrors: {
        ...state.apiErrors,
        login: {
          code: 1,
          message: payload,
        },
      },
    }),
    [rejectResetForgottenPassword]: (state, { payload }) => ({
      ...state,
      apiErrors: {
        ...state.apiErrors,
        changePassword: {
          code: 1,
          message: payload,
        },
      },
    }),
    [requestResetPassword]: (state) => ({
      ...state,
      apiCallInProgress: true,
    }),
    [successResetPassword]: (state, { payload }) => ({
      ...state,
      apiErrors: {
        ...state.apiErrors,
        passwordReset: errorData,
      },
      apiCallInProgress: false,
    }),
    [rejectResetPassword]: (state, { payload }) => ({
      ...state,
      apiErrors: {
        ...state.apiErrors,
        passwordReset: {
          code: 1,
          message: payload,
        },
      },
      apiCallInProgress: false,
    }),
    [successResetForgottenPassword]: (state, { payload }) => ({
      ...state,
      apiErrors: {
        ...state.apiErrors,
        changePassword: errorData,
      },
    }),
    [receiveFirstLogin]: (state, { payload: { session, username } }) => ({
      ...state,
      session,
      username,
      firstTimeLogin: true,
    }),
    [requestLoggedInStatus]: (state) => ({ ...state, logOutClicked: false, checkingSession: true }),
    [receiveLoggedInStatus]: (state, action) => ({
      ...state,
      checkingSession: false,
      loggedIn: action.payload,
    }),
    [setAccountId]: (
      state,
      { payload: { currentAccountId, accountSwitched, currentAccountName } }
    ) => ({
      ...state,
      accountSwitched: accountSwitched,
      currentAccountId: currentAccountId,
      currentAccountName: currentAccountName,
    }),
    [rejectLoggedInStatus]: (state) => ({
      ...state,
      checkingSession: false,
      loggedIn: false,
    }),
    [requestLogout]: (state) => ({
      ...state,
      isSSOLogin: false,
      loggingOut: true,
      apiErrors: {
        ...state.apiErrors,
        logout: errorData,
      },
    }),
    [successLogout]: () => ({
      ...defaultState,
      isSSOLogin: false,
      logOutClicked: true,
      loggingOut: false,
    }),
    [rejectLogout]: (state, { payload }) => ({
      ...state,
      isSSOLogin: false,
      loggingOut: false,
      apiErrors: {
        ...state.apiErrors,
        logout: {
          code: 1,
          message: payload,
        },
      },
    }),
    [requestAccountFaqs]: (state) => ({
      ...state,
      loadingFaqs: true,
    }),
    [setAccountFaqs]: (state, { payload }) => ({
      ...state,
      faqData: payload,
      loadingFaqs: false,
    }),
    [rejectAccountFaqs]: (state, { payload }) => ({
      ...state,
      faqData: defaultState.faqData,
      loadingFaqs: false,
      apiErrors: {
        ...state.apiErrors,
        logout: {
          code: 1,
          message: payload,
        },
      },
    }),
    [noNetworkFound]: (state) => ({
      ...state,
      showNoNetworkDialouge: true,
    }),
    [networkRestored]: (state) => ({
      ...state,
      showNoNetworkDialouge: false,
    }),
  },
  defaultState
);

export default user;
