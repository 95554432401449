/**
 * @ External Dependencies
 */
import React from "react";
import ReactSVG from "react-svg";

const IconSvgComponent = ({ iconPath, additionalClass, style = {} }) => {
  let classAddition = "";

  if (additionalClass !== undefined) {
    classAddition = additionalClass;
  } else {
    classAddition = "";
  }

  return (
    <ReactSVG
      src={`//${window.location.host}/${iconPath}`}
      className={`svg-icon ${classAddition}`}
      svgClassName={additionalClass}
      style={style}
    />
  );
};

export default IconSvgComponent;
