/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

/**
 * The internal dependencies.
 */

import stemifyRoutes from "constants/routes";
import DocumentTitle from "react-document-title";
import ModalLearningVideoTransition from "components/modal/ModalLearningVideoTransition";
import { NoDataMessageContainer, NoDataMessage } from "styles/components/Card";
import Loader from "components/common/loader";
import IconSvgComponent from "components/common/IconSvgComponent";
import SectionGroups, {
  SectionHead,
  SectionBody,
  SectionTitle,
} from "styles/components/SectionGroups";

import SubjectCard, {
  SubjectBackLink,
  SubjectCards,
  SubjectCardTitle,
  SubjectCardMedia,
  SubjectCardImage,
  SubjectCardContent,
  SubjectCardRoute,
  SubjectCardEntry,
  SubjectCardBadge,
  SubjectCardProgress,
  SubjectCardProgressIndicator,
} from "styles/components/SubjectCard";

import { getLearningUnit } from "store/state/learningLibrary/actions";
import { FormattedText } from "components/common/FormattedText";
import { IMAGE_S3_SERVER, THUMBNAILS } from "constants/api";
import queryString from "query-string";

/**
 * Class for deployment dashboard.
 *
 * @class      LearningDiscipline (name)
 */
class LearningDisciplineSubject extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    showModal: false,
    modalSubject: null,
  };

  componentDidMount() {
    const { getLearningUnit, isFilteredByTopic } = this.props;
    const unitId = this.props.match?.params?.unitId;

    if (!isFilteredByTopic) getLearningUnit({ unitId });
  }

  componentWillUnmount() {
    if (this.chartReference) {
      const { chartInstance } = this.chartReference.chartReference;
      chartInstance.destroy();
    }
  }

  activateModal = (e, activeSubject) => {
    e.preventDefault();

    this.setState({
      showModal: true,
      modalSubject: activeSubject,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalSubject: null,
    });
  };

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const {
      learningUnit,
      learningUnit: { learningModules },
      apiCallInProgress,
      isFilteredByTopic,
    } = this.props;

    const { showModal, modalSubject } = this.state;

    return !apiCallInProgress && learningModules && learningModules.length > 0 ? (
      <DocumentTitle title={`Stemify | ${learningUnit.name}`}>
        <SectionGroups>
          <ModalLearningVideoTransition
            active={showModal}
            subject={modalSubject}
            onCloseModal={this.closeModal}
            firstStepTitle="Good job, you’ve finished the video lesson"
            secondStepTitle="Complete the lesson by taking a practice assignment."
            showRewatchButton={true}
          />

          <SectionHead>
            <SectionTitle>
              <FormattedText text={learningUnit.name}></FormattedText>
            </SectionTitle>

            {isFilteredByTopic ? (
              <SubjectBackLink to={`/`}>
                <IconSvgComponent iconPath="svg/ico-arrow-left.svg" />
                Back To Home Dashboard
              </SubjectBackLink>
            ) : (
              <SubjectBackLink to={`${stemifyRoutes.learningLibrary}`}>
                <IconSvgComponent iconPath="svg/ico-arrow-left.svg" />
                Back To All Subjects
              </SubjectBackLink>
            )}
          </SectionHead>

          <SectionBody id={"learningModuleContainer"}>
            <SubjectCards id={"learningModuleCardList"}>
              {learningModules.map((learningModule) => (
                <SubjectCard id={learningModule.id} key={learningModule.id}>
                  <SubjectCardRoute
                    to={{
                      pathname: isFilteredByTopic
                        ? `${stemifyRoutes.learningLibraryLearningModule}/${learningModule.id}`
                        : `${stemifyRoutes.learningLibrary}/${learningUnit.id}/${learningModule.id}`,
                    }}
                  >
                    <SubjectCardMedia>
                      {learningModule.isRecent && (
                        <SubjectCardBadge>Recently Watched</SubjectCardBadge>
                      )}
                      <div style={{ position: "relative" }}>
                        <SubjectCardImage
                          image={`${IMAGE_S3_SERVER}${THUMBNAILS}${learningModule.image}`}
                        />
                        {learningModule.openstax_resource && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "13px",
                              right: "7px",
                            }}
                          >
                            <IconSvgComponent iconPath="svg/ico-openstax-badge.svg" />
                          </div>
                        )}
                      </div>

                      {learningModule.progressPercent > 0 && (
                        <SubjectCardProgress>
                          <SubjectCardProgressIndicator value={learningModule.progressPercent} />
                        </SubjectCardProgress>
                      )}
                    </SubjectCardMedia>

                    <SubjectCardContent>
                      <SubjectCardTitle id={"learningModuleCardTitle"}>
                        <FormattedText text={learningModule.name}></FormattedText>
                      </SubjectCardTitle>

                      <SubjectCardEntry>
                        Average time to complete: {learningModule.completionTimeMins}{" "}
                        {learningModule.completionTimeMins > 1 ? "mins" : "min"}
                      </SubjectCardEntry>
                    </SubjectCardContent>
                  </SubjectCardRoute>
                </SubjectCard>
              ))}
            </SubjectCards>
          </SectionBody>
        </SectionGroups>
      </DocumentTitle>
    ) : (
      <NoDataMessageContainer>
        {apiCallInProgress !== false && <Loader />}
        {apiCallInProgress == false && (
          <NoDataMessage id={"noDataMsgWrapper"}>
            No Learning Library content <br /> available at this time.
          </NoDataMessage>
        )}
      </NoDataMessageContainer>
    );
  }
}

export default connect(
  (state) => ({
    learningUnit: state.learningLibrary.learningUnit,
    apiCallInProgress: state.learningLibrary.apiCallInProgress.learningUnit,
  }),
  {
    getLearningUnit,
  }
)(LearningDisciplineSubject);
