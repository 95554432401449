import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardInfo, StatusBadge } from "styles/components/CardInfo";
import moment from "moment";

export default class AssessmentCard extends Component {
  static propTypes = {
    assessmentDetails: PropTypes.object,
  };

  render() {
    const {
      props: {
        assessmentDetails: {
          id,
          assessment_name,
          status,
          end_date,
          top_score_perc,
          remaining_attempt,
          attempt_history,
          deployment_name,
        },
        viewResultClickHandler,
        takeAssessmentClickHandler,
        fullWidth = false,
      },
    } = this;

    let content;

    if (status === "Live" && attempt_history.length !== 0) {
      content = (
        <CardInfo fullWidth={fullWidth} id={"cardInfo"}>
          <CardInfo.Group>
            <CardInfo.Title id={"assessmentTitle"}>{assessment_name}</CardInfo.Title>

            <StatusBadge absolute success>
              {`${status.charAt(0).toUpperCase()}${status.slice(1)}`}
            </StatusBadge>

            <CardInfo.Text>{deployment_name}</CardInfo.Text>
            <CardInfo.Text>{`Deadline: ${moment(end_date).format("LL")}`}</CardInfo.Text>
          </CardInfo.Group>

          <CardInfo.Group>
            <CardInfo.Inner>
              <CardInfo.Entry>
                <CardInfo.Text small bold uppercase>
                  Score
                </CardInfo.Text>

                <CardInfo.LargeTitle inlineBlock>{`${top_score_perc}%`}</CardInfo.LargeTitle>
              </CardInfo.Entry>

              <CardInfo.Entry>
                <CardInfo.Text small bold uppercase>
                  Attempts Remaining
                </CardInfo.Text>

                <CardInfo.LargeTitle>{remaining_attempt}</CardInfo.LargeTitle>
              </CardInfo.Entry>
            </CardInfo.Inner>
          </CardInfo.Group>

          <CardInfo.Group>
            <CardInfo.Button id={"viewResult"} onClick={viewResultClickHandler}>
              View Results
            </CardInfo.Button>

            <CardInfo.Button id={"retake"} text uppercase onClick={takeAssessmentClickHandler}>
              Retake
            </CardInfo.Button>
          </CardInfo.Group>
        </CardInfo>
      );
    } else if (status === "Live" && attempt_history.length === 0) {
      content = (
        <CardInfo fullWidth={fullWidth} id={"cardInfo"} flexSpaceBetween>
          <CardInfo.Group>
            <CardInfo.Title id={"assessmentTitle"}>{assessment_name}</CardInfo.Title>

            <StatusBadge absolute success>
              {`${status.charAt(0).toUpperCase()}${status.slice(1)}`}
            </StatusBadge>

            <CardInfo.Text>{deployment_name}</CardInfo.Text>
            <CardInfo.Text>{`Deadline: ${moment(end_date).format("LL")}`}</CardInfo.Text>
          </CardInfo.Group>

          <CardInfo.Group>
            <CardInfo.Text>{`You may take the assessment up to ${remaining_attempt} times.`}</CardInfo.Text>
          </CardInfo.Group>

          <CardInfo.Group>
            <CardInfo.Button id={"takeAssessment"} onClick={takeAssessmentClickHandler}>
              Take Assessment
            </CardInfo.Button>
          </CardInfo.Group>
        </CardInfo>
      );
    } else {
      // status is Closed
      content = (
        <CardInfo fullWidth={fullWidth} id={"cardInfo"} title={deployment_name}>
          <CardInfo.Group>
            <CardInfo.Title id={"assessmentTitle"}>{assessment_name}</CardInfo.Title>

            <StatusBadge absolute>{`${status.charAt(0).toUpperCase()}${status.slice(
              1
            )}`}</StatusBadge>

            <CardInfo.Text>{deployment_name}</CardInfo.Text>
            <CardInfo.Text>{`Ended: ${moment(end_date).format("LL")}`}</CardInfo.Text>
          </CardInfo.Group>

          <CardInfo.Group>
            <CardInfo.Inner>
              <CardInfo.Entry>
                <CardInfo.Text small bold uppercase>
                  Score
                </CardInfo.Text>

                <CardInfo.LargeTitle inlineBlock>{`${top_score_perc}%`}</CardInfo.LargeTitle>
              </CardInfo.Entry>
            </CardInfo.Inner>
          </CardInfo.Group>

          <CardInfo.Group>
            <CardInfo.Button id={"viewResult"} onClick={viewResultClickHandler}>
              View Results
            </CardInfo.Button>
          </CardInfo.Group>
        </CardInfo>
      );
    }

    return content;
  }
}
