/**
 * The external dependencies.
 */
import React from "react";
import { connect } from "react-redux";

/**
 * Returns the account id
 *
 * @param {Object} / The router match
 * @return {JSX}
 */
const CohortBreadcrumb = ({ name }) => <span>{name}</span>;

export default connect((state, props) => {
  const { cohorts, cohortDetails } = state.administration;
  var cohort = cohorts.find((cohort) => cohort.id === props.match.params.cohortId);
  if (!cohort && cohortDetails && cohortDetails.length > 0) {
    cohort = cohortDetails[0];
  }
  var name = cohort ? cohort.name : "";
  return { name };
})(CohortBreadcrumb);
