const stemifyRoutes = {
  home: "/",
  studentHome: "/",
  studentPerformanceDetails: "/my-dashboard",
  instructorHome: "/",
  accounts: "/accounts",
  cohorts: "/cohorts",
  learningLibrary: "/learning-library",
  learningLibraryLearningModule: "/learning-library/learning-module",
  pageNotFound: "/page-not-found",
  assessments: "/assessments",
  practiceAssignment: "/practice-assignment",
  deployments: "/deployments",
  faq: "/faq",
  graph: "/graph",
  stemifyGuide: "https://stemify.zendesk.com/hc/en-us",
  courses: "/courses",
};

export default stemifyRoutes;
