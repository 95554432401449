/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import theme from "styles/theme";
import { media } from "../media";

export const StudentInfoTitle = styled.h1`
  margin-bottom: 0;
  font-size: 26px;
  line-height: 1;
  color: #fff;

  ${media.tablet_portrait`
		font-size: 20px;
	`};
`;

export const StudentInfoEntry = styled.p`
  font-weight: 700;
  line-height: 1;

  ${(props) =>
    props.inline &&
    `
		display: inline;
	`}
`;

export const StudentInfoContent = styled.div`
  padding-right: 15px;
`;

export const StudentInfoIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${theme.color.white};
  color: ${theme.color.sky_blue};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  line-height: 1;
  flex-shrink: 0;
  margin-right: 15px;

  ${media.tablet_portrait`
		width: 40px;
		height: 40px;
		font-size: 30px;
	`};
`;

const StudentInfo = styled.div`
  font-size: 18px;
  color: ${theme.color.white};
  display: flex;
  align-items: center;
  margin-left: -8px;

  ${media.tablet_portrait`
		font-size: 14px;
		margin-left: 0;
		padding-left: 10px;
	`};
`;

export const StudentInfoQuestionNotice = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding: 30px 0 .svg-icon {
    margin-bottom: 10px;
  }
`;

export const StudentInfoQuestion = styled.div`
  font-size: 19px;
  font-weight: 700;
`;

export const StudentInfoQuestionNumber = styled.div`
  font-size: 28px;
  line-height: 1;
  font-weight: 700;
  color: ${theme.color.blue};
  align-self: flex-start;
`;

export const StudentInfoQuestionHead = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  img {
    margin-right: 20px;
  }
`;

export const StudentInfoQuestionTitle = styled.h3`
  font-size: 28px;
`;

export const StudentInfoQuestionList = styled.ul`
  list-style: none;
  margin: 0 -28px;
`;

export const StudentInfoQuestionListItem = styled.li`
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0 28px;

  ${(props) =>
    props.correct &&
    `
    background: rgba(156, 212, 255, 0.57);
  `}

  ${(props) =>
    props.selected &&
    `
    background: rgba(156, 212, 255, 0.57);
  `}

  ${(props) =>
    props.containsImage &&
    `
    height: auto;
  `}
`;

export const StudentInfoQuestionListIndicator = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #979797;
  margin-right: 14px;

  svg {
    width: 100%;
    height: auto;
  }

  ${(props) =>
    props.correct &&
    `
      border: none;
    `}

  ${(props) =>
    props.selected &&
    `
      border: none;
    `}
`;

export default StudentInfo;
