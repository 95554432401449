import React, { Component } from "react";
import { connect } from "react-redux";

import AssessmentModalContent from "components/assessment/AssessmentModalContent";

class AssessmentSubmit extends Component {
  componentDidMount() {
    const { progress, modal } = this.props;
    const { assessmentId, deploymentId } = modal.data;
    if (progress !== 100) {
    }
  }

  render() {
    const {
      progress,
      modal: { data },
    } = this.props;

    return progress === 100 ? (
      <AssessmentModalContent
        icon="svg/board-check.svg"
        title="Are you sure you’re ready to submit?"
        description="Before submitting it is advised that you double check your answers."
        {...data}
      />
    ) : (
      <AssessmentModalContent
        icon="svg/board-exclamation.svg"
        title="You aren’t finished yet!"
        description="You have not answered all the questions. Do you still want to submit the assessment incomplete?"
        {...data}
      />
    );
  }
}

export default connect((state) => {
  const placementProgress = state.assessment.progress;
  const courseProgress = state.courseAssessment.progress;
  const isAssessmentFromCourse = state.courseAssessment.activeAssessment;
  const progress = isAssessmentFromCourse ? courseProgress : placementProgress;

  return {
    modal: state.ui.modal,
    progress,
  };
}, {})(AssessmentSubmit);
