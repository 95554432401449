import React, { Component } from "react";
import { connect } from "react-redux";
import { OPERATIONS } from "constants/common";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import { closeModal } from "store/state/ui/actions";
import { getPlacementAssessment, startCountdown } from "store/state/assessment/actions";

import FieldErrors from "../common/FieldErrors";
import {
  AssessmentPasscodeContainer,
  AssessmentIcon,
  AssessmentTitle,
  AssessmentForm,
  AssessmentFormActions,
} from "../../styles/components/AssessmentPasscodeForm";

import SplitField from "components/common/SplitField";
import { ASSESSMENT_PASSCODE_FORM } from "constants/messages";

class AssessmentPasscodeForm extends Component {
  state = {
    accessCode: "",
    isLoading: false,
    formErrors: {
      accessCode: {
        valid: false,
        message: ASSESSMENT_PASSCODE_FORM.ACCESS_CODE_REQUIRED,
      },
      formValid: false,
    },
    formSubmitted: false,
  };

  rules = {
    accessCode: {
      requiredMessage: ASSESSMENT_PASSCODE_FORM.ACCESS_CODE_REQUIRED,
    },
  };

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    const { accessCode } = this.state;
    const { closeModal, modal } = this.props;
    const { handleBeginAttempt } = modal.data;

    this.setState({ formSubmitted: true, isLoading: true });

    if (this.state.formErrors.formValid) {
      await handleBeginAttempt(accessCode);

      this.setState({ isLoading: false }, () => {
        closeModal();
      });
    }
  };

  getAccessCodeValue = (value) => {
    this.setState({ accessCode: value });
  };

  isAccessCodeValid = (isValid) => {
    this.setState({
      formErrors: {
        ...this.state.formErrors,
        accessCode: {
          valid: isValid,
          message: isValid ? "" : this.rules.accessCode.requiredMessage,
        },
        formValid: isValid,
      },
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <AssessmentPasscodeContainer>
        <AssessmentIcon>
          <IconSvgComponent iconPath="svg/lock-icon.svg" />
        </AssessmentIcon>

        <AssessmentTitle>An access code is required to start.</AssessmentTitle>

        {this.state.formSubmitted && (
          <FieldErrors
            id={"accessCodeErrorMsg"}
            formErrors={this.state.formErrors}
            apiErrorMessage={this.props.assessmentApiErrors[OPERATIONS.GET].message}
          />
        )}

        <AssessmentForm onSubmit={this.handleFormSubmit} disabled={isLoading}>
          <SplitField
            fieldName="accessCode"
            inputType="password"
            numberOfSlots={6}
            charactersPerSlot={1}
            getValue={this.getAccessCodeValue}
            isValid={this.isAccessCodeValid}
          />

          <AssessmentFormActions>
            <ButtonLink transparent onClick={() => this.props.closeModal()} type="button">
              Cancel
            </ButtonLink>

            <ButtonLink className="btn-submit" type="submit" danger hasIcon disabled={isLoading}>
              <span>Start</span>
              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          </AssessmentFormActions>
        </AssessmentForm>
      </AssessmentPasscodeContainer>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    assessmentApiErrors: state.assessment.apiErrors.assessment,
    apiCallInProgress: state.assessment.apiCallInProgress,
  }),
  {
    startCountdown,
    closeModal,
    getPlacementAssessment,
  }
)(AssessmentPasscodeForm);
