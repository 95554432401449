/**
 * @ The external dependecies
 */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/**
 * The internal dependencies.
 */
import { closeModal } from "store/state/ui/actions";
import { create } from "store/state/administration/actions";
import ModalFrame from "./ModalFrame";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import { AssessmentContainer } from "../../styles/components/SelectAssessment";
import {
  FormHead,
  FormLogo,
  FormActions
} from "styles/components/Form";

/**
 * Class for modal create user.
 *
 * @class      ModalCreateUser (name)
 */
class ModalShowErrorMessage extends React.Component {
  handleOnClick = () => {
    const { location, history } = this.props;
    if (this.props.modal.data.shouldRedirect) {
      history.push({
        pathname: this.props.modal.data.redirectTo,
        state: { referrer: this.props.modal.data.redirectTo },
      });
      this.props.closeModal();
    } else {
      this.props.closeModal();
    }
  };

  render() {
    return (
      <ModalFrame>
        <FormHead>
          <FormLogo>
            {/* <IconSvgComponent iconPath="svg/ico-user-limit-reached.svg" /> */}
          </FormLogo>
          <h3>Error Occurred</h3>
          <AssessmentContainer>{this.props.modal.data.errorMessage}</AssessmentContainer>
        </FormHead>
        <FormActions>
          <ButtonLink violet onClick={this.handleOnClick} type="button">
            Dismiss
          </ButtonLink>
        </FormActions>
      </ModalFrame>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    create,
    closeModal,
  }
)(withRouter(ModalShowErrorMessage));
