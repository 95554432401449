/**
 * @ External Dependecies
 */
import { css } from "styled-components";

/**
 * { The supported media queries }
 *
 * @type       {Object}
 */
const sizes = {
  desktop_md: 1280,
  desktop: 1200,
  desktop_min: 1250,
  tablet: 1024,
  tablet_mid: 768,
  tablet_portrait: 1023,
  mobile: 767,
  mobile_portrait: 375,
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});

export const mediaMin = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});
