/**
 * @ External Dependencies
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { getUserInfo } from "utilities/localStore";
import IconSvgComponent from "components/common/IconSvgComponent";
import { ROLES } from "constants/roles";
/**
 * @ Redux Actions
 */
import { toggleGlobalOverlay, openModal } from "store/state/ui/actions";
import { clearUserAccount } from "store/state/user/actions";
import { submitLogout } from "store/state/user/actions";

/**
 * @ Styled Components
 */
import ProfileWidget, {
  ProfileWidgetHolder,
  StyledUserIcon,
  UserEmail,
  UserDetails,
} from "styles/components/ProfileWidget";
import DropdownMenu, {
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuBody,
} from "styles/components/DropdownMenu";
import ButtonLink from "components/common/ButtonLink";

import GlobalOverlay from "styles/components/GlobalOverlay";
import { Dropdown, DropdownItem, DropdownButton } from "components/elements/TableDropdown";

class ProfileWidgetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDropdownActive: false,
      alertsDropdownActive: false,
      adminMenuOpen: false,
      adminMenuHoverItem: null,
      logoutSuccessful: null,
      /*
       *alertsDropdownActive: false,
       *profileDropdownActive: false
       */
    };

    this.submitLogout = this.props.submitLogout(this);
  }

  toggleAlertsDropdown = () => {
    this.setState(({ alertsDropdownActive }) => ({
      alertsDropdownActive: !alertsDropdownActive,
    }));

    this.props.toggleGlobalOverlay();
  };

  toggleProfileDropdown = () => {
    this.setState(({ profileDropdownActive }) => ({
      profileDropdownActive: !profileDropdownActive,
    }));
    this.props.toggleGlobalOverlay();
  };

  switchToParentAccount = () => {
    this.props.clearUserAccount().then(() => {
      this.props.history.push("/");
    });
  };

  toggleAdminMenu = () => {
    this.setState(({ adminMenuOpen }) => ({
      adminMenuOpen: !adminMenuOpen,
    }));
    this.props.toggleGlobalOverlay();
  };

  onAdminMenuMouseOver = (key) => () => {
    this.setState({
      adminMenuHoverItem: key,
    });
  };

  onAdminMenuMouseOut = () => {
    this.setState({
      adminMenuHoverItem: null,
    });
  };

  closeMenus = () => {
    this.setState({
      alertsDropdownActive: false,
      profileDropdownActive: false,
      adminMenuOpen: false,
      adminMenuHoverItem: null,
    });

    this.props.toggleGlobalOverlay();
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      e.target.parentElement.previousElementSibling !== null &&
        e.target.parentElement.previousElementSibling.firstElementChild.focus();
      e.preventDefault();
    } else if (e.keyCode === 40) {
      e.target.parentElement.nextElementSibling !== null &&
        e.target.parentElement.nextElementSibling.firstElementChild.focus();
      e.preventDefault();
    } else if (
      e.keyCode === 13 &&
      e.target.parentElement.previousElementSibling === null &&
      this.props.currentAccountName
    ) {
      this.switchToParentAccount();
    }
    if (e.keyCode === 9 && !e.shiftKey && e.target.parentElement.nextElementSibling === null) {
      e.preventDefault();
    }
  };

  render() {
    const { profileDropdownActive, logoutSuccess } = this.state;

    const { loggingOut, openModal, currentAccountName, accountName, user } = this.props;
    const userInfo = getUserInfo();
    const { name, email } = userInfo;

    return (
      <ProfileWidget>
        {logoutSuccess ? <Redirect to={"/login"} /> : null}
        <ProfileWidgetHolder>
          <DropdownMenu active={profileDropdownActive}>
            <DropdownMenuTrigger onClick={this.toggleProfileDropdown}>
              <ButtonLink isBtnUser>
                <StyledUserIcon>{name && name[0].toUpperCase()}</StyledUserIcon>
              </ButtonLink>
              <DropdownMenuContent small={user.role === ROLES.MASTER_ADMIN ? 1 : undefined}>
                {user.role != ROLES.MASTER_ADMIN && (
                  <DropdownMenuBody>
                    {" "}
                    {/* To be removed after demo, Not sure old dropdown still to be displayed for no master admin users. */}
                    <ButtonLink widgetButton onClick={this.submitLogout} disabled={loggingOut}>
                      Log Out
                    </ButtonLink>
                  </DropdownMenuBody>
                )}
                {user.role == ROLES.MASTER_ADMIN && (
                  <Dropdown>
                    <DropdownItem blue>
                      <DropdownButton fixColor borderOnFocus onKeyDown={this.handleKeyDown}>
                        <UserEmail>{email}</UserEmail>
                        <UserDetails>
                          {currentAccountName ? currentAccountName : accountName}
                          {currentAccountName && (
                            <span onClick={this.switchToParentAccount}>
                              <IconSvgComponent iconPath="svg/ico-close-white.svg" />
                            </span>
                          )}
                        </UserDetails>
                      </DropdownButton>
                    </DropdownItem>

                    <DropdownItem>
                      <DropdownButton
                        onClick={() => {
                          openModal({
                            type: "ModalAccountListing",
                            data: { modalSize: "xs" },
                          });
                        }}
                        handleLogoutWidgetFocus
                        onKeyDown={this.handleKeyDown}
                      >
                        Switch Accounts
                      </DropdownButton>
                    </DropdownItem>

                    <DropdownItem>
                      <DropdownButton
                        onClick={this.submitLogout}
                        disabled={loggingOut}
                        last
                        handleLogoutWidgetFocus
                        onKeyDown={this.handleKeyDown}
                      >
                        Log Out
                      </DropdownButton>
                    </DropdownItem>
                  </Dropdown>
                )}
              </DropdownMenuContent>
            </DropdownMenuTrigger>
          </DropdownMenu>

          <GlobalOverlay onClick={this.toggleProfileDropdown} visible={profileDropdownActive} />
        </ProfileWidgetHolder>
      </ProfileWidget>
    );
  }
}

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
ProfileWidgetComponent.propTypes = {
  userImage: PropTypes.string,
};

export default connect(
  (state) => ({
    user: state.user,
    tabsData: state.ui.tabsData,
    globalOverlayIsVisible: state.ui.globalOverlayIsVisible,
    loggingOut: state.user.loggingOut,
    currentAccountName: state.user.currentAccountName,
    accountName: state.user.accountName,
  }),
  (dispatch) => ({
    //toggle: selector => dispatch(toggle(selector)),
    clearUserAccount: () => dispatch(clearUserAccount()),
    openModal: ({ type, data }) => dispatch(openModal({ type, data })),
    toggleGlobalOverlay: () => dispatch(toggleGlobalOverlay()),
    submitLogout: (component) => async () => {
      await dispatch(submitLogout());
    },
  })
)(withRouter(ProfileWidgetComponent));
