import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { TabHead } from "styles/components/ModalCoursesAddToAssignment";

/**
 * getJustification - helper method for TabNav
 * @param { string } alignment Value representing the alignment of the tab navigation
 * @returns { string }
 */
const getJustification = (alignment) => {
  switch (alignment) {
    case "left":
      return "flex-start";

    case "center":
      return "center";

    case "right":
      return "flex-end";

    default:
      return alignment;
  }
};

/**
 * TabNav Element - Reusable Tab Navigation
 * @param { array } props.routes Array of tabroute objects
 * @example routes = [
 *  { name: "Tab 1", path: "/tab-1" },
 *  { name: "Tab 2", path: "/tab-2" },
 * ]
 * @param { string } props.alignment Alignment of the tab navigation
 */
const TabNav = ({ routes, alignment }) => {
  const history = useHistory();
  const justifyContent = getJustification(alignment);

  return (
    <TabHead>
      <ul style={{ justifyContent }}>
        {routes.map((tab) => (
          <li
            key={tab.name}
            className={classNames({ "is-active": history.location.pathname === tab.path })}
            onClick={() => history.push(tab.path)}
          >
            {tab.name}
          </li>
        ))}
      </ul>
    </TabHead>
  );
};

export default TabNav;
