/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import { media } from "../media";

export const ListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const List = styled.ul`
	list-style-type: none;
	padding: 15px;
	text-align: left;
	margin: 0;

	& > li {
		position: relative;
		max-width: 300px;
		margin: 0 auto;

		&:first-child {
			padding-top: 35px;
		}

		.flex {
			display: flex;
			align-items: center;

			.svg-icon {
				margin-right: 10px;
			}
		}

		ul {
			list-style-type: none;
			margin: 0;
			width: 100%;
		}

		.dropdown-wrapper {
			position: absolute;
			right: 0;
			top: 5px;
		}

		li {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}

		& > ul > li + li {
			margin-top: 10px;
		}
	}

	& > li + li {
		margin-top: 20px;
		padding-top: 35px
		border-top: 1px solid #ccc;
	}
	
	a {
		display: block;
		text-decoration: none;
		color: inherit;

		span {
			margin-left: 10px;
		}
		
		.svg-icon {
			display: inline-flex;
			align-items: center;
			vertical-align: middle;

			div {
				display: inline-flex;
				align-items: center;
			}
		}

		span {
			display: inline-flex;
			vertical-align: middle;
			line-height: 1.2;
		}
	}
`;

export const ListStats = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ListItem} {
    flex: 1 1 190px;
    display: block;
    border-right: 1px solid rgba(216, 216, 216, 0.5);

    &:last-child {
      border-right: none;
    }
  }

  ${(props) =>
    props.modified &&
    `
		padding: 20px 0;
	`}

  ${media.tablet_portrait`
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 0 -10px;

		${ListItem} {
			margin: 0 0 10px -1px;
			border-left: 1px solid rgba(216, 216, 216, 0.5);

			&:last-child {
				border-right: 1px solid rgba(216, 216, 216, 0.5);
			}
		}

		${(props) =>
      props.modified &&
      css`
        ${ListItem} {
          padding: 20px;
        }
      `}
	`};
`;

export default List;
