/**
 * @ External Dependecies
 */

import styled from "styled-components";
import ReactModal from "react-modal";
import { media } from "../media";

export const Modal = styled(ReactModal)`
  position: absolute;
  top: 50% !important;
  filter: blur(0);
  max-width: 796px;
  margin: 0 auto;
  left: 40px;
  right: 40px;
  border: none;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0;
  outline: none;
  padding: 0;
  transform: translateY(-50%);

  &.modal-tags {
    left: 0;
    right: 0;
  }

  ${media.mobile`
		left: 10px;
		right: 10px;
		max-height: 100vh;
		overflow-y: scroll;
    `}
`;

export const ModalHead = styled.header`
  background: #fff;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.mobile`
		padding: 10px 5px;
	`}
`;

export const AddTagButton = styled.button`
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  text-align: center;

  .svg-icon {
    font-size: 0;
  }
`;

export const AddTagButtonText = styled.span`
  color: #484848;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  margin-right: 13px;

  ${media.mobile`
		font-size: 20px;
	`}
`;

export const ModalClose = styled.button`
  background: none;
  border: none;
  font-size: 0;
  padding: 5px;
  margin-right: -5px;

  &.close-modal-absolute {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const ModalEntry = styled.div`
  background: #fff;
  text-align: center;
  padding: 66px 20px;
  color: #484848;

  .svg-icon {
    margin: 0 auto 19px;
  }

  .btn-add-tag .svg-icon {
    margin: 0 0 0 10px;
  }

  ${media.mobile`
		padding: 15px 10px;

		.svg-icon { margin: 0 auto 10px; }
	`}
`;

export const ModalEntryParagraph = styled.p`
  max-width: 400px;
  margin: 0 auto 24px;
  font-weight: 400;
  ${media.mobile`
		font-size: 14px;
	`}
`;

export const ModalEntryTitle = styled.h3`
  margin-bottom: 4px;
  font-weight: 500;

  ${media.mobile`
		font-size: 18px;
	`}
`;

export default Modal;
