import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { firstSigninResetPassword, getLoggedInStatus } from "store/state/user/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import { FORM_CREATE_PASSWORD_MESSAGES } from "constants/messages";
import Form, {
  FormBody,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
} from "styles/components/Form";
import FieldErrors from "../common/FieldErrors";
import { StyledGenericError, StyledError } from "styles/components/Form";
import {
  Wrapper,
  StyledFormWrapper,
  LoginWrapper,
  StyledHead,
  StyledBody,
  StyledTitle,
  StyledIconWrapper,
  StyledFieldset,
  StyledFormDescription,
} from "styles/components/Form";
import { validateField } from "utilities/validation";

class FormCreatePassword extends Component {
  state = {
    passwordOne: "",
    passwordTwo: "",
    loading: false,
    formErrors: {
      passwordOne: {
        valid: true,
        message: "",
      },
      passwordTwo: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
    formSubmitted: false,
  };

  rules = {
    passwordOne: {
      requiredMessage: FORM_CREATE_PASSWORD_MESSAGES.PASSWORD,
      // eslint-disable-next-line no-useless-escape
      expression: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{6,}$/,
    },
    passwordTwo: {
      requiredMessage: FORM_CREATE_PASSWORD_MESSAGES.REPEAT_PASSWORD_REQUIRED,
    },
  };

  passwordOneRef = React.createRef();
  passwordTwoRef = React.createRef();

  handleFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value.trim() });
  };

  componentWillMount() {
    this.props.getLoggedInStatus();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { passwordOne } = this.state;
    const handleSubmissionCallback = async (formErrors) => {
      if (formErrors.formValid) {
        this.setState({
          formSubmitted: true,
        });
        var resp = await this.props.firstSigninResetPassword({
          session: this.props.session,
          username: this.props.username,
          password: passwordOne,
        });
        if (resp.status) {
          this.props.history.push({
            pathname: "/login",
            state: { referrer: this.props.location.pathname },
          });
        }
      } else {
        this.setState({
          formSubmitted: false,
        });
      }
    };
    this.validateAllFields(handleSubmissionCallback);
  };

  validateAllFields = (callback) => {
    const { formErrors } = this.state;

    let formErrorMessages = {};
    formErrorMessages.formValid = true;

    const passwordOneElement = document.getElementById("passwordOne");
    const { passwordOne } = validateField(formErrors, this.rules, passwordOneElement, "", true);
    formErrorMessages.passwordOne = passwordOne;

    if (!passwordOne.valid) {
      formErrorMessages.passwordLength = {
        valid: false,
        message: "Password must contain at least 6 characters",
      };
      formErrorMessages.passwordLowerCase = {
        valid: false,
        message: "Password must contain at least 1 lower case letter",
      };
      formErrorMessages.passwordUpperCase = {
        valid: false,
        message: "Password must contain at least 1 upper case letter",
      };
      formErrorMessages.passwordNumber = {
        valid: false,
        message: "Password must contain at least 1 number",
      };
      formErrorMessages.passwordSymbol = {
        valid: false,
        message: `Password must contain at least 1 special character`,
      };
      formErrorMessages.passwordOne = { valid: true, message: "" }; // tricking validator
      formErrorMessages.formValid = false;
    }

    const passwordTwoElement = document.getElementById("passwordTwo");
    const { passwordTwo } = validateField(formErrors, this.rules, passwordTwoElement, "", true);
    formErrorMessages.passwordTwo = passwordTwo;

    const passwordsMatch = this.state.passwordTwo === this.state.passwordOne;
    if (!passwordsMatch) {
      formErrorMessages.passwordTwo = {
        valid: false,
        message: "Passwords must match",
      };
      formErrorMessages.formValid = false;
    }

    this.setState({ formErrors: formErrorMessages }, () => {
      callback(this.state.formErrors);
    });
  };

  render() {
    const { formErrors, formSubmitted } = this.state;
    const { apiCallInProgress, session } = this.props;
    return (
      <Wrapper>
        {formSubmitted != true && !session ? <Redirect to={"/login"} /> : ""} }
        <LoginWrapper>
          <StyledFormWrapper>
            <StyledHead>
              <StyledIconWrapper>
                <IconSvgComponent additionalClass="login" iconPath="svg/stemify-logo-login.svg" />
              </StyledIconWrapper>

              <StyledTitle top={25}>Create Password</StyledTitle>
              <StyledFormDescription>
                Your password must contain at least 6 characters, 1 special character, 1 number, and
                1 upper case.
              </StyledFormDescription>
            </StyledHead>

            {formSubmitted && this.props.createPasswordApiError.code !== -1 && (
              <StyledGenericError>{this.props.createPasswordApiError.message}</StyledGenericError>
            )}
            <MediaQuery maxWidth={1023}>
              <FieldErrors formErrors={formErrors}></FieldErrors>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              <FieldErrors formErrors={formErrors}></FieldErrors>
            </MediaQuery>

            <StyledBody>
              <Form onSubmit={this.handleSubmit}>
                <StyledFieldset disabled={this.state.loading}>
                  <FormBody>
                    <FormRowGrid>
                      <FormCol>
                        <FormControls>
                          <Field
                            customRef={this.passwordOneRef}
                            id="passwordOne"
                            type="password"
                            name="passwordOne"
                            onChange={this.handleFieldChange}
                            value={this.state.passwordOne}
                            placeholder="Password"
                            required
                            error={!this.state.formErrors.passwordOne.valid}
                          />
                          {formErrors.passwordLength && formErrors.passwordLength.message && (
                            <StyledError>Password is weak</StyledError>
                          )}
                        </FormControls>
                        <FormControls>
                          <Field
                            customRef={this.passwordTwoRef}
                            id="passwordTwo"
                            type="password"
                            name="passwordTwo"
                            onChange={this.handleFieldChange}
                            value={this.state.passwordTwo}
                            placeholder="Repeat Password"
                            required
                            error={!this.state.formErrors.passwordTwo.valid}
                          />
                          {formErrors.passwordTwo.message && (
                            <StyledError>{formErrors.passwordTwo.message}</StyledError>
                          )}
                        </FormControls>
                      </FormCol>
                    </FormRowGrid>
                  </FormBody>

                  <FormActions>
                    <ButtonLink
                      id="btnCreatePassword"
                      type="submit"
                      violet
                      disabled={apiCallInProgress}
                    >
                      <span>Create Password</span>
                    </ButtonLink>
                  </FormActions>
                </StyledFieldset>
              </Form>
            </StyledBody>
          </StyledFormWrapper>
        </LoginWrapper>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    username: state.user.username,
    session: state.user.session,
    createPasswordApiError: state.user.apiErrors.firstSignInResetPassword,
    loggedIn: state.user.loggedIn,
    firstTimeLogin: state.user.firstTimeLogin,
    apiCallInProgress: state.user.apiCallInProgress,
  }),
  (dispatch) => ({
    firstSigninResetPassword: async ({ session, username, password }) => {
      return dispatch(await firstSigninResetPassword({ session, username, password }));
    },
    getLoggedInStatus: () => dispatch(getLoggedInStatus()),
  })
)(FormCreatePassword);
