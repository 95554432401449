import {
  facultyDeploymentAssessmentStudentListPath,
  facultyDeploymentAssessmentStatsPath,
  deploymentsPath,
  deploymentAssessmentPath,
  studentPath,
  attemptPath,
} from "constants/api";
import { fetchAPICall, apiUrl } from "utilities/API";
import { getAccessToken } from "utilities/localStore";
import user from "store/state/user/reducer";

export const callGetDeploymentDashboardAPI = async ({ accountId, deploymentId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${deploymentsPath}/${deploymentId}/stat`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const getFacultyDeploymentAssessmentStats = async ({
  deploymentId,
  assessmentId,
  account_id,
}) => {
  const studentListPath = facultyDeploymentAssessmentStatsPath(deploymentId, assessmentId);
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: studentListPath,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id,
    },
  });
};

export const getFacultyDeploymentStudentList = async ({
  deploymentId,
  assessmentId,
  page,
  pageSize,
  userScore,
  account_id,
}) => {
  const studentListPath = facultyDeploymentAssessmentStudentListPath(deploymentId, assessmentId);
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${studentListPath}?page=${page}&pageSize=${pageSize}${
      userScore != null ? `&user_score=${userScore}` : ""
    }`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id,
    },
  });
};

export const callGetAssessmentStudentDashboardAPI = async ({
  accountId,
  deploymentId,
  assessmentId,
  studentId,
}) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${deploymentsPath}/${deploymentId}${deploymentAssessmentPath}/${assessmentId}${studentPath}/${studentId}/stat`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const callGetStudentAttemptDashboardAPI = async ({ accountId, assessmentId, attemptId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${deploymentAssessmentPath}/${assessmentId}${attemptPath}/${attemptId}/stat`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

export const callGetAttemptTopicPerformanceAPI = async ({ accountId, attemptId, topicLevel }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/${topicLevel}/performance/${attemptId}`, // topicLevel = tag | topic
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};
