import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { StudentBreakdownCard } from "components/card/StudentBreakdownCard";
import { getCourseAssessmentStudentBreakdownData as getCourseAssessmentStudentBreakdownDataAction } from "store/state/courses/actions";

const columns = [
  {
    id: "studentName",
    Header: "Student Name",
    accessor: "studentName",
  },
  {
    id: "attempts",
    Header: "# of attempts",
    accessor: "attempts",
    Cell: (props) => {
      return props.value || "—";
    },
  },
  {
    id: "topScore",
    Header: "Top Score",
    accessor: "topScore",
    Cell: (props) => {
      return `${props.value}%`;
    },
  },
  {
    id: "latestAttempt",
    Header: "Latest Attempt",
    accessor: "latestAttempt",
  },
];

const mobileColumns = (student) => ({
  studentName: {
    title: "Student Name",
    value: student.studentName,
  },
  attempts: {
    title: "# of Attempts",
    value: student.attempts,
  },
  topScore: {
    title: "Top Score",
    value: student.topScore,
  },
  latestAttempt: {
    title: "Latest Attempt",
    value: student.latestAttempt,
  },
});

export const AssessmentStudentBreakdownCard = ({ assignmentId }) => {
  const dispatch = useDispatch();
  const getCourseAssessmentStudentBreakdownData = useCallback(() => {
    return dispatch(getCourseAssessmentStudentBreakdownDataAction({ assignmentId }));
  }, [assignmentId, dispatch]);

  return (
    <StudentBreakdownCard
      getDataCallback={getCourseAssessmentStudentBreakdownData}
      columns={columns}
      mobileColumns={mobileColumns}
    />
  );
};

export const AssessmentStudentBreakdownCardWithDuration = ({ assignmentId }) => {
  const dispatch = useDispatch();
  const getCourseAssessmentStudentBreakdownData = useCallback(() => {
    return dispatch(getCourseAssessmentStudentBreakdownDataAction({ assignmentId }));
  }, [assignmentId, dispatch]);

  return (
    <StudentBreakdownCard
      getDataCallback={getCourseAssessmentStudentBreakdownData}
      columns={[
        ...columns,
        {
          id: "duration",
          Header: "Duration Of Latest",
          accessor: "duration",
        },
      ]}
      mobileColumns={(student) => ({
        ...mobileColumns(student),
        duration: {
          title: "Duration Of Latest",
          value: student.duration,
        },
      })}
    />
  );
};
