/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import { media } from "../media";
import { Pill } from "./Btns";
import { Wrapper } from "components/elements/TableDropdown";
import theme from "../theme";

export const FrameTitle = styled.h4`
  margin-bottom: ${(props) => props.mb || "0"}px;
  padding: 20px 27px 13px;
  line-height: 1.2;

  ${(props) =>
    props.size &&
    `
		font-size: ${props.size}px;
	`}

  ${(props) =>
    props.white &&
    `
		color: ${theme.color.white}
    `}

    ${(props) =>
      props.marginleft &&
      `
		margin-left: 19px;
	`}
`;

export const FrameHolder = styled.div`
  padding: 0 27px 33px;
`;

export const FrameInner = styled.div``;
export const FrameAside = styled.div``;
export const FrameContent = styled.div``;
export const FrameCaption = styled.h3`
  font-size: 26px;
  line-height: 27px;
  margin-bottom: 8px;
  color: ${theme.color.darker_gray};

  ${(props) =>
    props.nomargin &&
    `
		margin: 0px;
	`}
`;
export const FrameHeading = styled.h5`
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 10px !important;
  color: ${theme.color.darker_gray};
`;
export const FrameLabel = styled.span`
  font-size: 11px;
  font-weight: bold;
  color: ${theme.color.darker_gray};

  ${(props) =>
    props.caps &&
    `
		text-transform: uppercase;
	`}
`;

export const FrameValue = styled.span`
  color: ${theme.color.darker_gray};
  font-weight: bold;
  height: 33px;
  width: 65px;
  font-size: 33px;
  line-height: 33px;

  ${(props) =>
    props.small &&
    `
		height: 16px;
		width: 90px;
		font-size: 14px;
		line-height: 16px;
	`}

  ${(props) =>
    props.upWithGap &&
    `
		padding-left: 10px;
		position: relative;
		top: -7px;
	`}
`;

export const FrameSpan = styled.span`
	color: ${theme.color.darker_gray};
	font-size: 11px;
	letter-spacing: 0.44px;
	color: ${theme.color.darker_gray};

	${(props) =>
    props.block &&
    `
		display: block;
  `}

  ${(props) =>
    props.inlineblock &&
    `
		display: inline-block;
	`}

	${(props) =>
    props.padding &&
    `
		padding: 8px;
  `}

  ${(props) =>
    props.percent &&
    `
		width: ${props.percent}%;
	`}
`;

export const FrameList = styled.ul`
  list-style-type: none;
`;

export const FrameListItem = styled.li`
  cursor: pointer;

  &:hover {
    font-weight: bold;
    color: #4a4a4a;
  }
`;

const Frame = styled.div`
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

  ${(props) =>
    props.programChart &&
    css`
      ${FrameTitle} {
        padding: 20px 27px 0;
      }

      ${FrameInner} {
        display: flex;
        padding: 0 31px 21px;
      }

      ${FrameAside} {
        max-width: 240px;
        flex: 0 0 240px;
        height: 240px;
        margin: 18px 70px 0 0;
      }

      ${FrameContent} {
        max-width: 100%;
        flex: 1 1 100%;
        margin-top: -11px;
      }

      ${FrameList} {
        height: 270px;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 12px;
        line-height: 2.5;
        color: #a3aaad;
      }

      ${media.mobile`
			${FrameInner} {
				display: block;
				padding: 0 15px 21px;
			}

			${FrameAside} {
				margin: 18px auto 0;
			}

			${FrameContent} {
				margin-top: 0;
			}
		`};
    `}
`;

export const FrameCell = styled.div`
	word-break: break-word;

	${(props) =>
    props.sideGap &&
    `
		padding-left: 26px;
		padding-right: 19px;
	`}

    ${(props) =>
      props.borderRadius &&
      `border-bottom-left-radius: ${theme.border_radius};
        border-bottom-right-radius: ${theme.border_radius};`}

	${(props) =>
    props.padding &&
    `
		padding: 20px;
	`}

	${(props) =>
    props.verticalPadding &&
    `
		padding: 20px 10px 20px 10px;
	`}

	${(props) =>
    props.flex &&
    `
		display: flex;
	`}

	${(props) =>
    props.flexColumn &&
    `
		flex-direction: column;
	`}

	${(props) =>
    props.percent &&
    `
		width: ${props.percent}%;
	`}

	${(props) =>
    props.variant === "primary" &&
    `
		background: ${theme.color.sky_blue};
		color: ${theme.color.white};
	`}

	${(props) =>
    props.float &&
    `
		float: right;
	`}

	${(props) =>
    props.alignmiddle &&
    `
		margin-top: auto;
		margin-bottom: auto;
	`}
`;

export const CourseFrameTitle = styled(FrameTitle)`
  font-family: Circular Pro;
  font-size: 16px;
  font-weight: 200;
  text-transform: uppercase;
  padding: 0;
  margin-top: -1px;
`;

export const FrameBox = styled(Frame)`
  position: relative;
	padding: 20px 28px;
	border-radius: ${theme.border_radius};

	${(props) =>
    props.flex &&
    `
		display: flex;
	`}

	${(props) =>
    props.noPadding &&
    `
		padding: 0px;
	`}

	${FrameTitle}, ${CourseFrameTitle} {
		padding: 0;
		margin-bottom: 13px;
		font-size: 18px;
	}

	${(props) =>
    props.variant === "primary" &&
    `
		background: ${theme.color.sky_blue};
		color: ${theme.color.white};

		${FrameTitle} {
			color: #C8E7FF;
		}
    `}

     ${(props) =>
       props.horzontalPadding &&
       `
        padding-left: 5px !important;
        padding-right: 5px !important;

	`}

  ${Wrapper} {
    position: absolute;
    right: 15px;
    margin-right: 0;

    > button {
      height: auto;
      margin: 0 -9px 0 0;
      transform: rotate(90deg);

      span {
        background: ${theme.color.gray};
      }
    }
  }

`;

export const QuestionAnswer = styled.article`
  margin-top: 25px;
`;

export const Question = styled.h5`
  margin-bottom: 4px;
`;

export const Answer = styled.section``;

export const AnswerPara = styled.p`
  margin-bottom: 15px;
`;

export const AnswerList = styled.ul`
  margin-top: 20px;
  margin-left: 35px;
`;

export const AnswerListItem = styled.li``;

export const Hyperlink = styled.a`
  color: ${theme.color.royal_blue};
  text-decoration: none;

  ${(props) =>
    props.email &&
    `
		color: ${theme.color.darker_gray} !important;
	`}

  ${(props) =>
    props.caption &&
    `
		font-size: 16px;
	`}
`;

export const StrongText = styled.strong`
  ${(props) =>
    props.underlined &&
    `
		text-decoration: underline;
	`}
`;

export default Frame;
