/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import FormDeleteBuilderQuestion from "components/form/FormDeleteBuilderQuestion";

/**
 * @class      ModalActivation (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */
const ModalDeleteBuilderQuestion = (props) => {
  return (
    <ModalFrame>
      <FormDeleteBuilderQuestion />
    </ModalFrame>
  );
};

export default ModalDeleteBuilderQuestion;
