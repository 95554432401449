/**
 * The external dependencies.
 */
import React, { Component } from "react";

import AutosizeInput from "react-input-autosize";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import BuilderAnswers from "./BuilderAnswers";

import TitleEditable, {
  TitleContent,
  TitleField,
  ButtonEdit,
  TitleHolder,
} from "styles/components/TitleEditable";
import {
  BuilderQuestion,
  BuilderQuestionBar,
  BuilderQuestionBarActions,
  BuilderQuestionName,
  BuilderQuestioContent,
  BuilderQuestioContentActions,
  BuilderQuestioContentEntry,
  BuilderQuestioImagePreview,
  BuilderQuestioFoot,
} from "styles/components/BuilderQuestion";
import Tooltip from "styles/components/Tooltip";
import SelectField from "styles/components/SelectField";

class BuilderQuestionBlock extends Component {
  state = {
    editTitle: false,
    liveLatexStatus: false,
    questionName: "",
    questionLabel: "",
    questionText: "",
    answers: [],
    selectedOption: {
      value: "multiple-choice",
      label: (
        <div className="option">
          <span>Muliple Choice</span>
        </div>
      ),
    },
    options: [
      {
        value: "multiple-choice",
        label: (
          <div className="option">
            <span>Muliple Choice</span>
          </div>
        ),
      },
      {
        value: "open-text",
        label: (
          <div className="option">
            <span>Open Text</span>
          </div>
        ),
      },
    ],
  };

  componentDidMount() {
    const { question, questionIndex } = this.props;
    this.typingTimer = null;

    this.setState({
      questionName: question.name || `Question Name`,
      questionLabel: `Question ${questionIndex}`,
      questionText: question.text,
      questionImageSrc: question.questionImageSrc,
      answers: question.answers,
      liveLatexStatus: question.liveLatexStatus,
    });
  }

  componentDidUpdate(prevProps) {
    const { question, questionIndex } = this.props;

    if (question !== prevProps.question || questionIndex !== prevProps.questionIndex) {
      this.setState({
        questionName: question.name || `Question Name`,
        questionLabel: `Question ${questionIndex}`,
        questionText: question.text,
        questionImageSrc: question.questionImageSrc,
        answers: question.answers,
        liveLatexStatus: question.liveLatexStatus,
      });
    }
  }

  handleTitleChange = (e) => {
    const target = e.target;

    this.setState({
      questionName: target.value,
    });
  };

  toggleEdit = (e) => {
    e.stopPropagation();
    this.setState({ editTitle: !this.state.editTitle });
  };

  titleEdit = (e) => {
    this.setState({ editTitle: true });
  };

  handleNameFocusOut = (event) => {
    this.setState({ editTitle: false });

    this.props.handleQuestionUpdate({
      name: this.state.questionName,
    });
  };

  handleNameKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleNameFocusOut(event);
    }
  };

  handleTextChange = (e) => {
    const element = e.nativeEvent.target ? e.nativeEvent.target : e.target;
    clearTimeout(this.typingTimer);

    this.setState({
      questionText: element.value,
    });

    this.typingTimer = setTimeout(() => {
      this.handleTextFocusOut(e);
    }, 300);
  };

  handleTextFocusOut = (event) => {
    this.props.handleQuestionUpdate({
      text: this.state.questionText,
    });
  };

  handleTextKeyDown = (event) => {
    if (event.key === "Enter") {
      this.handleTextFocusOut(event);
    }
  };

  deleteQuestion = (e) => {
    this.props.handleDelete();
  };

  toggleLiveLatexState = (e) => {
    this.setState({ liveLatexStatus: !this.state.liveLatexStatus }, () => {
      this.props.handleQuestionUpdate({
        liveLatexStatus: this.state.liveLatexStatus,
      });
    });
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleTagsClick = (e) => {
    this.props.onTagsTriggerClick();
  };

  handleAnswerTagsClick = (data, index) => {
    this.props.onAnswerTagsTriggerClick(data, index);
  };

  render() {
    const {
      editTitle,
      liveLatexStatus,
      questionName,
      questionLabel,
      questionText,
      questionImageSrc,
      selectedOption,
      options,
      answers,
    } = this.state;

    const { allowTitleEdit } = this.props;

    return (
      <BuilderQuestion>
        <BuilderQuestionBar>
          <BuilderQuestionName noTitleEdit={allowTitleEdit}>
            <TitleContent>{questionLabel}</TitleContent>

            {!allowTitleEdit && (
              <TitleEditable isBlock editMode={editTitle} onClick={(e) => this.titleEdit(e)}>
                {!editTitle ? (
                  <TitleHolder>
                    <TitleContent> {questionName} </TitleContent>

                    <IconSvgComponent iconPath="svg/ico-pencil.svg" />
                  </TitleHolder>
                ) : (
                  <TitleField isAlt isQuestion>
                    <AutosizeInput
                      name="form-field-name"
                      value={questionName}
                      onChange={(e) => this.handleTitleChange(e)}
                      onBlur={this.handleNameFocusOut}
                      onKeyDown={this.handleNameKeyDown}
                    />
                  </TitleField>
                )}
              </TitleEditable>
            )}
          </BuilderQuestionName>

          <BuilderQuestionBarActions>
            <SelectField
              classNamePrefix="react-select"
              className="selectToggle"
              closeMenuOnSelect={true}
              isMulti={false}
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
            />

            <ButtonLink
              className="question-btn"
              isUnstyledButton
              onClick={() => this.toggleLiveLatexState()}
            >
              <span>Live Latex {liveLatexStatus ? "On" : "Off"}</span>

              {liveLatexStatus && <IconSvgComponent iconPath="svg/ico-eye.svg" />}
              {!liveLatexStatus && <IconSvgComponent iconPath="svg/ico-eye-off.svg" />}
            </ButtonLink>

            <ButtonLink
              className="question-btn question-btn--delete"
              isUnstyledButton
              onClick={() => this.deleteQuestion()}
            >
              <span>Delete</span>

              <IconSvgComponent iconPath="svg/ico-close-thick.svg" />
            </ButtonLink>
          </BuilderQuestionBarActions>
        </BuilderQuestionBar>

        <BuilderQuestioContent>
          <BuilderQuestioContentActions>
            <ButtonEdit
              isAlt
              data-tip="Apply Tags"
              data-place={"bottom"}
              onClick={(e) => this.handleTagsClick(e)}
            >
              <IconSvgComponent iconPath="svg/ico-tags-lg.svg" />
            </ButtonEdit>

            <ButtonLink isUnstyledButton>
              <IconSvgComponent iconPath="svg/ico-font-bold.svg" />
            </ButtonLink>

            <ButtonLink isUnstyledButton>
              <IconSvgComponent iconPath="svg/ico-font-italic.svg" />
            </ButtonLink>

            <ButtonLink isUnstyledButton>
              <IconSvgComponent iconPath="svg/ico-font-underline.svg" />
            </ButtonLink>

            <ButtonEdit
              isAlt
              data-tip="Add Image"
              data-place={"bottom"}
              onClick={(e) => this.props.onImageUploadClick(e)}
            >
              <IconSvgComponent iconPath="svg/ico-picture.svg" />
            </ButtonEdit>

            <Tooltip />
          </BuilderQuestioContentActions>

          {questionImageSrc && (
            <BuilderQuestioImagePreview>
              <img src={questionImageSrc} alt="" />
            </BuilderQuestioImagePreview>
          )}

          <BuilderQuestioContentEntry>
            <Field
              id="questionContent"
              onChange={(e) => this.handleTextChange(e)}
              value={questionText ? questionText : ""}
              placeholder="Type your question here."
              onBlur={this.handleTextFocusOut}
              onKeyDown={this.handleTextKeyDown}
            />
          </BuilderQuestioContentEntry>
        </BuilderQuestioContent>

        <BuilderQuestioFoot>
          {answers.length > 0 && (
            <BuilderAnswers
              answers={answers}
              onTagsClick={(data, index) => this.handleAnswerTagsClick(data, index)}
              onReorder={(items) => this.props.onAnswersReorder(items)}
            />
          )}

          <ButtonLink
            isUnstyledButton
            className="btn-add-answer"
            onClick={this.props.onCreateAnswer}
          >
            <IconSvgComponent iconPath={`svg/ico-plus.svg`} additionalClass={"icon-add"} />

            <span>Add Answer</span>
          </ButtonLink>
        </BuilderQuestioFoot>
      </BuilderQuestion>
    );
  }
}

export default BuilderQuestionBlock;
