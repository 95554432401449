import stemifyRoutes from "./routes";

export const OPERATIONS = {
  LIST: "list",
  CREATE: "create",
  COPY: "copy",
  UPDATE: "update",
  CHANGE_STATUS: "changeStatus",
  GET: "get",
  REMOVE: "remove",
  DEACTIVATE: "deactivate",
  ATTACH_TO_ENTITY: "attachToEntity",
};

export const FORMAT = {
  DATE_DEPLOYMENT: "MM/DD/YYYY",
  TIME_DEPLOYMENT: "\\at hh:mm A",
  DATETIME_DEPLOYMENT: "M.D.YY / hh:mm A",
  SHORT_DATE: "M.D.YY",
  TIME_DURATION: "hh:mm:ss",
};

export const percentageColorRange = {
  lessThanTen: "#FCCD26",
  lessThanTwenty: "#F89242",
  lessThanThirty: "#A0A0A0",
  lessThanFourty: "#F9F9F9",
  lessThanFifty: "#F86742",
  lessThanSixty: "#A50099",
  lessThanSeventy: "#C5CE26",
  lessThanEighty: "#371D1A",
  lessThanNinty: "#E62499",
  lessThanHundred: "#657B9A",
  hundredPercent: "#d82237",
};

export const barChartColors = Array.from({ length: 101 }, (_, idx) => {
  if (idx <= 25) {
    return "#FF9F4D";
  }
  if (idx <= 35) {
    return "#FF953A";
  }
  if (idx <= 50) {
    return "#FF6F74";
  }
  if (idx <= 60) {
    return "#FF5F65";
  }
  if (idx <= 70) {
    return "#DC408B";
  }
  if (idx <= 75) {
    return "#DF5396";
  }
  if (idx <= 100) {
    return "#9242A1";
  }
});

export const customChartColors = (count) => {
  return Array.from({ length: count }, (_, idx) => {
    if (idx <= Math.round(count * 0.25)) {
      return "#FF9F4D";
    }
    if (idx <= Math.round(count * 0.35)) {
      return "#FF953A";
    }
    if (idx <= Math.round(count * 0.5)) {
      return "#FF6F74";
    }
    if (idx <= Math.round(count * 0.6)) {
      return "#FF5F65";
    }
    if (idx <= Math.round(count * 0.7)) {
      return "#DC408B";
    }
    if (idx <= Math.round(count * 0.75)) {
      return "#DF5396";
    }
    if (idx <= count) {
      return "#9242A1";
    }
  });
};

export const dataTableItems = ["accounts", "users", "cohorts", "students", "deployments"];

export const practiceAssignmentPath = stemifyRoutes.practiceAssignment;

export const SCORE_HISTORY_BAR_CHART_STEP_SIZE = 10;

export const progressWheelColorRange = [
  {
    color: percentageColorRange.lessThanTen,
  },
  {
    color: percentageColorRange.lessThanTwenty,
  },
  {
    color: percentageColorRange.lessThanThirty,
  },
  {
    color: percentageColorRange.lessThanFourty,
  },
  {
    color: percentageColorRange.lessThanFifty,
  },
  {
    color: percentageColorRange.lessThanSixty,
  },
  {
    color: percentageColorRange.lessThanSeventy,
  },
  {
    color: percentageColorRange.lessThanEighty,
  },
  {
    color: percentageColorRange.lessThanTwenty,
  },
  {
    color: percentageColorRange.lessThanNinty,
  },
  {
    color: percentageColorRange.hundredPercent,
  },
];

export const CalculateProgressIndicatorColor = (percentage, progressWheelColorRange) => {
  return progressWheelColorRange[Math.floor(percentage / 10)].color;
};
export const googleAnalysticsCode = "UA-168776710-2";

export const ASSIGNMENT_TYPES = {
  VIDEO: "Video",
  LINK: "Link",
  PRACTICE: "Practice",
  HOMEWORK: "Homework",
  QUIZ: "Quiz",
  EXAM: "Exam",
  OPENSTAX: "OpenStax",
};

export const ASSESSMENT_TYPES = {
  PRACTICE: "Practice Assessment",
  PLACEMENT: "Placement Assessment",
  EXAM: "Exam",
};

export const ATTEMPT_STATUSES = {
  OPEN: "open",
  CLOSED: "close",
};
