/**
 * @ External Dependecies
 */
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

const Tooltip = styled(ReactTooltip)`
  padding: 3px 14px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  background: #4a4a4a !important;

  &.transform-none {
    text-transform: none;
  }

  &:after {
    display: none;
  }
`;

export default Tooltip;
