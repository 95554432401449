import {
  contentType,
  studentPerformanceDashboardPath,
  studentAssessmentSpecificPerformancePath,
} from "../constants/api";
import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";

export const getStudentPerformanceData = async () => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: studentPerformanceDashboardPath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  })
};

export const getStudentSelectedAssessmentDetails = async (assessmentId) => {
  const assessmentPath = studentAssessmentSpecificPerformancePath(assessmentId);
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assessmentPath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};
