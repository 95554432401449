/**
 * The external dependencies.
 */
import React, { Component } from "react";

import styled from "styled-components";
import ButtonLink from "components/common/ButtonLink";
import theme from "styles/theme";
import { media } from "styles/media";
import IconSvgComponent from "components/common/IconSvgComponent";

/**
 * Class for Version dropdown.
 *
 * @class      TableDropdown (name)
 */
class VersionDropdown extends Component {
  state = {
    isActive: false,
    selected: "",
  };

  toggle = (e) => {
    e.preventDefault();

    this.setState(({ isActive }) => ({
      isActive: !isActive,
    }));
  };

  close = () => {
    this.setState({
      isActive: false,
    });
  };

  documentClick = (e) => {
    const el = this.dropdownWrapper;
    const target = e.target;

    if (el !== target && !el.contains(target)) {
      this.close();
    }
  };

  handleDropdownClick = (element) => (e) => {
    e.preventDefault();

    this.props.onActivate(element.id);

    this.setState({
      selected: element.btnText,
    });

    this.close();
  };

  componentDidMount() {
    document.addEventListener("click", this.documentClick);

    const selectedItem = this.props.options.find((option) => option.isSelected === true);

    if (selectedItem) {
      this.setState({
        selected: selectedItem.btnText,
      });
    } else {
      this.setState({
        selected: "Which Version",
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.documentClick);
  }

  render() {
    const { status, options } = this.props;
    const { selected } = this.state;

    return (
      <Wrapper className="dropdown-holder" innerRef={(el) => (this.dropdownWrapper = el)}>
        <ButtonLink className="dropdown-btn" onClick={this.toggle}>
          <span>
            <IconSvgComponent iconPath="svg/ico-dots-square.svg" />
            {selected}
          </span>

          <IconSvgComponent iconPath="svg/ico-select-caret-white.svg" />
        </ButtonLink>

        <DropdownWrapper active={this.state.isActive}>
          <Dropdown>
            <DropdownItem>
              <DropdownButton onClick={() => this.props.onCreate()}>
                Add New Version
                <IconSvgComponent additionalClass={"icon-add"} iconPath={`svg/ico-plus.svg`} />
              </DropdownButton>
            </DropdownItem>

            {options.map((item, idx) => (
              <DropdownItem
                key={`dropdown-item-${idx}`}
                first={0 === idx}
                last={options.length - 1 === idx}
              >
                <DropdownButton onClick={this.handleDropdownClick(item)}>
                  {item.btnText}
                </DropdownButton>

                <DropdownMoreActions>
                  <ButtonLink type="button" isFrameLink onClick={() => this.props.onCopy(item.id)}>
                    <IconSvgComponent iconPath="svg/ico-copy.svg" />
                  </ButtonLink>

                  <ButtonLink
                    type="button"
                    isFrameLink
                    onClick={() => this.props.onDelete(item.id)}
                  >
                    <IconSvgComponent iconPath="svg/ico-close-thick.svg" />
                  </ButtonLink>
                </DropdownMoreActions>
              </DropdownItem>
            ))}
          </Dropdown>
        </DropdownWrapper>
      </Wrapper>
    );
  }
}

export const Wrapper = styled.div`
  margin-right: 20px;
  position: relative;

  .dropdown-btn {
    background: ${theme.color.gray};
    color: ${theme.color.white};
    padding: 10px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 196px;
    min-width: 196px;

    span {
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: 10px;
      }
    }

    ${media.desktop`
      padding: 10px;
      width: 175px;
      min-width: 175px;
    `}

    ${media.mobile`
      width: 100%;
      min-width: 0;
    `}
  }

  ${media.desktop`
    margin-right: 10px;
  `}

  ${media.mobile`
    margin-right: 0;
    margin-bottom: 15px;
  `}
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  border-radius: ${theme.border_radius};
  right: -32px;
  top: calc(100% + 10px);
  width: 263px;
  max-width: 263px;
  background: #fff;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5), 0 41px 51px -6px rgba(0, 0, 0, 0.27);
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  transition: visibility 0.3s, opacity 0.3s, z-index 0.3s;

  &:before {
    content: "";
    position: absolute;
    z-index: 5;
    bottom: calc(100% - 12px);
    right: 50px;
    width: 20px;
    height: 22px;
    background: #fff;
    transform: rotate(-45deg);
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5), 0 41px 51px -6px rgba(0, 0, 0, 0.27);
  }

  ${(props) =>
    props.active &&
    `
		visibility: visible;
		opacity: 1;
		z-index: 15;
    pointer-events: auto;
	`}
`;

export const DropdownMoreActions = styled.div`
  position: absolute;
  top: 50%;
  right: 19px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  opacity: 0;
  visibility: visible;
  transition: opacity 0.3s, visibility 0.3s;

  button,
  a {
    margin: 0 0 0 10px;
  }

  g {
    fill: #4a4a4a;
  }
`;

export const Dropdown = styled.ul`
  list-style-type: none;
  position: relative;
  z-index: 10;
  transform: rotate(0deg);
  background: #fff;
  font-size: 14px;
  color: #9b9b9b;
  border-radius: ${theme.border_radius};
  overflow: hidden;
`;

export const DropdownItem = styled.li`
  border-bottom: 1px solid #d8d8d8;
  position: relative;

  &:hover {
    background: #e3ebfa;
    color: #4a4a4a;

    .icon-add g {
      stroke: ${theme.color.red};
    }

    ${DropdownMoreActions} {
      opacity: 1;
      visibility: visible;
    }

    a,
    button {
      border-radius: 0;
    }
  }

  &:last-child {
    border: none;
  }
`;

export const DropdownButton = styled.button`
  display: block;
  width: 100%;
  padding: 17px 25px;
  background: none;
  border: none;
  text-align: left;
  transition: background 0.3s, color 0.3s;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0;

  .icon-add g { stroke: #9B9B9B; }

  ${(props) =>
    !props.fixColor &&
    `
        &:hover {
            background: #E3EBFA;
            color: #4A4A4A;
            border-radius: 0px;
        }
   `}

   ${(props) =>
     props.first &&
     `
        &:hover {
            border-radius: ${theme.border_radius} !important;
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
   `}

    ${(props) =>
      props.last &&
      `
        &:hover {
            border-bottom-left-radius: ${theme.border_radius} !important;;
            border-bottom-right-radius: ${theme.border_radius} !important;;
        }
   `}

  ${(props) =>
    props.active &&
    `
		background: #E3EBFA;
    color: #4A4A4A;
    border-radius: ${theme.border_radius};
	`}
`;

export default VersionDropdown;
