/**
 * The external dependencies.
 */
import React from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import { closeModal } from "store/state/ui/actions";
import AssessmentModalContent from "../assessment/AssessmentModalContent";

/**
 * @class      ModalAssessmentTimeEnd (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */

function ModalAssessmentTimeEnd(props) {
  return (
    <ModalFrame hideCloseBtn>
      <AssessmentModalContent
        isExpired
        icon="svg/board-exclamation.svg"
        title="Time has expired"
        description="You did not submit your Assessment before time expired. Your Assessment has been submitted as-is."
        {...props.modal.data}
      />
    </ModalFrame>
  );
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    closeModal,
  }
)(ModalAssessmentTimeEnd);
