/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

export const ResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 70px 0;

  ${media.tablet_portrait`
		display; block;
		padding: 50px 0;
	`}

  ${media.mobile`
		padding: 30px 10px;
	`}
`;
export const ResultsMedia = styled.div`
  width: 55%;
  text-align: center;

  ${media.tablet_portrait`
		width: 100%;
		margin-bottom: 40px;
	`}
`;
export const ResultsContent = styled.div`
  width: 45%;
  padding: 0 25px 0 50px;

  ${media.tablet_portrait`
		width: 100%;
		padding: 0;
	`}
`;
export const ResultsSection = styled.div`
  margin-bottom: 30px;
  background: ${theme.color.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: ${theme.border_radius};
  &:last-child {
    margin-bottom: 0;
  }
`;
export const ResultsSectionTitle = styled.h4`
  padding: 20px 25px 0;
  font-size: 18px;
  font-weight: 700;
`;
export const ResultsTitle = styled.h3`
  margin-bottom: 20px;
`;
export const ResultsPercentage = styled.h2`
  padding: 0px 0px 0px;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  top: -40px;
`;
export const ResultsScoreTotal = styled.strong`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 82%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin: 0px;
  padding-bottom: 35px;
  font-size: 45px;
  font-weight: 700;
  color: ${theme.color.white};

  ${(props) =>
    props.result === "pass" &&
    `
    margin: 0px 0px 0px 8px;
    padding-bottom: 0px;
  `}

  ${media.mobile`
		margin-top: 8px;
		font-size: 110px;

		${(props) =>
      props.small &&
      `
			font-size: 90px;
		`}
	`}
`;

export const ResultsScore = styled.strong`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin: 0px;
  padding-bottom: 35px;
  font-size: 145px;
  font-weight: 700;
  color: ${theme.color.white};

  ${(props) =>
    props.result === "pass" &&
    `
    margin: 0px 0px 0px 8px;
    padding-bottom: 0px;
  `}

  ${media.mobile`
		margin-top: 8px;
		font-size: 110px;

		${(props) =>
      props.small &&
      `
			font-size: 90px;
		`}
	`}
`;
export const ResultsScoreBackground = styled.div`
  position: relative;
  margin-bottom: 20px;

  img {
    width: 100%;
  }
`;
export const ResultsCongrats = styled.p`
  max-width: 360px;
  margin: 0 auto 18px auto;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.35;
`;
export const ResultsMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  margin-bottom: 5px;

  &:first-child {
    padding-top: 20px;
  }

  &:last-child {
    padding-bottom: 20px;
    margin-bottom: 0;
  }
`;
export const ResultsMetaTitle = styled.h4`
  padding-right: 10px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
`;
export const ResultsMetaData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: ${theme.color.blue};

  .svg-icon {
    margin-left: 10px;
  }
`;
export const ResultsTable = styled.table`
  table-layout: fixed;
`;
export const ResultsThead = styled.thead``;
export const ResultsTbody = styled.tbody``;
export const ResultsTr = styled.tr``;
export const ResultsFooter = styled.tfoot``;
export const ResultsTh = styled.th`
  padding: 10px 25px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: left;
  text-transform: uppercase;

  &:first-child {
    width: 60%;
  }
`;
export const ResultsTd = styled.td`
  overflow: hidden;
  padding: 21px 25px 18px;
  border-top: 1px solid rgba(151, 151, 151, 0.1);
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-child {
    padding-right: 5px;
  }
`;
