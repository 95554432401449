/**
 * @ External Dependencies
 */
import { handleActions } from "redux-actions";
import moment from "moment";

/**
 * @ Actions
 */
import {
  createBuilderAssessment,
  updateBuilderAssessment,
  setActiveBuilderAssessment,
  deleteBuilderAssessment,
  createBuilderQuestion,
  setActiveBuilderQuestion,
  updateActiveBuilderAssessmentQuestionsOrder,
  updateActiveQuestion,
  deleteBuilderQuestion,
  createBuilderAnswer,
  deleteBuilderAnswer,
  updateBuilderAnswer,
  updateBuilderAnswersOrder,
  addImageToFolder,
  setActiveImageFolder,
  toggleActiveImageFile,
  moveFileToFolder,
  deleteFileFromFolder,
  updateFileName,
} from "./actions";
/**
 * @ Reducer
 */

const defaultState = {
  items: [
    {
      id: "1",
      randomizeOrder: false,
      name: "Algebra Placement - 2018",
      versionNumber: 5,
      createdBy: "Amit Savkar",
      modifiedBy: "David Nichols",
      createdDate: "2018-06-22",
      modifiedDate: "2018-07-16",
      questions: [
        {
          id: "q1",
          name: "",
          text: "question 1",
          liveLatexStatus: false,
          questionImageSrc: "",
          tags: [],
          answers: [
            {
              id: "ans1",
              answerText: "",
              answerStatus: true,
              tags: [],
            },
            {
              id: "ans2",
              answerText: "Another Answer",
              answerStatus: false,
              tags: [],
            },
          ],
        },
        {
          id: "q2",
          name: "",
          text: "question 2",
          liveLatexStatus: false,
          questionImageSrc: "",
          tags: [],
          answers: [],
        },
      ],
      versions: [
        {
          id: "version1",
          isSelected: true,
          btnText: "Version 1",
        },
        {
          id: "version2",
          isSelected: false,
          btnText: "Version 2",
        },
        {
          id: "version3",
          isSelected: false,
          btnText: "Version 3",
        },
      ],
    },
    {
      id: "2",
      randomizeOrder: false,
      name: "Calculus Placement - 2018",
      versionNumber: 6,
      createdBy: "Amit Savkar",
      modifiedBy: "David Nichols",
      createdDate: "2018-06-18",
      modifiedDate: "2018-06-18",
      questions: [],
      versions: [
        {
          id: "version1",
          isSelected: true,
          btnText: "Version 12",
        },
        {
          id: "version2",
          isSelected: false,
          btnText: "Version 22",
        },
        {
          id: "version3",
          isSelected: false,
          btnText: "Version 32",
        },
      ],
    },
  ],
  activeAssessmentId: null,
  activeQuestionId: null,
  activeFolderId: "all",
  folders: [
    {
      id: "folder1",
      name: "Algebra",
      files: [
        {
          id: "img1-1",
          name: "image1-1.png",
          fileSrc: "https://unsplash.it/250/275?image=1011",
          selected: false,
        },
        {
          id: "img1-2",
          name: "image1-2.png",
          fileSrc: "https://unsplash.it/250/290?image=883",
          selected: false,
        },
        {
          id: "img1-3",
          name: "ima1-3.png",
          fileSrc: "https://unsplash.it/250/215?image=65",
          selected: false,
        },
      ],
    },
    {
      id: "folder2",
      name: "Calculus",
      files: [
        {
          id: "img2-1",
          name: "imag2-1.png",
          fileSrc: "https://unsplash.it/250/215?image=65",
          selected: false,
        },
      ],
    },
    {
      id: "folder3",
      name: "David's Images",
      files: [
        {
          id: "img3-1",
          name: "image3-1.png",
          fileSrc: "https://unsplash.it/250/290?image=883",
          selected: false,
        },
      ],
    },
    {
      id: "folder4",
      name: "Untitled Folder",
      files: [
        {
          id: "img4-1",
          name: "img4-1.png",
          fileSrc: "https://unsplash.it/250/215?image=66",
          selected: false,
        },
      ],
    },
  ],
};

const assessmentObjectTemplate = {
  id: "",
  randomizeOrder: false,
  name: "",
  versionNumber: 0,
  createdBy: "",
  modifiedBy: "",
  createdDate: "",
  modifiedDate: "",
  questions: [],
  versions: [],
};

const questionObjectTemplate = {
  id: "",
  name: "",
  text: "",
  liveLatexStatus: false,
  questionImageSrc: "",
  tags: [],
  answers: [],
};

const answerObjectTemplate = {
  id: "",
  answerText: "",
  answerStatus: false,
  tags: [],
};

/**
 * Actions Handler
 *
 * @type       {Function}
 */
const builderAssessment = handleActions(
  {
    // Create Assessment
    [createBuilderAssessment]: (state, { payload }) => {
      const newItem = {
        ...assessmentObjectTemplate,
        ...payload,
        id: `${state.items.length + 1}`,
        createdDate: moment(Date.now()).format(),
      };

      return {
        ...state,
        items: [...state.items, newItem],
      };
    },
    [deleteBuilderAssessment]: (state, { payload }) => {
      const { items } = state;

      const newItems = items.filter((item) => item.id !== payload);

      return {
        ...state,
        items: newItems,
      };
    },
    // Update Assessment
    [updateBuilderAssessment]: (state, { payload }) => {
      const updatedItems = [...state.items].map((item) => {
        let updatedItem = item;

        if (item.id === payload.id) {
          updatedItem = {
            ...item,
            ...payload,
          };
        }

        return updatedItem;
      });

      return {
        ...state,
        items: updatedItems,
      };
    },
    // Activate Assessment
    [setActiveBuilderAssessment]: (state, { payload }) => {
      return {
        ...state,
        activeAssessmentId: payload,
      };
    },
    // Activate Assessment Question
    [setActiveBuilderQuestion]: (state, { payload }) => {
      return {
        ...state,
        activeQuestionId: payload,
      };
    },
    // Create Assessment Question
    [createBuilderQuestion]: (state, { payload }) => {
      const generate = require("nanoid/generate");
      const { items, activeAssessmentId } = state;

      const newQuestionId = generate("1234567890", 8);

      const updatedAssessments = [...items].map((assessment) => {
        let updatedAssessment = assessment;

        // Update Assessment if it is active
        if (assessment.id === activeAssessmentId) {
          const newQuestion = {
            ...questionObjectTemplate,
            ...payload,
            id: newQuestionId,
          };

          //Add new question
          const updatedQuestions = [...assessment.questions, newQuestion];

          updatedAssessment = {
            ...assessment,
            questions: updatedQuestions,
          };
        }

        return updatedAssessment;
      });

      return {
        ...state,
        items: updatedAssessments,
        activeQuestionId: newQuestionId,
      };
    },
    [deleteBuilderQuestion]: (state, { payload }) => {
      const { items, activeAssessmentId, activeQuestionId } = state;
      let newActiveQuestionId = "";

      const updatedAssessments = [...items].map((assessment) => {
        let updatedAssessment = assessment;

        // Update Assessment if it is active
        if (assessment.id === activeAssessmentId) {
          //Add delete question
          const updatedQuestions = [...assessment.questions].filter(
            (question) => question.id !== activeQuestionId
          );

          //set active question after deletion
          if (updatedQuestions.length > 0) {
            newActiveQuestionId = updatedQuestions[0].id;
          }

          updatedAssessment = {
            ...assessment,
            questions: updatedQuestions,
          };
        }

        return updatedAssessment;
      });

      return {
        ...state,
        items: updatedAssessments,
        activeQuestionId: newActiveQuestionId,
      };
    },
    // Update Assessment Questions Order
    [updateActiveBuilderAssessmentQuestionsOrder]: (state, { payload }) => {
      const updatedAssessment = state.items.find((item) => item.id === state.activeAssessmentId);
      updatedAssessment.questions = payload;

      const updatedItems = [...state.items].map((item) => {
        let updatedItem = item;

        // Update Assessment if it is active
        if (item.id === state.activeAssessmentId) {
          updatedItem = {
            ...item,
            ...updatedAssessment,
          };
        }

        return updatedItem;
      });

      return {
        ...state,
        items: updatedItems,
      };
    },
    // Update Assessment Active Question
    [updateActiveQuestion]: (state, { payload }) => {
      const { activeQuestionId, activeAssessmentId, items } = state;

      const updatedAssessments = [...items].map((assessment) => {
        let updatedAssessment = assessment;

        // Update Assessment if it is active
        if (assessment.id === activeAssessmentId) {
          const updatedQuestions = assessment.questions.map((question) => {
            let updatedQuestion = question;

            // Update question if it is active
            if (question.id === activeQuestionId) {
              updatedQuestion = {
                ...question,
                ...payload,
              };
            }

            return updatedQuestion;
          });

          updatedAssessment = {
            ...assessment,
            questions: updatedQuestions,
          };
        }

        return updatedAssessment;
      });

      return {
        ...state,
        items: updatedAssessments,
      };
    },
    // Create Assessment Answer
    [createBuilderAnswer]: (state, { payload }) => {
      const generate = require("nanoid/generate");
      const { items, activeAssessmentId, activeQuestionId } = state;

      const updatedAssessments = [...items].map((assessment) => {
        let updatedAssessment = assessment;

        // Update Assessment if it is active
        if (assessment.id === activeAssessmentId) {
          const updatedQuestions = assessment.questions.map((question) => {
            let updatedQuestion = question;

            // Update Question if it is active
            if (question.id === activeQuestionId) {
              const newAnswernId = generate("1234567890", 8);

              const newAnswer = {
                ...answerObjectTemplate,
                ...payload,
                id: newAnswernId,
                answerStatus: question.answers.length === 0,
              };

              // Add answer to answers array
              const updatedAnswers = [...question.answers, newAnswer];

              updatedQuestion = {
                ...question,
                answers: updatedAnswers,
              };
            }

            return updatedQuestion;
          });

          updatedAssessment = {
            ...assessment,
            questions: updatedQuestions,
          };
        }

        return updatedAssessment;
      });

      return {
        ...state,
        items: updatedAssessments,
      };
    },

    // Delete Assessment Answer
    [deleteBuilderAnswer]: (state, { payload }) => {
      const { items, activeAssessmentId, activeQuestionId } = state;

      const updatedAssessments = [...items].map((assessment) => {
        let updatedAssessment = assessment;

        // Update Assessment if it is active
        if (assessment.id === activeAssessmentId) {
          const updatedQuestions = assessment.questions.map((question) => {
            let updatedQuestion = question;

            if (question.id === activeQuestionId) {
              // Remove answer to answers array
              const updatedAnswers = [...question.answers].filter(
                (answer) => answer.id !== payload
              );

              updatedQuestion = {
                ...question,
                answers: updatedAnswers,
              };
            }

            return updatedQuestion;
          });

          updatedAssessment = {
            ...assessment,
            questions: updatedQuestions,
          };
        }

        return updatedAssessment;
      });

      return {
        ...state,
        items: updatedAssessments,
      };
    },
    // Update Assessment Answer data
    [updateBuilderAnswer]: (state, { payload }) => {
      const { items, activeAssessmentId, activeQuestionId } = state;

      const updatedAssessments = [...items].map((assessment) => {
        let updatedAssessment = assessment;

        // Update Assessment if it is active
        if (assessment.id === activeAssessmentId) {
          const updatedQuestions = assessment.questions.map((question) => {
            let updatedQuestion = question;

            if (question.id === activeQuestionId) {
              const updatedAnswers = [...question.answers].map((answer) => {
                let updatedAnswer = answer;
                if (payload.answerStatus) {
                  // Only 1 answer should be correct at a time.
                  answer.answerStatus = false;
                }

                if (answer.id === payload.id) {
                  updatedAnswer = {
                    ...answer,
                    ...payload,
                  };
                }

                return updatedAnswer;
              });

              updatedQuestion = {
                ...question,
                answers: updatedAnswers,
              };
            }

            return updatedQuestion;
          });

          updatedAssessment = {
            ...assessment,
            questions: updatedQuestions,
          };
        }

        return updatedAssessment;
      });

      return {
        ...state,
        items: updatedAssessments,
      };
    },
    // Delete Assessment Answers order
    [updateBuilderAnswersOrder]: (state, { payload }) => {
      const { items, activeAssessmentId, activeQuestionId } = state;

      const updatedAssessments = [...items].map((assessment) => {
        let updatedAssessment = assessment;

        // Update Assessment if it is active
        if (assessment.id === activeAssessmentId) {
          const updatedQuestions = assessment.questions.map((question) => {
            let updatedQuestion = question;

            if (question.id === activeQuestionId) {
              const updatedAnswers = payload;

              updatedQuestion = {
                ...question,
                answers: updatedAnswers,
              };
            }

            return updatedQuestion;
          });

          updatedAssessment = {
            ...assessment,
            questions: updatedQuestions,
          };
        }

        return updatedAssessment;
      });

      return {
        ...state,
        items: updatedAssessments,
      };
    },
    // Add Image to folder
    [addImageToFolder]: (state, { payload }) => {
      const generate = require("nanoid/generate");

      const updatedFolders = state.folders.map((folder) => {
        folder.files.map((file) => (file.selected = false));

        if (folder.id === payload.folderId) {
          const imageId = generate("1234567890", 8);

          // Add new file at beginning of array
          folder.files = [
            {
              id: imageId,
              name: payload.fileName,
              fileSrc: payload.file,
              selected: true,
            },
            ...folder.files,
          ];
        }

        return folder;
      });

      return {
        ...state,
        activeFolderId: payload.folderId,
        folders: updatedFolders,
      };
    },

    // Activate Image Folder
    [setActiveImageFolder]: (state, { payload }) => {
      return {
        ...state,
        activeFolderId: payload,
      };
    },

    // Set Active Image File
    [toggleActiveImageFile]: (state, { payload }) => {
      const updatedFolders = state.folders.map((folder) => {
        folder.files.map((file) => {
          if (file.id === payload) {
            file.selected = !file.selected;
          } else {
            file.selected = false;
          }

          return file;
        });

        return folder;
      });

      return {
        ...state,
        folders: updatedFolders,
      };
    },
    // Move File to folder
    [moveFileToFolder]: (state, { payload }) => {
      const { fileId, destinationFolderId } = payload;

      // Get the file by id
      let file = state.folders.map((folder) => {
        return folder.files.find((file) => file.id === fileId);
      });

      // Remove undefined itesm
      file = file.filter(Boolean)[0];

      // Remove File from the folder it belongs to
      const updatedFoldersAfterFileRemove = state.folders.map((folder) => {
        const updatedFolderFiles = folder.files.filter((file) => file.id !== fileId);

        return {
          ...folder,
          files: updatedFolderFiles,
        };
      });

      // Add File to the new folder after move
      const updatedFoldersAfterFileMove = updatedFoldersAfterFileRemove.map((folder) => {
        if (folder.id === destinationFolderId) {
          folder.files.push(file);
        }

        return folder;
      });

      return {
        ...state,
        folders: updatedFoldersAfterFileMove,
      };
    },
    // Delete File from folder
    [deleteFileFromFolder]: (state, { payload }) => {
      const updatedFolders = state.folders.map((folder) => {
        const updatedFiles = folder.files.filter((file) => file.id !== payload);

        return {
          ...folder,
          files: updatedFiles,
        };
      });

      return {
        ...state,
        folders: updatedFolders,
      };
    },
    // Update File name
    [updateFileName]: (state, { payload }) => {
      const { fileId, fileName } = payload;

      const updatedFolders = state.folders.map((folder) => {
        const updatedFiles = folder.files.map((file) => {
          if (file.id === fileId) {
            file.name = fileName;
          }

          return file;
        });

        return {
          ...folder,
          files: updatedFiles,
        };
      });

      return {
        ...state,
        folders: updatedFolders,
      };
    },
  },
  defaultState
);

export default builderAssessment;
