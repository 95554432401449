/**
 * @ External Dependecies
 */
import styled from "styled-components";

export const NavTrigger = styled.button`
  position: relative;
  z-index: 10;
  width: 28px;
  height: 24px;
  border: none;
  background: none;
  transition: all 0.3s;
  padding: 2px 2px 0px 2px;
  margin-top: 2px;
  margin-left: -4px;
  border: 2px solid transparent;
  border-radius: 4px;

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 6px;
  }

  span:nth-child(3) {
    top: 12px;
  }

  ${(props) =>
    props.isHighlighted &&
    `
		span {
			background: #9B9B9B;
		}
	`}

  &:focus {
    border: 2px solid #0b289b;
  }
`;

export const NavTriggerItem = styled.span`
  position: absolute;
  display: block;
  width: 18px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 2px;
  background: #808898;
  transition: all 0.3s, top 0.2s 0.25s, left 0.2s 0.25s, opacity 0.2s 0.25s;
`;

export default NavTrigger;
