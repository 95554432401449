import React, { useState, useCallback, useEffect } from "react";
import DocumentTitle from "react-document-title";
import { orderBy } from "lodash";

import SectionTable, { SectionHead, SectionBody } from "styles/components/SectionTable";
import Grid, { Col, Row } from "styles/components/Grid";
import { getAssessments, updateAssessment } from "services/assessment";
import { IMAGE_S3_SERVER } from "constants/api";

import IconSvgComponent from "components/common/IconSvgComponent";
import { FormattedText } from "../common/FormattedText";
import { FrameBox } from "styles/components/Frame";
import {
  StudentInfoQuestion,
  StudentInfoQuestionHead,
  StudentInfoQuestionList,
  StudentInfoQuestionListItem,
  StudentInfoQuestionListIndicator,
} from "styles/components/StudentInfo";
import {
  AssessmentQuestionNumber,
  AssessmentQuestionImage,
  AssessmentQuestionText,
  OptionImage,
  AnswerLabel,
  AnswerImage,
  AnswerText,
} from "styles/components/AssessmentQuestions";
import { addNotification } from "store/state/ui/actions";
import { LEVELS, createNotification } from "utilities/notification";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";

const getDisplayType = (data) => {
  let display = "radio";
  if (data.find((i) => i.type === "IMAGE") != undefined) {
    display = "image";
  }
  return display;
};

const TypeSelect = ({ value, onChange, style = {} }) => {
  return (
    <div style={style}>
      <label style={{ marginRight: "5px" }}>Type</label>
      <select name="type" value={value} onChange={onChange}>
        <option value="TEXT">TEXT</option>
        <option value="IMAGE">IMAGE</option>
      </select>
    </div>
  );
};

const DraftQuestionForm = ({
  content,
  handleContentChange,
  handleAddQuestionContent,
  handleRemoveQuestionContent,
}) => {
  return (
    <div>
      {content.map((content, index) => {
        if (content.type === "TEXT") {
          return (
            <div key={index} style={{ marginBottom: "10px" }}>
              <TypeSelect
                value={content.type}
                onChange={handleContentChange(index)}
                style={{ marginBottom: "15px" }}
              />
              <textarea
                name="content"
                value={content.text}
                onChange={handleContentChange(index)}
                rows="10"
                style={{ width: "100%", padding: "5px" }}
              />
            </div>
          );
        } else {
          return (
            <div key={index} style={{ marginBottom: "10px" }}>
              <TypeSelect
                value={content.type}
                onChange={handleContentChange(index)}
                style={{ marginBottom: "15px" }}
              />
              <input
                type="text"
                name="content"
                value={content.src}
                onChange={handleContentChange(index)}
                style={{
                  width: "100%",
                  height: "25px",
                  padding: "5px",
                  border: "1px solid black",
                }}
              />
            </div>
          );
        }
      })}

      {content.length === 1 && <button onClick={handleAddQuestionContent}>Add Block</button>}
      {content.length === 2 && <button onClick={handleRemoveQuestionContent}>Remove Block</button>}
    </div>
  );
};

const AdminPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [assessmentType, setAssessmentType] = useState("");
  const [typesLoaded, setTypesLoaded] = useState({
    "Placement Assessment": false,
    Exam: false,
    "Practice Assessment": false,
  });
  const [selectedAssessmentId, setSelectedAssessmentId] = useState("");
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [draftQuestionContent, setDraftQuestionContent] = useState(null);
  const [draftAnswersContent, setDraftAnswersContent] = useState(null);
  const [draftAssessmentName, setDraftAssessmentName] = useState(null);
  const [assessmentChanges, setAssessmentChanges] = useState([]);

  const handleAssessmentTypeChange = (event) => {
    const { value } = event.target;
    setAssessmentType(value);
  };

  const handleSelectAssessment = (event) => {
    const { value } = event.target;

    setSelectedQuestionId(null);
    setDraftQuestionContent(null);
    setDraftAnswersContent(null);
    setAssessmentChanges([]);
    setDraftAssessmentName(assessments.find((a) => a.id === value).name);
    setSelectedAssessmentId(value);
  };

  const handleAssessmentNameChange = (event) => {
    const { value } = event.target;
    setDraftAssessmentName(value);
    const valueEqualsOriginal = value === selectedAssessment.name;

    // Update assessment changes array
    // Remove changes if they exist when input returns to default value
    if (valueEqualsOriginal) {
      setAssessmentChanges((prevState) =>
        prevState.filter((change) => change.type !== "assessment")
      );
    } else {
      setAssessmentChanges((prevState) => {
        if (prevState.find((change) => change.type === "assessment")) {
          return prevState.map((change) => {
            // If a change does already exist, just update it
            if (change.type === "assessment") {
              return {
                ...change,
                changes: {
                  name: value,
                },
              };
            } else {
              return change;
            }
          });
        } else {
          // If a change does already exist, just update it
          return prevState.concat({
            type: "assessment",
            id: selectedAssessmentId,
            changes: { name: value },
          });
        }
      });
    }
  };

  const handleSelectQuestion = (questionId) => {
    setAssessmentChanges([]);
    setSelectedQuestionId(questionId);
    const selectedQuestion = selectedAssessment?.questions.find((q) => q.id === questionId);
    setDraftQuestionContent(selectedQuestion);
    setDraftAnswersContent(selectedQuestion.answers);
    setDraftAssessmentName(assessments.find((a) => a.id === selectedAssessmentId).name);
  };

  const handleQuestionContentChange = (index) => (event) => {
    const { name, value } = event.target;

    const originalDescription = assessments
      .find((a) => a.id === selectedAssessmentId)
      .questions.find((q) => q.id === selectedQuestionId).description;
    const originalDescriptionItem =
      originalDescription[index] || draftQuestionContent.description[index];

    const originalValueKey = originalDescriptionItem.type === "TEXT" ? "text" : "src";
    const valueEqualsOriginal =
      name === "type" ? true : value === originalDescriptionItem[originalValueKey];
    const typeEqualsOriginal = name === "type" ? value === originalDescriptionItem.type : true;

    const updatedDescription = draftQuestionContent.description.map(
      (description, originalIndex) => {
        const updatedType = name === "type" ? value : description.type;
        const updatedValueKey = updatedType === "TEXT" ? "text" : "src";
        const previousValueKey = description.type === "TEXT" ? "text" : "src";
        const updatedValue = name === "type" ? description[previousValueKey] : value;

        if (index === originalIndex) {
          return {
            type: updatedType,
            [updatedValueKey]: updatedValue,
          };
        } else {
          return description;
        }
      }
    );

    setDraftQuestionContent((prevState) => {
      return {
        ...prevState,
        description: updatedDescription,
      };
    });

    if (valueEqualsOriginal && typeEqualsOriginal) {
      setAssessmentChanges((prevState) => prevState.filter((change) => change.type !== "question"));
    } else {
      setAssessmentChanges((prevState) => {
        // If a change does already exist, just update it
        if (prevState.find((change) => change.type === "question")) {
          return prevState.map((change) => {
            if (change.type === "question") {
              return {
                ...change,
                changes: {
                  description: updatedDescription,
                },
              };
            } else {
              return change;
            }
          });
        } else {
          // If a change does not already exist, create a new one
          return prevState.concat({
            type: "question",
            id: selectedQuestionId,
            changes: {
              description: updatedDescription,
            },
          });
        }
      });
    }
  };

  const handleAddQuestionContent = () => {
    const textBlock = { type: "TEXT", text: "Type your question content here..." };

    setDraftQuestionContent((prevState) => {
      return {
        ...prevState,
        description: prevState.description.concat(textBlock),
      };
    });

    setAssessmentChanges((prevState) => {
      if (prevState.find((c) => c.type === "question" && c.id === selectedQuestionId)) {
        return prevState.map((change) => {
          if ((change.type === "question", change.id === selectedQuestionId)) {
            return {
              ...change,
              changes: {
                description: change.changes.description.concat(textBlock),
              },
            };
          } else {
            return change;
          }
        });
      } else {
        return prevState.concat({
          type: "question",
          id: selectedQuestionId,
          changes: {
            description: draftQuestionContent.description.concat(textBlock),
          },
        });
      }
    });
  };

  const handleRemoveQuestionContent = async () => {
    const originalDescription = assessments
      .find((a) => a.id === selectedAssessmentId)
      .questions.find((q) => q.id === selectedQuestionId).description;
    const draftDescriptionEqualsOriginal = isEqual(
      draftQuestionContent.description[0],
      originalDescription[0]
    );

    const descriptionLengthEqualsOriginal = originalDescription.length === 1;

    setDraftQuestionContent((prevState) => {
      return {
        ...prevState,
        description: prevState.description.toSpliced(1, 1),
      };
    });

    if (draftDescriptionEqualsOriginal && descriptionLengthEqualsOriginal) {
      setAssessmentChanges((prevState) => prevState.filter((change) => change.type !== "question"));
    } else {
      setAssessmentChanges((prevState) => {
        return prevState.map((change) => {
          if (change.type === "question") {
            return {
              ...change,
              changes: {
                description: change.changes.description.toSpliced(1, 1),
              },
            };
          } else {
            return change;
          }
        });
      });
    }
  };

  const handleAnswerContentChange = (answerIndex, contentIndex) => (event) => {
    const { name, value } = event.target;
    const originalAnswer = assessments
      .find((a) => a.id === selectedAssessmentId)
      .questions.find((q) => q.id === selectedQuestionId).answers[answerIndex];
    const originalDescription = originalAnswer.description;
    const originalDescriptionItem = originalDescription[contentIndex];
    const originalValueKey = originalDescriptionItem.type === "TEXT" ? "text" : "src";
    const valueEqualsOriginal =
      name === "type" ? true : value === originalDescriptionItem[originalValueKey];
    const typeEqualsOriginal = name === "type" ? value === originalDescriptionItem.type : true;

    const updatedDescription = draftAnswersContent[answerIndex].description.map(
      (description, originalIndex) => {
        const updatedType = name === "type" ? value : description.type;
        const updatedValueKey = updatedType === "TEXT" ? "text" : "src";
        const previousValueKey = description.type === "TEXT" ? "text" : "src";
        const updatedValue = name === "type" ? description[previousValueKey] : value;

        if (contentIndex === originalIndex) {
          return {
            type: updatedType,
            [updatedValueKey]: updatedValue,
          };
        } else {
          return description;
        }
      }
    );

    setDraftAnswersContent((prevState) => {
      return prevState.map((answer, i) => {
        if (i === answerIndex) {
          return {
            ...answer,
            description: updatedDescription,
          };
        } else {
          return answer;
        }
      });
    });

    if (valueEqualsOriginal && typeEqualsOriginal) {
      setAssessmentChanges((prevState) =>
        prevState.filter((change) => change.type !== "answer" && change.id === originalAnswer.id)
      );
    } else {
      setAssessmentChanges((prevState) => {
        // If a change does already exist, just update it
        if (
          prevState.find((change) => change.type === "answer" && change.id === originalAnswer.id)
        ) {
          return prevState.map((change) => {
            if (change.type === "answer" && change.id === originalAnswer.id) {
              return {
                ...change,
                changes: {
                  description: updatedDescription,
                },
              };
            } else {
              return change;
            }
          });
        } else {
          // If a change does not already exist, create a new one
          return prevState.concat({
            type: "answer",
            id: originalAnswer.id,
            changes: {
              description: updatedDescription,
            },
          });
        }
      });
    }
  };

  const handleAnswerCorrectnessChange = (answerIndex) => (event) => {
    const { checked } = event.target;
    const originalAnswer = assessments
      .find((a) => a.id === selectedAssessmentId)
      .questions.find((q) => q.id === selectedQuestionId).answers[answerIndex];
    const valueEqualsOriginal = originalAnswer.is_correct === checked;

    setDraftAnswersContent((prevState) => {
      return prevState.map((answer, i) => {
        if (i === answerIndex) {
          return {
            ...answer,
            is_correct: checked,
          };
        } else {
          return answer;
        }
      });
    });

    if (valueEqualsOriginal) {
      setAssessmentChanges((prevState) =>
        prevState.filter((change) => change.type !== "answer" && change.id === originalAnswer.id)
      );
    } else {
      setAssessmentChanges((prevState) => {
        // If a change does already exist, just update it
        if (
          prevState.find((change) => change.type === "answer" && change.id === originalAnswer.id)
        ) {
          return prevState.map((change) => {
            if (change.type === "answer" && change.id === originalAnswer.id) {
              return {
                ...change,
                changes: {
                  is_correct: checked,
                },
              };
            } else {
              return change;
            }
          });
        } else {
          // If a change does not already exist, create a new one
          return prevState.concat({
            type: "answer",
            id: originalAnswer.id,
            changes: {
              is_correct: checked,
            },
          });
        }
      });
    }
  };

  const findCurrentQuestionIndex = (q) => q.id === selectedQuestionId;

  const handlePreviousClick = () => {
    const selectedAssessment = assessments.find((a) => a.id === selectedAssessmentId);
    const selectedQuestionIndex = selectedAssessment.questions.findIndex(findCurrentQuestionIndex);
    const previousQuestionId = selectedAssessment.questions[selectedQuestionIndex - 1].id;

    handleSelectQuestion(previousQuestionId);
  };

  const handleNextClick = () => {
    const selectedAssessment = assessments.find((a) => a.id === selectedAssessmentId);
    const selectedQuestionIndex = selectedAssessment.questions.findIndex(findCurrentQuestionIndex);
    const nextQuestionId = selectedAssessment.questions[selectedQuestionIndex + 1].id;

    handleSelectQuestion(nextQuestionId);
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);

    try {
      // Call API to make change
      await updateAssessment({ updates: assessmentChanges });

      // Update local state to sync with changes
      const updatedAssessments = assessments.map((assessment) => {
        const assessmentChange = assessmentChanges.find(
          (change) => change.type === "assessment" && change.id === assessment.id
        ) || { changes: {} };

        return {
          ...assessment,
          ...assessmentChange.changes,
          questions: assessment.questions.map((question) => {
            const questionChange = assessmentChanges.find(
              (change) => change.type === "question" && change.id === question.id
            ) || { changes: {} };

            return {
              ...question,
              ...questionChange.changes,
              answers: question.answers.map((answer) => {
                const answerChange = assessmentChanges.find(
                  (change) => change.type === "answer" && change.id === answer.id
                ) || { changes: {} };

                return {
                  ...answer,
                  ...answerChange.changes,
                };
              }),
            };
          }),
        };
      });

      // Reset forms with latest assessment changes
      setAssessments(updatedAssessments);

      const findAssessment = (assessment) => assessment.id === selectedAssessmentId;
      const selectedAssessment = updatedAssessments.find(findAssessment);
      const selectedQuestion = selectedAssessment?.questions.find(
        (q) => q.id === selectedQuestionId
      );
      setDraftQuestionContent(selectedQuestion);
      setDraftAnswersContent(selectedQuestion.answers);
      setDraftAssessmentName(updatedAssessments.find(findAssessment).name);
      setAssessmentChanges([]);

      // Notify success
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            "Success",
            "Assessment question updated successfully!"
          ),
        })
      );
    } catch (error) {
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            "Error",
            "There was a problem updating the assessment"
          ),
        })
      );
    }

    setIsLoading(false);
  };

  const fetchAssessments = useCallback(
    async (type) => {
      setIsLoading(true);
      const isTypeLoaded = typesLoaded[type];
      setSelectedAssessmentId("");
      setSelectedQuestionId(null);
      setDraftQuestionContent(null);
      setDraftAnswersContent(null);
      setDraftAssessmentName(null);
      setAssessmentChanges([]);

      if (!isTypeLoaded) {
        try {
          const assessmentsResult = await getAssessments({ type });
          const newAssessments = assessmentsResult.data?.map((assessment) => {
            const orderedQuestions = orderBy(
              assessment.questions.map((question) => {
                return {
                  ...question,
                  answers: orderBy(question.answers, "display_order", "asc"),
                };
              }),
              "assessment_content.display_order",
              "asc"
            );

            return {
              ...assessment,
              questionCount: assessment.question_count,
              questions: orderedQuestions,
            };
          });

          setTypesLoaded((prev) => {
            return {
              ...prev,
              [type]: true,
            };
          });
          setAssessments((currentAssessments) => currentAssessments.concat(newAssessments));
        } catch (error) {
          dispatch(
            addNotification({
              notification: createNotification(
                LEVELS.ERROR,
                "Error",
                "There was a problem getting the assessment content"
              ),
            })
          );
        }
      }

      setIsLoading(false);
    },
    [dispatch, typesLoaded]
  );

  useEffect(() => {
    if (assessmentType) {
      fetchAssessments(assessmentType);
    }
  }, [assessmentType, fetchAssessments]);

  const selectedAssessment = assessments?.find((a) => a.id === selectedAssessmentId);
  const hasQuestions = !!selectedAssessment?.questions.length;
  const hasPreviousQuestion = selectedAssessment?.questions.findIndex(findCurrentQuestionIndex) > 0;
  const hasNextQuestion =
    selectedAssessment?.questions.findIndex(findCurrentQuestionIndex) <
    selectedAssessment?.questions.length - 1;

  return (
    <DocumentTitle title="Stemify | Administration">
      <SectionTable style={{ padding: "10px" }}>
        <SectionHead style={{ marginBottom: "10px" }}>
          <h2 style={{ textAlign: "center" }}>Assessment Management</h2>
          <div>
            <div style={{ textAlign: "center", marginBottom: "15px" }}>
              <label htmlFor="assessmentType" style={{ display: "block" }}>
                Select Assessment Type
              </label>
              <select
                id="assessmentType"
                name="assessmentType"
                value={assessmentType}
                onChange={handleAssessmentTypeChange}
                disabled={isLoading}
              >
                <option value="" disabled hidden>
                  {isLoading ? "Loading..." : "Select a type..."}
                </option>
                {["Placement Assessment", "Exam", "Practice Assessment"].map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            <div style={{ textAlign: "center" }}>
              <label htmlFor="selectedAssessmentId" style={{ display: "block" }}>
                Select Assessment
              </label>
              {assessmentType ? (
                <select
                  id="selectedAssessmentId"
                  name="selectedAssessmentId"
                  value={selectedAssessmentId}
                  onChange={handleSelectAssessment}
                  disabled={isLoading}
                >
                  <option value="" disabled hidden>
                    {isLoading ? "Loading..." : "Select an assessment..."}
                  </option>
                  {assessments
                    .filter((assessment) => assessmentType === assessment.type)
                    .map((assessment) => {
                      if (assessment.type === "Practice Assessment" && assessment.description) {
                        return (
                          <option key={assessment.id} value={assessment.id}>
                            {assessment.name} - {assessment.description}
                          </option>
                        );
                      } else {
                        return (
                          <option key={assessment.id} value={assessment.id}>
                            {assessment.name}
                          </option>
                        );
                      }
                    })}
                </select>
              ) : (
                <strong>First select an assessment type</strong>
              )}
              {assessmentType && selectedAssessmentId && !hasQuestions && (
                <div>This assessments has no questions</div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {selectedAssessmentId &&
                selectedAssessment.questions.map((q) => {
                  const isActiveQuestion = q.id === selectedQuestionId;

                  return (
                    <div
                      key={q.id}
                      onClick={() => handleSelectQuestion(q.id)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                        width: "25px",
                        height: "25px",
                        textAlign: "center",
                        border: "1px solid black",
                        borderRadius: "100%",
                        cursor: "pointer",
                        backgroundColor: isActiveQuestion ? "lightcyan" : "#fff",
                      }}
                    >
                      {q.assessment_content.display_order}
                    </div>
                  );
                })}
            </div>
            {(hasPreviousQuestion || hasNextQuestion) && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                }}
              >
                <button onClick={handlePreviousClick} disabled={!hasPreviousQuestion}>
                  Previous Question
                </button>
                <button onClick={handleNextClick} disabled={!hasNextQuestion}>
                  Next Question
                </button>
              </div>
            )}
          </div>
        </SectionHead>

        {selectedQuestionId && (
          <>
            <SectionBody style={{ padding: "10px", marginBottom: "10px" }}>
              <Grid>
                <Row>
                  <Col flex>
                    <FrameBox>
                      <StudentInfoQuestion>
                        <StudentInfoQuestionHead>
                          <AssessmentQuestionImage fullwidth>
                            {draftQuestionContent.description.map((dataItem, k) => {
                              if (dataItem.type === "TEXT") {
                                return (
                                  <AssessmentQuestionText
                                    key={k}
                                    layout={draftQuestionContent.layout}
                                    widthCounter={draftQuestionContent.description.length}
                                    floatCounter={k}
                                  >
                                    {k == 0 && (
                                      <AssessmentQuestionNumber>
                                        {draftQuestionContent.assessment_content.display_order}.
                                      </AssessmentQuestionNumber>
                                    )}
                                    <FormattedText text={dataItem.text} />
                                  </AssessmentQuestionText>
                                );
                              }
                              return (
                                <AssessmentQuestionText
                                  key={k}
                                  layout={draftQuestionContent.layout}
                                  widthCounter={draftQuestionContent.description.length}
                                  floatCounter={k}
                                >
                                  {k == 0 && (
                                    <AssessmentQuestionNumber>
                                      {draftQuestionContent.assessment_content.display_order}.
                                    </AssessmentQuestionNumber>
                                  )}
                                  <OptionImage
                                    widthCounter={draftQuestionContent.description.length}
                                    layout={draftQuestionContent.layout}
                                  >
                                    <img
                                      key={k}
                                      src={`${IMAGE_S3_SERVER}${dataItem.src}`}
                                      alt={dataItem.src}
                                    />
                                  </OptionImage>
                                </AssessmentQuestionText>
                              );
                            })}
                          </AssessmentQuestionImage>
                        </StudentInfoQuestionHead>

                        <StudentInfoQuestionList>
                          {draftAnswersContent.map((answer, aIndex) => (
                            <StudentInfoQuestionListItem
                              key={aIndex}
                              correct={answer.is_correct}
                              selected={answer.isSelected}
                              containsImage={answer.description[0]?.type === "IMAGE"}
                            >
                              <StudentInfoQuestionListIndicator
                                correct={answer.is_correct}
                                selected={answer.isSelected}
                              >
                                {answer.is_correct && (
                                  <IconSvgComponent
                                    additionalClass="correct"
                                    iconPath="svg/ico-correct.svg"
                                  />
                                )}
                                {draftQuestionContent.isAttempted &&
                                  !draftQuestionContent.is_correct &&
                                  answer.isSelected && (
                                    <IconSvgComponent
                                      additionalClass="correct"
                                      iconPath="svg/ico-incorrect.svg"
                                    />
                                  )}
                              </StudentInfoQuestionListIndicator>
                              <AnswerLabel
                                displayType={getDisplayType(answer.description)}
                                quizType="assessment"
                                layout={answer.layout}
                              >
                                <AnswerImage>
                                  {answer.description &&
                                    answer.description.map((data, k) => {
                                      if (data.type === "TEXT") {
                                        return (
                                          <AnswerText
                                            key={k}
                                            layout={answer.layout}
                                            widthCounter={answer.description.length}
                                            floatCounter={k}
                                          >
                                            <FormattedText text={data.text}></FormattedText>
                                          </AnswerText>
                                        );
                                      } else {
                                        return (
                                          <OptionImage
                                            key={k}
                                            widthCounter={answer.description.length}
                                            layout={answer.layout}
                                          >
                                            {data.src.trim() ? (
                                              <img
                                                key={k}
                                                src={`${IMAGE_S3_SERVER}${data.src}`}
                                                alt={data.src}
                                              />
                                            ) : null}
                                          </OptionImage>
                                        );
                                      }
                                    })}
                                </AnswerImage>
                              </AnswerLabel>
                            </StudentInfoQuestionListItem>
                          ))}
                        </StudentInfoQuestionList>
                      </StudentInfoQuestion>
                    </FrameBox>
                  </Col>
                </Row>
              </Grid>
            </SectionBody>

            <SectionBody style={{ padding: "10px" }}>
              <Grid>
                <>
                  <Row>
                    <Col style={{ justifyContent: "center", paddingBottom: "10px" }}>
                      <strong>Assessment</strong>
                      <div>ID: {selectedAssessmentId}</div>
                      <div>
                        <label>Assessment Name</label>
                        <br />
                        <input
                          type="text"
                          name="assessmentName"
                          value={draftAssessmentName}
                          onChange={handleAssessmentNameChange}
                          style={{
                            width: "350px",
                            height: "25px",
                            padding: "5px",
                            border: "1px solid black",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col flex>
                      <div>
                        <strong>
                          Question {draftQuestionContent.assessment_content.display_order}
                        </strong>
                        <div>ID: {draftQuestionContent.id}</div>

                        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                          <DraftQuestionForm
                            content={draftQuestionContent.description}
                            handleContentChange={handleQuestionContentChange}
                            handleAddQuestionContent={handleAddQuestionContent}
                            handleRemoveQuestionContent={handleRemoveQuestionContent}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col flex>
                      <div>
                        <strong>Answers</strong>
                        <br />
                        {draftAnswersContent.map((answer, answerIndex) => {
                          return (
                            <div key={answer.id} style={{ marginBottom: "15px" }}>
                              <div>
                                Answer #{answerIndex + 1} - ID: {answer.id}
                              </div>
                              {answer.description.map((content, contentIndex) => {
                                if (content.type === "TEXT") {
                                  return (
                                    <div
                                      key={contentIndex}
                                      style={{ display: "flex", alignItems: "center" }}
                                    >
                                      <TypeSelect
                                        value={content.type}
                                        onChange={handleAnswerContentChange(
                                          answerIndex,
                                          contentIndex
                                        )}
                                        style={{ width: "150px" }}
                                      />
                                      <input
                                        type="text"
                                        name="content"
                                        value={content.text}
                                        onChange={handleAnswerContentChange(
                                          answerIndex,
                                          contentIndex
                                        )}
                                        style={{
                                          width: "100%",
                                          height: "25px",
                                          padding: "5px",
                                          border: "1px solid black",
                                        }}
                                      />
                                      <div style={{ position: "relative", width: "75px" }}>
                                        <label
                                          style={{
                                            position: "absolute",
                                            top: "-22px",
                                            right: "3px",
                                          }}
                                        >
                                          Correct
                                        </label>
                                        <input
                                          type="checkbox"
                                          name="is_correct"
                                          checked={answer.is_correct}
                                          onChange={handleAnswerCorrectnessChange(answerIndex)}
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      key={contentIndex}
                                      style={{ display: "flex", alignItems: "center" }}
                                    >
                                      <TypeSelect
                                        value={content.type}
                                        onChange={handleAnswerContentChange(
                                          answerIndex,
                                          contentIndex
                                        )}
                                        style={{ width: "150px" }}
                                      />
                                      <input
                                        type="text"
                                        name="content"
                                        value={content.src}
                                        onChange={handleAnswerContentChange(
                                          answerIndex,
                                          contentIndex
                                        )}
                                        style={{
                                          width: "100%",
                                          height: "25px",
                                          padding: "5px",
                                          border: "1px solid black",
                                        }}
                                      />
                                      <div style={{ position: "relative", width: "75px" }}>
                                        <label
                                          style={{
                                            position: "absolute",
                                            top: "-22px",
                                            right: "3px",
                                          }}
                                        >
                                          Correct
                                        </label>
                                        <input
                                          type="checkbox"
                                          name="is_correct"
                                          checked={answer.is_correct}
                                          onChange={handleAnswerCorrectnessChange(answerIndex)}
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ justifyContent: "center" }}>
                      <button
                        disabled={!assessmentChanges.length || isLoading}
                        onClick={handleSaveChanges}
                      >
                        {isLoading ? "Updating..." : "Update Assessment"}
                      </button>
                    </Col>
                  </Row>
                </>
              </Grid>
            </SectionBody>
          </>
        )}
      </SectionTable>
    </DocumentTitle>
  );
};

export default AdminPage;
