export const accountPath = "/account";
export const accountStatusPath = "/account/status";
export const accountsPath = "/account";
export const userPath = "/user";
export const usersPath = "/user";
export const loginPath = "/login";
export const logoutPath = "/logout";
export const refreshTokenPath = "/refreshToken";
export const resetPasswordPath = "/forgetPassword";
export const confirmForgetPasswordPath = "/confirmForgetPassword";
export const getForgottenPasswordCodePath = "/forgotpwdreset";
export const firstSigninResetPath = "/firstTimeLogin";
export const programPath = "/program";
export const learningUnitPath = "/learningUnit";
export const learningModulePath = "/learningModule";
export const signedURLByTokenPath = "/signedUrl/token";
export const contentType = "application/x-www-form-urlencoded";
export const contentTypeJSON = "application/json";
export const cohortsPath = "/cohort";
export const cohortByIdPath = (cohortId) => `/cohort/${cohortId}`;
export const userActivityPath = "/user/activity";
export const videoPath = "/video";
export const quizPath = "/quiz";
export const assessmentSeriesPath = "/assessment";
export const assessmentSeriesPathAll = "/deployment/all/assessment";
export const assessmentPath = "/assessments";
export const deploymentsPath = "/deployment";
export const deploymentAssessmentPath = "/assessment";
export const studentPath = "/student";
export const attemptsPath = "/attempts";
export const attemptPath = "/attempt";
export const studentPerformacePath = "/assessment/latest/attempt/latest";
export const beginPlacementAssessmentPath = ({ deploymentId, assessmentSeriesId }) =>
  `${deploymentsPath}/${deploymentId}${assessmentSeriesPath}/${assessmentSeriesId}/begin`;
export const submitPlacementAssessmentPath = ({ deploymentId, assessmentSeriesId }) =>
  `${deploymentsPath}/${deploymentId}${assessmentSeriesPath}/${assessmentSeriesId}/submit`;
export const beginAttemptPath = `/attempt/begin`;
export const ssoTokenPath = "/oauth2/token";
export const studentPerformanceDashboardPath = "/assessment/all/attempt/top/";
export const studentAssessmentSpecificPerformancePath = (id) => `/assessment/${id}/attempt/top/`;
export const facultyDeploymentAssessmentStatsPath = (deploymentId, assessmentId) =>
  `/deployment/${deploymentId}/assessment/${assessmentId}/stat`;
export const facultyDeploymentAssessmentStudentListPath = (deploymentId, assessmentId) =>
  `/deployment/${deploymentId}/assessment/${assessmentId}/student`;
export const courseAssessmentScoreDistributionPath = (assignmentId) =>
  `/assignment/${assignmentId}/count_by_score`;
export const courseAssessmentTopicBreakDownPath = (assignmentId) =>
  `/assignment/${assignmentId}/concepts_by_score`;
export const courseAssessmentStudentBreakDownPath = (assignmentId) =>
  `/assignment/${assignmentId}/student_breakdown`;
export const videoConceptsBreakdownPath = (assignmentId) =>
  `/assignment/${assignmentId}/video_concepts_by_score`;
export const apiBaseUrl = "https://api.savkar.ai/";
export const apiLocalUrl = "http://localhost:3001/dev";
export const apiProdUrl = "https://api-p.savkar.ai/";

export const userFields = [
  "id",
  "firstName",
  "lastName",
  "email",
  "role",
  "studentId",
  "year",
  "assessmentTimeMultiplier",
];

export const errorData = {
  code: -1,
  message: "",
};

export const JW_ASSET_BASEURL = "https://cdn.jwplayer.com";
export const IMAGE_S3_SERVER = "https://images.savkar.ai/";
export const THUMBNAILS = "thumbnails/";
export const RETRY_API_ERROR_CODES = [];
export const disciplinePath = "/discipline";
export const sectionsPath = "/section?page=1&pageSize=0";
export const assignmentsPath = "/assignment";
export const schedulesPath = "/schedule";

export const sectionByIdPath = ({ sectionId }) => `/section/${sectionId}`;
export const sectionByIdCopyPath = ({ sectionId }) => `/section/${sectionId}/copy`;
export const unitsBySectionIdPath = ({ sectionId }) => `/section/${sectionId}/unit`;
export const assignmentBySectionAndIdPath = ({ sectionId, assignmentId }) =>
  `/section/${sectionId}/assignment/${assignmentId}`;
export const assignmentByIdPath = ({ assignmentId }) => `/assignment/${assignmentId}`;
export const assignmentsBySectionIdPath = ({ sectionId }) => `/section/${sectionId}/assignment`;
export const scheduleBySectionIdPath = ({ sectionId }) => `/section/${sectionId}/schedule`;
export const videoByIdPath = ({ id }) => `/video/${id}`;
export const assessmentByIdPath = ({ id }) => `/assessment/${id}`;
export const sectionUnitsPath = ({ id }) => `/section/${id}/unit`;
export const unitPath = ({ unitId }) => `/unit/${unitId}`;
export const unitsPath = "/unit";
export const errorCodesPageNotFound = [167, 156, 154, 171, 169];
export const sectionUserPath = ({ id }) => `/section/${id}/user`;
export const usersInCoursePath = ({ id, username, role }) =>
  `/section/${id}/user/${username}/role/${role}`;
export const assignmentPath = "/assignment";
export const assignmentWithIdPath = ({ assignmentId }) => `/assignment/${assignmentId}`;
export const sectionEnrollmentPath = ({ courseId }) => `/section/${courseId}/enrollment`;
export const sectionPath = "/section/";
export const enrollmentPath = "/enrollment";
export const getAssignmentPath = ({ sectionId, assignmentId }) =>
  `/section/${sectionId}/assignment/${assignmentId}`;
export const getAssignmentAttemptsPath = ({ assignmentId }) =>
  `/assignment/${assignmentId}/attempt`;
export const updateAssignmentPath = ({ assignmentId }) => `/assignment/${assignmentId}`;
export const courseAssessmentBeginPath = ({ assignmentId }) => `/assignment/${assignmentId}/begin`;
export const sectionAssignmentGradesPath = ({ sectionId }) =>
  `/section/${sectionId}/grades_by_assignment`;
export const sectionStudentsGradesPath = ({ sectionId }) =>
  `/section/${sectionId}/grades_by_student`;
export const assignmentDashboardStudentCountPath = ({ assignmentId }) =>
  `/assignment/${assignmentId}/student_count`;
export const assignmentDashboardAvgTimePath = ({ assignmentId }) =>
  `/assignment/${assignmentId}/avg_time`;
export const assignmentDashboardAverageScorePath = ({ assignmentId }) =>
  `/assignment/${assignmentId}/avg_score`;
export const assignmentStandardDeviationPath = ({ assignmentId }) =>
  `/assignment/${assignmentId}/std_dev`;
export const courseAssessmentCompletionPath = (assignmentId) =>
  `/assignment/${assignmentId}/completion`;
export const videoQuizAverageCorrectAnswersPath = (assignmentId) =>
  `/assignment/${assignmentId}/video_quiz_questions_correct`;
export const videoQuizStudentBreakdownPath = (assignmentId) =>
  `/assignment/${assignmentId}/video_quiz_by_students`;
export const videoStudentBreakdownPath = (assignmentId) =>
  `/assignment/${assignmentId}/video_by_students`;

export const getBannerPath = `/banner`;
export const editBannerPath = (bannerId) => `${getBannerPath}/${bannerId}`;
export const resumeAttemptPath = `/attempt/resume`;
export const assessmentQuestionResponsePath = (attemptId) => `/attempt/${attemptId}/response`;
export const submitAttemptPath = (attemptId) => `/attempt/${attemptId}/submit`;
export const attemptStatsPath = ({ attemptId, assessmentId }) =>
  `/assessment/${assessmentId}/attempt/${attemptId}/stat`;
