import { signedURLByTokenPath, contentType, videoPath, quizPath } from "../constants/api";
import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";

const callGetSignedURLByTokenAPI = async (mediaData) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: signedURLByTokenPath,
    method: "post",
    body: JSON.stringify(mediaData),
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};

export { callGetSignedURLByTokenAPI };

export const callGetvideoQuizDataAPI = async ({ videoId }) => {
  const resourcePath = `${videoPath}/${videoId}${quizPath}`;
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};

export const resetVideoQuizDataAPI = async ({ videoId }) => {
  const resourcePath = `${videoPath}/${videoId}${quizPath}/response`;
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath,
    method: "delete",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};

export const callAddQuizResponseAPI = async ({ quiz_id, question_id, answer_id }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${videoPath}${quizPath}/${quiz_id}/response`,
    method: "post",
    body: JSON.stringify({ question_id, answer_id }),
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
    },
  });
};
