/**
 * The external dependencies.
 */
import React, { Component, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import styled from "styled-components";
import Select from "react-select";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "react-datetime/css/react-datetime.css";
import "styles/dropdown.css";
/**
 * The internal dependencies.
 */
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  FormFieldWrapper,
  FormField,
  FormLabel,
  FormFieldStatic,
  FormTextarea,
  FormIcon,
} from "styles/components/Form";
import { StyledErrorIconWrapper } from "styles/components/Form";
import {
  limitText,
  allowOnlyDigits,
  preventPaste,
  setMetaKey,
  unsetMetaKey,
  disableContextMenu,
} from "utilities/commonFunctions";
import { fieldInvalidIconPath } from "constants/paths";
import moment from "moment";

const InternalField = ({
  id,
  icon,
  placeholder,
  onChange,
  onKeyUp,
  onKeyDown,
  onMouseUp,
  value,
  type,
  required,
  isSelect,
  isMultiselect = false,
  isStatic,
  isTextarea,
  isDateTimePicker,
  datePickerRef,
  options,
  autoComplete,
  disabled,
  min,
  max,
  suffix,
  showTimeSelect,
  dateFormat,
  name,
  customRef,
  error,
  infiniteTyping,
  hideRequiredSymbol,
  isNumericTextField,
  shiftUp,
  apiCallInProgress,
  fieldWidth,
  smallLabel,
  inline,
  notAfter,
  notBefore,
  patternAvailable,
  patternString,
  isHidden,
  errorTitle,
  style,
  classes,
  hasSuffix,
  onFocus,
  onBlur,
  onOrAfter,
  onOrBefore,
}) => {
  const [draftValue, setDraftValue] = useState(null);
  const inputElement = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);

  useEffect(() => {
    if (isDateTimePicker && inputElement.current === document.activeElement) {
      inputElement.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition, draftValue, isDateTimePicker]);

  const onInput = (event) => {
    if (event.key === "Backspace") {
      setCursorPosition(inputElement.current.selectionStart - 1);
    } else if (event.key === "Delete") {
      setCursorPosition(inputElement.current.selectionStart);
    } else if (typeof event.key === "number" || event.key === "/") {
      setCursorPosition(inputElement.current.selectionStart + 1);
    } else {
      setCursorPosition(inputElement.current.selectionStart);
    }
  };

  if (isSelect) {
    return (
      <React.Fragment>
        <Select
          id={id}
          className={"react-select-container" + (error ? " validation-error" : "")}
          classNamePrefix="react-select"
          onChange={onChange}
          value={value}
          options={options}
          required={required}
          isSearchable={true}
          isMulti={isMultiselect}
          isDisabled={disabled}
          error={error}
          isClearable={true}
          placeholder=""
          noOptionsMessage={() => (apiCallInProgress ? "Loading..." : "No options")}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={onMouseUp}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {error && (
          <StyledErrorIconWrapper specialInput>
            <IconSvgComponent iconPath={fieldInvalidIconPath} />
          </StyledErrorIconWrapper>
        )}
      </React.Fragment>
    );
  } else if (isStatic) {
    return <FormFieldStatic>{value}</FormFieldStatic>;
  } else if (isTextarea) {
    return (
      <React.Fragment>
        <FormTextarea
          id={id}
          name={name}
          minLength={min}
          maxLength={max}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseUp={onMouseUp}
          value={value}
          required={required}
          autoComplete={autoComplete}
          disabled={disabled}
          error={error}
        />
        {error && (
          <StyledErrorIconWrapper>
            <IconSvgComponent iconPath={fieldInvalidIconPath} />
          </StyledErrorIconWrapper>
        )}
      </React.Fragment>
    );
  } else if (isDateTimePicker) {
    const isValid = (current, selected) => {
      let isTrue = true;

      if (notAfter) {
        isTrue = !current.isAfter(notAfter);
      }

      if (isTrue && notBefore) {
        isTrue = !current.isBefore(notBefore);
      }

      if (isTrue && onOrAfter) {
        isTrue = current.isSameOrAfter(onOrAfter, "day");
      }

      if (isTrue && onOrBefore) {
        isTrue = current.isSameOrBefore(onOrBefore, "day");
      }

      return isTrue;
    };

    return (
      <React.Fragment>
        <DatePicker
          className={error ? "custom-datepicker error" : "custom-datepicker"}
          value={typeof draftValue === "string" ? draftValue : value}
          onChange={(newValue) => {
            const isValidDate = moment(newValue, dateFormat, true).isValid();

            if (isValidDate) {
              setDraftValue(null);
              onChange(newValue);
            } else {
              setDraftValue(newValue);
            }
          }}
          timeFormat={showTimeSelect}
          dateFormat={dateFormat}
          required={required}
          isValidDate={isValid}
          ref={datePickerRef}
          closeOnSelect={true}
          strictParsing={true}
          inputProps={{
            onInput: onInput,
            ref: inputElement,
          }}
        />
        {error && (
          <StyledErrorIconWrapper specialInput>
            <IconSvgComponent iconPath={fieldInvalidIconPath} />
          </StyledErrorIconWrapper>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <FormField
          innerRef={customRef}
          withSuffix={suffix ? true : false}
          id={id}
          type={type ? type : "text"}
          min={min}
          max={max}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          onKeyUp={type === "password" ? (e) => unsetMetaKey(e) : onKeyUp}
          onKeyDown={
            type === "password"
              ? (e) => {
                  // setMetaKey(e);
                  // preventPaste(e);
                }
              : isNumericTextField && max && type === "text" && !infiniteTyping
              ? (e) => {
                  allowOnlyDigits(e);
                  limitText(e, id, max);
                }
              : max && type !== "number" && !infiniteTyping
              ? (e) => limitText(e, id, max)
              : type === "number"
              ? (e) => allowOnlyDigits(e)
              : onKeyDown
          }
          onMouseUp={type === "password" ? disableContextMenu : onMouseUp}
          value={value}
          required={required}
          autoComplete={autoComplete}
          disabled={disabled}
          name={name}
          error={error}
          shiftUp={shiftUp}
          width={fieldWidth}
          pattern={patternAvailable ? patternString : null}
          title={errorTitle}
          isHidden={isHidden}
        />
        {error && (
          <StyledErrorIconWrapper>
            <IconSvgComponent iconPath={fieldInvalidIconPath} />
          </StyledErrorIconWrapper>
        )}

        {hasSuffix && <span className="field-suffix">{suffix}</span>}
      </React.Fragment>
    );
  }
};

class Field extends Component {
  state = {
    isFocused: this.props.defaultFocus || false,
  };

  static propTypes = {
    id: PropTypes.string,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    value: PropTypes.any,
    type: PropTypes.string,
    required: PropTypes.bool,
    isSelect: PropTypes.bool,
    isMultiselect: PropTypes.bool,
    options: PropTypes.array,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    isStatic: PropTypes.bool,
    isTextarea: PropTypes.bool,
    isDateTimePicker: PropTypes.bool,
    defaultFocus: PropTypes.bool,
    isNumericTextField: PropTypes.bool,
    shiftUp: PropTypes.bool,
    apiCallInProgress: PropTypes.bool,
    patternAvailable: PropTypes.bool,
    errorTitle: PropTypes.bool,
    patternString: PropTypes.string,
    isHidden: PropTypes.bool,
    style: PropTypes.object,
  };

  static defaultProps = {
    dateFormat: "L",
    showTimeSelect: true,
  };

  render() {
    const {
      id,
      icon,
      placeholder,
      onChange,
      onKeyUp,
      onKeyDown,
      onMouseUp,
      value,
      type,
      required,
      isSelect,
      isMultiselect = false,
      isStatic,
      isTextarea,
      isDateTimePicker,
      datePickerRef,
      options,
      autoComplete,
      disabled,
      min,
      max,
      suffix,
      showTimeSelect,
      dateFormat,
      name,
      customRef,
      error,
      infiniteTyping,
      hideRequiredSymbol,
      isNumericTextField,
      shiftUp,
      apiCallInProgress,
      fieldWidth,
      smallLabel,
      inline,
      notAfter,
      notBefore,
      patternAvailable,
      patternString,
      isHidden,
      errorTitle,
      style,
    } = this.props;

    const classes = classnames({
      "is-filled": type === "number" && value == 0 ? true : value,
      "is-focused": this.state.isFocused,
      "has-icon": icon,
      disabled: disabled,
      "is-static": isStatic,
      "is-textarea": isTextarea,
    });

    const hasSuffix = (suffix && value.length) || this.state.isFocused;

    return (
      <FormFieldWrapper className={classes} fieldWidth={fieldWidth} inline={inline} style={style}>
        {icon && (
          <FormIcon>
            <IconSvgComponent iconPath={`svg/${icon}.svg`} />
          </FormIcon>
        )}

        <FormLabel htmlFor={id} smallLabel={smallLabel}>
          {placeholder}

          {required && !hideRequiredSymbol && <span>*</span>}
        </FormLabel>

        <InternalField
          {...this.props}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          hasSuffix={hasSuffix}
          classes={classes}
        />
      </FormFieldWrapper>
    );
  }

  onFocus = (event) => {
    this.setState({ isFocused: true });
    this.props.onFocus && this.props.onFocus(event);
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };
}

export default Field;
