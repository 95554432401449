import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import TableDropdown from "components/elements/TableDropdown";
import { ROLES } from "constants/roles";
import {
  Course,
  CourseTitle,
  CourseInnerLink,
  CourseBody,
  CourseFoot,
  CourseEntry,
} from "styles/components/Courses";
import stemifyRoutes from "constants/routes";
import { multiSort, COMPARE_TYPES } from "utilities/commonFunctions";

const getDueMessage = (dueDateAndTime) => {
  const daysUntilDue = moment().diff(dueDateAndTime, "days");
  const dueTime = moment(dueDateAndTime).format("h:mma");

  switch (daysUntilDue) {
    case 0:
      return `Today by ${dueTime}`;

    case -1:
      return `Tomorrow by ${dueTime}`;

    case -2:
    case -3:
    case -4:
    case -5:
    case -6:
      return `${moment(dueDateAndTime).format("dddd")} by ${dueTime}`;

    default:
      return `${moment(dueDateAndTime).format("MMM D")}`;
  }
};
class CourseItem extends Component {
  editCourse = (course) => {
    this.props.onEdit(course);
  };

  copyCourse = (course) => {
    this.props.onCopy(course);
  };

  deleteCourse = (course) => {
    this.props.onDelete(course);
  };

  render() {
    const { data, fullWidth = false } = this.props;
    const nextEndingSchedule = multiSort({
      items: data.schedules,
      operations: [
        { type: COMPARE_TYPES.DATE, order: "asc", key: "endTs" },
        { type: COMPARE_TYPES.DATE, order: "asc", key: "startTs" },
      ],
    })[0];

    const dueDateAndTime = nextEndingSchedule?.endTs;

    const dueMessage = getDueMessage(dueDateAndTime);

    const nextAssignment =
      data.assignments.length > 0 &&
      nextEndingSchedule &&
      data.assignments.find((assignment) => nextEndingSchedule.assignmentId === assignment.id);

    return (
      <Course
        innerRef={this.props.innerRef}
        isCopy={this.props.isCopy}
        background={data.background}
        fullWidth={fullWidth}
      >
        {this.props.userRole !== ROLES.STUDENT && (
          <TableDropdown
            options={{
              items: this.props.isInstructorView
                ? [
                    {
                      callback: () => this.editCourse(data),
                      btnText: "Edit",
                    },
                  ]
                : [
                    {
                      callback: () => this.editCourse(data),
                      btnText: "Edit",
                    },
                    {
                      callback: () => this.copyCourse(data),
                      btnText: "Copy",
                    },
                    {
                      callback: () => this.deleteCourse(data),
                      btnText: "Delete",
                    },
                  ],
            }}
          />
        )}
        <CourseInnerLink to={`${stemifyRoutes.courses}/${data.id}/coursework`}>
          <CourseBody background={data.background}>
            <CourseTitle>
              {data.name} {data.courseNumber}
            </CourseTitle>
            <CourseEntry>Section {data.sectionNumber}</CourseEntry>
            <CourseEntry>{data.students} Students</CourseEntry>
          </CourseBody>

          <CourseFoot background={data.background}>
            {data.schedules.length > 0 ? (
              <>
                {nextAssignment && <CourseEntry>Due {dueMessage}</CourseEntry>}
                <CourseEntry>
                  {nextAssignment
                    ? `${nextAssignment.name} ${
                        nextAssignment.description ? `: ${nextAssignment.description}` : ""
                      }`
                    : "No assignments due"}
                </CourseEntry>
              </>
            ) : (
              "No assignments due"
            )}
          </CourseFoot>
        </CourseInnerLink>
      </Course>
    );
  }
}

const getNextDueAssignmentFromSection = (section) => {};

export default connect((state) => ({
  userRole: state.user.role,
}))(CourseItem);
