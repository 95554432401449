/**
 * @ External Dependecies
 */
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { media } from "styles/media";
import theme from "../theme";

const url = window.location.host;

export const CardHead = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 13px 23px
  border-bottom: 1px solid rgba(151, 151, 151, 0.16);

  ${(props) =>
    props.noBorder &&
    `
  border-bottom: none;
  `}

  ${media.mobile`
    display: block;
    padding: 15px;
  `}
`;
export const CardTitle = styled.h1`
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 20px;

  ${(props) =>
    props.paddingRight &&
    `
    padding-right: 5px;
  `}

  ${media.mobile`
    margin-bottom: 20px;
  `}
`;
export const CardLink = styled(Link)`
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 1px 17px;
  background: ${theme.color.red};
  border-radius: 13px;
  font-size: 14px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  transition: opacity 0.3s;
  border: 2px solid transparent !important;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    border: 2px solid ${theme.color.red_faded} !important;
  }

  ${(props) =>
    props.minwidth &&
    `
    min-width: 130px;
  `}
`;

export const CardVideoLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 2px;
  margin-top: -4px;
  margin-left: -4px;
  border-radius: 4px;
  border: 2px solid transparent;
  display: inherit;

  &:focus {
    border: 2px solid #2049e6;
  }
`;

export const CardButton = styled.button.attrs({
  type: "button",
})``;
export const CardBody = styled.div``;
export const CardOrderedList = styled.ol`
  padding: 20px 28px 28px 52px;
  font-size: 16px;
  font-weight: 400;
  column-count: 3;
  column-gap: 18px;

  ${media.desktop`
    column-count: 2;
  `}

  ${media.mobile`
    column-count: 1;
  `}
`;
export const CardOrderedListItem = styled.li`
  margin-bottom: 10px;
  margin-right: 18px;
  list-style-position: outside;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const CardOrderedListLink = styled(Link)`
  text-decoration: none;
  cursor: pointer !important;
  padding: 2px;
  margin-top: -4px;
  margin-left: -4px;
  border-radius: 4px;
  border: 2px solid transparent;
  margin-right: 24px;

  ${(props) =>
    props.smallgap &&
    `
    margin-right: 10px;
  `}

  ${(props) =>
    props.smallergap &&
    `
    margin-right: 0px;
  `}

  &:hover {
    text-decoration: none;
  }

  &:focus {
    border: 2px solid #2049e6;
  }
`;
export const CardColumn = styled.div`
  max-width: 100%;
  flex: 1 1 100%;
  padding: 0 0 20px;

  ${(props) =>
    props.size &&
    `
    max-width: ${props.size};
    flex: 0 0 ${props.size};
  `}

  ${(props) =>
    props.flex &&
    `
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  `}

  ${(props) =>
    props.spaceBetween &&
    `
    justify-content: space-between;
  `}

  ${media.desktop`
    ${(props) =>
      props.sizeLg &&
      `
      max-width: ${props.sizeLg};
      flex: 1 1 ${props.sizeLg};
    `}
  `}

  ${media.mobile`
    max-width: 100%;
    flex: 1 1 100%;

    ${(props) =>
      props.flex &&
      `
      flex-wrap: wrap;
    `}
  `}
`;
export const CardColumns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -10px -20px;

  & > ${CardColumn} {
    padding: 0 10px 20px;
  }

  ${(props) =>
    props.flexWrap &&
    `
    flex-wrap: wrap;
  `}

  ${(props) =>
    props.padding &&
    `
    padding: ${props.padding};
  `}

  ${media.desktop`
    flex-wrap: wrap;
  `}
`;
export const CardHolder = styled.div`
  padding: 22px 22px 26px 23px;

  ${media.mobile`
    padding: 15px;
  `}
`;
export const CardVideo = styled.div`
  position: relative;
  width: 140px;
  height: 90px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  ${(props) =>
    props.large &&
    `
    width: 100%;
    height: 184px;
  `}
`;
export const CardVideoButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 130px;
  height: 130px;
  background-image: url('//${url}/svg/play-icon-large.svg');
  background-color: transparent;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0 none;
  transform: translate(-50%, -50%);

  ${(props) =>
    props.size &&
    `
    width: ${props.size}px;
    height: ${props.size}px;
  `}
`;
export const CardContent = styled.div``;
export const CardEntry = styled.p`
  font-size: 12px;
  line-height: 1.5;
`;
export const CardSubtitle = styled.h2`
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: normal;

  ${(props) =>
    props.textBold &&
    `
		font-weight: bold;
	`}
`;

export const CardListIconsItem = styled.li`
  .svg-icon {
    margin-right: 7px;
    display: inline-block;
    vertical-align: bottom;
  }

  ${(props) =>
    props.larger &&
    css`
      .svg-icon {
        vertical-align: sub;
      }
    `}

  ${(props) =>
    props.large &&
    css`
      .svg-icon {
        vertical-align: sub;
      }
    `}

  ${(props) =>
    props.space &&
    css`
      margin-bottom: 5px !important;
    `}
`;

export const CardListIconsButton = styled.button.attrs({
  type: "button",
})`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border: 0 none;

  .svg-icon {
    margin-right: 7px;
    display: inline-block;
    vertical-align: top;
    vertical-align: text-top;
  }

  ${(props) =>
    props.underline &&
    `
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  `}
`;
export const CardListIcons = styled.ul`
  list-style-type: none;
  font-size: 12px;
  line-height: 1.2;

  ${(props) =>
    props.larger &&
    `
    font-size: 16px;
  `}

  ${(props) =>
    props.large &&
    `
    font-size: 14px;
  `}

  ${CardListIconsItem} {
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.horizontal &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      ${CardListIconsItem} {
        margin-bottom: 0;
        padding: 0px 2px 2px 5px;
      }
    `}

  ${(props) =>
    props.sizeSm &&
    css`
      max-width: 356px;
    `}

  ${(props) =>
    props.noSpace &&
    css`
      justify-content: unset;
    `}

  ${media.mobile`
    ${(props) =>
      props.horizontal &&
      css`
        display: block;
      `}
  `}
`;

export default styled.div`
  margin-bottom: 30px;
  border: 1px solid rgba(151, 151, 151, 0.1);
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04);
  border-radius:${theme.border_radius};
  position: relative;
  ${(props) =>
    props.isQuizOverlayOn &&
    css`
      .jwplayer {
        opacity: 0.3;
        -webkit-filter: blur(4px);
        filter: blur(4px);
      }
    `}

  ${media.mobile`
    ${CardVideoButton} {
      width: 60px;
      height: 60px;
    }
  `}

  ${(props) =>
    props.large &&
    css`
    padding: 30px;
    background: none;
    box-shadow: none;
    border: 0 none;

    ${(props) =>
      props.videoquiz &&
      `
    padding-top: 0px;
    padding-bottom: 0px;
    background: none;
    box-shadow: none;
    border: 0 none;
   `}

    ${(props) =>
      props.positionRelative &&
      `
      position:relative;
   `}

    ${CardVideo} {
      height: 481px;
      margin-bottom: 30px;
    }

    ${CardHolder} {
      border: 1px solid rgba(151, 151, 151, 0.1);
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04);
      border-radius: ${theme.border_radius};
    }

    ${CardListIcons} {
      justify-content: flex-start;
      margin: 0 -20px -10px;

      ${CardListIconsItem} {
        margin-bottom: 0;
        padding: 0 20px 10px;
      }

      ${(props) =>
        props.vertical &&
        `
        display:inline-block;
      `}
    }

    ${media.mobile`
      padding: 15px;

      ${(props) =>
        props.positionRelative &&
        `
      padding: 0px;
     `}

      ${CardVideo} {
        height: 230px;
        padding-top: 0;
      }
    `}
  `}
`;

// STEM:346 Removed background-color:black;
export const CardRibbon = styled.div`
  height: 550px;
  // background-color:black;
  margin-bottom: 22px;
`;

export const CardDownloadLink = styled.a`
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 7px 3px 4px 3px;
  margin-top: -9px;
  margin-left: -5px;
  &:focus {
    border: 2px solid #2049e6;
  }
`;

export const CardRedirectLink = styled(Link)`
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 7px 3px 4px 3px;
  margin-top: -9px;
  margin-left: -5px;
  &:focus {
    border: 2px solid #2049e6;
  }
`;

export const CardMessage = styled.span`
  font-size: 16px;
`;

export const NoDataMessageContainer = styled.div`
  margin-top: 120px;
  text-align: center;
  ${(props) =>
    props.noIcon &&
    `
  margin-top: 220px;
`}

  ${(props) =>
    props.marginBottom &&
    `
    margin-bottom: ${props.marginBottom + "px"};
`}
`;

export const NoDataMessage = styled.p.attrs({
  id: (props) => props.id,
})`
  font-size: 21px;
  font-weight: 700;
  margin-top: 30px;
  color: #4a4a4a;
`;

export const CardContentList = styled.li`
  font-size: 12px;
  line-height: 1.5;
  margin-left: 13px;

  ${(props) =>
    props.larger &&
    `
    font-size: 16px;
    line-height: 1.75;
    margin-left: 0px;

    .svg-icon {
      margin-right: 7px;
      display: inline-block;
      vertical-align: sub;
    }
  `}

  ${(props) =>
    props.large &&
    `
    font-size: 14px;
    line-height: 1.75;
    margin-left: 0px;

    .svg-icon {
      margin-right: 7px;
      display: inline-block;
      vertical-align: sub;
    }
  `}
`;

export const CardListDescription = styled.ul`
  ${(props) =>
    props.custombullets &&
    `
    list-style-type: none;
  `}
`;
