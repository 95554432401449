/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import FormArchive from "components/form/FormArchive";

/**
 * @class      ModalActivation (name)
 * @param      {Object} / The properties
 * @return     {JSX}
 */
const ModalActivation = (props) => {
  return (
    <ModalFrame>
      <FormArchive />
    </ModalFrame>
  );
};

export default ModalActivation;
