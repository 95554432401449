/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import { Link } from "react-router-dom";
import theme from "../theme";

// background:  linear-gradient(90deg, #3269D6 0%, #3CABFF 100%);
// box-shadow: 0 2px 79px 0 rgba(0,0,0,0.10);
// 0 2px 1px 0 rgba(10, 0, 0, 0.12);
const Header = styled.header.attrs({
  className: "header",
})`
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 25px;
  background: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.13);

  ${media.mobile`
		padding: 4px 10px;
	`}
`;

export const HeaderAside = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
  height: 30px;

  ${(props) =>
    props.isFixed &&
    `
		position: fixed;
		z-index: 105;

		& + div {
			margin-left: 232px;
		}
	`}

  ${media.tablet_portrait`
		min-width: 160px;

		${(props) =>
      props.isFixed &&
      `
			& + div { margin-left: 160px; }
		`}
	`};

  ${media.mobile`
		left: 10px;
		min-width: 140px;

		${(props) =>
      props.isFixed &&
      `
			background: #fff;
			left: 0;
			top: 0;
			padding: 20px 10px;
			height: 60px;
			width: 100%
			border-bottom: 1px solid #9B9B9B;
		`}
	`}
`;

export const HeaderActions = styled.div`
  display: flex;
  margin-left: auto;
  button {
    background: none;
    border: none;
  }

  button + button {
    margin-left: 17px;
  }
`;

export const BtnNotification = styled.button`
  visibility: hidden;
  display: block;
  color: #96a0b2;
`;

export const BtnInfo = styled(Link)`
  display: block;
  color: #96a0b2;
  margin-top: 15px;
`;

export const BtnUser = styled.button`
  display: block;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 20px;
  margin-right: -7px;
  border: 2px solid transparent !important;

  &:focus {
    border: 2px solid #0b289b !important;
  }
`;

export default Header;
