/**
 * @ The external dependecies
 */
import React from "react";

/**
 * The internal dependencies.
 */
import ModalFrame from "./ModalFrame";
import FormCreateUser from "components/form/FormCreateUser";

/**
 * Class for modal create user.
 *
 * @class      ModalCreateUser (name)
 */
const ModalCreateUser = (props) => {
  return (
    <ModalFrame>
      <FormCreateUser />
    </ModalFrame>
  );
};

export default ModalCreateUser;
