/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

/**
 * The internal dependencies.
 */
import stemifyRoutes from "constants/routes";
import DocumentTitle from "react-document-title";
import { NoDataMessageContainer, NoDataMessage } from "styles/components/Card";
import Loader from "components/common/loader";
import IconSvgComponent from "components/common/IconSvgComponent";
import SectionGroups, {
  SectionHead,
  SectionBody,
  SectionTitle,
} from "styles/components/SectionGroups";

import DisciplineCard, {
  DisciplineCards,
  DisciplineCardTitle,
  DisciplineCardImage,
  DisciplineCardContent,
  DisciplineCardRoute,
  DisciplineCardEntry,
} from "styles/components/DisciplineCard";

import {
  getLearningUnitsOfProgram,
  getLearningModulesByFilter,
} from "store/state/learningLibrary/actions";

import LearningDisciplineSubject from "./LearningDisciplineSubject";
import { FormattedText } from "components/common/FormattedText";
import { IMAGE_S3_SERVER, THUMBNAILS } from "constants/api";
/**
 * Class for deployment dashboard.
 *
 * @class      LearningDiscipline (name)
 */
class LearningDiscipline extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */

  componentDidMount() {
    this.initializeModule();
  }

  initializeModule = () => {
    const { getLearningUnitsOfProgram, getLearningModulesByFilter, location } = this.props;
    const searchParams = queryString.parse(location.search);

    if (searchParams?.type) {
      // Fetch learning modules by topic and render
      getLearningModulesByFilter({ ...searchParams });
    } else {
      getLearningUnitsOfProgram({ programName: "Foundational Math" });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { location: currentLocation } = this.props;
    const { location: prevLocation } = prevProps;

    const currSearchParams = queryString.parse(currentLocation.search);
    const prevSearchParams = queryString.parse(prevLocation.search);

    if (currSearchParams.type !== prevSearchParams.type) {
      this.initializeModule();
    }
  };

  componentWillUnmount() {
    if (this.chartReference) {
      const { chartInstance } = this.chartReference.chartReference;
      chartInstance.destroy();
    }
  }

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const {
      learningLibrary: {
        program: { learningUnits },
      },
      apiCallInProgress,
      isFilteredByTopic,
    } = this.props;

    if (isFilteredByTopic) {
      return apiCallInProgress !== false ? (
        <Loader />
      ) : (
        <LearningDisciplineSubject isFilteredByTopic />
      );
    }

    return (
      <DocumentTitle title="Stemify | Learning Library">
        {!apiCallInProgress && learningUnits && learningUnits.length > 0 ? (
          <SectionGroups>
            <SectionHead>
              <SectionTitle>Learning Library</SectionTitle>
            </SectionHead>

            <SectionBody id={"learningDisciplinesContainer"}>
              <DisciplineCards id={"learningDisciplineCardsList"}>
                {learningUnits.map((learningUnit) => (
                  <DisciplineCard id={learningUnit.id} key={learningUnit.id}>
                    <DisciplineCardRoute to={`${stemifyRoutes.learningLibrary}/${learningUnit.id}`}>
                      <DisciplineCardImage
                        image={`${IMAGE_S3_SERVER}${THUMBNAILS}${learningUnit.image}`}
                      />
                      <DisciplineCardContent>
                        <DisciplineCardTitle id={"learningDisciplineCardTitle"}>
                          <FormattedText text={learningUnit.name}></FormattedText>
                        </DisciplineCardTitle>

                        <DisciplineCardEntry>
                          <IconSvgComponent
                            additionalClass="ico-svg-play"
                            iconPath="svg/ico-play-blue.svg"
                          />
                          {learningUnit.lessonsCount} Video{" "}
                          {learningUnit.lessonsCount > 1 ? "Lessons " : "Lesson"}
                        </DisciplineCardEntry>
                      </DisciplineCardContent>
                    </DisciplineCardRoute>
                  </DisciplineCard>
                ))}
              </DisciplineCards>
            </SectionBody>
          </SectionGroups>
        ) : (
          <NoDataMessageContainer>
            {apiCallInProgress !== false && <Loader />}
            {apiCallInProgress == false && (
              <NoDataMessage id={"noDataMsgWrapper"}>
                No Learning Library content <br /> available at this time.
              </NoDataMessage>
            )}
          </NoDataMessageContainer>
        )}
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => ({
    isFilteredByTopic: state.learningLibrary.isFilteredByTopic,
    learningLibrary: state.learningLibrary,
    apiCallInProgress: state.learningLibrary.apiCallInProgress.learningLibrary,
  }),
  {
    getLearningUnitsOfProgram,
    getLearningModulesByFilter,
  }
)(LearningDiscipline);
