import { contentType, contentTypeJSON } from "constants/api";
import { fetchAPICall, apiUrl } from "utilities/API";
import { getAccessToken } from "utilities/localStore";

export const fetchQuestions = async ({ accountId, assignmentId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/assignment/${assignmentId}`, // 6 and 7 available
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const fetchQuestionsForAssignment = async ({ accountId, assignmentId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/assignment/${assignmentId}/begin`,
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
  });
};

export const attemptQuestion = async ({
  accountId,
  assignmentId,
  attemptId,
  questionId,
  answerId,
}) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/assignment/${assignmentId}/response`,
    method: "PUT",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
    body: JSON.stringify({
      student_practice_attempt_id: attemptId,
      question_id: questionId,
      answer_id: answerId,
    }),
  });
};

export const attemptQuestionForAssignment = async ({
  accountId,
  assignmentId,
  attemptId,
  questionId,
  answerId,
}) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/assignment/${assignmentId}/question_response`,
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentTypeJSON,
      account_id: accountId,
    },
    body: JSON.stringify({
      attempt_id: attemptId,
      question_id: questionId,
      answer_id: answerId,
    }),
  });
};

export const submit = async ({ accountId, assignmentId, attemptId }) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `/assignment/${assignmentId}/result`,
    method: "PUT",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": contentType,
      account_id: accountId,
    },
    body: JSON.stringify({
      student_practice_attempt_id: attemptId,
    }),
  });
};
