/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";

export const SectionTitle = styled.h3``;

export const SectionContent = styled.div``;

export const SectionBody = styled.div``;

export const SectionActions = styled.div`
  text-align: center;
`;

export const DeploymentActions = styled(SectionActions)`
  display: flex;
`;

const Section = styled.section``;

export const SectionWizard = styled(Section)`
  ${SectionTitle} {
    margin-bottom: 16px;
    line-height: 1.67;
    text-align: center;
    color: #484848;
    font-weight: bolder;
    font-size: x-large;
  }

  ${(props) =>
    props.md &&
    css`
      ${SectionBody} {
        max-width: 500px;
        margin: 0 auto;
      }
    `}

  ${(props) =>
    props.lg &&
    css`
      ${SectionBody} {
        max-width: 660px;
        margin: 0 auto;
      }
    `}
`;

export default Section;
