/**
 * The external dependencies.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * The internal dependencies.
 */
import CreateDeployment from "./CreateDeployment";
import DeploymentSummary from "./DeploymentSummary";

/**
 * Class for wizard deployment.
 *
 * @class      WizardDeployment (name)
 */
class WizardDeployment extends Component {
  /**
   * The properties that can be passed to the component.
   *
   * @type {Object}
   */
  static propTypes = {
    step: PropTypes.string.isRequired,
    changeStep: PropTypes.func.isRequired,
  };

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const { changeStep, isEdit } = this.props;
    const WizardStepComponent = this.lookupWizardStepComponent();
    return <WizardStepComponent changeStep={changeStep} isEdit={isEdit ? isEdit : false} />;
  }

  /**
   * Lookup for wizard step component.
   */
  lookupWizardStepComponent() {
    const { step } = this.props;
    const { steps } = WizardDeployment;

    return steps[step];
  }
}

/**
 * The supported wizard steps.
 *
 * @type {Object}
 */
WizardDeployment.steps = {
  CreateDeployment,
  DeploymentSummary,
};

export default WizardDeployment;
