/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

const SectionTable = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;

  .button-add {
    position: absolute;
    right: 38px;
    top: -20px;
    z-index: 20;
  }

  ${media.tablet_portrait`
		padding: 30px 15px;

		.button-add {
			right: 15px;
		}
	`};
`;

export const SectionHead = styled.header`
  position: relative;
  flex: 0 0 auto;
  min-height: 47px;
  background: #fff;
  margin-bottom: 20px;
  padding: 15px 20px 13px 20px;
  overflow: hidden;
  border-radius: ${theme.border_radius};
  ${(props) =>
    props.active &&
    `
		padding: 16px 20px;
		overflow: visible;
	`}
`;

export const SectionHeadInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.mobile`
		display: block;
	`};
`;

export const SectionMeta = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	transition: opacity .3s, visibility .3s;

	span {
		font-size: 10px;
		font-weight: bold;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #4A4A4A;
	}

	@media(max-width: 1023px) {
		& {
			display: block;
		}
	}

	${(props) =>
    props.edit &&
    `
		justify-content: flex-start;
		align-items: flex-start;

		span {
			flex: 0 0 auto;
		}

		.form {
			max-width: 100%;
			flex: 1 1 100%;
		}

		@media(max-width: 1235px) {
			display: block;
		}

		@media(max-width: 1023px) {
			.form {
				max-width: 400px;
				flex: 1 1 400px;
			}
		}
	`}

	${(props) =>
    props.hidden &&
    `
		position: absolute;
		left: 20px;
		top: 46px;
		right: 20px;
		opacity: 0;
		visibility: hidden;
	`}

	${(props) =>
    props.active &&
    `
		opacity: 1;
		visibility: visible;
	`}

	${(props) =>
    props.right &&
    `
		margin-left:151px;
	`};

	${media.mobile`
		display: block;
	`};
`;

export const SectionMetaAside = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	span + span {
		margin-left: 30px;
	}

	${(props) =>
    props.left &&
    `
		float: left;
	`}

	${(props) =>
    props.hidden &&
    `
		visibility: hidden;
		display:none;
	`}

	${media.mobile`
		display: block;
		margin-top: 10px;

		span {
			display: block;
		}

		span + span {
			margin-left: 0;
			margin-top: 10px;
		}
	`};
`;

export const SectionMetaContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 555px;
  color: #8013e0;

  ${(props) =>
    props.hidden &&
    `
		display: none;
	`}

  ${media.tablet_portrait`
		display: block;
		width: 100%;

		span {
			display: block;
		}

		span + span {
			margin-left: 0;
			margin-top: 10px;
		}
	`};
`;

export const SectionTitle = styled.h3`
  ${media.tablet_portrait`
		margin-bottom: 10px;
	`};
`;

export const SectionActions = styled.div`
  ${media.mobile`
		a,
		button {
			display: block;
			width: 100%;
		}
	`};
`;

export const SectionBody = styled.div`
  flex: 1 1 100%;
  border-radius: ${theme.border_radius};
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  text-align: center;
`;

export default SectionTable;
