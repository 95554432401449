import React from "react";
import {
  StyledFieldErrorSection,
  StyledFieldErrorTitle,
  StyledFieldErrorList,
  StyledFieldError,
  StyledGenericError,
} from "styles/components/Form";
import { prop } from "ramda";

const FieldErrors = (props) => {
  const { apiErrorMessage, formErrors, slider, width } = props;
  return !formErrors.formValid ? (
    <StyledFieldErrorSection slider={slider} width={width}>
      <StyledFieldErrorTitle>
        Please address the following error/s and resubmit the form.
      </StyledFieldErrorTitle>
      <StyledFieldErrorList>
        {Object.keys(formErrors).map(function(fieldName) {
          return (
            fieldName !== "formValid" &&
            !formErrors[fieldName].valid &&
            formErrors[fieldName].message && (
              <StyledFieldError key={fieldName}>{formErrors[fieldName].message}</StyledFieldError>
            )
          );
        })}
      </StyledFieldErrorList>
    </StyledFieldErrorSection>
  ) : apiErrorMessage ? (
    <StyledGenericError id={props.id} slider={slider} width={width}>
      {apiErrorMessage}
    </StyledGenericError>
  ) : (
    ""
  );
};
export default FieldErrors;
