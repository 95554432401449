import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";

import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import CourseSubnav from "components/courses/CourseSubnav";
import CourseDetailsBlock, {
  CourseDetailsBody,
  CourseDetailsGroup,
  CourseDetailsGroupHead,
  CourseDetailsGroupBody,
  CourseDetailsTitle,
} from "styles/components/CourseDetails";
import CourseTabs, { CourseTab, CourseTabsContent } from "styles/components/CourseTabs";
import TableDropdown from "components/elements/TableDropdown";
import { openModal, closeModal } from "store/state/ui/actions";
import {
  setActiveCourse,
  getAssignedCourseUsers,
  removeUserFromCourse,
  getEnrolledCourseStudents,
  addUsersToCourse,
  enrollCourseStudents,
  removeEnrolledCourseStudents,
  getSectionById,
} from "store/state/courses/actions";
import TableDefault, {
  TableContainer,
  TableWrapper,
  TableHead,
  TableBody,
  TableRow,
  TableTh,
  TableTd,
} from "styles/components/TableDefault";
import { MODAL_ADD_USERS, MODAL_CONFIRMATION } from "lib/constants";
import Loader from "components/common/loader";
import { ROLES } from "constants/roles";
import Can from "components/common/Can";

class CourseDetailsPeople extends Component {
  viewTableItem = (id) => {
    console.log("view", id);
  };

  removeInstructor = (id, userRole, name) => {
    const { courseId } = this.props.match.params;
    const { accountId, openModal, removeUserFromCourse } = this.props;
    openModal({
      type: MODAL_CONFIRMATION,
      data: {
        id: id,
        title: name,
        icon: "ico-user-x.svg",
        type: "remove",
        action: () => removeUserFromCourse({ accountId, courseId, userName: id, userRole }),
      },
    });
  };

  removeEnrolledStudent = (username, fullname) => {
    const { courseId } = this.props.match.params;
    const { accountId, openModal, removeEnrolledCourseStudents } = this.props;
    openModal({
      type: MODAL_CONFIRMATION,
      data: {
        title: fullname,
        icon: "ico-user-x.svg",
        type: "remove",
        action: () => removeEnrolledCourseStudents({ accountId, courseId, username }),
      },
    });
  };

  handleAddButtonClick = (userRole, size) => {
    const {
      openModal,
      accountId,
      activeCourseId,
      addUsersToCourse,
      enrollCourseStudents,
      assignedInstructors,
      assignedStudents,
    } = this.props;
    const existingUsersInList = userRole === "Instructor" ? assignedInstructors : assignedStudents;
    const addUserAction = userRole === "Instructor" ? addUsersToCourse : enrollCourseStudents;

    openModal({
      type: MODAL_ADD_USERS,
      data: {
        modalSize: size,
        accountId: accountId,
        courseId: activeCourseId,
        userRole,
        existingUsersInList: existingUsersInList,
        implementUserHierarchy: true,
        includeInactiveUsers: true,
        addUserAction: addUserAction,
        selector: userRole === "Instructor" ? "assignedInstructors" : "assignedStudents",
      },
    });
  };

  async componentDidMount() {
    const {
      getAssignedCourseUsers,
      getEnrolledCourseStudents,
      getSectionById,
      setActiveCourse,
      activeCourseId,
      accountId,
      match,
    } = this.props;
    const { courseId } = match.params;

    // Detect fresh page reload via activeCourseId
    if (!activeCourseId) {
      getSectionById({ accountId, courseId, selector: "sections" });
    }
    getAssignedCourseUsers({ courseId });
    getEnrolledCourseStudents({ courseId });
  }

  render() {
    const { currentCourse, userRole } = this.props;
    const { assignedInstructors, assignedStudents, apiCallInProgress } = this.props;

    return !currentCourse ? null : (
      <DocumentTitle title="Stemify | Course">
        <CourseTabs>
          <CourseSubnav />

          <CourseTabsContent>
            {apiCallInProgress == true && <Loader />}
            {apiCallInProgress == false && (
              <CourseTab active={true}>
                <CourseDetailsBlock>
                  <CourseDetailsBody>
                    <CourseDetailsGroup>
                      <CourseDetailsGroupHead>
                        <CourseDetailsTitle>Instructors</CourseDetailsTitle>

                        <Can
                          role={userRole}
                          perform={"courses-people:add"}
                          yes={() => (
                            <ButtonLink
                              transparent
                              type="button"
                              className="btn-add-item"
                              onClick={() => this.handleAddButtonClick("Instructor", "mdx")}
                            >
                              <IconSvgComponent
                                iconPath={`svg/ico-plus.svg`}
                                additionalClass={"icon-add"}
                              />

                              <span>Add Instructor</span>
                            </ButtonLink>
                          )}
                        />
                      </CourseDetailsGroupHead>

                      <CourseDetailsGroupBody>
                        <TableContainer isCourseTable>
                          <TableWrapper noTopBorder>
                            <TableDefault boxed>
                              <TableHead>
                                {assignedInstructors && assignedInstructors.length > 0 ? (
                                  <TableRow>
                                    <TableTh>Name</TableTh>
                                    <TableTh>Role</TableTh>
                                    <TableTh>Email</TableTh>
                                    <Can
                                      role={userRole}
                                      perform={"courses-people:remove"}
                                      yes={() => <TableTh>&nbsp;</TableTh>}
                                    />
                                  </TableRow>
                                ) : (
                                  <TableRow>
                                    <TableTh>There are no Instructors yet.</TableTh>
                                  </TableRow>
                                )}
                              </TableHead>

                              <TableBody>
                                {assignedInstructors &&
                                  assignedInstructors.map((instructor) => (
                                    <TableRow key={instructor.id}>
                                      <TableTd>{instructor.name}</TableTd>

                                      <TableTd>{instructor.role}</TableTd>

                                      <TableTd>
                                        <span>{instructor.email}</span>
                                      </TableTd>

                                      <Can
                                        role={userRole}
                                        perform={"courses-people:remove"}
                                        yes={() => (
                                          <TableTd>
                                            <TableDropdown
                                              options={{
                                                items: [
                                                  {
                                                    callback: () =>
                                                      this.removeInstructor(
                                                        instructor.id,
                                                        instructor.role,
                                                        instructor.name
                                                      ),
                                                    btnText: "Remove Instructor",
                                                  },
                                                ],
                                              }}
                                            />
                                          </TableTd>
                                        )}
                                      />
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </TableDefault>
                          </TableWrapper>
                        </TableContainer>
                      </CourseDetailsGroupBody>
                    </CourseDetailsGroup>

                    <CourseDetailsGroup>
                      <CourseDetailsGroupHead>
                        <CourseDetailsTitle>Students</CourseDetailsTitle>

                        <Can
                          role={userRole}
                          perform={"courses-people:add"}
                          yes={() => (
                            <ButtonLink
                              transparent
                              type="button"
                              className="btn-add-item"
                              onClick={() => this.handleAddButtonClick("Student", "xl")}
                            >
                              <IconSvgComponent
                                iconPath={`svg/ico-plus.svg`}
                                additionalClass={"icon-add"}
                              />

                              <span>Add Student</span>
                            </ButtonLink>
                          )}
                        />
                      </CourseDetailsGroupHead>

                      <CourseDetailsGroupBody>
                        <TableContainer isCourseTable>
                          <TableWrapper noTopBorder>
                            <TableDefault boxed>
                              <TableHead>
                                {assignedStudents && assignedStudents.length > 0 ? (
                                  <TableRow>
                                    <TableTh>Name</TableTh>
                                    <TableTh>Email</TableTh>
                                    <Can
                                      role={userRole}
                                      perform={"courses-people:remove"}
                                      yes={() => <TableTh>&nbsp;</TableTh>}
                                    />
                                  </TableRow>
                                ) : (
                                  <TableRow>
                                    <TableTh>There are no Students yet.</TableTh>
                                  </TableRow>
                                )}
                              </TableHead>

                              <TableBody>
                                {assignedStudents &&
                                  assignedStudents.map((student) => (
                                    <TableRow key={student.id}>
                                      <TableTd>{student.name}</TableTd>

                                      <TableTd>
                                        <span>{student.email}</span>
                                      </TableTd>

                                      <Can
                                        role={userRole}
                                        perform={"courses-people:remove"}
                                        yes={() => (
                                          <TableTd>
                                            <TableDropdown
                                              options={{
                                                items: [
                                                  {
                                                    callback: () =>
                                                      this.removeEnrolledStudent(
                                                        student.id,
                                                        student.name
                                                      ),
                                                    btnText: "Remove Student",
                                                  },
                                                ],
                                              }}
                                            />
                                          </TableTd>
                                        )}
                                      />
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </TableDefault>
                          </TableWrapper>
                        </TableContainer>
                      </CourseDetailsGroupBody>
                    </CourseDetailsGroup>
                  </CourseDetailsBody>
                </CourseDetailsBlock>
              </CourseTab>
            )}
          </CourseTabsContent>
        </CourseTabs>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const { courseId } = ownProps.match.params;
    const currentCourse = Object.values(state.storedCourses.sections).find(
      (item) => item.id === courseId
    );
    const assignedInstructors = Object.values(state.storedCourses.assignedInstructors).filter(
      (item) => {
        return item.sectionId === state.storedCourses.activeCourseId;
      }
    );
    // Matt: Only use active students
    const assignedStudents = Object.values(state.storedCourses.assignedStudents).filter(
      (student) => student.isActive && student.sectionId === currentCourse.id
    );

    return {
      currentCourse,
      assignedInstructors,
      assignedStudents,
      modal: state.ui.modal,
      userRole: state.user.role,
      accountId: state.user.accountSwitched ? state.user.currentAccountId : state.user.accountId,
      apiCallInProgress: state.storedCourses.apiCallInProgress,
      activeCourseId: state.storedCourses.activeCourseId,
    };
  },
  {
    openModal,
    closeModal,
    setActiveCourse,
    getAssignedCourseUsers, //Instructors
    removeUserFromCourse,
    getEnrolledCourseStudents,
    addUsersToCourse,
    enrollCourseStudents,
    removeEnrolledCourseStudents,
    getSectionById,
  }
)(CourseDetailsPeople);
