import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import moment from "moment";
import { utils, writeFile } from "xlsx";

import { MODAL_WIZARD, WIZARD_DEPLOYMENT, WIZARD_CREATE_DEPLOYMENT } from "lib/constants";
import { openModal } from "store/state/ui/actions";
import { updateDeploymentState } from "store/state/deployment/actions";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import SectionTable, {
  SectionBody,
  SectionHead,
  SectionTitle,
} from "styles/components/SectionTable";
import TableDropdown from "components/elements/TableDropdown";
import Can from "../common/Can";
import { StyledSpan } from "styles/components/TableDefault";
import { TextLink } from "styles/components/Btns";
import { PaginatedTable, NoDataComponent } from "components/common/table";
import {
  requestClearUpdateRowId,
  getDeployments,
  getAccount,
} from "store/state/administration/actions";
import StatusPill from "components/elements/StatusPill";
import stemifyRoutes from "../../constants/routes";

import { getAttempts } from "store/state/assessment/actions";
import { getAttemptExportData } from "services/assessment";
import styled from "styled-components";
import Popover from "components/common/Popover";

const ExportButton = styled.button`
  background-color: #ff3e1d;
  border-radius: 6px;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  outline: none;
  border: none;
  display: block;
  box-sizing: border-box;

  &:hover {
    opacity: 0.8;
  }
`;

class Deployments extends Component {
  state = {
    isPageLoading: true,
    isFetchingExportData: false,
    isFetchingMoreData: false,
    exportFormat: "csv",
  };

  fetchData = async () => {
    const { getDeployments, getAccount, accountId } = this.props;

    this.setState({ isPageLoading: true });

    try {
      const accountPromise = getAccount({
        accountId,
      });

      const deploymentPromise = getDeployments({
        selector: "deployments",
        accountId,
        pageSize: 200,
        page: 1,
      });

      await Promise.all([accountPromise, deploymentPromise]);

      this.setState({ isPageLoading: false });
    } catch (error) {
      console.log({ error });
      this.setState({ isPageLoading: false });
    }
  };

  columns = [
    {
      id: "name",
      Header: "Name",
      minWidth: 250,
      accessor: "name",
      Cell: (props) => {
        const { original } = props.row;
        const OptionButton = original.status === "Draft" ? TextLink : ButtonLink;

        const ButtonProps =
          original.status === "Draft"
            ? { onClick: this.edit(original), noLeftPadding: true }
            : {
                isRouterLink: true,
                noLeftPadding: true,
                to: `${stemifyRoutes.deployments}/${original.id}`,
              };

        return (
          <OptionButton
            {...ButtonProps}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <IconSvgComponent
              additionalClass={"accounts-building-icon"}
              iconPath="svg/ico-rocket-colored.svg"
            />
            <StyledSpan
              hasIconOnLeft
              bold
              style={{
                width: "fit-content",
                whiteSpace: "nowrap",
                overflowX: "clip",
                textOverflow: "ellipsis",
              }}
            >
              {original["name"]}
            </StyledSpan>
          </OptionButton>
        );
      },
    },
    {
      id: "program",
      Header: "Program",
      accessor: "program",
      minWidth: 200,
    },
    {
      id: "cohort",
      Header: "Cohort",
      accessor: "cohort",
      minWidth: 175,
    },
    {
      id: "cohortNumber",
      Header: "# in Cohort",
      accessor: "cohortNumber",
      minWidth: 50,
      Cell: (props) => {
        const { original } = props.row;
        return original["cohortNumber"] ? original["cohortNumber"] : 0;
      },
    },
    {
      id: "startDate",
      Header: "Start Date",
      accessor: "startDate",
      minWidth: 75,
      Cell: (props) => {
        const { original } = props.row;
        return original["startDate"] ? moment(original["startDate"]).format("MM.DD.YY") : "-";
      },
    },
    {
      id: "endDate",
      Header: "End Date",
      accessor: "endDate",
      minWidth: 75,
      Cell: (props) => {
        const { original } = props.row;
        return original["endDate"] ? moment(original["endDate"]).format("MM.DD.YY") : "-";
      },
    },
    {
      id: "Status",
      Header: "Status",
      accessor: "status",
      minWidth: 100,
      Cell: (props) => {
        const { original } = props.row;

        return <StatusPill type="deployment" status={original.status} />;
      },
    },
  ];

  dropdownColumn = {
    id: "dropdown",
    Header: "",
    minWidth: 50,
    style: {
      display: "flex",
      justifyContent: "flex-end",
    },
    Cell: (props) => {
      const {
        yPositionStart,
        tableBodyRef,
        row: { original: deployment },
      } = props;

      return (
        <Popover
          placement="bottom-start"
          portalRef={tableBodyRef}
          yPositionStart={yPositionStart}
          TriggerComponent={(props) => {
            const { setRef, ...triggerProps } = props;

            return (
              <ButtonLink setRef={setRef} {...triggerProps} isTableDropdownButton>
                <span />
                <span />
                <span />
              </ButtonLink>
            );
          }}
          actions={[
            {
              name: "Edit",
              onClick: this.edit(deployment),
            },
          ]}
        />
      );
    },
  };

  edit = (deployment) => (e) => {
    e.preventDefault();

    const { updateDeploymentState, openModal } = this.props;

    deployment = {
      ...deployment,
      program: { value: deployment.programId, label: deployment.program },
      cohort: { value: deployment.cohortId, label: deployment.cohort },
    };
    updateDeploymentState(deployment);

    openModal({
      type: MODAL_WIZARD,
      data: {
        id: deployment.id,
        status: deployment.status,
        wizardType: WIZARD_DEPLOYMENT,
        initialStep: WIZARD_CREATE_DEPLOYMENT,
        isEdit: true,
        editDeployment: true,
      },
    });
  };

  handleGetExportData = async () => {
    const { accountId, accountName } = this.props;
    const { exportFormat } = this.state;

    this.setState({ isFetchingExportData: true });

    try {
      const fileDate = moment().format("MM-DD-YYYY");
      const filePrefix = accountName
        ? `${accountName} Attempt Data as of ${fileDate}`
        : `Attempt Data as of ${fileDate}`;

      const initialPageSize = 7500;
      let rows = [];
      const result = await getAttemptExportData({ accountId, page: 1, pageSize: initialPageSize });

      // const formattedAttempts = attempts.map((attempt) => {
      if (result.status === "success") {
        // Add first fetch to dataset
        rows.push(...result.data.rows);

        // 80,000 - 10,000 = 70,000 => true
        const totalCount = result.data.count;
        const needToFetchMoreData = totalCount > initialPageSize;

        if (needToFetchMoreData) {
          this.setState({ isFetchingMoreData: true });

          // 1...999,123
          const missingRecordCount = totalCount - initialPageSize;

          // 10,000 / 70,000 = 7
          // 10,000 / 70,001 = 8
          // 10,000 / 9,000 = 1
          const batchGetSize = Math.ceil(missingRecordCount / initialPageSize);
          let batchPromises = [];
          for (let i = 1; i <= batchGetSize; i++) {
            batchPromises.push(
              getAttemptExportData({
                accountId,
                page: i + 1, // since we already fetched page 1
                pageSize: initialPageSize,
              })
            );
          }

          const batchResults = await Promise.all(batchPromises);
          batchResults.forEach((batchResult) => rows.push(...batchResult.data.rows));
        }

        const json = rows.map((row) => {
          return {
            ...row,
            cutoff_range: row.cutoff_range
              ? row.cutoff_range.reduce((finalString, currentCutoff) => {
                  if (row.score >= currentCutoff.min && !finalString) {
                    return currentCutoff.course;
                  }

                  if (row.score >= currentCutoff.min) {
                    return `${finalString}, ${currentCutoff.course}`;
                  }

                  return finalString;
                }, "")
              : "",
            start_time: row.start_time ? moment(row.start_time).format("MM/DD/YYYY h:mma") : "",
            start_time_raw: row.start_time ? row.start_time : "",
          };
        });
        const headers = {
          external_id: "ExternalId",
          user_name: "Username",
          email: "Email",
          name: "FirstName",
          family_name: "LastName",
          assessment_name: "AssessmentName",
          attempt_number: "AttemptNumber",
          start_time: "AssessmentDate",
          start_time_raw: "AssessmentDateRaw",
          score: "Score",
          time_limit: "TimeLimit",
          cutoff_range: "Courses",
        };
        const orderedKeys = [
          "external_id",
          "user_name",
          "email",
          "name",
          "family_name",
          "assessment_name",
          "attempt_number",
          "start_time",
          "start_time_raw",
          "score",
          "time_limit",
          "cutoff_range",
        ];

        // Create worksheet from attempt data
        const worksheet = utils.json_to_sheet(json, {
          header: orderedKeys,
        });

        // Rename header row cells
        utils.sheet_add_aoa(worksheet, [orderedKeys.map((key) => headers[key])], { origin: "A1" });

        // Adjust column widths
        worksheet["!cols"] = [
          { wch: 10 },
          { wch: 20 },
          { wch: 35 },
          { wch: 15 },
          { wch: 15 },
          { wch: 20 },
          { wch: 15 },
          { wch: 20 },
          { wch: 20 },
          { wch: 7 },
          { wch: 10 },
          { wch: 50 },
        ];

        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Attempts");

        if (exportFormat === "csv") {
          utils.sheet_to_csv(worksheet);
          writeFile(workbook, `${filePrefix}.csv`, { compression: true, bookType: "csv" });
        } else {
          writeFile(workbook, `${filePrefix}.xlsx`, { compression: true, bookType: "xlsx" });
        }
      }
      this.setState({ isFetchingExportData: false, isFetchingMoreData: false });
    } catch (error) {
      console.log("There was an error fetching attempts", { error });
      this.setState({ isFetchingExportData: false, isFetchingMoreData: false });
    }
  };

  render() {
    const { deployments, openModal, userRole, table } = this.props;
    const { count } = table;
    const { isPageLoading, exportFormat, isFetchingExportData, isFetchingMoreData } = this.state;
    const columns = Can({
      role: userRole,
      perform: "deployments:edit",
      yes: () => this.columns.concat(this.dropdownColumn),
      no: () => this.columns,
    });

    return (
      <DocumentTitle title="Stemify | Deployment">
        <SectionTable>
          <SectionHead
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              overflow: "visible",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "25px",
                flex: "1 1 auto",
              }}
            >
              <SectionTitle style={{ marginBottom: "0px" }}>Deployments</SectionTitle>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "5px",
                  marginLeft: "25px",
                }}
              >
                <div style={{ fontWeight: 700 }}>Export All Attempts</div>
                <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
                  <label
                    htmlFor="exportFormat"
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    <span>Format: </span>
                    <select
                      id="exportFormat"
                      onChange={(event) => this.setState({ exportFormat: event.target.value })}
                      value={exportFormat}
                      disabled={isFetchingExportData}
                      style={{
                        fontSize: "12px",
                        border: "1px solid rgba(0, 0, 0, .1)",
                        backgroundColor: "#fff",
                        padding: "2px 4px",
                        borderRadius: "3px",
                        fontWeight: 400,
                        outlineWidth: 0,
                      }}
                    >
                      <option value="csv">CSV</option>
                      <option value="xlsx">Excel</option>
                    </select>
                  </label>

                  {isFetchingExportData ? (
                    <div style={{ display: "flex", width: "200px", gap: "7px" }}>
                      <div
                        className="loader"
                        style={{
                          // width: "23px",
                          borderTop: "2px solid #3269d6",
                          borderRight: "2px solid #3269d6",
                          borderBottom: "2px solid #3269d6",
                          borderLeft: "2px solid #ffffff",
                        }}
                      />
                      {isFetchingMoreData && (
                        <div style={{ width: "175px" }}>Fetching additional data...</div>
                      )}
                    </div>
                  ) : (
                    <ExportButton isLoading onClick={this.handleGetExportData}>
                      Export
                    </ExportButton>
                  )}
                </div>
              </div>
            </div>

            <Can
              role={userRole}
              perform={"deployments:create"}
              yes={() => (
                <ButtonLink
                  onClick={() =>
                    openModal({
                      type: MODAL_WIZARD,
                      data: {
                        wizardType: WIZARD_DEPLOYMENT,
                        initialStep: WIZARD_CREATE_DEPLOYMENT,
                      },
                    })
                  }
                  violet
                  size="43"
                >
                  Create Deployment
                </ButtonLink>
              )}
            />
          </SectionHead>

          <SectionBody>
            <PaginatedTable
              data={deployments}
              serverSidePagination={false}
              fetchData={this.fetchData}
              count={count}
              isLoading={isPageLoading}
              columns={columns}
              NoDataComponent={() => (
                <div style={{ height: "487px", padding: "40px 24px" }}>
                  <NoDataComponent
                    type="Deployment"
                    iconPath="svg/ico-rocket-dark-lg.svg"
                    userRole={userRole}
                    roleRule={"deployments:create"}
                    onClickAction={() =>
                      openModal({
                        type: MODAL_WIZARD,
                        data: {
                          wizardType: WIZARD_DEPLOYMENT,
                          initialStep: WIZARD_CREATE_DEPLOYMENT,
                        },
                      })
                    }
                  />
                </div>
              )}
            />
          </SectionBody>
        </SectionTable>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => ({
    accountName: state.administration.accounts[0]?.organization,
    deployments: state.administration.deployments,
    modal: state.ui.modal,
    apiCallInProgress: state.administration.apiCallInProgress,
    table: state.administration.deploymentsTable,
    accountId: state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId,
    currentAccountId: state.user.currentAccountId,
    updatedRowId: state.administration.updatedRowId,
    userRole: state.user.role,
  }),
  {
    updateDeploymentState,
    openModal,
    requestClearUpdateRowId,
    getDeployments,
    getAttempts,
    getAccount,
  }
)(Deployments);
