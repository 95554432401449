import styled, { css } from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import { media } from "styles/media";
import ReactJWPlayer from "react-jw-player";

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0.8;
  color: #ffffff;
  background-color: #040404;
  z-index: 2;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  cursor: pointer;

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    left: 4.6%;
    right: 4.6%;
    overflow-y: scroll;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    left: 3.6%;
    right: 3.6%;
    overflow-y: scroll;
  }

  ${media.mobile`
	display:block;
	overflow-y:auto;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  border: none;
`;

export const QuizWrap = styled.div`
  // text-align: center;
  // height: 100%;
  font-size: 19px;
  font-weight: 500;
  font-family: "Circular Pro";
  // padding: 20px 20px 20px 20px;
  // margin-top: 20px;

  .svg-icon svg {
    pointer-events: none;
  }
  ${(props) =>
    props.isFeedback &&
    `
      height: 100%
	`}
`;

export const QuizAnswerStyle = styled.div`
  margin-left: 48%;
`;

export const QuizTitle = styled.span`
  width: 542px;
  color: #ffffff;
  font-family: "Circular Pro";
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
`;

export const QuizFeedback = styled.h1`
  height: 55px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  font-size: 43px;
  font-weight: 405;
  line-height: 55px;
  text-align: center;
`;

export const VideoPlaybackNetworkError = styled.h1`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
`;

export const QuizFeedbackText = styled.h3`
  height: 60px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 28px;
  font-weight: 405;
  line-height: 30px;
  text-align: center;
`;

export const QuizAnswerWrapper = styled.div`
  .svg-icon {
    float: left;
    margin-right: 10px;
    // margin-left: 30px;
  }
  .hideRadio {
    display: none;
  }
  .showRadio {
    display: block;
  }
  margin-top: 15px;
  ${(props) =>
    props.quizstyle &&
    `
			margin-right:5%;
		`}
`;

export const QuizHead = styled.div`
  // width: 80%;
  // margin-left: 10%;
  height: 100%;
`;

export const QuizFeedbackAnswerSelection = styled.p``;

export const QuizAnswer = styled.label`
  font-size: 19px;
  font-weight: 405;
  line-height: 24px;
  display: inline-block;
  width: 45%;
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    display: inline-block;
    width: 45%;
  }
`;

export const QuizForm = styled.form`
  height: 100%;
`;

export const Formula = styled.span``;

export const QuizFormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const QuizScrollbar = styled(Scrollbars)`
  //  margin-top: 4px !important;
  .scrollbar__track-vertical {
    top: 0;
    right: 0;
    height: 80%;
    width: 4px !important;
    padding-top: 48px;
    margin-right: 100px;
    div {
      border-radius: 4px !important;
      background-color: #bfc3cb !important;
      max-height: 150px !important;
    }
  }

  .scrollbar__track-horizontal {
    display: none !important;
  }
  // margin-top: 35px;

  ${media.tablet`
    // height: auto !important;

    & > div {
      // position: static !important;
    }

    .scrollbar__track-vertical {
      display: none;
    }
  `}
`;

export const OverlayClose = styled.button`
  position: absolute;
  top: 25px;
  z-index: 10;
  right: 25px;
  border: none;
  background: none;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 3px;
  margin-top: -4px;
  margin-right: -4px;

  &:focus {
    border: 2px solid #2049e6;
  }
`;

export const VideoPlayer = styled(ReactJWPlayer)`
${(props) =>
  props.width &&
  css`
    #jw-player-id {
      width: ${props.width}% !important;
    }
  `}
}`;

export const CardQuizVideo = styled.div`
  position: relative;
  ${(props) =>
    props.isQuizOverlayOn &&
    css`
      .jwplayer {
        opacity: 0.3;
        -webkit-filter: blur(4px);
        filter: blur(4px);
      }
    `}
  ${(props) =>
    props.large &&
    css`
    padding: 30px;
    background: none;
    box-shadow: none;
    border: 0 none;
    ${(props) =>
      props.videoquiz &&
      `
    padding-top: 0px;
    padding-bottom: 0px;
    background: none;
    box-shadow: none;
    border: 0 none;
   `}
    ${(props) =>
      props.positionRelative &&
      `
    position:relative;
   `}
    ${(props) =>
      props.vertical &&
      `
        display:inline-block;
      `}
    }
    ${media.mobile`
      padding: 15px;
      ${(props) =>
        props.positionRelative &&
        `
      padding: 0px;
     `}
    `}
  `}
`;

export const VideoQuiz = styled.div`
  // margin-left: 5%;
  // margin-right: 5%;
  // margin-top: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3vw 5vw 1vw 5vw;

  ${media.desktop`
  padding: 5vw 5vw 2vw 5vw;
	`}
`;

export const QuizImage = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const VideoQuizQuestion = styled.div`
  margin-bottom: 35px;
`;

export const FeedBackWrap = styled.div`
  text-align: center;
`;

export default Wrapper;
