import React from "react";
import { Pill } from "styles/components/Btns";

const AssignmentStatusPill = ({ isReleased, isDraft, isScheduled, startDate }) => {
  let status = isReleased && "Released";
  status = isDraft ? "Draft" : status;
  status = isScheduled ? "Scheduled" : status;
  let statusString = status === "Released" ? `Released Date: ${startDate}` : status;

  return status ? (
    <Pill
      big
      primaryOutline={status === "Scheduled"}
      defaultOutline={status === "Draft"}
      defaultSuccessOutline={status === "Released"}
    >
      {statusString}
    </Pill>
  ) : (
    ""
  );
};

export default AssignmentStatusPill;
