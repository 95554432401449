/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import FormAssessmentTemplate from "components/form/FormAssessmentTemplate";
import { SectionWizard, SectionTitle, SectionContent } from "styles/components/Section";

const ProgramAssessmentFromTemplate = ({ changeStep, programType }) => {
  return (
    <SectionWizard>
      <SectionTitle>Create Assessment</SectionTitle>

      <SectionContent>
        <FormAssessmentTemplate programType={programType} />
      </SectionContent>
    </SectionWizard>
  );
};

export default ProgramAssessmentFromTemplate;
