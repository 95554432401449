export const modalTypes = {
  vehicle: "VEHICLE",
  createVehicle: "CREATE_VEHICLE",
  editVehicle: "EDIT_VEHICLE",
  deleteVehicle: "DELETE_VEHICLE",
  createGroup: "CREATE_GROUP",
  editGroup: "EDIT_GROUP",
  renameGroup: "RENAME_GROUP",
  duplicateGroup: "DUPLICATE_GROUP",
  deleteGroup: "DELETE_GROUP",
  createUser: "CREATE_USER",
  editUser: "EDIT_USER",
  createAccount: "CREATE_ACCOUNT",
  editAccount: "EDIT_ACCOUNT",
  deactivateAccount: "DEACTIVATE_ACCOUNT",
  deactivateUser: "DEACTIVATE_USER",
  userLimit: "USER_LIMIT",
  userCreationActiveLimit: "USER_CREATION_ACTIVE_LIMIT",
};

export const coohorts = {
  removeuser:
    "Student is no longer a member of any Cohorts. Would you like to deactivate this User?",
};
