import React, { Component } from "react";
import classNames from "classnames";

import { FullscreenModal, FullscreenModalClose } from "styles/components/FullscreenModal";

export default class FullscreenModalItem extends Component {
  state = {
    active: false,
  };

  componentDidMount() {
    this.setState({
      active: this.props.active,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      this.setState({
        active: this.props.active,
      });
    }
  }

  /**
   * Handle close modal click
   */
  handleClose() {
    this.setState({
      active: false,
    });

    this.props.onClose();
  }

  /**
   * Render component
   */
  render() {
    return (
      <FullscreenModal className={classNames({ "is-active": this.state.active })}>
        <FullscreenModalClose onClick={() => this.handleClose()} />
        {this.props.children}
      </FullscreenModal>
    );
  }
}
