/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

/**
 * The internal dependencies.
 */

import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import ColorPickerBlock from "components/courses/ColorPickerBlock";

import Form, {
  FormHead,
  FormLogo,
  FormBody,
  FormTitle,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
  FormRow,
} from "styles/components/Form";

import { createUnit, updateUnit } from "store/state/courses/actions";

import { validateField, validateDateField, setValidityAndMessage } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { FORM_CREATE_COURSE_UNIT_MESSAGES } from "constants/messages";
import { OPERATIONS } from "constants/common";
/**
 * Class for form create cohort.
 *
 * @class      FormCreateCourse (name)
 */
class FormCreateCourseUnit extends Component {
  state = {
    id: this.props.unit?.id || "",
    //courseId: this.props.activeCourse.id,
    sectionId: this.props.activeCourse.id,
    name: "",
    startDate: "",
    endDate: "",
    description: "",
    formErrors: {
      name: {
        valid: true,
        message: "",
      },
      startDate: {
        valid: true,
        message: "",
      },
      endDate: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };

  rules = {
    name: {
      requiredMessage: FORM_CREATE_COURSE_UNIT_MESSAGES.COURSE_UNIT_NAME_REQUIRED,
      maxMessage: FORM_CREATE_COURSE_UNIT_MESSAGES.COURSE_UNIT_NAME_MAX,
    },
    startDate: {
      requiredMessage: FORM_CREATE_COURSE_UNIT_MESSAGES.START_DATE_REQUIRED,
      typeDateMessage: FORM_CREATE_COURSE_UNIT_MESSAGES.START_DATE_INVALID,
      valueGreaterThan: "endDate",
      fromToInvalidMessage: FORM_CREATE_COURSE_UNIT_MESSAGES.START_DATE_GREATER_THAN_END_DATE,
    },
    endDate: {
      requiredMessage: FORM_CREATE_COURSE_UNIT_MESSAGES.END_DATE_REQUIRED,
      typeDateMessage: FORM_CREATE_COURSE_UNIT_MESSAGES.END_DATE_INVALID,
    },
    description: {},
  };

  handleInputChange = (key) => {
    const handleChange = this.handleChange(key);
    return (e) => {
      const element = e.nativeEvent.target ? e.nativeEvent.target : e.target;
      handleChange(element.value);
    };
  };

  handleChange = (key) => (value) => {
    this.setState({
      [key]: value,
    });
  };

  isEdit() {
    return this.props.modal.data && this.props.modal.data.editItem;
  }

  /**
   * Handles Form Submit
   *
   */
  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.validateAllFields();

    if (this.state.formErrors.formValid) {
      try {
        var res = null;
        const { createUnit, updateUnit, activeCourse, closeModal } = this.props;
        const { formErrors, ...formData } = this.state;
        const success = this.isEdit()
          ? await updateUnit({
              ...formData,
              courseId: activeCourse.id,
            })
          : await createUnit({
              unit: {
                name: formData.name,
                description: formData.description || "Test Description",
                sectionId: formData.sectionId,
                start_time: moment(formData.startDate)
                  .startOf("day")
                  .utc()
                  .format(),
                end_time: moment(formData.endDate)
                  .endOf("day")
                  .utc()
                  .format(),
              },
              section: activeCourse,
            });
        if (success) {
          closeModal();
        }
      } catch (e) {}
    }
  };

  /**
   *
   * Handles Validation
   */
  validateAllFields = () => {
    this.validateTextField(document.getElementById("name"), true);
    this.validateDates();
  };

  validateDates() {
    setValidityAndMessage("dates", this.state.formErrors, true, "");

    this.validateFormDateField("startDate", this.state.startDate);
    this.validateFormDateField("endDate", this.state.endDate);

    if (this.state.formErrors.formValid) {
      this.setState({
        formValid: this.state.formErrors.formValid,
      });
    }
  }

  getMomentObjectFromDateString = (value) => {
    if (typeof value === "string") {
      const dateTimeParts = value.split(" at ");
      const dateTime = dateTimeParts.join(" ");
      value = moment(dateTime);
    }
    return value;
  };

  validateFormDateField = (name, value) => {
    const valueData =
      !value || (typeof value === "string" && (value.trim() === "" || value.trim() === "None"))
        ? undefined
        : this.getMomentObjectFromDateString(value);

    this.setState({
      formErrors: validateDateField(this.state.formErrors, this.rules, {
        name,
        value: valueData,
        required: true,
        compareWith: name == "startDate" ? this.state.endDate : this.state.startDate,
      }),
    });
  };

  validateTextField = (element, trimValues) => {
    this.setState({
      formErrors: validateField(this.state.formErrors, this.rules, element, "", trimValues),
    });
  };

  componentDidMount() {
    if (this.isEdit()) {
      this.setState({
        ...this.props.modal.data.editItem,
      });
    }
  }

  render() {
    const { name, formErrors, startDate, endDate, description, courseApiErrors } = this.state;

    let operation = this.isEdit() ? OPERATIONS.UPDATE : OPERATIONS.CREATE;

    return (
      <Form hasLargeFields fulloNmobile tabletSize1 onSubmit={this.handleFormSubmit}>
        <FormHead bottomOffset="10">
          <FormLogo>
            {this.isEdit() ? (
              <IconSvgComponent iconPath="svg/ico-unit-edit.svg" />
            ) : (
              <IconSvgComponent iconPath="svg/ico-unit-add.svg" />
            )}
          </FormLogo>

          <FormTitle>{this.isEdit() ? "Edit Unit" : "Create Unit"}</FormTitle>
          <FieldErrors
            formErrors={formErrors}
            apiErrors={this.props.courseApiErrors[operation]?.message}
          ></FieldErrors>
        </FormHead>

        <FormBody size1 bottomOffset="25">
          <FormRowGrid justifyCenter>
            <FormCol>
              <FormControls>
                <Field
                  id="name"
                  name="name"
                  onChange={this.handleInputChange("name")}
                  value={name}
                  placeholder="Unit Name"
                  required
                  max="255"
                />
              </FormControls>
            </FormCol>
          </FormRowGrid>
          <FormRowGrid>
            <FormCol half>
              <FormControls>
                <Field
                  icon="ico-calendar"
                  id="startDate"
                  isDateTimePicker
                  onChange={this.handleChange("startDate")}
                  value={startDate ? moment(startDate) : ""}
                  placeholder="Start Date"
                  showTimeSelect={false}
                  required
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  icon="ico-calendar"
                  id="endDate"
                  isDateTimePicker
                  onChange={this.handleChange("endDate")}
                  value={endDate ? moment(endDate) : ""}
                  placeholder="End Date"
                  showTimeSelect={false}
                  required
                />
              </FormControls>
            </FormCol>
          </FormRowGrid>
          {/* <FormRow textarea>
            <FormControls>
              <Field
                isTextarea
                id="description"
                onChange={this.handleInputChange("description")}
                value={description ? description : ""}
                placeholder="Description"
              />
            </FormControls>
          </FormRow>
          */}
        </FormBody>

        <FormActions>
          <ButtonLink type="button" transparent onClick={() => this.props.closeModal()}>
            <span>Cancel</span>
          </ButtonLink>

          {this.isEdit() ? (
            <ButtonLink type="submit" violet hasIcon>
              <span>Update Unit</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          ) : (
            <ButtonLink type="submit" violet hasIcon>
              <span>Create Unit</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          )}
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    apiCallInProgress: state.administration.apiCallInProgress,
    courseApiErrors: state.storedCourses.apiErrors.units,
    accountId: state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId,
    activeCourseId: state.storedCourses.activeCourseId,
    activeCourse: Object.values(state.storedCourses.sections).find(
      (section) => section.id === state.storedCourses.activeCourseId
    ),
  }),
  (dispatch) => ({
    createUnit: async ({ unit, section }) => await dispatch(createUnit({ unit, section })),
    updateUnit: (unit) => dispatch(updateUnit(unit)),
    closeModal: () => dispatch(closeModal()),
  })
)(FormCreateCourseUnit);
