/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import theme from "styles/theme";
import { Link } from "react-router-dom";

export const StudentAttemptDetail = styled.div`
  text-align: center;
  width: 100%;
`;

export const StudentAttemptDetailText = styled.p`
  font-size: 18px;
  font-weight: 700;
  padding: 7px 0 0;
`;

export const StudentAttemptDetailTitle = styled.h6`
  text-align: center;
  font-size: 12px;
  margin-bottom: 0;
  text-transform: uppercase;
`;

export const StudentAttemptScore = styled.div`
  font-size: 66px;
  font-weight: 700;
  line-height: 1;
  color: ${theme.color.blue};
`;

export const StudentAttemptScoreDetail = styled.div`
  font-size: 28px;
  line-height: 1.1;
  font-weight: 700;
`;

export const StudentAttemptHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

export const StudentAttemptTitle = styled.h4`
  font-size: 18px;
  margin: 0;
`;

export const StudentAttemptBody = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 23px;
`;

export const StudentAttemptFoot = styled.div`
  text-align: center;
  width: 100%;
  display: flex;

  ${StudentAttemptDetail} {
    border-right: 2px solid rgba(32, 73, 230, 0.06);
    padding: 8px 0 13px;

    &:last-child {
      border-right: none;
    }
  }
`;

const StudentAttempt = styled.div`
  font-size: 18px;
  color: #484848;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: ${theme.color.white};
  border-radius: ${theme.border_radius};
  padding: 17px 20px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
`;

export const Button = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 15px;
  background: ${theme.color.red};
  border: 0 none;
  border-radius: 21px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  transition: background 0.2s;
  text-decoration: inherit;

  &:hover {
    background: rgba(255, 62, 29, 0.8);
  }

  span {
    margin-right: 13px;
  }
`;

export default StudentAttempt;
