/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */
import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Form, { FormHead, FormLogo, FormTitle, FormActions } from "styles/components/Form";

/**
 * Class for form archive.
 *
 * @class      FormArchive (name)
 */
class FormRemoveSelected extends Component {
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.props.modal.data.action();
    this.props.closeModal();
  };

  render() {
    const { closeModal, modal } = this.props;

    return (
      <Form onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            {modal.icon ? (
              <IconSvgComponent iconPath={`svg/${modal.icon}`} />
            ) : (
              <IconSvgComponent iconPath="svg/ico-remove.svg" />
            )}
          </FormLogo>

          <FormTitle>Are you sure you want to remove {modal.data.title}?</FormTitle>
        </FormHead>

        <FormActions>
          <ButtonLink transparent onClick={() => closeModal()} type="button">
            Cancel
          </ButtonLink>

          <ButtonLink type="submit" violet>
            <span>Remove</span>
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
  }),
  {
    closeModal,
  }
)(FormRemoveSelected);
