import { handleActions } from "redux-actions";

import { requestLogin, successLogout } from "../user/actions";
import { errorData } from "constants/api";
import {
  request,
  reject,
  listDeploymentDashboardData,
  facultyDeploymentAssessmentStudentList,
  assessmentStats,
  requestDashboard,
  listAssessmentStudentDashboardData,
  clearAssessmentStudentDashboardData,
  getFacultyDashboardSuccess,
  listStudentAttemptDashboardData,
  clearStudentAttemptDashboardData,
  listTopicPerformanceData,
} from "./actions";
import assessment from "../assessment/reducer";

/**
 * @ Reducer
 */

const operations = {
  get: errorData,
};

const defaultTable = {
  pageSize: 10,
  page: 0,
  pages: null,
  count: 0,
};

const defaultState = {
  dashboardLoading: true,
  placements: [],
  deployment: {
    assessmentName: "",
    deploymentName: "",
    status: "",
    program: "",
    ownerName: "",
    ownerEmail: "",
    cohort: "",
    startDate: "",
    endDate: "",
    placementSummary: [],
  },
  deploymentAssessmentUserList: {
    users: [],
    total_count: 0,
  },
  deploymentAssessmentStats: { deploymentDetails: { assessmentName: "" }, stats: null },
  facultyStudentTable: defaultTable,
  facultyTopicTable: defaultTable,
  student: { attempts: [] },
  apiCallInProgress: true,
  studentAttempt: {},
  topicBreakdown: [],
  attemptPerformance: {
    isLoading: true,
    error: "",
    topicPerformance: [],
  },
  apiErrors: {
    placements: { ...operations },
    deployment: { ...operations },
    deploymentAssessmentDetails: {
      ...operations,
    },
    student: { ...operations },
    studentAttempt: { ...operations },
  },
};

const facultyDashboard = handleActions(
  {
    [request]: (state, { payload: { selector, operation, resetData } }) => ({
      ...state,
      apiCallInProgress: true,
      [selector]: resetData && resetData === true ? defaultState[selector] : state[selector],
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: errorData,
        },
      },
    }),
    [requestDashboard]: (state) => ({
      ...state,
      dashboardLoading: true,
    }),
    [listDeploymentDashboardData]: (state, { payload: { selector, data } }) => ({
      ...state,
      apiCallInProgress: false,
      [selector]: data,
    }),
    [assessmentStats]: (state, { payload }) => ({
      ...state,
      deploymentAssessmentStats: payload,
      apiCallInProgress: false,
      dashboardLoading: false,
    }),
    [listAssessmentStudentDashboardData]: (state, { payload: { selector, data } }) => ({
      ...state,
      apiCallInProgress: false,
      [selector]: data,
      deployment: {
        deploymentName: data.deploymentName,
        assessmentName: data.assessmentName,
      },
    }),
    [listTopicPerformanceData]: (state, { payload: { selector, data } }) => ({
      ...state,
      [selector]: data,
    }),
    [getFacultyDashboardSuccess]: (state, { payload: { placements } }) => ({
      ...state,
      apiCallInProgress: false,
      placements,
    }),
    [listDeploymentDashboardData]: (state, { payload: { selector, data } }) => ({
      ...state,
      apiCallInProgress: false,
      [selector]: data,
    }),
    [facultyDeploymentAssessmentStudentList]: (
      state,
      { payload: { selector, data, page, count, appendRecords } }
    ) => {
      const configuredTableProps = (() => {
        const tableName = `facultyStudentTable`;
        return {
          [tableName]: {
            ...state[tableName],
            count,
            page: page - 1,
            pages: Math.ceil(count / state[tableName].pageSize),
          },
        };
      })();

      return {
        ...state,
        [selector]: appendRecords ? { users: [...state[selector].users, ...data.users] } : data,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
          },
        },
        ...configuredTableProps,
      };
    },
    [reject]: (state, { payload: { selector, operation, errorItem } }) => ({
      ...state,
      [selector]: defaultState[selector],
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: {
            code: 1,
            message: errorItem && errorItem.message,
          },
        },
      },
    }),
    [listStudentAttemptDashboardData]: (state, { payload: { selector, data } }) => ({
      ...state,
      apiCallInProgress: false,
      [selector]: data,
      deployment: {
        deploymentName: data.deploymentName,
        assessmentName: data.assessmentName,
      },
      student: {
        ...state.student,
        name: data.studentName,
      },
    }),
    [clearAssessmentStudentDashboardData]: (state) => ({
      ...state,
      student: defaultState.student,
      deployment: {
        deploymentName: "",
        assessmentName: "",
      },
    }),
    [clearStudentAttemptDashboardData]: (state) => ({
      ...state,
      studentAttempt: {},
      deployment: {
        deploymentName: "",
        assessmentName: "",
      },
      attemptPerformance: {
        isLoading: true,
        error: "",
        topicPerformance: [],
      },
    }),
    [requestLogin]: () => ({
      ...defaultState,
    }),
    [successLogout]: () => ({
      ...defaultState,
    }),
  },
  defaultState
);

export default facultyDashboard;
