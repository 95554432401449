import {
  fetchAPICall,
  apiUrl,
  convertKeysToUnderscore,
} from "../utilities/API";
import { getAccessToken } from "utilities/localStore";
import {
  getBannerPath,
  editBannerPath,
  contentTypeJSON,
} from "../constants/api";

export const getBannerAPI = async (accountId) => {
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: getBannerPath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

export const editBannerAPI = async ({ accountId, bannerData }) => {
  const { id, ...bannerRequestBody } = bannerData;
  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: editBannerPath(id),
    method: "put",
    body: JSON.stringify({
      ...convertKeysToUnderscore(bannerRequestBody)
    }),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};
