import { createAction } from "redux-actions";

import {
  login,
  logout,
  changePassword,
  resetPassword,
  firstSigninResetPassword as firstSigninResetPasswordAPI,
  appEnv,
} from "utilities/API";
import { getDebounced } from "utilities/commonFunctions";
import store from "store/store";
import {
  decorateUserObject,
  getUserProgram,
  callSSOLoginApi,
  callAccountInformationApi,
} from "services/users";
import { saveUserActivity } from "services/activity";
import {
  updateAuthStore,
  loginIsFresh,
  setUserCurrentAccount,
  setUserDefaultAccount,
  getUserInfo,
} from "utilities/localStore";
import { COMMON_MESSAGES } from "constants/messages";

import { list, getBanner } from "store/state/administration/actions";
import { getOpenAttempts } from "store/state/assessment/actions";
import { addNotification, clearNotifications } from "store/state/ui/actions";
import { createNotification, LEVELS } from "utilities/notification";

export const requestLogin = createAction("REQUEST_LOGIN");
export const receiveFirstLogin = createAction("RECEIVE_FIRST_LOGIN");
export const completeLogin = createAction("COMPLETE_LOGIN");
export const rejectLogin = createAction("REJECT_LOGIN");
export const requestLoggedInStatus = createAction("REQUEST_LOGGED_IN_STATUS");
export const receiveLoggedInStatus = createAction("RECEIVE_LOGGED_IN_STATUS");
export const rejectLoggedInStatus = createAction("REJECT_LOGGED_IN_STATUS");
export const requestFirstSigninResetPassword = createAction("REQUEST_FIRST_SIGNIN_RESET_PASSWORD");
export const successFirstSigninResetPassword = createAction("SUCCESS_FIRST_SIGNIN_RESET_PASSWORD");
export const rejectFirstSigninResetPassword = createAction("REJECT_FIRST_SIGNIN_RESET_PASSWORD");
export const requestResetForgottenPassword = createAction("REQUEST_FORGOT_RESET_PASSWORD");
export const successResetForgottenPassword = createAction("SUCCESS_FORGOT_RESET_PASSWORD");
export const rejectResetForgottenPassword = createAction("REJECT_FORGOT_RESET_PASSWORD");
export const requestResetPassword = createAction("REQUEST_RESET_PASSWORD");
export const successResetPassword = createAction("SUCCESS_RESET_PASSWORD");
export const rejectResetPassword = createAction("REJECT_RESET_PASSWORD");
export const requestLogout = createAction("REQUEST_LOGOUT");
export const successLogout = createAction("SUCCESS_LOGOUT");
export const rejectLogout = createAction("REJECT_LOGOUT");
export const setAccountId = createAction("SET_ACCOUNT");

export const requestAccountFaqs = createAction("REQUEST_ACCOUNT_FAQ");
export const setAccountFaqs = createAction("SET_ACCOUNT_FAQ");
export const rejectAccountFaqs = createAction("REJECT_ACCOUNT_FAQ");

export const noNetworkFound = createAction("NETWORK_CONNECTIVITY_LOST");
export const networkRestored = createAction("NETWORK_CONNECTIVITY_RESTORED");

export const handleAccountSwitch = ({ accountId, accountName }) => async (dispatch) => {
  await setUserCurrentAccount({ accountId, accountName });
  dispatch(
    setAccountId({
      currentAccountId: accountId,
      accountSwitched: true,
      currentAccountName: accountName,
    })
  );
  await getUserProgram({ accountId }).then((res) => {
    if (res.status == "success") {
      dispatch(list({ selector: "program", items: res.data[0].id }));
      dispatch(list({ selector: "account", items: accountId }));
    }
    const successAccountSwitchNotification = addNotification({
      notification: createNotification(
        LEVELS.SUCCESS,
        "Account Switch Success",
        "You have successfully switched accounts."
      ),
    });
    dispatch(successAccountSwitchNotification);
  });
};

export const clearUserAccount = () => async (dispatch) => {
  setUserCurrentAccount({ accountId: null, accountName: null });
  const accountId = getUserInfo()["custom:account_id"];
  await getUserProgram({ accountId }).then((res) => {
    if (res.status == "success") {
      dispatch(list({ selector: "program", items: res.data[0].id }));
      dispatch(list({ selector: "account", items: accountId }));
    }
    dispatch(
      setAccountId({
        accountId,
        accountSwitched: false,
        accountName: "Default",
      })
    );
    dispatch(list({ selector: "accounts", items: [] }));
    const successAccountSwitchNotification = addNotification({
      notification: createNotification(
        LEVELS.SUCCESS,
        "Account Switch Success",
        "You have successfully switched to your default account."
      ),
    });
    dispatch(successAccountSwitchNotification);
  });
};

export const submitLogin = ({ username, password }) => async (dispatch) => {
  dispatch(requestLogin());
  try {
    const response = await login({ username, password });
    if (response.status === "success" && response.data) {
      if (response.data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        const userAttributes = JSON.parse(response.data.ChallengeParameters.userAttributes);
        dispatch(
          receiveFirstLogin({
            session: response.data.Session,
            username: response.data.ChallengeParameters.USER_ID_FOR_SRP,
            // username: userAttributes.email,
          })
        );
      }
      if (response.data.AuthenticationResult) {
        const { AuthenticationResult, account_name } = response.data;
        // update local storage
        updateAuthStore(AuthenticationResult);
        if (account_name) {
          setUserDefaultAccount(account_name);
        }
        // get user from local storage and decorate
        const user = decorateUserObject({ user: getUserInfo() });
        user.accountName = account_name;

        // complete the login w/ formatted user object
        dispatch(postLoginFetchcData()).then(() => {
          dispatch(clearNotifications());
          dispatch(completeLogin({ user }));
        });
      }
    } else if (response.status === "error") {
      dispatch(rejectLogin(response.data.message));
      console.log("error submitting login:", response.data.message);
    }
  } catch (e) {
    console.log(e.message);
    dispatch(rejectLogin(COMMON_MESSAGES.ERROR_OCCURRED));
    console.log("error submitting login:", e);
  }
};

const debouncedGetBanner = getDebounced(() => {
  store.dispatch(getBanner());
}, 5000);

export const postLoginFetchcData = () => (dispatch) => {
  const userInfo = getUserInfo();
  const accountData = userInfo.currentAccountId
    ? { accountId: userInfo.currentAccountId, accountSwitched: true }
    : {
        accountId: userInfo["custom:account_id"] ? userInfo["custom:account_id"] : 1,
        accountSwitched: false,
      };
  dispatch(setAccountId(accountData));
  debouncedGetBanner();
  return getUserProgram({ accountId: accountData.accountId }).then((res) => {
    if (res.status == "success") {
      dispatch(list({ selector: "program", items: res.data[0].id }));
      dispatch(setAccountId(accountData));
    }
  });
};

export const firstSigninResetPassword = ({ session, username, password }) => async (dispatch) => {
  try {
    dispatch(requestFirstSigninResetPassword());
    const response = await firstSigninResetPasswordAPI({
      session,
      username,
      password,
    });
    if (response.status === "success" && response.data) {
      dispatch(successFirstSigninResetPassword());
    }
    if (response.status === "error") {
      let sessionExpired = false;
      if (response.data.errorCode == 155 || response.data.errorCode == 154) {
        sessionExpired = true;
      }
      dispatch(
        rejectFirstSigninResetPassword({
          message: response.data.message,
          sessionExpired,
        })
      );
    }
    return response;
  } catch (e) {
    console.log(e.message);
    dispatch(
      rejectFirstSigninResetPassword({
        message: COMMON_MESSAGES.ERROR_OCCURRED,
      })
    );
  }
};

export const resetPasswordRequest = ({ email }) => {
  return async (dispatch) => {
    dispatch(requestResetPassword());
    try {
      const response = await resetPassword({ email });
      if (response && response.status === "success") {
        dispatch(successResetPassword());
      }
      if (response && response.status === "error") {
        dispatch(rejectResetPassword(response.data.message));
      }
      return { response };
    } catch (e) {
      dispatch(rejectResetPassword(COMMON_MESSAGES.ERROR_OCCURRED));
      console.log("error resetting password:", e);
    }
  };
};

export const resetForgottenPassword = ({ confirmationCode, username, newPassword }) => {
  return async (dispatch) => {
    dispatch(requestResetForgottenPassword());
    try {
      const response = await changePassword({
        confirmationCode,
        username,
        newPassword,
      });
      if (response && response.status == "success") {
        dispatch(successResetForgottenPassword());
      }
      if (response && response.status == "error") {
        dispatch(rejectResetForgottenPassword(response.data.message));
      }
      return response;
    } catch (e) {
      dispatch(rejectResetForgottenPassword(COMMON_MESSAGES.ERROR_OCCURED));
    }
  };
};

export const submitLogout = () => async (dispatch) => {
  dispatch(requestLogout());
  const successLogoutNotification = addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      "Logout Success",
      "You have successfully logged out."
    ),
  });

  try {
    const response = await logout();
    if (response.status === "success") {
      updateAuthStore({
        AccessToken: null,
        IdToken: null,
        ExpiresIn: null,
        ExpiresAt: null,
      });
      setUserCurrentAccount({ accountId: null, accountName: null });
      dispatch(successLogout());
      dispatch(successLogoutNotification);
    } else {
      console.log(response.error);
      updateAuthStore({
        AccessToken: null,
        IdToken: null,
        ExpiresIn: null,
        ExpiresAt: null,
      });
      setUserCurrentAccount({ accountId: null, accountName: null });
      dispatch(successLogout());
      dispatch(successLogoutNotification);
    }
  } catch (e) {
    console.log("error logging out:", e);
    updateAuthStore({
      AccessToken: null,
      IdToken: null,
      ExpiresIn: null,
      ExpiresAt: null,
    });
    setUserCurrentAccount({ accountId: null, accountName: null });
    dispatch(successLogout());
  }
};

export const getLoggedInStatus = ({ routeParams } = {}) => {
  return async (dispatch) => {
    dispatch(requestLoggedInStatus());

    try {
      const loggedInStatus = loginIsFresh();
      const {
        deploymentId: deploymentConfigId,
        assignmentId,
        attemptId,
        assessmentSeriesId,
      } = routeParams;

      if (loggedInStatus) {
        const user = decorateUserObject({ user: getUserInfo() });

        dispatch(completeLogin({ user }));
        debouncedGetBanner();
        if (
          !(deploymentConfigId && attemptId) ||
          !(assignmentId && attemptId) ||
          !assessmentSeriesId
        ) {
          dispatch(getOpenAttempts());
        }
      }
      dispatch(receiveLoggedInStatus(loggedInStatus));
    } catch (e) {
      dispatch(rejectLoggedInStatus(e));
    }
  };
};

export const saveUserActivityAction = ({
  type,
  id,
  seekDuration,
  quizId,
  cuepoint,
  action,
  isComplete,
}) => async (dispatch) => {
  const resp = saveUserActivity({
    type,
    id,
    seekDuration,
    quizId,
    cuepoint,
    action,
    isComplete,
  });
};

export const completeSSOLogin = (objData) => async (dispatch) => {
  try {
    dispatch(requestLogin());
    const resp = await callSSOLoginApi(objData);
    if (!resp) {
      dispatch(rejectLogin("something went wrong! Please try again"));
      return false;
    }
    if (resp.status == "success") {
      const { account_name } = resp.data;
      if (account_name) {
        setUserDefaultAccount(account_name);
      }
      await updateAuthStore(resp.data);
      // get user from local storage and decorate
      const user = decorateUserObject({ user: getUserInfo() });
      user.accountName = account_name;

      // complete the login w/ formatted user object
      dispatch(postLoginFetchcData()).then(() => {
        dispatch(clearNotifications());
        dispatch(completeLogin({ user, isSSOLogin: true }));
      });
      return true;
    } else {
      dispatch(rejectLogin(resp.data.message));
      return false;
    }
  } catch (e) {
    console.log(e);
    dispatch(rejectLogin(e.message));
    return false;
  }
};

export const getAccountSupportContent = () => async (dispatch, getState) => {
  dispatch(requestAccountFaqs());
  const state = getState();
  const {
    user: { currentAccountId, accountId },
  } = state;
  let account_id = currentAccountId ? currentAccountId : accountId;
  const resp = await callAccountInformationApi({ accountId: account_id });
  if (resp.status == "success") {
    dispatch(setAccountFaqs(resp.data));
  } else {
    dispatch(rejectAccountFaqs());
  }
};
