/**
 * @ External Dependencies
 */
import { handleActions } from "redux-actions";
import { errorData } from "constants/api";

/**
 * @ Actions
 */
import {
  setActiveCourse,
  deleteActionCourse,
  setAssignmentAssets,
  resetAsset,
  requestActionCourse,
  rejectActionCourse,
  listActionCourse,
  updateCourseItem,
  insertCourseUnits,
  insertCourseUnitAssignments,
  removeActionCourse,
  setAPICallInProgress,
  resetPrevAssignmentCreationError,
  listGradesByAssignment,
  requestActionAssignmentDashboard,
  rejectActionAssignmentDashboard,
  listActionAssignmentDashboard,
  listCourseAssessmentCompletionData,
  listCourseAssessmentData,
  courseAssessmentDashboardStudentNTopicBreakDown,
  noop,
} from "./actions";

import { receiveCourseAssessmentExam } from "../courseAssessment/actions";

/**
 * @ Reducer
 */
const defaultTable = {
  pageSize: 10,
  page: 0,
  pages: null,
  count: 0,
};
const operations = {
  create: errorData,
  copy: errorData,
  list: errorData,
  get: errorData,
};

const defaultState = {
  activeCourseId: null,
  activeCourse: {},
  units: {},
  schedules: {},
  sections: {},
  assignments: {},
  attempts: {},
  grades: {},
  assignmentAssets: {},
  disciplines: [],
  videos: {},
  concepts: {},
  apiCallInProgress: false,
  isLoading: {
    sections: false,
  },
  apiErrors: {
    course: {
      create: errorData,
      update: errorData,
    },
    disciplines: {
      ...operations,
    },
    units: {
      create: errorData,
      update: errorData,
      delete: errorData,
    },
    assignments: {
      create: errorData,
      get: errorData,
    },
    grades: {
      get: errorData,
    },
  },
  assignedInstructors: {},
  assignedStudents: {},
  assessments: {},
  gradesByAssignment: {},
  gradesByStudent: {},
  studentTotalCount: {
    value: 0,
    apiCallInProgress: false,
  },
  avgScore: {
    value: 0,
    apiCallInProgress: false,
  },
  avgTime: {
    value: 0,
    apiCallInProgress: false,
  },
  stdDev: {
    value: 0,
    apiCallInProgress: false,
  },
  scoreDistribution: {},
  assessmentDashboardCompletionDonutChart: {},
  topicBreakDownTable: defaultTable,
  assessmentDashboardTopicBreakDown: [],
  studentBreakDownTable: defaultTable,
  assessmentDashboardStudentBreakDown: [],
  videoDashboardConceptsBreakdown: {},
  attemptsAnalytics: {
    value: {},
    apiCallInProgress: false,
  },
};

const getIdKeyedMapFromArray = (items) =>
  items.reduce(
    (memo, item) => ({
      ...memo,
      [item.id]: item,
    }),
    {}
  );

const storedCourses = handleActions(
  {
    //common request action for course module
    [requestActionCourse]: (state, { payload: { selector, operation, resetData } }) => ({
      ...state,
      apiCallInProgress: true,
      [selector]: resetData && resetData === true ? [] : state[selector],
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: errorData,
        },
      },
    }),
    // common List action for course module
    [listActionCourse]: (state, { payload: { selector, items } }) => {
      // Is items an Array or a single Object
      // If Array reduce into Object with id as keys
      const newSelectorItems = getIdKeyedMapFromArray([].concat(items));

      return {
        ...state,
        [selector]: {
          ...state[selector],
          ...newSelectorItems,
        },
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            list: errorData,
          },
        },
      };
    },
    // common update action for course module
    [updateCourseItem]: (state, { payload: { selector, operation, id, update } }) => {
      return {
        ...state,
        [selector]: {
          ...state[selector],
          [id]: {
            ...state[selector][id],
            ...update,
          },
        },
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            [operation]: errorData,
          },
        },
      };
    },
    // common delete action for course module
    [deleteActionCourse]: (state, { payload: { selector, itemId } }) => {
      const { [itemId]: deletedUnit, ...restOfSelectorState } = state[selector];

      return {
        ...state,
        [selector]: restOfSelectorState,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            delete: errorData,
          },
        },
      };
    },
    //common Reject action for course module
    [rejectActionCourse]: (state, { payload: { selector, operation, errorItem } }) => ({
      ...state,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: {
            code: errorItem.errorCode ? errorItem.errorCode : 1,
            message: errorItem && errorItem.message,
          },
        },
      },
    }),
    // Activate Course
    [setActiveCourse]: (state, { payload }) => {
      return {
        ...state,
        activeCourseId: payload.id,
        activeCourse: payload,
      };
    },
    //Insert units to respective sections
    [insertCourseUnits]: (state, { payload }) => {
      const { assignedUnits } = payload;

      return {
        ...state,
        units: assignedUnits.sort(
          (a, b) =>
            a.endDate.localeCompare(b.endDate) ||
            a.startDate.localeCompare(b.startDate) ||
            a.createdOn.localeCompare(b.createdOn) ||
            a.name.localeCompare(b.name)
        ),
      };
    },
    // Insert Assignments to respective Units
    [insertCourseUnitAssignments]: (state, { payload }) => {
      const { units, activeCourseId, schedules } = state;
      const { assignments } = payload;

      // Update course if active

      const updatedUnits = [...units].map((unit) => {
        let updatedUnit = unit;
        if (unit.sectionId == activeCourseId) {
          assignments.map((assignment) => {
            if (unit.id == assignment.unitId) {
              const newAssignmentSchedule =
                schedules.filter((i) => i.assignment_id == assignment.id)[0] || null;
              // Add new assignments to existing array
              const newAssignment = newAssignmentSchedule
                ? {
                    ...assignment,
                    startDate: newAssignmentSchedule.start_date,
                    dueDate: newAssignmentSchedule.end_date,
                  }
                : { ...assignment };
              updatedUnit = {
                ...updatedUnit,
                assignments: [...updatedUnit.assignments, newAssignment],
              };
            }
            return assignment;
          });

          updatedUnit = {
            ...updatedUnit,
            assignments: updatedUnit.assignments.sort(
              (a, b) =>
                a.sequence.toString().localeCompare(b.sequence.toString()) ||
                a.dueDate.localeCompare(b.dueDate) ||
                a.startDate.localeCompare(b.startDate) ||
                a.createdOn.localeCompare(b.createdOn) ||
                a.name.localeCompare(b.name)
            ),
          };
        }
        return updatedUnit;
      });

      return {
        ...state,
        units: updatedUnits,
        apiCallInProgress: false,
        assignmentAssets: [],
      };
    },
    // Set Temporally chosen assets
    [setAssignmentAssets]: (state, { payload }) => {
      return {
        ...state,
        assignmentAssets: payload || {},
      };
    },
    [resetAsset]: (state, { payload }) => {
      return {
        ...state,
        assignmentAssets: defaultState.assignmentAssets,
      };
    },
    [removeActionCourse]: (state, { payload: { id, selector } }) => {
      const sectionObj = { ...state[selector] };
      delete sectionObj[id];
      return {
        ...state,
        [selector]: sectionObj,
        apiCallInProgress: false,
      };
    },
    [setAPICallInProgress]: (state, { payload }) => {
      return {
        ...state,
        apiCallInProgress: payload,
      };
    },
    [resetPrevAssignmentCreationError]: (state, { payload: { selector, operation } }) => ({
      ...state,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: errorData,
        },
      },
    }),
    [listGradesByAssignment]: (state, { payload: { selector, items } }) => {
      return {
        ...state,
        [selector]: {
          ...state[selector],
          ...items,
        },
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            list: errorData,
          },
        },
      };
    },
    // common Request action for course assignment Dashboard module
    [requestActionAssignmentDashboard]: (state, { payload: { selector, operation } }) => ({
      ...state,
      [selector]: { ...state[selector], apiCallInProgress: true },
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: errorData,
        },
      },
    }),
    // common List action for course assignment Dashboard module
    [listActionAssignmentDashboard]: (state, { payload: { selector, items } }) => {
      return {
        ...state,
        [selector]: {
          ...state[selector],
          ...items,
        },
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            list: errorData,
          },
        },
      };
    },
    [receiveCourseAssessmentExam]: (state, { payload: { data, assessmentId, courseId } }) => {
      const selector = "assignments";
      const assignment = data?.assignment;

      if (assignment) {
        return {
          ...state,
          [selector]: {
            ...state[selector],
            [assignment.id]: assignment,
          },
        };
      }
      return state;
    },
    //common Reject action for course assignment Dashboard module
    [rejectActionAssignmentDashboard]: (
      state,
      { payload: { selector, operation, errorItem } }
    ) => ({
      ...state,
      [selector]: { ...defaultState[selector] },
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: {
            code: 1,
            message: errorItem && errorItem.message,
          },
        },
      },
    }),
    [listCourseAssessmentCompletionData]: (state, { payload: { selector, items } }) => {
      return {
        ...state,
        [selector]: {
          ...state[selector],
          ...items,
        },
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            list: errorData,
          },
        },
      };
    },
    [listCourseAssessmentData]: (state, { payload: { selector, items } }) => {
      return {
        ...state,
        [selector]: {
          ...state[selector],
          ...items,
        },
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
            list: errorData,
          },
        },
      };
    },
    [courseAssessmentDashboardStudentNTopicBreakDown]: (
      state,
      { payload: { selector, data, page, count, appendRecords } }
    ) => {
      const configuredTableProps = (() => {
        const tableName = `topicBreakDownTable`;
        return {
          [tableName]: {
            ...state[tableName],
            count,
            page: page - 1,
            pages: Math.ceil(count / state[tableName].pageSize),
          },
        };
      })();

      return {
        ...state,
        [selector]: appendRecords ? [...state[selector], ...data] : data,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          [selector]: {
            ...state.apiErrors[selector],
          },
        },
        ...configuredTableProps,
      };
    },
  },
  defaultState
);

export default storedCourses;
