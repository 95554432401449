import React, { Component } from "react";

import { ChromePicker } from "react-color";
import IconSvgComponent from "components/common/IconSvgComponent";

import {
  ColorPickerHolder,
  ColorPicker,
  ColorPickerIndicator,
  ColorPickerOverlay,
} from "styles/components/Form";

class ColorPickerBlock extends Component {
  render() {
    const { background, onClose, onOpen, showPicker, onChange } = this.props;

    return (
      <ColorPickerHolder>
        <button type="butotn" onClick={onOpen}>
          <IconSvgComponent iconPath="svg/ico-color-picker.svg" />

          <ColorPickerIndicator chosenColor={background} />
        </button>

        <ColorPicker active={showPicker}>
          {showPicker && <ColorPickerOverlay onClick={onClose} />}
          <ChromePicker color={background ? background : "#2049E6"} onChange={onChange} />
        </ColorPicker>
      </ColorPickerHolder>
    );
  }
}

export default ColorPickerBlock;
