import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, useParams } from "react-router";

import Grid, { Col, Row } from "styles/components/Grid";
import SectionDashboard, { SectionBody } from "styles/components/SectionDashboard";
import ProgressCard from "components/card/ProgressCard";
import DashboardDetailsCard from "components/card/DashboardDetailsCard";
import { VideoConceptBreakdownCard } from "components/card/VideoTopicBreakdownCard";
import { VideoQuizStudentBreakdownCard } from "components/card/VideoQuizStudentBreakdownCard";
import { VideoStudentBreakdownCard } from "components/card/VideoStudentBreakdownCard";

import useAPIData from "components/useAPIData";
import {
  getSectionById,
  getAssignmentById,
  getVideoDetailsById,
  getVideoQuizAverageCorrectAnswers,
  getVideoConceptsBreakdown,
  getEnrolledCourseStudents,
  getVideoCompletionData,
  getVideoQuizCompletionData,
} from "store/state/courses/actions";
import { getQuizData } from "store/state/video/actions";
import { padTime } from "utilities/commonFunctions";
import TabNav from "components/elements/TabNav";
import CompletionCard from "components/card/CompletionCard";

// Discipline API not yet available
const getDiscipline = (disciplineId) => {
  switch (disciplineId) {
    case "1":
      return "Mathematics";
    case "2":
      return "Physics";
    case "3":
      return "Chemistry";
    case "4":
      return "Science";
    case "5":
      return "Biology";
    default:
      return "Mathematics";
  }
};

const CourseVideoDashboard = () => {
  const dispatch = useDispatch();
  const { courseId, assignmentId } = useParams();
  const course = useSelector((state) => state.storedCourses.sections[courseId]);
  const assignment = useSelector((state) => state.storedCourses.assignments[assignmentId]);

  const tabRoutes = [
    { name: "Video Summary", path: `/courses/${courseId}/analytics/${assignmentId}` },
    { name: "Quiz", path: `/courses/${courseId}/analytics/${assignmentId}/quiz` },
  ];

  const getVideoDetails = useCallback(
    () => dispatch(getVideoDetailsById({ videoId: assignment.videoId })),
    [dispatch, assignment.videoId]
  );

  const getAverageCorrectAnswers = useCallback(
    () => dispatch(getVideoQuizAverageCorrectAnswers(assignmentId)),
    [dispatch, assignmentId]
  );

  const getVideoConceptsBreakdown = useCallback(
    () => dispatch(getVideoConceptsBreakdown(assignmentId)),
    [assignmentId]
  );

  const getVideoQuiz = useCallback(() => dispatch(getQuizData({ videoId: assignment.videoId })), [
    dispatch,
    assignment.videoId,
  ]);

  const getEnrolledStudents = useCallback(() => dispatch(getEnrolledCourseStudents({ courseId })), [
    courseId,
  ]);

  const getVideoCompletion = useCallback(() => dispatch(getVideoCompletionData({ assignmentId })), [
    dispatch,
    assignmentId,
  ]);

  const getVideoQuizCompletion = useCallback(
    () => dispatch(getVideoQuizCompletionData({ assignmentId })),
    [dispatch, assignmentId]
  );

  const [videoLoaded, video] = useAPIData({
    fetchDataCallback: getVideoDetails,
    dependencies: getVideoDetails,
  });

  const [videoQuizLoaded, videoQuiz] = useAPIData({
    fetchDataCallback: getVideoQuiz,
    dependencies: getVideoQuiz,
  });

  const [enrolledStudentsLoaded] = useAPIData({
    fetchDataCallback: getEnrolledStudents,
    dependencies: getEnrolledStudents,
  });

  return (
    <SectionDashboard>
      <SectionBody>
        <Grid gutterSmall colSpacing>
          <Row>
            <Col>
              {course && assignment ? (
                <DashboardDetailsCard
                  dataLoaded={videoLoaded && videoQuizLoaded}
                  title={video && video[0].title}
                  subheader={assignment.name}
                  detailsList={[
                    {
                      name: "Duration",
                      value:
                        video &&
                        `${padTime(Math.floor(video[0].duration / 60))}:${padTime(
                          video[0].duration % 60
                        )}`,
                    },
                    {
                      name: "Quiz questions",
                      value:
                        videoQuiz && videoQuiz.data.questions && videoQuiz.data.questions.length,
                    },
                    { name: "Course", value: course.name },
                    {
                      name: "Discipline",
                      value: `${getDiscipline(course.discipline)} ${course.courseNumber}`,
                    },
                    { name: "Term/Year", value: `${course.term} ${course.year}` },
                  ]}
                  iconPath="svg/ico-type1.svg"
                  iconClass="dashboard-video-icon"
                />
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col style={{ paddingBottom: "0px" }}>
              <TabNav routes={tabRoutes} alignment="left" />
            </Col>
          </Row>
          <Switch>
            <Route exact path="/courses/:courseId/analytics/:assignmentId">
              <Row>
                <Col size="33.33">
                  <CompletionCard
                    title={"Video Completion"}
                    height="570px"
                    getDataHandler={getVideoCompletion}
                    dataMap={(data = {}) => {
                      return [
                        [
                          { name: "Completed", value: data.completed || 0 },
                          { name: "Started", value: data.started || 0 },
                          { name: "Not Started", value: data.not_started || 0 },
                        ],
                      ];
                    }}
                  />
                </Col>
                <Col size="66.67">
                  {enrolledStudentsLoaded ? (
                    <VideoStudentBreakdownCard assignmentId={assignmentId} />
                  ) : null}
                </Col>
              </Row>
            </Route>
            <Route exact path="/courses/:courseId/analytics/:assignmentId/quiz">
              <Row>
                <Col size="50">
                  <CompletionCard
                    title={"Video Quiz Completion"}
                    getDataHandler={getVideoQuizCompletion}
                    dataMap={(data = {}) => {
                      return [
                        [
                          { name: "Completed", value: data.completed || 0 },
                          { name: "Started", value: data.started || 0 },
                          { name: "Not Started", value: data.not_started || 0 },
                        ],
                      ];
                    }}
                  />
                </Col>
                <Col size="50">
                  <ProgressCard
                    title={"Avg Questions Correct"}
                    getDataHandler={getAverageCorrectAnswers}
                    potentialSelector={(data) => data && data.totalQuestions}
                    actualSelector={(data) => data && data.averageQuestionsCorrect}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <VideoConceptBreakdownCard assignmentId={assignmentId} />
                </Col>
                <Col>
                  {enrolledStudentsLoaded ? (
                    <VideoQuizStudentBreakdownCard assignmentId={assignmentId} />
                  ) : null}
                </Col>
              </Row>
            </Route>
          </Switch>
        </Grid>
      </SectionBody>
    </SectionDashboard>
  );
};

export default CourseVideoDashboard;
