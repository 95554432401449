//Preferred : Create one object constant per page, naming convension const [PAGE_NAME]_MESSAGES

export const FORM_CREATE_USER_MESSAGES = {
  USER_NAME_REQUIRED: "User Name is a required field.",
  USER_NAME_MAX: "The maximum number of characters for User Name is 255.",
  USER_NAME_FORMAT_INVALID: "User Name must be a valid format.",
  FIRST_NAME_REQUIRED: "First Name is a required field.",
  FIRST_NAME_MAX: "The maximum number of characters for First Name is 255.",
  LAST_NAME_REQUIRED: "Last Name is a required field.",
  LAST_NAME_MAX: "The maximum number of characters for Last Name is 255.",
  EMAIL_REQUIRED: "Email is a required field.",
  EMAIL_MAX: "The maximum number of characters for Email is 128.",
  EMAIL_FORMAT_INVALID: "Email must be a valid email format.",
  ROLE_REQUIRED: "Role is a required field.",
  STUDENT_ID_REQUIRED: "Student ID is a required field.",
  STUDENT_ID_MAX: "The maximum number of characters for Student ID is 255.",
  YEAR_REQUIRED: "Year is a required field.",
};

export const FORM_PASSWORD_RESET_MESSAGES = {
  EMAIL_REQUIRED: "Email is a required field.",
  EMAIL_FORMAT_INVALID: "Email must be a valid email format.",
};

export const FORM_CHANGE_PASSWORD_MESSAGES = {
  EMAIL_REQUIRED: "Email is a required field.",
  RESET_CODE_REQUIRED: "Reset Code is a required field.",
  NEW_PASSWORD_REQUIRED: "New Password is a required field.",
  REPEAT_NEW_PASSWORD_REQUIRED: "Repeat New Password is a required field.",
};

export const FORM_CREATE_PASSWORD_MESSAGES = {
  PASSWORD: "Password is a required field.",
  REPEAT_PASSWORD_REQUIRED: "Repeat Password is a required field.",
};

export const FORM_LOGIN_MESSAGES = {
  EMAIL_REQUIRED: "Email is a required field.",
  EMAIL_FORMAT_INVALID: "Email must be a valid email format.",
  PASSWORD_REQUIRED: "Password is a required field.",
  PASSWORD_RESET_EMAIL_SENT:
    "An email with password reset instructions has been sent to your email address, if it exists in our system.",
  SUCCESS_CHANGE_PASSWORD_FIRST_LOGIN: "Password created successfully.",
  ERROR_CHANGE_PASSWORD_FIRST_LOGIN: "Session expired, please login again with temporary password.",
};

export const FORM_DEPLOYMENT_MESSAGES = {
  OWNER_EMAIL_REQUIRED: "Email is a required field.",
  OWNER_EMAIL_FORMAT_INVALID: "Email must be a valid email format.",
  OWNER_EMAIL_MAX: "The maximum number of characters for Email is 128.",
  DEPLOYMENT_NAME_REQUIRED: "Deployment Name is a required field.",
  DEPLOYMENT_NAME_MAX: "The maximum number of characters for Deployment Name is 255.",
  PROGRAM_REQUIRED: "Program is a required field.",
  RETAKE_NUMBER_REQUIRED: "Retake Limit is a required field.",
  COOLING_PERIOD_REQUIRED: "Cooling Period is a required field.",
  OWNER_NAME_REQUIRED: "Full Name is a required field.",
  OWNER_NAME_MAX: "The maximum number of characters for Full Name is 255.",
  START_DATE_REQUIRED: "Start Date is a required field.",
  END_DATE_REQUIRED: "End Date is a required field.",
  START_DATE_INVALID: "Start Date must be a valid date.",
  END_DATE_INVALID: "End Date must be a valid date.",
  START_DATE_GREATER_THAN_END_DATE: "Start date should not be greater than end date.",
  START_DATE_END_DATE_INVALID: "Invalid start or end date.",
  COHORT_REQUIRED: "Cohort is a required field.",
  ACCESS_CODE_REQUIRED: "Access Code is a required field.",
  ACCESS_CODE_LENGTH: "Access Code should contain exactly 6 digits.",
  ACCESS_CODE_NUMERIC_ONLY: "Access Code should be numeric.",
  ASSESSMENT_TIME_REQUIRED: "Assessment Time is a required field.",
  ASSESSMENT_DEFAULT_WELCOME_MESSAGE: `Welcome to the <assessment_name>! This placement exam will test your knowledge of foundational mathematics.
It is highly recommended that you study before you take the placement exam for the first time. The lessons in the Learning Library are designed to help you.
You are permitted up to <max_attempt> total attempts to take and pass this placement exam. You currently have <remaining_attempt> attempts remaining.
The math placement exam is important and you should not rush through it. You should not start the placement exam unless you have enough time to complete it.
Once you begin, you will have <time_limit> minutes to complete the placement exam. You are not allowed to close and restart the exam.
<course_cutoff_list>
The purpose of the math placement exam is to make sure that you can enroll in a math class that is at the right level for you.
It is in your best interest to take this exam honestly and seriously.`,
};

export const FORM_CREATE_ACCOUNT_MESSAGES = {
  ORGANIZATION_REQUIRED: "Organization Name is a required field.",
  ORGANIZATION_MAX: "The maximum number of characters for Organization is 255.",
  CONTACT_NAME_REQUIRED: "Contact Name is a required field.",
  CONTACT_NAME_INVALID: "Contact Name should not be numeric.",
  CONTACT_NAME_MAX: "The maximum number of characters for Contact Name is 255.",
  CONTACT_EMAIL_REQUIRED: "Email is a required field.",
  CONTACT_EMAIL_INVALID: "Contact Email must be a valid email format.",
  CONTACT_EMAIL_MAX: "The maximum number of characters for Contact Email is 128.",
  CONTACT_PHONE_REQUIRED: "Contact Phone is a required field.",
  CONTACT_PHONE_MIN: "Phone number is incomplete.",
  CONTACT_PHONE_MAX: "The maximum number of characters for Contact Phone is 21.",
  ADDRESS1_MAX: "The maximum number of characters for Address 1 is 255.",
  ADDRESS2_MAX: "The maximum number of characters for Address 2 is 255.",
  CITY_MAX: "The maximum number of characters for City is 255.",
  USER_LIMIT_REQUIRED: "User Limit is a required field.",
  USER_LIMIT_MIN: "User Limit should be at least 1.",
};

export const COMMON_MESSAGES = {
  ERROR_OCCURRED: "An error has occurred.",
  API_ERROR_OCCURRED: "There was a server error.",
};

export const FORM_CREATE_COHORT_MESSAGES = {
  COHORT_NAME_REQUIRED: "Cohort Name is a required field.",
  COHORT_NAME_MAX: "The maximum number of characters for Cohort Name is 255.",
  SEMESTER_REQUIRED: "Semester is a required field.",
  START_DATE_REQUIRED: "Start Date is a required field.",
  END_DATE_REQUIRED: "End Date is a required field.",
  START_DATE_INVALID: "Start Date must be a valid date.",
  END_DATE_INVALID: "End Date must be a valid date.",
  START_DATE_GREATER_THAN_END_DATE: "End date must be after start date.",
};

export const FORM_CREATE_STUDENT_MESSAGES = {
  USER_NAME_REQUIRED: "User Name is a required field.",
  USER_NAME_MAX: "The maximum number of characters for User Name is 255.",
  USER_NAME_FORMAT_INVALID: "User Name must be a valid format.",
  FIRST_NAME_REQUIRED: "First Name is a required field.",
  FIRST_NAME_MAX: "The maximum number of characters for First Name is 255.",
  LAST_NAME_REQUIRED: "Last Name is a required field.",
  LAST_NAME_MAX: "The maximum number of characters for Last Name is 255.",
  EMAIL_REQUIRED: "Email is a required field.",
  EMAIL_MAX: "The maximum number of characters for Email is 128.",
  EMAIL_FORMAT_INVALID: "Email must be a valid email format.",
  ROLE_REQUIRED: "Role is a required field.",
  STUDENT_ID_REQUIRED: "Student ID is a required field.",
  STUDENT_ID_MAX: "The maximum number of characters for Student ID is 255.",
  YEAR_REQUIRED: "Year is a required field.",
};

export const ASSESSMENT_PASSCODE_FORM = {
  ACCESS_CODE_REQUIRED: "Access code is a required field.",
};

export const FORM_CREATE_COURSE_MESSAGES = {
  COURSE_NAME_REQUIRED: "Course Name is a required field.",
  COURSE_NAME_MAX: "The maximum number of characters for Course Name is 255.",
  COURSE_NUMBER_REQUIRED: "Course Number is a required field.",
  COURSE_NUMBER_MAX: "The maximum number of characters for Course Number is 255.",
  COURSE_SECTION_REQUIRED: "Lecture Section Number is a required field.",
  COURSE_SECTION_MAX: "The maximum number of characters for Course Section is 255.",
  COURSE_ROOM_REQUIRED: "Room is a required field.",
  COURSE_ROOM_MAX: "The maximum number of characters for Course Room is 255.",
  COURSE_TERM_MAX: "The maximum number of characters for Course Term is 255.",
  COURSE_BACKGROUND_REQUIRED: "Background is a required field.",
  COURSE_DISCIPLINE_REQUIRED: "Discipline is a required field.",
};

export const FORM_CREATE_COURSE_UNIT_MESSAGES = {
  COURSE_UNIT_NAME_REQUIRED: "Unit Name is a required field.",
  COURSE_UNIT_NAME_MAX: "The maximum number of characters for Unit Name is 255.",
  START_DATE_REQUIRED: "Start Date is a required field.",
  END_DATE_REQUIRED: "End Date is a required field.",
  START_DATE_INVALID: "Start Date must be a valid date.",
  END_DATE_INVALID: "End Date must be a valid date.",
  START_DATE_GREATER_THAN_END_DATE: "Start date should not be greater than end date.",
};

export const FORM_CREATE_COURSE_ASSIGNMENT_MESSAGES = {
  COURSE_ASSIGNMENT_NAME_REQUIRED: "Assignment Name is a required field.",
  COURSE_ASSIGNMENT_NAME_MAX: "The maximum number of characters for Assignment Name is 255.",
  COURSE_ASSIGNMENT_INSTRUCTION_MAX:
    "The maximum number of characters for Assignment Instruction is 255.",
  COURSE_ASSIGNMENT_START_DATE_REQUIRED: "Start Date is a required field.",
  COURSE_ASSIGNMENT_END_DATE_REQUIRED: "End Date is a required field.",
  COURSE_ASSIGNMENT_DATE_INVALID: "Invalid date Format.",
  COURSE_ASSIGNMENT_POINTS_REQUIRED: "Points is a required field.",
  COURSE_ASSIGNMENT_ATTEMPTS_REQUIRED: "Attempts is a required field.",
  COURSE_ASSIGNMENT_UNIT_START_DATE_SCOPE: "Start Date should be on or after Unit Start Date.",
  COURSE_ASSIGNMENT_UNIT_END_DATE_SCOPE: "End Date should be on or before Unit End Date.",
};

export const FORM_CREATE_COURSE_SETTINGS_MESSAGES = {
  COURSE_NAME_REQUIRED: "Course Name is a required field.",
  COURSE_NAME_MAX: "The maximum number of characters for Unit Name is 255.",
  COURSE_SECTION_MAX: "The maximum number of characters for Section is 255.",
  COURSE_SUBJECT_MAX: "The maximum number of characters for Subject is 255.",
  COURSE_ROOM_MAX: "The maximum number of characters for Room is 255.",
  UNIT_LABEL_REQUIRED: "Unit Label is a required field.",
  UNIT_LABEL_MAX: "The maximum number of characters for Unit Label is 255.",
  SETTINGS_1_MAX: "The maximum number of characters for Settings 1 is 255.",
  SETTINGS_2_MAX: "The maximum number of characters for Settings 2 is 255.",
  GRADING_WEIGHT_MAX: "The maximum number of characters for Grading Weight is 255.",
};
