/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

/**
 * The internal dependencies.
 */
import { closeModal } from "store/state/ui/actions";
import { updateDeployment } from "store/state/deployment/actions";
import { resetApiErrors } from "store/state/administration/actions";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import Field from "components/common/Field";
import {
  FormDefault as Form,
  FormBody,
  FormSection,
  FormRowGrid,
  FormCol,
  FormControls,
  FormActions,
} from "styles/components/Form";
import { setValidityAndMessage, validateDateField } from "utilities/validation";
import { FORM_DEPLOYMENT_MESSAGES } from "constants/messages";
import { FORMAT } from "constants/common";
import { WIZARD_DEPLOYMENT_SUMMARY, WIZARD_CREATE_DEPLOYMENT } from "lib/constants";
import FieldErrors from "../common/FieldErrors";

class FormCreateDeploymentSchedule extends Component {
  state = {
    startDate: this.props.deployment.startDate
      ? moment(this.props.deployment.startDate).format(
          `${FORMAT.DATE_DEPLOYMENT} ${FORMAT.TIME_DEPLOYMENT}`
        )
      : null,
    endDate: this.props.deployment.endDate
      ? moment(this.props.deployment.endDate).format(
          `${FORMAT.DATE_DEPLOYMENT} ${FORMAT.TIME_DEPLOYMENT}`
        )
      : null,
    formErrors: {
      startDate: {
        valid: true,
        message: "",
      },
      endDate: {
        valid: true,
        message: "",
      },
      dates: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };

  rules = {
    startDate: {
      requiredMessage: FORM_DEPLOYMENT_MESSAGES.START_DATE_REQUIRED,
      typeDateMessage: FORM_DEPLOYMENT_MESSAGES.START_DATE_INVALID,
      valueGreaterThan: "endDate", // For date comparison
      fromToInvalidMessage: FORM_DEPLOYMENT_MESSAGES.START_DATE_GREATER_THAN_END_DATE,
    },
    endDate: {
      requiredMessage: FORM_DEPLOYMENT_MESSAGES.END_DATE_REQUIRED,
      typeDateMessage: FORM_DEPLOYMENT_MESSAGES.END_DATE_INVALID,
    },
  };

  componentDidMount() {
    const { resetApiErrors, selectedCohort } = this.props;
    const { startDate, endDate } = this.state;

    resetApiErrors();

    if (selectedCohort && !startDate && !endDate) {
      this.setState({
        startDate: moment(selectedCohort.start_date).format(
          `${FORMAT.DATE_DEPLOYMENT} ${FORMAT.TIME_DEPLOYMENT}`
        ),
        endDate: moment(selectedCohort.end_date).format(
          `${FORMAT.DATE_DEPLOYMENT} ${FORMAT.TIME_DEPLOYMENT}`
        ),
      });
    }
  }

  getMomentObjectFromDateString(value) {
    if (typeof value === "string") {
      const dateTimeParts = value.split(" at ");
      const dateTime = dateTimeParts.join(" ");
      value = moment(dateTime);
    }
    return value;
  }

  handleDatepickerChange = (key) => (date) => {
    this.props.resetApiErrors();

    this.setState({
      [key]: date,
    });
  };

  validateFormDateField = (name, value) => {
    value =
      !value || (typeof value === "string" && value.trim() === "")
        ? undefined
        : this.getMomentObjectFromDateString(value);

    this.setState({
      formErrors: validateDateField(this.state.formErrors, this.rules, {
        name,
        value,
        required: true,
        compareWith:
          name === "startDate"
            ? this.getMomentObjectFromDateString(this.state.endDate)
            : this.getMomentObjectFromDateString(this.state.startDate),
      }),
    });
  };

  validateDates() {
    setValidityAndMessage("dates", this.state.formErrors, true, "");

    this.validateFormDateField("startDate", this.state.startDate);
    this.validateFormDateField("endDate", this.state.endDate);

    if (this.state.formErrors.formValid) {
      this.setState({
        formValid: this.state.formErrors.formValid,
      });
    }
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.validateDates();

    if (this.state.formErrors.formValid) {
      const { updateDeployment, changeStep } = this.props;
      const { startDate, endDate } = this.state;

      const resp = await updateDeployment({
        startDate: this.getMomentObjectFromDateString(startDate).format(),
        endDate: this.getMomentObjectFromDateString(endDate).format(),
        is_draft: true,
      });

      if (resp.status === "success") {
        changeStep(WIZARD_DEPLOYMENT_SUMMARY, WIZARD_CREATE_DEPLOYMENT)();
      }
    }
  };

  handleDraftSave = async (e) => {
    e.preventDefault();

    const { updateDeployment } = this.props;
    const { startDate, endDate } = this.state;

    await updateDeployment({
      startDate: startDate ? this.getMomentObjectFromDateString(startDate).format() : null,
      endDate: endDate ? this.getMomentObjectFromDateString(endDate).format() : null,
      is_draft: true,
    });
  };

  render() {
    const {
      props: { modal, prevStep, apiCallInProgress, deploymentsApiErrors, selectedCohort },
      state: { startDate, endDate, formErrors },
    } = this;
    const { create: createApiErrors, update: updateApiErrors } = deploymentsApiErrors;
    const cohortStartDate = selectedCohort?.start_date || "";
    const cohortEndDate = selectedCohort?.end_date || "";

    return (
      <Form fulloNmobile onSubmit={this.handleFormSubmit} colSpacing>
        <FieldErrors
          formErrors={formErrors}
          apiErrorMessage={createApiErrors.message || updateApiErrors.message}
        />
        <FormBody>
          <FormSection>
            <FormRowGrid>
              <FormCol half>
                <FormControls>
                  <Field
                    icon="ico-calendar"
                    id="startDate"
                    isDateTimePicker
                    onChange={this.handleDatepickerChange("startDate")}
                    value={startDate}
                    placeholder="Select Start Date & Time"
                    required
                    error={!formErrors.startDate.valid}
                    dateFormat={FORMAT.DATE_DEPLOYMENT}
                    showTimeSelect={FORMAT.TIME_DEPLOYMENT}
                    onOrAfter={cohortStartDate}
                    onOrBefore={cohortEndDate}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    icon="ico-calendar"
                    id="endDate"
                    isDateTimePicker
                    onChange={this.handleDatepickerChange("endDate")}
                    value={endDate}
                    placeholder="Select End Date & Time"
                    required
                    error={!formErrors.endDate.valid}
                    dateFormat={FORMAT.DATE_DEPLOYMENT}
                    showTimeSelect={FORMAT.TIME_DEPLOYMENT}
                    notAfter={cohortEndDate}
                    notBefore={startDate ? startDate : cohortStartDate}
                  />
                </FormControls>
              </FormCol>
            </FormRowGrid>
          </FormSection>
        </FormBody>

        <FormActions flex>
          <ButtonLink type="submit" violet hasIcon isPrevious onClick={prevStep}>
            <IconSvgComponent iconPath="svg/ico-arrow-left-large.svg" />
            <span>Previous</span>
          </ButtonLink>
          <ButtonLink
            disabled={apiCallInProgress}
            transparent
            onClick={this.handleDraftSave}
            type="button"
          >
            {modal.data.editDeployment ? "Save Changes" : "Save Draft"}
          </ButtonLink>
          <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
            <span>Next Step</span>
            <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    deployment: state.deployment,
    modal: state.ui.modal,
    apiCallInProgress: state.administration.apiCallInProgress,
    deploymentsApiErrors: state.administration.apiErrors.deployments,

    selectedCohort: state.administration.cohorts.find(
      (c) => c.id === state.deployment.cohort.value
    ),
  }),
  {
    updateDeployment,
    resetApiErrors,
    closeModal,
  }
)(FormCreateDeploymentSchedule);
