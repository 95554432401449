import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import DocumentTitle from "react-document-title";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { CSVLink } from "react-csv";

import { ASSIGNMENT_TYPES } from "constants/common";
import { media } from "styles/media";
import { openModal, closeModal } from "store/state/ui/actions";
import { setActiveCourse, getAssignmentGrades, getAssignments } from "store/state/courses/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import TableDropdown, { DropdownWrapper, DropdownButton } from "components/elements/TableDropdown";
import CourseSubnav from "components/courses/CourseSubnav";
import CourseTabs, { CourseTab, CourseTabsContent } from "styles/components/CourseTabs";
import { ButtonEdit } from "styles/components/TitleEditable";
import Tooltip from "styles/components/Tooltip";
import SectionTable, { SectionBody } from "styles/components/SectionTable";
import Loader from "components/common/loader";
// import "react-table/react-table.css";
// import "styles/react_table_override.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "styles/react_table_fixed_columns_override.css";
import { StyledSpan } from "styles/components/TableDefault";
import { Pill } from "styles/components/Btns";
import TabNav from "components/elements/TabNav";
import { PaginatedTable } from "components/common/table";

// const TableWithFixedColumns = withFixedColumns(PaginatedTable);

const selectCourseAssignments = (courseId) => (state) => {
  return Object.values(state.storedCourses.assignments).filter(
    (assignment) => assignment.sectionId === courseId
  );
};

const CourseDetailsStudentGrades = (props) => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const [dataLoaded, setDataLoaded] = useState(false);

  const assignments = useSelector(selectCourseAssignments(courseId));
  const assignmentColumns = useSelector((state) =>
    selectCourseAssignments(courseId)(state)?.map((assignment) => ({
      Header: assignment.name,
      accessor: `assignment_grades_${assignment.id}`,
      minWidth: 250,
      Cell: (props) => {
        const { original } = props.row;
        const matchingAssignment = original.assignment_grades.find(
          (tableAssignment) => tableAssignment.assignment.id === assignment.id
        );
        const isGraded = assignment.gradedIndicator;

        const assignmentGrade = matchingAssignment && matchingAssignment.grade;
        const isVideoAssignment = assignment.assignmentType === ASSIGNMENT_TYPES.VIDEO;
        const isOpenStaxAssignment = assignment.assignmentType === ASSIGNMENT_TYPES.OPENSTAX;
        const completionStatus = isVideoAssignment
          ? matchingAssignment?.video?.status
          : isOpenStaxAssignment
          ? matchingAssignment?.activity?.status === "DONE"
            ? "Complete"
            : "Incomplete"
          : assignmentGrade?.status;

        return isGraded && assignmentGrade && typeof assignmentGrade.score === "number" ? (
          `${assignmentGrade.score}%`
        ) : !isGraded ? (
          <Pill
            {...{
              defaultOutline: completionStatus === "Incomplete" || !completionStatus,
              defaultSuccessOutline: completionStatus === "Complete",
            }}
          >
            {completionStatus ? completionStatus : "Incomplete"}
          </Pill>
        ) : (
          "—"
        );
      },
    }))
  );

  const gradesByStudent = useSelector((state) => {
    return Object.values(state.storedCourses.gradesByStudent);
  });

  const getAssignmentGradesObject = useCallback(
    (item) => {
      let assignmentGradesObj = {};
      assignments.forEach((assignment) => {
        const matchingAssignment = item.assignment_grades.find(
          (tableAssignment) => tableAssignment.assignment.id === assignment.id
        );
        const isVideoAssignment = assignment.assignmentType === ASSIGNMENT_TYPES.VIDEO;
        const isOpenStaxAssignment = assignment.assignmentType === ASSIGNMENT_TYPES.OPENSTAX;

        if (isVideoAssignment) {
          const videoAssignmentCompletionStatus =
            isVideoAssignment && matchingAssignment && matchingAssignment.video
              ? matchingAssignment.video.status
              : "Incomplete";
          assignmentGradesObj[
            `assignment_grades_${assignment.id}`
          ] = videoAssignmentCompletionStatus;
        } else if (isOpenStaxAssignment) {
          const openStaxAssignmentCompletionStatus =
            isOpenStaxAssignment && matchingAssignment?.activity?.status === "DONE"
              ? "Complete"
              : "Incomplete";
          assignmentGradesObj[
            `assignment_grades_${assignment.id}`
          ] = openStaxAssignmentCompletionStatus;
        } else {
          const assignmentGrade = matchingAssignment && matchingAssignment.grade;

          assignmentGradesObj[`assignment_grades_${assignment.id}`] =
            assignmentGrade && typeof assignmentGrade.score === "number"
              ? `${assignmentGrade.score}%`
              : "—";
        }
      });
      return assignmentGradesObj;
    },
    [assignments]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Student Name",
        accessor: "student",
        width: 200,
        sticky: "left",
        Cell: (props) => {
          const { original } = props.row;

          return (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <IconSvgComponent iconPath="svg/ico-user-blue.svg" />
              <StyledSpan
                hasIconOnLeft
                bold
                style={{
                  width: "fit-content",
                  whiteSpace: "nowrap",
                  overflowX: "clip",
                  textOverflow: "ellipsis",
                  paddingTop: "0px",
                }}
              >
                {`${original.student.name} ${original.student.family_name}`}
              </StyledSpan>
            </div>
          );
        },
      },
      {
        Header: "Overall Grade",
        accessor: "overall_grade",
        width: 125,
        sticky: "left",
        Cell: (props) => {
          return <div>{props.value}%</div>;
        },
      },
      ...assignmentColumns,
      {
        accessor: "actions",
        width: 75,
        sticky: "right",
        Header: () => {
          const getDataForDownload = () => {
            const studentNameIndex = columns.findIndex(
              (column) => column.Header === "Student Name"
            );
            const downloadColumns = columns.slice();
            downloadColumns.splice(
              studentNameIndex,
              1,
              {
                Header: "First Name",
                accessor: "student",
              },
              {
                Header: "Last Name",
                accessor: "lastName",
              },
              {
                Header: "email",
                accessor: "email",
              },
              {
                Header: "Student ID",
                accessor: "studentId",
              }
            );
            const headers = downloadColumns
              .filter(
                ({ Header, accessor }) => typeof Header == "string" && typeof accessor == "string"
              )
              .map(({ Header, accessor }) => {
                return {
                  key: accessor,
                  label: Header,
                };
              });

            const data = gradesByStudent.map((item) => {
              return {
                student: item.student.name,
                lastName: item.student.family_name,
                email: item.student.email,
                studentId: item.student.external_id,
                overall_grade: `${item.overall_grade}%`,
                ...getAssignmentGradesObject(item),
              };
            });

            return {
              headers,
              data,
            };
          };
          const { data, headers } = getDataForDownload();

          return (
            assignmentColumns?.length && (
              <CSVLink data={data} headers={headers} filename="student_grades_data.csv">
                <>
                  <ButtonEdit data-tip="Download .CSV" data-place={"bottom"}>
                    <IconSvgComponent
                      iconPath="svg/ico-download.svg"
                      additionalClass={"icon-download"}
                    />
                  </ButtonEdit>
                  <Tooltip className="transform-none" />
                </>
              </CSVLink>
            )
          );
        },
        Cell: (props) => "",
      },
    ];
  }, [assignmentColumns, getAssignmentGradesObject, gradesByStudent]);

  const fetchData = useCallback(async () => {
    setDataLoaded(false);

    try {
      const assignmentsPromise = dispatch(getAssignments({ sectionIds: [courseId] }));
      const gradesPromise = dispatch(getAssignmentGrades({ sectionId: courseId }));

      await Promise.all([assignmentsPromise, gradesPromise]);

      setDataLoaded(true);
    } catch (error) {
      console.log({ error });
      setDataLoaded(true);
    }
  }, [courseId, dispatch]);

  useEffect(() => {
    if (!dataLoaded) {
      fetchData();
    }
  }, [dataLoaded, fetchData]);

  const tabRoutes = [
    { name: "Assignments", path: `/courses/${courseId}/grades/assignments` },
    { name: "Students", path: `/courses/${courseId}/grades/students` },
  ];

  return (
    <DocumentTitle title="Stemify | Course">
      <CourseTabs>
        <CourseSubnav />

        <CourseTabsContent>
          <CourseTab active={true}>
            <TabNav routes={tabRoutes} alignment="center" />

            {dataLoaded ? (
              <SectionTable style={{ padding: "0px" }}>
                <SectionBody>
                  <PaginatedTable
                    isSticky={true}
                    data={gradesByStudent}
                    columns={columns}
                    isLoading={!dataLoaded}
                    count={gradesByStudent.length}
                    // sortable={false}
                    // resizable={false}
                    // minRows={0}
                    // pageSize={gradesByStudent.length}
                    /* style={{ overflowX: "scroll" }} */
                    /* // style={{ overflowX: "scroll" }} */
                  />
                </SectionBody>
              </SectionTable>
            ) : (
              <Loader />
            )}
          </CourseTab>
        </CourseTabsContent>
      </CourseTabs>
    </DocumentTitle>
  );
};

export default CourseDetailsStudentGrades;
