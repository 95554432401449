/**
 * @ External Dependecies
 */
import { injectGlobal } from "styled-components";

injectGlobal`
	.custom-datepicker.error {
		border-bottom: 1px solid #FF0000;
	}
	.custom-datepicker {
		.form-control {
			display: block;
			width: 100%;
			padding: 17px 0 14px 30px;
			border: none;
			border-bottom: 1px solid rgba(138, 144, 156, 0.25);
			font-size: 14px;
		}

		.rdtPicker {
			width: 242px;
			padding: 0;
			margin-top: 20px;
			background: #fff;
			border: 0 none;
			box-shadow: 0 0 7px -2px rgba(0,0,0,0.50), 0 41px 51px -6px rgba(0,0,0,0.27);
			border-radius: 2px;

			&:before {
				content: '';
				position: absolute;
				z-index: 5;
				bottom: calc(100% - 12px);
				left: 15px;
				width: 20px;
				height: 22px;
				background: #fff;
				transform: rotate(-45deg);
				box-shadow: 0 0 7px -2px rgba(0,0,0,0.50), 0 41px 51px -6px rgba(0,0,0,0.27);
			}

			.rdtTime {
				thead {
					th.rdtSwitch:hover,
					th.rdtSwitch {
						padding-top: 10px;
						text-align: center;
						font-size: 14px;
					}
				}

				.rdtCounters {
					margin-top: 30px !important;
				}	
			}

			table {
				position: relative;
				background: #fff;
				z-index: 10;

				td,
				th {
					border-radius: 50%;
				}

				thead {
					position: relative;
					
					tr:first-child th,
					tr:first-child th:hover {
						background: none;
						border: 0 none;
						border-radius: 0;
						text-align: left;
					}

					.dow {
						padding-top: 30px;
						font-size: 9.3px;
						text-transform: uppercase;
						color: rgba(52, 72, 94, 0.5);
					}

					.rdtSwitch {
						position: absolute;
						left: 0;
						top: 0;
						z-index: 5;
						width: 100%;
						font-size: 9px;
						text-transform: none;
						color: #9B9B9B;
					}
					
					.rdtNext,
					.rdtPrev {
						position: absolute;
						right: 0;
						top: 0;
						z-index: 20;
						width: 13px;
						height: 13px;

						span {
							display: block;
							width: 13px;
							height: 13px;
							background: #E3EBFA;
							border-radius: 50%;
							line-height: 11px;
							font-size: 18px;
							text-align: center;
							color: #8C96AB;
						}
					}

					.rdtPrev {
						right: 20px;
					}
				}

				tbody {
					td.rdtNew,
					td.rdtOld {
						color: rgba(52, 72, 94, 0.2);
					}

					.rdtDay {
						font-size: 8.7px;
						color: #7C86A2;
					}
					
					.rdtActive {
						color: #fff;
						background: #3269D6;

						&:before {
							display: none;
						}
					}

					.rdtToday {
						color: #7C86A2;
						background: transparent;

						&:before {
							display: none;
						}
					}
				}

				tfoot {
					td.rdtTimeToggle {
						border: 0 none;
						border-radius: 0;
					}
				}
			}
		}

		.rdtDays {
			position: relative;
			z-index: 10;
			transform: rotate(0deg);
			padding: 16px 20px;
			background: #fff;
		}
	}
`;
