import React, { useMemo, memo } from "react";
import { PaginatedTable } from "components/common/table";

const InputFilterComponent = (props) => {
  const { filterValue, setFilter } = props.column;

  return (
    <input
      type="text"
      onChange={(event) => setFilter(event.target.value)}
      value={filterValue || ""}
      style={{
        fontSize: "14px",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, .1)",
        backgroundColor: "#fff",
        padding: "5px 7px",
        borderRadius: "3px",
        fontWeight: 400,
        outlineWidth: 0,
      }}
      autoComplete="no"
    />
  );
};

const defaultFilter = memo((rows, columnIds, filterValue) => {
  const [columnId] = columnIds;

  return rows.filter((row) => {
    return (
      String(row.original[columnId])
        .toLowerCase()
        .indexOf(filterValue.toLowerCase()) !== -1
    );
  });
});

const DataTable = ({
  count,
  data,
  columns,
  loading,
  noDataComponent,
  classNames = "",
  defaultFilterMethod = defaultFilter,
  defaultSorted,
}) => {
  const columnsWithFilter = useMemo(() => {
    return columns.map((c) => ({
      ...c,
      Filter: InputFilterComponent,
      filter: defaultFilterMethod,
    }));
  }, [columns, defaultFilterMethod]);

  return (
    <PaginatedTable
      classNames={classNames}
      data={data}
      columns={columnsWithFilter}
      count={count}
      isLoading={loading}
      isFilteringEnabled={true}
      NoDataComponent={noDataComponent}
      defaultSorted={defaultSorted}
      style={{ fontSize: "14px", fontWeight: 500 }}
    />
  );
};

export default DataTable;
