import React, { Fragment } from "react";
import { connect } from "react-redux";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import AccountBreadcrumb from "./AccountBreadcrumb";
import CohortBreadcrumb from "./CohortBreadcrumb";
import LearningUnitBreadcrumb from "./LearningUnitBreadcrumb";
import learningModuleBreadcrump from "./LearningModuleBreadcrumb";
import AssessmentBreadCrumb, { AssessmentResultsBreadCrumb } from "./AssessmentBreadCrumb";
import AssessmentDeploymentBreadcrumb from "./AssessmentDeploymentBreadcrumb";
import AssessmentDashboardBreadcrumb from "./AssessmentDashboardBreadcrumb";
import DeploymentBreadcrumb from "./DeploymentBreadcrumb";
import StudentBreadCrumb from "./StudentBreadCrumb";
import StudentAttemptBreadCrumb from "./StudentAttemptBreadCrumb";
import CourseBreadcrumb, { AssignmentBreadcrumb } from "./CourseBreadcrumb";
import AssignmentVideoBreadcrumb from "./AssignmentVideoBreadcrumb";
import CourseAssessmentDashboardBreadcrumb from "./CourseAssessmentDashboardBreadcrumb";
import {
  StudentHome,
  StudentSelectedDeployment,
  StudentSelectedAssessmentSeries,
} from "./StudentHomeBreadcrumb";
import { getIsNavigationFromTopicFilter } from "utilities/localStore";
import { withRouter } from "react-router-dom";
import {
  PracticeAssignmentHome,
  PracticeAssignment,
  PracticeAssignmentResults,
} from "./PracticeAssignmentBreadcrumb";
import BreadcrumbsContainer, { CrumbItem } from "styles/components/BreadcrumbsContainer";
import { ROLES } from "constants/roles";
import stemifyRoutes from "constants/routes";
import learningTopicBreadcrump from "./LearningTopicBreadcrump";
import LearningLibraryBreadcrumb from "./LearningLibraryBreadcrumb";
import queryString from "query-string";
import { capitalizeFirstChar } from "utilities/commonFunctions";

const routes = [
  {
    path: `${stemifyRoutes.studentHome}`,
    breadcrumb: StudentHome,
  },
  {
    path: `${stemifyRoutes.assessments}/:deploymentId`,
    breadcrumb: AssessmentDeploymentBreadcrumb,
    disabled: true,
    hidearrow: "hidearrow",
  },
  {
    path: `${stemifyRoutes.assessments}/:deploymentId/:assessmentId`,
    breadcrumb: AssessmentBreadCrumb,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.assessments}/:deploymentId/:assessmentId/:attemptId`,
    hidearrow: true,
    breadcrumb: null,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.assessments}/:deploymentId/:assessmentId/:attemptId/results`,
    breadcrumb: AssessmentResultsBreadCrumb,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.accounts}/:accountId`,
    breadcrumb: AccountBreadcrumb,
  },
  {
    path: `${stemifyRoutes.cohorts}/:cohortId`,
    breadcrumb: CohortBreadcrumb,
  },
  {
    path: `${stemifyRoutes.learningLibraryLearningModule}/:moduleId`,
    breadcrumb: learningModuleBreadcrump,
    disabled: false,
  },
  {
    path: `${stemifyRoutes.learningLibraryLearningModule}`,
    breadcrumb: learningTopicBreadcrump,
    isTopicFilter: true,
  },
  {
    path: `${stemifyRoutes.learningLibrary}`,
    breadcrumb: LearningLibraryBreadcrumb,
  },
  {
    path: `${stemifyRoutes.learningLibrary}/:unitId`,
    breadcrumb: LearningUnitBreadcrumb,
  },
  {
    path: `${stemifyRoutes.learningLibrary}/:unitId/:moduleId`,
    breadcrumb: learningModuleBreadcrump,
  },
  {
    path: `${stemifyRoutes.practiceAssignment}`,
    breadcrumb: PracticeAssignmentHome,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.learningLibraryLearningModule}/:moduleId/:assignmentId`,
    breadcrumb: PracticeAssignment,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.learningLibraryLearningModule}/:moduleId/:assignmentId/results`,
    breadcrumb: PracticeAssignmentResults,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.learningLibrary}/:unitId/:moduleId/:assignmentId`,
    breadcrumb: PracticeAssignment,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.learningLibrary}/:unitId/:moduleId/:assignmentId/results`,
    breadcrumb: PracticeAssignmentResults,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.deployments}/:deploymentId`,
    breadcrumb: DeploymentBreadcrumb,
  },
  {
    path: `${stemifyRoutes.deployments}/:deploymentId/:assessmentId`,
    breadcrumb: AssessmentDashboardBreadcrumb,
  },
  {
    path: `${stemifyRoutes.deployments}/:deploymentId/:assessmentId/:studentId`,
    breadcrumb: StudentBreadCrumb,
  },
  {
    path: `${stemifyRoutes.deployments}/:deploymentId/:assessmentId/:studentId/:attemptId`,
    breadcrumb: StudentAttemptBreadCrumb,
  },
  {
    path: `${stemifyRoutes.faq}`,
    breadcrumb: "FAQ",
  },
  {
    path: `${stemifyRoutes.studentPerformanceDetails}`,
    breadcrumb: "My Dashboard",
  },
  {
    path: `${stemifyRoutes.studentPerformanceDetails}/:deplyomentId`,
    breadcrumb: StudentSelectedDeployment,
  },
  {
    path: `${stemifyRoutes.studentPerformanceDetails}/:deplyomentId/:assessmentSeriesId`,
    breadcrumb: StudentSelectedAssessmentSeries,
  },
  {
    path: "/courses",
    breadcrumb: "My Courses",
    disabled: false,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId`,
    breadcrumb: CourseBreadcrumb,
    link: ({ courseId }) => `/courses/${courseId}/coursework`,
    disabled: false,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework`,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId`,
    breadcrumb: AssignmentBreadcrumb,
    hidearrow: ({ match, location }) => {
      return location.pathname.indexOf("results") > -1 ? false : true;
    },
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId/exam/:assessmentId`,
    breadcrumb: () => "",
    hidearrow: true,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId/exam/:assessmentId/:attemptId`,
    breadcrumb: () => "",
    hidearrow: true,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId/exam`,
    hidearrow: true,
    breadcrumb: () => "",
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:unitId/:moduleId`,
    breadcrumb: AssignmentVideoBreadcrumb.LearningModuleName,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:unitId`,
    breadcrumb: AssignmentVideoBreadcrumb.LearningUnitName,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId`,
    breadcrumb: PracticeAssignment,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/people`,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/grades`,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/grades/assignments`,
    disabled: true,
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/grades/students`,
    disabled: true,
  },
  {
    path: "/courses/:courseId/analytics",
    disabled: true,
  },
  {
    path: "/courses/:courseId/analytics/:assignmentId",
    breadcrumb: CourseAssessmentDashboardBreadcrumb,
    disabled: true,
  },
  {
    path: "/admin",
    breadcrumb: "Admin",
    disabled: true,
  },
];

const Breadcrumbs = ({
  breadcrumbs,
  children,
  role,
  location: currentLocation,
  filteredTopicName,
  match,
}) => (
  <BreadcrumbsContainer>
    {breadcrumbs.map(
      ({ key, location, match, link, breadcrumb, disabled, hidearrow, isTopicFilter }) => {
        if (key === `${stemifyRoutes.studentHome}` && key !== location.pathname) return;
        if (role === ROLES.ACCOUNT_ADMIN && key === `${stemifyRoutes.accounts}`) return;

        if (isTopicFilter) {
          const { id, type } = getIsNavigationFromTopicFilter();

          return (
            <CrumbItem
              key={key}
              to={`${stemifyRoutes.learningLibrary}?id=${id}&type=${type}`}
              disabled={disabled}
              hidearrow={hidearrow}
            >
              {breadcrumb}
            </CrumbItem>
          );
        } else {
          const topicFilterParams = queryString.parse(location.search);
          const to = link ? link(match.params) : match.url;

          return (
            <Fragment key={key}>
              <CrumbItem
                key={key}
                to={to}
                disabled={disabled}
                hidearrow={
                  typeof hidearrow === "function" ? hidearrow({ match, location }) : hidearrow
                }
                match={match}
              >
                {breadcrumb}
              </CrumbItem>
              {topicFilterParams?.id && topicFilterParams?.type && (
                <CrumbItem
                  key={key}
                  to={`${stemifyRoutes.learningLibrary}?id=${topicFilterParams.id}&type=${topicFilterParams.type}`}
                >
                  {`${capitalizeFirstChar(filteredTopicName)}`}
                </CrumbItem>
              )}
            </Fragment>
          );
        }
      }
    )}

    {children}
  </BreadcrumbsContainer>
);

export default connect((state) => {
  const filteredTopicName = state.learningLibrary.filterTopic;
  return { filteredTopicName };
})(withBreadcrumbs(routes)(withRouter(Breadcrumbs)));
