/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

/**
 * The internal dependencies.
 */
import { create } from "store/state/administration/actions";
import { closeModal } from "store/state/ui/actions";
import Field from "components/common/Field";
import IconSvgComponent from "components/common/IconSvgComponent";
import SearchAutocomplete from "components/elements/SearchAutocomplete";
import ButtonLink from "components/common/ButtonLink";
import {
  FormTemplate,
  FormHead,
  FormBody,
  FormLogo,
  FormTitle,
  FormRowGrid,
  FormCol,
  FormControls,
  FormActions,
} from "styles/components/Form";

/**
 * Class for form content template.
 *
 * @class      FormContentTemplate (name)
 */
class FormContentTemplate extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    name: "",
    discipline: { value: "math", label: "Math" },
    template: "",
    templates: [{ title: "Calculus Placement 1" }, { title: "Calculus Placement 2" }],
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const generate = require("nanoid/generate");
    const {
      props: { programType, closeModal, create },
      state: { name, discipline, template },
    } = this;

    create({
      selector: "programs",
      item: {
        id: generate("1234567890", 8),
        name,
        programType,
        discipline,
        template,
        createdBy: "Admin 12",
        modifiedBy: "",
        dateCreated: moment(Date.now()).format(),
        dateModified: "",
      },
    });

    closeModal();
  };

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleSelectChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { name, discipline, templates } = this.state;

    return (
      <FormTemplate onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            <IconSvgComponent iconPath="svg/ico-template-colored.svg" />
          </FormLogo>

          <FormTitle>Create from Template</FormTitle>
        </FormHead>

        <FormBody>
          <FormRowGrid>
            <FormCol third>
              <FormControls>
                <Field
                  id="name"
                  onChange={this.handleInputChange("name")}
                  value={name}
                  placeholder="Program Name"
                  required
                />
              </FormControls>
            </FormCol>

            <FormCol third>
              <FormControls>
                <Field
                  isSelect
                  onChange={this.handleSelectChange("discipline")}
                  options={[{ value: "math", label: "Math" }]}
                  value={discipline}
                  placeholder="Discipline"
                  required
                  disabled
                />
              </FormControls>
            </FormCol>

            <FormCol third>
              <FormControls>
                <SearchAutocomplete
                  id="template"
                  placeholder="Template"
                  keysToFilter={["title"]}
                  onChange={this.handleSelectChange("template")}
                  data={templates}
                />
              </FormControls>
            </FormCol>
          </FormRowGrid>
        </FormBody>

        <FormActions>
          <ButtonLink type="submit" violet hasIcon>
            <span>Create Program</span>

            <IconSvgComponent iconPath="svg/ico-check-white.svg" />
          </ButtonLink>
        </FormActions>
      </FormTemplate>
    );
  }
}

export default connect(null, {
  closeModal,
  create,
})(FormContentTemplate);
