import { handleActions } from "redux-actions";

/**
 * @ Actions
 */
import { studentDashboardDetails, AssessmentPerformanceDetails } from "./action";
import { request, reject } from "./action";
import { errorData } from "../../../constants/api";

/**
 * @ Reducer
 */

const operations = {
  get: errorData,
};
const defaultState = {
  DashboardAssessmentList: {
    assessments: [],
    topicInfo: [],
  },
  selectedAssessmentDetails: {
    id: "",
    assessment_name: "",
    status: "",
    last_attempt_date: "",
    next_attempt_date: "",
    remaining_attempt: 0,
    end_date: "",
    status: "",
    score: {
      top_score: 0,
      total_score: 0,
      top_score_perc: 0,
      total_score_perc: 0,
    },
    assessment_course: [],
    attempt_history: [],
    topicInfo: [],
  },
  isLoading: true,
  apiErrors: {
    DashboardAssessmentList: {
      ...operations,
    },
    selectedAssessmentDetails: {
      ...operations,
    },
  },
};

const studentDashboard = handleActions(
  {
    [request]: (state, { payload: { selector, operation } }) => ({
      ...state,
      isLoading: true,
      [selector]: defaultState[selector],
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: errorData,
        },
      },
    }),
    [studentDashboardDetails]: (state, { payload: { selector, data } }) => ({
      ...state,
      [selector]: data,
      isLoading: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
        },
      },
    }),
    [AssessmentPerformanceDetails]: (state, { payload: { selector, data } }) => ({
      ...state,
      [selector]: data,
      isLoading: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
        },
      },
    }),
    [reject]: (state, { payload: { selector, operation, errorItem } }) => ({
      ...state,
      [selector]: defaultState[selector],
      isLoading: false,
      apiErrors: {
        ...state.apiErrors,
        [selector]: {
          ...state.apiErrors[selector],
          [operation]: {
            code: errorItem.errorCode,
            message: errorItem.message,
          },
        },
      },
    }),
  },
  defaultState
);

export default studentDashboard;
