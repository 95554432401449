/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty, contains } from "ramda";
import { Scrollbars } from "react-custom-scrollbars";
import ButtonLink from "components/common/ButtonLink";
import SearchField from "components/search/SearchField";
import { handleAccountSwitch } from "store/state/user/actions";
import { getAccounts } from "store/state/administration/actions";
import { closeModal } from "store/state/ui/actions";
import { getFacultyDashboard } from "store/state/facultyDashboard/actions";
import Loader from "components/common/loader";
import {
  FormHead,
  FormTitle,
  FormBody,
  FormActions,
  AccountList,
  AccountListItem,
  AccountListHeading,
  FieldContainer,
} from "styles/components/Form";

import ModalFrame from "./ModalFrame";

class ModalAccountListing extends Component {
  state = {
    accountId: "",
    accountName: "",
    filterText: null,
    switchingAccounts: false,
  };

  switchAccount = (id, organization) => {
    this.setState({ accountId: id, accountName: organization });
  };

  switchAccountTo = () => {
    this.setState({ switchingAccounts: true });
    const { accountId, accountName } = this.state;
    this.props.handleAccountSwitch({ accountId, accountName }).then(() => {
      this.props.getFacultyDashboard();
      this.props.history.push("/");
      this.setState({ switchingAccounts: false });
      this.props.closeModal();
    });
  };

  setFilter = (e) => {
    this.setState({ filterText: e.target.value });
  };

  applyFilter = (accounts) => {
    var data = accounts;
    if (this.state.filterText) {
      data = accounts.filter((item) => {
        if (item.organization.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1) {
          return true;
        }
      });
    }
    return data.sort(function(a, b) {
      var nameA = a.organization.toLowerCase(),
        nameB = b.organization.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  };

  componentDidMount() {
    if (!this.props.accounts.length) {
      this.getAllAccounts();
    }
  }

  getAllAccounts() {
    this.props.getAccounts({ selector: "accountList", accountId: null, pageSize: 100000 });
  }

  render() {
    const {
      accounts,
      closeModal,
      apiCallInProgress,
      defaultAccountId,
      currentAccountId,
    } = this.props;
    // const accountsList = this.applyFilter(accounts);
    return (
      <ModalFrame modifier={"modal-account-switching"}>
        <FieldContainer disabled={this.state.switchingAccounts} marginTopValue={30}>
          <FormTitle leftMargin={20}>Select Account</FormTitle>
          <SearchField
            small
            value={this.state.account}
            onChange={this.setFilter}
            placeholder="Search Account Name."
          />
          <AccountListHeading>ACCOUNT NAME</AccountListHeading>
          <Scrollbars style={{ height: 160 }}>
            <FormBody noMargin>
              <AccountList>
                {/* loading text is temporary will replace it with loader once received */}
                {apiCallInProgress ? (
                  <React.Fragment>
                    <Loader height={80} width={250} />
                  </React.Fragment>
                ) : (
                  this.applyFilter(accounts).map(({ organization, id }) => {
                    return [currentAccountId, defaultAccountId].includes(id) ? null : (
                      <AccountListItem
                        active={id == this.state.accountId ? true : false}
                        id={id}
                        key={id}
                        onClick={() => {
                          this.switchAccount(id, organization);
                        }}
                      >
                        {organization}
                      </AccountListItem>
                    );
                  })
                )}
              </AccountList>
            </FormBody>
          </Scrollbars>
          <FormActions marginTop={18}>
            <ButtonLink
              transparent
              onClick={() => closeModal()}
              type="button"
              disabled={apiCallInProgress}
              xs
            >
              Cancel
            </ButtonLink>
            <ButtonLink
              type="submit"
              violet
              xs
              onClick={this.switchAccountTo}
              disabled={!this.state.accountId}
            >
              Select
            </ButtonLink>
          </FormActions>
        </FieldContainer>
      </ModalFrame>
    );
  }
}

export default connect(
  (state) => ({
    currentAccountId: state.user.currentAccountId,
    defaultAccountId: state.user.accountId,
    accounts: state.administration.accountList,
    modal: state.ui.modal,
    apiCallInProgress: state.administration.apiCallInProgress,
  }),
  {
    getAccounts,
    closeModal,
    handleAccountSwitch: ({ accountId, accountName }) =>
      handleAccountSwitch({ accountId, accountName }),
    getFacultyDashboard,
  }
)(withRouter(ModalAccountListing));
