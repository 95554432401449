import React, { useRef, useEffect } from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import styled from "styled-components";
import { useSticky } from "react-table-sticky";

import IconSvgComponent from "components/common/IconSvgComponent";
import { UnstyledButton } from "styles/components/Btns";
import Loader from "components/common/loader";

const Container = styled.div`
  .table {
    &.sticky {
      width: 100%;
      overflow-x: scroll;

      .header,
      .body {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        background-color: #ffffff;
        border: 1px solid #ffffff;
      }

      [data-sticky-last-left-td] {
        border-right: 1px solid #9797971a;
      }

      [data-sticky-first-right-td] {
        border-left: 1px solid #9797971a;
      }
    }
  }
`;

const StyledTable = styled.div``;

const StyledTableHeader = styled.div`
  border-bottom: 1px solid #9797971a;
`;

const StyledTableHeadCell = styled.div`
  width: fit-content;
  flex: 1 1 auto;
  padding: 12px 20px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
  user-select: none;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;

  ${(props) =>
    props.isSorted &&
    !props.isSortedDesc &&
    `
      border-top: 2px solid #96a0b2;
    `}

${(props) =>
  props.isSorted &&
  props.isSortedDesc &&
  `
    border-bottom: 2px solid #96a0b2;
  `}



  ${(props) =>
    props.width &&
    `
    flex: 1 1 ${props.width}px;
    width: ${props.width}px;
  `}

  ${(props) =>
    props.minWidth &&
    `
    flex: 1 1 ${props.minWidth}px;
    minWidth: ${props.minWidth}px;
  `}

  ${(props) =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
  `}
`;

const StyledTableBodyContainer = styled.div`
  /* overflow: auto; */
`;

const StyledTableRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #9797971a;
  transition: background-color 0.5s ease;

  ${(props) =>
    props.isSelected &&
    `
      background-color: #88c7f6;
  `}
`;

const StyledTableCell = styled.div`
  width: fit-content;
  flex: 1 1 auto;
  padding: 12px 24px;
  text-align: left;
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;

  ${(props) =>
    props.width &&
    `
    flex: 1 1 ${props.width}px;
    width: ${props.width}px;
  `}

  ${(props) =>
    props.minWidth &&
    `
    flex: 1 1 ${props.minWidth}px;
    minWidth: ${props.minWidth}px;
  `}

  ${(props) =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
  `}
`;

const StyledTableBody = styled.div`
  width: 100%;
  position: relative;
`;

const CustomPaginationComponent = (props) => {
  const {
    pageRows,
    pageIndex,
    totalCount,
    pageSize,
    canNextPage,
    canPreviousPage,
    // handleViewLessClick,
    // handleViewAllClick,
    // showViewAllButton,
    isLoading,
    previousPage,
    nextPage,
  } = props;

  const pageMin = pageSize * pageIndex + 1;
  const pageMax = pageMin + pageRows.length - 1;

  return (
    <div style={styles.paginationContainer}>
      <div style={styles.paginationContent}>
        <div style={styles.paginationPages}>
          {!isLoading && `${pageMin}-${pageMax} of ${totalCount}`}
        </div>
        {/* {showViewAllButton && (
          <div style={styles.viewAllLessButton}>
            <ButtonLink
              disabled={localCount === pageSize ? false : localCount < pageSize ? true : false}
              style={localCount <= pageSize ? styles.paginationButtonDisabled : {}}
              isUnstyledButton
              blue={true}
              onClick={pageMax === localCount ? handleViewLessClick : handleViewAllClick}
            >
              {pageMax === localCount ? "VIEW LESS" : "VIEW ALL"}
            </ButtonLink>
          </div>
        )} */}
        <div style={styles.paginationButtons}>
          <UnstyledButton
            disabled={!canPreviousPage}
            style={!canPreviousPage ? styles.paginationButtonDisabled : {}}
            onClick={previousPage}
          >
            <IconSvgComponent iconPath="svg/page-left-icn.svg" />
          </UnstyledButton>
          <UnstyledButton
            disabled={!canNextPage}
            style={!canNextPage ? styles.paginationButtonDisabled : {}}
            onClick={nextPage}
          >
            <IconSvgComponent iconPath="svg/page-right-icn.svg" />
          </UnstyledButton>
        </div>
      </div>
    </div>
  );
};

const Table = (props) => {
  const {
    data,
    fetchData,
    columns,
    isLoading = true,
    isFilteringEnabled = false,
    serverSidePagination = false,
    isSortingEnabled = !serverSidePagination,
    count = 100,
    defaultPageSize = 10,
    defaultPageIndex = 0,
    NoDataComponent,
    LoadingComponent = () => {
      return (
        <div style={{ height: "487px" }}>
          <Loader />
        </div>
      );
    },
    isSticky = false,
    // SubComponent,
    // defaultFilterMethod = () => {},
    // defaultSorted = [],
    // getTrProps = () => {},
    // getTdProps = () => {},
    meta = {},
    classNames = "",
    style,
  } = props;

  const parentRef = useRef();
  const tableBodyRef = useRef();

  const instance = useTable(
    {
      columns,
      data,

      manualFilters: serverSidePagination, // true == server side filtering
      disableFilters: !isFilteringEnabled,

      manualSortBy: serverSidePagination, // true == server side sorting
      disableSortBy: !isSortingEnabled,

      manualPagination: serverSidePagination, // true === server side pagination

      initialState: {
        pageSize: defaultPageSize,
        pageIndex: defaultPageIndex,
      },
      pageCount: Math.ceil(count / defaultPageSize),
      meta: {
        ...meta,
        tableBodyRef,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useSticky
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state: { pageSize, pageIndex, filters },
  } = instance;

  useEffect(() => {
    if (serverSidePagination) {
      fetchData({ pageSize, pageIndex });
    }
  }, [fetchData, pageIndex, pageSize, serverSidePagination]);

  useEffect(() => {
    if (!serverSidePagination && fetchData) {
      fetchData();
    }
  }, [fetchData, serverSidePagination]);

  return (
    <Container isSticky={isSticky} style={style} className={classNames}>
      <StyledTable {...getTableProps()} className={isSticky ? "table sticky" : ""}>
        <StyledTableHeader className="header">
          {headerGroups.map((headerGroup, index) => {
            return isFilteringEnabled ? (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                <StyledTableRow>
                  {headerGroup.headers.map((column) => {
                    return (
                      <StyledTableHeadCell
                        className="th"
                        title={column.render("Header")}
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        {...column.getHeaderProps(
                          isSticky
                            ? {
                                width: column.minWidth,
                                maxWidth: column.maxWidth,
                                ...column.getSortByToggleProps(),
                              }
                            : {
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                                ...column.getSortByToggleProps(),
                              }
                        )}
                      >
                        {column.render("Header")}
                      </StyledTableHeadCell>
                    );
                  })}
                </StyledTableRow>
                <StyledTableRow {...headerGroup.getHeaderGroupProps({ key: `${index}-filter` })}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <StyledTableHeadCell
                        {...column.getHeaderProps(
                          isSticky
                            ? {
                                width: column.width,
                                style: column.style,
                              }
                            : {
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                              }
                        )}
                      >
                        {column.canFilter && column.render("Filter")}
                      </StyledTableHeadCell>
                    );
                  })}
                </StyledTableRow>
              </div>
            ) : (
              <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <StyledTableHeadCell
                      className="th"
                      title={column.render("Header")}
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                      {...column.getHeaderProps(
                        isSticky
                          ? {
                              width: column.width,
                              style: column.style,
                              ...column.getSortByToggleProps(),
                            }
                          : {
                              minWidth: column.minWidth,
                              maxWidth: column.maxWidth,
                              ...column.getSortByToggleProps(),
                            }
                      )}
                    >
                      {column.render("Header")}
                    </StyledTableHeadCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </StyledTableHeader>

        <StyledTableBodyContainer innerRef={parentRef}>
          <StyledTableBody
            {...getTableBodyProps()}
            id="tableBody"
            className="body"
            innerRef={tableBodyRef}
            style={{
              minHeight:
                serverSidePagination || isLoading ? `${53 * pageSize}px` : `${53 * page.length}px`,
            }}
          >
            {!isLoading && rows.length === 0 && NoDataComponent ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <NoDataComponent />
              </div>
            ) : null}

            {isLoading && <LoadingComponent />}

            {!isLoading &&
              page.map((row) => {
                prepareRow(row);

                return (
                  <StyledTableRow
                    {...row.getRowProps({ isSelected: row.values.isSelected })}
                    className="tr"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <StyledTableCell
                          className="td"
                          title={cell.value || ""}
                          {...cell.getCellProps(
                            isSticky
                              ? {
                                  width: cell.column.width,
                                  style: cell.column.style,
                                }
                              : {
                                  minWidth: cell.column.minWidth,
                                  maxWidth: cell.column.maxWidth,
                                  style: cell.column.style,
                                }
                          )}
                        >
                          {cell.render("Cell", {
                            tableBodyRef,
                          })}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </StyledTableBody>
        </StyledTableBodyContainer>
      </StyledTable>
      <CustomPaginationComponent
        pageRows={instance.page}
        pageIndex={instance.state.pageIndex}
        pageSize={instance.state.pageSize}
        canPreviousPage={instance.canPreviousPage}
        canNextPage={instance.canNextPage}
        previousPage={instance.previousPage}
        nextPage={instance.nextPage}
        totalCount={filters.length > 0 ? rows.length : count}
        isLoading={isLoading}
      />
    </Container>
  );
};

const styles = {
  paginationContainer: {
    padding: "12px 12px 40px 12px",
  },
  paginationContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  paginationButtons: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100px",
  },
  viewAllLessButton: {
    display: "flex",
    justifyContent: "space-evenly",
    marginLeft: "25px",
  },
  paginationButtonDisabled: {
    opacity: ".5",
  },
};

export default Table;
