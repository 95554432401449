/**
 * The external dependencies.
 */
import { Chart } from "react-chartjs-2";

export default () => {
  Chart.pluginService.register({
    afterUpdate: function(chart) {
      if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
        var a = chart.config.data.datasets.length - 1;
        for (let i in chart.config.data.datasets) {
          var arc = chart.getDatasetMeta(i).data[
            chart.config.options.elements.arc.roundedCornersFor
          ];

          arc.round = {
            x: (chart.chartArea.left + chart.chartArea.right) / 2,
            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
            radius: chart.innerRadius + chart.radiusLength / 2 + a * chart.radiusLength,
            thickness: chart.radiusLength / 2 - 1,
            backgroundColor: arc._model.backgroundColor,
          };
          a--;
        }
      }
    },

    afterDraw: function(chart) {
      if (chart.config.options.elements.arc.roundedCornersFor !== undefined) {
        var ctx = chart.chart.ctx;
        for (let i in chart.config.data.datasets) {
          var arc = chart.getDatasetMeta(i).data[
            chart.config.options.elements.arc.roundedCornersFor
          ];
          var startAngle = Math.PI / 2 - arc._view.startAngle;
          var endAngle = Math.PI / 2 - arc._view.endAngle;

          ctx.save();
          ctx.translate(arc.round.x, arc.round.y);
          ctx.fillStyle = arc.round.backgroundColor;
          ctx.beginPath();
          ctx.arc(
            arc.round.radius * Math.sin(startAngle),
            arc.round.radius * Math.cos(startAngle),
            arc.round.thickness,
            0,
            2 * Math.PI
          );
          ctx.arc(
            arc.round.radius * Math.sin(endAngle),
            arc.round.radius * Math.cos(endAngle),
            arc.round.thickness,
            0,
            2 * Math.PI
          );
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        }
      }
    },
  });
};
