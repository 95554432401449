/**
 * @ External Dependecies
 */
import styled from "styled-components";
import theme from "../theme";

export const SubmitContainer = styled.div`
  padding: 30px 0;
  text-align: center;
`;
export const SubmitIcon = styled.div`
  margin-bottom: 16px;
  text-align: center;
`;
export const SubmitTitle = styled.h3.attrs({
  id: (props) => props.id,
})`
  margin-bottom: 10px;
  text-align: center;
  ${(props) =>
    props.isAssessmentSubmissionGuide &&
    `
        max-width: 529px;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 26px;
	`}
`;
export const SubmitText = styled.div`
  font-size: 15px;
  color: #9b9b9b;
  ${(props) =>
    props.isAssessmentSubmissionGuide &&
    `
        display: inline-block;
        max-width: 450px;
        color: #2049E6;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
	`}
`;

export const SubmitDescription = styled.div`
  font-size: 15px;
  color: #9b9b9b;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SubmitActions = styled.div`
  margin-top: 35px;
  text-align: center;

  .btn-back {
    margin: 0 14px;
    font-family: ${theme.font};
    font-size: 16px;
    font-weight: 700;
  }

  .btn-submit {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 9px 20px;
    border: 2px solid transparent !important;
    border-radius: 22px;
    margin: 0 14px;
    background: ${theme.color.red};
    font-family: ${theme.font};
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: ${theme.color.white};
    text-decoration: none;
    transition: background 0.4s;

    .svg-icon {
      margin-left: 15px;
    }

    &:focus {
      border: 2px solid #2049e6 !important;
    }
  }
`;
