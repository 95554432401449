/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

export const ImageManager = styled.div`
  text-align: center;

  h3 {
    margin: 0;
  }

  .form-title-alt {
    margin-bottom: 20px;
  }

  .form-actions-with-offset {
    margin-top: 40px;
  }

  .form-upload {
    width: 100%;
    position: static;
    padding: 0 15px;
  }

  .form-image-library {
    width: 100%;
    padding: 0 15px;
    display: flex;

    ${media.mobile`
      padding: 0 5px;
    `}
  }

  .react-select__option {
    padding: 5px 10px !important;
    height: auto;
  }

  .upload-holder {
    position: static;

    input {
      appearance: none;
      border: none;
      background: transparent;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
    }

    .uploader__btn {
      border-radius: 22px;
      background: ${theme.color.red};
      color: ${theme.color.white};
      display: inline-flex;
      padding: 10px 25px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.075em;

      &:before {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        bottom: 10px;
        right: 10px;
        border: 2px dashed rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &.has-file:before {
        opacity: 1;
      }
    }
  }
`;

export const ImageManagerTabs = styled.div`
  position: relative;
`;

export const ImageManagerTabsHead = styled.div`
  position: relative;
  margin-bottom: 23px;
`;

export const ImageManagerTabsNav = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageManagerTabsNavButton = styled.button`
  border: none;
  border-radius: 0;
  background: transparent;
  font-size: 18px;
  line-height: 1.17;
  font-weight: 400;
  margin: 0 18px;
  position: relative
  padding: 0 0 3px;
  display: block;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 2px;
    background: transparent;
    transition: background .3s;
  }

  &:hover:before {
    background: ${theme.color.blue}
  }

  ${(props) =>
    props.active &&
    `
    &:before {
      background: ${theme.color.blue}
    }
  `}
`;

export const ImageManagerTabsBody = styled.div`
  position: relative;
`;

export const ImageManagerTab = styled.div`
  position: relative;
  height: 428px;
  overflow-y: auto;
  display: flex;
  align-items: center;
`;

export const ImageManagerTabContent = styled.div`
  width: 100%;
  display: flex;

  ${(props) =>
    props.alignStretch &&
    `
    align-self: stretch;
  `}
`;

export const ImageLibrary = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;

  .btn-apply {
    position: absolute;
    right: 30px;
    bottom: 30px;
    padding: 7px 23px;
  }
`;

export const ImageLibraryAside = styled.div`
  width: 200px;
  padding: 0 5px;

  ${media.tablet_portrait`
    width: 170px;
  `}

  ${media.mobile`
    width: 140px;
  `}
`;

export const ImageLibraryContent = styled.div`
  width: calc(100% - 200px);
  padding-left: 20px;
  display: flex;
  flex-direction: column;

  ${media.tablet_portrait`
    width: calc(100% - 170px);
    padding-left: 10px;
  `}

  ${media.mobile`
    width: calc(100% - 140px);
    padding-left: 5px;
  `}
`;

export const ImageLibrarySearch = styled.div`
  width: 100%;
  position: relative;

  .svg-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);

    ${media.mobile`
      left: 5px;
    `}
  }
`;

export const ImageLibrarySearchField = styled.input`
  width: 100%;
  appearance: none;
  border: 1px solid rgba(138, 144, 156, 0.3);
  border-radius: 5px;
  font-size: 14px;
  color: ${theme.color.dark_gray};
  height: 40px;
  padding: 5px 10px 5px 40px;

  &:focus {
    border-color: rgba(138, 144, 156, 1);
  }

  &::placeholder {
    color: #9b9b9b;
  }

  ${media.mobile`
    padding: 5px 10px 5px 40px;
  `}
`;

export const ImageLibraryFolderItem = styled.div`
  padding: 0 7px;
  height: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 4px;

  span {
    pointer-events: none;
  }

  .icon-plus {
    width: 15px;
    height: 15px;

    g {
      stroke: ${theme.color.red};
    }
  }

  .svg-icon {
    width: 24px;
    height: auto;
    display: flex;
    justify-content: center;
    margin-right: 8px;

    ${media.mobile`
      margin-right: 3px;
    `}
  }

  &.is-drag-over {
    box-shadow: 0 0 3px ${theme.color.blue};
    background: #e3ebfa;
  }

  ${(props) =>
    props.active &&
    `
    background: #E3EBFA
  `}

  ${media.mobile`
    font-size: 12px;
  `}
`;

export const ImageLibraryFiles = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;

  .files-masonry {
    width: 100%;
  }

  ${(props) =>
    props.active &&
    `
    box-shadow: 0 0 0 6px ${theme.color.light_blue}
  `}
`;

export const ImageLibraryFile = styled.div`
  border-radius: 8px;
  width: calc(33.33% - 30px);
  margin: 15px;
  text-align: left;
  padding: 3px;
  cursor: pointer;
  position: relative;

  img {
    margin-bottom: 10px;
    pointer-events: none;
  }

  span {
    display: block;
    padding: 0 5px;
  }

  ${(props) =>
    props.selected &&
    `
    box-shadow: 0 0 0 6px #1FC9FF
  `}

  ${(props) =>
    props.menuActive &&
    `
    z-index: 2;
  `}

  ${media.tablet_portrait`
    width: calc(33.33% - 20px);
    margin: 10px;
  `}

  ${media.mobile`
    width: calc(50% - 10px);
    margin: 10px 5px;
    font-size: 12px;
  `}

  ${media.mobile_portrait`
    width: calc(100% - 10px);
  `}
`;

export const ImageLibraryFileMenuHolder = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  max-width: 90%;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5), 0 41px 51px -6px rgba(0, 0, 0, 0.27);
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  &:before {
    content: "";
    position: absolute;
    right: 10px;
    bottom: 100%;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg) translate(4px, 11px);
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.5);
  }

  ${(props) =>
    props.active &&
    `
    opacity: 1;
    visibility: visible;

    .menu-trigger {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  `}
`;

export const ImageLibraryFileMenu = styled.div`
  background: #ffffff;
  font-size: 14px;
  font-weight: 700;
  color: #9b9b9b;
  letter-spacing: 0.3px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  button {
    width: 100%;
    text-align: left;
    padding: 13px 20px;
    border-radius: 0;
    border: none !important;
    position: relative;
    z-index: 1;

    &:hover {
      color: ${theme.color.blue};
    }
  }

  button + button {
    border-top: 1px solid rgba(155, 155, 155, 0.15) !important;
  }
`;

export const ImageLibraryFileInput = styled.input`
  appearance: none;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 21px;
  background: #e3ebfa;
  padding: 0 5px;
`;

export default ImageManager;
