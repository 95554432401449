import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
  Legend,
} from "recharts";
import styled from "styled-components";
import PropTypes from "prop-types";
import { barChartDesktopLargeBreakpoint } from "constants/deviceDisplay";
let colormap = require("colormap");

export default class BarCharts extends PureComponent {
  state = {
    activeIndex: null,
  };

  handleClick = (data, index) => {
    const {
      state: { activeIndex },
      props: { onBarClick },
    } = this;

    if (activeIndex === null) {
      this.setState({ activeIndex: index }, () => {
        onBarClick && onBarClick({ data, shouldReset: false });
      });
    } else {
      if (activeIndex === index) {
        this.setState({ activeIndex: null }, () => {
          onBarClick && onBarClick({ data, shouldReset: true });
        });
      } else {
        this.setState({ activeIndex: index }, () => {
          onBarClick && onBarClick({ data, shouldReset: false });
        });
      }
    }
  };

  xAxesRenderer = () => {
    const {
      props: {
        xAxis: {
          axisLine,
          tickLine,
          ticks,
          labelText,
          position = "insideBottom",
          offset = -5,
          allowDecimals,
        },
        isHorizontalChart,
        isHorizontalCategoryChart,
        dataKeys: { xAxisKey, yAxisKeys },
        dataSet,
      },
    } = this;

    if (isHorizontalCategoryChart) {
      const maxValue = dataSet.reduce((max, datapoint) => {
        const datapointMax = Math.max(...yAxisKeys.map((key) => datapoint[key]));
        return datapointMax < max ? max : datapointMax;
      }, 0);
      const domainMax = Math.max(Math.round(maxValue * 1.05), maxValue + 1);

      return (
        <XAxis
          scale="linear"
          type="number"
          axisLine={axisLine}
          tickLine={false}
          dataKey={yAxisKeys}
          allowDecimals={allowDecimals}
          tickCount={maxValue < 100 ? 6 : maxValue < 1000 ? 11 : 16}
          domain={[0, domainMax]}
        >
          <Label value={labelText} offset={offset} position={position} />
        </XAxis>
      );
    }
    if (isHorizontalChart) {
      return (
        <XAxis
          type="number"
          axisLine={axisLine}
          tickLine={tickLine}
          ticks={ticks}
          allowDecimals={allowDecimals}
          domain={[0, "dataMax"]}
        >
          <Label value={labelText} offset={offset} position={position} />
        </XAxis>
      );
    } else {
      return (
        <XAxis
          type="category"
          dataKey={xAxisKey}
          ticks={ticks}
          allowDecimals={allowDecimals}
          domain={[0, "dataMax"]}
        >
          <Label value={labelText} offset={offset} position={position} />
        </XAxis>
      );
    }
  };

  yAxesRenderer = () => {
    const {
      props: {
        yAxis: {
          axisLine,
          tickLine,
          ticks,
          labelText,
          position = "center",
          offset = -5,
          allowDecimals,
        },
        isHorizontalChart,
        isHorizontalCategoryChart,
        dataKeys: { xAxisKey, yAxisKeys },
      },
    } = this;

    if (isHorizontalCategoryChart) {
      return (
        <YAxis
          type="category"
          dataKey={xAxisKey}
          ticks={ticks}
          tickLine={false}
          interval="preserveStartEnd"
          allowDecimals={allowDecimals}
          domain={[0, "dataMax"]}
        >
          <Label value={labelText} angle={-90} offset={offset} position={position} />
        </YAxis>
      );
    }
    if (isHorizontalChart) {
      return (
        <YAxis
          type="category"
          dataKey={xAxisKey}
          ticks={ticks}
          interval="preserveStartEnd"
          allowDecimals={allowDecimals}
          domain={[0, "dataMax"]}
        >
          <Label value={labelText} angle={-90} offset={offset} position={position} />
        </YAxis>
      );
    } else {
      return (
        <YAxis
          type="number"
          axisLine={axisLine}
          tickLine={tickLine}
          ticks={ticks}
          allowDecimals={allowDecimals}
          domain={[0, "dataMax"]}
        >
          <Label
            value={labelText}
            angle={-90}
            offset={offset}
            position={position}
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
      );
    }
  };

  renderLegend = (props) => {
    const totalStudents = this.props.dataSet.reduce(
      (total, datapoint) => total + datapoint.studentCount,
      0
    );

    return (
      <LegendContainer>
        {this.props.dataSet
          .slice()
          .sort((a, b) => (a.attempts >= b.attempts ? 1 : -1))
          .map((datapoint, index) => {
            return (
              <LegendItemContainer>
                <LegendValue>{datapoint[this.props.dataKeys.yAxisKeys[0]]}</LegendValue>
                <LegendPercentage>{`${Math.round(
                  100 * (datapoint.studentCount / totalStudents)
                )}%`}</LegendPercentage>
                <LegendKeyItem>
                  <LegendCircle style={{ backgroundColor: props.colors[index] }} />
                  <LegendCircleLabel>{`${datapoint.attempts} Attempts`}</LegendCircleLabel>
                </LegendKeyItem>
              </LegendItemContainer>
            );
          })}
      </LegendContainer>
    );
  };

  render() {
    const {
      props: {
        config: {
          options: {
            dimension: { width, height },
            margin: { top, right, left, bottom },
          },
        },
        grid,
        barSize,
        maxBarSize,
        barRadius,
        isGroupBarChart,
        groupBarColors,
        isHorizontalChart,
        isHorizontalCategoryChart,
        dataSet,
        dataKeys: { xAxisKey, yAxisKeys },
        CustomTooltip,
        CustomTooltipCursor,
      },
      state: { activeIndex },
    } = this;

    const colors = colormap({
      colormap: isHorizontalCategoryChart
        ? [
            { index: 0, rgb: [36, 0, 137] },
            { index: 0.33, rgb: [131, 0, 170] },
            { index: 0.66, rgb: [237, 101, 65] },
            { index: 1, rgb: [232, 255, 0] },
          ]
        : "plasma",
      nshades: isHorizontalCategoryChart
        ? 5
        : dataSet && dataSet.length > 9
        ? dataSet.length + 1
        : 101,
      format: "hex",
      alpha: 1,
    });

    return (
      <ResponsiveContainer width={width} height={height} debounce={10}>
        <BarChart
          data={dataSet}
          margin={{
            top,
            right,
            left,
            bottom,
          }}
          layout={isHorizontalChart ? "vertical" : "horizontal"}
        >
          {grid && <CartesianGrid strokeDasharray="3 3" />}
          {this.xAxesRenderer()}
          {this.yAxesRenderer()}
          {CustomTooltip ? (
            <Tooltip cursor={CustomTooltipCursor || true} content={CustomTooltip} colors={colors} />
          ) : (
            <Tooltip />
          )}
          {isGroupBarChart ? (
            yAxisKeys.map((dataKey, index) => (
              <Bar
                key={`bar${index}`}
                dataKey={dataKey}
                fill={groupBarColors[index]}
                radius={barRadius}
                barSize={barSize}
              />
            ))
          ) : (
            <Bar
              dataKey={yAxisKeys[0]}
              maxBarSize={maxBarSize}
              radius={barRadius}
              barSize={barSize}
              onClick={this.handleClick}
            >
              {dataSet.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={index === activeIndex ? "#1FC9FF" : colors[index]}
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          )}
          {isHorizontalCategoryChart ? (
            <Legend
              colors={colors}
              wrapperStyle={{
                width: "auto",
              }}
              content={this.renderLegend}
            />
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

const LegendContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 25px;

  @media (min-width: ${barChartDesktopLargeBreakpoint}px) {
    margin-top: 15px;
  }
`;

const LegendItemContainer = styled.div`
  display: inline-block;
  text-align: center;
`;

const LegendKeyItem = styled.div`
  padding: 0px 10px;
`;

const LegendCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: relative;
  display: inline-block;
`;

const LegendCircleLabel = styled.span`
  font-family: "Circular Pro", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  margin-left: 5px;

  @media (min-width: ${barChartDesktopLargeBreakpoint}px) {
    font-size: 16px;
  }
`;

const LegendValue = styled.span`
  font-family: "Circular Pro", "Helvetica", "Arial", sans-serif;
  font-size: 30px;
  font-weight: 700;

  @media (min-width: ${barChartDesktopLargeBreakpoint}px) {
    font-size: 48px;
  }
`;

const LegendPercentage = styled.span`
  font-family: "Circular Pro", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 3px;
  color: rgb(149, 159, 179);

  @media (min-width: ${barChartDesktopLargeBreakpoint}px) {
    margin-left: 8px;
    font-size: 28px;
  }
`;

const LegendLineBreak = styled.br`
  display: none;
  @media (max-width: ${barChartDesktopLargeBreakpoint}px) {
    display: inline;
  }
`;

BarCharts.propTypes = {
  config: PropTypes.shape({
    options: PropTypes.shape({
      dimension: PropTypes.exact({
        width: PropTypes.number,
        height: PropTypes.number,
      }).isRequired,
      margin: PropTypes.exact({
        top: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  xAxis: PropTypes.shape({
    axisLine: PropTypes.bool,
    tickLine: PropTypes.bool,
    ticks: PropTypes.arrayOf(PropTypes.number),
    label: PropTypes.string,
    allowDecimals: PropTypes.bool,
  }),
  yAxis: PropTypes.shape({
    axisLine: PropTypes.bool,
    tickLine: PropTypes.bool,
    ticks: PropTypes.arrayOf(PropTypes.number),
    label: PropTypes.string,
    allowDecimals: PropTypes.bool,
  }),
  grid: PropTypes.bool,
  barSize: PropTypes.number,
  maxBarSize: PropTypes.number,
  barRadius: PropTypes.number,
  isGroupBarChart: PropTypes.bool,
  groupBarColors: PropTypes.arrayOf(PropTypes.string),
  isHorizontalChart: PropTypes.bool,
  dataSet: PropTypes.arrayOf(PropTypes.object),
  dataKeys: PropTypes.exact({
    xAxisKey: PropTypes.string,
    yAxisKeys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onBarClick: PropTypes.func,
  customeToolTip: PropTypes.element,
};

BarCharts.defaultProps = {
  xAxis: {
    axisLine: true,
    tickLine: true,
    ticks: [],
    label: "",
    allowDecimals: false,
  },
  yAxis: {
    axisLine: true,
    tickLine: true,
    ticks: [],
    label: "",
    allowDecimals: false,
  },
  grid: false,
  barSize: 6,
  maxBarSize: 90,
  barRadius: 0,
  isGroupBarChart: false,
  groupBarColors: colormap({
    colormap: "plasma",
    nshades: 10,
    format: "hex",
    alpha: 1,
  }),
  isHorizontalChart: false,
};
