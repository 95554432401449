import React, { useState, useEffect } from "react";

import useAPIData from "components/useAPIData";
import { Card, styles } from "./styles";
import { LoadingSpinner } from "components/card/withSpinnerHOC";
import { useSelector, useDispatch } from "react-redux";
import { BreakdownTable } from "components/card/BreakdownTable";
import { getCourseAssessmentStudentBreakdownData } from "store/state/courses/actions";

export const StudentBreakdownCard = ({
  getDataCallback = () => {},
  selector = () => {},
  columns = [],
  mobileColumns = () => {},
  cardStyles = {},
  tableClasses = "",
}) => {
  const tableParams = useSelector((state) => state.storedCourses.studentBreakDownTable);

  const [studentBreakdownDataLoaded, studentBreakdownData] = useAPIData({
    fetchDataCallback: getDataCallback,
    dependencies: [getDataCallback],
  });

  return (
    <Card style={{ height: "765px", width: "100%", ...cardStyles }}>
      <div style={styles.textContainer}>STUDENT BREAKDOWN</div>
      <LoadingSpinner isLoading={!studentBreakdownDataLoaded}>
        <BreakdownTable
          data={studentBreakdownData || []}
          tableParams={tableParams}
          columns={columns}
          mobileColumns={(student) => mobileColumns(student)}
          tableClasses={tableClasses}
          containerStyles={{ height: "90%" }}
        />
      </LoadingSpinner>
    </Card>
  );
};
