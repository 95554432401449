import React, { Component } from "react";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import IconSvgComponent from "components/common/IconSvgComponent";

import BuilderAnswerBlock from "./BuilderAnswerBlock";

import { BuilderAnswers, BuilderAnswerHandle } from "styles/components/BuilderAnswer";

/**
 * @ function to help us with reordering the result
 */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const letters = ["A", "B", "C", "D", "E", "F", "G"];

const DragHandle = sortableHandle(() => (
  <BuilderAnswerHandle>
    <IconSvgComponent iconPath="svg/ico-dots-drag.svg" />{" "}
  </BuilderAnswerHandle>
));

const SortableItem = SortableElement(({ item, idx, onTagsClick }) => {
  return (
    <BuilderAnswerBlock
      isAnswerRight={item.answerStatus}
      letter={letters[idx]}
      {...item}
      onTagsClick={() => onTagsClick(item, idx)}
    >
      <DragHandle />
    </BuilderAnswerBlock>
  );
});

const SortableList = SortableContainer(({ items, onTagsClick }) => {
  return (
    <BuilderAnswers>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item.id}`}
          index={index}
          idx={index}
          item={item}
          onTagsClick={onTagsClick}
        />
      ))}
    </BuilderAnswers>
  );
});

class QuestionIndicators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.setState({
      items: this.props.answers,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.answers !== prevProps.answers) {
      this.setState({
        items: this.props.answers,
      });
    }
  }

  onTagsClick = (data, index) => {
    this.props.onTagsClick(data, index);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const reorderedAnswers = reorder(this.state.items, oldIndex, newIndex);

    this.setState({
      items: reorderedAnswers,
    });

    this.props.onReorder(reorderedAnswers);
  };

  render() {
    return (
      <SortableList
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        axis={"y"}
        useDragHandle
        helperClass="is-draggable"
        onTagsClick={(data, index) => this.onTagsClick(data, index)}
      />
    );
  }
}

export default QuestionIndicators;
