/**
 * @ External Dependencies
 */
import React, { Component } from "react";

/**
 * The internal dependencies.
 */
import SearchContainer, { SearchField, SearchButton } from "styles/components/Search";
import IconSvgComponent from "components/common/IconSvgComponent";

/**
 * Class for search component.
 *
 * @class      SearchComponent (name)
 */
class SearchComponent extends Component {
  render() {
    return (
      <SearchContainer>
        <SearchField placeholder="Search for Programs, Learning Modules and Questions (e.g. orders of operations)" />
        <SearchButton>
          <IconSvgComponent iconPath="svg/ico-magnifier.svg" />
        </SearchButton>
      </SearchContainer>
    );
  }
}

export default SearchComponent;
