import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Courses, {
  Course,
  CourseTitle,
  CourseInner,
  CourseBody,
  CourseFoot,
  CourseEntry,
  CourseFootFlex,
  CourseFootFlexItem,
  CourseFootAnalyticsLink,
} from "styles/components/Courses";
import TableDropdown from "components/elements/TableDropdown";
import IconSvgComponent from "components/common/IconSvgComponent";
import stemifyRoutes from "../../constants/routes";
import { ROLES } from "constants/roles";
import { ASSIGNMENT_TYPES } from "constants/common";

const AssignmentGradesCard = ({ assignments }) => {
  const gradesByAssignment = useSelector((state) => state.storedCourses.gradesByAssignment);
  const user = useSelector((state) => state.user);
  const { courseId } = useParams();

  return (
    <Courses>
      {assignments.items.map((assignment) => {
        const assignmentGrades = gradesByAssignment[assignment.id];

        return (
          <Course key={assignment.id}>
            {/* <TableDropdown
            options={{
              items: [
                {
                  btnText: "Edit",
                },
                {
                  btnText: "Copy",
                },
                {
                  btnText: "Archive",
                },
              ],
            }}
          /> */}

            <CourseInner>
              <CourseBody background={assignment.background}>
                <CourseTitle>{assignment.name}</CourseTitle>

                <CourseEntry>{assignment.title}</CourseEntry>
                <CourseEntry>
                  {assignment.assignedUsers.length > 0
                    ? assignment.assignedUsers.length
                    : assignment.enrolledStudentInSection}{" "}
                  Students
                </CourseEntry>
              </CourseBody>

              <CourseFoot>
                <CourseFootFlex>
                  {![
                    ASSIGNMENT_TYPES.VIDEO,
                    ASSIGNMENT_TYPES.OPENSTAX,
                    ASSIGNMENT_TYPES.PRACTICE,
                  ].includes(assignment.assignmentType) && (
                    <CourseFootFlexItem>
                      <p>Class Average</p>
                      <h4>
                        {assignmentGrades
                          ? assignmentGrades.class_avg
                            ? assignmentGrades.class_avg
                            : 0
                          : 0}
                      </h4>
                    </CourseFootFlexItem>
                  )}
                  <CourseFootFlexItem>
                    <p>Complete</p>
                    <h4>
                      {assignmentGrades ? assignmentGrades.completed_count : 0}/
                      {assignment.assignedUsers.length > 0
                        ? assignment.assignedUsers.length
                        : assignment.enrolledStudentInSection}
                    </h4>
                  </CourseFootFlexItem>
                </CourseFootFlex>
                {/* Will need in future for redirecting to analytics dashboard.So just commenting the code */}
                {/* <CourseFootAnalyticsLink to={``}>
                    <IconSvgComponent iconPath="svg/analytics.svg" />
                  </CourseFootAnalyticsLink> */}
              </CourseFoot>
            </CourseInner>
          </Course>
        );
      })}
    </Courses>
  );
};

export default AssignmentGradesCard;
