import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import DrawerLayout from "components/layouts/DrawerLayout";
import {
  getLearningUnitsOfProgram,
  getLearningModulesByFilter,
} from "store/state/learningLibrary/actions";
import { postLoginFetchcData } from "store/state/user/actions";
import { NoDataMessageContainer, NoDataMessage } from "styles/components/Card";
import MediaQuery from "react-responsive";
import queryString from "query-string";

import LearningUnit from "./LearningUnit";
import Card, {
  CardHead,
  CardTitle,
  CardBody,
  CardOrderedList,
  CardOrderedListItem,
} from "styles/components/Card";
import ButtonLink from "components/common/ButtonLink";
import { FormattedText } from "../common/FormattedText";
import Loader from "components/common/loader";

class LearningLibrary extends Component {
  componentDidMount() {
    const { getLearningUnitsOfProgram, getLearningModulesByFilter, location } = this.props;
    const searchParams = queryString.parse(location.search);

    if (searchParams?.type) {
      // fetch learning modules by topic/tag and render
      getLearningModulesByFilter({ ...searchParams });
    } else {
      getLearningUnitsOfProgram({ programName: "Foundational Math" });
    }
  }

  render() {
    const { apiCallInProgress, learningLibrary, isFilteredByTopic } = this.props;

    if (isFilteredByTopic) {
      return apiCallInProgress !== false ? <Loader /> : <LearningUnit isFilteredByTopic />;
    }

    const learningUnits = learningLibrary?.program?.learning_units
      ? learningLibrary.program.learning_units.map((learning_unit) => ({
          ...learning_unit,
          height: 0,
        }))
      : [];

    let drawerData = JSON.parse(JSON.stringify(learningUnits));
    drawerData = drawerData.map((item) => {
      item["learning_modules"] = item["learning_modules"].map((lm) => {
        lm["url"] = "learning-library/" + lm["url"];
        return lm;
      });
      return item;
    });
    return (
      <DocumentTitle title="Stemify | Learning Library">
        {!apiCallInProgress && learningUnits && learningUnits.length > 0 ? (
          <Fragment>
            <MediaQuery maxWidth={768}>
              <DrawerLayout data={drawerData} childArrayName="learning_modules" isOpen={false}>
                {learningUnits.map(({ id, url, name, learning_modules }) => (
                  <Card key={url} id={id}>
                    <CardHead>
                      <CardTitle>
                        <FormattedText text={name}></FormattedText>
                      </CardTitle>
                      <ButtonLink isCardLink to={url}>
                        Open Unit
                      </ButtonLink>
                    </CardHead>

                    <CardBody>
                      <CardOrderedList>
                        {learning_modules.map(({ url, name }) => (
                          <CardOrderedListItem key={"learning-library/" + url}>
                            <ButtonLink
                              isCardOrderedListLink
                              smallergap="smallergap"
                              to={"learning-library/" + url}
                            >
                              <FormattedText text={name}></FormattedText>
                            </ButtonLink>
                          </CardOrderedListItem>
                        ))}
                      </CardOrderedList>
                    </CardBody>
                  </Card>
                ))}
              </DrawerLayout>
            </MediaQuery>
            <MediaQuery minWidth={769}>
              <DrawerLayout data={drawerData} childArrayName="learning_modules" isOpen={true}>
                {learningUnits.map(({ id, url, name, learning_modules }) => (
                  <Card key={url} id={id}>
                    <CardHead>
                      <CardTitle>
                        <FormattedText text={name}></FormattedText>
                      </CardTitle>
                      <ButtonLink isCardLink to={url}>
                        Open Unit
                      </ButtonLink>
                    </CardHead>

                    <CardBody>
                      <CardOrderedList>
                        {learning_modules.map(({ url, name }) => (
                          <CardOrderedListItem key={"learning-library/" + url}>
                            <ButtonLink
                              isCardOrderedListLink
                              smallergap="smallergap"
                              to={"learning-library/" + url}
                            >
                              <FormattedText text={name}></FormattedText>
                            </ButtonLink>
                          </CardOrderedListItem>
                        ))}
                      </CardOrderedList>
                    </CardBody>
                  </Card>
                ))}
              </DrawerLayout>
            </MediaQuery>
          </Fragment>
        ) : (
          <NoDataMessageContainer>
            {apiCallInProgress !== false && <Loader />}
            {apiCallInProgress == false && (
              <NoDataMessage id={"noDataMsgWrapper"}>
                No Learning Library content <br /> available at this time.
              </NoDataMessage>
            )}
          </NoDataMessageContainer>
        )}
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => ({
    isFilteredByTopic: state.learningLibrary.isFilteredByTopic,
    learningLibrary: state.learningLibrary,
    apiCallInProgress: state.learningLibrary.apiCallInProgress.learningLibrary,
  }),
  {
    getLearningUnitsOfProgram,
    postLoginFetchcData,
    getLearningModulesByFilter,
  }
)(LearningLibrary);
