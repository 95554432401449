/**
 * @ External Dependecies
 */
import React from "react";
import { connect } from "react-redux";

/**
 * @ Internal Dependecies
 */
import { BarContainer, BarTitle, Bar, BarInner } from "../../styles/components/ProgressBar";

function ProgressBar({ title, progress, isFromCourse }) {
  return (
    <BarContainer>
      <BarTitle>{title}</BarTitle>

      <Bar>
        <BarInner progress={progress} />
      </Bar>
    </BarContainer>
  );
}

export default connect(
  (state, ownProps) => ({
    progress: state.assessment.progress,
  }),
  {}
)(ProgressBar);
