/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 509px;
  margin-left: 32px;
  flex-grow: 1;
  border: 1px solid #bfc3cb;
  visibility: hidden;
  ${media.tablet_portrait`
		margin-left: 0;
		margin-right: 15px;
	`} ${media.mobile`
		max-width: 100%
		margin: 0;
		padding: 0 10px;

		input {
			padding: 0 40px 0 10px;
			max-width: 100%;
		}
	`};
`;

export const SearchButton = styled.button`
  background: none;
  position: absolute;
  right: 15px;
  top: 50%;
  border: none;
  font-size: 0;
  transform: translateY(-50%);

  .svg-icon g {
    stroke: #808898;
  }
`;

export const SearchField = styled.input`
  display: block;
  width: 100%;
  max-width: 509px;
  height: 40px;
  padding: 0 47px 0 16px;
  background: none;
  border-radius: 2px;
  border: none;
  border: 1px solid #808898;
  font-size: 12px;
  font-weight: 400;
  opacity: 1;
  color: #808898;

  &::placeholder {
    color: #bfc3cb;
  }
`;

export default SearchContainer;
