import React from "react";

import TableDropdown from "components/elements/TableDropdown";
import DonutChart from "components/chart/DonutChart";
import Loader from "components/common/loader";
import useAPIData from "components/useAPIData";
import { FrameBox, FrameCell, CourseFrameTitle } from "styles/components/Frame";

const CompletionCard = ({ title, getDataHandler, dataMap, height = "373px" }) => {
  const [dataLoaded, data] = useAPIData({
    fetchDataCallback: getDataHandler,
    dependencies: [getDataHandler],
  });
  const chartData = data ? dataMap(data) : dataMap();

  const options = {
    charts: [
      {
        colors: ["#0D0887", "#CC4678", "#F0F921"],
        innerRadius: "60%",
        outerRadius: "80%",
      },
    ],
    highlightRadius: 2.5,
    legend: {
      // eslint-disable-next-line no-template-curly-in-string
      singularFormat: "`${item.name} : ${item.value}`",
      // eslint-disable-next-line no-template-curly-in-string
      pluralFormat: "`${item.name} : ${item.value}`",
      columnCount: 1,
    },
  };

  return (
    <FrameBox
      style={{
        height,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <TableDropdown
        options={{
          items: [
            {
              callback: () => {},
              btnText: "REPLACE ME",
            },
          ],
        }}
      /> */}

      <CourseFrameTitle style={{ alignSelf: "flex-start" }}>{title}</CourseFrameTitle>

      {!dataLoaded ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
            backgroundColor: "rgba(0, 0, 0, .2)",
            borderRadius: "8px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : null}

      <FrameCell
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <DonutChart options={options} data={chartData} />
      </FrameCell>
    </FrameBox>
  );
};

export default CompletionCard;
