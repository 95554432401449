/**
 * @ External Dependecies
 */
import styled from "styled-components";
import theme from "../theme";

export const Question = styled.button`
  display: inline-block;
  vertical-align: middle;
  width: 33px;
  border: 0;
  border-radius: 0;
  background: transparent;
  text-align: center;
  appearance: none;
  width: 50px;
  height: 80px;
  padding: 0 8px;
  padding-top: 10px;
  padding-bottom: 9px;

  ${(props) =>
    props.current &&
    `
		background-color: #E3EBFA;
	`}
`;
export const QuestionIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  margin-bottom: 9px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
export const QuestionIconMeta = styled.div`
  position: absolute;
  right: -1px;
  bottom: -3px;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(74, 74, 74, 0.8);
  font-size: 11px;
  font-weight: 400;
  color: ${theme.color.white};
`;
export const QuestionText = styled.div`
  font-size: 15px;
  font-weight: 400;
`;
export const Circle = styled.div`
  width: 33px;
  height: 33px;
  border: 1px solid ${theme.color.dark_gray};
  border-radius: 50%;

  ${(props) =>
    props.dashed &&
    `
		border-style: dashed;
	`}
`;
