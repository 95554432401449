/**
 * The external dependencies.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * The internal dependencies.
 */
import ProgramType from "./ProgramType";

import ProgramAssessment from "./ProgramAssessment";
import ProgramAssessmentFromTemplate from "./ProgramAssessmentFromTemplate";

import ProgramContent from "./ProgramContent";
import ProgramContentFromTemplate from "./ProgramContentFromTemplate";

/**
 * Class for wizard programs.
 *
 * @class      WizardPrograms (name)
 */
class WizardPrograms extends Component {
  /**
   * The properties that can be passed to the component.
   *
   * @type {Object}
   */
  static propTypes = {
    step: PropTypes.string.isRequired,
    changeStep: PropTypes.func.isRequired,
    programType: PropTypes.string.isRequired,
  };

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const { changeStep, programType } = this.props;
    const WizardStepComponent = this.lookupWizardStepComponent();

    return <WizardStepComponent programType={programType} changeStep={changeStep} />;
  }

  /**
   * Lookup for wizard step component.
   */
  lookupWizardStepComponent() {
    const { step } = this.props;
    const { steps } = WizardPrograms;

    return steps[step];
  }
}

/**
 * The supported wizard steps.
 *
 * @type {Object}
 */
WizardPrograms.steps = {
  ProgramType,
  ProgramAssessment,
  ProgramAssessmentFromTemplate,
  ProgramContent,
  ProgramContentFromTemplate,
};

export default WizardPrograms;
