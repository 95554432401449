import React, { Component, Fragment } from "react";
import classNames from "classnames";

import ButtonLink from "components/common/ButtonLink";
import FullscreenModalItem from "components/courses/FullscreenModal";
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  AssignmentTypes,
  AssignmentTypesContent,
  Assignment,
  AssignmentTypesTitle,
  AssignmentTypesWrapper,
} from "styles/components/ModalCourseAssignmentUnit";
import { ASSIGNMENT_TYPES } from "constants/common";

export default class ModalCourseAssignmentUnit extends Component {
  state = {
    activeItem: null,
    items: [
      {
        type: ASSIGNMENT_TYPES.VIDEO,
        text: "Video",
        content: "Assign your students to watch a video lesson from the Stemify learning library.",
        icon: {
          src: "svg/ico-type1.svg",
          width: "83px",
        },
      },
      // {
      //   type: "link",
      //   text: "Link",
      //   content: "Assign your students a link to visit an external webpage.",
      //   icon: {
      //     src: "svg/ico-type-link.svg",
      //     width: "83px",
      //   },
      // },
      {
        type: ASSIGNMENT_TYPES.PRACTICE,
        text: "Practice",
        content:
          "Assign completion-based homework with no countdown timer for students to practice with low stakes. A student can submit each question individually to see if they have answered correctly or incorrectly.",
        icon: {
          src: "svg/ico-type2.svg",
          width: "65px",
        },
      },
      // {
      //   type: "homework",
      //   text: "Homework",
      //   content:
      //     "Assign graded homework with no countdown timer. A student can submit each question individually to see if they have answered correctly or incorrectly.",
      //   icon: {
      //     src: "svg/ico-type3.svg",
      //     width: "65px",
      //   },
      // },
      // {
      //   type: "quiz",
      //   text: "Quiz",
      //   content:
      //     "Assign a timed and graded quiz. A student will submit all questions at once and then receive their grade. Use this category for assessments that are more frequent than exams.",
      //   icon: {
      //     src: "svg/ico-type4.svg",
      //     width: "65px",
      //   },
      // },
      {
        type: ASSIGNMENT_TYPES.EXAM,
        text: "Exam",
        disabled: false,
        content:
          "Assign a timed and graded exam. A student will submit all questions at once and then receive their grade. Use this category for assessments that are less frequent than quizzes.",
        icon: {
          src: "svg/ico-type5.svg",
          width: "83px",
        },
      },
      {
        type: ASSIGNMENT_TYPES.OPENSTAX,
        text: "OpenStax",
        disabled: false,
        content:
          "Assign a section from an OpenStax textbook for students to read. Students can indicate when they have completed the reading.",
        icon: {
          src: "svg/ico-openstax-assignment-type.svg",
          width: "83px",
        },
      },
    ],
  };

  assignments() {
    return this.state.items.map((item, index) => (
      <Fragment key={item.text}>
        <Assignment
          className={classNames({ "is-active": index === this.state.activeItem })}
          onMouseEnter={() => this.handleMouseEnter(index)}
          onMouseLeave={() => this.handleMouseLeave()}
        >
          <div style={{ width: item.icon.width, margin: "0 auto", display: "flex" }}>
            <IconSvgComponent iconPath={item.icon.src} />
          </div>
          <h5>{item.text}</h5>
          <ButtonLink
            disabled={item.disabled}
            className="btn-submit"
            type="button"
            violet
            onClick={() => this.handleAssignmentTypeSelection(item)}
          >
            <span>Select</span>
          </ButtonLink>
        </Assignment>
      </Fragment>
    ));
  }

  handleMouseEnter(index) {
    this.setState({
      activeItem: index,
    });
  }

  handleMouseLeave() {
    this.setState({
      activeItem: null,
    });
  }

  handleAssignmentTypeSelection = (data) => {
    this.props.onSelect(data.type);
  };

  render() {
    const { activeItem, items } = this.state;
    const { active, onClose } = this.props;

    return (
      <FullscreenModalItem active={active} onClose={onClose}>
        <AssignmentTypesWrapper>
          <AssignmentTypesTitle>Select Assignment Type</AssignmentTypesTitle>
          <AssignmentTypes>{this.assignments()}</AssignmentTypes>
          {items[activeItem] && (
            <AssignmentTypesContent row={Math.floor(activeItem / 2) + 2}>
              {items[activeItem].content}
            </AssignmentTypesContent>
          )}
        </AssignmentTypesWrapper>
      </FullscreenModalItem>
    );
  }
}
