import styled from "styled-components";
import theme from "../theme";
import { media } from "../media";

export const FullscreenModal = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  z-index: 9999;

  &.is-active {
    display: block;
  }
`;

export const FullscreenModalClose = styled.span`
  position: fixed;
  top: 25px;
  right: 25px;
  width: 25px;
  height: 25px;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 23px;
    background: #a3aaad;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  ${media.mobile`
    top: 15px;
    right: 5px;
  `};
`;

export const FullscreenModalContent = styled.div`
  padding: 80px 10px 0;
  margin: 0 auto;
  ${(props) =>
    props.max_width &&
    `
    max-width: ${props.max_width}
  `}

  ${media.mobile`
    padding: 50px 15px 0;
  `};
`;
