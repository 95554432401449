/**
 * @ External Dependecies
 */
import styled from "styled-components";
import theme from "../theme";

export const CountdownContainer = styled.div`
  display: block;
  padding: 10px 17px;
  border-radius: ${theme.border_radius};
  background: ${theme.color.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  text-align: center;

  &.danger {
    background: ${theme.color.red};
    color: ${theme.color.white};
  }
`;

export const CountdownTitle = styled.h6`
  margin-bottom: 0;
  line-height: 1.3;
`;
export const CountdownTimer = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
`;
