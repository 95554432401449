import React, { useCallback } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import useAPIData from "components/useAPIData";
import Loader from "components/common/loader";
import { getAssignmentById, getSectionById } from "store/state/courses/actions";
import CourseVideoDashboard from "components/views/CourseVideoDashboard";
import CourseAssessmentDashboard from "components/views/CourseAssessmentDashboard";
import { ASSIGNMENT_TYPES } from "constants/common";

export default function CourseAssignmentDashboard() {
  const { assignmentId, courseId } = useParams();
  const dispatch = useDispatch();
  const storeAssignment = useSelector((state) => state.storedCourses.assignments[assignmentId]);
  const storeCourse = useSelector((state) => state.storedCourses.sections[courseId]);
  const getAssignment = useCallback(() =>
    dispatch(getAssignmentById({ sectionId: courseId, assignmentId }))
  );
  const getCourse = useCallback(() => dispatch(getSectionById({ courseId })));
  const [assignmentLoaded, assignment] = useAPIData({
    fetchDataCallback: getAssignment,
    dependencies: [assignmentId],
    initialDataLoaded: storeAssignment,
  });
  const [courseLoaded, course] = useAPIData({
    fetchDataCallback: getCourse,
    dependencies: [courseId],
    initialDataLoaded: storeCourse,
  });

  return !assignmentLoaded || !courseLoaded ? (
    <Loader />
  ) : assignment && assignment.assignmentType === ASSIGNMENT_TYPES.VIDEO ? (
    <CourseVideoDashboard />
  ) : assignment && assignment.assignmentType === ASSIGNMENT_TYPES.EXAM ? (
    <CourseAssessmentDashboard />
  ) : assignment && assignment.assignmentType === ASSIGNMENT_TYPES.PRACTICE ? (
    <CourseAssessmentDashboard />
  ) : null;
}
