import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import DocumentTitle from "react-document-title";
import moment from "moment";
import { connect } from "react-redux";

import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import Field from "components/common/Field";
import Switch from "components/common/Switch";

import { OPERATIONS } from "constants/common";
import { FORM_CREATE_COURSE_SETTINGS_MESSAGES } from "constants/messages";
import { validateField } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";

import CourseSubnav from "components/courses/CourseSubnav";

import CourseDetailsBlock, {
  CourseDetailsBody,
  CourseDetailsGroupHead,
  CourseDetailsGroupBody,
  CourseDetailsTitle,
  CourseDetailsGroupCard,
  CourseDetailsGroupCardFlex,
  CourseDetailsGroupCardTitle,
  CourseDetailsAlign,
} from "styles/components/CourseDetails";

import Form, { FormControls } from "styles/components/Form";

import CourseTabs, { CourseTab, CourseTabsContent } from "styles/components/CourseTabs";

import { openModal, closeModal } from "store/state/ui/actions";

import { setActiveCourse } from "store/state/courses/actions";
import { Button } from "styles/components/studentDashboard";

class CourseDetailsSettings extends Component {
  state = {
    course_name: "",
    course_section: "",
    course_subject: "",
    course_room: "",
    general_unit_label: "",
    general_settings_1: "",
    general_settings_2: "",
    general_advanced_settings: "",
    general_toggle_1: false,
    general_toggle_2: false,
    grading_weight: "",
    grading_pass: false,
    grading_advanced_settings: false,
    grading_categories: "",
    general_advanced_settings_options: [
      {
        label: "Advanced Setting",
        value: "Advanced Setting",
      },
      {
        label: "Advanced Setting 1",
        value: "Advanced Setting 1",
      },
      {
        label: "Advanced Setting 2",
        value: "Advanced Setting 2",
      },
    ],
    formErrors: {
      course_name: {
        valid: true,
        message: "",
      },
      course_section: {
        valid: true,
        message: "",
      },
      course_subject: {
        valid: true,
        message: "",
      },
      course_room: {
        valid: true,
        message: "",
      },
      general_unit_label: {
        valid: true,
        message: "",
      },
      general_settings_1: {
        valid: true,
        message: "",
      },
      general_settings_2: {
        valid: true,
        message: "",
      },
      grading_weight: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };

  rules = {
    course_name: {
      requiredMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.COURSE_NAME_REQUIRED,
      maxMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.COURSE_NAME_MAX,
    },
    course_section: {
      maxMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.COURSE_SECTION_MAX,
    },
    course_subject: {
      maxMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.COURSE_SUBJECT_MAX,
    },
    course_room: {
      maxMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.COURSE_ROOM_MAX,
    },
    general_unit_label: {
      requiredMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.UNIT_LABEL_REQUIRED,
      maxMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.UNIT_LABEL_MAX,
    },
    general_settings_1: {
      maxMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.SETTINGS_1_MAX,
    },
    general_settings_2: {
      maxMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.SETTINGS_2_MAX,
    },
    grading_weight: {
      maxMessage: FORM_CREATE_COURSE_SETTINGS_MESSAGES.GRADING_WEIGHT_MAX,
    },
  };

  componentDidMount() {
    const { courseId } = this.props.match.params;
    const activeCourse = this.props.courses.filter((course) => course.id == courseId);
    activeCourse.length > 0 && this.props.setActiveCourse(activeCourse[0]);
  }

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  handleStaticChange = (key) => (value) => {
    this.setState({
      [key]: value,
    });
  };

  validateAllFields = () => {
    this.validateTextField(document.getElementById("course_name"), true);
    this.validateTextField(document.getElementById("course_section"), true);
    this.validateTextField(document.getElementById("course_subject"), true);
    this.validateTextField(document.getElementById("course_room"), true);
    this.validateTextField(document.getElementById("general_unit_label"), true);
    this.validateTextField(document.getElementById("general_settings_1"), true);
    this.validateTextField(document.getElementById("general_settings_2"), true);
    this.validateTextField(document.getElementById("grading_weight"), true);
  };

  validateTextField = (element, trimValues) => {
    this.setState({
      formErrors: validateField(this.state.formErrors, this.rules, element, "", trimValues),
    });
  };

  /**
   * Handles the switch input field change.
   *
   * @param {String} / The key
   * @return {Void}
   */
  handleSwitchChange(key, value) {
    this.setState({
      [key]: value,
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.validateAllFields();
    if (this.state.formErrors.formValid) {
      console.log("Submit");
    }
  };

  render() {
    const { openModal, courses, match } = this.props;
    const { courseId } = match.params;
    const currentCourse = courses.find((item) => item.id == courseId);
    const { formErrors } = this.state;

    if (!currentCourse) {
      return null;
    }

    return (
      <DocumentTitle title="Stemify | Course">
        <CourseTabs>
          <CourseSubnav />

          <CourseTabsContent>
            <CourseTab active={true}>
              <Form onSubmit={this.handleFormSubmit}>
                <FieldErrors
                  width={"47%"}
                  formErrors={formErrors}
                  apiErrors={this.props.courseApiErrors[OPERATIONS.UPDATE]?.message}
                ></FieldErrors>
                <CourseDetailsAlign align="right" sticky={true} margin="0 0 -30px">
                  <ButtonLink violet type="submit">
                    Save Changes
                  </ButtonLink>
                </CourseDetailsAlign>
                <CourseDetailsBlock>
                  <CourseDetailsBody>
                    <CourseDetailsGroupHead>
                      <CourseDetailsTitle>Course Details</CourseDetailsTitle>
                    </CourseDetailsGroupHead>
                    <CourseDetailsGroupBody>
                      <CourseDetailsGroupCard>
                        <FormControls>
                          <Field
                            id="course_name"
                            name="course_name"
                            onChange={this.handleInputChange("course_name")}
                            value={this.state.course_name}
                            placeholder="Course Name"
                            required
                            max="255"
                          />
                        </FormControls>
                        <FormControls>
                          <Field
                            id="course_section"
                            name="course_section"
                            onChange={this.handleInputChange("course_section")}
                            value={this.state.course_section}
                            placeholder="Section"
                            max="255"
                          />
                        </FormControls>
                        <FormControls>
                          <Field
                            id="course_subject"
                            name="course_subject"
                            onChange={this.handleInputChange("course_subject")}
                            value={this.state.course_subject}
                            placeholder="Subject"
                            max="255"
                          />
                        </FormControls>
                        <FormControls>
                          <Field
                            id="course_room"
                            name="course_room"
                            onChange={this.handleInputChange("course_room")}
                            value={this.state.course_room}
                            placeholder="Room"
                            max="255"
                          />
                        </FormControls>
                      </CourseDetailsGroupCard>
                    </CourseDetailsGroupBody>

                    <CourseDetailsGroupHead>
                      <CourseDetailsTitle>General</CourseDetailsTitle>
                    </CourseDetailsGroupHead>
                    <CourseDetailsGroupBody>
                      <CourseDetailsGroupCard>
                        <FormControls>
                          <Field
                            id="general_unit_label"
                            name="general_unit_label"
                            onChange={this.handleInputChange("general_unit_label")}
                            value={this.state.general_unit_label}
                            placeholder="Unit Label"
                            required
                            max="255"
                          />
                        </FormControls>
                        <FormControls>
                          <Field
                            id="general_settings_1"
                            name="general_settings_1"
                            onChange={this.handleInputChange("general_settings_1")}
                            value={this.state.general_settings_1}
                            placeholder="Settings 1"
                            max="255"
                          />
                        </FormControls>
                        <FormControls>
                          <Field
                            id="general_settings_2"
                            name="general_settings_2"
                            onChange={this.handleInputChange("general_settings_2")}
                            value={this.state.general_settings_2}
                            placeholder="Settings 2"
                            max="255"
                          />
                        </FormControls>
                        <FormControls>
                          <Field
                            id="general_advanced_settings"
                            name="general_advanced_settings"
                            isSelect
                            onChange={this.handleStaticChange("general_advanced_settings")}
                            options={this.state.general_advanced_settings_options}
                            value={this.state.general_advanced_settings}
                            placeholder="Advanced Setting"
                          />
                        </FormControls>
                        <CourseDetailsGroupCardFlex>
                          Toggle Setting 1
                          <Switch
                            onChange={(value) => this.handleSwitchChange("general_toggle_1", value)}
                            id="general_toggle_1"
                            value={this.state.general_toggle_1}
                          />
                        </CourseDetailsGroupCardFlex>
                        <CourseDetailsGroupCardFlex>
                          Toggle Setting 2
                          <Switch
                            onChange={(value) => this.handleSwitchChange("general_toggle_2", value)}
                            id="general_toggle_2"
                            value={this.state.general_toggle_2}
                          />
                        </CourseDetailsGroupCardFlex>
                      </CourseDetailsGroupCard>
                    </CourseDetailsGroupBody>

                    <CourseDetailsGroupHead>
                      <CourseDetailsTitle>Grading</CourseDetailsTitle>
                    </CourseDetailsGroupHead>
                    <CourseDetailsGroupBody>
                      <CourseDetailsGroupCard>
                        <CourseDetailsGroupCardTitle>Calculations</CourseDetailsGroupCardTitle>
                        <FormControls>
                          <Field
                            id="grading_weight"
                            name="grading_weight"
                            onChange={this.handleInputChange("grading_weight")}
                            value={this.state.grading_weight}
                            placeholder="Grading Weight"
                            max="255"
                          />
                        </FormControls>
                        <CourseDetailsGroupCardFlex>
                          Pass/Fail
                          <Switch
                            onChange={(value) => this.handleSwitchChange("grading_pass", value)}
                            id="grading_pass"
                            value={this.state.grading_pass}
                          />
                        </CourseDetailsGroupCardFlex>
                        <CourseDetailsGroupCardFlex>
                          Advanced Setting
                          <Switch
                            onChange={(value) =>
                              this.handleSwitchChange("grading_advanced_settings", value)
                            }
                            id="grading_advanced_settings"
                            value={this.state.grading_advanced_settings}
                          />
                        </CourseDetailsGroupCardFlex>
                        <CourseDetailsGroupCardTitle>Grade Categories</CourseDetailsGroupCardTitle>

                        <ButtonLink transparent type="button" className="btn-add-item">
                          <IconSvgComponent
                            iconPath={`svg/ico-plus.svg`}
                            additionalClass={"icon-add"}
                          />
                          <span>ADD CATEGORY</span>
                        </ButtonLink>
                      </CourseDetailsGroupCard>
                    </CourseDetailsGroupBody>
                  </CourseDetailsBody>
                </CourseDetailsBlock>
              </Form>
            </CourseTab>
          </CourseTabsContent>
        </CourseTabs>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    userRole: state.user.role,
    currentAccountId: state.user.currentAccountId,
    accountId: state.user.accountId,
    apiCallInProgress: state.administration.apiCallInProgress,
    courses: Object.values(state.storedCourses.sections),
    courseApiErrors: state.administration.apiErrors.cohorts,
  }),
  {
    openModal,
    closeModal,
    setActiveCourse,
  }
)(CourseDetailsSettings);
