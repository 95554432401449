/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";
import { ButtonUpload } from "styles/components/QuestionsNavigation";

export const SectionOuter = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  ${(props) =>
    props.display &&
    `
		display: ${props.display};
	`}
`;

export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.withNav &&
    `
		height: calc(100vh);
	`}

  ${media.mobile`
		&.no-horizontal-scroll {
      overflow-x: hidden;
    }
	`}

      ${(props) =>
        props.padding &&
        `
        padding: ${props.padding};
  `}
`;

export const SectionContent = styled.div`
  position: relative;
  width: 100%;
  padding: 122px 30px 30px;

   ${(props) =>
     !props.dontCalcHeight &&
     `
        height: calc(100vh - 113px);
        max-height: calc(100vh - 113px);
  `}

  overflow-y: auto;
  overflow-x: hidden;

  ${media.tablet_portrait`
    padding: 92px 15px 15px;
  `}

  ${media.mobile`
		padding: 0 10px;
    height: calc(100vh - 105px);
    max-height: calc(100vh - 105px);
    `}

      ${(props) =>
        props.noOverflow &&
        `
        overflow-y: unset;
        overflow-x: unset;
	`}

      ${(props) =>
        props.padding &&
        `
        padding: ${props.padding};
  `}
`;

export const SectionHead = styled.header`
display: flex;
padding: 30px;
          background-image: linear-gradient(180deg, #e6eaf1 60%, rgba(230, 234, 241, 0) 100%);
          position: absolute;
top: 0;
left: 0;
width: 100%;
z-index: 1;
transition: width .3s;

${(props) =>
  props.top &&
  `
    position: fixed;
    top: ${props.top}px;
  `}

${(props) =>
  props.hasSidebar &&
  `
    width: calc(100% - 60px);
  `}

${(props) =>
  props.sidebarOpen &&
  `
    width: calc(100% - 250px);
  `}

  .countdown {
    flex-shrink: 0;
    width: 140px;
    margin-left: 30px;

    ${media.tablet_portrait`
      margin-left: 15px;
    `}
}

${media.tablet_portrait`
    padding: 15px;
  `}

${media.mobile`
		flex-flow: column nowrap;
		margin-bottom: 20px;
    position: static;
    padding: 15px 0;

		.countdown {
			width: 100%;
			margin-left: 0;
			margin-bottom: 10px;
      order: -1;
		}

    ${(props) =>
      props.top &&
      `
      position: static;
      top: 0;
      width: 100%;
    `}

		${(props) =>
      props.paddingRight &&
      `
			padding-right: 0;

			.countdown {
				width: calc(100% - 60px);
			}
		`}
	`}
`;

export const SectionBody = styled.div`
  position: relative;
  ${(props) =>
    props.position &&
    `
        position: ${props.position};
  `}
`;

export const AssessmentUpload = styled(ButtonUpload)`
visibility: hidden;
transition: opacity 0.3s, visibility 0.3s;
flex-shrink: 0;
margin-left: 30px;
align-self: center;

  &.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s 0.3s, visibility 0.3s 0.3s;
    outline: 2px;
    position: static;
}

  &:focus:not(:hover) {
    outline: 2px;
    border: 2px solid ${theme.color.blue} !important;
}

${(props) =>
  props.focus &&
  `
		&:focus:not(:hover){
			outline: 2px;
			border: 2px solid ${theme.color.blue} !important;
		}
	`}

${media.tablet_portrait`
    margin-left: 15px;
    font-size: 0;

    svg { margin-left: 0; }
  `}

${media.mobile`
    margin: 15px 0 0;
    font-size: 15px;
    z-index: 1;
    svg { margin-left: 15px; }
  `}
`;
