import { fetchAPICall, apiUrl } from "utilities/API";
import { getRefreshToken, updateAuthStore, getAuthState } from "utilities/localStore";
import { refreshTokenPath } from "../constants/api";

export const refreshToken = async () => {
  // 1. Check to see if token is about to expire
  const currentTime = Date.now() / 1000;
  const { ExpiresAt } = getAuthState();
  const secondsLeft = ExpiresAt - currentTime;
  const fiveMinutesInSeconds = 300;

  // 2. If token is NOT about to expire (within 5 minutes) then return without fetching new token
  const isTokenAboutToExpire = secondsLeft < fiveMinutesInSeconds;
  if (!isTokenAboutToExpire) return;

  // 3. Fetch new access token with refresh token if within 5 minutes of expiry
  try {
    const refreshTokenValue = getRefreshToken();
    if (refreshTokenValue) {
      const response = await fetchAPICall({
        baseURI: apiUrl,
        resourcePath: refreshTokenPath,
        headers: {
          Authorization: getRefreshToken(),
        },
        method: "post",
      });

      if (
        response &&
        response.status &&
        response.status == "success" &&
        response.data.AuthenticationResult
      ) {
        const currentAuth = getAuthState();
        const receivedAuth = response.data.AuthenticationResult;

        const refreshedAuth = {
          ...currentAuth,
          ...receivedAuth,
          ExpiresAt: null, // so that updateAuthStore can re-calculate expiration
        };

        updateAuthStore(refreshedAuth);
      }
      return response;
    }
  } catch (e) {
    console.log("error refreshing access token", e);
  }
};

export const renewAccessToken = async () => {
  try {
    const refreshTokenValue = getRefreshToken();
    if (refreshTokenValue) {
      const response = await fetchAPICall({
        baseURI: apiUrl,
        resourcePath: refreshTokenPath,
        headers: {
          Authorization: getRefreshToken(),
        },
        method: "post",
      });

      if (
        response &&
        response.status &&
        response.status == "success" &&
        response.data.AuthenticationResult
      ) {
        const currentAuth = getAuthState();
        const receivedAuth = response.data.AuthenticationResult;

        const refreshedAuth = {
          ...currentAuth,
          ...receivedAuth,
          ExpiresAt: null, // so that updateAuthStore can re-calculate expiration
        };

        updateAuthStore(refreshedAuth);
      }
      return response;
    }
  } catch (e) {
    console.log("error refreshing access token", e);
  }
};
