import { handleActions } from "redux-actions";

import { updateDeploymentState, clearDeployment, closeModal } from "./actions";
import { requestLogin, successLogout } from "../user/actions";
import { selectAssessmentSeries } from "../assessment/actions";

const defaultState = {
  id: "",
  name: "",
  program: "",
  programId: "",
  cohort: "",
  cohortId: "",
  cohortNumber: "",
  startDate: "",
  endDate: "",
  dateCreated: "",
  status: "",
  ownerName: "",
  ownerEmail: "",
  message: "",
  completed_before_retake: false,
  restricted: false,
  accessCode: "",
  assessments: [],
  apiCallInprogress: {},
};

const deployment = handleActions(
  {
    [updateDeploymentState]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [clearDeployment]: (state) => ({
      id: "",
      is_draft: "",
      name: "",
      program: "",
      cohort: "",
      cohortNumber: "",
      startDate: "",
      endDate: "",
      dateCreated: "",
      status: "",
      ownerName: "",
      ownerEmail: "",
      message: "",
      completed_before_retake: false,
      restricted: false,
      accessCode: "",
      assessments: [],
    }),
    [closeModal]: (state) => ({
      ...state,
      apiErrors: defaultState.apiErrors,
    }),
    [requestLogin]: () => ({
      ...defaultState,
    }),
    [successLogout]: () => ({
      ...defaultState,
    }),
    [selectAssessmentSeries]: (state, { payload: { deploymentId: id } }) => ({
      ...state,
      id,
    }),
  },
  defaultState
);

export default deployment;
