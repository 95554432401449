import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Redirect, matchPath } from "react-router-dom";
import styled from "styled-components";
import {
  MODAL_WIZARD,
  WIZARD_DEPLOYMENT,
  WIZARD_CREATE_DEPLOYMENT,
  MODAL_NO_NETWORK,
} from "lib/constants";
import { updateDeployment } from "store/state/deployment/actions";
import { openModal } from "store/state/ui/actions";
import { setPageBlur } from "store/state/questions/actions";
import HeaderComponent from "components/common/HeaderComponent";
import Breadcrumb from "components/breadcrumb/Breadcrumb";
import { BreadcrumbsInner } from "styles/components/BreadcrumbsContainer";
import SectionTable from "styles/components/SectionTable";
import ButtonLink from "components/common/ButtonLink";
import PageWrap from "styles/components/PageWrap";
import Accounts from "components/views/Accounts";
import Users from "components/views/Users";
import Cohorts from "components/views/Cohorts";
import Students from "components/views/Students";
import LearningLibrary from "components/views/LearningLibrary";
import LearningUnit from "components/views/LearningUnit";
import LearningModule from "components/views/LearningModule";
import Can from "components/common/Can";
import { ROLES, ROLE_RULES } from "constants/roles";
import ErrorPage from "components/views/ErrorPage";
import { pickBy } from "ramda";
import ScrollLock from "react-scrolllock";
import AssessmentSelect from "components/views/AssessmentSelect";
import AssessmentOverview from "components/views/AssessmentOverview";
import PracticeAssignment from "components/views/PracticeAssignment";
import PracticeAssignmentResults from "components/views/PracticeAssignmentResults";
import AssessmentTaker from "components/views/AssessmentTaker";
import Deployments from "components/views/Deployments";
import DeploymentDetails from "components/views/DeploymentDetails";
import AssessmentDashboard from "components/views/AssessmentDashboard";
import CourseAssignmentDashboard from "components/views/CourseAssignmentDashboard";
import StudentHome from "./StudentHome";
import InstructorHome from "./InstructorHome";
import AssessmentDashboardStudent from "./AssessmentDashboardStudent";
import AssessmentDashboardStudentAttempt from "./AssessmentDashboardStudentAttempt";
import FAQ from "components/views/FAQ";
import GraphDemo from "components/views/GraphDemo";
import stemifyRoutes from "../../constants/routes";
import Main from "../studentDashboard/Main";
import LearningDiscipline from "components/views/LearningDiscipline";
import LearningDisciplineSubject from "components/views/LearningDisciplineSubject";
import Courses from "components/views/Courses";
import CourseDetails from "components/views/CourseDetails";
import CourseDetailsPeople from "components/views/CourseDetailsPeople";
import CourseDetailsAssignmentGrades from "components/views/CourseDetailsAssignmentGrades";
import CourseDetailsStudentGrades from "components/views/CourseDetailsStudentGrades";
import CourseDetailsSettings from "components/views/CourseDetailsSettings";
import CourseDetailPageAssessment from "components/views/CourseDetailPageAssessment";
import AssessmentBuilderList from "components/views/AssessmentBuilderList";
import AssessmentBuilder from "components/views/AssessmentBuilder";
import AdminAttemptsPage from "components/views/AdminAttemptsPage";
import AdminBannerPage from "components/views/AdminBannerPage";
import AdminOpenStaxPage from "components/views/AdminOpenStaxPage";
import AdminAssessmentsPage from "components/views/AdminAssessmentsPage";

import { getUserCurrentAccount } from "utilities/localStore";
const routes = [
  {
    path: "/assessment-builder",
    component: AssessmentBuilderList,
    exact: true,
    permissions: "home-page:view",
  },
  {
    path: "/assessment-builder/:builderId",
    component: AssessmentBuilder,
    exact: true,
    permissions: "home-page:view",
  },
  {
    path: `${stemifyRoutes.courses}`,
    component: Courses,
    exact: true,
    permissions: "courses:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework`,
    component: CourseDetails,
    exact: true,
    permissions: "courses:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/people`,
    component: CourseDetailsPeople,
    exact: true,
    permissions: "courses:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/grades/assignments/:assignmentId`,
    component: CourseDetailPageAssessment,
    exact: true,
    permissions: "courses:view",
  },
  {
    path: "/courses/:courseId/analytics/:assignmentId",
    component: CourseAssignmentDashboard,
    exact: true,
    permissions: "courses-dashboard:view",
  },
  {
    path: "/courses/:courseId/analytics/:assignmentId/quiz",
    component: CourseAssignmentDashboard,
    exact: true,
    permissions: "courses-dashboard:view",
  },
  {
    path: "/courses/:courseId/grades/assignments",
    component: CourseDetailsAssignmentGrades,
    exact: true,
    permissions: "courses:view",
  },
  {
    path: "/courses/:courseId/grades/students",
    component: CourseDetailsStudentGrades,
    exact: true,
    permissions: "courses-student-tab-grade:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/settings`,
    component: CourseDetailsSettings,
    exact: true,
    permissions: "courses:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId/exam/:assessmentId`,
    component: AssessmentTaker,
    key: "exam-assessment-taker",
    exact: true,
    permissions: "courses:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId/exam/:assessmentId/:attemptId`,
    component: AssessmentTaker,
    key: "exam-assessment-taker",
    exact: true,
    permissions: "courses:view",
  },
  {
    path: "/learning-discipline",
    component: LearningDiscipline,
    exact: true,
    permissions: "home-page:view",
  },
  {
    path: "/learning-discipline/:disciplineId",
    component: LearningDisciplineSubject,
    exact: true,
    permissions: "home-page:view",
  },
  {
    path: `${stemifyRoutes.deployments}/:deploymentId/:assessmentId/:studentId`,
    component: AssessmentDashboardStudent,
    exact: true,
    permissions: "assessment-dashboard-student:view",
  },
  {
    path: `${stemifyRoutes.deployments}/:deploymentId/:assessmentId/:studentId/:attemptId`,
    component: AssessmentDashboardStudentAttempt,
    exact: true,
    permissions: "assessment-dashboard-student:view",
  },
  {
    path: `${stemifyRoutes.home}`,
    dynamic: true,
    getDynamicRoute: (userRole) => {
      const { accountId } = getUserCurrentAccount();

      // Check if MASTER_ADMIN has switched account? If yes set role to ACCOUNT_ADMIN
      // so as to view the My Dashboard same as that of ACCOUNT_ADMIN and INSTRUCTOR roles
      const role = !!accountId ? ROLES.ACCOUNT_ADMIN : userRole;
      switch (role) {
        case ROLES.ACCOUNT_ADMIN:
        case ROLES.INSTRUCTOR:
          return {
            component: InstructorHome,
            permissions: "faculty-home:view",
          };
        case ROLES.STUDENT:
          return {
            component: StudentHome,
            permissions: "student-home:view",
          };
        default:
          return {
            component: () => <Redirect to={stemifyRoutes.learningLibrary} />,
            permissions: "learning-library-page:view",
          };
      }
    },
    exact: true,
  },
  {
    path: `${stemifyRoutes.accounts}`,
    component: Accounts,
    exact: true,
    permissions: "accounts-page:view",
  },
  {
    path: `${stemifyRoutes.accounts}/:accountId`,
    component: Users,
    exact: true,
    permissions: "users-page:view",
  },
  {
    path: `${stemifyRoutes.accounts}/:accountId/settings`,
    component: Users,
    exact: true,
    permissions: "users-page:view",
  },
  {
    path: `${stemifyRoutes.cohorts}`,
    component: Cohorts,
    exact: true,
    permissions: "cohorts-page:view",
  },
  {
    path: `${stemifyRoutes.cohorts}/:cohortId`,
    component: Students,
    exact: true,
    permissions: "cohorts-page:view",
  },
  {
    path: `${stemifyRoutes.learningLibrary}`,
    component: LearningDiscipline,
    exact: true,
    permissions: "learning-library-page:view",
  },
  {
    path: `${stemifyRoutes.learningLibraryLearningModule}`,
    component: LearningLibrary,
    exact: true,
    permissions: "learning-library-page:view",
  },
  {
    path: `${stemifyRoutes.learningLibraryLearningModule}/:moduleId`,
    component: LearningModule,
    exact: true,
    permissions: "learning-library-page:view",
  },
  {
    path: `${stemifyRoutes.learningLibrary}/:unitId`,
    component: LearningDisciplineSubject,
    exact: true,
    permissions: "learning-library-page:view",
  },
  {
    path: `${stemifyRoutes.learningLibrary}/:unitId/:moduleId`,
    component: LearningModule,
    exact: true,
    permissions: "learning-library-page:view",
  },
  {
    path: `${stemifyRoutes.pageNotFound}`,
    component: ErrorPage,
    exact: true,
    permissions: "cohorts-page:view",
  },
  {
    path: `${stemifyRoutes.assessments}/:deploymentId/:assessmentId`,
    component: AssessmentTaker,
    key: "placement-assessment-taker",
    exact: true,
    permissions: "placement-assessment:view",
  },
  {
    path: `${stemifyRoutes.assessments}/:deploymentId/:assessmentId/results`,
    component: AssessmentOverview,
    exact: true,
    permissions: "placement-assessment:view",
  },
  {
    path: `${stemifyRoutes.assessments}/:deploymentId/:assessmentId/:attemptId/results`,
    component: AssessmentOverview,
    exact: true,
    permissions: "placement-assessment:view",
  },
  {
    path: `${stemifyRoutes.assessments}/:deploymentId/:assessmentId/:attemptId`,
    component: AssessmentTaker,
    key: "placement-assessment-taker",
    exact: true,
    permissions: "placement-assessment:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId/exam/:assessmentId/results`,
    component: AssessmentOverview,
    exact: true,
    permissions: "placement-assessment:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId/exam/:assessmentId/:attemptId/results`,
    component: AssessmentOverview,
    exact: true,
    permissions: "placement-assessment:view",
  },
  {
    path: `${stemifyRoutes.assessments}`,
    component: AssessmentSelect,
    exact: true,
    permissions: "placement-assessment:view",
  },
  {
    path: `${stemifyRoutes.learningLibraryLearningModule}/:moduleId/:assignmentId`,
    component: PracticeAssignment,
    exact: true,
    permissions: "practice-assignment:view",
  },
  {
    path: `${stemifyRoutes.learningLibraryLearningModule}/:moduleId/:assignmentId/results`,
    component: PracticeAssignmentResults,
    exact: true,
    permissions: "practice-assignment:view",
  },
  {
    path: `${stemifyRoutes.learningLibrary}/:unitId/:moduleId/:assignmentId`,
    component: PracticeAssignment,
    exact: true,
    permissions: "practice-assignment:view",
  },
  {
    path: `${stemifyRoutes.learningLibrary}/:unitId/:moduleId/:assignmentId/results`,
    component: PracticeAssignmentResults,
    exact: true,
    permissions: "practice-assignment:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId`,
    component: PracticeAssignment,
    exact: true,
    permissions: "practice-assignment:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:assignmentId/results`,
    component: PracticeAssignmentResults,
    exact: true,
    permissions: "practice-assignment:view",
  },
  {
    path: `${stemifyRoutes.courses}/:courseId/coursework/:unitId/:moduleId`,
    component: LearningModule,
    exact: true,
    permissions: "courses:view",
  },
  {
    path: `${stemifyRoutes.deployments}`,
    component: Deployments,
    exact: true,
    permissions: "deployments:view",
  },
  {
    path: `${stemifyRoutes.deployments}/:deploymentId`,
    component: DeploymentDetails,
    exact: true,
    permissions: "deployments:view",
  },
  {
    path: `${stemifyRoutes.deployments}/:deploymentId/:assessmentId`,
    component: AssessmentDashboard,
    exact: true,
    permissions: "deployments:view",
  },
  {
    path: `${stemifyRoutes.faq}`,
    component: FAQ,
    exact: true,
    permissions: "faq:view",
  },
  {
    path: `${stemifyRoutes.studentPerformanceDetails}/:deploymentId/:assessmentSeriesId`,
    component: Main,
    exact: true,
    permissions: "student-home:view",
  },
  {
    path: `${stemifyRoutes.graph}`,
    component: GraphDemo,
    exact: true,
    permissions: "faq:view",
  },
  {
    path: `/admin/attempts`,
    component: AdminAttemptsPage,
    exact: true,
    permissions: "admin:view",
  },
  {
    path: `/admin/banner`,
    component: AdminBannerPage,
    exact: true,
    permissions: "admin:view",
  },
  {
    path: `/admin/openstax`,
    component: AdminOpenStaxPage,
    exact: true,
    permissions: "admin:view",
  },
  {
    path: `/admin/assessments`,
    component: AdminAssessmentsPage,
    exact: true,
    permissions: "admin:view",
  },
];

class Home extends Component {
  state = {
    isLocked: this.props.isLocked,
    isBlured: this.props.isModalOpen,
  };

  handleTriggerLock = () => {
    this.setState({ isLocked: !this.state.isLocked });
  };

  handleBlurBackground = () => {
    this.setState({ isBlured: !this.state.isBlured });
  };

  isProgramDashboardRoute = () => {
    return matchPath(this.props.location.pathname, {
      path: `/programs/:programId`,
    });
  };

  is404 = () => {
    if (this.props.isApiError404 && this.props.location.pathname != "/page-not-found") return true;

    const res = matchPath(this.props.location.pathname, {
      path: routes.map((route) => route.path),
      exact: true,
    });

    if (res) {
      const { params } = res;
      delete params.unitId; // As unitId is passed as string "learning-module" when its filter by topic/tag
      delete params.studentId; //To allow string for studentId (username)
      delete params.coursework;
      // check if matching dynamic route parameters are set as string and are integer values
      const isNumeric = (val, key) => parseInt(val) > 0;
      const isString = (val, key) => typeof val === "string";
      const numericV = pickBy(isNumeric, params);
      const stringV = pickBy(isString, params);

      if (Object.keys(numericV).length !== Object.keys(stringV).length) {
        return true;
      }

      return false;
    }

    return true;
  };

  deployProgram = (e) => {
    e.preventDefault();
    const { updateDeployment, openModal, programs } = this.props;

    const id = this.isProgramDashboardRoute().params.programId;
    const programName = programs.find((program) => program.id === id).name;

    updateDeployment({
      program: programName,
    });

    openModal({
      type: MODAL_WIZARD,
      data: {
        wizardType: WIZARD_DEPLOYMENT,
        initialStep: WIZARD_CREATE_DEPLOYMENT,
      },
    });
  };

  openNonetworkModal = () => {
    const { openModal } = this.props;
    openModal({
      type: MODAL_NO_NETWORK,
      data: { modalSize: "sm" },
      shouldNotCloseOnEsc: true,
    });
  };

  render() {
    const { user, isModalOpen, isLocked, location, showNoNetworkDialouge, openModal } = this.props;

    return this.is404() ? (
      <Redirect to={stemifyRoutes.pageNotFound} />
    ) : (
      <PageWrap
        isBlured={isModalOpen}
        isBodyLocked={isLocked}
        showReconnect={showNoNetworkDialouge}
      >
        <HeaderComponent triggerLock={this.handleTriggerLock} />
        {showNoNetworkDialouge ? (
          this.openNonetworkModal()
        ) : (
          <Fragment>
            {location.pathname != stemifyRoutes.pageNotFound && (
              <Breadcrumb role={user.role} match={this.props.match}>
                {this.isProgramDashboardRoute() && (
                  <BreadcrumbsInner>
                    <ButtonLink xs onClick={this.deployProgram} violet>
                      Deploy Program
                    </ButtonLink>
                  </BreadcrumbsInner>
                )}
              </Breadcrumb>
            )}
            <LockScroll className="scroll-lock" isActive={isLocked}>
              <Switch>
                {routes.map(
                  ({
                    key,
                    path,
                    component: Component,
                    exact,
                    permissions,
                    dynamic,
                    getDynamicRoute,
                  }) => {
                    if (dynamic) {
                      const dynamicProps = getDynamicRoute(user.role);

                      return (
                        <Route
                          key={key ? key : path}
                          exact={exact}
                          path={path}
                          render={(props) => {
                            return (
                              <Can
                                key={path}
                                role={user.role}
                                perform={dynamicProps.permissions}
                                yes={() => <dynamicProps.component {...props} />}
                                no={() => {
                                  if (process.env.REACT_APP_DISABLE_LOGIN) {
                                    return <dynamicProps.component {...props} />;
                                  }
                                  return <Redirect to={stemifyRoutes.learningLibrary} />;
                                }}
                              />
                            );
                          }}
                        />
                      );
                    }

                    return (
                      <Route
                        key={key ? key : path}
                        exact={exact}
                        path={path}
                        render={(props) => {
                          if (
                            path === `${stemifyRoutes.accounts}` &&
                            user.role !== ROLES.MASTER_ADMIN
                          ) {
                            return <Redirect to={`${stemifyRoutes.accounts}/${user.accountId}`} />;
                          }
                          return (
                            <Can
                              key={key ? key : path}
                              role={user.role}
                              perform={permissions}
                              yes={() => <Component {...props} />}
                              no={() => {
                                if (process.env.REACT_APP_DISABLE_LOGIN) {
                                  return <Component {...props} />;
                                }
                                return <Redirect to={stemifyRoutes.home} />;
                              }}
                            />
                          );
                        }}
                      />
                    );
                  }
                )}
                <Route
                  key="*"
                  exact
                  path="*"
                  render={(props) => {
                    return <Redirect to={stemifyRoutes.pageNotFound} />;
                  }}
                />
              </Switch>
            </LockScroll>
          </Fragment>
        )}
      </PageWrap>
    );
  }
}

const LockScroll = styled(ScrollLock)`
  display: flex;
  width: 100 %;
  flex-grow: 1;
  flex-shrink: 1;

  & > *,
  ${SectionTable} {
    flex: 1 1 100%;
  }
`;

export default withRouter(
  connect(
    (state) => ({
      isModalOpen: state.questions.isModalOpen,
      isLocked: state.questions.isLocked,
      loggedIn: state.user.loggedIn,
      programs: state.administration.programs,
      accounts: state.administration.accounts,
      user: state.user,
      apiErrors: state.administration.apiErrors,
      isApiError404: state.administration.isApiResponsePageNotFound,
      showNoNetworkDialouge: state.user.showNoNetworkDialouge,
    }),
    {
      setPageBlur,
      updateDeployment,
      openModal,
    }
  )(Home)
);
