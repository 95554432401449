import React from "react";
import { Pill } from "styles/components/Btns";

// Matt: add enum without breaking existing implementation
export const STATUS_TYPES = {
  DASHBOARD: "dashboard",
  DEPLOYMENT: "deployment",
};

export const STATUSES = {
  LIVE: "Live",
  SCHEDULED: "Scheduled",
  DRAFT: "Draft",
  CLOSED: "Closed",
};

const StatusPill = (props) => {
  const { type, status, isAbsolute, data } = props;

  if (type && status) {
    switch (type) {
      // Matt: adding dashboard type for reusable dashboard card "DashboardDetailsCard"
      case STATUS_TYPES.DASHBOARD:
      case STATUS_TYPES.DEPLOYMENT:
        switch (status.toLowerCase()) {
          case "live":
            return (
              <Pill small primary absolute={isAbsolute}>
                {status}
              </Pill>
            );
          case "scheduled":
            return (
              <Pill small primaryOutline absolute={isAbsolute}>
                {status}
              </Pill>
            );
          case "draft":
            return (
              <Pill small defaultOutline absolute={isAbsolute}>
                {status}
              </Pill>
            );
          case "closed":
            return (
              <Pill small default absolute={isAbsolute}>
                {status}
              </Pill>
            );

          default:
            return status ? status : "";
        }

      default:
        return status ? status : "";
    }
  }
  return status ? status : "";
};

export default StatusPill;
