import {
  disciplinePath,
  sectionsPath,
  schedulesPath,
  assignmentsPath,
  assignmentBySectionAndIdPath,
  sectionByIdPath,
  sectionByIdCopyPath,
  unitsBySectionIdPath,
  scheduleBySectionIdPath,
  assignmentsBySectionIdPath,
  videoByIdPath,
  assessmentByIdPath,
  assessmentSeriesPath,
  videoPath,
  unitPath,
  sectionUnitsPath,
  sectionUserPath,
  usersInCoursePath,
  assignmentPath,
  assignmentWithIdPath,
  sectionEnrollmentPath,
  sectionPath,
  enrollmentPath,
  getAssignmentPath,
  getAssignmentAttemptsPath,
  updateAssignmentPath,
  sectionAssignmentGradesPath,
  sectionStudentsGradesPath,
  assignmentDashboardStudentCountPath,
  assignmentDashboardAvgTimePath,
  assignmentDashboardAverageScorePath,
  assignmentStandardDeviationPath,
  courseAssessmentCompletionPath,
  courseAssessmentScoreDistributionPath,
  courseAssessmentTopicBreakDownPath,
  courseAssessmentStudentBreakDownPath,
  videoQuizAverageCorrectAnswersPath,
  videoConceptsBreakdownPath,
  videoQuizStudentBreakdownPath,
  videoStudentBreakdownPath,
  contentType,
} from "../constants/api";

import { fetchAPICall, apiUrl } from "../utilities/API";
import { getAccessToken } from "../utilities/localStore";
import { contentTypeJSON } from "../constants/api";

const getSectionsAPI = ({ accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionsPath,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getAssignmentsAPI = ({ accountId, sectionIds = [] }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${assignmentsPath}?sections=[${sectionIds}]&page=1&pageSize=0`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getAssignmentByIdAPI = ({ accountId, sectionId, assignmentId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assignmentBySectionAndIdPath({ sectionId, assignmentId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getSchedulesAPI = ({ accountId, sectionIds }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${schedulesPath}?sections=${sectionIds.join(",")}&page=1&pageSize=0`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getAllDisciplinesAPI = ({ accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: disciplinePath,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getSectionByIdAPI = ({ accountId, sectionId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionByIdPath({ sectionId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getUnitsBySectionIdAPI = ({ accountId, sectionId, page = 1, pageSize = 0 }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${unitsBySectionIdPath({ sectionId })}?page=${page}&pageSize=${pageSize}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getAssignmentsBySectionIdAPI = ({ accountId, sectionId, pageSize = 0 }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${assignmentsBySectionIdPath({ sectionId })}?pageSize=${pageSize}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getScheduleBySectionIdAPI = ({ accountId, sectionId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: scheduleBySectionIdPath({ sectionId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getVideoDetailsByIdAPI = ({ accountId, id }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: videoByIdPath({ id }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getVideoQuizAverageCorrectAnswersAPI = ({ accountId, assignmentId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: videoQuizAverageCorrectAnswersPath(assignmentId),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getAssessmentDetailsByIdAPI = ({ accountId, id }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assessmentByIdPath({ id }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const createSectionAPI = (requestData, accountId) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionsPath,
    method: "post",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  });
};

const copySectionAPI = ({ accountId, sectionId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionByIdCopyPath({ sectionId }),
    method: "post",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const updateSectionAPI = (requestData, courseId, accountId) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionByIdPath({ sectionId: courseId }),
    method: "PUT",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  });
};

const getAllVideosAPI = ({ accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: videoPath,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getAllAssessments = ({ accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assessmentSeriesPath,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getEnrolledCourseUsersAPI = ({ accountId, courseId, pageSize }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${sectionEnrollmentPath({ courseId })}?pageSize=${pageSize}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const getAssignedCourseUsersAPI = ({ accountId, courseId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionUserPath({ id: courseId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const addUsersToCourseAPI = ({ accountId, courseId, decorateAddUsersRequest, selectedItems }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionUserPath({ id: courseId }),
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(decorateAddUsersRequest),
  });
};

const getAssignmentGradesAPI = ({ accountId, sectionId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${sectionStudentsGradesPath({ sectionId })}?pageSize=0`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const removeUserFromCourseAPI = ({ accountId, courseId, userName, userRole }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: usersInCoursePath({ id: courseId, username: userName, role: userRole }),
    method: "DELETE",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const updateUnit = (unit, unitId, accountId) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: unitPath({ unitId }),
    method: "put",
    body: JSON.stringify(unit),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const createUnit = ({ unit, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionUnitsPath({ id: unit.section_id }),
    method: "post",
    body: JSON.stringify(unit),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const deleteSectionAPI = ({ sectionId, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionByIdPath({ sectionId }),
    method: "delete",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const deleteUnitAPI = ({ accountId, unitId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: unitPath({ unitId }),
    method: "delete",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const createAssignmentAPI = (request, accountId) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assignmentPath,
    method: "post",
    body: JSON.stringify(request),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const deleteAssignmentAPI = ({ accountId, assignmentId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assignmentWithIdPath({ assignmentId }),
    method: "delete",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const createScheduleAPI = ({ accountId, request }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: schedulesPath,
    method: "post",
    body: JSON.stringify(request),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const removeEnrolledCourseStudentsAPI = ({ accountId, courseId, username }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${sectionEnrollmentPath({ courseId })}/${username}`,
    method: "DELETE",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
    },
  });
};

const enrollCourseStudentsAPI = ({ accountId, courseId, users }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: sectionEnrollmentPath({ courseId }),
    method: "POST",
    body: JSON.stringify(users),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getAssignmentAPI = ({ sectionId, assignmentId, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: getAssignmentPath({ sectionId, assignmentId }),
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const getAssignmentAttemptsAPI = ({ assignmentId, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${getAssignmentAttemptsPath({ assignmentId })}?page=1&pageSize=0`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const getNumberOfAssignmentAttemptsAPI = ({ assignmentId, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${assignmentWithIdPath({ assignmentId })}/number_of_attempts`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const updateAssignmentAPI = (request, assignmentId, accountId) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: updateAssignmentPath({ assignmentId }),
    method: "put",
    body: JSON.stringify(request),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const getScheduleForSelectedAssignmentAPI = ({ assignmentIdArray, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${schedulesPath}?assignment_id=[${assignmentIdArray}]`,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const updateScheduleForSelectedAssignmentAPI = ({ request, assignmentId, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${assignmentPath}/${assignmentId}/schedule`,
    method: "put",
    body: JSON.stringify(request),
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": "application/json",
    },
  });
};

const getSectionGradesByAssignmentAPI = ({ accountId, sectionId, page = 1, pageSize = 0 }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${sectionAssignmentGradesPath({ sectionId })}?page=${page}&pageSize=${pageSize}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getAssignmentDashboardStudentCountAPI = ({ accountId, assignmentId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assignmentDashboardStudentCountPath({ assignmentId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getAssignmentDashboardAverageTimeAPI = ({ accountId, assignmentId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assignmentDashboardAvgTimePath({ assignmentId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getAssignmentStandardDeviationAPI = ({ accountId, assignmentId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assignmentStandardDeviationPath({ assignmentId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getAssignmentDashboardAverageScoreAPI = ({ accountId, assignmentId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: assignmentDashboardAverageScorePath({ assignmentId }),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getCourseAssessmentCompletionAPI = ({ assignmentId, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: courseAssessmentCompletionPath(assignmentId),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getCourseAssessmentScoreDistributionAPI = ({ assignmentId, accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: courseAssessmentScoreDistributionPath(assignmentId),
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getCourseAssessmentTopicBreakDownAPI = ({
  assignmentId,
  accountId,
  page = 1,
  pageSize = 0,
}) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${courseAssessmentTopicBreakDownPath(
      assignmentId
    )}?page=${page}&pageSize=${pageSize}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getCourseAssessmentStudentBreakDownAPI = ({
  assignmentId,
  accountId,
  page = 1,
  pageSize = 0,
}) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${courseAssessmentStudentBreakDownPath(assignmentId)}?pageSize=0`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getVideoConceptsBreakdownAPI = ({ assignmentId, accountId, page = 1, pageSize = 0 }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${videoConceptsBreakdownPath(assignmentId)}`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const fetchVideoCompletionDataAPI = async ({ accountId, assignmentId }) => {
  const resourcePath = `${assignmentPath}/${assignmentId}/video_completion`;

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentType,
    },
  });
};

const getVideoQuizStudentBreakdownAPI = ({ assignmentId, accountId, page = 1, pageSize = 0 }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${videoQuizStudentBreakdownPath(assignmentId)}?pageSize=0`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getVideoStudentBreakdownAPI = ({ assignmentId, accountId, page = 1, pageSize = 0 }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${videoStudentBreakdownPath(assignmentId)}?pageSize=0`,
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const fetchVideoQuizCompletionDataAPI = async ({ accountId, assignmentId }) => {
  const resourcePath = `${assignmentPath}/${assignmentId}/video_quiz_completion`;

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath,
    method: "get",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentType,
    },
  });
};

const getOpenStaxReleases = ({ accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: "/section/openstax/releases",
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const getOpenStaxTracking = ({ accountId }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: "/openstax/tracking",
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
  });
};

const saveOpenStaxClick = ({ accountId, feature }) => {
  return fetchAPICall({
    baseURI: apiUrl,
    resourcePath: "/openstax/tracking",
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      account_id: accountId,
      "Content-Type": contentTypeJSON,
    },
    body: JSON.stringify({ feature }),
  });
};

export {
  getAllDisciplinesAPI,
  getSectionsAPI,
  getAssignmentsAPI,
  getAssignmentByIdAPI,
  getSchedulesAPI,
  getSectionByIdAPI,
  getUnitsBySectionIdAPI,
  getAssignmentsBySectionIdAPI,
  getScheduleBySectionIdAPI,
  getVideoDetailsByIdAPI,
  getAssessmentDetailsByIdAPI,
  createSectionAPI,
  copySectionAPI,
  updateSectionAPI,
  getAllAssessments,
  updateUnit,
  createUnit,
  deleteUnitAPI,
  deleteAssignmentAPI,
  createAssignmentAPI,
  createScheduleAPI,
  getAssignedCourseUsersAPI,
  addUsersToCourseAPI,
  removeUserFromCourseAPI,
  getAllVideosAPI,
  getEnrolledCourseUsersAPI,
  removeEnrolledCourseStudentsAPI,
  enrollCourseStudentsAPI,
  deleteSectionAPI,
  getAssignmentAPI,
  updateAssignmentAPI,
  updateScheduleForSelectedAssignmentAPI,
  getScheduleForSelectedAssignmentAPI,
  getSectionGradesByAssignmentAPI,
  getAssignmentGradesAPI,
  getAssignmentAttemptsAPI,
  getNumberOfAssignmentAttemptsAPI,
  getAssignmentDashboardStudentCountAPI,
  getAssignmentDashboardAverageTimeAPI,
  getAssignmentDashboardAverageScoreAPI,
  getAssignmentStandardDeviationAPI,
  getCourseAssessmentCompletionAPI,
  getCourseAssessmentScoreDistributionAPI,
  getCourseAssessmentTopicBreakDownAPI,
  getCourseAssessmentStudentBreakDownAPI,
  getVideoQuizAverageCorrectAnswersAPI,
  getVideoConceptsBreakdownAPI,
  getVideoQuizStudentBreakdownAPI,
  getVideoStudentBreakdownAPI,
  fetchVideoCompletionDataAPI,
  fetchVideoQuizCompletionDataAPI,
  getOpenStaxReleases,
  getOpenStaxTracking,
  saveOpenStaxClick,
};
