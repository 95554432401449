/**
 * The external dependencies.
 */
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import theme from "styles/theme";
/**
 * The internal dependencies.
 */
import { media } from "styles/media";
import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";

/**
 * Class for modal frame.
 *
 * @class      ModalFrame (name)
 */
const ModalFrame = ({
  children,
  closeModal,
  modifier,
  onClick,
  apiCallInProgress,
  isAssessmentSubmissionGuide,
  hideCloseBtn = false,
}) => {
  onClick = onClick ? onClick : closeModal;
  return (
    <Frame
      id={"modalFrame"}
      modifier={modifier}
      isAssessmentSubmissionGuide={isAssessmentSubmissionGuide}
    >
      {!hideCloseBtn && (
        <FrameBtnClose onClick={() => onClick()} disabled={apiCallInProgress}>
          <IconSvgComponent iconPath="svg/ico-close-thick.svg" />
        </FrameBtnClose>
      )}
      {children}
    </Frame>
  );
};

const Frame = styled.div.attrs({
  id: (props) => props.id,
})`
	position: relative;
    padding: 65px;
    border-radius: ${theme.border_radius};
	// padding: 38px 65px;
	background: #fff;

    ${(props) =>
      props.isAssessmentSubmissionGuide &&
      `
		padding: 45px;
	`}

	${(props) =>
    props.modifier === "modal-account-switching" &&
    `
		padding: 20px 3px 15px;
    `}
    ${(props) =>
      props.modifier === "modal-assessment-resume" &&
      `
		padding: 20px;
    `}


	${(props) =>
    props.modifier === "modal-frame-add-users" &&
    `
		padding: 46px 0;
	`}

    ${(props) =>
      props.modifier === "modal-frame-image-manager" &&
      `
      padding: 45px 0 0;
    `}

	${media.tablet_portrait`
		padding: 38px 15px;

    ${(props) =>
      props.modifier === "modal-frame-image-manager" &&
      `
      padding: 45px 0 0;
    `}
	`}
`;

const FrameBtnClose = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  border: none;
  background: none;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 3px;
  margin-top: -4px;
  margin-right: -4px;

  &:focus {
    border: 2px solid #2049e6;
  }
`;

export const FrameHead = styled.div`
  margin-bottom: 22px;
  padding: 0 20px;
`;

export const FrameBody = styled.div`
  margin-bottom: 27px;
`;

export const FrameFoot = styled.div`
  text-align: center;
`;

export const FrameTitle = styled.h3`
  margin-bottom: 25px;
  text-align: center;
`;

export const FrameLink = styled.a`
  font-size: 14px;
  line-height: 1.29;
  text-decoration: underline;
  color: #9b9b9b;
  padding: 2px;
  margin-left: -4px;
  margin-top: -4px;
  border: 2px solid transparent;
  border-radius: 4px;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    border: 2px solid #2049e6;
  }
`;

export const FrameAction = styled.div`
  margin-bottom: 22px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default connect(
  (state) => ({
    apiCallInProgress: state.administration.apiCallInProgress,
  }),
  {
    closeModal,
  }
)(ModalFrame);
