import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "ramda";
import { connect } from "react-redux";
import Loader from "components/common/loader";
import IconSvgComponent from "components/common/IconSvgComponent";
import { withRouter } from "react-router-dom";
import {
  ResultsContainer,
  ResultsMedia,
  ResultsContent,
  ResultsSection,
  ResultsSectionTitle,
  ResultsPercentage,
  ResultsScoreTotal,
  ResultsTitle,
  ResultsScore,
  ResultsScoreBackground,
  ResultsCongrats,
  ResultsMeta,
  ResultsMetaTitle,
  ResultsMetaData,
  ResultsTable,
  ResultsThead,
  ResultsTr,
  ResultsTh,
  ResultsTbody,
  ResultsTd,
  ResultsFooter,
} from "styles/components/AssessmentResults";
import ButtonLink from "components/common/ButtonLink";
import { PaginatedTable, NoDataComponent } from "components/common/table";
import { Col } from "styles/components/Grid";
import { FrameBox, FrameTitle } from "styles/components/Frame";
import stemifyRoutes from "constants/routes";
import { ROLES } from "constants/roles";

const AssessmentResults = ({
  name,
  result,
  score,
  questionsCorrect,
  totalQuestions,
  cooldown,
  attemptsRemaining,
  topicInfo,
  apiCallInProgress,
  history,
  isStudent,
  assessmentId,
}) => {
  const pageSize = 7;
  const page = 0;
  const pages = null;
  const count = 0;
  const [table, setTable] = useState({
    pageSize,
    page,
    pages,
    count,
  });
  const { courseId } = useParams();

  const columns = [
    {
      id: "concepts",
      Header: "Concepts",
      minWidth: 280,
      accessor: "topic_name",
      Cell: (props) => {
        const { original } = props.row;

        return (
          <span style={{ fontWeight: 500, wordBreak: "break-word" }}>{original.topic_name}</span>
        );
      },
    },
    {
      id: "currentPerformance",
      Header: "Current Performance",
      minWidth: 160,
      accessor: "percent_score",
      Cell: (props) => {
        const { original } = props.row;

        return (
          <span style={{ fontWeight: 500, wordBreak: "break-word" }}>
            {original.percent_score}%
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    setTable((prevState) => {
      return {
        ...prevState,
        pages: Math.ceil(topicInfo.length / pageSize),
        count: topicInfo.length,
      };
    });
  }, [topicInfo.length]);

  const getPaginatedData = (list) => {
    const startIndex = table.page * table.pageSize;
    const endIndex = startIndex + table.pageSize;
    return list.slice(startIndex, endIndex);
  };

  const handlePreviousButtonClick = () => {
    setTable((prevState) => {
      return {
        ...prevState,
        page: prevState.page - 1,
      };
    });
  };

  const handleNextButtonClick = () => {
    setTable((prevState) => {
      return {
        ...prevState,
        page: prevState.page + 1,
      };
    });
  };

  const scoreBackgroundPath =
    result === "fail" ? "/svg/assessment-fail.svg" : "/svg/assessment-pass.svg";

  const getMessage = () => {
    switch (result) {
      case "fail":
        return "Looks like you have some room for improvement.";
      case "pass":
        if (score === 100) {
          return "Congratulations, you got a perfect score!";
        } else {
          return "Congratulations, you’ve passed the cut off!";
        }
      default:
        return;
    }
  };

  const redirectToNextStep = () => {
    const pathname = !!courseId
      ? `${stemifyRoutes.courses}/${courseId}/coursework`
      : isStudent
      ? `/my-dashboard/${assessmentId}`
      : `/`;

    history.push({ pathname });
  };

  return (
    <ResultsContainer>
      {apiCallInProgress == false && (
        <ResultsMedia>
          <ResultsTitle>Your Score</ResultsTitle>

          <ResultsScoreBackground>
            <img
              src={!courseId ? scoreBackgroundPath : "/svg/exam-score.svg"}
              alt="Background Shapes"
            />
            {/* Passing in result because svgs are different sizes, need to adjust margin/padding */}
            {!courseId ? (
              <ResultsScore result={result}>
                {score}
                <ResultsPercentage>%</ResultsPercentage>
              </ResultsScore>
            ) : (
              <>
                <ResultsScore result={result}>
                  {result.percentage
                    ? result.percentage
                    : score
                    ? score
                    : questionsCorrect && totalQuestions
                    ? Math.round((questionsCorrect / totalQuestions) * 100)
                    : 0}
                  <ResultsPercentage>%</ResultsPercentage>
                </ResultsScore>
                {questionsCorrect && totalQuestions && (
                  <ResultsScoreTotal>
                    {questionsCorrect} of {totalQuestions ? totalQuestions : 0}
                  </ResultsScoreTotal>
                )}
              </>
            )}
          </ResultsScoreBackground>

          <ResultsCongrats>{!courseId ? getMessage() : ""}</ResultsCongrats>
          <ButtonLink type="button" violet onClick={redirectToNextStep}>
            {!courseId ? "View Next Steps" : "Return to Course"}
          </ButtonLink>
        </ResultsMedia>
      )}
      {apiCallInProgress == false && !courseId && (
        <ResultsContent>
          <ResultsSection>
            <ResultsMeta>
              <ResultsMetaTitle>Cool Down Period:</ResultsMetaTitle>

              <ResultsMetaData>
                {`${cooldown} ${cooldown === "Not Available" ? "" : "Days"}`}
                <IconSvgComponent iconPath="svg/ico-clock.svg" />
              </ResultsMetaData>
            </ResultsMeta>

            <ResultsMeta>
              <ResultsMetaTitle>Attempts Remaining:</ResultsMetaTitle>

              <ResultsMetaData>
                {attemptsRemaining}
                <IconSvgComponent iconPath="svg/ico-upload-blue.svg" />
              </ResultsMetaData>
            </ResultsMeta>
          </ResultsSection>

          {/* If it isn't a perfect score show topics for improvement */}
          {!(score?.length < 2) && (
            <Col noPadding flex>
              <FrameBox horzontalPadding>
                <FrameTitle marginleft>Concepts to Work On</FrameTitle>
                <PaginatedTable
                  data={getPaginatedData(topicInfo)}
                  columns={columns}
                  count={table.count}
                  isLoading={false}
                  NoDataComponent={() => <NoDataComponent type="Topic" />}
                  style={{ minWidth: "100%" }} // override default table styles
                />
              </FrameBox>
            </Col>
          )}
        </ResultsContent>
      )}
    </ResultsContainer>
  );
};

export default withRouter(
  connect((state, ownProps) => {
    const { remainingAttempts } = state.assessment;
    return {
      result: state.assessment.attemptResult || {},
      attemptsRemaining:
        remainingAttempts === 0 ? remainingAttempts : remainingAttempts || "Not Available",
      isStudent: state.user.role === ROLES.STUDENT,
      assessmentId: state.assessment.assessmentId,
    };
  }, {})(AssessmentResults)
);
