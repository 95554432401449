import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentTitle from "react-document-title";
import { useMediaQuery } from "react-responsive";

import { PaginatedTable, NoDataComponent } from "components/common/table";
import { MODAL_ACTIVATION, MODAL_CREATE_ACCOUNT } from "lib/constants";
import { openModal } from "store/state/ui/actions";
import { getAccounts } from "store/state/administration/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import SectionTable, { SectionBody } from "styles/components/SectionTable";
import { StyledSpan } from "styles/components/TableDefault";
import stemifyRoutes from "../../constants/routes";
import Popover from "components/common/Popover";

const Accounts = (props) => {
  const dispatch = useDispatch();

  const accountId = useSelector((state) =>
    !!state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId
  );
  const accounts = useSelector((state) => state.administration.accounts);
  const accountsTable = useSelector((state) => state.administration.accountsTable);
  const { count } = accountsTable;

  const [isPageLoading, setPageLoading] = useState(true);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const editAccount = useCallback(
    (account, page) => {
      return (e) => {
        dispatch(
          openModal({
            type: MODAL_CREATE_ACCOUNT,
            data: {
              id: account.id,
              editItem: {
                organization: account.organization,
                contactName: account.contactName,
                contactEmail: account.contactEmail,
                contactPhone: account.contactPhone,
                address1: account.address1,
                address2: account.address2,
                city: account.city,
                state: account.state && account.state.value === "" ? "" : account.state,
                userLimit: account.userLimit,
                status: account.status,
                id: account.id,
                page,
              },
            },
          })
        );
      };
    },
    [dispatch]
  );

  const updateAccountStatus = useCallback(
    (account, page) => {
      return (e) => {
        dispatch(
          openModal({
            type: MODAL_ACTIVATION,
            data: {
              id: account.id,
              accountId,
              status: account.status,
              title: account.organization,
              type: "accounts",
            },
          })
        );
      };
    },
    [accountId, dispatch]
  );

  const fetchData = useCallback(async () => {
    setPageLoading(true);

    try {
      await dispatch(
        getAccounts({
          selector: "accounts",
          accountId: accountId === "1" ? null : accountId,
          pageSize: 1000,
          page: 1,
        })
      );
      setPageLoading(false);
    } catch (error) {
      console.log({ error });
      setPageLoading(false);
    }
  }, [accountId, dispatch]);

  const columns = useMemo(() => {
    const mobileColumns = ["name", "status", "dropdown"];

    return [
      {
        Header: "Organization",
        accessor: "organization",
        minWidth: 150,
        Cell: (props) => {
          return (
            <ButtonLink
              isRouterLink
              noLeftPadding
              to={`${stemifyRoutes.accounts}/${props.row.original.id}`}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <IconSvgComponent iconPath="svg/ico-building-small.svg" />
              <StyledSpan
                hasIconOnLeft
                bold
                style={{
                  width: "fit-content",
                  whiteSpace: "nowrap",
                  overflowX: "clip",
                  textOverflow: "ellipsis",
                }}
              >
                {props.value}
              </StyledSpan>
            </ButtonLink>
          );
        },
      },
      {
        accessor: "contactName",
        Header: "Contact Name",
        minWidth: 150,
      },
      {
        accessor: "contactEmail",
        Header: "Contact Email",
        minWidth: 200,
      },
      {
        accessor: "contactPhone",
        Header: "Contact Phone",
        minWidth: 100,
      },
      {
        accessor: "userLimit",
        Header: "User Limit",
        minWidth: 50,
      },
      {
        accessor: "activeUsers",
        Header: "Active Users",
        minWidth: 50,
      },
      {
        accessor: "status",
        Header: "Status",
        minWidth: 50,
        Cell: (props) => {
          const iconPath =
            props.value === "active" ? "svg/ico-success.svg" : "svg/ico-not-active.svg";

          return <IconSvgComponent iconPath={iconPath} style={{ width: "fit-content" }} />;
        },
      },
      {
        accessor: "dropdown",
        Header: "",
        minWidth: 75,
        style: {
          display: "flex",
          justifyContent: "flex-end",
        },
        Cell: (props) => {
          //   if (this.props.usersLimitReached && this.props.status === "not-active") {
          //     this.props.openModal({
          //       type: MODAL_USER_LIMIT_REACHED,
          //     });
          //   } else {

          const {
            yPositionStart,
            tableBodyRef,
            row: { original: account },
          } = props;

          return (
            <Popover
              placement="bottom-start"
              portalRef={tableBodyRef}
              yPositionStart={yPositionStart}
              TriggerComponent={(props) => {
                const { setRef, ...triggerProps } = props;

                return (
                  <ButtonLink setRef={setRef} {...triggerProps} isTableDropdownButton>
                    <span />
                    <span />
                    <span />
                  </ButtonLink>
                );
              }}
              actions={[
                {
                  name: "Edit",
                  onClick: editAccount(props.row.original),
                },
                {
                  name: account.status === "active" ? "Deactivate" : "Activate",
                  onClick: updateAccountStatus(props.row.original),
                },
              ]}
            />
          );
        },
      },
    ].filter((column) => {
      if (isMobile) {
        return mobileColumns.includes(column.id);
      }

      return column;
    });
  }, [editAccount, isMobile, updateAccountStatus]);

  return (
    <DocumentTitle title="Stemify | Administration">
      <SectionTable>
        <ButtonLink
          className="button-add"
          onClick={() => dispatch(openModal({ type: MODAL_CREATE_ACCOUNT }))}
          violet
          fab
          size="43"
        />

        <SectionBody>
          <PaginatedTable
            data={accounts}
            columns={columns}
            fetchData={fetchData}
            serverSidePagination={false}
            count={count}
            isLoading={isPageLoading}
            NoDataComponent={() => (
              <div style={{ height: "487px", padding: "65px 24px" }}>
                <NoDataComponent
                  type="Account"
                  iconPath="svg/ico-building.svg"
                  onClickAction={() => openModal({ type: MODAL_CREATE_ACCOUNT })}
                />
              </div>
            )}
          />
        </SectionBody>
      </SectionTable>
    </DocumentTitle>
  );
};

export default Accounts;
