import React, { useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useHover,
  safePolygon,
  FloatingPortal,
} from "@floating-ui/react";

import styled from "styled-components";

export const MenuContainer = styled.div`
  min-width: 160px;
  width: max-content;
  border: 1px solid #dddbda;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;

export const MenuItem = styled.div`
  text-align: left;
  padding: 12px 18px;
  font-size: 14px;
  color: #080707;
  background-color: #ffffff;
  cursor: pointer;

  :not(:last-child) {
    border-bottom: 1px solid #d8d8d8;
  }

  :hover {
    background-color: #f5f2fc;
  }
`;

const Popover = ({
  TriggerComponent,
  actions,
  placement = "top",
  strategy = "absolute",
  offset: offsetValue = 0,
  portalRef = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    strategy,
    placement,
    middleware: [offset(offsetValue), flip({ fallbackAxisSideDirection: "end" }), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const hover = useHover(context, {
    enabled: isOpen, // force click to open the popover
    move: isOpen,
    handleClose: safePolygon({
      requireIntent: false,
      buffer: 1,
    }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role, hover]);

  return (
    <>
      <TriggerComponent setRef={refs.setReference} {...getReferenceProps()} />

      {portalRef.current && isOpen && (
        <FloatingPortal root={portalRef}>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={{ ...floatingStyles, zIndex: 100 }}
              {...getFloatingProps()}
            >
              <MenuContainer>
                {actions.map((action) => {
                  return (
                    <MenuItem
                      key={action.name}
                      data-cy={action.dataCy}
                      onClickCapture={(event) => action.onClick(event, setIsOpen)}
                    >
                      {action.name}
                    </MenuItem>
                  );
                })}
              </MenuContainer>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export default Popover;
