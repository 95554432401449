export const ROLES = {
  STUDENT: "STUDENT",
  INSTRUCTOR: "INSTRUCTOR",
  ACCOUNT_ADMIN: "ACCOUNT_ADMIN",
  MASTER_ADMIN: "MASTER_ADMIN",
};

export const ROLE_RULES = {
  STUDENT: {
    static: [
      "home-page:view",
      "assessment-dashboard-student:view",
      "learning-library-page:view",
      "placement-assessment:view",
      "learning-module-video:trackProgress",
      "practice-assignment:view",
      "student-home:view",
      "courses:view",
      "student-menu:view",
    ],
  },
  INSTRUCTOR: {
    static: [
      "home-page:view",
      "assessment-dashboard-student:view",
      "learning-library-page:view",
      "learning-module-video:trackProgress",
      "learning-module-video:seek",
      "practice-assignment:view",
      "cohorts-page:view",
      "deployments:view",
      "faculty-home:view",
      "courses:view",
      "non-student-menu:view",
      "courses-student-tab-grade:view",
      "courses-people:add",
      "courses-people:remove",
      "courses-dashboard:view",
    ],
  },
  ACCOUNT_ADMIN: {
    static: [
      "assessment-dashboard-student:view",
      "home-page:view",
      "learning-library-page:view",
      "cohorts-page:view",
      "cohorts-page:create",
      "users-page:view",
      "placement-assessment:view",
      "learning-module-video:trackProgress",
      "learning-module-video:seek",
      "deployments:view",
      "deployments:create",
      "deployments:edit",
      "practice-assignment:view",
      "faculty-home:view",
      "courses:view",
      "non-student-menu:view",
      "courses-student-tab-grade:view",
      "courses-people:add",
      "courses-people:remove",
      "courses-dashboard:view",
    ],
  },
  MASTER_ADMIN: {
    static: [
      "assessment-dashboard-student:view",
      "home-page:view",
      "learning-library-page:view",
      "cohorts-page:view",
      "cohorts-page:create",
      "accounts-page:view",
      "users-page:view",
      "placement-assessment:view",
      "learning-module-video:seek",
      "deployments:view",
      "deployments:create",
      "deployments:edit",
      "practice-assignment:view",
      "faculty-home:view",
      "courses:view",
      "non-student-menu:view",
      "courses-student-tab-grade:view",
      "courses-people:add",
      "courses-people:remove",
      "courses-dashboard:view",
      "admin:view",
    ],
  },
};
