import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import DocumentTitle from "react-document-title";
import moment from "moment";
import { connect } from "react-redux";
import { isEmpty } from "ramda";
import { ASSIGNMENT_TYPES } from "constants/common";

import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";

import CourseItemUnit from "components/courses/CourseItemUnit";
import CourseSubnav from "components/courses/CourseSubnav";

import ModalCourseAssignmentUnit from "components/courses/ModalCourseAssignmentUnit";
import ModalCourseAssignmentCreate from "components/courses/ModalCourseAssignmentCreate";
import ModalCoursesAddToAssignment from "components/courses/ModalCoursesAddToAssignment";
import ModalCourseAddAssessments from "components/courses/ModalCoursesAddAssessments";
import ModalCourseAddOpenStax from "components/courses/ModalCoursesAddOpenStax";
import { resetAsset } from "store/state/courses/actions";
import Loader from "components/common/loader";

import List, { ListItem } from "styles/components/List";
import Courses, { Course } from "styles/components/Courses";

import CourseDetailsBlock, {
  CourseDetailsAside,
  CourseDetailsContent,
  CourseDetailsTitle,
  CourseDetailsUnitsList,
  CourseDetailsUnitsListTitle,
  CourseDetailsUnitsListItem,
  CourseDetailsUnitBlock,
  CourseDetailsUnitBlockNotice,
} from "styles/components/CourseDetails";

import CourseTabs, { CourseTab, CourseTabsContent } from "styles/components/CourseTabs";

import { MODAL_CREATE_COURSE_UNIT, MODAL_CONFIRMATION } from "lib/constants";

import { openModal, closeModal } from "store/state/ui/actions";
import { OPERATIONS } from "constants/common";

import {
  setActiveCourse,
  deleteUnit,
  deleteCourseUnit,
  createAssignment,
  updateAssignment,
  getSectionById,
  getUnitsBySectionId,
  getAssignments,
  getSchedules,
  getSectionGradesByAssignment,
  resetPrevAssignmentCreationError,
  decorateSectionResponse,
  getAssignmentAttempts,
  getAssessments,
} from "store/state/courses/actions";
import { ROLES } from "constants/roles";
import { COMPARE_TYPES, multiSort } from "utilities/commonFunctions";

class CourseDetails extends Component {
  state = {
    activeUnitId: null,
    showAssignmentTypeModal: false,
    showAssignmentPracticeModal: false,
    showVideoAssetsModal: false,
    showAddAssessmentAssetsModal: false,
    showAddOpenStaxAssetsModal: false,
    assignmentType: "",
    visibleUnitId: "all",
    isEditMode: false,
    activeAssignmentId: "",
    dataLoaded: false,
  };

  handleClickCreateAssignment = (unit) => {
    this.setState({
      activeUnitId: unit.id,
      showAssignmentTypeModal: true,
    });

    this.refreshMuuriComponent();
  };

  closeCourseAssignmentModal = () => {
    this.props.resetPrevAssignmentCreationError({
      selector: "assignments",
      operation: OPERATIONS.CREATE,
    });
    this.setState({
      showAssignmentTypeModal: false,
      showAssignmentPracticeModal: false,
      showVideoAssetsModal: false,
      showAddAssessmentAssetsModal: false,
      assignmentType: "",
      activeAssignmentId: "",
      activeUnitId: null,
      isEditMode: false,
    });

    this.props.resetAsset();
    this.refreshMuuriComponent();
  };

  setSelectedAssignmentType = (type) => {
    this.setState({
      assignmentType: type,
      showAssignmentTypeModal: false,
      showAssignmentPracticeModal: true,
    });
  };

  handleCreateAssignment = async (formData, onSuccess) => {
    const { activeUnitId, activeAssignmentId } = this.state;
    const { createAssignment, updateAssignment, resetAsset } = this.props;

    const isSuccess = !this.state.isEditMode
      ? await createAssignment({
          formData,
          activeUnitId,
        })
      : await updateAssignment({
          formData,
          activeUnitId,
          activeAssignmentId,
        });

    if (isSuccess) {
      this.closeCourseAssignmentModal();
      resetAsset();
    }
  };

  openAddVideoAssetsModal = () => {
    this.setState({
      showAssignmentTypeModal: false,
      showAssignmentPracticeModal: false,
      showVideoAssetsModal: true,
      showAddAssessmentAssetsModal: false,
      showAddOpenStaxAssetsModal: false,
    });
  };

  openAddOpenStaxAssetsModal = () => {
    this.setState({
      showAssignmentTypeModal: false,
      showAssignmentPracticeModal: false,
      showVideoAssetsModal: false,
      showAddAssessmentAssetsModal: false,
      showAddOpenStaxAssetsModal: true,
    });
  };

  openAddAssessmentAssetsModal = async () => {
    await this.props.getAssessments();
    this.setState({
      showAssignmentTypeModal: false,
      showAssignmentPracticeModal: false,
      showVideoAssetsModal: false,
      showAddAssessmentAssetsModal: true,
      showAddOpenStaxAssetsModal: false,
    });
  };

  closeAddAssetsModal = () => {
    this.setState({
      showAssignmentPracticeModal: true,
      showVideoAssetsModal: false,
      showAddAssessmentAssetsModal: false,
      showAddOpenStaxAssetsModal: false,
    });

    this.refreshMuuriComponent();
  };

  openAssignmentAssetsModal = () => {
    const { assignmentType } = this.state;
    switch (assignmentType) {
      case ASSIGNMENT_TYPES.VIDEO:
        this.openAddVideoAssetsModal();
        break;
      case ASSIGNMENT_TYPES.PRACTICE:
        this.openAddAssessmentAssetsModal();
        break;
      case ASSIGNMENT_TYPES.EXAM:
        this.openAddAssessmentAssetsModal();
        break;
      case ASSIGNMENT_TYPES.OPENSTAX:
        // TODO
        this.openAddOpenStaxAssetsModal();
        break;
      default:
        break;
    }
  };

  setVisibleUnitId = (id) => {
    this.setState({
      visibleUnitId: id,
    });
  };

  refreshMuuriComponent = () => {
    // Dispatch resize for Drag&Drop component to refresh
    window.dispatchEvent(new Event("resize"));
  };

  componentDidMount() {
    const { courseId } = this.props.match.params;
    const activeCourse = this.props.courses.filter((course) => course.id == courseId);
    activeCourse.length > 0 && this.props.setActiveCourse(activeCourse[0]);
    this.getSectionDetailsData();
  }

  getSectionDetailsData = async () => {
    const { courseId } = this.props.match.params;
    const {
      getSectionById,
      getUnitsBySectionId,
      getAssignments,
      getSchedules,
      getSectionGradesByAssignment,
      setActiveCourse,
      accountId,
      getAssignmentAttempts,
    } = this.props;

    try {
      const { data: sectionResponse } = await getSectionById({
        accountId,
        courseId,
        selector: "sections",
      });
      setActiveCourse(decorateSectionResponse(sectionResponse));
      const unitsResponse = await getUnitsBySectionId({ accountId, courseId, selector: "units" });
      const assignmentsResponse = await getAssignments({ accountId, sectionIds: [courseId] });
      const assignmentIds = assignmentsResponse.data
        .filter((assignment) => assignment.assignment_type === ASSIGNMENT_TYPES.EXAM)
        .map((assignment) => assignment.id);
      const attemptsResponse = await getAssignmentAttempts({ accountId, assignmentIds });
      const schedulesResponse = await getSchedules({
        accountId,
        sectionIds: [courseId],
        selector: "assignments",
      });
      const gradesResponse = await getSectionGradesByAssignment({ sectionId: courseId });

      this.setState({
        dataLoaded: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  renderFilteredItems = (units) => {
    const { courseId } = this.props.match.params;

    const { assignments, user } = this.props;
    const filteredUnits = units.filter((unit) => unit.id == this.state.visibleUnitId) || units;
    const renderedUnits = filteredUnits.length ? filteredUnits : units;

    const renderedItems = renderedUnits
      .filter((unit) => user.role !== ROLES.STUDENT || moment(unit.startDate).isBefore(moment()))
      .map((unit) => {
        const filteredAssignments = assignments.filter((a) => unit.id === a.unitId);

        return (
          <CourseItemUnit
            key={unit.id}
            unit={unit}
            assignments={filteredAssignments}
            handleCreateAssignment={this.handleClickCreateAssignment}
            onEditAssignment={(type, assignmentId) =>
              this.handleUnitAssignmentEdit(unit, type, assignmentId)
            }
          />
        );
      });

    return renderedItems;
  };

  handleUnitAssignmentEdit = (unit, type, assignmentId) => {
    this.setState({
      activeUnitId: unit.id,
      isEditMode: true,
      activeAssignmentId: assignmentId,
    });
    this.setSelectedAssignmentType(type);
    this.refreshMuuriComponent();
  };

  render() {
    const { openModal, courses, match, units, user } = this.props;
    const { isEditMode, activeAssignmentId, activeUnitId } = this.state;
    const { courseId } = match.params;
    const {
      showAssignmentTypeModal,
      showAssignmentPracticeModal,
      showVideoAssetsModal,
      showAddAssessmentAssetsModal,
      showAddOpenStaxAssetsModal,
      visibleUnitId,
      dataLoaded,
    } = this.state;
    const currentCourse = courses.find((item) => item.id == courseId);

    if (dataLoaded && !currentCourse) {
      this.props.history.replace("/courses");
      return null;
    }

    return (
      <DocumentTitle title="Stemify | Course">
        <CourseTabs>
          <CourseSubnav />
          {!dataLoaded ? (
            <Loader />
          ) : (
            <CourseTabsContent>
              <CourseTab active={true}>
                <CourseDetailsBlock>
                  <CourseDetailsAside>
                    <CourseDetailsTitle>{currentCourse.name}</CourseDetailsTitle>
                    <p>{currentCourse.courseNumber}</p>

                    <CourseDetailsUnitsList>
                      <CourseDetailsUnitsListTitle
                        onClick={() => this.setVisibleUnitId("all")}
                        active={visibleUnitId === "all"}
                      >
                        All Units
                      </CourseDetailsUnitsListTitle>

                      {units &&
                        units
                          .filter(
                            (unit) =>
                              user.role !== ROLES.STUDENT ||
                              moment(unit.startDate).isBefore(moment())
                          )
                          .map((unit) => (
                            <CourseDetailsUnitsListItem
                              key={unit.id}
                              onClick={() => this.setVisibleUnitId(unit.id)}
                              active={visibleUnitId === unit.id}
                            >
                              {unit.name}
                            </CourseDetailsUnitsListItem>
                          ))}
                      {user.role !== ROLES.STUDENT && (
                        <ButtonLink
                          isUnstyledButton
                          className="btn-add-unit-item"
                          onClick={() =>
                            openModal({ type: MODAL_CREATE_COURSE_UNIT, section: currentCourse })
                          }
                        >
                          <span>Create Unit</span>

                          <IconSvgComponent
                            iconPath={`svg/ico-plus.svg`}
                            additionalClass={"icon-add"}
                          />
                        </ButtonLink>
                      )}
                    </CourseDetailsUnitsList>
                  </CourseDetailsAside>

                  <CourseDetailsContent>
                    {units.length > 0 ? (
                      this.renderFilteredItems(units)
                    ) : user.role === ROLES.STUDENT ? (
                      <CourseDetailsUnitBlock isEmpty>
                        <CourseDetailsUnitBlockNotice>
                          You have no assigned coursework at this time. Check back later!
                        </CourseDetailsUnitBlockNotice>
                      </CourseDetailsUnitBlock>
                    ) : (
                      <CourseDetailsUnitBlock isEmpty>
                        <CourseDetailsUnitBlockNotice>
                          <IconSvgComponent iconPath={`svg/ico-folded.svg`} />
                          Create assignments and questions
                        </CourseDetailsUnitBlockNotice>

                        <CourseDetailsUnitBlockNotice>
                          <IconSvgComponent iconPath={`svg/ico-unit-blank.svg`} />
                          Use units to organize coursework
                        </CourseDetailsUnitBlockNotice>

                        <CourseDetailsUnitBlockNotice>
                          <IconSvgComponent iconPath={`svg/ico-swap.svg`} />
                          Order work the way you want students to see it
                        </CourseDetailsUnitBlockNotice>
                      </CourseDetailsUnitBlock>
                    )}

                    {user.role !== ROLES.STUDENT && (
                      <ButtonLink
                        isUnstyledButton
                        className="btn-add-unit-block with-shadow"
                        onClick={() => openModal({ type: MODAL_CREATE_COURSE_UNIT })}
                      >
                        <span>Create Unit</span>

                        <IconSvgComponent
                          iconPath={`svg/ico-plus.svg`}
                          additionalClass={"icon-add"}
                        />
                      </ButtonLink>
                    )}
                  </CourseDetailsContent>
                </CourseDetailsBlock>
              </CourseTab>
            </CourseTabsContent>
          )}
          <ModalCourseAssignmentUnit
            active={showAssignmentTypeModal}
            onClose={() => this.closeCourseAssignmentModal()}
            onSelect={(type) => this.setSelectedAssignmentType(type)}
          />
          <ModalCourseAssignmentCreate
            isOpen={showAssignmentPracticeModal}
            onClose={this.closeCourseAssignmentModal}
            assignmentType={this.state.assignmentType}
            onSubmit={this.handleCreateAssignment}
            onAdd={() => this.openAssignmentAssetsModal()}
            isEditMode={isEditMode}
            activeAssignmentId={activeAssignmentId}
            activeUnitId={activeUnitId}
          />

          <ModalCoursesAddToAssignment
            active={showVideoAssetsModal}
            onClose={this.closeAddAssetsModal}
          />

          <ModalCourseAddAssessments
            assignmentType={this.state.assignmentType}
            active={showAddAssessmentAssetsModal}
            onClose={this.closeAddAssetsModal}
          />

          <ModalCourseAddOpenStax
            active={showAddOpenStaxAssetsModal}
            onClose={this.closeAddAssetsModal}
          />
        </CourseTabs>
      </DocumentTitle>
    );
  }
}

function getSectionUnits(state) {
  const filteredUnits = Object.values(state.storedCourses.units).filter(
    (unit) => unit.sectionId === state.storedCourses.activeCourseId
  );
  const sortedUnits = multiSort({
    items: filteredUnits,
    operations: [
      { type: COMPARE_TYPES.DATE, order: "asc", key: "endDate" },
      { type: COMPARE_TYPES.DATE, order: "asc", key: "startDate" },
      { type: COMPARE_TYPES.DATE, order: "asc", key: "createdOn" },
      { type: COMPARE_TYPES.ALPHABETICAL, order: "asc", key: "name" },
    ],
  });

  return sortedUnits;
}

export default connect(
  (state, ownProps) => {
    const user = state.user;
    const schedulesArray = Object.values(state.storedCourses.schedules);
    const attemptsForCurrentUser = Object.values(state.storedCourses.attempts).filter(
      (attempt) => user.username === attempt.username
    );
    const attemptsByAssignmentId = attemptsForCurrentUser.reduce((attempts, currentAttempt) => {
      const existingCount = attempts[currentAttempt.assignmentId] || 0;
      return {
        ...attempts,
        [currentAttempt.assignmentId]: existingCount + 1,
      };
    }, {});
    const assignments = Object.values(state.storedCourses.assignments).map((assignment) => {
      const schedule = schedulesArray.find((s) => s.assignmentId === assignment.id);
      const attemptCount = attemptsByAssignmentId[assignment.id] || null;

      return {
        ...assignment,
        startDate: schedule ? schedule.startDate : "",
        endDate: schedule ? schedule.endDate : "",
        startTs: schedule ? schedule.startTs : "",
        endTs: schedule ? schedule.endTs : "",
        attemptCount,
      };
    });

    return {
      modal: state.ui.modal,
      userRole: state.user.role,
      accountId: state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId,
      apiCallInProgress: state.storedCourses.courseDetailsAPICallInProgress,
      courses: Object.values(state.storedCourses.sections),
      units: getSectionUnits(state, ownProps),
      assignments,
      user,
    };
  },
  {
    openModal,
    closeModal,
    setActiveCourse,
    deleteUnit,
    createAssignment,
    updateAssignment,
    getSectionById,
    getUnitsBySectionId,
    getAssignments,
    getAssignmentAttempts,
    getSchedules,
    getSectionGradesByAssignment,
    resetAsset,
    resetPrevAssignmentCreationError,
    getAssessments,
  }
)(CourseDetails);
