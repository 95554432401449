import React from "react";
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  Question,
  QuestionIcon,
  QuestionIconMeta,
  QuestionText,
  Circle,
} from "styles/components/NavigationQuestion";

const NavigationQuestion = ({ type, text, attempts, current, onClick }) => {
  const renderIcon = () => {
    switch (type) {
      case "perfect":
        return <IconSvgComponent additionalClass="perfect" iconPath="svg/ico-perfect-star.svg" />;
      case "correct":
        return <IconSvgComponent additionalClass="correct" iconPath="svg/ico-correct.svg" />;
      case "incorrect":
        return <IconSvgComponent additionalClass="incorrect" iconPath="svg/ico-incorrect.svg" />;
      case "unanswered":
        return <Circle dashed />;
      default:
        return <Circle />;
    }
  };

  return (
    <Question current={current} onClick={onClick}>
      <QuestionIcon>
        {renderIcon()}

        {attempts !== 0 && <QuestionIconMeta>{attempts}</QuestionIconMeta>}
      </QuestionIcon>

      <QuestionText>{text}</QuestionText>
    </Question>
  );
};

export default NavigationQuestion;
