/**
 * The external dependencies.
 */
import Slider from "react-slick";
import styled from "styled-components";
import { Link } from "react-router-dom";
import theme from "styles/theme";
/**
 * The internal dependencies.
 */
import { media } from "styles/media";

export const SlickSlider = styled(Slider).attrs({
  id: "slickSlider",
})`
  .slick-track {
    display: flex;
    margin-left: 0;
    margin-right: 0;

    .slick-slide {
      display: flex;
      height: auto;

      & > div {
        display: flex;
        flex: 1 1 100%;
        width: 100%;
      }
    }
  }

  ${media.tablet_portrait`
		.slick-track {
			display: block;
		}

		.slick-slide {
			display: block;
			width: 100%;

			& > div {
				display: block;
				width: 100%;
			}
		}
	`}
`;

const Section = styled.div`
  padding: 25px 35px;

  ${media.mobile`
		overflow: hidden;
		padding: 25px 15px;
	`}
`;
Section.Head = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${media.mobile`
		display: block;
		text-align: center;
		margin-bottom: 25px;
	`}
`;
Section.HeadContent = styled.div`
  ${media.mobile`
		margin-bottom: 20px;
	`}
`;
Section.HeadActions = styled.div``;
Section.Title = styled.h1.attrs({
  id: "title",
})`
  margin-bottom: 3px;
  font-size: 25px;
  line-height: 1;
  font-weight: 600;
  cursor: pointer;
`;
Section.Entry = styled.div`
  font-size: 17px;
`;
Section.Body = styled.div.attrs({
  id: (props) => props.id,
})`
	width: 100%:
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -30px;

  ${media.mobile`
		max-width: 100%;
		flex: 1 1 100%;
		margin: 0 0 -30px;
	`}
`;
Grid.Col = styled.div`
	max-width: 100%;
	flex: 1 1 100%;
	padding: 0 15px 30px;

	${(props) =>
    props.size &&
    `
		max-width: ${props.size}%;
		flex: 0 0 ${props.size}%;
	`}

	${media.tablet_portrait`
		max-width: 50%;
		flex: 1 1 50%;
	`}

	${media.mobile`
		max-width: 100%;
		flex: 1 1 100%;
		padding: 0 0 30px;
	`}
`;

export const Button = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 23px;
  background: #ff3e1d;
  border: 0 none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 21px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  transition: background 0.2s;
  text-decoration: inherit;
  &:hover {
    background: rgba(255, 62, 29, 0.8);
  }

  span {
    margin-right: 13px;
  }
`;

export const Card = styled.div`
	display: flex !important;
	flex-direction: column;
	margin-bottom: 34px;
	padding: 12px 22px;
	background: #fff;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04);
  border-radius: ${theme.border_radius};
  overflow: hidden;

	&:last-child {
		margin-bottom: 0;
	}

	table tbody td {
		padding: 10px 20px 10px 27px !important;
	}

    ${(props) =>
      props.textCentered &&
      `
        text-align: center;
    `}

	${(props) =>
    props.large &&
    `
		padding: 24px 30px 30px 30px;
	`}

	${(props) =>
    props.minHeight &&
    `
		min-height: ${props.minHeight}px;
	`}

	${(props) =>
    props.noPadding &&
    `
		padding: 0;
	`}

	${(props) =>
    props.tableContainer &&
    `
		padding: 12px 0;
	`}

	${media.tablet_portrait`
		${(props) =>
      props.small &&
      `
			width: 100%;
		`}
	`}
`;
Card.Inner = styled.div`
  margin-bottom: 10px;
`;
Card.Head = styled.header`
  flex: 0 0 100%;

  ${(props) =>
    props.tableContainer &&
    `
		padding: 0 22px;
	`}
`;
Card.Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
`;
Card.Icon = styled.div`
  margin-bottom: 12px;
`;
Card.LargeTitle = styled.h1`
  margin-bottom: 18px;
  font-size: 40px;
  line-height: 1.13;
  font-weight: 700;

  ${media.tablet_portrait`
		font-size: 27px;
	`}
`;
Card.SubTitle = styled.h5`
  margin-bottom: 15px !important;
  font-size: 20px;
  line-height: 1.05;
  letter-spacing: -0.012em;

  ${(props) =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth}px;
  `}

  ${(props) =>
    props.centered &&
    `
    text-align: center;
    margin: 0 auto;
  `}
`;
Card.Body = styled.div`
  flex: 1 1 100%;

  ${(props) =>
    props.flexCenteredColumn &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}

  ${(props) =>
    props.flexCentered &&
    `
		display: flex;
		justify-content: center;
		align-items: center;
	`}
`;
Card.Image = styled.figure`
  min-height: 173px;
  background-color: #000;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;
Card.Content = styled.div`
  padding: 14px 22px 20px 22px;
`;
Card.Entry = styled.div`
  margin-bottom: 18px;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: -0.011em;
  color: #4a4a4a;

  ${(props) =>
    props.marginBottom &&
    `
		margin-bottom: ${props.marginBottom}px;
	`}
`;
Card.Actions = styled.div`
  ${(props) =>
    props.alignText &&
    `
		text-align: ${props.alignText}
	`}
`;
Card.Link = styled(Link)`
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  text-decoration: none;
  color: #2049e6;
`;
Card.Foot = styled.footer`
  font-size: 15px;
`;

export default Section;
