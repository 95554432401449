/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import { media } from "../media";
import theme from "styles/theme";
import { Link } from "react-router-dom";

const SubjectCard = styled.div.attrs({
  className: "subject-card",
})`
  color: ${theme.color.darker_gray};
  display: flex;
`;

export const SubjectBackLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  text-decoration: none;

  .svg-icon {
    font-size: 0;
    line-height: 1;
    display: inline-block;
    margin-right: 8px;

    g,
    mask,
    use {
      fill: currentColor;
    }
  }
`;

export const SubjectCardRoute = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${theme.color.white};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 1px 12px 6px rgba(0, 0, 0, 0.25);
  }
`;

export const SubjectCardTitle = styled.h2.attrs({
  id: (props) => props.id,
})`
  margin: 0 0 5px;
  line-height: 1.07;
  font-size: 16px;
`;

export const SubjectCardMedia = styled.div`
  position: relative;
`;

export const SubjectCardBadge = styled.div`
  position: absolute;
  top: 6px;
  left: 8px;
  border-radius: 8px;
  background: ${theme.color.red};
  color: ${theme.color.white}
  font-size: 14px;
  font-weight: 700;
  padding: 0 5px;
  z-index: 1;
`;

export const SubjectCardProgress = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #e3ebfa;
  height: 7px;
`;

export const SubjectCardProgressIndicator = styled.div.attrs({
  className: "subject-progress",
})`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${(props) => props.value}%;
  background: ${theme.color.red};
  transition: width 0.5s;
`;

export const SubjectCardImage = styled.div`
  background-size: cover;
  background-image: url(${(props) => props.image});
  height: 170px;
`;

export const SubjectCardContent = styled.div`
  padding: 10px 22px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${media.mobile`
    padding: 10px;
  `};
`;

export const SubjectCardEntry = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;

  .svg-icon {
    margin-right: 7px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const SubjectCards = styled.div.attrs({
  id: (props) => props.id,
})`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -30px;

  ${media.mobile`
    margin: 0 -10px -30px;
  `};

  ${SubjectCard} {
    width: calc(25% - 30px);
    margin: 0 15px 30px;

    ${media.tablet_portrait`
      width: calc(33.33% - 30px);
    `};

    ${media.mobile`
      width: calc(50% - 20px);
      margin: 0 10px 20px;
    `};
  }
`;

export default SubjectCard;
