/**
 * @ External Dependencies
 */
import { createAction } from "redux-actions";

/**
 * @ Action creators
 */
export const createBuilderAssessment = createAction("CREATE_BUILDER_ASSESSMENT");
export const updateBuilderAssessment = createAction("UPDATE_BUILDER_ASSESSMENT");
export const setActiveBuilderAssessment = createAction("SET_ACTIVE_BUILDER_ASSESSMENT");
export const deleteBuilderAssessment = createAction("DELETE_BUILDER_ASSESSMENT");

export const createBuilderQuestion = createAction("CREATE_BUILDER_QUESTION");
export const setActiveBuilderQuestion = createAction("SET_ACTIVE_BUILDER_QUESTION");
export const updateActiveBuilderAssessmentQuestionsOrder = createAction(
  "UPDATE_ACTIVE_BUILDER_ASSESSMENT_QUESTIONS_ORDER"
);
export const updateActiveQuestion = createAction("UPDATE_ACTIVE_QUESTION");
export const deleteBuilderQuestion = createAction("DELETE_BUILDER_QUESTION");

export const createBuilderAnswer = createAction("CREATE_BUILDER_ANSWER");
export const deleteBuilderAnswer = createAction("DELETE_BUILDER_ANSWER");
export const updateBuilderAnswer = createAction("UPDATE_BUILDER_ANSWER");
export const updateBuilderAnswersOrder = createAction(
  "UPDATE_ACTIVE_BUILDER_QUESTION_ANSWERS_ORDER"
);

export const addImageToFolder = createAction("ADD_IMAGE_TO_FOLDER");
export const setActiveImageFolder = createAction("SET_ACTIVE_IMAGE_FOLDER");
export const toggleActiveImageFile = createAction("TOGGLE_ACTIVE_IMAGE_FILE");
export const moveFileToFolder = createAction("MOVE_FILE_TO_FOLDER");
export const deleteFileFromFolder = createAction("DELETE_FILE_FROM_FOLDER");
export const updateFileName = createAction("UPDATE_FILE_NAME");
