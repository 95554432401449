/**
 * @ External Dependecies
 */
import styled from "styled-components";

/**
 * @ Internal Dependecies
 */
import { media } from "../media";

const Nav = styled.nav`
  position: fixed;
  z-index: 100;
  padding: 70px 0 0;
  background: white;
  left: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  box-shadow: 0 12px 4px 0 rgba(0, 0, 0, 0.5);
  transition: transform 0.4s;
  transform: translateX(-100%);
  max-height: 100vh;

  &.nav-open {
    transform: translateX(0);
  }

  svg {
    opacity: 0.45;
    transition: opacity 0.4s;
  }

  ${media.mobile`
		width: 100%
		overflow-y: auto;
	`}
`;

export const NavigationList = styled.ul.attrs({
  id: (props) => props.id,
})``;

export const Dropdown = styled.ul`
  padding-left: 32px;
  left: 100%;
  top: 0;
  width: 100%;
  transition: opacity 0.4s, visibility 0.4s;

  a {
    background: #fff;
  }

  ${media.mobile`
		left: 0;
		top: 100%;
	`}
`;

export const NavigationListItem = styled.li`
	${(props) =>
    props.current &&
    `
		> a {
			background: #E3EBFA;
			color: #4A4A4A;
		}

		svg {
			opacity: 1;
		}

		.ico-arrow svg g {
			stroke: #8013E0
		}
	`}

	${(props) =>
    props.hasDropdown &&
    `
		position: relative;

		&:hover > a {
			background: #E3EBFA;
			color: #4A4A4A;
		}

		&:hover .dropdown {
			visibility: visible;
			opacity: 1;
			z-index: 99;
		}

		&:hover a svg {
			opacity: 1;
		}

		&:hover > a .ico-arrow svg g {
			stroke: #8013E0;
			fill: none
		}
	`}

	${(props) =>
    props.childClicked &&
    `
		.dropdown {
			visibility: hidden;
		}
	`}

	${media.mobile`
		> a .ico-arrow {
			transform: rotate(45deg) translate(-50%, 0);
			transition: transform .4s;
			min-width: 0;
		}
		.dropdown {
			position: static;
			max-height: 0;
			overflow: hidden;
			transition: max-height .4s;
			visibility: visible;
			opacity: 1;
		}

		${(props) =>
      props.hasDropdown &&
      props.current &&
      `
			.dropdown {
				max-height: 300px;
				overflow: hidden;
			}

			> a .ico-arrow {
				transform: rotate(135deg) translate(0, 50%);
			}
		`}
	`}
`;

export const NavLink = styled.a.attrs({
  id: (props) => props.id,
})`
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0 10px 0 61px;
  color: #9b9b9b;
  font-weight: 500;
  text-decoration: none;
  transition: background 0.4s, color 0.4s;

  &:hover {
    background: #e3ebfa;
    color: #4a4a4a;
  }

  &:hover svg {
    opacity: 1;
  }

  &:hover .ico-arrow svg g {
    stroke: #8013e0;
  }

  .svg-icon {
    position: absolute;
    top: 50%;
    left: 27px;
    min-width: 20px;
    text-align: center;
    font-size: 0;
    line-height: 1;
    transform: translateY(-50%);
  }

  .ico-arrow {
    left: auto;
    right: 17px;
  }
  .ico-arrow svg {
    opacity: 1;
  }

  ${media.mobile`
		padding-left: 40px;

		.svg-icon {
			left: 10px;
		}

		.ico-arrow {
			left: auto;
		}
	`}
`;

export const NavigationOvarlay = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 0;

  ${(props) =>
    props.expand &&
    `
		width: calc(100vw - 300px);
	`}

  ${media.mobile`
		width: 0;
	`}
`;

export default Nav;
