/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import theme from "../../styles/theme";
import { FrameCell } from "../../styles/components/Frame";

/**
/**
 * Class for bar chart small.
 *
 * @class      BarChartSmall (name)
 */
class BarChartSmall extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    data: {
      labels: this.props.xAxisLabels,
      datasets: [
        {
          backgroundColor: this.props.chartColors,
          borderWidth: 0,
          hoverBackgroundColor: this.props.chartColors,
          data: this.props.data,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            categoryPercentage: 1.0,
            barPercentage: 0.55,
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              zeroLineColor: "#F5F7F8",
              color: "#F5F7F8",
            },
            ticks: {
              padding: 10,
              min: 0,
              stepSize: 100,
              beginAtZero: true,
            },
          },
        ],
      },
    },
  };

  componentWillUnmount() {
    this.chartReference.chartInstance.destroy();
  }

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const { data, options } = this.state;

    return (
      <React.Fragment>
        <FrameCell flex>
          <FrameCell percent="8">
            <DescriptionYAxis># of students placed</DescriptionYAxis>
          </FrameCell>

          <FrameCell percent="92">
            <Wrapper>
              <Bar
                ref={(reference) => (this.chartReference = reference)}
                data={data}
                options={options}
              />
            </Wrapper>

            <DescriptionXAxis>Course #</DescriptionXAxis>
          </FrameCell>
        </FrameCell>
      </React.Fragment>
    );
  }
}

const Wrapper = styled.div`
  max-height: 282px;
  margin-top: 25px;

  .chartjs-render-monitor {
    height: 282px !important;
  }
`;

const DescriptionXAxis = styled.span`
  display: block;
  padding: 10px 0;
  font-size: 11px;
  line-height: 1.27;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.color.darker_gray};
`;

const DescriptionYAxis = styled.span`
  display: block;
  font-size: 11px;
  line-height: 1.27;
  font-weight: bold;
  text-transform: uppercase;
  color: ${theme.color.darker_gray};
  transform: rotate(-90deg);
  position: absolute;
  margin-top: 150px;
  margin-left: -50px;
`;

export default BarChartSmall;
