import React from "react";
import TableDropdown from "components/elements/TableDropdown";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import CourseItemUnitAssignment from "./CourseItemUnitAssignment";
import { CourseDetailsTitle, CourseDetailsUnitBlock } from "styles/components/CourseDetails";
import { MuuriComponent } from "muuri-react";
import { ROLES } from "constants/roles";
import { useSelector } from "react-redux";
import { openModal } from "store/state/ui/actions";
import { useDispatch } from "react-redux";
import { MODAL_CONFIRMATION, MODAL_CREATE_COURSE_UNIT } from "lib/constants";
import { useParams } from "react-router";
import { deleteUnit } from "store/state/courses/actions";
import { multiSort, COMPARE_TYPES } from "utilities/commonFunctions";

// Drag&Drop component static options.
const dragOptions = {
  dragSort: { groupId: "UNITS" },
  groupIds: ["UNITS"],
  containerClass: "units-drag-container",
  dragEnabled: false,
  dragFixed: true,
  dragSortHeuristics: {
    sortInterval: 0,
  },
  dragStartPredicate: {
    distance: 10,
  },
  dragPlaceholder: {
    enabled: true,
  },
  itemPlaceholderClass: "units-drag-placeholder",
  dragContainer: document.body,
  layoutDuration: 0,
  layoutOnResize: 1,
};

const CourseItemUnit = ({ unit, assignments = [], handleCreateAssignment, onEditAssignment }) => {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const openEditUnitModal = () => {
    dispatch(
      openModal({
        type: MODAL_CREATE_COURSE_UNIT,
        data: {
          id: unit.id,
          editItem: unit,
        },
      })
    );
  };

  const openCopyUnitModal = () => {
    dispatch(
      openModal({
        type: MODAL_CONFIRMATION,
        data: {
          id: unit.id,
          title: unit.name,
          icon: "ico-unit-copy.svg",
          type: "copy",
          action: () => console.log("unit copied"),
        },
      })
    );
  };

  const openRemoveUnitModal = () => {
    dispatch(
      openModal({
        type: MODAL_CONFIRMATION,
        data: {
          id: unit.id,
          title: unit.name,
          icon: "ico-unit-delete.svg",
          type: "remove",
          action: () => dispatch(deleteUnit(unit.id)),
        },
      })
    );
  };

  const editAssignment = (type, assignmentId) => {
    onEditAssignment(type, assignmentId);
  };

  const sortedAssignments = multiSort({
    items: assignments,
    operations: [
      { type: COMPARE_TYPES.DATE, order: "asc", key: "endTs" },
      { type: COMPARE_TYPES.DATE, order: "asc", key: "startTs" },
      { type: COMPARE_TYPES.ALPHABETICAL, order: "asc", key: "name" },
    ],
  });
  const sortedAssignmentIds = sortedAssignments.map((a) => a.id);

  return (
    <CourseDetailsUnitBlock>
      {user.role !== ROLES.STUDENT && (
        <TableDropdown
          options={{
            items: [
              {
                callback: openEditUnitModal,
                btnText: "Edit",
              },
              /*{
                callback: openCopyUnitModal,
                btnText: "Copy",
              },
              */
              {
                callback: openRemoveUnitModal,
                btnText: "Remove",
                disabled: assignments.length > 0,
              },
            ],
          }}
        />
      )}

      <CourseDetailsTitle>{unit.name}</CourseDetailsTitle>
      <MuuriComponent
        id={`${unit.id}`}
        instantLayout
        onSend={unit.onSend}
        sort={sortedAssignmentIds}
        {...dragOptions}
      >
        {sortedAssignments.length > 0 &&
          sortedAssignments.map((assignment, index) => (
            <CourseItemUnitAssignment
              key={assignment.id}
              assignment={assignment}
              index={assignments.length - index}
              courseId={courseId}
              editAssignment={(type) => editAssignment(type, assignment.id)}
            />
          ))}
      </MuuriComponent>

      {user.role !== ROLES.STUDENT && (
        <ButtonLink
          isUnstyledButton
          className="btn-add-unit-block"
          onClick={() => handleCreateAssignment(unit)}
        >
          <span>Create Assignment</span>

          <IconSvgComponent iconPath={`svg/ico-plus.svg`} additionalClass={"icon-add"} />
        </ButtonLink>
      )}
    </CourseDetailsUnitBlock>
  );
};

export default CourseItemUnit;
