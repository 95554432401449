import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Expand from "react-expand-animated";

import { attemptQuestion } from "store/state/practiceAssignment/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import {
  AssessmentQuestionContainer,
  AssessmentQuestionItem,
  AssessmentQuestionNumber,
  AssessmentQuestionItemContainer,
  AssessmentQuestionImage,
  AssessmentQuestionText,
  AssessmentAnswers,
  AssessmentAnswerContainer,
  AssessmentAnswer,
  AssessmentQuestionItemPin,
  AssessmentQuestionItemPinTooltip,
  AssessmentQuestionActions,
  AssessmentQuestionActionsInner,
  AnswerLabel,
  AnswerInput,
  AnswerImage,
  AnswerText,
  Message,
  MessageIcon,
  MessageContent,
  MessageTitle,
  MessageText,
  OptionImage,
} from "styles/components/AssessmentQuestions";

import { FormattedText } from "components/common/FormattedText";

class ReviewQuestion extends Component {
  renderButton = () => {
    const { selectedAnswer, handleCheckButtonClick } = this.props;

    return (
      <ButtonLink
        className="btn-next btn-click"
        danger
        hasIcon
        onClick={handleCheckButtonClick}
        disabled={!selectedAnswer}
      >
        <span>Check Answer</span>
        <IconSvgComponent iconPath="svg/ico-pointer-right.svg" />
      </ButtonLink>
    );
  };

  renderActions = (buttonState, index) => {
    const { attempts } = this.props.selectedQuestion;

    if (buttonState === "correct" && attempts === 1) {
      return (
        <AssessmentQuestionActions perfect>
          <Message>
            <MessageIcon>
              <IconSvgComponent iconPath="svg/ico-perfect-star.svg" />
            </MessageIcon>
            <MessageContent>
              <MessageTitle>Perfect!</MessageTitle>
              {/* <MessageText>
                22% of students got this right on their first attempt
              </MessageText> */}
            </MessageContent>
          </Message>
          <AssessmentQuestionActionsInner>
            {this.renderButton(buttonState, index)}
          </AssessmentQuestionActionsInner>
        </AssessmentQuestionActions>
      );
    }

    if (buttonState === "correct") {
      return (
        <AssessmentQuestionActions correct>
          <Message>
            <MessageContent>
              <MessageTitle>Correct!</MessageTitle>
              {/* <MessageText>52% of students got this right</MessageText> */}
            </MessageContent>
          </Message>
          <AssessmentQuestionActionsInner>
            {this.renderButton(buttonState, index)}
          </AssessmentQuestionActionsInner>
        </AssessmentQuestionActions>
      );
    }

    if (buttonState === "incorrect") {
      return (
        <AssessmentQuestionActions incorrect>
          <Message>
            <MessageContent>
              <MessageTitle>Incorrect</MessageTitle>
              {/* <MessageText>52% of students got this wrong</MessageText> */}
            </MessageContent>
          </Message>
          <AssessmentQuestionActionsInner>
            {this.renderButton(buttonState, index)}
          </AssessmentQuestionActionsInner>
        </AssessmentQuestionActions>
      );
    }

    return (
      <AssessmentQuestionActions>
        <AssessmentQuestionActionsInner>
          {this.renderButton(buttonState, index)}
        </AssessmentQuestionActionsInner>
      </AssessmentQuestionActions>
    );
  };

  render() {
    const {
      selectedQuestion,
      isQuestionMaximized,
      handleToggleQuestionMaximized,
      currentQuestionIndex,
      buttonState,
      disableAnswers,
      isChecked,
      handleInputChange,
    } = this.props;

    return (
      <AssessmentQuestionContainer
        style={{
          paddingBottom: isQuestionMaximized ? "0px" : "20px",
        }}
      >
        <div
          onClick={handleToggleQuestionMaximized}
          style={{
            position: "absolute",
            top: "24px",
            right: "24px",
            cursor: "pointer",
          }}
        >
          <IconSvgComponent iconPath="svg/ico-maximize-question.svg" />
        </div>
        <AssessmentQuestionItem>
          <AssessmentQuestionItemContainer>
            <AssessmentQuestionImage fullwidth>
              {selectedQuestion.content.map((item, contentIndex) => {
                if (item.type === "TEXT") {
                  return (
                    <AssessmentQuestionText
                      key={item.text}
                      layout={selectedQuestion.layout}
                      widthCounter={selectedQuestion.content.length}
                      floatCounter={selectedQuestion.contentIndex}
                    >
                      {contentIndex === 0 && (
                        <AssessmentQuestionNumber>
                          {selectedQuestion.index + 1}.
                        </AssessmentQuestionNumber>
                      )}
                      <FormattedText text={item.text} />
                    </AssessmentQuestionText>
                  );
                }
                return (
                  <OptionImage
                    widthCounter={selectedQuestion.content.length}
                    layout={selectedQuestion.layout}
                  >
                    <img key={item.src} src={`/images/${item.src}`} alt={item.src} />
                  </OptionImage>
                );
              })}
            </AssessmentQuestionImage>
          </AssessmentQuestionItemContainer>
        </AssessmentQuestionItem>

        <Expand open={isQuestionMaximized} duration={600}>
          <AssessmentAnswers type="assignment">
            {selectedQuestion.answers.map((answer) => {
              const { id, displayType, layout } = answer;

              return (
                <AssessmentAnswerContainer
                  key={id}
                  type="assignment"
                  displayType={displayType}
                  selected={isChecked(id)}
                >
                  <AssessmentAnswer
                    displayType={displayType}
                    correct={buttonState === "correct"}
                    incorrect={buttonState === "incorrect"}
                  >
                    <AnswerInput
                      type="radio"
                      name={`answer-${id}`}
                      value={id}
                      id={id}
                      onChange={handleInputChange}
                      checked={isChecked(id)}
                      disabled={disableAnswers}
                    />

                    <AnswerLabel displayType={displayType} htmlFor={id} layout={layout}>
                      <AnswerImage>
                        {answer.data &&
                          answer.data.map((data, k) => {
                            if (data.type === "TEXT") {
                              return (
                                <AnswerText
                                  key={data.text}
                                  layout={answer.layout}
                                  widthCounter={answer.data.length}
                                  floatCounter={k}
                                >
                                  <FormattedText text={data.text} />
                                </AnswerText>
                              );
                            }
                            return (
                              <OptionImage widthCounter={answer.data.length} layout={answer.layout}>
                                <img key={data.src} src={`/images/${data.src}`} alt={data.src} />
                              </OptionImage>
                            );
                          })}
                      </AnswerImage>
                    </AnswerLabel>
                  </AssessmentAnswer>
                </AssessmentAnswerContainer>
              );
            })}
          </AssessmentAnswers>

          {this.renderActions(buttonState, currentQuestionIndex)}
        </Expand>
      </AssessmentQuestionContainer>
    );
  }
}

export default connect(
  (state) => ({
    questions: state.practiceAssignment.assignmentQuestions,
    currentQuestionId: state.practiceAssignment.currentQuestionId,
    currentQuestionIndex: state.practiceAssignment.currentQuestionIndex,
  }),
  {}
)(ReviewQuestion);
