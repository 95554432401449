import React, { Fragment } from "react";
import styled from "styled-components";
import NavigationQuestion from "../practiceAssignment/NavigationQuestion";
import { NavigationListItem } from "styles/components/QuestionsNavigation";
import { SectionHead, SectionBody } from "styles/components/AssessmentTaker";

const SummaryLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3ebfa;
  box-sizing: border-box;
  margin-top: 1px;
  margin-bottom: 16px;
`;

const QuestionList = ({ isSelected, questions, selectedQuestion, handleSelectQuestion }) => {
  return (
    <div
      style={{
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingBottom: isSelected ? "0px" : "20px",
        paddingTop: "0px",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {questions.map((question, index) => {
        const getNavQuestionType = (attempts, isCorrect) => {
          if (attempts === 0) return "unanswered";
          if (attempts === 1 && isCorrect === true) return "perfect";
          if (attempts > 0 && isCorrect === false) return "incorrect";
          if (attempts > 1 && isCorrect === true) return "correct";
        };

        const navQuestionType = getNavQuestionType(question.attempts, question.isCorrect);

        return (
          <NavigationListItem
            key={question.id}
            style={{ margin: "5px 10px" }}
            onClick={() => handleSelectQuestion(question)}
          >
            <NavigationQuestion
              type={navQuestionType}
              attempts={question.attempts}
              text={question.index + 1}
              current={selectedQuestion && selectedQuestion.id === question.id}
            />
          </NavigationListItem>
        );
      })}
    </div>
  );
};

const QuestionSummary = ({ questions, selectedQuestion, selectedTopic, handleSelectQuestion }) => {
  const enhancedQuestions = questions.map((q, index) => {
    return {
      ...q,
      index,
      inTopic: selectedTopic && selectedTopic.questionIndexes.includes(index),
    };
  });

  return (
    <Fragment>
      <SectionHead
        style={{
          fontSize: "1.5em",
          padding: "25px",
          marginBottom: "0px",
          fontWeight: "bold",
          position: "initial",
          backgroundImage: "none",
        }}
      >
        Question Summary
      </SectionHead>
      <SectionBody
        style={{
          display: "flex",
          flexWrap: "wrap",
          height: "100%",
        }}
      >
        {selectedTopic ? (
          <Fragment>
            <QuestionList
              isSelected
              questions={enhancedQuestions.filter((q) => q.inTopic)}
              selectedQuestion={selectedQuestion}
              handleSelectQuestion={handleSelectQuestion}
            />
            <SummaryLine />
            <QuestionList
              questions={enhancedQuestions.filter((q) => !q.inTopic)}
              selectedQuestion={selectedQuestion}
              handleSelectQuestion={handleSelectQuestion}
            />
          </Fragment>
        ) : (
          <QuestionList
            questions={enhancedQuestions}
            selectedQuestion={selectedQuestion}
            handleSelectQuestion={handleSelectQuestion}
          />
        )}
      </SectionBody>
    </Fragment>
  );
};

export default QuestionSummary;
