/**
 * The external dependencies.
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

const SectionBuilder = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  color: ${theme.color.darker_gray};

  .btn-add {
    padding: 10px 12px 10px 20px;
    margin: 0 auto;
  }

  ${media.tablet_portrait`
		.btn-add {
      margin: 0;
    }
	`};

  ${media.mobile`
    overflow-x: hidden;
    overflow-y: visible;
    flex-grow: 0;
    display: block;

    .btn-add {
      width: 100%;
    }
  `};
`;

export const SectionBuilderNotification = styled.div`
  padding: 0;
  text-align: center;
  align-self: center;
  width: 100%;

  h5 {
    margin-top: 15px;
    font-size: 18px;
  }

  svg {
    max-width: 70px;
    height: auto;
  }

  ${(props) =>
    props.isMain &&
    `
		h5 {
			font-size: 24px;
		}

		svg {
			max-width: none;
		}
	`};
`;

export const SectionBody = styled.div`
  display: flex;
  width: 100%;

  ${media.mobile`
    display: block;
  `};
`;

export const SectionContent = styled.div`
	width: calc(100% - 250px);
	padding: 104px 30px 22px
	display: flex;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 113px);

	${SectionBuilderNotification} {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

  ${media.desktop`
    padding: 74px 15px 15px
  `}

  ${media.tablet_portrait`
    padding: 15px;
    width: calc(100% - 200px);
    flex-direction: column;
  `};

  ${media.mobile`
    display: block;
    width: 100%;
    overflow: visible;
    max-height: none;
  `};
`;

export const SectionBuilderBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-image: linear-gradient(180deg, #E6EAF1 55%, rgba(230,234,241,0.00) 100%);

  ${(props) =>
    props.top &&
    `
    top: ${props.top}px;
    width: calc(100% - 250px);
  `}

	.dropdown-wrapper {
		position: relative
		margin: 0;

		button {
			margin-top: 0;
		}
	}

  ${media.desktop`
    padding: 15px;
  `}

  ${media.tablet_portrait`
    position: static;
    padding: 0 0 15px;
    flex-wrap: wrap;

    ${(props) =>
      props.top &&
      `
      top: 0;
      width: 100%;
    `}
  `};

  ${media.mobile`
    width: 100%;
    z-index: auto;
  `};
`;

export const SectionBuilderBarTitle = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  h3 {
    margin: 0 10px 0 0;
    min-width: 170px;
  }

  ${media.tablet_portrait`
    width: calc(100% - 35px);
    margin-bottom: 15px;
  `};
`;

export const SectionBuilderBarActions = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${media.tablet_portrait`
    width: 100%;
    justify-content: flex-end;
  `};

  ${media.mobile`
    display: block;
  `};
`;

export const SectionAside = styled.aside`
  background: ${theme.color.white};
  width: 250px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  position: relative;

  .btn-toggle-sidebar {
    border-radius: 0;
    background: ${theme.color.white};
    position: absolute;
    right: 100%;
    top: 0;
    padding: 10px;
    z-index: 1;
    display: none;

    .svg-icon {
      margin-left: 0;
    }
  }

  ${SectionBuilderNotification} {
    margin-top: -50px;
  }

  ${media.tablet_portrait`
    width: 200px;
  `};

  ${media.mobile`
    position: absolute;
    top: 0;
    right: -200px;
    bottom: 0;
    z-index: auto;
    transition: right .3s;

    .btn-toggle-sidebar {
      display: inline-flex;
    }

    ${(props) =>
      props.active &&
      `
      right: 0;
    `}
  `};
`;

export const SectionAsideHead = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);

  h5 {
    margin-bottom: 0;
  }
`;

export const SectionAsideBody = styled.div`
  padding: 12px 20px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  ${media.tablet_portrait`
    padding: 12px 10px;
  `};
`;

export const SectionQuestionItems = styled.div`
	width: calc(100% + 10px);
	height: auto;
	margin: 0 -5px
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	align-self: flex-start;
`;

export const QuestionItem = styled.div`
  border-radius: 50%;
  overflow: hidden;
  color: ${theme.color.white};
  font-size: 18px;
  margin: 2px 3px;
  cursor: pointer;
  padding: 8px;

  span {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.color.sky_blue};
  }

  &.is-draggable {
    span {
      box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.3);
    }
  }

  ${(props) =>
    props.active &&
    `
			background: #E3EBFA;
		`} ${(props) =>
    props.noBorder &&
    `
    border: none;
  `};

  ${media.tablet_portrait`
      border-width: 4px;
    `};
`;

export default SectionBuilder;
