import { handleActions } from "redux-actions";
import { without, includes } from "ramda";
import {
  startCountdown,
  sidebarToggle,
  setActiveAssessment,
  setCurrentQuestion,
  setCompletedQuestion,
  setPinnedQuestion,
  requestAssessmentSeries,
  listAssessmentSeries,
  rejectAssessmentSeries,
  requestPlacementAssessment,
  receivePlacementAssessment,
  rejectPlacementAssessment,
  requestCompletePlacementAssessment,
  receiveCompletePlacementAssessment,
  rejectCompletePlacementAssessment,
  requestCourseAssessment,
  receiveCourseAssessment,
  rejectCourseAssessment,
  selectAssessmentSeries,
  requestGetAttempts,
  receiveGetAttempts,
  rejectGetAttempts,
  receiveOpenAttempt,
  receiveBeginAttempt,
  receiveResumeAttempt,
  receiveSubmitAttempt,
  clearAttempt,
  receiveGetExpiredAttemptAsResult,
} from "./actions";
import { errorData } from "constants/api";
import { shuffle, orderBy } from "lodash";

const operations = {
  list: errorData,
  get: errorData,
};

const defaultState = {
  data: [],
  assessmentSeriesId: null,
  activeAssessment: false,
  assessmentQuestions: [],
  assessmentId: null,
  deploymentId: null,
  attemptId: null,
  assessmentName: "",
  assessmentVersion: null,
  pinnedQuestions: [],
  completedQuestions: [],
  currentQuestionId: null,
  currentQuestionIndex: 0,
  isCountdownRunning: true,
  isSidebarOpen: true,
  isAccessCodeRequired: false,
  assessmentTimeLimit: null,
  progress: 0,
  isAnimationForward: true,
  remainingAttempts: null,
  cooldown: null,
  attemptResult: null,
  attemptScore: null,
  topicInfo: [],
  apiCallInProgress: false,
  assignmentSubmit: {},
  apiErrors: {
    assessment: operations,
  },
  attempt: {},
  attempts: [],
};

const assessment = handleActions(
  {
    [startCountdown]: (state) => ({
      ...state,
      isCountdownRunning: true,
    }),
    [sidebarToggle]: (state) => ({
      ...state,
      isSidebarOpen: !state.isSidebarOpen,
    }),
    [setActiveAssessment]: (state, action) => {
      const {
        payload: { assessmentId, deploymentId },
      } = action;
      const assessment =
        state.data &&
        state.data.find((assessment) => {
          return assessment.id == assessmentId && assessment.deployment_config_id == deploymentId;
        });

      // if there is local time then its stored in seconds
      // if its the beginning then its in minutes
      const avgDuration = parseInt(assessment?.avg_duration) * 60;

      return {
        ...state,
        deploymentId: deploymentId,
        isAccessCodeRequired: assessment?.isAccessCodeRequired,
        assessmentTimeLimit: avgDuration,
      };
    },
    [setCurrentQuestion]: (state, { payload: { questionId, questionIndex } }) => {
      const oldIndex = state.currentQuestionIndex;
      const isAnimationForward = oldIndex > questionIndex ? false : true;

      return {
        ...state,
        isAnimationForward,
        currentQuestionId: questionId,
        currentQuestionIndex: questionIndex,
      };
    },
    [setCompletedQuestion]: (state, action) => {
      const { assessmentQuestions } = state;
      const { payload } = action;
      const { questionId, selectedAnswerId } = payload;

      const completedQuestions = {
        ...state.completedQuestions,
        [questionId]: { selectedAnswerId },
      };

      const completedQuestionsLength = Object.keys(completedQuestions).length;
      const questionsLength = assessmentQuestions.length;

      const progress = (completedQuestionsLength / questionsLength) * 100;

      return {
        ...state,
        completedQuestions,
        progress,
      };
    },
    [setPinnedQuestion]: (state, action) => {
      const { payload } = action;

      let pinnedQuestions = state.pinnedQuestions;

      if (includes(payload, pinnedQuestions)) {
        pinnedQuestions = without(payload, pinnedQuestions);
      } else {
        pinnedQuestions = [...state.pinnedQuestions, payload];
      }

      return {
        ...state,
        pinnedQuestions,
      };
    },
    [requestAssessmentSeries]: (state, { payload: { operation } }) => ({
      ...state,
      data: [],
      apiCallInProgress: true,
      isCountdownRunning: false,
      currentQuestionId: null,
      currentQuestionIndex: 0,
      assessmentId: null,
      attemptId: null,
      assessmentName: null,
      assessmentVersion: null,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: errorData,
        },
      },
    }),
    [listAssessmentSeries]: (state, { payload: { items } }) => {
      return {
        ...state,
        data: items,
        assessmentTimeLimit: null,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [rejectAssessmentSeries]: (state, { payload: { operation, errorItem } }) => ({
      ...state,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: {
            code: 1,
            message: errorItem.message,
          },
        },
      },
    }),
    [requestPlacementAssessment]: (state, { payload: { operation, assessmentSeriesId } }) => ({
      ...state,
      isSidebarOpen: true,
      pinnedQuestions: [],
      completedQuestions: [],
      currentQuestionIndex: 0,
      progress: 0,
      activeAssessment: false,
      assessmentQuestions: [],
      currentQuestionId: null,
      isCountdownRunning: false,
      assessmentSeriesId,
      apiCallInProgress: true,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: errorData,
        },
      },
    }),
    [receivePlacementAssessment]: (state, { payload: { data, assessmentId, deploymentId } }) => {
      const currentQuestionId = data.questions[0].id;
      const currentQuestionIndex = 0;
      const isAnimationForward = true;
      const pinnedQuestions = [];
      const completedQuestions = state.completedQuestions ?? [];
      const completedQuestionsArray = Object.entries(state.completedQuestions);
      const progress =
        data.questions.length &&
        completedQuestionsArray.length &&
        (100 * completedQuestionsArray.length) / data.questions.length;

      const displayAnswersRandomly = data.is_random || false;

      const questionsWithRandomizedAnswers = data.questions.map((question, index) => {
        const orderedAnswers = orderBy(question.answers, ["display_order"], ["asc"]);
        const [lastAnswer, ...restOfAnswers] = orderedAnswers.reverse();
        const lastAnswerDescription = lastAnswer.description[0];
        const isText = lastAnswerDescription.type === "TEXT";
        const finalStatements = [
          "none of the above",
          "none of these",
          "all of the above",
          "all the above",
          "none of the above statements",
          "there is no solution",
          "no solution",
          "i don’t know",
          "there is not enough information",
          "the limit does not exist",
          "undefined",
          "above",
          "no real roots",
          "equation has no real roots",
          "the equation has no real roots",
        ];
        const includesFinalStatement = finalStatements.some((statement) => {
          return lastAnswerDescription.text?.toLowerCase().includes(statement);
        });

        if (isText && includesFinalStatement) {
          return {
            ...question,
            answers: [...shuffle(restOfAnswers), lastAnswer],
          };
        } else {
          return {
            ...question,
            answers: shuffle(question.answers),
          };
        }
      });

      const assessmentQuestions = displayAnswersRandomly
        ? questionsWithRandomizedAnswers
        : data.questions;

      return {
        ...state,
        activeAssessment: true,
        assessmentQuestions,
        currentQuestionId,
        currentQuestionIndex,
        pinnedQuestions,
        isAnimationForward,
        completedQuestions,
        progress,
        assessmentId: assessmentId,
        attemptId: data.student_placement_attempt_id,
        assessmentName: data.name,
        assessmentVersion: data.version,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [rejectPlacementAssessment]: (state, { payload: { operation, errorItem } }) => ({
      ...state,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: {
            code: 1,
            message: errorItem.message,
          },
        },
      },
    }),
    [requestCompletePlacementAssessment]: (state, { payload: { operation } }) => ({
      ...state,
      isSidebarOpen: true,
      pinnedQuestions: [],
      currentQuestionIndex: 0,
      activeAssessment: false,
      assessmentQuestions: [],
      currentQuestionId: null,
      isCountdownRunning: false,
      apiCallInProgress: true,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: errorData,
        },
      },
    }),
    [receiveCompletePlacementAssessment]: (state, { payload: { data } }) => {
      const newState = {
        remainingAttempts: data.attempt_remaining,
        cooldown: data.cooldown_period,
        attemptResult: data.result,
        attemptScore: data.score,
        topicInfo: data.topicInfo,
        progress: 0,
        assessmentId: data.assessmentId,
        deploymentId: data.deploymentId,
      };

      return {
        ...state,
        ...newState,
        assessmentQuestions: [],
        isCountdownRunning: false,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [receiveSubmitAttempt]: (state, { payload: { data } }) => {
      return {
        ...state,
        remainingAttempts: data.attempt_limit - data.attempt_number,
        cooldown: data.cooldown_period,
        attemptResult: data.result,
        attemptScore: data.score,
        attemptQuestionsCorrect: data.questions_correct,
        attemptTotalQuestions: data.total_questions,
        topicInfo: data.topicInfo,
        progress: 0,
        assessmentId: data.assessmentId,
        deploymentId: data.deploymentConfigId,
        assessmentQuestions: [],
        isCountdownRunning: false,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [receiveGetExpiredAttemptAsResult]: (state, { payload: { attempt } }) => {
      return {
        ...state,
        remainingAttempts: attempt.attemptLimit - attempt.attemptNumber,
        cooldown: attempt.coolingPeriod,
        attemptResult: undefined,
        attemptScore: Math.floor(attempt.totalScore),
        attemptQuestionsCorrect: attempt.correctQuestionsCount,
        attemptTotalQuestions: attempt.questions?.length || attempt.totalQuestions,
        assessmentName: attempt.assessmentName,
        topicInfo: attempt.topic_breakdown.map((topic) => ({
          topic_name: topic.topic,
          percent_score: topic.student_performance,
        })),
        deploymentId: attempt.deploymentConfigId,
        assessmentQuestions: attempt.questions,
        isCountdownRunning: false,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [rejectCompletePlacementAssessment]: (state, { payload: { operation, errorItem } }) => ({
      ...state,
      assessmentQuestions: [],
      isCountdownRunning: false,
      progress: 0,
      apiCallInProgress: false,
      apiErrors: {
        ...state.apiErrors,
        assessment: {
          ...state.apiErrors.assessment,
          [operation]: {
            code: 1,
            message: errorItem.message,
          },
        },
      },
    }),
    [selectAssessmentSeries]: (state, { payload: { assessmentId } }) => {
      return {
        ...state,
        assessmentId,
      };
    },
    [receiveOpenAttempt]: (state, { payload: { attempt, deploymentId, assessmentSeriesId } }) => {
      const { attemptQuestionResponses } = attempt;
      const currentQuestionId = attemptQuestionResponses.length
        ? attemptQuestionResponses.at(-1)?.questionId
        : 0;
      const pinnedQuestions = attempt.pinnedQuestions
        ? attempt.pinnedQuestions.map((questionId) => `${questionId}`)
        : [];
      const completedQuestions = attemptQuestionResponses || {};

      return {
        ...state,
        isCountdownRunning: true,
        activeAssessment: true,
        currentQuestionId,
        pinnedQuestions,
        completedQuestions,
        attemptId: attempt.id,
        apiCallInProgress: false,
        apiErrors: {
          ...state.apiErrors,
          assessment: {
            ...state.apiErrors.assessment,
            list: errorData,
          },
        },
      };
    },
    [receiveBeginAttempt]: (state, { payload: { attempt, assessment } }) => {
      const currentQuestionId = assessment.questions[0].id;
      return {
        ...state,
        attempt,
        currentQuestionId,
      };
    },
    [receiveResumeAttempt]: (state, { payload: { attempt, assessment } }) => {
      const attemptedQuestionsAsArray = Object.entries(attempt.attemptedQuestions);
      const completedQuestions = attemptedQuestionsAsArray.reduce(
        (memo, questionResponse) => ({
          ...memo,
          [questionResponse[0]]: { selectedAnswerId: questionResponse[1].answerId },
        }),
        {}
      );

      return {
        ...state,
        attempt,
        completedQuestions,
        progress: (attemptedQuestionsAsArray.length / assessment.questions.length) * 100,
        pinnedQuestions: attempt.pinnedQuestions.map((questionId) => `${questionId}`),
        currentQuestionId: attempt.activeQuestionId || assessment.questions[0].id,
      };
    },
    [clearAttempt]: (state) => {
      return {
        ...state,
        attempt: {},
        completedQuestions: [],
        progress: 0,
      };
    },
  },
  defaultState
);

export default assessment;
