import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import ModalFrame, { FrameHead, FrameTitle, FrameBody, FrameFoot, FrameAction } from "./ModalFrame";
import {
  SubmitContainer,
  SubmitIcon,
  SubmitTitle,
  SubmitDescription,
  SubmitActions,
} from "styles/components/AssessmentSubmit";

class ModalAssessmentResume extends Component {
  handleRestart = () => {};

  handleResume = () => {};

  render() {
    return (
      <ModalFrame modifier={"modal-assessment-resume"} onClick={this.closePopup}>
        <SubmitContainer>
          <SubmitIcon>
            <IconSvgComponent iconPath={"svg/board-exclamation.svg"} />
          </SubmitIcon>
          <SubmitTitle>You never finished your last attempt</SubmitTitle>
          <SubmitDescription>
            Your last attempt was not completed would you like to resume or restart the test as a
            new attempt ?
          </SubmitDescription>
          <SubmitActions>
            <ButtonLink className="btn-submit" onClick={this.handleResume}>
              <span>{"Resume"}</span>
            </ButtonLink>
          </SubmitActions>
        </SubmitContainer>
      </ModalFrame>
    );
  }
}

export default connect(null)(ModalAssessmentResume);
