/**
 * The external dependencies.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, prop } from "ramda";
import { connect } from "react-redux";
import styled from "styled-components";

/**
 * The internal dependencies.
 */
import { media } from "styles/media";
import { openModal, closeModal } from "store/state/ui/actions";
import { clearDeployment } from "store/state/deployment/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import WizardPrograms from "components/wizard/wizard-programs";
import WizardDeployment from "components/wizard/wizard-deployment";
import { MODAL_FORM_DEPLOYMENT_CANCELLATION } from "lib/constants";

/**
 * Class for modal wizard.
 *
 * @class      ModalWizard (name)
 */
class ModalWizard extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    wizardStep: this.props.initialStep,
    wizardPrevSteps: [],
    programType: "",
  };

  /**
   * Define the interface of the component.
   *
   * @type {Object}
   */
  static propTypes = {
    wizardType: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const {
      props: { wizardType, closeModal },
      state: { wizardStep, wizardPrevSteps, programType },
    } = this;

    const WizardComponent = this.lookupWizardComponent();
    const prevStep = wizardPrevSteps[wizardPrevSteps.length - 1];

    return (
      <Wrapper centered={wizardType !== "WizardDeployment"}>
        <Head>
          {!isEmpty(wizardPrevSteps) && (
            <Button className="btn-prev" onClick={this.handlePrevStepChange(prevStep)}>
              <IconSvgComponent iconPath="svg/ico-arrow-left.svg" />
            </Button>
          )}

          {wizardType === "WizardDeployment" ? (
            <Button className="btn-close" onClick={this.handleWizardClose}>
              <IconSvgComponent iconPath="svg/ico-x.svg" />
            </Button>
          ) : (
            <Button className="btn-close" onClick={() => closeModal()}>
              <IconSvgComponent iconPath="svg/ico-x.svg" />
            </Button>
          )}
        </Head>

        <Body additionalTopSpace={wizardType === "WizardDeployment"}>
          {WizardComponent ? (
            <React.Fragment>
              {wizardType === "WizardPrograms" ? (
                <WizardComponent
                  programType={programType}
                  step={wizardStep}
                  changeStep={this.handleStepChange}
                  isEdit={this.props.isEdit}
                />
              ) : (
                <WizardComponent
                  step={wizardStep}
                  changeStep={this.handleStepChange}
                  isEdit={this.props.isEdit}
                />
              )}
            </React.Fragment>
          ) : (
            <p>WIZARD-NOT-FOUND</p>
          )}
        </Body>
      </Wrapper>
    );
  }

  handleWizardClose = (e) => {
    e.preventDefault();
    const { openModal, closeModal, clearDeployment, modal } = this.props;
    const hasProp = (key) => prop(key, modal.data);

    if (hasProp("editDeployment")) {
      closeModal();
      clearDeployment();
    } else {
      openModal({
        type: MODAL_FORM_DEPLOYMENT_CANCELLATION,
      });
    }
  };

  handlePrevStepChange = (step) => (e) => {
    e.preventDefault();

    const prevSteps = this.state.wizardPrevSteps;

    prevSteps.pop();

    this.setState({
      wizardStep: step,
      wizardPrevSteps: prevSteps,
    });
  };

  /**
   * Change current wizard step
   */
  handleStepChange = (step, prevStep = null, prop, value) => (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState(({ wizardPrevSteps }) => ({
      wizardStep: step,
      wizardPrevSteps: [...wizardPrevSteps, prevStep],
    }));

    if (prop && value) {
      this.setState({
        [prop]: value,
      });
    }
  };

  /**
   * Lookup for wizard component.
   */
  lookupWizardComponent() {
    const { wizardType } = this.props;
    const { wizards } = ModalWizard;

    return wizards[wizardType];
  }
}

/**
 * The supported wizards of the component.
 *
 * @type {Object}
 */
ModalWizard.wizards = {
  WizardPrograms,
  WizardDeployment,
};

/**
 * The supported styles of the component.
 */
const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 86px 50px 56px;
  overflow-y: auto;
  overflow-x: hidden;

  ${(props) =>
    props.centered &&
    `
		align-items: center;
	`}

  ${media.mobile`
		padding: 86px 15px 56px;
	`};
`;
const Head = styled.header`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 19px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn-close {
    margin-left: auto;
  }
`;
const Body = styled.div`
  max-width: 100%;
  flex: 1 1 100%;

  ${(props) =>
    props.additionalTopSpace &&
    `
		padding-top: 10%;
	`}
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: transparent;
  border: none;
  transition: background 0.3s;

  * {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    background: #d8d8d8;

    svg path {
      fill: #4a4a4a;
    }
  }
`;

export default connect(
  (state) => ({
    modal: state.ui.modal,
    wizardType: state.ui.modal.data.wizardType,
    isEdit: state.ui.modal.data.isEdit,
    initialStep: state.ui.modal.data.initialStep,
  }),
  {
    openModal,
    closeModal,
    clearDeployment,
  }
)(ModalWizard);
