import React, { Component } from "react";
import { connect } from "react-redux";

import {
  SectionAssessment,
  SectionContainer,
  SectionHead,
  SectionTitle,
  SectionBody,
} from "styles/components/SelectAssessment";
import { getAssessmentSeries } from "store/state/assessment/actions";
import { NoDataMessageContainer, NoDataMessage } from "styles/components/Card";
import Loader from "components/common/loader";
import Assessment from "./Assessment";

class SelectAssessment extends Component {
  componentDidMount() {
    const { getAssessmentSeries } = this.props;
    getAssessmentSeries();
  }

  render() {
    const { assessment, apiCallInProgress } = this.props;
    return assessment && assessment.length > 0 ? (
      <SectionAssessment>
        <SectionContainer>
          <SectionHead>
            <SectionTitle>Select an assessment you’re ready to take.</SectionTitle>
          </SectionHead>

          <SectionBody>
            {assessment.map(
              ({
                id,
                deployment_id,
                deployment_config_id,
                name,
                remaining_attempt,
                avg_duration,
                prev_score,
                cooling_period,
                last_attempt_date,
              }) => {
                return (
                  <Assessment
                    key={`${id}_${deployment_config_id}`}
                    id={id}
                    deploymentId={deployment_config_id}
                    icon="svg/ico-calculus.svg"
                    title={name}
                    attemptsRemaining={remaining_attempt}
                    timeLimit={avg_duration ? `${avg_duration} mins` : "n/a"}
                    prevScore={prev_score !== null ? `${prev_score}` : "n/a"}
                    coolingPeriod={cooling_period}
                    lastAttemptDate={last_attempt_date}
                  />
                );
              }
            )}
          </SectionBody>
        </SectionContainer>
      </SectionAssessment>
    ) : (
      <NoDataMessageContainer noIcon>
        {apiCallInProgress == true && <Loader />}
        {apiCallInProgress == false && (
          <NoDataMessage>
            No Assessments <br /> available at this time.
          </NoDataMessage>
        )}
      </NoDataMessageContainer>
    );
  }
}

export default connect(
  (state) => ({
    assessment: state.assessment.data,
    apiCallInProgress: state.assessment.apiCallInProgress,
  }),
  {
    getAssessmentSeries,
  }
)(SelectAssessment);
