/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import Grid, { Col } from "styles/components/Grid";
import { SectionWizard, SectionTitle } from "styles/components/Section";
import Box from "components/box";
import {
  WIZARD_PROGRAM_TYPE,
  WIZARD_PROGRAM_ASSESSEMENT,
  WIZARD_PROGRAM_CONTENT,
} from "lib/constants";

const ProgramType = ({ changeStep }) => {
  const prop = "programType";
  const Assessment = "Assessment";
  const Content = "Content";

  return (
    <SectionWizard>
      <SectionTitle>Select Program Type</SectionTitle>

      <Grid justifyCenter alignCenter>
        <Col size1>
          <Box
            icon="ico-board"
            title="Assessment"
            entry="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            btnText="Create"
            btnClick={changeStep(WIZARD_PROGRAM_ASSESSEMENT, WIZARD_PROGRAM_TYPE, prop, Assessment)}
          />
        </Col>

        <Col size1>
          <Box
            icon="ico-content"
            title="Content"
            entry="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            btnText="Create"
            btnClick={changeStep(WIZARD_PROGRAM_CONTENT, WIZARD_PROGRAM_TYPE, prop, Content)}
          />
        </Col>

        <Col size1>
          <Box
            icon="ico-screen"
            title="External Assessment"
            entry="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            btnText="Create"
          />
        </Col>
      </Grid>
    </SectionWizard>
  );
};

export default ProgramType;
