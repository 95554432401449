import { getNotificationId } from "components/Notifier";
import moment from "moment";

export const LEVELS = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARN: "warning",
};

export const createNotification = (level, title, m, dismiss) => {
  // when the error is a type error, extract the type error message
  const message = m instanceof TypeError ? m.message : m;

  return {
    uid: getNotificationId(),
    title,
    message,
    level,
    dismissible: "button",
    // default to a 10 second autoDismiss time
    // autoDimiss === 0 => notification remains until manually dismissed
    autoDismiss: dismiss ?? 10,
  };
};

export const getMaintenanceBannerMessage = ({
  mxStartTime,
  mxEndTime,
}) => {
  if (mxStartTime, mxEndTime) {
    const startDate = moment(mxStartTime).format("dddd, MMMM Do");
    const endDate = moment(mxEndTime).format("dddd, MMMM Do");
    const startTime = moment(mxStartTime).format("h:mm A");
    const endTime = moment(mxEndTime).format("h:mm A");
    return `Stemify will be conducting system maintenance between ${startTime}, ${startDate} and ${endTime}, ${endDate}. We ask that you refrain from taking any Diagnostic or Placement Assessments during this maintenance window. Please be aware that you may experience disruptions in service during this time.`
  }
  return '';
}
