import styled from "styled-components";

export const Card = styled.div`
  position: relative;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  background: #fff;
  width: 50%;
  height: 300px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;

  &:first-child {
    margin-left: 0px;
  }
`;

export const styles = {
  container: {
    position: "relative",
    boxShadow: "0px 4px 4px 1px rgba(0,0,0,.04)",
    borderRadius: "12px",
    background: "#fff",
    width: "50%",
    height: "300px",
    padding: "15px 20px",
    // margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  textContainer: {
    height: "50px",
    width: "100%",
    fontSize: "16px",
    fontFamily: "Circular Pro, sans-serif",
    fontWeight: "500",
    letterSpacing: "1.5px",
    lineHeight: "20px",
  },
};
