import moment from "moment";
import jwt_decode from "jwt-decode";
import { localStore, authKey, accountKey } from "../constants/localStore";

export const updateAuthStore = ({
  AccessToken,
  ExpiresIn,
  ExpiresAt,
  TokenType,
  RefreshToken,
  IdToken,
  ...rest
}) => {
  if (localStore) {
    const authState = JSON.parse(localStore.getItem(authKey));
    let nextExpiresAt = null;
    if (ExpiresAt) {
      nextExpiresAt = ExpiresAt;
    }
    if (!ExpiresAt && AccessToken && ExpiresIn) {
      nextExpiresAt = Math.floor(moment().valueOf() / 1000 + ExpiresIn);
    }
    localStore.setItem(
      authKey,
      JSON.stringify({
        ...authState,
        AccessToken,
        ExpiresIn,
        ExpiresAt: nextExpiresAt,
        TokenType,
        RefreshToken,
        IdToken,
      })
    );
  }
};

export const getAuthState = () => JSON.parse(localStore.getItem(authKey));

export const getAccessToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { AccessToken } = authState;
      return AccessToken;
    }
    return "";
  }
};

export const getRefreshToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { RefreshToken } = authState;
      return RefreshToken;
    }
    return "";
  }
};

export const getIdToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { IdToken } = authState;
      return IdToken;
    }
    return "";
  }
};

export const getUserInfo = () => {
  if (process.env.REACT_APP_DISABLE_LOGIN) {
    return { name: "HTML Burger", email: "dev@htmlburger.com" };
  }

  if (localStore) {
    const authState = getAuthState();

    if (authState) {
      const { IdToken } = authState;

      try {
        const userInfo = jwt_decode(IdToken);
        const usAccount = getUserCurrentAccount();
        userInfo.defaultAccountName = getUserDefaultAccount();

        if (usAccount) {
          const { accountId, accountName } = usAccount;
          userInfo.currentAccountId = accountId;
          userInfo.currentAccountName = accountName;
        }
        if (!userInfo.name) {
          userInfo.name = userInfo["email"];
        }
        if (process.env.REACT_APP_DISABLE_LOGIN) {
          throw "set htmlburger user";
        }
        return userInfo;
      } catch (err) {
        console.log({ err });
        if (process.env.REACT_APP_DISABLE_LOGIN) {
          return { name: "HTML Burger", email: "dev@htmlburger.com" };
        }
        return { name: null, email: null };
      }
    }
  }
};

export const setUserCurrentAccount = ({ accountId, accountName }) => {
  if (localStore) {
    localStore.setItem(accountKey, JSON.stringify({ accountName, accountId }));
  }
};

export const getUserCurrentAccount = () => {
  if (localStore) {
    const val = localStore.getItem(accountKey);
    if (val) {
      return JSON.parse(val);
    }
  }
  return { accountId: null, accountName: null };
};

export const getUsername = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState && authState.IdToken) {
      const { "cognito:username": username } = JSON.parse(atob(authState.IdToken.split(".")[1]));
      return username;
    }
  }
};

export const getExpiryTime = () => {
  if (localStore) {
    const authState = JSON.parse(localStore.getItem(authKey)) || {};
    if (authState) {
      const { ExpiresAt } = authState;
      return ExpiresAt;
    }
    return 0;
  }
};

export const setUserDefaultAccount = (accountName) => {
  if (localStore) {
    localStore.setItem("defaultAccountName", accountName);
  }
};

export const getUserDefaultAccount = () => {
  if (localStore) {
    return localStore.getItem("defaultAccountName");
  }
  return "";
};

export const loginIsFresh = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { AccessToken, IdToken, ExpiresAt } = authState;
      return AccessToken !== null && IdToken !== null && ExpiresAt > moment().valueOf() / 1000;
    }
    return false;
  }
};

export const setIsNavigationFromTopicFilter = ({ isFilterByTopic, type, id, filterTopic }) => {
  if (localStore) {
    localStore.setItem(
      "isNavigationFromTopicFilter",
      JSON.stringify({ isFilterByTopic, type, id, filterTopic })
    );
  }
};

export const getIsNavigationFromTopicFilter = () => {
  if (localStore) {
    const val = localStore.getItem(`isNavigationFromTopicFilter`);
    if (val) {
      return JSON.parse(val);
    }
  }
  return null;
};
