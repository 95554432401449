import React, { Component } from "react";
import classNames from "classnames";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import styled from "styled-components";

import { getRandomColor } from "utilities/commonFunctions";
import {
  setActiveCourse,
  getSectionById,
  getSectionGradesByAssignment,
  getAssignments,
  getUnitsBySectionId,
} from "store/state/courses/actions";
import { openModal, closeModal } from "store/state/ui/actions";
import { media } from "styles/media";
import Loader from "components/common/loader";
import TableDropdown, { DropdownWrapper, DropdownButton } from "components/elements/TableDropdown";
import CourseSubnav from "components/courses/CourseSubnav";
import CourseTabs, { CourseTab, CourseTabsContent } from "styles/components/CourseTabs";
import {
  Accordion,
  AccordionTitle,
  AccordionContent,
} from "styles/components/ModalCoursesAddToAssignment";
import AssignmentGradesCard from "components/views/AssignmentGradesCard";
import AssignmentStudentGradesCard from "components/views/AssignmentStudentGradesCard";
import Can from "components/common/Can";
import { ROLES } from "constants/roles";
import TabNav from "components/elements/TabNav";

class CourseDetailsAssignmentGrades extends Component {
  state = {
    dataLoaded: false,
    assignment_items: [],
  };
  async componentDidMount() {
    const { courseId } = this.props.match.params;

    const {
      getSectionById,
      getSectionGradesByAssignment,
      getAssignments,
      accountId,
      getUnitsBySectionId,
    } = this.props;

    const sectionResponse = await getSectionById({ accountId, courseId });
    const gradesResponse = await getSectionGradesByAssignment({ sectionId: courseId });
    const unitsResponse = await getUnitsBySectionId({ accountId, courseId, selector: "units" });
    const assignmentsResponse = await getAssignments({ accountId, sectionIds: [courseId] });

    this.setState({
      dataLoaded: true,
    });

    const activeCourse = this.props.courses.filter((course) => course.id === courseId);
    activeCourse.length > 0 && this.props.setActiveCourse(activeCourse[0]);
    const filteredUnits =
      this.props.units && this.props.units.filter((unit) => unit.sectionId === activeCourse[0].id);

    const accordianObj =
      filteredUnits &&
      filteredUnits.reduce((acc, unit) => {
        if (!acc[unit.id]) {
          acc[unit.id] = {};
          acc[unit.id].is_hidden = false;
          acc[unit.id].items = [];
          acc[unit.id].title = unit.name;
        }
        acc[`${unit.id}`].items =
          this.props.assignmentList &&
          this.props.assignmentList.filter((assignment) => {
            if (assignment.unitId === unit.id) {
              assignment.enrolledStudentInSection = activeCourse[0].students;
              assignment.background = getRandomColor();
              return true;
            }
            return false;
          });
        return acc;
      }, {});

    let accordionsArr = Object.values(accordianObj).sort((a, b) =>
      ("" + a.title).localeCompare(b.title)
    );

    // let accordionsArray = Object.entries(accordions)
    //   .map(([title, obj]) => {
    //     obj.title = title;
    //     return obj;
    //   })
    //   .sort((a, b) => ("" + a.title).localeCompare(b.title));
    // accordionsArray.push(
    //   ...accordionsArray.splice(
    //     accordionsArray.findIndex((v) => v.title == "Archived"),
    //     1
    //   )
    // );

    this.setState({
      assignment_items: accordionsArr,
    });
  }

  /**
   * Toggle accordion content
   * @param {string} name
   */
  handleToggleAccordion(ind) {
    const items = this.state.assignment_items.map((item, index) => {
      if (index === ind) {
        item.is_hidden = !item.is_hidden;
      }
      return item;
    });
    this.setState({
      assignment_items: items,
    });
  }

  render() {
    const { courses, match, userRole } = this.props;
    const { courseId } = match.params;
    const currentCourse = courses.find((item) => item.id == courseId);
    const { dataLoaded } = this.state;

    if (!currentCourse) {
      return null;
    }

    const tabRoutes = [
      { name: "Assignments", path: `/courses/${courseId}/grades/assignments` },
      { name: "Students", path: `/courses/${courseId}/grades/students` },
    ];

    return (
      <DocumentTitle title="Stemify | Course">
        <CourseTabs>
          <CourseSubnav />

          <CourseTabsContent>
            {!dataLoaded ? (
              <Loader />
            ) : (
              <CourseTab active={true}>
                <Can
                  role={userRole}
                  perform={"courses-student-tab-grade:view"}
                  yes={() => <TabNav routes={tabRoutes} alignment="center" />}
                />
                {this.state.assignment_items &&
                  this.state.assignment_items.map((assignment, ind) => (
                    <Accordion
                      key={assignment.title}
                      className={classNames("accordion--space", {
                        "is-hidden": assignment.is_hidden,
                      })}
                    >
                      <AccordionTitle>
                        {assignment.title}{" "}
                        <span onClick={() => this.handleToggleAccordion(ind)}></span>
                      </AccordionTitle>
                      <AccordionContent>
                        <AccordionContent>
                          {userRole === ROLES.STUDENT ? (
                            <AssignmentStudentGradesCard assignments={assignment} />
                          ) : (
                            <AssignmentGradesCard assignments={assignment} />
                          )}
                        </AccordionContent>
                      </AccordionContent>
                    </Accordion>
                  ))}
              </CourseTab>
            )}
          </CourseTabsContent>
        </CourseTabs>
      </DocumentTitle>
    );
  }
}

export const TableCustom = styled.div`
  overflow: hidden;
  margin: 0 0 50px;

  ${media.mobile`
    overflow: auto;
  `}

  .rthfc .rt-th.rthfc-th-fixed-right-first,
  .rthfc .rt-td.rthfc-td-fixed-right-first,
  .rthfc .-filters .rt-th.rthfc-th-fixed-left-last,
  .rthfc .rt-th.rthfc-th-fixed-left-last,
  .rthfc .rt-td.rthfc-td-fixed-left-last {
    border-left: 0;
    border-right: 0;
  }

  .ReactTable {
    min-width: 530px;
    border: none;

    ${media.mobile`
        max-height: none !important;
    `}

    .rt-table {
      margin-bottom: -17px;

      ${media.mobile`
        overflow-x: hidden;
        overflow-y: auto;
    `}
    }

    .rt-tbody {
      padding: 0 0 17px;
      background: #fff;

      .rt-td {
        font-size: 16px;
        align-self: initial;
        min-height: 65px;

        ${media.mobile`
          padding: 5px;
          font-size: 11px;
          min-height: 0;
        `}

        &.rthfc-td-fixed-right {
          button {
            align-items: center;
          }
        }
      }
    }

    .rt-tr-group {
      flex: 0 0 auto;
    }

    .rt-thead {
      flex: 0 0 auto;
      .rt-th {
        white-space: initial;
        &.rthfc-th-fixed-left-last {
          padding-right: 0;
        }

        ${media.mobile`
          padding: 5px;
        `}
      }
      &.-header {
        height: auto;
      }
    }

    .-headerGroups {
      display: none;
    }

    ${DropdownWrapper} {
      top: -43px;
      right: 40px;
      width: 100px;

      &:before {
        top: 40px;
        right: -7px;
        bottom: auto;
      }

      ${DropdownButton} {
        padding: 10px;
      }
    }
  }
`;

export const ScrollArrow = styled.div`
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    border: 2px solid #979797;
    transform: rotate(45deg);
  }

  &.left {
    &:after {
      border-right: 0;
      border-top: 0;
    }

    ${media.mobile`
        display: flex;
        justify-content: center;
    `}
  }

  &.right {
    &:after {
      border-left: 0;
      border-bottom: 0;
    }

    ${media.mobile`
        display: flex;
        justify-content: center;
    `}
  }
`;

export default connect(
  (state) => ({
    modal: state.ui.modal,
    userRole: state.user.role,
    currentAccountId: state.user.currentAccountId,
    accountId: state.user.accountId,
    apiCallInProgress: state.administration.apiCallInProgress,
    courses: Object.values(state.storedCourses.sections),
    units: Object.values(state.storedCourses.units),
    assignmentList: Object.values(state.storedCourses.assignments),
  }),
  {
    openModal,
    closeModal,
    setActiveCourse,
    getAssignments,
    getSectionGradesByAssignment,
    getSectionById,
    getUnitsBySectionId,
  }
)(CourseDetailsAssignmentGrades);
