/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";
//
// Containers
//
export const AssessmentQuestionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 27px 0;
  border-radius: ${theme.border_radius};
  margin-bottom: 30px;
  background: ${theme.color.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

  ${media.mobile`
    position: relative;
    margin-bottom: 90px;
  `}
`;

export const AssessmentQuestionActions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
    height: 101px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
	padding: 27px 35px;

	.btn-prev {
		padding: 8px 35px;
		margin-right: 30px;
		font-size: 16px;
		font-weight: 700;
	}

	.btn-click {
		transition: transform .3s;
	}

	.btn-click:active {
		transform: translateY(2px);
	}

	${(props) =>
    props.perfect &&
    `
		background: ${theme.color.perfect};
		color: #6D5C2F;
	`}

	${(props) =>
    props.correct &&
    `
		background: ${theme.color.correct};
		color: #0E663F;
	`}

	${(props) =>
    props.incorrect &&
    `
		background: ${theme.color.incorrect};
		color: #FF3E1D;
	`}

	${media.mobile`
		padding: 10px;

		button {
			font-size: 14px;
		}

		.btn-prev {
			margin-right: 15px;
		}
	`}
`;
export const AssessmentQuestionActionsInner = styled.div`
  flex-shrink: 0;
`;

//
// Pin
//
export const AssessmentQuestionItemPin = styled.button`
  position: absolute;
  top: 25px;
  right: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 50%;
  background: transparent;
  appearance: none;
  transition: background 0.3s;

  &:focus:not(:hover) {
    outline: 2px;
    border: 2px solid ${theme.color.blue};
  }

  &:hover {
    background: #d8d8d8;

    div {
      opacity: 1;
      visibility: visible;
    }
  }

  ${(props) =>
    props.active &&
    `
		path {
			fill: ${theme.color.blue};
		}
	`}

  ${media.mobile`
		top: 10px;
		right: 10px;
	`}
`;

export const AssessmentQuestionItemPinTooltip = styled.div`
  position: absolute;
  top: 7px;
  right: 100%;
  min-width: 100px;
  padding: 1px 5px;
  border-radius: 2px;
  margin-right: 15px;
  background: #4a4a4a;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.color.white};
  text-align: center;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
`;

//
// Question
//
export const AssessmentQuestionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 35px;
  width: calc(100% - 34px);

  ${media.tablet_portrait`
		padding: 0 20px;
	`}

  ${media.mobile`
			width: 100%;
		`}
`;

export const AssessmentQuestionNumber = styled.div`
  flex-shrink: 0;
  display: inline-block;
  margin-right: 5px;
  font-size: 28px;
  font-weight: 500;
  color: ${theme.color.blue};

  ${media.mobile`
		font-size: 20px;
	`}
`;

export const AssessmentQuestionItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  ${media.mobile`
		display: block;
		width: 100%;
		padding-right: 0;
	`}
`;

export const AssessmentQuestionImage = styled.div`
  flex-shrink: 0;
  margin-right: 20px;
  margin-bottom: 35px;

  ${(props) =>
    props.fullwidth &&
    `
		width: 100%;
	`}

  ${media.mobile`
		margin-right: 0;
		margin-bottom: 20px;
  `}

  ${(props) =>
    props.fullwidth &&
    `
		width: 100%;
	`};
`;

export const AssessmentQuestionText = styled.div`
	font-size: 28px;
	font-weight: 500;
	${media.mobile`
		font-size: 20px;
	`}

	${(props) =>
    props.layout === "horizontal" &&
    `
		display: inline;
	`}

	${(props) =>
    props.widthCounter == "2" &&
    props.layout === "horizontal" &&
    `
		width: 45%;
	`}

	${(props) =>
    props.widthCounter == "3" &&
    props.layout === "horizontal" &&
    `
		width: 25%;
		float: left;
	`}

	${(props) =>
    props.floatCounter == "0" &&
    props.layout === "horizontal" &&
    `
		float: left;
	`}

	${(props) =>
    props.floatCounter == "2" &&
    props.layout === "horizontal" &&
    `
		float: right;
	`}

`;

//
// Answers
//
export const AssessmentAnswers = styled.div.attrs({
  id: (props) => props.id,
})`
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: center;
	// padding: 0 40px;
	// margin: 0 -40px 30px;

	${(props) =>
    props.type === "assignment" &&
    `
		// display: block;
		padding: 0;
		// margin: 0;
	`}

	${media.desktop`
		// padding: 0 30px;
		// margin: 0 -30px 30px;
	`}

	${media.tablet_portrait`
		// padding: 0 10px;
		// margin: 0 -10px 30px;
	`}
`;

export const AssessmentAnswerContainer = styled.div`
	width: 25%;
	padding: 0 40px;
   text-align: center;

	${(props) =>
    props.displayType === "radio" &&
    `
		width: 100%;
		padding: 0;
		margin: 0;
		text-align: left;
  `}

   ${(props) =>
     props.type === "assignment" &&
     `
		padding: 0 40px;
	`}

	${(props) =>
    props.selected &&
    `
		background-color: #C7E7FF;
	`}

	${(props) =>
    props.displayType === "image" &&
    `
		width: 100%;
	`}

	${media.desktop`
		padding: 0 30px;

		${(props) =>
      props.displayType === "radio" &&
      `
			padding: 0;
		`}
	`}

	${media.tablet_portrait`
		width: 50%;
		padding: 0 10px;

		${(props) =>
      props.displayType === "radio" &&
      `
			width: 100%;
			padding: 0;
		`}
	`}

	${media.mobile`
		width: 100%;
	`}
`;

export const AssessmentAnswer = styled.div`
	position: relative;
	display: inline-block;
	width: 99%;
	text-align: left;
	${(props) =>
    props.quizType == "assessment" &&
    `
		width: 100%;
	`}

	input[type=radio] + label {
		position: relative;
		display: block;
		padding: 12px;
		cursor: pointer;
		transition: background .3s;
		${(props) =>
      props.quizType == "assessment" &&
      `
			&:hover {
				background: rgba(156, 212, 255, 0.57);
			}
		`}

	}

	input[type=radio]:checked + label {
		${(props) =>
      props.quizType == "assessment" &&
      `
			background: rgba(156,212,255, .57)
		`}
		// ${(props) =>
      props.quizType == "video" &&
      props.onlyText &&
      `
		// 	background: #C7E7FF;
		// 	color: #4A4A4A;
		// `}

	}

	${(props) =>
    props.displayType === "radio" &&
    `
		display: block;
		input[type=radio] + label {
			padding: 0px 10px 0px 70px; // changes 8px to zero px as added line height.
			margin-bottom: 1px;
			margin-top: 10px;
			display: inline-block;
			width: 100%;
		}

		input[type=radio] + label:before {
			content: '';
			position: absolute;
			top: 10px;
			left: 33px;
			width: 24px;
			height: 24px;
			padding: 0;
			border: 1px solid #979797;
			border-radius: 50%;
			margin: 0;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: auto;
		}

		input[type=radio]:disabled + label:before {
			background-color: transparent;
			border: 1px solid #979797;
		}

		input[type=radio]:checked + label:before {
			content: '';
			padding: 0;
			border-color: ${theme.color.light_blue};
			background-color: ${theme.color.light_blue};
			background-image: url(/svg/ico-check-white-medium.svg);
		}
	`}

	// styles for text only
	${(props) => {
    if (props.displayType === "radio" && props.correct) {
      return `
				input[type=radio]:checked + label:before {
					padding: 0;
					border: 0;
					background-color: transparent;
					background-image: url(/svg/ico-correct.svg);
					background-size: cover;
				}
			`;
    }

    if (props.displayType === "radio" && props.incorrect) {
      return `
				input[type=radio]:checked + label:before {
					padding: 0;
					border: 0;
					background-color: transparent;
					background-image: url(/svg/ico-incorrect.svg);
					background-size: cover;
				}
			`;
    }
  }}

	// Styles for answers with image/image+text
	${(props) =>
    props.displayType === "2" &&
    `
		input[type=radio] + label {
			border-radius: 5px;
			text-align: center;
		}

		input[type=radio] + label:before {
			display: none;
		}
	`}

	// Styles for answers with image large
	${(props) =>
    props.displayType === "image" &&
    `
			input[type=radio] + label {
			padding: 19px;
			border-radius: 5px;
			text-align: center;
			display: inline-block;
			width: 100%;
		}


		input[type=radio]:checked + label {
			// border: 12px solid #C7E7FF
			// border-radius: 4.45px;
		}

    input[type=radio] + label:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 15px;
			width: 24px;
			height: 24px;
			padding: 0;
			border: 1px solid #979797;
			border-radius: 50%;
			margin: 0;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: auto;
		}

    input[type=radio]:disabled + label:before {
			background-color: transparent;
			border: 1px solid #979797;
		}

		input[type=radio]:checked + label:before {
			content: '';
			padding: 0;
			border-color: ${theme.color.light_blue};
			background-color: ${theme.color.light_blue};
			background-image: url(/svg/ico-check-white-medium.svg);
		}
	`}

	${media.mobile`
		// styles for text only
		${(props) =>
      props.displayType === "radio" &&
      `
			input[type=radio] + label {
				padding-left: 45px;
			}

			input[type=radio] + label:before {
				top: 7px;
				left: 10px;
			}
		`}
	`}

	${(props) =>
    props.focusOn &&
    props.dataCount > 1 &&
    `
		border: 12px solid #C7E7FF
		border-radius: 4.45px;
	`}
`;

export const AnswerInput = styled.input``;

export const AnswerLabel = styled.label`
  ${(props) =>
    props.focusOn &&
    props.quizType == "assessment" &&
    `
			background-color: background: rgba(156, 212, 255, 0.57);
		`}
  display: inline-block;
`;

export const AnswerImage = styled.div`
  display: block;
  &:focus {
    border: 2px solid #2049e6;
  }
  ${(props) =>
    props.displayType === "radio" &&
    `
		display: none;
	`}
`;
export const AnswerText = styled.div`
	font-size: 19px;
	font-weight: 500;
	line-height: 2.5;
	display: inline-block;
	float:left;
	text-align:left;
	${(props) =>
    props.widthCounter === 2 &&
    props.layout == "horizontal" &&
    `
		width: 45%;
		float:none;
	`}

	${(props) =>
    props.widthCounter === 3 &&
    props.layout == "horizontal" &&
    `
		width: 25%;
	`}

	${(props) =>
    props.displayType === "radio" &&
    `
		margin-top: 0;
	`}

	${media.mobile`
		font-size: 15px;
		line-height: 1.8;
		padding: 5px 0;
	`}

	${(props) =>
    props.layout == "vertical" &&
    `
		display: block !important;
	`}

	// Below two are for text before and after image when horizontal layouut
	${(props) =>
    props.floatCounter == 0 &&
    props.layout == "horizontal" &&
    `
		float: left;
	`}

	${(props) =>
    props.floatCounter == 2 &&
    props.layout == "horizontal" &&
    `
		float: right;
	`}
`;

export const Message = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
export const MessageIcon = styled.div`
  width: 56px;
  margin-right: 12px;

  svg {
    width: 100%;
  }
`;
export const MessageContent = styled.div`
  line-height: 1;
`;
export const MessageTitle = styled.h3`
  margin-bottom: 5px;
  font-weight: 500;
`;
export const MessageText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #4a4a4a;
`;

export const OptionImage = styled.div`
	display: inline-block;
	text-align: center;
	margin-left: 15px;
	margin-right: 15px;
	float:left;
	width: 100%;

	img {
    max-height: 33vw;
    margin-left: 15px;

		${(props) =>
      props.layout == "vertical" &&
      `
			float:left;
		`}
		${(props) =>
      props.layout == "horizontal" &&
      `
			vertical-align: initial;
			margin: auto;
		`}
		 ${(props) =>
       props.layout == "vertical" &&
       `
			margin-top: 15px;
			margin-bottom: 15px;
		`}
	}

	${(props) =>
    props.widthCounter == 3 &&
    props.layout == "horizontal" &&
    `
		width: 45%;
		${media.mobile`
			width: 40%;
		`}
	`}

	${(props) =>
    props.widthCounter == 2 &&
    props.layout == "horizontal" &&
    `
		${media.mobile`
			width: 40%;
		`}
	`}
`;

export const VideoQuestionText = styled(AssessmentQuestionText)`
  ${(props) =>
    props.widthCounter == "3" &&
    props.layout === "horizontal" &&
    `
		width: 25%;
		float: left;
	`}
`;

export const VideoOptionImage = styled(OptionImage)`
  margin-left: 1px;
  margin-right: 1px;
  ${(props) =>
    props.dataCount == 1 &&
    `
			img{
				margin-top: 0px !important;
				margin-bottom: 0px !important;
			}
		`}
		${(props) =>
      props.dataCount == 1 &&
      props.checked &&
      `
			border: 12px solid #C7E7FF
			border-radius: 4.45px;
			margin-
			img{
				vertical-align:bottom;
			}
		`}

  ${(props) =>
    props.widthCounter == 3 &&
    props.layout == "horizontal" &&
    `	width: 48%;
		${media.mobile`
			width: 48%;
		`}
	`}
  ${(props) =>
    props.widthCounter == 2 &&
    props.layout == "horizontal" &&
    `
		width: 45%;
		${media.mobile`
			width: 45%;
		`}
	`}
`;

export const AssessmentQuestionActionsBar = styled.div`
  position: fixed;
  bottom: 0;
  right: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;

  button ~ button {
  	margin-left: 11px;
  }

  .btn-question {
    width: 58px;
    height: 58px;
    padding: 0;
    flex-direction: column;
    border-radius: 50%;
    background: transparent;
    color: ${theme.color.white};
    transition: background 0.3s;

    &:hover:not(:disabled) {
      background: rgba(54, 54, 54, 0.8);
      color: ${theme.color.white};
    }
  }

  .svg-icon {
    margin: 0 0 5px;
  }

  	${(props) =>
      props.sidebarOpen &&
      `
  		right: 250px;
  	`}

  ${media.tablet_portrait`
    padding: 15px;
  `}

  ${media.mobile`
		padding: 10px;
		right: 0;
		width: 100%;
		justify-content: center;

		button {
			font-size: 14px;
		}

		.svg-icon {
	    margin: 0 0 3px;
	  }

	  .btn-question {
			width: 50px;
			height: 50px;
			font-size: 14px;
	  }
	`}
`;

export const AssessmentQuestionActionsBarInner = styled.div`
  display: flex;
  align-items: center;
  background: rgba(74, 74, 74, 0.75);
  border-radius: 100px;
  padding: 6px 22px;
`;

export const AssessmentQuestionButtonPin = styled.button`
  width: 58px;
  height: 58px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  background: transparent;
  color: ${theme.color.white};
  transition: background 0.3s;
  border: transparent;
  font-size: 16px;
  font-weight: 700;

  .icon-pin {
    width: 17px;
    height: 17px;
  }

  path {
    fill: ${theme.color.white};
  }

  &:hover {
    background: rgba(54, 54, 54, 0.8);
    color: ${theme.color.white};
  }

  ${(props) =>
    props.active &&
    `
    background: ${theme.color.blue};

    &:hover {
      background: ${theme.color.blue};
    }
	`}

  ${media.mobile`
		width: 50px;
		height: 50px;
		font-size: 14px;
	`}
`;
