import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import DocumentTitle from "react-document-title";
import moment from "moment";
import { connect } from "react-redux";
import { isEmpty } from "ramda";
import { Link } from "react-router-dom";

import Loader from "components/common/loader";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  MODAL_CREATE_BUILDER_ASSESSMENT,
  MODAL_DELETE_BUILDER_QUESTION,
  MODAL_IMAGE_MANAGER,
} from "lib/constants";

import { openModal, closeModal } from "store/state/ui/actions";
import {
  setActiveBuilderAssessment,
  setActiveBuilderQuestion,
  updateActiveBuilderAssessmentQuestionsOrder,
  updateActiveQuestion,
  createBuilderQuestion,
  createBuilderAnswer,
  updateBuilderAnswersOrder,
} from "store/state/builderAssessment/actions";

import { setPageBlur, setPageLock } from "store/state/questions/actions";

import TableDropdown from "components/elements/TableDropdown";
import NoQuestionsNotification from "components/builderAssessment/NoQuestionsNotification";
import VersionDropdown from "components/builderAssessment/VersionDropdown";
import QuestionIndicators from "components/builderAssessment/QuestionIndicators";
import BuilderQuestionBlock from "components/builderAssessment/BuilderQuestionBlock";
import ModalTagsBuilder from "components/modal/ModalTagsBuilder";

import SectionBuilder, {
  SectionBody,
  SectionBuilderBar,
  SectionBuilderBarTitle,
  SectionBuilderBarActions,
  SectionContent,
  SectionAside,
  SectionAsideHead,
  SectionAsideBody,
} from "styles/components/SectionBuilder";

class AssessmentBuilder extends Component {
  state = {
    activeAssessment: null,
    previewMode: false,
    activeQuestion: null,
    barTopPosition: 0,
    sidebarActive: false,
    showModal: false,
    modalTagsType: "",
    modalTagsIndex: null,
    modalTagsData: null,
  };

  getEditState = (assessment) => {
    return {
      id: assessment.id,
      name: assessment.name,
      randomizeOrder: assessment.randomizeOrder,
    };
  };

  edit = (assessment) => (e) => {
    this.props.openModal({
      type: MODAL_CREATE_BUILDER_ASSESSMENT,
      data: {
        id: assessment.id,
        editItem: this.getEditState(assessment),
      },
    });
  };

  togglePreviewMode = () => {
    this.setState({
      previewMode: !this.state.previewMode,
    });
  };

  toggleSidebar = () => {
    this.setState({
      sidebarActive: !this.state.sidebarActive,
    });
  };

  handleQuestionCreate = () => {
    this.props.createBuilderQuestion();
    this.props.createBuilderAnswer();
  };

  handleQuestionsReorder = (questions) => {
    this.props.updateActiveBuilderAssessmentQuestionsOrder(questions);
  };

  handleQuestionUpdate = (data) => {
    this.props.updateActiveQuestion(data);
  };

  handleQuestionDelete = () => {
    this.props.openModal({
      type: MODAL_DELETE_BUILDER_QUESTION,
    });
  };

  onQuestionClick = (id) => {
    this.props.setActiveBuilderQuestion(id);
  };

  handleAnswerCreate = () => {
    this.props.createBuilderAnswer();
  };

  createVersion = () => {
    console.log("Version Created");
  };

  setActiveVersion = (versionId) => {
    console.log("active version:", versionId);
  };

  deleteVersion = (versionId) => {
    console.log("version to delete:", versionId);
  };

  copyVersion = (versionId) => {
    console.log("version to copy", versionId);
  };

  handleBarTopPosition = (e) => {
    this.setState({
      barTopPosition: e.target.getBoundingClientRect().top,
    });
  };

  handleAnswersReorder = (answers) => {
    this.props.updateBuilderAnswersOrder(answers);
  };

  componentDidMount() {
    const { builderAssessments, activeAssessment } = this.props;
    const { builderId } = this.props.match.params;
    const currentAssessment = builderAssessments.find((item) => item.id === builderId);
    const scrollableNode = this.scrollableParent;

    if (!currentAssessment) {
      this.props.history.replace(`/assessment-builder`);
      return;
    }

    this.props.setActiveBuilderAssessment(builderId);

    if (currentAssessment.questions.length > 0) {
      const firstQuestionId = currentAssessment.questions[0].id;
      this.props.setActiveBuilderQuestion(firstQuestionId);
    } else {
      this.props.setActiveBuilderQuestion("");
    }

    this.setState({
      barTopPosition: scrollableNode.getBoundingClientRect().top,
    });
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      modalTagsType: "",
      modalTagsIndex: null,
    });
  };

  handleOpenModal = (tagType, index, data) => {
    this.setState({
      showModal: true,
      modalTagsType: tagType,
      modalTagsIndex: index,
      modalTagsData: data,
    });
  };

  openImageManagerModal = (data) => {
    this.props.openModal({
      type: MODAL_IMAGE_MANAGER,
      data: { modalSize: "lg" },
    });
  };

  render() {
    const { builderAssessments, activeAssessmentId, activeQuestionId, data } = this.props;
    const activeAssessment = builderAssessments.find((item) => item.id === activeAssessmentId);
    let activeQuestion = null;
    let activeQuestionIndex = 0;

    if (activeAssessment && activeQuestionId && activeAssessment.questions.length > 0) {
      activeQuestionIndex = activeAssessment.questions.findIndex(
        (item) => item.id === activeQuestionId
      );

      activeQuestion = activeAssessment.questions.find((item) => item.id === activeQuestionId);
    }

    return (
      <DocumentTitle title="Stemify | Module Builder">
        <SectionBuilder>
          <SectionBody>
            <SectionContent innerRef={(element) => (this.scrollableParent = element)}>
              {activeAssessment ? (
                <React.Fragment>
                  <SectionBuilderBar top={this.state.barTopPosition}>
                    <SectionBuilderBarTitle>
                      <h3>{activeAssessment.name}</h3>

                      <TableDropdown
                        isSettingsButton
                        options={{
                          items: [
                            {
                              btnText: this.state.previewMode ? "Edit" : "Preview",
                              callback: () => this.togglePreviewMode(),
                            },
                            {
                              callback: this.edit(activeAssessment),
                              btnText: "Settings",
                            },
                          ],
                        }}
                      />
                    </SectionBuilderBarTitle>

                    <SectionBuilderBarActions>
                      <VersionDropdown
                        onDelete={(id) => this.deleteVersion(id)}
                        onCreate={() => this.createVersion()}
                        onActivate={(id) => this.setActiveVersion(id)}
                        onCopy={(id) => this.copyVersion(id)}
                        options={activeAssessment.versions}
                      />
                      <ButtonLink
                        onClick={() => this.handleQuestionCreate()}
                        hasIcon
                        violet
                        className="btn-add"
                      >
                        <span>Add Question</span>

                        <IconSvgComponent iconPath={`svg/ico-plus.svg`} />
                      </ButtonLink>
                    </SectionBuilderBarActions>
                  </SectionBuilderBar>

                  {activeAssessment.questions.length > 0 ? (
                    activeAssessment.questions.map((question, qIdx) => {
                      if (question.id === activeQuestionId) {
                        return (
                          <BuilderQuestionBlock
                            key={question.id}
                            allowTitleEdit={this.state.previewMode}
                            question={question}
                            questionIndex={qIdx + 1}
                            handleQuestionUpdate={(data) => this.handleQuestionUpdate(data)}
                            handleDelete={() => this.handleQuestionDelete()}
                            onCreateAnswer={() => this.handleAnswerCreate()}
                            onTagsTriggerClick={() =>
                              this.handleOpenModal("question", qIdx, activeQuestion)
                            }
                            onAnswerTagsTriggerClick={(data, index) =>
                              this.handleOpenModal("answer", index, data)
                            }
                            onAnswersReorder={(items) => this.handleAnswersReorder(items)}
                            onImageUploadClick={(data) => this.openImageManagerModal(data)}
                          />
                        );
                      }
                    })
                  ) : (
                    <NoQuestionsNotification
                      isMain
                      title="Add your first question."
                      iconPath="svg/review-question-empty-alt.svg"
                      onClickAction={() => this.handleQuestionCreate()}
                    />
                  )}
                </React.Fragment>
              ) : (
                <Loader />
              )}
            </SectionContent>

            {activeAssessment && (
              <SectionAside active={this.state.sidebarActive}>
                <ButtonLink
                  onClick={() => this.toggleSidebar()}
                  hasIcon
                  className="btn-toggle-sidebar"
                >
                  {this.state.sidebarActive && (
                    <IconSvgComponent iconPath="svg/ico-sidebar-close.svg" />
                  )}
                  {!this.state.sidebarActive && (
                    <IconSvgComponent iconPath="svg/ico-sidebar-open.svg" />
                  )}
                </ButtonLink>

                <SectionAsideHead>
                  <h5>Question Navigator</h5>
                  <p>Drag and drop to reorder</p>
                </SectionAsideHead>

                <SectionAsideBody>
                  {activeAssessment.questions.length > 0 && activeQuestion ? (
                    <QuestionIndicators
                      questions={activeAssessment.questions}
                      activeItemId={activeQuestion.id}
                      onReorder={(items) => this.handleQuestionsReorder(items)}
                      onQuestionClick={(questionId) => this.onQuestionClick(questionId)}
                    />
                  ) : (
                    <NoQuestionsNotification
                      title="You have no questions."
                      iconPath="svg/review-question-empty-alt.svg"
                    />
                  )}
                </SectionAsideBody>
              </SectionAside>
            )}
          </SectionBody>

          <ModalTagsBuilder
            showModal={this.state.showModal}
            closeModal={this.handleCloseModal}
            data={this.state.modalTagsData}
            type={this.state.modalTagsType}
            index={this.state.modalTagsIndex}
          />
        </SectionBuilder>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    userRole: state.user.role,
    currentAccountId: state.user.currentAccountId,
    accountId: state.user.accountId,
    builderAssessments: state.builderAssessment.items,
    activeAssessmentId: state.builderAssessment.activeAssessmentId,
    activeQuestionId: state.builderAssessment.activeQuestionId,
  }),
  {
    openModal,
    closeModal,
    setActiveBuilderAssessment,
    setActiveBuilderQuestion,
    updateActiveBuilderAssessmentQuestionsOrder,
    createBuilderQuestion,
    updateActiveQuestion,
    createBuilderAnswer,
    updateBuilderAnswersOrder,
  }
)(AssessmentBuilder);
