/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";
import TitleEditable from "styles/components/TitleEditable";

export const BuilderAnswers = styled.div`
  display: block;
`;

export const BuilderAnswer = styled.div`
  border-top: 1px solid rgba(151, 151, 151, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 8px 3px;

  .icon-pic {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    overflow: hidden;
  }

  &.is-draggable {
    background: #f1f5fc;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.15);
  }

  ${media.desktop`
    padding: 8px 10px 8px 3px;
  `}

  ${media.mobile`
    flex-wrap: wrap;
  `}
`;

export const BuilderAnswerContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 75%;

  ${TitleEditable} {
    max-width: 85%;
  }

  h3 {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > * {
    flex-shrink: 0;
  }

  ${media.mobile`
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    min-height: 40px;

    ${TitleEditable} {
      max-width: 85%;
      margin-bottom: 0;
    }

    h3 {
      max-width: 100%;
      white-space: normal;
      text-overflow: initial;
      overflow: visible;
      height: auto;
      word-break: break-all;
      line-height: 1.25;
    }
  `}
`;

export const BuilderAnswerActions = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  button {
    padding: 4px 8px;

    .icon-remove svg path {
      fill: #4a4a4a;
    }

    &:hover {
      .icon-remove svg path {
        fill: ${theme.color.red};
      }
    }
  }

  .icon-answer-state {
    width: 18px;
    height: 18px;
  }

  ${media.mobile`
    width: 100%;
    max-width: 100%;
    justify-content: center;
  `}
`;

export const BuilderAnswerHandle = styled.div`
  cursor: pointer;
  padding: 7px;
`;

export default BuilderAnswer;
