import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { closeModal } from "store/state/ui/actions";
import {
  completePlacementAssessment,
  submitAttempt,
} from "store/state/assessment/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import stemifyRoutes from "../../constants/routes";

import {
  SubmitContainer,
  SubmitIcon,
  SubmitTitle,
  SubmitText,
  SubmitActions,
} from "styles/components/AssessmentSubmit";

class AssessmentSubmissionGuide extends Component {
  handleSubmission = async () => {
    const {
      closeModal,
      completePlacementAssessment,
      history,
      assessmentSeriesId,
      deploymentConfigId,
      courseId,
      assignmentId,
      attemptId,
      submitAttempt,
    } = this.props;

    await submitAttempt({
      assignmentId,
      assessmentSeriesId,
      attemptId,
      deploymentConfigId,
    });
    const redirectRoute = !!courseId
      ? `${stemifyRoutes.courses}/${courseId}/coursework/${assignmentId}/exam/${assessmentSeriesId}/${attemptId}/results`
      : `${stemifyRoutes.assessments}/${deploymentConfigId}/${assessmentSeriesId}/${attemptId}/results`;
    history.push(redirectRoute);
    closeModal();
  };

  handleKeepWorking = () => { this.props.closeModal(); };

  render() {
    const { icon, title, description } = this.props;

    return (
      <SubmitContainer>
        <SubmitIcon>
          <IconSvgComponent iconPath={icon} />
        </SubmitIcon>
        <SubmitTitle isAssessmentSubmissionGuide>{title}</SubmitTitle>
        <SubmitText isAssessmentSubmissionGuide>{description}</SubmitText>
        <SubmitActions>
          <ButtonLink
            transparent
            onClick={this.handleKeepWorking}
            type="button"
            isAssessmentSubmissionGuide
          >
            Keep Working
          </ButtonLink>
          <ButtonLink className="btn-submit" onClick={this.handleSubmission}>
            <span> Submit </span>
            <IconSvgComponent iconPath="svg/ico-check-white.svg" />
          </ButtonLink>
        </SubmitActions>
      </SubmitContainer>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      modal: state.ui.modal,
    }),
    {
      closeModal,
      submitAttempt,
    }
  )(AssessmentSubmissionGuide)
);
