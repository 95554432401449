import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { resetPageNotFound } from "store/state/administration/actions";
import {
  TextLink,
  ErrorButtonLink,
  ErrorH1,
  ErrorH2,
  ErrorP,
  ErrorBody,
  ErrorTable,
} from "styles/components/ErrorPage";
import { FormActions } from "styles/components/Form";

class ErrorPage extends Component {
  goBack = () => {
    this.props.history.goBack();
  };

  componentWillMount() {
    this.props.resetPageNotFound();
  }

  render() {
    return (
      <ErrorTable>
        <ErrorBody>
          <ErrorH1>
            2 + 2 =<ErrorH2> 404? </ErrorH2>
          </ErrorH1>
          <ErrorP>
            Sorry, looks like something didn't add up on our end.
            <br />
            The page you're looking for doesn't exist.
          </ErrorP>
          <FormActions>
            <TextLink onClick={this.goBack}>Go Back</TextLink>
            <ErrorButtonLink to={"/"}>
              <span>Visit Homepage</span>
            </ErrorButtonLink>
          </FormActions>
        </ErrorBody>
      </ErrorTable>
    );
  }
}

export default withRouter(connect(null, { resetPageNotFound })(ErrorPage));
