/**
 * The external dependencies.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import IconSvgComponent from "components/common/IconSvgComponent";
import theme from "styles/theme";

/**
 * Class for switch.
 *
 * @class      Switch (name)
 */
class Switch extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    isActive: this.props.value,
  };

  /**
   * The properties that can be passed to the component.
   *
   * @type {Object}
   */
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.bool,
    showIcon: PropTypes.bool,
    iconOffPath: PropTypes.string,
    iconOnPath: PropTypes.string,
    iconOffAdditionalClass: PropTypes.string,
    iconOnAdditionalClass: PropTypes.string,
  };

  /**
   * Toggles active state of the component.
   *
   * @param {Object} / The event
   * @return {Void}
   */
  toggle = (e) => {
    this.setState(
      ({ isActive }) => ({
        isActive: !isActive,
      }),
      () => {
        this.props.onChange(this.state.isActive);
      }
    );
  };

  componentDidUpdate() {
    if (this.state.isActive !== this.props.value) {
      this.setState({
        isActive: this.props.value,
      });
    }
  }
  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const {
      toggle,
      state: { isActive },
      props: {
        id,
        children,
        showIcon,
        iconOffPath,
        iconOnPath,
        iconOffAdditionalClass,
        dir,
        iconOnAdditionalClass,
      },
    } = this;

    return (
      <Wrapper dir={dir}>
        <Field onChange={toggle} checked={isActive} type="checkbox" id={id} />

        <LabelWrapper>
          {children && <Entry>{children}</Entry>}

          <Label active={isActive} htmlFor={id} />
          {showIcon && !isActive && (
            <IconSvgComponent iconPath={iconOffPath} additionalClass={iconOffAdditionalClass} />
          )}
          {showIcon && isActive && (
            <IconSvgComponent iconPath={iconOnPath} additionalClass={iconOnAdditionalClass} />
          )}
        </LabelWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${(props) =>
    props.dir &&
    `
		float: ${props.dir}
	`}
`;

const Field = styled.input`
  display: none;
`;
const Label = styled.label`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 34px;
  width: 34px;
  flex: 0 0 34px;
  height: 14px;
  background: #cacaca;
  border-radius: 14px;

  &:before {
    content: "";
    position: absolute;
    left: -3px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    border-radius: 50%;
    transition: transform 0.3s, background 0.3s;
  }

  ${(props) =>
    props.active &&
    `
		&:before {
			transform: translateY(-50%) translateX(20px);
			background: ${theme.color.light_blue};
		}
	`}
`;
const Entry = styled.p`
  max-width: 100%;
  flex: 1 1 100%;
  margin: 0 10px 0 0;
  font-size: 14px;
  line-height: 1.29;
  //color: #9B9B9B;

  & + ${Label} {
    margin-top: 3px;
  }
`;
const LabelWrapper = styled.div`
  display: flex;
  max-width: 346px;
`;

export default Switch;
