/**
 * @ External Dependecies
 */
import styled from "styled-components";
import theme from "../theme";
import { media } from "../media";

export const SectionAssessment = styled.section`
  overflow-y: auto;
  display: flex;
  height: calc(100vh - 66px - 47px);
  padding: 122px 40px 40px 40px;
  text-align: center;

  ${media.mobile`
		height: auto;
		padding: 20px 20px;
	`}
`;

export const SectionContainer = styled.div`
  width: 100%;
`;

export const SectionHead = styled.header`
  margin-bottom: 19px;

  ${media.mobile`
		margin-bottom: 30px;
	`}
`;

export const SectionTitle = styled.h3`
  font-weight: 700;
`;

export const SectionBody = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -35px;
`;

export const AssessmentContainer = styled.div`
  min-width: 320px;
  padding: 0 35px;
  text-align: center;

  ${media.mobile`
		margin-bottom: 30px;
	`}
`;

export const AssessmentWrapper = styled.div`
	display: block;
	padding: 27px 15px;
	text-decoration: none;
	cursor: pointer;
	transition: box-shadow .3s;

	.assessment__icon {
		color: ${theme.color.white}
		transition: color .3s;

		path {
			transition: fill .3s, opacity .3s;
		}
	}

	.assessment__button {
		opacity: 0;
		visibility: hidden;
		transition: .3s;
	}

	${(props) =>
    props.disabled &&
    `
    	opacity: .5;
	`}

	${(props) =>
    !props.disabled &&
    `
		&:hover {
			box-shadow: 0 11px 20px rgba(0,0,0, .15);
            border-radius: ${theme.border_radius};
            background: #FFFFFF;
			.assessment__icon {
				color: ${theme.color.dark_blue};

				path {
					fill: ${theme.color.light_blue};
					opacity: 1;
				}
			}

			.assessment__button {
				opacity: 1;
				visibility: visible;
			}
		}`}


	&:focus {
		box-shadow: 0 11px 20px rgba(0,0,0, .15);

		.assessment__icon {
			color: ${theme.color.dark_blue};

			path {
				fill: ${theme.color.light_blue};
				opacity: 1;
			}
		}

		.assessment__button {
			opacity: 1;
			visibility: visible;
		}
	}

	}

	${media.mobile`
		box-shadow: 0 11px 20px rgba(0,0,0, .15);

		.assessment__icon {
			color: ${theme.color.dark_blue};

			path {
				fill: ${theme.color.light_blue};
				opacity: 1;
			}
		}

		.assessment__button {
			opacity: 1;
			visibility: visible;
		}
	`}

	&:focus:not(:hover) {
		outline: 2px;
		border: 2px solid ${theme.color.blue};
	}
`;

export const AssessmentIcon = styled.div`
  margin-bottom: 9px;
`;

export const AssessmentTitle = styled.h4`
  margin-bottom: 15px;
`;

export const AssessmentStats = styled.ul`
  list-style-type: none;
  margin-bottom: 15px;
  font-size: 14px;
  color: blue;
`;

export const AssessmentStat = styled.li``;

export const AssessmentButton = styled.span`
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  padding: 9px 20px;
  border: 2px solid transparent;
  border-radius: 22px;
  background: ${theme.color.red};
  font-family: ${theme.font};
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: ${theme.color.white};
  text-decoration: none;
  appearance: none;
  transition: background 0.4s;
`;
