import React from "react";
import IconSvgComponent from "components/common/IconSvgComponent";
import StatusPill, { STATUS_TYPES } from "components/elements/StatusPill";
import { FrameBox, FrameCell, FrameCaption, FrameLabel, FrameSpan } from "styles/components/Frame";

const DashboardDetailsCard = ({
  title,
  subheader,
  status,
  detailsList = [],
  iconPath,
  iconClass,
}) => {
  // Get the number of columns (round up to get minimum columns)
  const numberOfDetailColumns = Math.ceil(detailsList.length / 2);
  // Nest details into column arrays of 2 rows
  const detailsColumnsAndRows = [...Array(numberOfDetailColumns)].map((item, index) => {
    const firstRow = detailsList[index * 2];
    const secondRow = detailsList[index * 2 + 1];
    // Its possible to have a single row column
    // If this is the case only return the last name/value pair fro the details list as the firstRow
    return secondRow ? [firstRow, secondRow] : [firstRow];
  });

  return (
    <FrameBox flex>
      <FrameCell flex percent={100 - numberOfDetailColumns * 15}>
        <IconSvgComponent additionalClass={iconClass} iconPath={iconPath} />
        <FrameCell sideGap>
          <FrameCaption>{title}</FrameCaption>
          {subheader && (
            <span
              style={{
                display: "block",
                marginBottom: "8px",
              }}
            >
              {subheader}
            </span>
          )}
          {status && <StatusPill type={STATUS_TYPES.DASHBOARD} status={status} />}
        </FrameCell>
      </FrameCell>

      <FrameCell flex percent={numberOfDetailColumns * 15}>
        {detailsColumnsAndRows.map((detailColumn, index) => {
          return (
            <FrameCell key={index} flex flexColumn percent="100">
              {detailColumn.map((detailRow, index) => {
                return (
                  <FrameSpan key={index} inlineblock padding>
                    <FrameLabel caps>{detailRow.name}: </FrameLabel>
                    <FrameSpan>{detailRow.value}</FrameSpan>
                  </FrameSpan>
                );
              })}
            </FrameCell>
          );
        })}
      </FrameCell>
    </FrameBox>
  );
};

export default DashboardDetailsCard;
