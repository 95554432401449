/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

export const BarContainer = styled.div`
  display: block;
  width: 100%;
  padding: 9px 24px 12px;
  border-radius: ${theme.border_radius};
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

  ${media.mobile`
		padding: 10px 10px;
	`}
`;
export const BarTitle = styled.h6`
  margin-bottom: 0;
  font-size: ${theme.font_size.h6};
  font-weight: 500;
`;

export const Bar = styled.div`
  display: block;
  width: 100%;
  padding: 8px 0;
`;

export const BarInner = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  background: #e2e7fc;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.progress}%;
    height: 100%;
    background: ${theme.color.blue};
  }
`;
