/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import styled from "styled-components";
import { media } from "styles/media";

/**
 * The internal dependencies.
 */
import IconSvgComponent from "components/common/IconSvgComponent";
import theme from "styles/theme";

/**
 * Class for tabs.
 *
 * @class      Tabs (name)
 */
class Tabs extends Component {
  /**
   * The initial state of the component.
   *
   * @type {Object}
   */
  state = {
    activeIndex: 0,
    tabs: this.props.isSteps
      ? this.props.children.map((child) => ({
          name: child.props.title,
          isValid: false,
        }))
      : [null],
  };

  /**
   * Sets the active tab.
   *
   * @param {Number} / The index
   * @return {Void}
   */
  setActiveTab = (idx, flag) => (e) => {
    const tabs = this.state.tabs;
    let isTabValid = true;
    for (let k = 0; k < idx; k++) {
      let val = this.isValid(tabs[k].isValid);
      if (!val && !flag) {
        isTabValid = false;
      }
    }

    if (isTabValid) {
      this.setState({
        activeIndex: idx,
      });
    }
    e.preventDefault();
  };

  /**
   * Set step to valid if validation passes.
   *
   * @param {Number} / The index
   * @param {Boolean} / Indicates if valid
   * @return  {Void}
   */
  toggleStepValidState = (idx, isValid) => {
    const tabs = this.state.tabs;

    if (isValid) {
      tabs[idx].isValid = true;
    } else {
      tabs[idx].isValid = false;
    }

    this.setState({
      tabs: tabs,
    });
  };

  /**
   * Determines if condition is valid.
   *
   * @param {Condition} / The condition
   * @return {Boolean}  True if valid, False otherwise.
   */
  isValid(condition) {
    return condition;
  }

  /**
   * The componentDidMount lifecycle hook of the component.
   *
   * @return {Void}
   */
  componentDidMount() {
    const { modal } = this.props;

    if (modal.data.editDeployment) {
      this.setState(({ tabs }) => ({
        tabs: tabs.map((tab) => ({
          ...tab,
          isValid: true,
        })),
      }));
    }
  }

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const {
      toggleStepValidState,
      props: { children, isSteps, modal },
      state: { activeIndex, tabs },
    } = this;

    if (!children) {
      return (
        <p
          style={{
            textAlign: "center",
          }}
        >
          Put some tab content!
        </p>
      );
    }

    const childrenWithProps = React.Children.map(children, (child, index) => {
      if (index !== activeIndex) return undefined;
      return React.cloneElement(child, {
        index,
        nextStep: () => this.setState({ activeIndex: index + 1 }),
        prevStep: () => this.setState({ activeIndex: index - 1 }),
        toggleStepValidState,
      });
    });

    return (
      <TabsWrapper>
        <TabsHead>
          <TabsNav>
            <TabsNavList>
              {children.map((child, idx) => (
                <TabsNavListItem
                  className={
                    tabs
                      ? classnames({
                          active: this.isValid(activeIndex === idx),
                          completed: this.isValid(
                            idx < activeIndex || tabs[idx].isValid || modal.data.editDeployment
                          ),
                        })
                      : classnames({
                          active: this.isValid(activeIndex === idx),
                        })
                  }
                  key={`tab-${idx}`}
                >
                  <TabsNavBtn
                    onClick={this.setActiveTab(
                      idx,
                      this.isValid(
                        idx < activeIndex || tabs[idx].isValid || modal.data.editDeployment
                      )
                    )}
                  >
                    <span>{child.props.title}</span>
                    {/* {tabs && this.isValid(tabs[idx].isValid || modal.data.editDeployment) && <IconSvgComponent iconPath="svg/ico-tick.svg" />} */}
                    {tabs && this.isValid(idx < activeIndex) && (
                      <IconSvgComponent iconPath="svg/ico-tick.svg" />
                    )}
                  </TabsNavBtn>
                </TabsNavListItem>
              ))}
            </TabsNavList>
          </TabsNav>
        </TabsHead>

        <TabsBody>{isSteps ? childrenWithProps : children}</TabsBody>
      </TabsWrapper>
    );
  }
}

const TabsHead = styled.div`
  margin-bottom: 34px;
`;
const TabsBody = styled.div``;

const TabsNav = styled.nav`
  font-size: 17px;
  color: #9b9b9b;
`;
const TabsNavList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile`
		flex-wrap: wrap;
		justify-content: flex-start;
	`}
`;
const TabsNavBtn = styled.button`
  display: block;
  width: 100%;
  padding: 9px 20px;
  background: none;
  border: 0 none;

  .svg-icon {
    margin-left: 6px;
  }

  span,
  .svg-icon {
    display: inline-block;
    vertical-align: middle;
  }
`;
const TabsNavListItem = styled.li`
  position: relative;
  flex: 1;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    transition: background 0.3s, color 0.3s;
  }

  &.active {
    color: #484848;

    &:after {
      background: ${theme.color.blue};
    }
  }

  &.completed {
    color: ${theme.color.blue};

    &:after {
      display: none;
    }
  }

  &.active.completed {
    &:after {
      display: block;
    }
  }

  ${media.mobile`
		max-width: 100%;
		flex: 1 1 100%;
	`}
`;
const TabsWrapper = styled.div``;

export default connect((state) => ({
  modal: state.ui.modal,
}))(Tabs);
