import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import useAPIData from "components/useAPIData";
import { Card, styles } from "./styles";
import { LoadingSpinner } from "components/card/withSpinnerHOC";
import { StudentBreakdownCard } from "components/card/StudentBreakdownCard";
import { getVideoQuizStudentBreakdownData } from "store/state/courses/actions";

const columns = [
  {
    id: "studentName",
    Header: "Student Name",
    accessor: "studentName",
  },
  {
    id: "questionsAttempted",
    Header: "Questions Attempted",
    accessor: "questionsAttempted",
  },
  {
    id: "questionsCompleted",
    Header: "Questions Completed",
    accessor: "questionsCompleted",
  },
];

const mobileColumns = (student) => ({
  studentName: {
    title: "Student Name",
    value: student.studentName,
  },
  questionsAttempted: {
    title: "Questions Attempted",
    value: student.questionsAttempted,
  },
  questionsCompleted: {
    title: "Questions Completed",
    value: student.questionsCompleted,
  },
});

export const VideoQuizStudentBreakdownCard = ({ assignmentId }) => {
  const tableParams = useSelector((state) => state.storedCourses.studentBreakDownTable);
  const assignedStudents = useSelector(
    (state) => state.storedCourses.assignments[assignmentId]?.studentId
  );
  const courseStudents = useSelector((state) => state.storedCourses.assignedStudents);
  const dispatch = useDispatch();

  const getDataCallback = useCallback(async () => {
    const studentBreakdownData = await dispatch(getVideoQuizStudentBreakdownData({ assignmentId }));
    const allAssignedStudents = assignedStudents
      ? { [assignedStudents]: courseStudents[assignedStudents] }
      : courseStudents;

    const mergedStudentBreakdownData = Object.values(allAssignedStudents)
      .filter((s) => s.isActive)
      .map((student) => {
        const matchingBreakdownStudent = studentBreakdownData
          ? studentBreakdownData.find(
              (breakdownStudent) => breakdownStudent.studentName === student.id
            )
          : "";

        return {
          questionsAttempted: 0,
          questionsCompleted: 0,
          ...(matchingBreakdownStudent || {}),
          studentName: student.name,
        };
      });

    return mergedStudentBreakdownData;
  }, [assignmentId]);

  return (
    <StudentBreakdownCard
      columns={columns}
      mobileColumns={mobileColumns}
      getDataCallback={getDataCallback}
    />
  );
};
