/**
 * The external dependencies.
 */
import styled, { css } from "styled-components";
import { media } from "../media";
import theme from "styles/theme";
import { Link } from "react-router-dom";

const DisciplineCard = styled.div.attrs({
  id: (props) => props.id,
})`
  color: ${theme.color.darker_gray};
  display: flex;
`;

export const DisciplineCardRoute = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${theme.color.white};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 1px 12px 6px rgba(0, 0, 0, 0.25);
  }
`;

export const DisciplineCardTitle = styled.h2.attrs({
  id: (props) => props.id,
})`
  margin: 0 0 5px;
  font-size: 20px;
`;

export const DisciplineCardImage = styled.div`
  background-size: cover;
  background-image: url(${(props) => props.image});
  height: 172px;
`;

export const DisciplineCardContent = styled.div`
  padding: 10px 22px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const DisciplineCardEntry = styled.div`
  margin-top: auto;
  font-size: 17px;
  display: flex;
  align-items: center;

  .svg-icon {
    margin-right: 7px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const DisciplineCards = styled.div.attrs({
  id: (props) => props.id,
})`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -45px;

  ${media.mobile`
    margin: 0 0 -30px;
  `};

  ${DisciplineCard} {
    width: calc(33.33% - 30px);
    margin: 0 15px 45px;

    ${media.tablet_portrait`
      width: calc(50% - 30px);
    `};

    ${media.mobile`
      width: 100%;
      margin: 0 0 30px;
    `};
  }
`;

export default DisciplineCard;
