/**
 * @ External Dependecies
 */
import styled, { css } from "styled-components";
import { media } from "../media";
import theme from "../theme";
import { DropdownWrapper, DropdownButton } from "components/elements/TableDropdown";

export const TableWrapper = styled.div`
  ${(props) =>
    props.height &&
    css`
      width: 100%;
      height: ${props.height}px;
      overflow-y: auto;
      overflow-x: hidden;
      border-top: 1px solid rgba(151, 151, 151, 0.1);
    `}

	${(props) =>
    props.noTopBorder &&
    `
		border-top: 0 none;
	`}

	${media.mobile`
		${(props) =>
      props.height &&
      css`
        overflow-x: auto;

        table {
          min-width: 530px;
        }
      `}
	`}
`;

export const TableHead = styled.thead``;

export const TableCheckWrap = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-right: 16px;
`;

export const TableCheckLabel = styled.label`
  position: relative;
  display: inline-block;
  min-height: 16px;
  min-width: 16px;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #9b9b9b;
    border-radius: 50px;
    opacity: 0.6;
    ${(props) =>
      props.disabled &&
      `
            cursor: context-menu;
    `}
  }
`;

export const TableCheck = styled.input.attrs({
  type: "checkbox",
})`
  display: none;
  &:checked + label:before {
    background-color: ${theme.color.blue};
    background-image: url(/svg/ico-check-tiny.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px 7px;
    border-color: ${theme.color.blue};
    opacity: 1;
  }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  &:last-child {
    td {
      border-bottom: none;
    }
  }
`;

export const TableTh = styled.th`
  padding: 13px 20px;
  font-size: 10px;
  font-weight: bold;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  text-transform: uppercase;
  text-align: left;

  &.table-actions {
    font-size: 0;
    line-height: 0;
    text-align: right;
  }

  ${(props) =>
    props.reactTable &&
    `
    border: none;
  `}

  ${(props) =>
    props.fontWeight &&
    `
		font-weight: ${props.fontWeight};
	`}
`;

export const TableTd = styled.td`
  padding: 13px 20px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  text-align: left;
  position: relative;

  ${(props) =>
    props.focussed &&
    `
		border: 1px solid #2049E6;
	`}

  &.table-actions {
    text-align: right;
    font-weight: bold;
  }

  &:first-child {
    color: #4a4a4a;

    a {
      display: block;
      color: inherit;
      text-decoration: none;
    }

    img {
      margin-right: 8px;
    }

    .svg-icon {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      margin-right: 10px;

      div {
        display: inline-flex;
        align-items: center;
      }
    }

    span {
      display: inline-flex;
      vertical-align: middle;
      line-height: 1.2;
    }
  }

  &.name {
    text-transform: none;
    color: #a3aaad;

    img {
      border-radius: 50%;
    }
  }
`;

export const TableContent = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 115px 15px;
`;

export const StyledSpan = styled.span`
  ${(props) =>
    props.wrappedText &&
    `
		display: inline-block;
		word-break: break-all;
  `}
  padding-top : 5px;
  ${(props) =>
    props.hasIconOnLeft &&
    `
		display: inline !important;
		width: 80%;
	`}
  ${(props) =>
    props.bold &&
    `
		font-weight: bold;
	`}
`;
// removed float : right; to fix space between icon and text

export const TableLogo = styled.div`
  margin-bottom: 7px;
`;

export const TableTitle = styled.h3`
  margin-bottom: 4px;
  margin-top: 23px;
  color: #484848;
`;

export const TableEntry = styled.p`
  margin-bottom: 30px;
  color: #a3aaad;

  ${(props) =>
    props.textColor &&
    `
      color: ${props.textColor}
    `}
`;

export const TableActions = styled.div``;

const TableDefault = styled.table`
	${(props) =>
    props.boxed &&
    css`
      ${TableTh} {
        padding: 0 20px 13px 27px;
      }

      ${TableTd} {
        padding: 17px 20px 17px 27px;
      }
    `}

	${(props) =>
    props.headerFixed &&
    css`
      width: 100%;

      @media (min-width: 767px) {
        thead th {
          height: 0;
          line-height: 0;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          color: transparent;
          border: none;
          white-space: nowrap;
        }

        thead th div {
          position: absolute;
          background: transparent;
          padding: 9px 25px;
          top: 0;
          margin-left: -25px;
          line-height: normal;
          color: #484848;
        }
      }

      ${media.mobile`
			thead th {
				padding-top: 13px !important;
			}

			thead th div {
				display: none;
			}
		`}
    `}

	${(props) =>
    props.tableUsers &&
    css`
      font-size: 12px;

      .with-checkbox {
        position: relative;
        padding-left: 30px;
        line-height: 1.2;

        .checkbox {
          position: absolute;
          left: 0;
          top: 1px;
          margin-right: 0;
        }
      }

      ${TableTh} {
        &:first-child {
          div {
            padding: 9px 25px 9px 57px;
            margin-left: -27px;
          }
        }
      }

      ${TableTd} {
        color: #9b9b9b;
        padding: 24px 20px 24px 27px;

        &:first-child {
          div {
            font-weight: 500;
          }
        }
      }
    `}
`;

export const TableContainer = styled.div`
  position: relative;
  background: #fff;
  padding-top: 31px;

  ${media.mobile`
      ${(props) =>
        props.isResponsive &&
        css`
        overflow: auto;
        padding-bottom: 10px;

        ${DropdownWrapper} {
          right: 38px;
          top: -41px;
          width: 100px;

          &:before {
            top: 33px;
            right: -5px
            width: 16px;
            height: 17px;
          }
        }

        ${DropdownButton} {
          padding: 5px 10px;
        }

        ${TableTd},
        ${TableTh} {
          padding: 10px;
          white-space: nowrap;
        }

      `}
  `}

  ${(props) =>
    props.isCourseTable &&
    `
      padding-top: 13px;
      border-radius: 8px;

      td:last-child {
          width: 0px;
      }
  `}

  ${media.mobile`
      ${(props) =>
        props.isCourseTable &&
        `
        font-size: 13px;

        span {
            word-break: break-all;
        }

        td {
            padding: 10px !important;
        }
    `}
  `}
`;

export const TableSpanStatus = styled.span`
  border-radius: 12px;
  width: 49.4px;
  font-weight: 500;
  text-align: center;
  padding: 5px 10px;
  text-transform: capitalize;
  border: solid 2px;

  ${(props) =>
    props.IsActive &&
    css`
      background-color: #1cc379;
      color: #ffffff;
      border: none;
    `}
`;

export default TableDefault;
