import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import DocumentTitle from "react-document-title";
import moment from "moment";
import { connect } from "react-redux";
import { isEmpty } from "ramda";

import { PaginatedTable, NoDataComponent } from "components/common/table";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import List, { ListItem } from "styles/components/List";

import SectionTable, { SectionBody } from "styles/components/SectionTable";
import TableDropdown from "components/elements/TableDropdown";

import { StyledSpan } from "styles/components/TableDefault";

import { MODAL_CREATE_BUILDER_ASSESSMENT } from "lib/constants";
import { openModal, closeModal } from "store/state/ui/actions";
import {
  setActiveBuilderAssessment,
  deleteBuilderAssessment,
} from "store/state/builderAssessment/actions";

class AssessmentBuilderList extends Component {
  state = {
    table: {
      pageSize: 10,
      page: 0,
      pages: null,
      count: 0,
    },
  };

  getEditState = (assessment) => {
    return {
      id: assessment.id,
      name: assessment.name,
      randomizeOrder: assessment.randomizeOrder,
    };
  };

  edit = (assessment) => (e) => {
    this.props.openModal({
      type: MODAL_CREATE_BUILDER_ASSESSMENT,
      data: {
        id: assessment.id,
        editItem: this.getEditState(assessment),
      },
    });
  };

  manageBulderAssessment = (id) => (e) => {
    const url = this.props.location.pathname;
    this.props.setActiveBuilderAssessment(id);

    this.props.history.push(`assessment-builder/${id}`);
  };

  deleteAssessment = (id) => (e) => {
    this.props.deleteBuilderAssessment(id);
  };

  columns = [
    {
      id: "name",
      Header: "Name",
      width: 290,
      accessor: "name",
      Cell: ({ original }) => (
        <ButtonLink type="button" isFrameLink onClick={this.manageBulderAssessment(original["id"])}>
          <IconSvgComponent
            additionalClass={"accounts-building-icon"}
            iconPath="svg/ico-board-blue.svg"
          />
          <StyledSpan wrappedText hasIconOnLeft bold>
            {original["name"]}
          </StyledSpan>
        </ButtonLink>
      ),
    },
    {
      id: "versionNumber",
      Header: "# of Versions",
      accessor: "versionNumber",
      width: 165,
    },
    {
      id: "createdBy",
      Header: "Created By",
      accessor: "createdBy",
      width: 185,
    },
    {
      id: "modifiedBy",
      Header: "Modified By",
      accessor: "modifiedBy",
      width: 175,
    },
    {
      id: "createdDate",
      Header: "Date Created",
      accessor: "createdDate",
      width: 180,
      Cell: ({ original }) => moment(original.createdDate).format("MM.DD.YY"),
    },
    {
      id: "modifiedDate",
      Header: "Date Modified",
      accessor: "modifiedDate",
      width: 160,
      Cell: ({ original }) =>
        original.modifiedDate ? moment(original.modifiedDate).format("MM.DD.YY") : "",
    },
    {
      id: "dropdown",
      Header: "",
      width: 56,
      style: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      },
      Cell: (row) => {
        const assessment = row.original;

        return (
          <TableDropdown
            options={{
              items: [
                {
                  callback: this.manageBulderAssessment(assessment.id),
                  btnText: "Edit",
                },
                {
                  callback: this.edit(assessment),
                  btnText: "Settings",
                },
                {
                  callback: this.deleteAssessment(assessment.id),
                  btnText: "Archive",
                },
              ],
            }}
          />
        );
      },
    },
  ];

  getData = ({ name, versionNumber, createdBy, modifiedBy, createdDate, modifiedDate }) => {
    return {
      name,
      versionNumber,
      createdBy,
      modifiedBy,
      createdDate,
      modifiedDate,
    };
  };

  getMobileData = ({ name, versionNumber, createdBy, modifiedBy, createdDate, modifiedDate }) => {
    return {
      name: {
        title: "Name",
        value: name,
      },
      versionNumber: {
        title: "# of versions",
        value: versionNumber,
      },
      createdBy: {
        title: "Created By",
        value: createdBy,
      },
      modifiedBy: {
        title: "Modified By",
        value: modifiedBy,
      },
      createdDate: {
        title: "Date Created",
        value: createdDate,
      },
      modifiedDate: {
        title: "Date Modified",
        value: modifiedDate,
      },
    };
  };

  renderMobileItems = (data, item, i) => {
    if (item === "name") {
      return (
        <ListItem key={item}>
          <strong>{this.getMobileData(data)[item].title}:</strong>

          <ButtonLink type="button" isFrameLink onClick={this.manageBulderAssessment(data.id)}>
            <IconSvgComponent
              additionalClass={"accounts-building-icon"}
              iconPath="svg/ico-board-blue.svg"
            />

            <span>{this.getMobileData(data)[item].value}</span>
          </ButtonLink>
        </ListItem>
      );
    } else if (isEmpty(this.getMobileData(data)[item].value)) {
      return (
        <ListItem key={item}>
          <strong>{this.getMobileData(data)[item].title}:</strong>

          <span>—</span>
        </ListItem>
      );
    } else if (item === "createdDate" || item === "modifiedDate") {
      return (
        <ListItem key={item}>
          <strong>{this.getMobileData(data)[item].title}:</strong>

          <span>{moment(this.getMobileData(data)[item].value).format("MM.DD.YY")}</span>
        </ListItem>
      );
    } else {
      return (
        <ListItem key={item}>
          <strong>{this.getMobileData(data)[item].title}:</strong>

          <span>{this.getMobileData(data)[item].value}</span>
        </ListItem>
      );
    }
  };

  render() {
    const { openModal, builderAssessments } = this.props;
    const { page, pages, pageSize, count } = this.state.table;

    return (
      <DocumentTitle title="Stemify | Module Builder">
        <SectionTable>
          <ButtonLink
            className="button-add"
            violet
            fab
            size="43"
            onClick={() => openModal({ type: MODAL_CREATE_BUILDER_ASSESSMENT })}
          />
          <SectionBody>
            <React.Fragment>
              <MediaQuery maxWidth={1023}>
                {!isEmpty(builderAssessments) && (
                  <List>
                    {builderAssessments.map((assessment, idx) => (
                      <ListItem key={assessment.id}>
                        <ul>
                          {Object.keys(this.getData(assessment)).map((item, i) =>
                            this.renderMobileItems(assessment, item, i)
                          )}
                        </ul>

                        <TableDropdown
                          options={{
                            items: [
                              {
                                callback: this.manageBulderAssessment(assessment.id),
                                btnText: "Edit",
                              },
                              {
                                callback: this.edit(assessment),
                                btnText: "Settings",
                              },
                              {
                                callback: this.deleteAssessment(assessment.id),
                                btnText: "Archive",
                              },
                            ],
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </MediaQuery>

              <MediaQuery minWidth={1024}>
                <PaginatedTable
                  data={builderAssessments}
                  columns={this.columns}
                  page={page}
                  pages={pages}
                  pageSize={pageSize}
                  count={count}
                  noDataComponent={() => (
                    <NoDataComponent
                      type="Assessment"
                      iconPath="svg/ico-rocket-dark-lg.svg"
                      onClickAction={() => openModal({ type: MODAL_CREATE_BUILDER_ASSESSMENT })}
                    />
                  )}
                />
              </MediaQuery>
            </React.Fragment>
          </SectionBody>
        </SectionTable>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    userRole: state.user.role,
    currentAccountId: state.user.currentAccountId,
    accountId: state.user.accountId,
    apiCallInProgress: state.administration.apiCallInProgress,
    builderAssessments: state.builderAssessment.items,
  }),
  {
    openModal,
    closeModal,
    setActiveBuilderAssessment,
    deleteBuilderAssessment,
  }
)(AssessmentBuilderList);
