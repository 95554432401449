import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ButtonLink from "components/common/ButtonLink";
import { IMAGE_S3_SERVER } from "constants/api";
import "katex/dist/katex.min.css";
import { FormActions, FormBody } from "styles/components/Form";
import IconSvgComponent from "components/common/IconSvgComponent";
import { FormattedText } from "../common/FormattedText";
import AnswerOptionsContainer from "components/views/AnswerOptionsContainer";
import {
  QuizWrap,
  QuizHead,
  QuizFeedback,
  QuizFeedbackText,
  QuizFormWrapper,
  QuizForm,
  Formula,
  QuizScrollbar,
  FeedBackWrap,
} from "styles/components/Quiz";
import {
  AssessmentQuestionItem,
  AssessmentQuestionItemContainer,
  AssessmentQuestionImage,
  AssessmentAnswers,
  VideoQuestionText,
  VideoOptionImage,
} from "styles/components/AssessmentQuestions";
import { sortBy } from "ramda";
import { VideoQuiz } from "styles/components/Quiz";

const Question = ({ q }) => {
  return (
    <Formula>
      <FormattedText text={q}></FormattedText>
    </Formula>
  );
};

class QuizScreen extends Component {
  state = {
    answer: null,
  };

  handleSelect = (id) => {
    //STEM-327
    // console.log(this.props.cuePoint);
    const answer = this.props.cuePoint.answers.find((a) => a.id === id);
    // 1. Update selected answer state
    this.setState({ answer });
  };

  handleSubmit = (e) => {
    // 1. Prevent page reload on form submission
    e.preventDefault();
    // 2. Pass selected answer up to parent for submitting answer
    const { answer } = this.state;
    this.props.submitQuizAnswer(answer);
  };

  isChecked = (id) => {
    return this.state.answer && this.state.answer.id == id;
  };

  onInputChange = (e) => {
    let id = e.target.value;
    const answer = this.props.cuePoint.answers.find((a) => a.id === id);
    // 1. Update selected answer state
    this.setState({ answer });
  };

  render() {
    const { answer } = this.state;
    const {
      cuePoint,
      onFeedback,
      isCorrect,
      handleFeedback,
      skipCuePoint,
      feedbackText,
      selectedCuePoint,
      apiCallInProgress,
    } = this.props;
    const attemptCount =
      selectedCuePoint && selectedCuePoint.length > 0 ? selectedCuePoint[0].attemptCount : 0;

    return (
      <QuizScrollbar
        renderTrackHorizontal={(props) => (
          <div {...props} className="scrollbar__track-horizontal" />
        )}
        renderTrackVertical={(props) => <div {...props} className="scrollbar__track-vertical" />}
      >
        <QuizWrap isFeedback={onFeedback}>
          <QuizHead>
            {onFeedback ? (
              isCorrect ? (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "25px",
                  }}
                >
                  <FeedBackWrap>
                    <QuizFeedback title="You're correct!">
                      {feedbackText ? (
                        <FormattedText text={feedbackText}></FormattedText>
                      ) : (
                        "You're correct!"
                      )}
                    </QuizFeedback>
                    <IconSvgComponent
                      iconPath={
                        attemptCount == 1 ? "svg/correct-cap-ic.svg" : "svg/correct-icon.svg"
                      }
                    />
                    <ButtonLink
                      isUnstyledButton
                      margintop={30}
                      type="submit"
                      red
                      onClick={handleFeedback}
                      title="Submit"
                    >
                      <IconSvgComponent iconPath="svg/continue-btn.svg" title="submit" />
                    </ButtonLink>
                  </FeedBackWrap>
                </div>
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "25px",
                  }}
                >
                  <FeedBackWrap>
                    <QuizFeedback title="You're wrong!">So close :(</QuizFeedback>
                    <IconSvgComponent iconPath="svg/wrong-icon.svg" />
                    <QuizFeedbackText>
                      {feedbackText ? (
                        <FormattedText text={feedbackText}></FormattedText>
                      ) : (
                        "We'll show you where you went wrong."
                      )}
                    </QuizFeedbackText>
                    <ButtonLink
                      type="submit"
                      violet
                      hasIcon
                      margintop={30}
                      isPrevious
                      onClick={handleFeedback}
                    >
                      <IconSvgComponent iconPath="svg/ico-arrow-left-large.svg" />
                      <span>Show Me</span>
                    </ButtonLink>

                    <ButtonLink
                      transparent
                      type="submit"
                      margintop={15}
                      videoquiz
                      color={"#FFFFFF"}
                      onClick={skipCuePoint}
                    >
                      <span>Skip correcting this answer</span>
                    </ButtonLink>
                  </FeedBackWrap>
                </div>
              )
            ) : (
              <VideoQuiz>
                <AssessmentQuestionItem>
                  <AssessmentQuestionItemContainer>
                    <AssessmentQuestionImage fullwidth>
                      {cuePoint.description.map((dataItem, index) => {
                        if (dataItem.type === "TEXT") {
                          return (
                            <VideoQuestionText
                              key={`{text_${index}}`}
                              layout={cuePoint.layout}
                              widthCounter={cuePoint.description.length}
                              floatCounter={index}
                            >
                              <FormattedText text={dataItem.text}></FormattedText>
                            </VideoQuestionText>
                          );
                        } else {
                          return (
                            <VideoOptionImage
                              key={`{image_${index}}`}
                              layout={cuePoint.layout}
                              widthCounter={cuePoint.description.length}
                            >
                              <img
                                key={dataItem.src}
                                src={`${IMAGE_S3_SERVER}${dataItem.src}`}
                                alt={dataItem.src}
                              />
                            </VideoOptionImage>
                          );
                        }
                      })}
                    </AssessmentQuestionImage>
                  </AssessmentQuestionItemContainer>
                </AssessmentQuestionItem>
                <QuizForm onSubmit={this.handleSubmit}>
                  <QuizFormWrapper>
                    <AssessmentAnswers>
                      <AnswerOptionsContainer
                        answers={sortBy((obj) => obj.displayOrder, cuePoint.answers)}
                        isChecked={this.isChecked}
                        onInputChange={this.onInputChange}
                        isVideoQuiz
                        type={"video"}
                      ></AnswerOptionsContainer>
                    </AssessmentAnswers>
                    <FormActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonLink
                        isUnstyledButton
                        type="submit"
                        red
                        disabled={!answer || apiCallInProgress}
                      >
                        <IconSvgComponent iconPath="svg/submit-btn.svg" />
                      </ButtonLink>
                    </FormActions>
                  </QuizFormWrapper>
                </QuizForm>
              </VideoQuiz>
            )}
          </QuizHead>
        </QuizWrap>
      </QuizScrollbar>
    );
  }
}

export default connect((state) => ({
  selectedCuePoint: state.video.cuePoints
    ? state.video.cuePoints.filter((c) => c.id === state.video.currentCuePointId)
    : [],
  apiCallInProgress: state.video.apiCallInProgress,
}))(QuizScreen);
