/**
 * The external dependencies.
 */
import React from "react";

/**
 * The internal dependencies.
 */
import Grid, { Col } from "styles/components/Grid";
import { SectionWizard, SectionTitle } from "styles/components/Section";
import Box from "components/box";
import {
  WIZARD_PROGRAM_ASSESSEMENT,
  WIZARD_PROGRAM_ASSESSEMENT_FROM_TEMPLATE,
} from "lib/constants";

const ProgramAssessment = ({ changeStep }) => {
  return (
    <SectionWizard>
      <SectionTitle>Create Assessment</SectionTitle>

      <Grid justifyCenter alignCenter>
        <Col size1>
          <Box
            icon="ico-create-scratch"
            title="Create from Scratch"
            entry="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            btnText="Create"
          />
        </Col>

        <Col size1>
          <Box
            icon="ico-create-template"
            title="Create from Template"
            entry="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            btnText="Create"
            btnClick={changeStep(
              WIZARD_PROGRAM_ASSESSEMENT_FROM_TEMPLATE,
              WIZARD_PROGRAM_ASSESSEMENT
            )}
          />
        </Col>
      </Grid>
    </SectionWizard>
  );
};

export default ProgramAssessment;
