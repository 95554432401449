import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { update } from "store/state/administration/actions";
import { resetApiErrors } from "store/state/administration/actions";
import { closeModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Form, {
  FormHead,
  FormLogo,
  FormBody,
  FormTitle,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
} from "styles/components/Form";
import { FORM_CREATE_COHORT_MESSAGES } from "constants/messages";
import { OPERATIONS } from "constants/common";
import {
  validateField,
  validateSelectField,
  validateDateField,
  setValidityAndMessage,
} from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { createCohort, updateCohort } from "store/state/administration/actions";
import { semesters } from "constants/dropdownOptions";
import { addNotification } from "store/state/ui/actions";
import { LEVELS, createNotification } from "utilities/notification";

class FormCreateCohort extends Component {
  state = {
    name: "",
    semester: "",
    startDate: "",
    endDate: "",
    formErrors: {
      name: {
        valid: true,
        message: "",
      },
      semester: {
        valid: true,
        message: "",
      },
      startDate: {
        valid: true,
        message: "",
      },
      endDate: {
        valid: true,
        message: "",
      },
      dates: {
        valid: true,
        message: "",
      },
      formValid: true,
    },
  };

  rules = {
    name: {
      requiredMessage: FORM_CREATE_COHORT_MESSAGES.COHORT_NAME_REQUIRED,
      maxMessage: FORM_CREATE_COHORT_MESSAGES.COHORT_NAME_MAX,
    },
    semester: {
      requiredMessage: FORM_CREATE_COHORT_MESSAGES.SEMESTER_REQUIRED,
    },
    startDate: {
      requiredMessage: FORM_CREATE_COHORT_MESSAGES.START_DATE_REQUIRED,
      typeDateMessage: FORM_CREATE_COHORT_MESSAGES.START_DATE_INVALID,
      valueGreaterThan: "endDate",
      fromToInvalidMessage: FORM_CREATE_COHORT_MESSAGES.START_DATE_GREATER_THAN_END_DATE,
    },
    endDate: {
      requiredMessage: FORM_CREATE_COHORT_MESSAGES.END_DATE_REQUIRED,
      typeDateMessage: FORM_CREATE_COHORT_MESSAGES.END_DATE_INVALID,
    },
  };

  handleInputChange = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  isEdit() {
    return this.props.modal.data && this.props.modal.data.editItem;
  }

  handleStaticChange = (key) => (value) => {
    if (key === "startDate") {
      this.setState({
        [key]: value.clone().startOf("day"),
      });
    } else if (key === "endDate") {
      this.setState({
        [key]: value.clone().endOf("day"),
      });
    } else {
      this.setState({
        [key]: value,
      });
    }
  };

  validateTextField = (element, trimValues) => {
    this.setState({
      formErrors: validateField(this.state.formErrors, this.rules, element, "", trimValues),
    });
  };

  validateDropDownField = (properties) => {
    this.setState({
      formErrors: validateSelectField(this.state.formErrors, this.rules, properties),
    });
  };

  validateFormDateField = (name, value) => {
    const formErrors = validateDateField(this.state.formErrors, this.rules, {
      name,
      value,
      required: true,
      compareWith: name === "startDate" ? this.state.endDate : this.state.startDate,
    });

    this.setState({
      formErrors,
    });
  };

  validateDates() {
    setValidityAndMessage("dates", this.state.formErrors, true, "");

    this.validateFormDateField("startDate", this.state.startDate);
    this.validateFormDateField("endDate", this.state.endDate);

    if (this.state.formErrors.formValid) {
      this.setState({
        formValid: this.state.formErrors.formValid,
      });
    }
  }

  validateAllFields = () => {
    this.validateTextField(document.getElementById("name"), true);
    this.validateDropDownField({ name: "semester", value: this.state.semester, required: true });
    this.validateDates();
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.validateAllFields();

    if (this.state.formErrors.formValid) {
      e.preventDefault();
      const { createCohort, updateCohort, closeModal, accountId } = this.props;
      const trimmedName = this.state.name.trim();

      const apiCall = this.isEdit()
        ? updateCohort({
            cohort: {
              name: trimmedName,
              semester: this.state.semester.value,
              startDate: this.state.startDate.format(),
              endDate: this.state.endDate.format(),
              isActive: this.props.modal.data.editItem.is_active,
            },
            accountId,
            cohortId: this.props.modal.data.id,
          })
        : createCohort({
            selector: "cohorts",
            accountId: accountId,
            item: {
              ...this.state,
              startDate: this.state.startDate.format(),
              endDate: this.state.endDate.format(),
              name: trimmedName,
            },
          });

      apiCall.then(({ status }) => {
        if (status === "success") {
          this.props.addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              "Success",
              this.isEdit() ? "Updated cohort successfully!" : "Created cohort successfully!",
              5
            ),
          });

          if (this.props.onSave) {
            this.props.onSave();
          } else {
            closeModal();
          }
        }
      });
    }
  };

  componentDidMount() {
    if (this.isEdit()) {
      this.setState({
        ...this.props.modal.data.editItem,
        semester: semesters.find(
          (semesterOption) => semesterOption.value === this.props.modal.data.editItem.semester
        ),
      });
    }
  }

  render() {
    const { name, startDate, endDate, semester, formErrors } = this.state;

    const { apiCallInProgress } = this.props;

    let operation = this.isEdit() ? OPERATIONS.UPDATE : OPERATIONS.CREATE;

    return (
      <Form colSpacing fulloNmobile onSubmit={this.handleFormSubmit}>
        <FormHead>
          <FormLogo>
            {this.isEdit() ? (
              <IconSvgComponent iconPath="svg/ico-users-dark-edit.svg" />
            ) : (
              <IconSvgComponent iconPath="svg/ico-users-dark-add.svg" />
            )}
          </FormLogo>

          <FormTitle>{this.isEdit() ? "Edit Cohort" : "Create Cohort"}</FormTitle>
          <FieldErrors
            formErrors={formErrors}
            apiErrorMessage={this.props.cohortsApiErrors[operation].message}
          ></FieldErrors>
        </FormHead>

        <FormBody>
          <FormRowGrid>
            <FormCol half>
              <FormControls>
                <Field
                  id="name"
                  name="name"
                  onChange={this.handleInputChange("name")}
                  value={name}
                  placeholder="Cohort Name"
                  required
                  max="255"
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  id="semester"
                  name="semester"
                  isSelect
                  onChange={this.handleStaticChange("semester")}
                  options={semesters}
                  value={semester}
                  placeholder="Semester"
                  required
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  icon="ico-calendar"
                  id="startDate"
                  isDateTimePicker
                  onChange={this.handleStaticChange("startDate")}
                  value={startDate || ""}
                  placeholder="Select Start Date"
                  showTimeSelect={false}
                  dateFormat="MM/DD/YYYY"
                  required
                />
              </FormControls>
            </FormCol>

            <FormCol half>
              <FormControls>
                <Field
                  icon="ico-calendar"
                  id="endDate"
                  isDateTimePicker
                  onChange={this.handleStaticChange("endDate")}
                  value={endDate || ""}
                  placeholder="Select End Date"
                  showTimeSelect={false}
                  dateFormat="MM/DD/YYYY"
                  notBefore={startDate}
                  required
                />
              </FormControls>
            </FormCol>
          </FormRowGrid>
        </FormBody>

        <FormActions>
          <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
            <span>{this.isEdit() ? "Update Cohort" : "Create Cohort"}</span>

            <IconSvgComponent iconPath="svg/ico-check-white.svg" />
          </ButtonLink>
        </FormActions>
      </Form>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    apiCallInProgress: state.administration.apiCallInProgress,
    cohortsApiErrors: state.administration.apiErrors.cohorts,
    accountId: state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId,
  }),
  {
    createCohort,
    updateCohort,
    resetApiErrors,
    closeModal,
    addNotification,
  }
)(FormCreateCohort);
