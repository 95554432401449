/**
 * @ External Dependecies
 */
import styled from "styled-components";
import { media } from "../media";
import theme from "../theme";

export const Sidebar = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 60px;
  transition: width 0.4s;
  z-index: 1;

  &.sidebar-open {
    width: 250px;

    .sidebar-content {
      transform: translateX(0);
    }
  }

  ${media.mobile`
		overflow: visible;
		width: 0;

		&.sidebar-open {
			width: 0;

			.sidebar-content {
				transform: translateX(0);
			}
		}
	`}
`;

//
// Sidebar Open
//
export const SidebarOpen = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 14px 0px 14px 0px;
  border: 0;
  border-radius: 0;
  background: ${theme.color.white};
  appearance: none;

  &:hover {
    span {
      background: #d8d8d8;
    }
  }

  &:focus:not(:hover) {
    outline: 2px;
    border: 2px solid ${theme.color.blue};
  }

  ${(props) =>
    props.focused &&
    `:not(:hover) {
		outline: 2px;
		border: 2px solid ${theme.color.blue};
		}`}

  ${media.mobile`
    top: 15px;
  `}
`;

export const SidebarOpenInner = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  transition: background 0.3s;
`;

//
// Sidebar Close
//
export const SidebarClose = styled.button`
  display: block;
  width: 100%;
  padding: 17px 20px;
  border: 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  border-radius: 0;
  background: transparent;
  font-weight: 500;

  &:focus:not(:hover) {
    outline: 2px;
    border: 2px solid ${theme.color.blue};
  }

  ${(props) =>
    props.focused &&
    `:not(:hover) {
		outline: 2px;
		border: 2px solid ${theme.color.blue};
		}`}
`;

export const SidebarCloseInner = styled.span`
  display: flex;
  align-items: center;

  .svg-icon {
    margin-right: 16px;
  }
`;

//
// Sidebar Content
//
export const SidebarContent = styled.div`
  top: 0px;
  right: 0;
  z-index: 5;
  transform: translateX(250px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 250px;
  background: ${theme.color.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11);
  transition: transform 0.4s;
  height: 100%;
`;

//
// Pinned Container
//
export const Pinned = styled.div`
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  width: 100%;
`;

export const PinnedHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;

  &:focus:not(:hover) {
    outline: 2px;
    border: 2px solid ${theme.color.blue};
  }

  ${(props) =>
    props.isOpen != undefined &&
    `&:focus:not(:hover) {
		outline: 2px;
		border: 2px solid ${theme.color.blue};
		}`}
`;

export const PinnedIcon = styled.div`
  display: inline-block;
  padding: 0 6px 0 3px;
`;

export const PinnedText = styled.div`
  flex-grow: 1;
  font-size: 12px;
  font-weight: 400;
`;

export const PinnedTotal = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 34px;
  padding: 3px 5px 2px;
  border-radius: 10px;
  background: ${theme.color.light_blue};
  font-size: 10px;
  font-weight: 700;
  color: ${theme.color.white};
`;

export const PinnedBody = styled.div``;

export const QuestionWrapper = styled.div`
  overflow-y: auto;
  min-height: 30px;
  height: calc(100vh - 168px);
  max-height: calc(100vh - 168px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 0px;
`;

//
// Question Buttons
//

export const QuestionsButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0px;
  width: 100%;
  min-height: 30px;
`;

// ${
//     (props) =>
//         props.isPinnedQuestions == false &&
//         `
// 		//  height: calc(100vh - 220px);
//         //  max-height: calc(100vh - 220px);
//     `}

export const QuestionButton = styled.div`
	padding: 6px 6px;
	margin: 0 5px 5px;
  border-radius: 8px;
	font-size: 15px;
	font-weight: 400;
	text-align: center;
	cursor: pointer;

  .check {
    opacity: 0;
  }

	${(props) =>
    props.current == false &&
    `&:focus:not(:hover) {
		outline: 2px;
		border: 2px solid ${theme.color.blue};
	}`}

	${(props) =>
    props.current &&
    `
		font-weight: 700;
		// color: ${theme.color.light_blue};
    background: #E3EBFA;
	`}

	${(props) =>
    props.completed &&
    `
		figure {
			border-color: ${theme.color.light_blue};
			background: ${theme.color.light_blue};
		}

    .check {
      opacity: 1;
    }
	`}

	${(props) =>
    props.pinned &&
    `
		figure {
			.pin {
				display: block;
			}
		}
	`}
`;

export const QuestionIcon = styled.figure`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 33px;
  height: 33px;
  border: 1px solid #979797;
  border-radius: 50%;
  margin-bottom: 7px;
  background: transparent;

  .pin {
    position: absolute;
    bottom: -2px;
    right: -2px;
    display: none;
  }
`;
