import React, { useMemo, Fragment, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import DocumentTitle from "react-document-title";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import { PaginatedTable, NoDataComponent } from "components/common/table";
import { MODAL_ADD_USERS, MODAL_CREATE_STUDENT, MODAL_REMOVE_STUDENT } from "lib/constants";
import {
  fetchCohortStudents,
  getCohortDetailsById,
  clearCohortDetails,
  requestClearUpdateRowId,
  getCohorts,
} from "store/state/administration/actions";
import { openModal, closeModal, addNotification } from "store/state/ui/actions";
import SectionTable, {
  SectionBody,
  SectionHead,
  SectionTitle,
} from "styles/components/SectionTable";
import { FormActions, CheckboxLabel, Checkbox } from "styles/components/Form";
import Field from "components/common/Field";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import TableDropdown from "components/elements/TableDropdown";
import { StyledSpan } from "styles/components/TableDefault";
import Popover from "components/common/Popover";
import { LEVELS, createNotification } from "utilities/notification";
import { callMigrateCohortEnrollmentAPI } from "services/cohorts";

const FirstNameCell = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
    }}
  >
    <IconSvgComponent iconPath="svg/ico-cohort-user.svg" />
    <StyledSpan
      hasIconOnLeft
      style={{
        width: "fit-content",
        whiteSpace: "nowrap",
        overflowX: "clip",
        textOverflow: "ellipsis",
      }}
    >
      {props.value}
    </StyledSpan>
  </div>
);

const CheckboxCell = (props) => {
  const userId = props.row.original.user_id;
  const { isAllStudentsSelected, selectedStudents, onToggleSelectStudent } = props.meta;
  const isChecked = isAllStudentsSelected || selectedStudents.includes(userId);
  const handleToggleSelectStudent = onToggleSelectStudent;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "2px",
      }}
    >
      <Checkbox
        checked={isChecked}
        onChange={() => handleToggleSelectStudent(userId)}
        id={userId}
      />
      <CheckboxLabel htmlFor={userId} />
    </div>
  );
};

const debounce = (callback, delay) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

const getSearchParams = (filter) => {
  return Object.values(filter)
    .map((set, i) => {
      if (i === 0) return `${set.id}=${set.value}`;
      return `&${set.id}=${set.value}`;
    })
    .join("");
};

const InputFilterComponent = (props) => {
  const columnId = props.column.id;
  const { searchFilter, handleFilterChange } = props.meta;
  const matchingFilter = searchFilter[columnId];

  return (
    <input
      type="text"
      onChange={(event) => handleFilterChange(event, matchingFilter, columnId)}
      value={matchingFilter?.value || ""}
      style={{
        fontSize: "14px",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, .1)",
        backgroundColor: "#fff",
        padding: "5px 7px",
        borderRadius: "3px",
        fontWeight: 400,
        outlineWidth: 0,
      }}
      autoComplete="no"
    />
  );
};

const Students = (props) => {
  const dispatch = useDispatch();
  const { cohortId } = useParams();

  const accountId = useSelector((state) =>
    !!state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId
  );
  const cohorts = useSelector((state) =>
    state.administration.cohorts.filter((c) => c.id !== cohortId)
  );
  const cohortName = useSelector((state) => state.administration.cohortDetails.name);

  const students = useSelector((state) => state.administration.students);
  const studentsTable = useSelector((state) => state.administration.studentsTable);
  const { count } = studentsTable;

  const [isPageLoading, setPageLoading] = useState(true);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isAllStudentsSelected, setAllStudentsSelected] = useState(false);
  const [selectedBulkMoveCohort, setSelectedBulkMoveCohort] = useState(null);
  const [searchFilter, setSearchFilter] = useState({});

  const isSelected = useCallback(
    (userId) => {
      return isAllStudentsSelected || selectedStudents.includes(userId);
    },
    [isAllStudentsSelected, selectedStudents]
  );

  const onToggleSelectStudent = (userId) => {
    setSelectedStudents((prevState) => {
      const isChecked = prevState.includes(userId);
      return isChecked ? prevState.filter((s) => s !== userId) : prevState.concat(userId);
    });
  };

  const edit = useCallback(
    (student) => (e) => {
      dispatch(
        openModal({
          type: MODAL_CREATE_STUDENT,
          data: {
            id: student.id,
            editItem: {
              id: student.id,
              firstName: student.firstName,
              lastName: student.lastName,
              studentId: student.studentId,
              email: student.email,
              year: student.year,
            },
          },
        })
      );
    },
    [dispatch]
  );

  const remove = useCallback(
    (student) => (e) => {
      dispatch(
        openModal({
          type: MODAL_REMOVE_STUDENT,
          data: {
            userHash: student.user_id,
            id: student.id, // this is cognito user hash
            name: `${student.firstName} ${student.lastName}`,
            accountId: accountId,
            cohortId: cohortId,
            selector: "students",
            type: "students",
          },
        })
      );
    },
    [accountId, cohortId, dispatch]
  );

  const toggleSelectAllStudents = () => setAllStudentsSelected((prevState) => !prevState);

  const handleMoveStudents = async () => {
    setPageLoading(true);

    try {
      // Prepare list of students to send
      const currentCohortId = cohortId;
      const nextCohortId = selectedBulkMoveCohort;
      const body = isAllStudentsSelected
        ? students.map((s) => {
            return {
              username: s.user_id,
              fromCohortId: currentCohortId,
              toCohortId: nextCohortId,
            };
          })
        : selectedStudents.map((username) => {
            return {
              username,
              fromCohortId: currentCohortId,
              toCohortId: nextCohortId,
            };
          });

      // Call move students endpoint!
      const result = await callMigrateCohortEnrollmentAPI({ accountId, body });

      if (result.status === "error") throw Error(result.data?.message);

      // Fetch new cohort students
      await dispatch(fetchCohortStudents({ selector: "students", cohort: cohortId, accountId }));

      setSelectedBulkMoveCohort(null);
      setSelectedStudents([]);
      setAllStudentsSelected(false);

      // Fire success toast notification
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            "Success",
            "Students moved successfully!",
            5
          ),
        })
      );

      setPageLoading(false);
    } catch (error) {
      console.log({ error });
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            "Error",
            "There was an issue trying to move students.",
            5
          ),
        })
      );
      setPageLoading(false);
    }
  };

  const fetchData = useCallback(
    async ({ pageSize, pageIndex }) => {
      const searchParams = getSearchParams(searchFilter);
      setPageLoading(true);

      try {
        const cohorts =
          pageIndex > 0
            ? Promise.resolve()
            : dispatch(getCohorts({ accountId, page: 1, pageSize: 100 }));
        const cohort =
          pageIndex > 0
            ? Promise.resolve()
            : dispatch(getCohortDetailsById({ accountId, id: cohortId }));

        const students = dispatch(
          fetchCohortStudents({
            selector: "students",
            cohort: cohortId,
            accountId,
            page: pageIndex + 1,
            pageSize: pageSize,
            searchParams,
          })
        );

        await Promise.all([cohorts, cohort, students]);

        setPageLoading(false);
      } catch (error) {
        console.log({ error });
        setPageLoading(false);
      }
    },
    [accountId, cohortId, dispatch, searchFilter]
  );

  const debouncedSearch = useCallback(
    debounce(async (searchParams) => {
      setPageLoading(true);

      await dispatch(
        fetchCohortStudents({
          selector: "students",
          cohort: cohortId,
          accountId,
          searchParams,
        })
      );

      setPageLoading(false);
    }, 500),
    []
  );

  const handleFilterChange = useCallback(
    async (event, matchingFilter, columnId) => {
      const newFilter = {
        id: columnId,
        value: event.target.value,
      };

      // If the filter key already exists and the value has been updated, updating the matchiing filter
      // If the filter key already exists but the value is empty (deleted/reset) then remove the filter
      // If the filter is new add it
      const { [columnId]: matchFilter, ...restOfFilter } = searchFilter;

      const newSearchFilter = matchingFilter
        ? newFilter.value
          ? { ...searchFilter, [columnId]: newFilter }
          : restOfFilter
        : { ...searchFilter, [columnId]: newFilter };

      setSearchFilter(newSearchFilter);

      const searchParams = getSearchParams(newSearchFilter);

      debouncedSearch(searchParams);
    },
    [debouncedSearch, searchFilter]
  );

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const columns = useMemo(() => {
    const mobileColumns = ["firstName", "lastName", "email"];

    return [
      {
        id: "isSelected",
        accessor: (original, index, row, data, table) => {
          return isSelected(original.user_id);
        },
        minWidth: 60,
        maxWidth: 60,
        sortable: false,
        disableFilters: true,
        Header: () => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2px",
              }}
            >
              <Checkbox
                id="selectAll"
                checked={isAllStudentsSelected}
                onChange={toggleSelectAllStudents}
              />
              <CheckboxLabel htmlFor="selectAll" />
            </div>
          );
        },
        Cell: CheckboxCell,
      },
      {
        id: "firstName",
        Header: "First Name",
        accessor: "firstName",
        minWidth: 150,
        Cell: FirstNameCell,
        Filter: InputFilterComponent,
      },
      {
        id: "lastName",
        Header: "Last Name" || "—",
        accessor: "lastName",
        minWidth: 125,
        Filter: InputFilterComponent,
      },
      {
        id: "studentId",
        Header: "Student ID" || "—",
        accessor: "studentId",
        minWidth: 75,
        Filter: InputFilterComponent,
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        minWidth: 200,
        Filter: InputFilterComponent,
      },
      {
        id: "year",
        Header: "Year",
        accessor: "year.label",
        minWidth: 50,
        Filter: InputFilterComponent,
      },
      {
        id: "dropdown",
        Header: "",
        minWidth: 75,
        disableFilters: true,
        style: {
          display: "flex",
          justifyContent: "flex-end",
        },
        Cell: (props) => {
          const { yPositionStart, tableBodyRef } = props;
          const student = props.row.original;

          return (
            <Popover
              placement="bottom-start"
              portalRef={tableBodyRef}
              yPositionStart={yPositionStart}
              TriggerComponent={(props) => {
                const { setRef, ...triggerProps } = props;

                return (
                  <ButtonLink setRef={setRef} {...triggerProps} isTableDropdownButton>
                    <span />
                    <span />
                    <span />
                  </ButtonLink>
                );
              }}
              actions={[
                {
                  name: "Edit",
                  onClick: edit(student),
                },
                {
                  name: "Remove",
                  onClick: remove(student),
                },
              ]}
            />
          );
        },
      },
    ].filter((column) => {
      if (isMobile) {
        return mobileColumns.includes(column.id);
      }

      return column;
    });
  }, [isMobile, edit, remove, isSelected, isAllStudentsSelected]);

  const cohortOptions = useMemo(() => {
    return cohorts.map((cohort) => {
      return {
        value: cohort.id,
        label: cohort.name,
      };
    });
  }, [cohorts]);

  const selectedCohort = useMemo(() => {
    return cohortOptions.find((cohort) => cohort.value === selectedBulkMoveCohort);
  }, [cohortOptions, selectedBulkMoveCohort]);

  return (
    <DocumentTitle title={cohortName ? `Stemify | ${cohortName}` : "Stemify"}>
      <SectionTable>
        <SectionHead
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "visible",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "25px",
            }}
          >
            <SectionTitle style={{ marginBottom: "0px" }}>Students</SectionTitle>

            {(isAllStudentsSelected || selectedStudents.length > 0) && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Field
                  id="default_cohort_id"
                  isSelect={true}
                  onChange={(selectedCohort) => {
                    setSelectedBulkMoveCohort(selectedCohort.value);
                  }}
                  value={selectedCohort}
                  options={cohortOptions}
                  isSearchable={true}
                  isClearable={true}
                  placeholder="Select Cohort"
                  style={{
                    width: "250px",
                    marginTop: "-8px",
                  }}
                  noOptionsMessage={() => (isPageLoading ? "Loading..." : "No cohorts found")}
                />
                <FormActions>
                  <ButtonLink
                    disabled={!selectedCohort || isPageLoading}
                    violet
                    onClick={handleMoveStudents}
                  >
                    <span>Move</span>
                  </ButtonLink>
                </FormActions>
              </div>
            )}
          </div>

          <ButtonLink
            violet
            size="43"
            onClick={() =>
              dispatch(
                openModal({
                  type: MODAL_ADD_USERS,
                  data: {
                    modalSize: "mdx",
                    accountId,
                    cohortId,
                  },
                })
              )
            }
          >
            Add Students
          </ButtonLink>
        </SectionHead>

        <SectionBody>
          <PaginatedTable
            data={students}
            columns={columns}
            serverSidePagination={true}
            fetchData={fetchData}
            count={count}
            isLoading={isPageLoading}
            isFilteringEnabled={true}
            meta={{
              selectedStudents,
              isAllStudentsSelected,
              onToggleSelectStudent,
              searchFilter,
              handleFilterChange,
              cohorts,
            }}
            NoDataComponent={() => (
              <div style={{ height: "487px", padding: "65px 24px" }}>
                <NoDataComponent
                  type="Student"
                  iconPath="svg/ico_cohorts_people.svg"
                  onClickAction={() =>
                    openModal({
                      type: MODAL_ADD_USERS,
                      data: { modalSize: "mdx", accountId, cohortId },
                    })
                  }
                />
              </div>
            )}
            getTrProps={(state, rowInfo, column, instance) => {
              console.log("STUDENTS", state, rowInfo, instance);
              return isAllStudentsSelected || selectedStudents.includes(rowInfo?.original?.user_id)
                ? {
                    className: "selected",
                  }
                : {};
            }}
          />
        </SectionBody>
      </SectionTable>
    </DocumentTitle>
  );
};

export default Students;
