import { createAction } from "redux-actions";
import { without, includes } from "ramda";
import { OPERATIONS } from "constants/common";
import { COMMON_MESSAGES } from "constants/messages";
import { getUserCurrentAccount, decorateQnAResponse } from "utilities/commonFunctions";
import {
  fetchCourseAssessment,
  fetchAssessmentSeries,
  //submitCourseAssessment,
} from "services/assessment";
import { decorateAssignmentResponse } from "../courses/actions";
import { submitAttemptAPI as submitCourseAssessment } from "data/attempts";

export const startCountdown = createAction("COURSE_START_COUNTDOWN");
export const sidebarToggle = createAction("COURSE_SIDEBAR_TOGGLE");
export const successSetActiveAssessment = createAction("COURSE_SET_ACTIVE_ASSESSMENT");

export const successSetCourseCurrentQuestion = createAction("COURSE_SET_CURRENT_QUESTION");
export const successSetCourseCompletedQuestion = createAction("COURSE_SET_COMPLETED_QUESTION");

export const successSetCoursePinnedQuestion = createAction("COURSE_SET_PINNED_QUESTION");

export const requestAssessmentSeries = createAction("COURSE_REQUEST_ASSESSMENT_SERIES");
export const listAssessmentSeries = createAction("COURSE_LIST_ASSESSMENT_SERIES");
export const rejectAssessmentSeries = createAction("COURSE_REJECT_ASSESSMENT_SERIES");

export const requestCourseAssessmentExam = createAction("REQUEST_COURSE_ASSESSMENT");
export const receiveCourseAssessmentExam = createAction("RECEIVE_COURSE_ASSESSMENT");
export const rejectCourseAssessment = createAction("REJECT_COURSE_ASSESSMENT");

export const requestCompleteCourseAssessment = createAction(
  "COURSE_REQUEST_COMPLETE_PLACEMENT_ASSESSMENT"
);
export const receiveCompleteCourseAssessment = createAction(
  "COURSE_RECEIVE_COMPLETE_PLACEMENT_ASSESSMENT"
);
export const rejectCompleteCourseAssessment = createAction(
  "COURSE_REJECT_COMPLETE_PLACEMENT_ASSESSMENT"
);
export const selectAssessmentSeries = createAction("COURSE_SELECT_ASSESSMENT_SERIES");

export const setCourseCurrentQuestion = ({ questionId, questionIndex }) => {
  return (dispatch, getState) => {
    const state = getState();
    const oldIndex = state.currentQuestionIndex;
    const isAnimationForward = oldIndex > questionIndex ? false : true;
    const { assessmentId, courseId } = state.courseAssessment;

    dispatch(successSetCourseCurrentQuestion({ questionId, questionIndex, isAnimationForward }));
  };
};

export const setCourseCompletedQuestion = ({ questionId, selectedAnswerId }) => {
  return (dispatch, getState) => {
    const { courseAssessment } = getState();
    const { assessmentQuestions, completedQuestions } = courseAssessment;
    const nextCompletedQuestions = {
      ...completedQuestions,
      [questionId]: { selectedAnswerId },
    };

    const nextCompletedQuestionsLength = Object.keys(nextCompletedQuestions).length;
    const questionsLength = assessmentQuestions.length;
    const progress = (nextCompletedQuestionsLength / questionsLength) * 100;

    dispatch(
      successSetCourseCompletedQuestion({
        completedQuestions: nextCompletedQuestions,
        progress,
      })
    );
  };
};

export const setCoursePinnedQuestion = (question) => {
  return (dispatch, getState) => {
    const { courseAssessment: state } = getState();
    const { pinnedQuestions, assessmentId, courseId } = state;
    const nextPinnedQuestions = includes(question, pinnedQuestions)
      ? without(question, pinnedQuestions)
      : [...pinnedQuestions, question];

    dispatch(successSetCoursePinnedQuestion({ pinnedQuestions: nextPinnedQuestions }));
  };
};

export const setActiveAssessment = ({ assessmentId, courseId, assignmentId }) => {
  return (dispatch, getState) => {
    const assignmentTimeLimit = getState().storedCourses.assignments[assignmentId]?.timeLimit;

    dispatch(successSetActiveAssessment({ assessmentId, courseId, assignmentTimeLimit }));
  };
};

export const getCourseAssessment = ({ assessmentId, assignmentId, courseId }) => async (
  dispatch,
  getState
) => {
  dispatch(
    requestCourseAssessmentExam({
      operation: OPERATIONS.GET,
      assignmentId,
    })
  );
  try {
    const accountId = getUserCurrentAccount(getState());
    const response = await fetchCourseAssessment({ assignmentId, accountId });

    const formatResponse = {
      id: response.data.assignment.id,
      name: response.data.assignment.name,
      description: response.data.assignment.description,
      assignmentType: response.data.assignment.assignment_type,
      assessmentId: response.data.assessment_series_id,
      questions: response.data.attempt.questions.map((q) => {
        return {
          id: String(q.question.id),
          layout: q.question.layout,
          type: q.question.type,
          data: q.question.description,
          answers: q.question.answers.map((answer) => {
            return {
              ...answer,
              id: String(answer.id),
              data: answer.description,
            };
          }),
        };
      }),
      attemptId: response.data.attempt.attempt_id,
    };

    if (response && response.status === "success") {
      dispatch(
        receiveCourseAssessmentExam({
          data: decorateQnAResponse(formatResponse),
          assessmentId,
          courseId,
        })
      );

      return formatResponse;
    } else {
      if (response && response.status === "error") {
        dispatch(
          rejectCourseAssessment({
            operation: OPERATIONS.GET,
            errorItem: { message: response.data.message },
          })
        );
        return response;
      }
    }
  } catch (e) {
    dispatch(
      rejectCourseAssessment({
        operation: OPERATIONS.GET,
        errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
      })
    );
    return false;
  }
};

export const completeCourseAssessment = ({ courseId, assessmentId, attemptId }) => {
  return async (dispatch, getState) => {
    dispatch(requestCompleteCourseAssessment({ operation: OPERATIONS.UPDATE }));

    try {
      const state = getState();
      const attemptId = state.courseAssessment.attemptId;
      const completedQuestions = state.courseAssessment.completedQuestions;
      const accountId = getUserCurrentAccount(state);
      const submittedQuestions = Object.keys(completedQuestions).map((q) => {
        return {
          question_id: q,
          answer_id: completedQuestions[q].answer,
        };
      });

      const data = {
        attempt_id: attemptId,
        response: submittedQuestions,
      };
      const response = await submitCourseAssessment({
        attemptId,
        accountId,
      });

      if (response && response.status === "success") {
        dispatch(
          receiveCompleteCourseAssessment({
            data: {
              remainingAttempts: response.data.assignment.attempt_limit - 1,
              cooldown: response.data.assignment.cooldown_days,
              attemptResult: response.data.result,
              attemptScore: response.data.result.score,
              topicInfo: response.data.topicInfo,
              progress: 0,
              assessmentId,
              assignment: decorateAssignmentResponse(response.data.assignment),
            },
          })
        );

        return response;
      } else {
        if (response && response.status === "error") {
          dispatch(
            rejectCompleteCourseAssessment({
              operation: OPERATIONS.UPDATE,
              errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
            })
          );
          return response;
        }
      }
    } catch (e) {
      console.log(e.message);
      dispatch(
        rejectCompleteCourseAssessment({
          operation: OPERATIONS.UPDATE,
          errorItem: { message: COMMON_MESSAGES.ERROR_OCCURRED },
        })
      );
      return false;
    }
  };
};

export { updateQuestionResponse } from "../assessment/actions";
