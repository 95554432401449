/**
 * The external dependencies.
 */
import styled, { css, keyframes } from "styled-components";
import { media } from "../media";
import theme from "styles/theme";
import { Link } from "react-router-dom";

const scaleUpDown = keyframes`
 0% { transform: scale(1); opacity: 0; }
 50% { transform: scale(1.1); opacity: 1; }
 100% { transform: scale(1); opacity: 1; }
`;

const shake = keyframes`
10%, 90% {
  transform: translate3d(-1px, 0, 0);
}

20%, 80% {
  transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
  transform: translate3d(-4px, 0, 0);
}

40%, 60% {
  transform: translate3d(4px, 0, 0);
}
`;

const ModalLearning = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e6eaf1;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s, visibility 0.3s;

  ${(props) =>
    props.active &&
    `
    opacity: 1;
    visibility: visible
  `};
`;

export const ModalLearningStep = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s, visibility 1s;

  .subject-card {
    width: 100%;
    max-width: 290px;
    margin: 0 25px;
    pointer-events: none;

    ${media.tablet_portrait`
      max-width: 230px;
      margin: 0 10px;
    `};
  }

  .subject-card--primary {
    opacity: 0;

    .subject-progress {
      left: -100%;
      transition: left 1s 0.5s;
    }
  }

  .subject-card--focus,
  .subject-card--faded {
    opacity: 0;
  }

  .subject-card--faded {
    transition: opacity 0.5s 2s;

    ${media.mobile`
      display: none;
    `};
  }

  .subject-card--focus {
    transform: translateX(100vw);
    transition: opacity 0.5s 1.5s, transform 0.5s 1.5s;
  }

  &.step-0,
  &.step-1,
  &.step-2 {
    position: relative;
    opacity: 1;
    visibility: visible;

    .modal-title {
      opacity: 1;
      transition: opacity 1s 1.5s;

      .svg-icon {
        animation: ${shake} 1s ease 1.5s;
        animation-iteration-count: 2;
        animation-fill-mode: forwards;
      }
    }

    .modal-actions {
      opacity: 1;
      transition: opacity 1s 1.5s;
    }
  }

  &.step-0 {
    .subject-card--primary {
      animation: ${scaleUpDown} 1s ease 0.5s;
      animation-fill-mode: forwards;
    }

    .subject-card--primary .subject-progress {
      left: 0;
    }
  }

  &.step-1 {
    transition-delay: 1s;

    .modal-title {
      opacity: 1;
      transition: opacity 1s 0.5s;
    }

    .modal-actions {
      opacity: 1;
      transition: opacity 1s 0.5s;
    }

    .subject-card--focus {
      transform: translateX(0);
      opacity: 1;
      transition: opacity 0.5s 1.5s, transform 0.5s 1.5s;
    }

    .subject-card--faded {
      opacity: 0.35;
    }
  }
`;

export const ModalLearningBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ModalLearningContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ModalLearningActions = styled.div.attrs({
  className: "modal-actions",
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  opacity: 0;
  transition: opacity 1s 0s;

  a,
  button {
    font-size: 16px;
    margin: 0 15px;
  }
`;

export const ModalLearningTitle = styled.h2.attrs({
  className: "modal-title",
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1s 0s;

  .svg-icon {
    margin-left: 10px;
  }

  ${media.tablet_portrait`
   font-size: 28px;
  `};

  ${media.mobile`
   font-size: 20px;
  `};
`;

export const ModalBackLink = styled(Link)`
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 16px;

  .svg-icon {
    margin-right: 10px;

    g,
    mask,
    use {
      fill: currentColor;
    }
  }

  &:hover {
    background: #c3cad6;
  }
`;

export default ModalLearning;
