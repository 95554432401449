/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { isEmpty } from "ramda";
import MediaQuery from "react-responsive";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { customChartColors } from "constants/common";

/**
 * The internal dependencies.
 */
import { FORMAT } from "constants/common";
import { StyledSpan } from "styles/components/TableDefault";
import { PaginatedTable, NoDataComponent } from "components/common/table";
import BarCharts from "components/chart/Bar";
import ScoreDistributionTooltip from "components/chart/ToolTips";

import BarChartSmall from "components/chart/BarChartSmall";
import DeploymentChart from "components/chart/DeploymentChart";
import Grid, { Col, Row } from "styles/components/Grid";
import SectionDashboard, { SectionBody } from "styles/components/SectionDashboard";
import Frame, { FrameBox, FrameTitle } from "styles/components/Frame";
import List, { ListStats, ListItem } from "styles/components/List";
import Stat, { StatTitle, StatEntry } from "styles/components/Stat";
import Loader from "components/common/loader";
import { NoDataMessageContainer } from "styles/components/Card";
import { NoDataMessage } from "styles/components/Card";
import DataTable from "components/views/DataTable";

import BasicDeploymentDetails from "components/elements/BasicDeploymentDetails";
import {
  getFacultyDeploymentAssessmentStudentList,
  getFacultyAssessmentDashboardData,
} from "store/state/facultyDashboard/actions";
import ButtonLink from "components/common/ButtonLink";
import stemifyRoutes from "constants/routes";
import InfiniteScroll from "react-infinite-scroll-component";
let colormap = require("colormap");

const basicNumberSort = (a, b) => {
  const first = Number(a);
  const second = Number(b);

  if (first < second) {
    return -1;
  }
  if (first > second) {
    return 1;
  }
  // a must be equal to b
  return 0;
};

const defaultTopicColumns = [
  {
    id: "topic",
    Header: () => <div style={{ fontSize: "12px" }}>Topic Name</div>,
    accessor: "topic",
    minWidth: 200,
  },
  {
    id: "class_overall",
    Header: () => <div style={{ fontSize: "12px" }}>Deployment Average</div>,
    accessor: "class_overall",
    minWidth: 75,
    Cell: (state) => {
      return <span>{state.value}%</span>;
    },
    sortMethod: basicNumberSort,
  },
];

const makeTopicColumns = (exampleRow) => {
  let topicColumns = [].concat(defaultTopicColumns);

  for (let i = 1; i < exampleRow.attempt_limit + 1; i++) {
    topicColumns.push({
      id: `attempt_${i}`,
      Header: (props) => {
        const totalAttempts = props.data.length ? props.data[0][props.column.id].total_attempts : 0;

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <div>{`Attempt ${i}`}</div>
            <div
              style={{
                marginLeft: "8px",
                color: "#4A4A4A",
                fontWeight: "normal",
              }}
            >
              ({totalAttempts})
            </div>
          </div>
        );
      },
      accessor: (row) => row[`attempt_${i}`].attempt_average,
      minWidth: 75,
      sortMethod: basicNumberSort,
      Cell: (props) => {
        const { original } = props.row;
        const hasAtLeastOneAttempted = original[props.column.id].total_attempts > 0;
        const attemptNumber = Number(props.column.id.replace("attempt_", ""));
        const isGreaterThanAttemptOne = attemptNumber > 1;

        if (hasAtLeastOneAttempted && isGreaterThanAttemptOne) {
          const previousAttemptAverage =
            props.row.original[`attempt_${attemptNumber - 1}`].attempt_average;
          const diff = Number(props.value) - Number(previousAttemptAverage);
          const showDiff = diff !== 0;
          const isPositive = diff > 0;
          const diffIcon = isPositive ? "+" : "-";
          const arrowIcon = isPositive ? "↑" : "↓";

          return (
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                alignItems: "center",
              }}
            >
              <span style={{ width: "35px" }}>{props.value}%</span>
              {showDiff && (
                <span
                  style={{
                    marginLeft: "20px",
                    color: isPositive ? "#117F4E" : "#E70F0E",
                  }}
                >
                  {diffIcon}
                  {Math.abs(diff)}{" "}
                  <span style={{ position: "relative", top: "1px" }}>{arrowIcon}</span>
                </span>
              )}
            </span>
          );
        } else if (hasAtLeastOneAttempted) {
          return <span>{props.value}%</span>;
        } else {
          return <span>—</span>;
        }
      },
    });
  }

  return topicColumns;
};

class AssessmentDashboard extends Component {
  state = {
    stats: [],
    completion: {},
    topScoreFilter: null,
    selectedChartArcNumber: null,
    apiCallInProgress: false,
  };

  getData = ({ name, numberOfAttempts, latestScore, latestAttempt, durationOfLatest }) => {
    return {
      name,
      numberOfAttempts,
      latestScore,
      latestAttempt,
      durationOfLatest,
    };
  };

  getTopicData = ({ topic, class_overall }) => {
    return {
      topic,
      class_overall,
    };
  };

  columns = [
    {
      id: "Id",
      Header: "Student ID",
      accessor: "studentId",
      minWidth: 100,
    },
    {
      id: "name",
      Header: "Student Name",
      minWidth: 200,
      accessor: "name",
      Cell: (props) => {
        const { original } = props.row;
        return (
          <ButtonLink
            isRouterLink
            noLeftPadding
            to={`${stemifyRoutes.deployments}/${this.props.match.params.deploymentId}/${this.props.match.params.assessmentId}/${original.id}`}
          >
            <StyledSpan wrappedText bold>
              {original.name}
            </StyledSpan>
          </ButtonLink>
        );
      },
    },
    {
      id: "numberOfAttempts",
      Header: "# of Attempts",
      accessor: "numberOfAttempts",
      minWidth: 50,
    },
    {
      id: "topScore",
      Header: "Top Score",
      accessor: "topScore",
      minWidth: 50,
    },
    {
      id: "latestScore",
      Header: "Latest Score",
      accessor: "latestScore",
      minWidth: 50,
    },
    {
      id: "latestAttempt",
      Header: "Latest Attempt",
      accessor: "latestAttempt",
      minWidth: 100,
      Cell: (props) => {
        const { original } = props.row;
        return moment(original.latestAttempt).format(FORMAT.SHORT_DATE);
      },
    },
    {
      id: "durationOfLatest",
      Header: "Duration of Latest",
      accessor: "durationOfLatest",
      minWidth: 100,
    },
  ].map((c) => ({
    ...c,
    Header: () => <div style={{ fontSize: "12px" }}>{c.Header}</div>,
  }));

  getMobileData = ({
    id,
    name,
    numberOfAttempts,
    latestScore,
    latestAttempt,
    topScore,
    durationOfLatest,
  }) => {
    return {
      name: {
        title: "Student Name",
        value: name,
        id,
      },
      numberOfAttempts: {
        title: "# of Attempts",
        value: numberOfAttempts,
      },
      topScore: {
        title: "Top Score",
        value: topScore,
      },
      latestScore: {
        title: "Latest Score",
        value: latestScore,
      },
      latestAttempt: {
        title: "Latest Attempt",
        value: latestAttempt,
        format: FORMAT.SHORT_DATE,
      },
      durationOfLatest: {
        title: "Duration Of Latest",
        value: durationOfLatest,
        format: FORMAT.TIME_DURATION,
      },
    };
  };

  renderMobileItems = (data, item, i) => {
    var dataItem = this.getMobileData(data)[item];
    if (isEmpty(dataItem.value)) {
      return (
        <ListItem key={item}>
          <strong>{dataItem.title}:</strong>
          <span>—</span>
        </ListItem>
      );
    } else if (item === "name") {
      return (
        <ListItem key={item}>
          <strong>{dataItem.title}:</strong>
          <Link
            className="flex"
            to={`${stemifyRoutes.deployments}/${this.props.match.params.deploymentId}/${this.props.match.params.assessmentId}/${dataItem.id}`}
          >
            <span>{dataItem.value}</span>
          </Link>
        </ListItem>
      );
    } else if (item === "latestAttempt") {
      return (
        <ListItem key={item}>
          <strong>{dataItem.title}:</strong>
          <span>{moment(dataItem.value).format(dataItem.format)}</span>
        </ListItem>
      );
    } else {
      return (
        <ListItem key={item}>
          <strong>{dataItem.title}:</strong>
          <span>{dataItem.value}</span>
        </ListItem>
      );
    }
  };

  onMouseEnter = (idx, number) => (event) => {
    const { chartInstance } = this.chartReference.chartReference;
    const id = chartInstance.id;
    const dataset = chartInstance.data.datasets[0];
    const borderColor = dataset.hoverBorderColor[idx];
    const borderWidth = dataset.hoverBorderWidth;
    const element = dataset._meta[id].data[idx];

    const model = element._model;
    const view = element._view;

    model.borderWidth = borderWidth;
    model.borderColor = borderColor;

    view.borderWidth = borderWidth;
    view.borderColor = borderColor;

    element.draw();

    this.setState({
      selectedChartArcNumber: number,
    });
  };

  onMouseLeave = () => {
    const { chartInstance } = this.chartReference.chartReference;

    if (chartInstance) {
      chartInstance.update();
    }
    this.setState({
      selectedChartArcNumber: null,
    });
  };

  async componentDidMount() {
    const { deploymentId, assessmentId } = this.props.match.params;
    const { getFacultyAssessmentDashboardData, accountId } = this.props;

    await getFacultyAssessmentDashboardData({
      selector: "deploymentAssessmentStats",
      deploymentId,
      assessmentId,
      page: "1",
      pageSize: 0,
      userScore: null,
      account_id: accountId,
    });
  }

  componentWillUnmount() {
    if (this.chartReference) {
      const { chartInstance } = this.chartReference.chartReference;
      chartInstance.destroy();
    }
  }

  handleBarClickEvent = ({ data, shouldReset }) => {
    const { onBarClickRunning } = this.state;

    if (data && !onBarClickRunning) {
      if (shouldReset) {
        this.setState({ topScoreFilter: null });
      } else {
        const score = data.score;

        this.setState({ topScoreFilter: score });
      }
    }
  };

  render() {
    const { topScoreFilter } = this.state;
    const {
      assessmentDetails,
      apiCallInProgress,
      studentData: preFilteredStudentData,
      topicData,
      deploymentDetails,
      studentTable,
      topicTable,
    } = this.props;
    const studentData = topScoreFilter
      ? preFilteredStudentData.filter((d) => d.topScore === topScoreFilter)
      : preFilteredStudentData;

    const { page, pages, pageSize, count } = studentTable;
    const { count: topicCount } = topicTable;

    const { stats, completion, scoreDistribution } = assessmentDetails;

    const colors = colormap({
      colormap: "plasma",
      nshades:
        scoreDistribution && scoreDistribution.graphData.datasets.length > 9
          ? scoreDistribution.graphData.datasets.length + 1
          : 101,
      format: "hex",
      alpha: 1,
    });

    const topicColumns = topicData.length ? makeTopicColumns(topicData[0]) : [];

    return (
      <SectionDashboard modified>
        {!apiCallInProgress || deploymentDetails.length > 0 ? (
          <SectionBody>
            <Grid gutterSmall colSpacing>
              <Row>
                <Col flex>
                  <BasicDeploymentDetails
                    displayName="assessment"
                    deploymentDetails={deploymentDetails}
                    iconURL="svg/ico-assessment-lg.svg"
                    additionalIconClass={"dashboard-assessment-icon"}
                  />
                </Col>
              </Row>
              <Row>
                <Col flex>
                  <FrameBox variant="primary">
                    <FrameTitle white>Score Summary</FrameTitle>

                    <ListStats stats>
                      {stats &&
                        stats.map((stat, idx) => (
                          <ListItem key={stat.title + idx}>
                            <Stat>
                              <StatTitle>{stat.title}</StatTitle>

                              <StatEntry>{stat.entry}</StatEntry>
                            </Stat>
                          </ListItem>
                        ))}
                      {completion && (
                        <ListItem key={"completion"}>
                          <Stat>
                            <StatTitle inline>{completion.completionPercent}% </StatTitle>
                            <StatEntry inline>
                              {completion.completed} / {completion.outOf}
                            </StatEntry>
                            <StatEntry>% completed</StatEntry>
                          </Stat>
                        </ListItem>
                      )}
                    </ListStats>
                  </FrameBox>
                </Col>
              </Row>

              <Row>
                <Col flex>
                  <FrameBox>
                    <FrameTitle>Score Distribution</FrameTitle>
                    <BarCharts
                      config={{
                        options: {
                          dimension: {
                            height: 200,
                          },
                          margin: {
                            top: 0,
                            right: 10,
                            left: 25,
                            bottom: 10,
                          },
                        },
                      }}
                      xAxis={{
                        axisLine: false,
                        tickLine: true,
                        ticks: [],
                        labelText: "SCORE",
                        allowDecimals: false,
                        domain: [],
                      }}
                      yAxis={{
                        axisLine: true,
                        tickLine: false,
                        labelText: "# OF STUDENTS ",
                        allowDecimals: false,
                      }}
                      CustomTooltip={<ScoreDistributionTooltip plasmaColors={colors} />}
                      grid={true}
                      barSize={8}
                      maxBarSize={90}
                      barRadius={10}
                      isGroupBarChart={false}
                      isHorizontalChart={false}
                      dataSet={scoreDistribution.graphData.datasets}
                      dataKeys={{
                        xAxisKey: "score",
                        yAxisKeys: ["studentCount"],
                      }}
                      onBarClick={({ data, shouldReset }) =>
                        this.handleBarClickEvent({ data, shouldReset })
                      }
                    />
                  </FrameBox>
                </Col>
              </Row>

              <Row>
                <Col flex>
                  <FrameBox>
                    <FrameTitle>Topic Breakdown</FrameTitle>
                    <MediaQuery maxWidth={1023}>
                      {topicData && !isEmpty(topicData) && (
                        <InfiniteScroll
                          dataLength={topicData.length}
                          loader={<Loader height={100} width={250} />}
                        >
                          <List>
                            {topicData.map((topic, idx) => (
                              <ListItem key={topic.id}>
                                <ul>
                                  <ListItem>
                                    <strong>Name:</strong>
                                    <span>{topic.topic}</span>
                                  </ListItem>
                                  <ListItem>
                                    <strong>Deployment Average:</strong>
                                    <span>{topic.class_overall}</span>
                                  </ListItem>
                                </ul>
                              </ListItem>
                            ))}
                          </List>
                        </InfiniteScroll>
                      )}
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                      <DataTable
                        classNames={"smaller-font"}
                        data={topicData}
                        columns={topicColumns}
                        loading={apiCallInProgress}
                        count={topicCount || topicData.length}
                        defaultSorted={[
                          {
                            id: "class_overall",
                            desc: true,
                          },
                        ]}
                        noDataComponent={() => (
                          <div
                            style={{
                              height: "487px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <NoDataComponent type="Topic" noSubMessage={true} />
                          </div>
                        )}
                      />
                    </MediaQuery>
                  </FrameBox>
                </Col>
              </Row>

              <Row>
                <Col flex>
                  <FrameBox>
                    <FrameTitle>Student Breakdown</FrameTitle>
                    <MediaQuery maxWidth={1023}>
                      {studentData && !isEmpty(studentData) && (
                        <InfiniteScroll
                          dataLength={studentData.length}
                          loader={<Loader height={100} width={250} />}
                        >
                          <List>
                            {studentData.map((student, idx) => (
                              <ListItem key={student.id}>
                                <ul>
                                  {Object.keys(this.getData(student)).map((item, i) =>
                                    this.renderMobileItems(student, item, i)
                                  )}
                                </ul>
                              </ListItem>
                            ))}
                          </List>
                        </InfiniteScroll>
                      )}
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                      <DataTable
                        classNames={"smaller-font"}
                        data={studentData}
                        columns={this.columns}
                        page={page}
                        pages={pages}
                        pageSize={pageSize}
                        count={count}
                        loading={apiCallInProgress}
                        noDataComponent={() => (
                          <div
                            style={{
                              height: "487px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <NoDataComponent
                              type="Student"
                              iconPath="svg/ico-users-dark-colored.svg"
                              additionalClass={"faculty-assessment-details-student-list"}
                            />
                          </div>
                        )}
                      />
                    </MediaQuery>
                  </FrameBox>
                </Col>
              </Row>
            </Grid>
          </SectionBody>
        ) : (
          <NoDataMessageContainer noIcon>
            {apiCallInProgress == true && <Loader />}
            {apiCallInProgress == false && (
              <NoDataMessage>
                No Dashboard content <br /> available at this time.
              </NoDataMessage>
            )}
          </NoDataMessageContainer>
        )}
      </SectionDashboard>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        assessmentDetails: state.facultyDashboard.deploymentAssessmentStats,
        deploymentDetails: state.facultyDashboard.deployment,
        studentTable: state.facultyDashboard.facultyStudentTable,
        topicTable: state.facultyDashboard.facultyTopicTable,
        apiCallInProgress: state.facultyDashboard.dashboardLoading,
        accountId: !!state.user.currentAccountId
          ? state.user.currentAccountId
          : state.user.accountId,
        studentData: state.facultyDashboard.deploymentAssessmentUserList.users,
        topicData: state.facultyDashboard.topicBreakdown,
      };
    },
    {
      getFacultyDeploymentAssessmentStudentList,
      getFacultyAssessmentDashboardData,
    }
  )(AssessmentDashboard)
);
