import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { MODAL_CREATE_STUDENT } from "lib/constants";
import { openModal, closeModal } from "store/state/ui/actions";
import { addUsersToCohorts } from "store/state/administration/actions";
// import { addUsersToCourse } from "store/state/courses/actions";
import { fetchAccountUsers } from "store/state/administration/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import ModalFrame, { FrameHead, FrameTitle, FrameBody, FrameFoot, FrameAction } from "./ModalFrame";

import { Checkbox, CheckboxLabel } from "styles/components/Form";

import { NoDataComponent, PaginatedTable } from "components/common/table";
import { addNotification } from "store/state/ui/actions";
import { LEVELS, createNotification } from "utilities/notification";

const CheckboxCell = (props) => {
  const userId = props.row.original.id;
  const { isAllStudentsSelected, selectedStudents, onToggleSelectStudent } = props.meta;
  const isChecked = isAllStudentsSelected || selectedStudents.includes(userId);
  const handleToggleSelectStudent = onToggleSelectStudent;
  const instanceId = `enrollment-${userId}`;

  const handleChange = (event) => {
    handleToggleSelectStudent(userId);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "2px",
      }}
    >
      <Checkbox checked={isChecked} onChange={handleChange} id={instanceId} />
      <CheckboxLabel htmlFor={instanceId} />
    </div>
  );
};

const InputFilterComponent = (props) => {
  const columnId = props.column.id;
  const { searchFilter, handleFilterChange } = props.meta;
  const matchingFilter = searchFilter[columnId];

  return (
    <input
      type="text"
      onChange={(event) => handleFilterChange(event, matchingFilter, columnId)}
      value={matchingFilter?.value || ""}
      style={{
        fontSize: "14px",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, .1)",
        backgroundColor: "#fff",
        padding: "5px 7px",
        borderRadius: "3px",
        fontWeight: 400,
        outlineWidth: 0,
      }}
      autoComplete="no"
    />
  );
};

const debounce = (callback, delay) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

const getSearchParams = (filter) => {
  return Object.values(filter)
    .map((set, i) => {
      if (i === 0) return `${set.id}=${set.value}`;
      return `&${set.id}=${set.value}`;
    })
    .join("");
};

const ModalAddUsers = (props) => {
  const dispatch = useDispatch();

  const accountId = useSelector((state) =>
    !!state.user.currentAccountId ? state.user.currentAccountId : state.user.accountId
  );
  const users = useSelector((state) => {
    return state.administration.users;
  });
  const usersTable = useSelector((state) => state.administration.usersTable);
  const modal = useSelector((state) => state.ui.modal);

  const { count } = usersTable;
  const { courseId, cohortId } = modal.data;
  const userRole = modal.data.userRole ? modal.data.userRole : "Student";

  const [isPageLoading, setPageLoading] = useState("");
  const [searchFilter, setSearchFilter] = useState(
    courseId
      ? {
          userRole: {
            id: "userRole",
            value: userRole,
          },
          status: {
            id: "status",
            value: "true",
          },
        }
      : {
          userRole: {
            id: "userRole",
            value: "Student",
          },
          status: {
            id: "status",
            value: "true",
          },
          notInCohortId: {
            id: "notInCohortId",
            value: cohortId,
          },
        }
  );
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isAllStudentsSelected, setAllStudentsSelected] = useState(false);

  const isSelected = useCallback(
    (userId) => {
      return isAllStudentsSelected || selectedStudents.includes(userId);
    },
    [isAllStudentsSelected, selectedStudents]
  );

  const onToggleSelectStudent = (userId) => {
    setSelectedStudents((prevState) => {
      const isChecked = prevState.includes(userId);
      return isChecked ? prevState.filter((s) => s !== userId) : prevState.concat(userId);
    });
  };

  const fetchData = useCallback(
    async ({ pageSize, pageIndex }) => {
      setPageLoading(true);
      const searchParams = getSearchParams(searchFilter);

      try {
        await dispatch(
          fetchAccountUsers({
            selector: "users",
            account: accountId,
            pageSize,
            page: pageIndex + 1,
            searchParams,
          })
        );

        setPageLoading(false);
      } catch (error) {
        console.log("PAGE_ERROR", { error });
        setPageLoading(false);
      }
    },
    [accountId, dispatch, searchFilter]
  );

  const openCreateUserForm = (event) => {
    event.preventDefault();

    dispatch(
      openModal({
        type: MODAL_CREATE_STUDENT,
        data: { ...modal.data, modalSize: "" },
      })
    );
  };

  const addUsers = async (event) => {
    event.preventDefault();

    const { data } = modal;
    const addUserActionHandler = data.addUserAction ? data.addUserAction : addUsersToCohorts;
    const selectedUsers = isAllStudentsSelected
      ? users
      : users.filter((user) => selectedStudents.includes(user.id));

    try {
      if (selectedUsers.length) {
        const response = await dispatch(
          addUserActionHandler({
            selectedItems: selectedUsers,
            ...data,
            userRole: data.userRole === "Instructor" ? "Lecturer" : data.userRole,
            selector: data.selector ? data.selector : "students",
          })
        );

        await dispatch(closeModal());

        if (response) {
          dispatch(
            addNotification({
              notification: createNotification(
                LEVELS.SUCCESS,
                "Success",
                "Enrolled students successfully!",
                5
              ),
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            "Error",
            "Something went wrong while trying to enroll students.",
            5
          ),
        })
      );
    }
  };

  const toggleSelectAllStudents = () => setAllStudentsSelected((prevState) => !prevState);

  const closePopup = () => {
    dispatch(closeModal());
  };

  const debouncedSearch = useCallback(
    debounce(async (searchParams) => {
      setPageLoading(true);
      await dispatch(
        fetchAccountUsers({
          selector: "users",
          account: accountId,
          resetData: false,
          appendRecords: false,
          searchParams,
        })
      );
      setPageLoading(false);
    }, 500),
    []
  );

  const handleFilterChange = useCallback(
    async (event, matchingFilter, columnId) => {
      const newFilter = {
        id: columnId,
        value: event.target.value,
      };

      // If the filter key already exists and the value has been updated, updating the matchiing filter
      // If the filter key already exists but the value is empty (deleted/reset) then remove the filter
      // If the filter is new add it
      const { [columnId]: matchFilter, ...restOfFilter } = searchFilter;

      const newSearchFilter = matchingFilter
        ? newFilter.value
          ? { ...searchFilter, [columnId]: newFilter }
          : restOfFilter
        : { ...searchFilter, [columnId]: newFilter };

      setSearchFilter(newSearchFilter);

      const searchParams = getSearchParams(newSearchFilter);

      if (["userRole", "status"].includes(columnId)) {
        setPageLoading(true);
        await dispatch(
          fetchAccountUsers({
            selector: "users",
            account: accountId,
            resetData: false,
            appendRecords: false,
            searchParams,
          })
        );
        setPageLoading(false);
      } else {
        debouncedSearch(searchParams);
      }
    },
    [accountId, debouncedSearch, dispatch, searchFilter]
  );

  const columns = useMemo(() => {
    return [
      {
        id: "isSelected",
        accessor: (original, index, row, data, table) => {
          return isSelected(original.id);
        },
        minWidth: 60,
        maxWidth: 60,
        sortable: false,
        disableFilters: true,
        Header: ({ data }) => {
          const instanceId = "enrollment-selectAllStudents";

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2px",
              }}
            >
              <Checkbox
                id={instanceId}
                checked={isAllStudentsSelected}
                onChange={toggleSelectAllStudents}
              />
              <CheckboxLabel htmlFor={instanceId} />
            </div>
          );
        },
        Cell: CheckboxCell,
      },
      {
        accessor: "firstName",
        Header: "First Name",
        Filter: InputFilterComponent,
        minWidth: 125,
      },
      {
        accessor: "lastName",
        Header: "Last Name",
        Filter: InputFilterComponent,
        minWidth: 125,
      },
      {
        accessor: "email",
        Header: "Email",
        minWidth: 200,
        Filter: InputFilterComponent,
      },
      {
        accessor: "studentId",
        Header: "Student ID",
        Filter: InputFilterComponent,
        minWidth: 75,
      },
    ];
  }, [isAllStudentsSelected, isSelected]);

  return (
    <ModalFrame modifier="modal-frame-add-users" onClick={closePopup}>
      <FrameHead>
        <FrameTitle>{`Add ${courseId ? `${userRole}s` : "Students"}`}</FrameTitle>
      </FrameHead>
      <FrameBody>
        <PaginatedTable
          data={users}
          serverSidePagination={true}
          fetchData={fetchData}
          columns={columns}
          isFilteringEnabled={true}
          count={count}
          isLoading={isPageLoading}
          meta={{
            searchFilter,
            handleFilterChange,
            selectedStudents,
            isAllStudentsSelected,
            onToggleSelectStudent,
          }}
          NoDataComponent={() => (
            <div style={{ height: "487px", padding: "40px 24px" }}>
              <NoDataComponent type="User" iconPath="svg/ico-user.svg" />
            </div>
          )}
          // defaultFilterMethod={(filter, row) => {
          //   return (
          //     String(row[filter.id])
          //       .toLowerCase()
          //       .search(filter.value.toLowerCase()) !== -1
          //   );
          // }}
        />
      </FrameBody>

      <FrameFoot>
        <FrameAction>
          <ButtonLink
            onClick={addUsers}
            className="frame-btn"
            violet
            hasIcon
            disabled={isPageLoading || (selectedStudents.length === 0 && !isAllStudentsSelected)}
          >
            <span>Add Students</span>
            <IconSvgComponent iconPath="svg/ico-check-white.svg" />
          </ButtonLink>
        </FrameAction>
        <FrameAction>
          <ButtonLink isFrameLink href="" onClick={openCreateUserForm}>
            Can’t find the user you are looking for?
          </ButtonLink>
        </FrameAction>
      </FrameFoot>
    </ModalFrame>
  );
};

export default ModalAddUsers;
