import React, { Component } from "react";
import { connect } from "react-redux";
import { IMAGE_S3_SERVER } from "constants/api";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import AnswerOptionsContainer from "components/views/AnswerOptionsContainer";
import {
  AssessmentQuestionContainer,
  AssessmentQuestionItem,
  AssessmentQuestionNumber,
  AssessmentQuestionItemContainer,
  AssessmentQuestionImage,
  AssessmentQuestionText,
  AssessmentAnswers,
  AssessmentQuestionItemPin,
  AssessmentQuestionItemPinTooltip,
  AssessmentQuestionActionsBar,
  AssessmentQuestionActionsBarInner,
  AssessmentQuestionButtonPin,
  OptionImage,
} from "styles/components/AssessmentQuestions";
import { FormattedText } from "../common/FormattedText";

class AssessmentQuestions extends Component {
  hasSelectedAnswer = () => {
    const { completedQuestions, currentQuestionId } = this.props;

    return !!completedQuestions[currentQuestionId];
  };

  isChecked = (id) => {
    const { completedQuestions, currentQuestionId } = this.props;
    const currentQuestion = completedQuestions[currentQuestionId];

    if (currentQuestion !== undefined) {
      return parseInt(id) === parseInt(currentQuestion.answer) || parseInt(id) === parseInt(currentQuestion.selectedAnswerId);
    }
  };

  render() {
    const {
      questions,
      question,
      index,
      isPinned,
      onNextQuestionButtonClick,
      onPreviousQuestionButtonClick,
      onInputChange,
      onPinClick,
      isSidebarOpen,
    } = this.props;

    const { id, type_id, answers, layout, description } = question;

    return (
      <AssessmentQuestionContainer>
        <AssessmentQuestionItem>
          <AssessmentQuestionItemContainer>
            <AssessmentQuestionImage fullwidth>
              {description.map((dataItem, k) => {
                if (dataItem.type === "TEXT") {
                  return (
                    <AssessmentQuestionText
                      key={k}
                      layout={question.layout}
                      widthCounter={description.length}
                      floatCounter={k}
                    >
                      {k == 0 && <AssessmentQuestionNumber>{index + 1}.</AssessmentQuestionNumber>}
                      <FormattedText text={dataItem.text} />
                    </AssessmentQuestionText>
                  );
                }
                return (
                  <AssessmentQuestionText
                    key={k}
                    layout={question.layout}
                    widthCounter={question.description.length}
                    floatCounter={k}
                  >
                    {k == 0 && <AssessmentQuestionNumber>{index + 1}.</AssessmentQuestionNumber>}
                    <OptionImage widthCounter={question.description.length} layout={question.layout}>
                      <img
                        key={`image_${k}`}
                        src={`${IMAGE_S3_SERVER}${dataItem.src}`}
                        alt={dataItem.src}
                      />
                    </OptionImage>
                  </AssessmentQuestionText>
                );
              })}
            </AssessmentQuestionImage>
          </AssessmentQuestionItemContainer>
        </AssessmentQuestionItem>

        <AssessmentAnswers id={"AssessmentAnswersListContainer"}>
          <AnswerOptionsContainer
            answers={answers}
            isChecked={this.isChecked}
            onInputChange={this.props.onInputChange}
            type="assessment"
          />
        </AssessmentAnswers>
        <AssessmentQuestionActionsBar sidebarOpen={isSidebarOpen}>
          <AssessmentQuestionActionsBarInner>
            <ButtonLink
              className="btn-question"
              hasIcon
              disabled={index === 0}
              onClick={onPreviousQuestionButtonClick}
            >
              <IconSvgComponent iconPath="svg/ico-pointer-left.svg" />
              <span>Back</span>
            </ButtonLink>

            <AssessmentQuestionButtonPin onClick={onPinClick} active={isPinned} tabIndex={0}>
              <IconSvgComponent iconPath="svg/ico-pin.svg" additionalClass={"icon-pin"} />

              <span>{isPinned ? "Unpin" : "Pin"}</span>
            </AssessmentQuestionButtonPin>

            <ButtonLink
              className="btn-question"
              hasIcon
              disabled={index + 1 === questions.length}
              onClick={onNextQuestionButtonClick}
            >
              <IconSvgComponent iconPath="svg/ico-pointer-right.svg" />

              <span>{this.hasSelectedAnswer() ? "Next" : "Skip"}</span>
            </ButtonLink>
          </AssessmentQuestionActionsBarInner>
        </AssessmentQuestionActionsBar>
      </AssessmentQuestionContainer>
    );
  }
}

export default connect((state, ownProps) => {
  //const storeKey = ownProps.courseAssessment ? "courseAssessment" : "assessment";
  return {
    questions: state.assessment.assessmentQuestions,
    currentQuestionId: state.assessment.currentQuestionId,
    completedQuestions: state.assessment.completedQuestions,
    isSidebarOpen: state.assessment.isSidebarOpen,
  };
}, {})(AssessmentQuestions);
