/**
 * The external dependencies.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { prop } from "ramda";
import { connect } from "react-redux";
import styled from "styled-components";
import { media } from "styles/media";
import moment from "moment";
import { OPERATIONS } from "constants/common";
import { StyledGenericError } from "styles/components/Form";
import { WIZARD_CREATE_DEPLOYMENT, WIZARD_DEPLOYMENT_SUMMARY } from "lib/constants";
/**
 * The internal dependencies.
 */
import { create, update } from "store/state/administration/actions";
import { openModal, closeModal } from "store/state/ui/actions";
import {
  createDeployment,
  editUpdateDeployment,
  clearDeployment,
  clearAPIErrors,
} from "store/state/deployment/actions";
import Field from "components/common/Field";
import ButtonLink from "components/common/ButtonLink";
import IconSvgComponent from "components/common/IconSvgComponent";
import {
  SectionWizard,
  SectionTitle,
  SectionBody,
  DeploymentActions,
} from "styles/components/Section";

/**
 * Class for deployment summary.
 *
 * @class      DeploymentSummary (name)
 */
class DeploymentSummary extends Component {
  /**
   * The properties that can be passed to the component.
   *
   * @type {Object}
   */
  static propTypes = {
    deployment: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.clearAPIErrors();
  }

  /**
   * Handles program deployment.
   *
   * @param      {Object} / The event.
   * @return     {Void}
   */
  hasProp = (key) => prop(key, this.props.modal.data);

  deploy = (e) => {
    const generate = require("nanoid/generate");
    e.preventDefault();
    const {
      deployment,
      create,
      update,
      modal,
      closeModal,
      createDeployment,
      editUpdateDeployment,
      clearDeployment,
    } = this.props;

    if (this.hasProp("editDeployment") || deployment.id) {
      const apiCall = editUpdateDeployment({
        selector: "deployments",
        item: { ...deployment, is_draft: false },
      });
      apiCall.then(({ status }) => {
        if (status === "success") {
          closeModal();
          clearDeployment();
        }
      });
    } else {
      const apiCall = createDeployment({
        selector: "deployments",
        item: { ...deployment, is_draft: false },
      });

      apiCall.then(({ status }) => {
        if (status === "success") {
          closeModal();
          clearDeployment();
        }
      });
    }
  };

  goBack = () => {
    const { changeStep } = this.props;
    changeStep(WIZARD_CREATE_DEPLOYMENT, WIZARD_DEPLOYMENT_SUMMARY)();
  };

  /**
   * The render method of the component.
   *
   * @return {JSX}
   */
  render() {
    const {
      id,
      name,
      program,
      ownerName,
      ownerEmail,
      startDate,
      endDate,
      cohort,
      restricted,
      access_code,
    } = this.props.deployment;

    const operation = this.hasProp("editDeployment") || id ? OPERATIONS.UPDATE : OPERATIONS.CREATE;

    return (
      <SectionWizard>
        <SectionTitle>Deployment Summary</SectionTitle>

        {this.props.apiError &&
          this.props.apiError[operation].code !== -1 &&
          this.props.apiError[operation].message && (
            <StyledGenericError fit="fit">
              {this.props.apiError[operation].message}
            </StyledGenericError>
          )}

        <Wrapper>
          <SectionBody>
            <SummaryList>
              <SummaryListItem>
                <Field isStatic placeholder="Deployment Name" value={name} />
              </SummaryListItem>

              <SummaryListItem>
                <Field isStatic placeholder="Program" value={program.label} />
              </SummaryListItem>

              <SummaryListItem>
                <Field isStatic placeholder="Program Owner Full Name" value={ownerName} />
              </SummaryListItem>

              <SummaryListItem>
                <Field isStatic placeholder="Program Owner Email" value={ownerEmail} />
              </SummaryListItem>

              <SummaryListItem>
                <Field
                  isStatic
                  placeholder="Start Date & Time"
                  value={moment(startDate).format("MM/DD/YYYY h:mm a")}
                />
              </SummaryListItem>

              <SummaryListItem>
                <Field
                  isStatic
                  placeholder="End Date & Time"
                  value={moment(endDate).format("MM/DD/YYYY h:mm a")}
                />
              </SummaryListItem>

              <SummaryListItem>
                <Field isStatic placeholder="Cohort" value={cohort.label} />
              </SummaryListItem>

              {restricted && (
                <SummaryListItem>
                  <Field isStatic placeholder="Access Code" value={access_code} />
                </SummaryListItem>
              )}
            </SummaryList>
          </SectionBody>

          <DeploymentActions>
            <ButtonLink
              type="button"
              isPrevious
              violet
              hasIcon
              onClick={this.goBack}
              floatdir="left"
            >
              <IconSvgComponent iconPath="svg/ico-arrow-left-large.svg" />
              <span>Back</span>
            </ButtonLink>
            <SummaryDeployButton type="button" onClick={this.deploy} violet hasIcon>
              <span>Deploy Program</span>
              <IconSvgComponent iconPath="svg/ico-arrow-right-large.svg" />
            </SummaryDeployButton>
          </DeploymentActions>
        </Wrapper>
      </SectionWizard>
    );
  }
}

const SummaryDeployButton = styled(ButtonLink)`
  margin-left: 85px;
`;

const Wrapper = styled.div`
  max-width: 492px;
  margin: 0 auto;
`;

const SummaryList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding: 10px 0 8px;

  ${media.mobile`
		display: block;
	`};
`;
const SummaryListItem = styled.li`
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 12px 8px;

  ${media.mobile`
		display: block;
		max-width: 100%;
		flex: 1 1 100%;
		padding: 0 12px 8px;
	`};
`;

export default connect(
  (state) => ({
    modal: state.ui.modal,
    deployment: state.deployment,
    apiError: state.administration.apiErrors.deployments,
  }),
  {
    create,
    update,
    openModal,
    closeModal,
    createDeployment,
    editUpdateDeployment,
    clearDeployment,
    clearAPIErrors,
  }
)(DeploymentSummary);
