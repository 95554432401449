/**
 * The external dependencies.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * The internal dependencies.
 */
import { MODAL_USER_LIMIT_REACHED } from "lib/constants";
import { createUserItem, updateUserItem } from "store/state/administration/actions";
import { closeModal, openModal } from "store/state/ui/actions";
import IconSvgComponent from "components/common/IconSvgComponent";
import ButtonLink from "components/common/ButtonLink";
import Field from "components/common/Field";
import Form, {
  FormHead,
  FormLogo,
  FormBody,
  FormTitle,
  FormActions,
  FormRowGrid,
  FormCol,
  FormControls,
  FormLabel,
} from "styles/components/Form";
import { validateField, validateSelectField, setValidityAndMessage } from "utilities/validation";
import FieldErrors from "../common/FieldErrors";
import { FORM_CREATE_USER_MESSAGES } from "constants/messages";
import { OPERATIONS } from "constants/common";
import { userRoles, studentYears } from "constants/dropdownOptions";
import { Pill } from "styles/components/Btns";
import { addNotification } from "store/state/ui/actions";
import { LEVELS, createNotification } from "utilities/notification";
import moment from "moment";

/**
 * Class for form create user.
 *
 * @class      FormCreateUser (name)
 */
class FormCreateUser extends Component {
  state = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    status: this.props.modal.data.status ? this.props.modal.data.status : "active",
    studentId: "",
    year: "",
    assessmentTimeMultiplier: null,
    createdAt: "",
    modifiedAt: "",
    createdBy: "",
    modifiedBy: "",
    formErrors: {
      userName: {
        valid: true,
        message: "",
      },
      firstName: {
        valid: true,
        message: "",
      },
      lastName: {
        valid: true,
        message: "",
      },
      email: {
        valid: true,
        message: "",
      },
      role: {
        valid: true,
        message: "",
      },
      studentId: {
        valid: true,
        message: "",
        validEarlier: true,
      },
      year: {
        valid: true,
        message: "",
        validEarlier: true,
      },
      assessmentTimeMultiplier: {
        valid: true,
        message: "",
        validEarlier: true,
      },
      formValid: true,
    },
  };
  rules = {
    userName: {
      requiredMessage: FORM_CREATE_USER_MESSAGES.USER_NAME_REQUIRED,
      typeUsernameMessage: FORM_CREATE_USER_MESSAGES.USER_NAME_FORMAT_INVALID,
      maxMessage: FORM_CREATE_USER_MESSAGES.USER_NAME_MAX,
    },
    firstName: {
      requiredMessage: FORM_CREATE_USER_MESSAGES.FIRST_NAME_REQUIRED,
      maxMessage: FORM_CREATE_USER_MESSAGES.FIRST_NAME_MAX,
    },
    lastName: {
      requiredMessage: FORM_CREATE_USER_MESSAGES.LAST_NAME_REQUIRED,
      maxMessage: FORM_CREATE_USER_MESSAGES.LAST_NAME_MAX,
    },
    email: {
      requiredMessage: FORM_CREATE_USER_MESSAGES.EMAIL_REQUIRED,
      typeEmailMessage: FORM_CREATE_USER_MESSAGES.EMAIL_FORMAT_INVALID,
      maxMessage: FORM_CREATE_USER_MESSAGES.EMAIL_MAX,
    },
    role: {
      requiredMessage: FORM_CREATE_USER_MESSAGES.ROLE_REQUIRED,
    },
    studentId: {
      requiredMessage: FORM_CREATE_USER_MESSAGES.STUDENT_ID_REQUIRED,
      maxMessage: FORM_CREATE_USER_MESSAGES.STUDENT_ID_MAX,
    },
    year: {
      requiredMessage: FORM_CREATE_USER_MESSAGES.YEAR_REQUIRED,
    },
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSelectChange = (key) => (value) => {
    if (key === "assessmentTimeMultiplier") {
      this.setState({
        [key]: value?.value ?? "",
      });
    } else {
      this.setState({
        [key]: value || "",
      });
    }

    if (key === "role") {
      let errors = this.state.formErrors;
      setValidityAndMessage("studentId", errors, true, null);
      setValidityAndMessage("year", errors, true, null);
      this.setState({ formErrors: errors });
    }
  };

  isEdit() {
    return this.props.modal.data && this.props.modal.data.editItem;
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.validateAllFields();
    let apiCall = null;

    if (this.state.formErrors.formValid) {
      if (this.isEdit()) {
        apiCall = this.props.updateUserItem({
          selector: "users",
          id: this.props.modal.data.id,
          updates: {
            ...this.state,
          },
        });
        apiCall.then(({ status }) => {
          if (status === "success") {
            this.props.addNotification({
              notification: createNotification(
                LEVELS.SUCCESS,
                "Success",
                this.isEdit() ? "Updated user successfully!" : "Created user successfully!",
                5
              ),
            });

            this.props.closeModal();
          }
        });
      } else {
        apiCall = this.props.createUserItem({
          selector: "users",
          item: {
            ...this.state,
            account: this.props.modal.data.accountId,
          },
        });
        apiCall.then(({ status, data }) => {
          if (status === "success" || data?.errorCode == 165) {
            this.props.closeModal();

            if (data.errorCode == 165) {
              this.props.openModal({ type: MODAL_USER_LIMIT_REACHED, data: { user: this.state } });
            } else {
              this.props.addNotification({
                notification: createNotification(
                  LEVELS.SUCCESS,
                  "Success",
                  this.isEdit() ? "Updated user successfully!" : "Created user successfully!",
                  5
                ),
              });
            }
          }
        });
      }
    }
  };

  validateTextField = (element, trimValues) => {
    this.setState({
      formErrors: validateField(this.state.formErrors, this.rules, element, "", trimValues),
    });
  };

  validateDropDownField = (properties) => {
    this.setState({
      formErrors: validateSelectField(this.state.formErrors, this.rules, properties),
    });
  };

  validateAllFields = () => {
    this.validateTextField(document.getElementById("userName"), true);
    this.validateTextField(document.getElementById("firstName"), true);
    this.validateTextField(document.getElementById("lastName"), true);
    this.validateTextField(document.getElementById("email"), true);
    this.validateDropDownField({ name: "role", value: this.state.role, required: true });

    if (this.state.role && this.state.role.value === "Student") {
      this.validateTextField(document.getElementById("studentId"), true);
    }
  };

  componentDidMount() {
    if (this.isEdit()) {
      this.setState({
        ...this.props.modal.data.editItem,
      });
    }
  }

  render() {
    const {
      id,
      firstName,
      lastName,
      email,
      role,
      studentId,
      year,
      enrollments,
      groupTag,
      assessmentTimeMultiplier,
      createdAt,
      modifiedAt,
      createdBy,
      modifiedBy,
      formErrors,
    } = this.state;
    const { apiCallInProgress, assessmentTimeMultipliers } = this.props;

    const assessmentTimeMultiplierOptions = assessmentTimeMultipliers.sort().map((value) => {
      return {
        value,
        label: value,
      };
    });
    const selectedAssessmentTimeMultiplier = !!assessmentTimeMultiplier
      ? assessmentTimeMultiplierOptions.find(
          (option) => option.value === assessmentTimeMultiplier
        ) || { value: assessmentTimeMultiplier, label: assessmentTimeMultiplier }
      : null;

    let operation = this.isEdit() ? OPERATIONS.UPDATE : OPERATIONS.CREATE;

    return (
      <>
        <Form userForm onSubmit={this.handleFormSubmit}>
          <FormHead>
            <FormLogo>
              {this.isEdit() ? (
                <IconSvgComponent iconPath="svg/ico-user-edit.svg" />
              ) : (
                <IconSvgComponent iconPath="svg/ico-user-add.svg" />
              )}
            </FormLogo>
            <FormTitle>{this.isEdit() ? "Edit User" : "Create User"}</FormTitle>
            <FieldErrors
              formErrors={formErrors}
              apiErrorMessage={this.props.usersApiErrors[operation].message}
            ></FieldErrors>
          </FormHead>

          <FormBody>
            <FormRowGrid>
              <FormCol>
                <FormControls>
                  <Field
                    id="userName"
                    name="id"
                    type="text"
                    onChange={this.handleInputChange}
                    value={id}
                    placeholder="User Name"
                    required
                    error={!formErrors.userName.valid}
                    patternAvailable={true}
                    patternString="^[^ ]{6,255}$"
                    disabled={this.isEdit()}
                    errorTitle={"Minimum 6 letters required without space."}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={this.handleInputChange}
                    value={firstName}
                    placeholder="First Name"
                    required
                    max="255"
                    error={!formErrors.firstName.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={this.handleInputChange}
                    value={lastName}
                    placeholder="Last Name"
                    required
                    max="255"
                    error={!formErrors.lastName.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    onChange={this.handleInputChange}
                    value={email}
                    placeholder="Email"
                    required
                    max="128"
                    error={!formErrors.email.valid}
                  />
                </FormControls>
              </FormCol>

              <FormCol half>
                <FormControls>
                  <Field
                    id="role"
                    name="role"
                    isSelect
                    onChange={this.handleSelectChange("role")}
                    options={userRoles}
                    value={role}
                    placeholder="Role"
                    required
                    error={!formErrors.role.valid}
                  />
                </FormControls>
              </FormCol>

              {role.value === "Student" && (
                <React.Fragment>
                  <FormCol half>
                    <FormControls>
                      <Field
                        id="studentId"
                        name="studentId"
                        type="text"
                        onChange={this.handleInputChange}
                        value={studentId}
                        placeholder="Student ID"
                        max="255"
                        error={!formErrors.studentId.valid}
                      />
                    </FormControls>
                  </FormCol>

                  <FormCol half>
                    <FormControls>
                      <Field
                        id="year"
                        name="year"
                        isSelect
                        onChange={this.handleSelectChange("year")}
                        options={studentYears}
                        value={year}
                        placeholder="Year"
                        error={!formErrors.year.valid}
                      />
                    </FormControls>
                  </FormCol>

                  <FormCol half>
                    <FormControls>
                      <Field
                        id="assessmentTimeMultiplier"
                        name="assessmentTimeMultiplier"
                        value={selectedAssessmentTimeMultiplier}
                        placeholder="Assessment Time Multiplier"
                        isSelect
                        onChange={this.handleSelectChange("assessmentTimeMultiplier")}
                        options={assessmentTimeMultiplierOptions}
                      />
                    </FormControls>
                  </FormCol>
                </React.Fragment>
              )}

              {this.isEdit() && (
                <>
                  <FormCol two_thirds>
                    <FormControls>
                      <FormLabel
                        style={{
                          transform: "translateY(-17px) scale(0.75)",
                        }}
                      >
                        Cohort Enrollments
                      </FormLabel>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                          padding: "20px 10px 10px 0px",
                          backgroundColor: "#fcfcfc",
                          borderBottom: "1px solid rgba(138,144,156,0.25)",
                        }}
                      >
                        {enrollments?.length > 0
                          ? enrollments.map((enrollment) => {
                              return (
                                <Pill key={enrollment.cohortId} small default>
                                  {enrollment.cohortName}
                                </Pill>
                              );
                            })
                          : "Not currently enrolled"}
                      </div>
                    </FormControls>
                  </FormCol>
                  <FormCol third>
                    <FormControls>
                      <Field
                        type="text"
                        id="groupTag"
                        name="groupTag"
                        value={groupTag || "—"}
                        placeholder="Group Tag"
                        disabled={true}
                      />
                    </FormControls>
                  </FormCol>
                </>
              )}
            </FormRowGrid>
          </FormBody>

          <FormActions
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            {this.isEdit() && (
              <div
                style={{
                  fontSize: "12px",
                  paddingLeft: "15px",
                  textAlign: "left",
                }}
              >
                {modifiedBy && (
                  <div>
                    <strong>Modified By:</strong> {modifiedBy}
                    {modifiedAt && ` at ${moment(modifiedAt).format("MMMM Do YYYY, h:mma")}`}
                  </div>
                )}
                {createdBy && (
                  <div style={{ marginTop: "2px" }}>
                    <strong>Created By:</strong> {createdBy}
                    {createdAt && ` at ${moment(createdAt).format("MMMM Do YYYY, h:mma")}`}
                  </div>
                )}
              </div>
            )}
            <ButtonLink type="submit" violet hasIcon disabled={apiCallInProgress}>
              <span>{this.isEdit() ? "Update User" : "Create User"}</span>

              <IconSvgComponent iconPath="svg/ico-check-white.svg" />
            </ButtonLink>
          </FormActions>
        </Form>
      </>
    );
  }
}

export default connect(
  (state) => ({
    modal: state.ui.modal,
    usersApiErrors: state.administration.apiErrors.users,
    apiCallInProgress: state.administration.apiCallInProgress,
    assessmentTimeMultipliers:
      state.administration.accounts?.[0].settings?.accommodations?.assessmentTimeMultipliers || [],
  }),
  {
    createUserItem,
    updateUserItem,
    closeModal,
    openModal,
    addNotification,
  }
)(FormCreateUser);
