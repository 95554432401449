import styled from "styled-components";
import theme from "../theme";
import { media } from "../media";

const CourseTabs = styled.div``;

export const CourseTabsNav = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 250;

  ${media.tablet`
    position: relative;
    left: auto;
    top: auto;
    transform: translateX(0)
  `}

  ${media.mobile`
    padding: 10px 0;
  `}
`;

export const CourseTabsNavItem = styled.li`
  padding: 0 27px;

  ${media.tablet`
    padding: 0 10px;
  `}

  ${media.mobile`
    padding: 0 5px;
  `}
`;

export const CourseTabsNavButton = styled.button`
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66px;
  padding: 0 6px;
  font-size: 16px;
  color: ${theme.color.darker_gray};
  min-width: 90px;
  position: relative;
  transition: color 0.3s;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 3px;
    background: transparent;
    transition: background 0.3s;
  }

  ${(props) =>
    props.active &&
    `
    color: ${theme.color.blue};

    &:before {
      background: ${theme.color.blue};
    }
  `}


  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
  `}

  ${media.tablet`
    font-size: 14px;
    min-width: 55px;
  `}

  ${media.mobile`
        height: 40px;
    `}
`;

export const CourseTabsContent = styled.div``;

export const CourseTab = styled.div`
  padding: 30px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;

  ${(props) =>
    props.active &&
    `
    position: static;
    opacity: 1;
    visibility: visible;
  `}

  ${media.tablet`
    padding: 30px 20px;
`}

  ${media.mobile`
    padding: 10px;
  `}
`;

export default CourseTabs;
