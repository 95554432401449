import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "store/state/administration/actions";

const AccountBreadcrumb = ({ match }) => {
  const dispatch = useDispatch();
  const accountId = match.params.accountId;

  const account = useSelector((state) => {
    return state.administration.accounts.find((account) => account.id === accountId);
  });

  useEffect(() => {
    if (accountId && !account) {
      // Fetch account
      dispatch(getAccount({ accountId }));
    }
  }, [account, accountId, dispatch]);

  return <span>{account?.organization}</span>;
};

export default AccountBreadcrumb;
