import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import DocumentTitle from "react-document-title";

import { PaginatedTable } from "components/common/table";
import SectionTable, { SectionHead, SectionBody } from "styles/components/SectionTable";
import { getOpenStaxTracking } from "services/courses";
import Grid, { Col, Row } from "styles/components/Grid";
import Loader from "components/common/loader";

const AdminPage = () => {
  const accountId = useSelector((state) => state.user.accountId);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [features, setFeatures] = useState([]);

  const fetchAnalytics = useCallback(async () => {
    const result = await getOpenStaxTracking({ accountId });

    const accounts = Object.keys(result.data.accounts).map((key) => {
      return {
        id: key,
        name: result.data.accounts[key].name,
        total: Number(result.data.accounts[key].total),
      };
    });
    const roles = Object.keys(result.data.roles).map((key) => {
      return {
        id: key,
        total: Number(result.data.roles[key]),
        name: key,
      };
    });
    const features = Object.keys(result.data.features).map((key) => {
      return {
        id: key,
        total: Number(result.data.features[key]),
        name: key,
      };
    });

    setTotal(result.data.total);
    setAccounts(accounts);
    setRoles(roles);
    setFeatures(features);
    setIsLoading(false);
  }, [accountId]);

  useEffect(() => {
    if (!total) {
      fetchAnalytics();
    }
  }, [total, fetchAnalytics]);

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      filterable: false,
    },
    {
      id: "total",
      Header: "Clicks",
      accessor: "total",
      filterable: false,
    },
  ];

  return (
    <DocumentTitle title="Stemify | Administration">
      <SectionTable>
        <SectionHead>
          <h2 style={{ textAlign: "center" }}>OpenStax Tracking Analytics</h2>
        </SectionHead>

        <SectionBody style={{ padding: "20px" }}>
          <Grid>
            <Row>
              <Col>
                <h3>Total: {total} clicks</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Accounts</h4>
                <PaginatedTable
                  data={accounts}
                  columns={columns}
                  page={0}
                  pages={1}
                  pageSize={accounts.length}
                  count={accounts.length}
                  isLoading={isLoading}
                  localPagination={true}
                  showPagination={false}
                  defaultSorted={[
                    {
                      id: "total",
                      desc: true,
                    },
                  ]}
                  style={{ minWidth: "auto" }}
                />
              </Col>
              <Col>
                <h4>Roles</h4>
                <PaginatedTable
                  data={roles}
                  columns={columns}
                  page={0}
                  pages={1}
                  isLoading={isLoading}
                  pageSize={roles.length}
                  count={roles.length}
                  showPagination={false}
                  localPagination={true}
                  defaultSorted={[
                    {
                      id: "total",
                      desc: true,
                    },
                  ]}
                  style={{ minWidth: "auto" }}
                />
              </Col>
              <Col>
                <h4>Features</h4>
                <PaginatedTable
                  data={features}
                  columns={columns}
                  page={0}
                  pages={1}
                  isLoading={isLoading}
                  pageSize={features.length}
                  count={features.length}
                  showPagination={false}
                  localPagination={true}
                  defaultSorted={[
                    {
                      id: "total",
                      desc: true,
                    },
                  ]}
                  style={{ minWidth: "auto" }}
                />
              </Col>
            </Row>
          </Grid>
        </SectionBody>
      </SectionTable>
    </DocumentTitle>
  );
};

export default AdminPage;
